export const useStyles = (theme) => ({
    paymentFormContainer: {
        padding: 0
    },
    paymentTitleContainer: {
        display: 'flex',
        gap: 8,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row'
        }
    },
    creditsContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            gap: 64
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row'
        }
    },
    remainingCredit: {
        fontSize: 14
    },
    creditsCard: {
        display: 'flex',
        flexDirection: 'column',
        gap: 32,
        padding: 16,
        minWidth: 400,
        borderRadius: 16,
        background: 'var(--background-purple)',
        [theme.breakpoints.down('md')]: {
            minWidth: 'unset',
            width: '100%'
        },
    },
    cardHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: 'white'
    },
    cardCreditsCost: {
        display: 'flex',
        gap: 16
    },
    cardCreditsInDetail: {
        display: 'flex',
        justifyContent: 'space-between',
        color: 'white'
    },
    whiteColor: {
        color: 'white'
    }
})
