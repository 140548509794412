import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    funnelForm: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '50px 100px 75px 100px',
        [theme.breakpoints.down('sm')]: {
            padding: 20,
            paddingBottom: 40
        }
    },
}))
