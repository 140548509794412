import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './kotsummary_style'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'

import CollectionHandler from 'assets/CollectionHandler.js'
import KotShowSectionTitle from '../KotShowSectionTitle/KotShowSectionTitle'

const KotSummary = ({ kot }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Box>
                    <Box className={`${classes.contentSection}`}>
                        <KotShowSectionTitle textId='landing_card_kot_page.in_summary'/>
                    </Box>
                    <TableContainer>
                        <Table className={classes.tableMinWidth} aria-label='simple table'>
                            <TableBody>
                                {kot.kot_type && (
                                    <TableRow>
                                        <TableCell component='th' className={classes.noPaddingLeft} scope='row'>
                                            <Typography component={'div'}><FormattedMessage id='landing_card_kot_page.info_container_housing_type' /></Typography>
                                        </TableCell>
                                        <TableCell className={classes.minWidth}>
                                            {CollectionHandler.Translate('kotTypeCollection', kot.kot_type)}
                                        </TableCell>
                                    </TableRow>
                                )}
                                {kot.availability && (
                                    <TableRow>
                                        <TableCell component='th' className={classes.noPaddingLeft} scope='row'>
                                            <Typography component={'div'}><FormattedMessage id='landing_card_kot_page.info_container_available' /></Typography>
                                        </TableCell>
                                        <TableCell className={classes.minWidth}>
                                            {CollectionHandler.Translate('kotDisponibilityCollection', kot.availability)}
                                        </TableCell>
                                    </TableRow>
                                )}
                                {kot.condition && (
                                    <TableRow>
                                        <TableCell component='th' className={classes.noPaddingLeft} scope='row'>
                                            <Typography component={'div'}> <FormattedMessage id='landing_card_kot_page.info_container_state_property' /></Typography>
                                        </TableCell>
                                        <TableCell className={classes.minWidth}>
                                            {CollectionHandler.Translate('kotStateCollection', kot.condition)}
                                        </TableCell>
                                    </TableRow>
                                )}
                                <TableRow>
                                    <TableCell component='th' className={classes.noPaddingLeft} scope='row'>
                                        <Typography component={'div'}> <FormattedMessage id='landing_card_kot_page.info_container_equipment' /></Typography>
                                    </TableCell>
                                    <TableCell className={classes.minWidth}>
                                        <ul className={`${classes.noListStyle} ${classes.noPaddingLeft}`}>
                                            {kot.furnished ? <li><FormattedMessage id='landing_card_kot_page.furniture' /> </li> : ''}
                                            {kot.private_bathroom ? <li><FormattedMessage id='landing_card_kot_page.private_bathroom' /> </li> : ''}
                                            {kot.private_kitchen ? <li><FormattedMessage id='landing_card_kot_page.private_kitchen' /> </li> : ''}
                                            {kot.terrace ? <li><FormattedMessage id='landing_card_kot_page.terrace' /></li> : ''}
                                            {kot.garden ? <li><FormattedMessage id='landing_card_kot_page.garden' /> </li> : ''}
                                        </ul>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component='th' className={classes.noPaddingLeft} scope='row'>
                                        <Typography component={'div'}><FormattedMessage id='landing_card_kot_page.info_container_rent_charge' /></Typography>
                                    </TableCell>
                                    <TableCell className={classes.minWidth}>
                                        <ul className={`${classes.noListStyle} ${classes.noPaddingLeft}`}>
                                            {kot.internet ? <li><FormattedMessage id='landing_card_kot_page.internet' /></li> : ''}
                                            {kot.cleaning ? <li><FormattedMessage id='landing_card_kot_page.cleaning' /></li> : ''}
                                            {kot.gas_electricity ? <li><FormattedMessage id='landing_card_kot_page.gas_electricity' /></li> : ''}
                                            {kot.water ? <li><FormattedMessage id='landing_card_kot_page.water' /></li> : ''}
                                        </ul>
                                    </TableCell>
                                </TableRow>
                                {kot.peb_certificate && (
                                    <TableRow>
                                        <TableCell className={`${classes.noborderBottom} ${classes.noPaddingLeft}`} component='th' scope='row'>
                                            <Typography component={'div'}><FormattedMessage id='landing_card_kot_page.info_container_certificat' /></Typography>
                                        </TableCell>
                                        <TableCell className={`${classes.noborderBottom} ${classes.minWidth}`}>
                                            {kot.peb_certificate}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Grid>
        </Grid>
    )
};

export default KotSummary
