import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Typography, useTheme } from '@material-ui/core'

import { useStyles } from './ArticleCardAlt_style'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import DateHandler from 'assets/DateHandler'
import IconFactory from 'icons/IconFactory/IconFactory'

const ArticleCardAlt = (props) => {
    const { article } = props
    const classes = useStyles()
    const theme = useTheme()

    return (
        <RouteMapLink target="_blank" showItemType='article' showItemUrl={article.url} className={classes.articleCard}>
            <Box className={classes.articleText}>
                <Typography variant='h5' className={classes.articleDate}>
                    {DateHandler.ToBasicFormat(article.publication_date)}
                </Typography>
                <Typography variant='h6' component='h4' className={classes.articleCardTitle}>
                    {article.title}
                </Typography>
                <Typography variant='body1'>
                    {article.meta_description}
                </Typography>
            </Box>
            <Box className={classes.articleReadMore}>
                <Typography variant='h6' component='span' className={classes.readMoreText}>
                    <FormattedMessage id='article_card_alt.read_more' />
                </Typography>
                <Box className={classes.linkIconContainer}>
                    <IconFactory
                        icon='arrow-right'
                        color={theme.palette.fg.white}
                        width='10px'
                        height='10px'
                    />
                </Box>
            </Box>
        </RouteMapLink>
    )
}

export default ArticleCardAlt
