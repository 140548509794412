import React from 'react'
import { useStyles } from './InfoLineView_style'
import { Box, Typography, useTheme } from '@material-ui/core'
import IconFactory from 'icons/IconFactory/IconFactory'

const InfoLineView = (props) => {
    const { icon, text } = props
    const classes = useStyles()
    const theme = useTheme()

    return (
        <Box className={classes.regularLine}>
            <Box className={classes.iconBox}>
                <IconFactory fontSize='14px' icon={icon} color={theme.palette.fg.black90Opacity} />
            </Box>
            <Typography className={classes.text}>
                {text}
            </Typography>
        </Box>
    )
}

export default InfoLineView
