export const useStyles = (theme) => ({
    multiStepRegisterCard: {
        width: '100%',
        maxWidth: '1027px',
        boxShadow: `4px 4px 0px 0px ${theme.palette.bg['black']}`,
        borderRadius: '19px',
        border: `2px solid ${theme.palette.fg['black']}`,
        backgroundColor: theme.palette.bg['white']
    },
    step1: {
        width: '100%',
        padding: '35px 20px',
        paddingBottom: '117px !important',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up(850)]: {
            paddingLeft: '190px',
            paddingRight: '190px'
        },
    },
    stepTitle: {
        width: '100%',
        fontFamily: 'GreedWide-Bold',
        color: theme.palette.fg['black'],
        fontSize: '29px',
        lineHeight: '49px',
        textAlign: 'center'
    },
    step1RadioGroup: {
        paddingTop: '45px',
        paddingBottom: '41px',
        [theme.breakpoints.up('sm')]: {
            paddingTop: '85px',
            paddingBottom: '81px'
        }
    },
    formControlLabelRoot: {
        margin: 0,
        columnGap: '7px'
    },
    formControlLabelText: {
        fontFamily: 'GreedStandard-Bold',
        color: theme.palette.fg['black'],
        fontSize: '18px',
        lineHeight: '28px'
    },
    radioRoot: {
        padding: '13px',
        color: theme.palette.fg['black'],
        fontSize: '20px'
    },
    submitButton: {
        width: '100%',
        maxWidth: '345px',
        textTransform: 'capitalize'
    },
    studentRegisterStep: {
        width: '100%',
        padding: '45px 20px 68px',
        [theme.breakpoints.up(760)]: {
            paddingLeft: '190px',
            paddingRight: '190px'
        }
    },
    employerInfoStep: {
        width: '100%'
    },
    form: {
        width: '100%',
        paddingBottom: '41px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '13px',
        [theme.breakpoints.up('sm')]: {
            paddingBottom: '81px'
        }
    }
})
