import { useEffect, useRef } from 'react'

export const useClickOutside = (handler) => {
    const ref = useRef(null)

    const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            handler()
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClick, true)
        return () => {
            document.removeEventListener('click', handleClick, true)
        }
    }, [handler])

    return ref
}
