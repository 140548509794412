import ReactQuill from 'react-quill'
import Delta from 'quill-delta'
import xss from 'xss'

const BlockEmbed = ReactQuill.Quill.import('blots/block/embed')
const Clipboard = ReactQuill.Quill.import('modules/clipboard')
const BaseImageFormat = ReactQuill.Quill.import('formats/image');

export class SurveyFormClipboard extends Clipboard {
    onPaste (e) {
        e.preventDefault()
        const range = this.quill.getSelection()
        const text = e.clipboardData.getData('text/plain')

        const delta = new Delta()
            .retain(range.index)
            .delete(range.length)
            .insert(text)
        const index = text.length + range.index
        const length = 0
        this.quill.updateContents(delta, 'silent')
        this.quill.setSelection(index, length, 'silent')
    }
}

const ImageFormatAttributesList = [ 'alt', 'height', 'width', 'style', 'align']
  
export class ImageFormat extends BaseImageFormat {
    static formats(domNode) {
        return ImageFormatAttributesList.reduce(function(formats, attribute) {
            if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute)
            }
        return formats
      }, {})
    }

    format(name, value) {
        if (ImageFormatAttributesList.indexOf(name) > -1) {
            if (value) {
                this.domNode.setAttribute(name, value)
            } else {
                this.domNode.removeAttribute(name)
            }
        } else {
            super.format(name, value)
        }
    }
}

export class CustomButtonEmbed extends BlockEmbed {
    static create(value) {
        let node = super.create()
        node.innerHTML = this.handleWithXss(value)
        return node
    }

    static value(node) {
        return node.innerHTML
    }

    static handleWithXss(content) {
        const options = {
            whiteList: {
                div: ['class', 'style', 'data-id','contenteditable'],
                a: ['id', 'class', 'style', 'href', 'target', 'rel']
            },
            stripIgnoreTag: true,
            onTagAttr: (tag, name, _, isWhiteAttr) => {
                //Content editable processing for div
                if (isWhiteAttr && tag === 'div' && name === 'contenteditable') {
                    return 'contenteditable="false"'
                }
            },
        }
        const myxss = new xss.FilterXSS(options)
        return myxss.process(content)
    }
}
CustomButtonEmbed.blotName = 'CustomButtonEmbed'
// The css class assigned to it
CustomButtonEmbed.className = 'ql-custom-button'
//Label type customization
CustomButtonEmbed.tagName = 'div'

export class CustomIframeEmbed extends BlockEmbed {
    static create(value) {
        let node = super.create()
        node.innerHTML = this.handleWithXss(value)
        return node
    }

    static value(node) {
        return node.innerHTML
    }

    static handleWithXss(content) {
        const acceptedEmbedsRegex = /(youtube|soundcloud|spotify|twitter)/
        const options = {
            whiteList: {
                div: ['class', 'style', 'data-id','contenteditable'],
                iframe: ['src', 'href', 'style', 'width', 'height', 'frameBorder', 'allowfullscreen', 'allow', 'loading']
            },
            stripIgnoreTag: true,
            onTagAttr: (tag, name, value, isWhiteAttr) => {
                if (isWhiteAttr && tag === 'div' && name === 'contenteditable') {
                    return 'contenteditable="false"'
                }
                if (tag === 'iframe' && name === 'src') {
                    return acceptedEmbedsRegex.test(value) ? `src=${value}` : ''
                }
            },
        }
        const myxss = new xss.FilterXSS(options)
        return myxss.process(content)
    }
}
CustomIframeEmbed.blotName = 'CustomIframeEmbed'
// The css class assigned to it
CustomIframeEmbed.className = 'ql-custom-iframe'
//Label type customization
CustomIframeEmbed.tagName = 'div'
