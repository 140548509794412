import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    aboutPageBanner: {
        minHeight: '66px',
        background: 'var(--background-yellow)',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2.5rem'
    },
    helloFuture: {
        fontSize: '50px !important',
        fontFamily: "'GreedWide-Bold', sans-serif !important",
        textAlign: 'center'
    },
    titlePage: {
        color: 'black',
        padding: '1rem 0',
        [theme.breakpoints.up('sm')]: {
            textAlign: 'center'
        }
    },
    subtitle: {
        margin: '40px 0 1.43rem',
        fontFamily: "'GreedWide-Bold', sans-serif !important",
        [theme.breakpoints.down('xs')]: {
            textAlign: 'initial'
        },
        textTransform: 'uppercase'
    },
    subsubtitle: {
        fontFamily: "'GreedWide-Bold', sans-serif !important",
        margin: '20px 0 1.43rem',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'initial'
        }
    },
    ourMissionText: {
        fontFamily: "'GreedStandard-Medium', sans-serif !important"
    },
    breakline: {
        fontSize: 16,
        marginBottom: '1.43rem'
    },
    text: {
        fontSize: 16
    },
    breaklines: {
        '& p': {
            marginBottom: '1.43rem',
            '&:last-child': {
                marginBottom: 0
            }
        }
    },
    studentFont: {
        fontSize: '16px',
        fontFamily: "'GreedStandard-Bold', sans-serif !important"
    },
    buttonOffers: {
        textAlign: 'center',
        margin: '40px auto'
    },
    cardTitle: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center'
        }
    },
    buttonBox: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
        }
    },
    [theme.breakpoints.down('xs')]: {
        responsiveText: {
            textAlign: 'center'
        }
    },
    listContainer: {
        margin: '20px 0px 0px 40px',
        '& p': {
            marginBottom: '7px',
            '&:before': {
                display: 'inline-block',
                content: '""',
                backgroundColor: 'rgba(165, 165, 165, 0.88) !important',
                height: 7,
                width: 7,
                borderRadius: 100,
                margin: '0 0.9rem 0.05rem -1.35rem'
            }
        }
    },
    kotBackground: {
        '& img': {
            width: '100%',
            objectFit: 'cover',
            [theme.breakpoints.up('sm')]: {
                maxHeight: '30rem'
            },
            [theme.breakpoints.only('xs')]: {
                height: '16rem'
            }
        }
    },
    kotButton: {
        display: 'flex',
        margin: '0 auto'
    },
    listContainerMargin: {
        margin: '10px 0px 20px 40px'
    },
    noMargin: {
        margin: '0 !important'
    },
    teamContainer: {
        background: 'var(--foreground-purple)',
        display: 'flex',
        flexAlign: 'center',
        justifyContent: 'center',
        '& *': {
            color: '#ffffff'
        }
    },
    teamBox: {
        textAlign: 'center',
        marginTop: '2rem',
        '& img': {
            margin: '1rem 0',
            borderRadius: '50%',
            objectFit: 'cover',

            [theme.breakpoints.up('xs')]: {
                width: '220px',
                height: '220px'
            },
            [theme.breakpoints.only('xs')]: {
                width: '120px',
                height: '120px'
            }
        }
    },
    teamMemberName: {
        fontSize: '16px !important'
    },
    teamRole: {
        fontSize: '16px !important',
        marginBottom: '1rem'
    },
    slogan: {
        margin: '0 auto',
        textAlign: 'center',
        fontStyle: 'italic',

        [theme.breakpoints.up('md')]: {
            fontSize: '22px !important',
            width: '70%'
        }
    },
    frame: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            top: '110px'
        },
        [theme.breakpoints.down('sm')]: {
            top: '47%'
        }
    },
    identityContainer: {
        position: 'relative',
        [theme.breakpoints.up('lg')]: {
            marginBottom: '-10rem'
        }
    },
    identityTitle: {
        lineHeight: '1.3em',
        textAlign: 'center',
        [theme.breakpoints.up('lg')]: {
            fontSize: '50px !important',
            position: 'absolute',
            margin: 'auto',
            top: '3rem',
            left: 0,
            right: 0
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '50px !important',
            marginBottom: '1rem'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px !important'
        },
        [theme.breakpoints.only('sm')]: {
            marginBottom: '1rem'
        }
    },
    sloganAuthor: {
        fontStyle: 'italic'
    },
    spacingTop: {
        marginTop: '1rem'
    }
}))
