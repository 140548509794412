/* eslint-disable react/prop-types */
import React from 'react'
import InfoLineView from './InfoLineView/InfoLineView'
import CollectionHandler from 'assets/CollectionHandler.js'

const DurationLine = (props) => {
    const { job } = props
    if (!job.length) return null

    return (
        <InfoLineView
            icon='clipboard'
            text={CollectionHandler.Translate('internshipDurationCollection', job.length)}
        />
    )
}

export default DurationLine
