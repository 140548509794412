import React, { useState, useEffect } from 'react'
import DashboardAPI from 'api/DashboardAPI'
import SmallJobCard from 'cards/SmallJobCard/SmallJobCard'
import RecommendedItemsContainer from 'components/RecommendedItemsContainer/RecommendedItemsContainer'
import RouteMap from 'assets/RouteMap'
import { Box } from '@material-ui/core'
import { useStyles } from '../../PersonalDashboard_style'

const FavouriteJobs = (props) => {
    const { user } = props
    const [favouriteJobs, setFavouriteJobs] = useState([])
    const classes = useStyles()

    useEffect(() => {
        getFavoriteJobs()
    }, [])

    const getFavoriteJobs = () => {
        const data = {
            user_id: user.id,
            amount: 3
        }
        DashboardAPI.GetFavoriteJobs(data).then((response) => {
            setFavouriteJobs(response.data.favourite_jobs)
        })
    }

    return (<>
        {favouriteJobs.length > 0 &&
            <Box className={classes.jobSectionBox}>
                <RecommendedItemsContainer
                    moreItemsURL={RouteMap.Page('users/favourites')}
                    user={user}
                    type='favorite'
                    titleId='personal_dashboard.favourite_jobs.title'
                    subTitleId='personal_dashboard.favourite_jobs.sub_title'
                    recommendedItemType='job'
                >
                    {favouriteJobs.map((favouriteJob) => {
                        return (
                            <SmallJobCard
                                key={`job-card-${favouriteJob.id}`}
                                job={favouriteJob}
                                userType={user.type}
                            />
                        )
                    })}
                </RecommendedItemsContainer>
            </Box>
        }
    </>)
}
export default FavouriteJobs
