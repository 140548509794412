/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import ArticleAPI from 'api/ArticleAPI'
import RecommendedJobsView from 'components/RecommendedJobsView/RecommendedJobsView'
import RouteMap from 'assets/RouteMap.js'
import CollectionHandler from 'assets/CollectionHandler.js'
import { FormattedMessage } from 'react-intl'

const ArticleSimilarJobs = (props) => {
    const { article } = props
    const [similarJobs, setSimilarJobs] = useState([])
    const studentJobCategories = CollectionHandler.Get('studentJobCategories')
    const internshipCategories = CollectionHandler.Get('internshipCategories')
    const firstJobCategories = CollectionHandler.Get('firstJobCategories')

    useEffect(() => {
        getSimilarJobs()
    }, [])

    const getSimilarJobs = () => {
        const data = {
            id: article.id
        }
        ArticleAPI.GetSimilarJobs(data).then((response) => {
            setSimilarJobs(response.data)
        })
    }

    const moreItemsURL = () => {
        if (article.company && article.company.name.toLowerCase() !== 'student.be') {
            return RouteMap.Show('company', article.company.url)
        } else if (studentJobCategories.includes(article.category)) {
            return RouteMap.Index('student_job')
        } else if (internshipCategories.includes(article.category)) {
            return RouteMap.Index('internship')
        } else if (firstJobCategories.includes(article.category)) {
            return RouteMap.Index('first_job')
        }

        return RouteMap.Index('first_job')
    }

    const getTitle = () => {
        if (article.company && article.company.name.toLowerCase() !== 'student.be') {
            return <FormattedMessage id='article_show.more_jobs_from_company' values={{ company_name: article.company_name }}/>
        }

        return <FormattedMessage id='job_show.recommended_jobs.title' />
    }

    const getMoreItemsText = () => {
        if (article.company && article.company.name.toLowerCase() !== 'student.be') {
            return <FormattedMessage id='article_show.more_jobs_from_company_link' values={{ company_name: article.company_name }}/>
        }

        return <FormattedMessage id='job_show.recommended_jobs.more_link' />
    }

    if (similarJobs.length === 0) return null

    return (
        <RecommendedJobsView
            items={similarJobs}
            moreItemsURL={moreItemsURL()}
            title={getTitle()}
            moreItemsText={getMoreItemsText()}
        />
    )
}

export default ArticleSimilarJobs
