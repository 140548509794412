import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import RouteMap from 'assets/RouteMap.js'
import ArticleBannerCard from './components/ArticleBannerCard'

const ArticleBanner = (props) => {
    const [article, setArticle] = useState({})
    const [loaded, setLoaded] = useState(false)

    useEffect(
        () => {
            getArticleBanner()
        }, []
    )

    const getArticleBanner = () => {
        axios.get('/api/v1/articles/banner', {
            params: {
                locale: RouteMap.GetLocaleFromUrl(),
                offset: props.offset,
                category: props.category === 'company' ? 'first_job' : props.category
            }
        }).then((response) => {
            setArticle(response.data[0])
            setLoaded(true)
        })
    }

    return (
        <Box>
            {loaded && article &&
                <ArticleBannerCard article={article} />
            }
        </Box>
    )
}

export default ArticleBanner
