import React from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import DefaultHelmet from './DefaultHelmet'
import RouteMap from 'assets/RouteMap.js'
import DateHandler from 'assets/DateHandler'
import CloudinaryURLHelper from 'assets/CloudinaryURLHelper'
import Sanitizer from 'assets/Sanitizer'

class ArticleShowHelmet extends React.Component {
    render () {
        const firstTag = this.props.article.tag && this.props.article.tag[0] && this.props.article.tag[0].name
        const tagNames = this.props.article.tag.map(tagInstance => tagInstance.name)

        const metaDescription = this.props.article.meta_description ? this.props.article.meta_description.substring(0, 155) : this.props.article.content.substring(0, 155)
        const imageCloudinaryKey = this.props.article.banner_image.cloudinary_key || this.props.article.cover_image.cloudinary_key
        const sanitizedArticleContent = Sanitizer.RemoveHTML(this.props.article.content)

        const getAboutTagList = () => {
            let tagListString = '['
            this.props.article.tag.forEach((tag) => {
                tagListString += `
                    {
                        "@type": "Thing",
                        "name": "${tag.name}"
                    },`
            })
            tagListString = tagListString.slice(0, -1)
            tagListString += ']'

            return tagListString
        }

        return <>
            <DefaultHelmet
                title={this.props.article.title}
                description={metaDescription}
                imageCloudinaryKey={imageCloudinaryKey}
            />
            <Helmet>
                <meta property='og:type' content='article' />
                <meta property='article:published_time' content={this.props.article.updated_at} /> {/* When the article was first published. */}
                <meta property='article:modified_time' content={this.props.article.updated_at} />
                <meta property='og:updated_time' content={this.props.article.updated_at} />
                <meta property='article:author' content={['https://www.facebook.com/student.be', 'https://www.student.be/']} /> {/* Writers of the article. */}
                <meta property='article:section' content={firstTag} /> {/* A high-level section name. E.g. Technology */}
                <meta property='article:tag' content={tagNames} /> {/* Tag words associated with this article. */}
                <meta property="og:url" content={RouteMap.Show('article', this.props.article.url)} />
                <link rel="canonical" href={RouteMap.Show('article', this.props.article.url, this.props.article.language)} />
                <script type="application/ld+json">
                    {`{
                        "@context" : "https://schema.org/",
                        "@type": "Article",
                        "headline" : "${Sanitizer.SanitizeForApplicationJson(this.props.article.title)}",
                        "alternativeHeadline" : "${Sanitizer.SanitizeForApplicationJson(metaDescription)}",
                        "image": [
                            "${this.props.article.banner_image.cloudinary_key && CloudinaryURLHelper.getImageUrl('META_IMAGE', this.props.article.banner_image.cloudinary_key)}",
                            "${this.props.article.cover_image.cloudinary_key && CloudinaryURLHelper.getImageUrl('META_IMAGE', this.props.article.cover_image.cloudinary_key)}"
                        ],
                        "author": {
                            "@type": "Organization",
                            "name": "${this.props.article.company_name}",
                            "url": "${this.props.article.company_url}"
                        },
                        "datePublished" : "${this.props.article.updated_at}",
                        "dateModified" : "${this.props.article.updated_at}",
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "https://example.com/articles/example-article"
                        },
                        "publisher": {
                            "@type": "Organization",
                            "name": "Student.be",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "https://res.cloudinary.com/studentbe/image/upload/f_auto,fl_progressive,q_auto:good,w_400/student-be-logos/studentbe_logo_cropped"
                            }
                        },
                        "description" : "${Sanitizer.SanitizeForApplicationJson(metaDescription)}",
                        "articleBody" : "${Sanitizer.SanitizeForApplicationJson(sanitizedArticleContent)}",
                        "articleSection": "${this.props.article.category}",
                        "wordCount": "${sanitizedArticleContent.length}",
                        "keywords": "${this.props.article.keywords}",
                        "inLanguage": "${this.props.article.language}",
                        "isAccessibleForFree": true,
                        "about": ${getAboutTagList()},
                        "thumbnailUrl": "${CloudinaryURLHelper.getImageUrl('META_IMAGE', this.props.article.cover_image.cloudinary_key)}"
                    }`}
                </script>
            </Helmet>
        </>
    }
}

export default injectIntl(ArticleShowHelmet)
