import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './NewKot_style'
import { FormattedMessage } from 'react-intl'
import CollectionHandler from 'assets/CollectionHandler.js'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import Button from 'components/Button/Button'
import SharedTabs from 'components/SharedTabs/SharedTabs'
import IconFactory from 'icons/IconFactory/IconFactory'
import KotOwnerAPI from 'api/KotOwnerAPI'
import RouteMap from 'assets/RouteMap'
import SectionTitle from 'components/SectionTitle/SectionTitle'
import UploadImageV2 from 'modals/UploadImageV2/UploadImageV2'
import PaperFormWrapper from 'components/PaperFormWrapper/PaperFormWrapper'

const selectPaperStyle = () => {
    return ({
        getContentAnchorEl: null,
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
        }
    })
}

const NewKot = (props) => {
    const { user, kot, action, address, images } = props
    const kotDurationCollection = CollectionHandler.Get('kotDurationCollection')
    const kotConditionCollection = CollectionHandler.Get('kotConditionCollection')
    const kotEnergyCertificateCollection = CollectionHandler.Get('kotEnergyCertificateCollection')
    const kotTypeCollection = CollectionHandler.Get('kotTypeCollection')
    const classes = useStyles()
    const [state, setState] = useState({
        titleKotOffer: kot.title || '',
        rent: kot.rent || '',
        charges: kot.charges || '',
        internet: kot.internet || false,
        cleaning: kot.cleaning || false,
        gasElectricity: kot.gas_electricity || false,
        water: kot.water || false,
        kotType: kot.kot_type || '',
        available: kot.availability || '',
        address: address.street ? address.street : '',
        zip: address.zip ? address.zip : '',
        city: address.city ? address.city : '',
        description: kot.description || '',
        proximity: kot.proximity || '',
        otherInfo: kot.other_infos || '',
        furnished: kot.furnished || false,
        privateBathroom: kot.private_bathroom || false,
        privateKitchen: kot.private_kitchen || false,
        terrace: kot.terrace || false,
        garden: kot.garden || false,
        condition: kot.condition || '',
        pebBoolean: kot.peb_certificate ? 'yes' : 'no',
        pebCertificate: kot.peb_certificate || 'D',
        availableRooms: kot.available_rooms || 1,
        showPopupPicturesEdit: false,
        availableOther: '',
        errors: {}
    })

    const [imageArray, setImageArray] = useState(images || [])

    const handleChange = event => {
        setState({ ...state, [event.target.name]: event.target.value })
    }
    const handleChangeCheckBoxes = event => {
        setState({ ...state, [event.target.name]: event.target.checked })
    }

    const onSubmitForm = event => {
        event.preventDefault()

        const data = {
            title: state.titleKotOffer,
            kot_type: state.kotType,
            rent: state.rent,
            charges: state.charges,
            internet: state.internet,
            cleaning: state.cleaning,
            gas_electricity: state.gasElectricity,
            water: state.water,
            description: state.description,
            availability: state.available,
            available_rooms: state.availableRooms,
            condition: state.condition,
            furnished: state.furnished,
            private_bathroom: state.privateBathroom,
            private_kitchen: state.privateKitchen,
            terrace: state.terrace,
            garden: state.garden,
            peb: state.pebBoolean,
            peb_certificate: state.pebCertificate,
            proximity: state.proximity,
            other_infos: state.otherInfo,
            availability_other: state.availableOther,
            kot_pictures: imageArray,
            id: kot.id,
            origin: 'kot_creation',
            zip: state.zip,
            city: state.city,
            street: state.address,
            action: action === 'edit' ? 'put' : 'post'
        }
        try {
            KotOwnerAPI.UpdateKot(action, data).then(
                window.location.href = RouteMap.Page('users/kots')
            ).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const buttonText = (action === 'edit' && !kot.duplicated) ? 'landing_new_kot_main_page.edit_button' : 'landing_new_kot_main_page.publish_button'

    return (
        <PageWrapper user={user} disableFooterLists>
            <PaperFormWrapper>
                <form onSubmit={onSubmitForm}>
                    <Grid container spacing={10}>
                        <Grid item xs={12} md={6} lg={6} xl={6}>
                            <Grid container alignItems='flex-start'>
                                <Grid item xs={12}>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <SectionTitle
                                                step={1}
                                                title={<FormattedMessage id='landing_new_kot_main_page.general_informations' />}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant='h4'
                                                component='h3'
                                                className={classes.titleField}
                                                color='primary'
                                            >
                                                <FormattedMessage id='landing_new_kot_main_page.title_kot_offer' />
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                required
                                                type={'text'}
                                                defaultValue={state.titleKotOffer}
                                                onBlur={handleChange}
                                                name='titleKotOffer'
                                                autoComplete={'on'}
                                                inputProps={{ maxLength: 60 }}
                                                InputProps={{ classes: { input: classes.inputfield } }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant='h4'
                                                component='h3'
                                                className={classes.titleField}
                                                color='primary'
                                            >
                                                <FormattedMessage id='landing_new_kot_main_page.change_kot_type' />
                                            </Typography>
                                            <FormControl required={true} className={classes.field}>
                                                <Select
                                                    value={state.kotType}
                                                    onChange={handleChange}
                                                    name='kotType'
                                                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                                                    MenuProps={selectPaperStyle()}
                                                >
                                                    {kotTypeCollection.map((e) => {
                                                        return <MenuItem key={e.value.toString()} value={e.value} >
                                                            <Typography variant='body2'>
                                                                {e.name}
                                                            </Typography>
                                                        </MenuItem>
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container spacing={5}>
                                                <Grid item xs={6}>
                                                    <Typography
                                                        variant='h4'
                                                        component='h3'
                                                        className={classes.titleField}
                                                        color='primary'
                                                    >
                                                        <FormattedMessage id='landing_new_kot_main_page.kot_rent' />
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        required={true}
                                                        type='number'
                                                        defaultValue={state.rent}
                                                        onBlur={handleChange}
                                                        name='rent'
                                                        autoComplete='on'
                                                        InputProps={{
                                                            inputProps: { min: 0 },
                                                            classes: { input: classes.inputfield }
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography
                                                        variant='h4'
                                                        component='h3'
                                                        className={classes.titleField}
                                                        color='primary'
                                                    >
                                                        <FormattedMessage id='landing_new_kot_main_page.kot_charges' />
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        required={true}
                                                        type='number'
                                                        defaultValue={state.charges}
                                                        onBlur={handleChange}
                                                        name='charges'
                                                        autoComplete='on'
                                                        InputProps={{
                                                            inputProps: { min: 0 },
                                                            classes: { input: classes.inputfield }
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box mb={2}>
                                                <Typography
                                                    variant='h4'
                                                    component='h3'
                                                    className={classes.titleField}
                                                    color='primary'
                                                >
                                                    <FormattedMessage id='landing_new_kot_main_page.kot_rent_charges_include' />
                                                </Typography>
                                            </Box>
                                            <Box m={1}>
                                                <FormControl component='fieldset'>
                                                    <FormGroup>
                                                        <Box className={classes.checkBoxList} >
                                                            <FormattedMessage id='landing_new_kot_main_page.rent_include.internet'>
                                                                {label =>
                                                                    <FormControlLabel
                                                                        control={<CustomCheckbox checked={state.internet} onChange={handleChangeCheckBoxes} name='internet' />}
                                                                        label={label}
                                                                        classes={{ label: classes.labelStyle }}
                                                                    />
                                                                }
                                                            </FormattedMessage>
                                                            <FormattedMessage id='landing_new_kot_main_page.rent_include.cleaning'>
                                                                {label =>
                                                                    <FormControlLabel
                                                                        control={<CustomCheckbox checked={state.cleaning} onChange={handleChangeCheckBoxes} name='cleaning' />}
                                                                        label={label}
                                                                        classes={{ label: classes.labelStyle }}
                                                                    />
                                                                }
                                                            </FormattedMessage>

                                                            <FormattedMessage id='landing_new_kot_main_page.rent_include.water'>
                                                                {label =>
                                                                    <FormControlLabel
                                                                        control={<CustomCheckbox checked={state.water} onChange={handleChangeCheckBoxes} name='water' />}
                                                                        label={label}
                                                                        classes={{ label: classes.labelStyle }}
                                                                    />
                                                                }
                                                            </FormattedMessage>
                                                            <FormattedMessage id='landing_new_kot_main_page.rent_include.gaz_elec'>
                                                                {label =>
                                                                    <FormControlLabel
                                                                        control={<CustomCheckbox checked={state.gasElectricity} onChange={handleChangeCheckBoxes} name='gasElectricity' />}
                                                                        label={label}
                                                                        classes={{ label: classes.labelStyle }}
                                                                    />
                                                                }
                                                            </FormattedMessage>

                                                        </Box>
                                                    </FormGroup>
                                                </FormControl>
                                            </Box>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} xl={6}>
                            <Grid container alignItems='flex-start'>
                                <Grid item xs={12} md={11}>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <SectionTitle
                                                step={2}
                                                title={<FormattedMessage id='landing_new_kot_main_page.location' />}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant='h4'
                                                component='h3'
                                                className={classes.titleField}
                                                color='primary'
                                            >
                                                <FormattedMessage id='landing_new_kot_main_page.address' />
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                required={true}
                                                type={'text'}
                                                defaultValue={state.address}
                                                onBlur={handleChange}
                                                name='address'
                                                autoComplete={'on'}
                                                InputProps={{ classes: { input: classes.inputfield } }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={5}>
                                                <Grid item xs={6}>
                                                    <Typography
                                                        variant='h4'
                                                        component='h3'
                                                        className={classes.titleField}
                                                        color='primary'
                                                    >
                                                        <FormattedMessage id='landing_new_kot_main_page.postal_code' />
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        required
                                                        type={'number'}
                                                        defaultValue={state.zip}
                                                        onBlur={handleChange}
                                                        name='zip'
                                                        autoComplete={'on'}
                                                        InputProps={{ classes: { input: classes.inputfield } }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography
                                                        variant='h4'
                                                        component='h3'
                                                        className={classes.titleField}
                                                        color='primary'
                                                    >
                                                        <FormattedMessage id='landing_new_kot_main_page.city' />
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        required
                                                        type={'text'}
                                                        defaultValue={state.city}
                                                        onBlur={handleChange}
                                                        name='city'
                                                        autoComplete={'on'}
                                                        InputProps={{ classes: { input: classes.inputfield } }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Box py={5} mt={2}>
                        <Grid container spacing={6}>
                            <Grid item xs={12} md={6} lg={6} xl={6}>
                                <Grid container alignItems='flex-start'>
                                    <Grid item md={11} xs={12}>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12}>
                                                <SectionTitle
                                                    step={3}
                                                    title={<FormattedMessage id='landing_new_kot_main_page.details' />}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant='h4'
                                                    component='h3'
                                                    className={classes.titleField}
                                                    color='primary'
                                                >
                                                    <FormattedMessage id='landing_new_kot_main_page.description' />
                                                </Typography>
                                                <FormattedMessage id='landing_new_kot_main_page.placeholder.kot_description'>
                                                    {placeholder =>
                                                        <TextField
                                                            fullWidth
                                                            multiline
                                                            rows={10}
                                                            variant='outlined'
                                                            placeholder={placeholder}
                                                            defaultValue={state.description}
                                                            onBlur={handleChange}
                                                            name='description'
                                                            InputProps={{ classes: { input: classes.inputfield } }}
                                                        />
                                                    }
                                                </FormattedMessage>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant='h4'
                                                    component='h3'
                                                    className={classes.titleField}
                                                    color='primary'
                                                >
                                                    <FormattedMessage id='landing_new_kot_main_page.proximity' />
                                                </Typography>
                                                <FormattedMessage id='landing_new_kot_main_page.placeholder.kot_proximity'>
                                                    {placeholder =>
                                                        <TextField
                                                            fullWidth
                                                            multiline
                                                            rows={10}
                                                            variant='outlined'
                                                            placeholder={placeholder}
                                                            defaultValue={state.proximity}
                                                            onBlur={handleChange}
                                                            name='proximity'
                                                            InputProps={{ classes: { input: classes.inputfield } }}
                                                        />
                                                    }
                                                </FormattedMessage>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='h4'
                                                    component='h3'
                                                    className={classes.titleField}>
                                                    <FormattedMessage id='landing_new_kot_main_page.others_info' />
                                                </Typography>
                                                <FormattedMessage id='landing_new_kot_main_page.placeholder.kot_other_infos'>
                                                    {placeholder =>
                                                        <TextField
                                                            fullWidth
                                                            multiline
                                                            rows={10}
                                                            variant='outlined'
                                                            placeholder={placeholder}
                                                            defaultValue={state.otherInfo}
                                                            onBlur={handleChange}
                                                            name='otherInfo'
                                                            InputProps={{ classes: { input: classes.inputfield } }}
                                                        />
                                                    }
                                                </FormattedMessage>
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6} xl={6}>
                                <Grid container spacing={5}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12}>
                                                <Hidden only={['xs', 'sm']}>
                                                    <div className={classes.spacing} />
                                                </Hidden>
                                                <Typography
                                                    variant='h4'
                                                    component='h3'
                                                    className={classes.titleField}
                                                    color='primary'
                                                >
                                                    <FormattedMessage id='landing_new_kot_main_page.availability' />
                                                </Typography>
                                                <FormControl required={true} className={classes.field}>
                                                    <Select
                                                        value={state.available}
                                                        onChange={handleChange}
                                                        name='available'
                                                        autoWidth
                                                        inputProps={{ MenuProps: { disableScrollLock: true } }}
                                                        MenuProps={selectPaperStyle()}
                                                    >
                                                        {kotDurationCollection.map((e) => {
                                                            return <MenuItem
                                                                key={e.value.toString()}
                                                                value={e.value}>
                                                                <Typography variant='body2'>
                                                                    {e.name}
                                                                </Typography>
                                                            </MenuItem>
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {state.available === 'other' &&
                                                <Grid item xs={12}>
                                                    <Typography variant='h4'
                                                        component='h3'
                                                        className={classes.titleField}>
                                                        <FormattedMessage id='landing_new_kot_main_page.other' />
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        multiline
                                                        rows={4}
                                                        variant='outlined'
                                                        placeholder='Indiquez quand le kot est disponible pour la location.'
                                                        defaultValue={state.availableOther}
                                                        onBlur={handleChange}
                                                        name='availableOther'
                                                        InputProps={{ classes: { input: classes.inputfield } }}
                                                    />

                                                </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <Typography variant='h4'
                                            component='h3'
                                            className={classes.titleField}>
                                            <FormattedMessage id='landing_new_kot_main_page.state' />
                                        </Typography>
                                        <FormControl required={true} className={classes.field}>
                                            <Select
                                                value={state.condition}
                                                onChange={handleChange}
                                                name='condition'
                                                MenuProps={selectPaperStyle()}
                                                inputProps={{ MenuProps: { disableScrollLock: true } }}
                                            >
                                                {kotConditionCollection.map((e) => {
                                                    return <MenuItem key={e.value.toString()} value={e.value}>
                                                        <Typography variant='body2'>
                                                            {e.name}
                                                        </Typography>
                                                    </MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant='h4'
                                            component='h3'
                                            className={classes.titleField}
                                            color='primary'
                                        >
                                            <FormattedMessage id='landing_new_kot_main_page.number_rooms_available' />
                                        </Typography>
                                        <FormControl className={classes.field}>
                                            <Select
                                                value={state.availableRooms}
                                                onChange={handleChange}
                                                name='availableRooms'
                                                MenuProps={selectPaperStyle()}
                                                inputProps={{ MenuProps: { disableScrollLock: true } }}
                                            >
                                                {[...Array(10)].map((elem, index) => (
                                                    <MenuItem value={index + 1} key={index}>
                                                        <Typography variant='body2'>{index + 1}</Typography>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box mb={2}>
                                            <Typography
                                                variant='h4'
                                                component='h3'
                                                className={classes.titleField}
                                                color='primary'
                                            >
                                                <FormattedMessage id='landing_new_kot_main_page.layout' />
                                            </Typography>
                                        </Box>
                                        <Box m={1}>
                                            <FormControl component='fieldset'>
                                                <FormGroup>
                                                    <FormattedMessage id='landing_new_kot_main_page.layout_include.furniture'>
                                                        {label =>
                                                            <FormControlLabel
                                                                control={<CustomCheckbox checked={state.furnished} onChange={handleChangeCheckBoxes} name='furnished' />}
                                                                label={label}
                                                                classes={{ label: classes.labelStyle }}
                                                            />
                                                        }
                                                    </FormattedMessage>
                                                    <FormattedMessage id='landing_new_kot_main_page.layout_include.terrace'>
                                                        {label =>
                                                            <FormControlLabel
                                                                control={<CustomCheckbox checked={state.terrace} onChange={handleChangeCheckBoxes} name='terrace' />}
                                                                label={label}
                                                                classes={{ label: classes.labelStyle }}
                                                            />
                                                        }
                                                    </FormattedMessage>
                                                    <FormattedMessage id='landing_new_kot_main_page.layout_include.private_bathroom'>
                                                        {label =>
                                                            <FormControlLabel
                                                                control={<CustomCheckbox checked={state.privateBathroom} onChange={handleChangeCheckBoxes} name='privateBathroom' />}
                                                                label={label}
                                                                classes={{ label: classes.labelStyle }}
                                                            />
                                                        }
                                                    </FormattedMessage>

                                                    <FormattedMessage id='landing_new_kot_main_page.layout_include.private_kitchen'>
                                                        {label =>
                                                            <FormControlLabel
                                                                control={<CustomCheckbox checked={state.privateKitchen} onChange={handleChangeCheckBoxes} name='privateKitchen' />}
                                                                label={label}
                                                                classes={{ label: classes.labelStyle }}
                                                            />
                                                        }
                                                    </FormattedMessage>
                                                    <FormattedMessage id='landing_new_kot_main_page.layout_include.garden'>
                                                        {label =>
                                                            <FormControlLabel
                                                                control={<CustomCheckbox checked={state.garden} onChange={handleChangeCheckBoxes} name='garden' />}
                                                                label={label}
                                                                classes={{ label: classes.labelStyle }}
                                                            />
                                                        }
                                                    </FormattedMessage>
                                                </FormGroup>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box py={5}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container alignItems='flex-start'>
                                    <Grid item xs={12} md={11}>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12}>
                                                <SectionTitle
                                                    step={4}
                                                    title={<FormattedMessage id='landing_new_kot_main_page.kot_photo' />}
                                                />
                                                <Box fontStyle='italic' mt={1} mb={4}>
                                                    <Typography variant='subtitle1'>
                                                        <FormattedMessage id='landing_new_kot_main_page.illustrate_kot_offer' />
                                                    </Typography>
                                                </Box>
                                                <UploadImageV2 imageArray={imageArray} setImageArray={setImageArray} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box py={5}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container alignItems='flex-start'>
                                    <Grid item xs={12}>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12}>
                                                <Box display={'flex'}>
                                                    <SectionTitle
                                                        step={5}
                                                        title={<FormattedMessage id='landing_new_kot_main_page.energy_certificate' />}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Typography
                                                    variant='h2'
                                                    component='h3'
                                                    className={classes.sectionTitleField}
                                                    color='primary'
                                                >
                                                    <FormattedMessage id='landing_new_kot_main_page.peb_question' />
                                                </Typography>
                                                <FormControl required={true} className={classes.field}>
                                                    <Select
                                                        value={state.pebBoolean}
                                                        onChange={handleChange}
                                                        name='pebBoolean'
                                                        MenuProps={selectPaperStyle()}
                                                        inputProps={{ MenuProps: { disableScrollLock: true } }}
                                                    >
                                                        <MenuItem value='yes'>
                                                            <Typography variant='body2'>
                                                                <FormattedMessage id='landing_new_kot_main_page.answ_yes' />
                                                            </Typography>
                                                        </MenuItem>
                                                        <MenuItem value='no'>
                                                            <Typography variant='body2'>
                                                                <FormattedMessage id='landing_new_kot_main_page.answ_no' />
                                                            </Typography>
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            {state.pebBoolean === 'yes' &&
                                                         <Grid item xs={12} md={4}>
                                                             <Typography
                                                                 variant='h2'
                                                                 component='h3'
                                                                 className={classes.sectionTitleField}
                                                                 color='primary'
                                                             >
                                                                 <FormattedMessage id='landing_new_kot_main_page.result_peb' />
                                                             </Typography>
                                                             <FormControl required={true} className={classes.field}>
                                                                 <Select
                                                                     value={state.pebCertificate}
                                                                     onChange={handleChange}
                                                                     name='pebCertificate'
                                                                     MenuProps={selectPaperStyle()}
                                                                     inputProps={{ MenuProps: { disableScrollLock: true } }}
                                                                 >
                                                                     {kotEnergyCertificateCollection.map((e, key) => {
                                                                         return <MenuItem key={key.toString()} value={e}>
                                                                             <Typography variant='body2'>
                                                                                 {e}
                                                                             </Typography>
                                                                         </MenuItem>
                                                                     })}
                                                                 </Select>
                                                             </FormControl>
                                                         </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box py={5} pb={10} display={'flex'} justifyContent={'flex-end'} >
                        <Button
                            endIcon={<IconFactory icon='forward'/>}
                            type='submit'
                            color='secondary'
                        >
                            <FormattedMessage id={buttonText} />
                        </Button>
                    </Box>
                </form>
            </PaperFormWrapper>
        </PageWrapper>
    )
}

export default NewKot
