import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    main: {
        '& span': {
            fontWeight: 'bold'
        }
    },
    greyTag: {
        backgroundColor: 'var(--light-grey)',
        padding: '0px 6px',
        borderRadius: 8,
    },
    infoSection: {
        marginLeft: 12,
        backgroundColor: 'var(--light-grey)',
        borderRadius: 12,
        padding: 12,
        width: 'fit-content'
    },
    offerAndDemand: {
        display: 'flex',
        '& li': {
            lineHeight: 2
        }
    }
}))
