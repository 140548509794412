import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import IconFactory from 'icons/IconFactory/IconFactory'
import BoostCompanyJobModalStep1View from './BoostCompanyJobModalStep1View'
import PaymentCompanyJobModalStep2View from './PaymentCompanyJobModalStep2View'
import { useStyles } from './BoostCompanyJobModalView_style'

import useTheme from '@material-ui/core/styles/useTheme'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'

const BoostCompanyJobModalView = (props) => {
    const { creditCount, closeModal, loading, boostJob, step, setStep, user, job, buyCredits, selectedPack, setSelectedPack } = props
    const classes = useStyles()
    const theme = useTheme()

    return (
        <Dialog
            maxWidth={step === 1 ? 'sm' : 'md'}
            open
            onClose={closeModal}
            disableScrollLock
            aria-labelledby="responsive-dialog-delete"
            fullWidth
            PaperProps={{ className: classes.dialogContainer }}
        >
            <DialogTitle onClose={closeModal}>
                <Typography variant='h2' style={{ marginTop: '1rem' }}>
                    <FormattedMessage id="boost_popup.boost_title" />:&nbsp;
                    <span>{job.title}</span>
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={closeModal}
                    style={{
                        position: 'absolute',
                        right: theme.spacing(1),
                        top: 5,
                        color: theme.palette.grey[500]
                    }}
                >
                    <IconFactory icon='close' />
                </IconButton>
            </DialogTitle>
            {step === 1 &&
                <BoostCompanyJobModalStep1View
                    creditCount={creditCount}
                    loading={loading}
                    closeModal={closeModal}
                    boostJob={boostJob}
                    setStep={setStep}
                />
            }
            {step === 2 &&
                <PaymentCompanyJobModalStep2View
                    creditCount={creditCount}
                    loading={loading}
                    closeModal={closeModal}
                    setStep={setStep}
                    user={user}
                    buyCredits={buyCredits}
                    selectedPack={selectedPack}
                    setSelectedPack={setSelectedPack}
                />
            }
        </Dialog>
    )
}

export default injectIntl(BoostCompanyJobModalView)
