import React from 'react'

import ProfileSimpleInfoView from './ProfileSimpleInfoView'

const ProfileSimpleInfo = (props) => {
    const { student, setStudent } = props

    const handleChangeTextField = prop => event => {
        setStudent({
            ...student,
            [prop]: event.target.value
        })
    }

    return <ProfileSimpleInfoView
        handleChangeTextField={handleChangeTextField}
    />
}

export default ProfileSimpleInfo
