import React from 'react'
import { Box } from '@material-ui/core'

import { useStyles } from './JobCardChips_style'
import JobCardChip from './JobCardChip'
import useJobIconColor from 'hooks/useJobIconColor'

const JobCardChips = (props) => {
    const classes = useStyles()
    const { job, showJobType } = props

    return (
        <Box className={classes.chips}>
            {showJobType && <JobCardChip
                name={job.type}
                key={job.type}
                background={useJobIconColor(job.type)}
            />}
            {job.job_tags.map(jobTag =>
                <JobCardChip name={jobTag} key={jobTag} />
            )}
        </Box>
    )
}

export default JobCardChips
