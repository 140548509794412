import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './AlertStickyButton_style'
import Box from '@material-ui/core/Box'

import Button from 'shared/components/Button/Button.jsx'

const AlertStickyButton = (props) => {
    const classes = useStyles()
    const { stickyAlertBtn, type } = props

    return (
        <Box className={classes.buttonContainer}>
            {/* <FilledButton
                newStyle
                color="initial"
                className={classes.button}
                onClick={stickyAlertBtn}
                name={<FormattedMessage id={`item_offers.${type}.save_my_search`} />}
                startIcon={<IconFactory icon='mail' />}
            /> */}
            <Button
                variant='cta-primary'
                size='small'
                className={classes.button}
                onClick={stickyAlertBtn}
            >
                <FormattedMessage id={`item_offers.${type}.save_my_search`} />
            </Button>
        </Box>
    )
}

export default AlertStickyButton
