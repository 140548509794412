import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './NavigationUserProfileIcon_style'
import { useStyles as useDarkModeStyles } from './NavigationUserProfileIconDarkMode_style'
import {
    Badge, Box, Button, Divider,
    Grow, MenuItem, MenuList,
    Paper, Popper, Typography, useTheme
} from '@material-ui/core'

import IconFactory from 'icons/IconFactory/IconFactory'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import ProfileHelper from 'assets/ProfileHelper'
import CustomButton from 'shared/components/Button/Button.jsx'

const NavigationUserProfileIcon = (props) => {
    const { user, darkMode } = props
    const classes = darkMode ? useDarkModeStyles() : useStyles()
    const [anchorModal, setAnchorModal] = useState(null)
    const [openUserModal, setOpenUserModal] = useState(false)
    const theme = useTheme()

    const openModal = (event) => {
        setAnchorModal(event.currentTarget)
        setOpenUserModal(true)
    }

    const closeModal = () => {
        setAnchorModal(null)
        setOpenUserModal(false)
    }

    const getCompanyShowUrl = () => {
        if (user.parent_company) {
            return `companies/${user.parent_company.url}`
        } else {
            return `companies/${user.company_id}`
        }
    }

    const renderMenuListFromUserType = () => {
        switch (user.type) {
        case 'employer':
            return (<>
                {user.admin && renderMenuItem('dashboard', 'Admin', 'admin')}
                {renderMenuItem('company', 'landing_connected_user.my_company_page', getCompanyShowUrl())}
                {renderMenuItem('ads', 'landing_connected_user.my_ads', 'users/company_jobs')}
                {(user.show_articles || user.has_articles) &&
                    renderMenuItem('articles', 'landing_connected_user.my_articles', 'users/articles')
                }
                {renderMenuItem('chat', 'landing_connected_user.my_messages', 'messages')}
                <Divider color='var(--black)' orientation="horizontal" />
                {renderMenuItem('settings', 'landing_connected_user.my_settings', 'users/settings/edit')}
                {renderMenuItem('sign-out', 'landing_connected_user.disconnect', 'sign_out')}
            </>)
        case 'student':
            return (<>
                {renderMenuItem('dashboard', 'landing_connected_user.dashboard', 'users/dashboard')}
                {renderMenuItem('chat', 'landing_connected_user.my_messages', 'messages')}
                {renderMenuItem('heart', 'landing_connected_user.my_favourites', 'users/favourites')}
                {renderMenuItem('alerts', 'landing_connected_user.my_alerts', 'users/alerts')}
                {renderMenuItem('rocket', 'landing_connected_user.my_candidacies', 'users/candidacies')}
                {user.is_student_kot_owner &&
                    renderMenuItem('kot', 'landing_connected_user.my_kots', 'users/kots')
                }
                {renderMenuListCommon()}
            </>)
        case 'kot_owner':
            return (<>
                {renderMenuItem('kot', 'landing_connected_user.my_kots', 'users/kots')}
                {renderMenuItem('chat', 'landing_connected_user.my_messages', 'messages')}
                {renderMenuListCommon()}
            </>)
        }
    }

    const renderMenuListCommon = () => {
        return (<>
            <Divider color='var(--black)' orientation="horizontal" />
            {renderMenuItem('account', 'landing_connected_user.my_profile', 'users/profile/edit')}
            {renderMenuItem('settings', 'landing_connected_user.my_settings', 'users/settings/edit')}
            {renderMenuItem('sign-out', 'landing_connected_user.disconnect', 'sign_out')}
        </>)
    }

    const renderMenuItem = (icon, formattedMessageID, page) => {
        return (
            <RouteMapLink page={page}>
                <MenuItem className={classes.menuItem}>
                    <IconFactory icon={icon} className={classes.menuItemIcon} />
                    <Typography variant='h5' component='div'>
                        <FormattedMessage id={formattedMessageID} />
                    </Typography>
                </MenuItem>
            </RouteMapLink>
        )
    }

    return (
        <Box onMouseLeave={closeModal}>
            {user.type === 'student'
                ? <RouteMapLink page={ProfileHelper.GetProfileUrl(user.type)}>
                    <Button
                        ref={anchorModal}
                        className={classes.userProfileIconButton}
                        onMouseOver={openModal}
                        disableRipple
                    >
                        <Badge
                            badgeContent={user.unseen_messages > 20 ? '20+' : user.unseen_messages}
                            color="error"
                            overlap="circle"
                            className={classes.badge}
                        >
                            <IconFactory icon='account' className={classes.userProfileIcon} color={theme.palette.fg.white} />
                        </Badge>
                    </Button>
                </RouteMapLink>
                : <RouteMapLink page={ProfileHelper.GetProfileUrl(user.type)}>
                    <CustomButton variant='tertiary' size='small' onMouseOver={openModal}>
                        {ProfileHelper.GetProfileName(user)}
                    </CustomButton>
                </RouteMapLink>
            }
            <Popper
                open={openUserModal}
                anchorEl={anchorModal}
                placement='bottom-end'
                transition disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} >
                        <Paper className={classes.paperMenu}>
                            <MenuList
                                autoFocusItem={Boolean(anchorModal)}
                                className={classes.menuList}
                            >
                                {renderMenuListFromUserType()}
                            </MenuList>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    )
}

export default NavigationUserProfileIcon
