import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    subtitle2: theme.typography.h6,
    bannerText: {
        // color: 'white'
        margin: '50px 0px',
        textAlign: 'center'
    },
    similarJobs: {
        width: '100%'
    },
    similarJobsBox: {
        paddingTop: 40,
        paddingBottom: 24,
        // backgroundColor: 'var(--super-dark-grey)'
    },
    similarArticlesBox: {
        paddingTop: 24,
        paddingBottom: 80,
        // backgroundColor: 'var(--super-dark-grey)'
    }
}))
