import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.fg['black'],
        fontSize: '18px',
        lineHeight: '28px'
    },
    inputLabel: {
        color: theme.palette.fg['black'],
        fontFamily: 'GreedStandard-Medium',
        lineHeight: '17px'
    },
    input: {
        paddingBottom: '12px'
    },
    inputUnderline: {
        '&::after': {
            borderBottom: `0.5px solid ${theme.palette.fg['greySlate']}`
        },
    },
    helperText: {
        color: theme.palette.fg['greyMedium']
    },
    button: {
        textTransform: 'capitalize !important'
    }
}))
