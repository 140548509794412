import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        backgroundImage: 'url("/shines.png")',
        backgroundSize: '25%',
        backgroundPosition: '75% 27%',
        backgroundRepeat: 'no-repeat',
        borderRadius: 'var(--border-radius)',
        width: '60vw',
        maxWidth: 'none',
        height: '40vh',
        maxHeight: 'none',
        display: 'flex',
        flexDirection: 'column'
    },
    dialogContent: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        overflow: 'hidden'
    },
    number: {
        fontFamily: 'GreedWide-Bold',
        fontSize: '2.7rem',
        width: '100px',
        height: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '0.6%',
        marginTop: '0.6%',
        color: '#a000ff'
    },
    dialogActions: {
        display: 'flex',
        justifyContent: 'center',
        padding: '15px',
        backgroundColor: '#a000ff'
    },
    dialogContentText: {
        fontFamily: "'GreedStandard-Medium', sans-serif",
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: '21px'
    },
    disclaimer: {
        color: 'black',
        display: 'flex',
        marginTop: '6%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: '12px'
    },
    mainText: {
        fontFamily: 'GreedWide-Bold'
    },
    button: {
        backgroundColor: 'white',
        color: 'black',
        '&:hover': {
            backgroundColor: 'white'
        }
    }
}))
