import React from 'react'
import AppMyItemSelectionIndexMain from './Components/AppMyKotItemSelectionIndexMain'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import SharedTabs from 'components/SharedTabs/SharedTabs'
import AttentionBanner from 'components/AttentionBanner/AttentionBanner'

export default class MyKotIndex extends React.Component {
    render () {
        return (
            <PageWrapper user={this.props.user} disableFooterLists>
                <SharedTabs user={this.props.user} currentTab='users/kots' />
                <AttentionBanner user={this.props.user} />
                <AppMyItemSelectionIndexMain
                    props={this.props}
                />
            </PageWrapper>
        )
    }
}
