import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    kotDescription: {
        height: 'auto',
        width: '100%',
        /* display: 'flex', */
        padding: 0,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            borderTopLeftRadius: '30px',
            borderTopRightRadius: '30px',
            fontSize: '2vh',
            alignItems: 'center'
        }
    },
    kotDescriptionText: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            margin: 0
        }
    },
    descriptionTextRow: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
            height: 'auto'
        }
    },
    kotDescriptionTitle: {
        marginTop: 24,
        marginBottom: 8
    },
    kotDescriptionContent: {
        margin: 0,
        whiteSpace: 'pre-wrap'
    },
    titleSection: {
        marginTop: 65
    }
}))
