/* eslint-disable react/prop-types */
import React from 'react'
import RouteMap from 'assets/RouteMap.js'
import AdBanner from 'components/AdBanner/AdBanner'

const HalfPageAds = (props) => {
    const { ads } = props
    if (!ads || ads.length === 0) return null

    const locale = RouteMap.GetLocaleFromUrl()
    const newAds = []
    const places = []
    const localizedAds = ads.filter(ad => ad.langage === locale)
    for (const [adIndex, ad] of localizedAds.entries()) {
        for (const [placeIndex, place] of ad.places.entries()) {
            if (place.includes('Show_Half page')) places.push(place)
        }
    }
    places.sort()
    for (const [index, place] of places.entries()) {
        newAds.push(
            <AdBanner
                key='ad-banner'
                ads={localizedAds}
                place={place}
                device='desktop'
                width={300}
            />
        )
    }
    return newAds
}

export default HalfPageAds
