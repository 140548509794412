import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    aboutPageBanner: {
        minHeight: '66px',
        background: 'var(--columbia-blue)',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 12,
        marginTop: 20,
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    titlePage: {
        color: 'black',
        padding: '16px 0',
        [theme.breakpoints.up('sm')]: {
            textAlign: 'center'
        }
    },
    subTitle: {
        textAlign: 'center',
        color: 'black',
        paddingBottom: 10
    }
}))
