import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    createJobNudgeContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '40px',
        padding: '60px 0px'
    },
    createJobNudge: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        alignItems: 'center'
    }
}))
