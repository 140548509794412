import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    articleCard: {
        flex: '0 0 32%',
        background: 'white',
        borderRadius: 20,
        padding: 40,
        minHeight: 330,
        display: 'flex',
        gap: 28,
        flexDirection: 'column',
        justifyContent: 'space-between',
        border: `1px solid ${theme.palette.bg.greyLight}`,
        '&:hover': {
            transition: 'all 0.3s ease',
            border: `1px solid ${theme.palette.fg.black}`,
            boxShadow: `4px 4px 0px 0px ${theme.palette.bg.black}`,
        },
        [theme.breakpoints.down('md')]: {
            flex: '0 0 48%',
        }
    },
    articleDate: {
        fontSize: 12,
        color: theme.palette.fg.greyDark
    },
    articleText: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20
    },
    articleCardTitle: {
        fontSize: 22
    },
    biggerFont: {
        // fontSize: '16px !important'
    },
    articleCardDescription: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12
    },
    articleReadMore: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
    readMoreText: {
        fontSize: 16,
        color: theme.palette.fg.black
    },
    employerArticleCTA: {
        display: 'flex',
        justifyContent: 'center'
    },
    linkIconContainer: {
        width: '24px',
        height: '24px',
        borderRadius: '50px',
        backgroundColor: theme.palette.bg.black,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))
