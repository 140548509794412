import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        position: 'relative',
        width: '85%',
        padding: '21px',
        border: `1px solid ${theme.palette.fg['silver']}`,
        borderRadius: '20px',
        backgroundColor: theme.palette.bg['white'],
        [theme.breakpoints.up('sm')]: {
            width: '484px',
            padding: '41px'
        },
    },

    closeIconButton: {
        position: 'absolute',
        top: '26px',
        right: '23px',
        padding: '4px',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },

    titleContainer: {
        padding: '0px',
        paddingBottom: '13px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '18px'
    },

    envelopeIconContainer: {
        width: '64px',
        height: '64px',
        borderRadius: '50%',
        backgroundColor: theme.palette.bg['yellow'],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    title: {
        color: theme.palette.fg['black'],
        fontSize: '32px',
        lineHeight: '40px',
        textTransform: 'uppercase',
        '& .greedStandardBold': {
            fontFamily: 'GreedStandard-Bold',
        },
        '& .greedWideHeavy': {
            fontFamily: 'GreedWide-Heavy',
        },
        '& .greedStandardRegular': {
            fontFamily: 'GreedStandard-Regular',
        },
        '& .greedWideRegular': {
            fontFamily: 'GreedWide-Regular',
        }
    },

    modalContent: {
        width: '100%',
        padding: '0px'
    },

    description: {
        paddingBottom: '29px',
        color: theme.palette.fg['black'],
        fontSize: '16px',
        lineHeight: '20px'
    },

    boxTags: {
        paddingBottom: '45px',
        display: 'flex',
        columnGap: '8px',
        rowGap: '7px',
        flexWrap: 'wrap'
    },

    tagFilter: {
        height: '20px',
        borderRadius: '50px',
        padding: '0px 12px',
        backgroundColor: theme.palette.bg['greyLight'],
        color: theme.palette.fg['black'],
        fontFamily: 'GreedStandard-Medium',
        fontSize: '10px',
        lineHeight: '18px',

        '& .MuiChip-label': {
            padding: '0px'
        }
    },

    submitButtonContainer: {
        width: '100%',
        paddingRight: '4px'
    },

    submitButton: {
        width: '100% !important',
    },

    footerText: {
        paddingTop: '39px',
        color: theme.palette.fg['black'],
        fontSize: '10px',
        lineHeight: '12px'
    }

}))
