import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    aboutPageBanner: {
        minHeight: '66px',
        background: 'var(--background-yellow)',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2.5rem'
    },
    titlePage: {
        padding: '1rem 0',
        [theme.breakpoints.up('sm')]: {
            textAlign: 'center'
        }
    },
    categoryContainer: {
        backgroundColor: 'var(--background-silver)',
        pointerEvents: 'none',
        border: 'solid var(--foreground-silver) 1px',
        borderRadius: '10px !important',
        boxShadow: 'none',
        '&::before': {
            height: 0
        }
    },
    content: {
        marginBottom: '5rem'
    },
    answerContainer: {
        opacity: '0.9'
    },
    text: {
        fontSize: 16
    },
    studentFont: {
        fontFamily: theme.typography.h1.fontFamily
    },
    accordion: {
        border: 'solid var(--foreground-silver) 1px',
        borderRadius: '10px !important',
        boxShadow: 'none',
        margin: '4px 0',
        padding: 4,
        '&::before': {
            height: 0
        }
    },
    videoBox: {
        maxWidth: 560,
        width: '100%'
    }
}))
