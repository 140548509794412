import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    selectedTagsContainer: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        padding: '10px 20px 0px 20px',
    },

    selectedTag: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: 50,
        backgroundColor: theme.palette.bg['purple'],
        color: theme.palette.fg['white'],
        padding: '0 16px',
        marginRight: 8,
        marginBottom: 8,
        boxShadow: `2px 2px 0px 0px ${theme.palette.bg['black']}`,
        fontFamily: 'GreedStandard-Medium',
        fontSize: '14px',
        lineHeight: '24px',
        '&:hover': {
            opacity: 0.8,
        },
    },

    selectedTagSmall: {
        fontSize: 12,
        height: 25,
        padding: '6px 8px',
    },

    tagCrossIcon: {
        marginRight: 10,
    },
}))
