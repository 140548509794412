import React, { useState, useEffect } from 'react'
import CompanyAPI from 'api/CompanyAPI'
import CompanyCard from 'cards/CompanyCard/CompanyCard'
import RouteMap from 'assets/RouteMap'
import RecommendedItemsContainer from 'components/RecommendedItemsContainer/RecommendedItemsContainer'

const RecruitingCompanies = (props) => {
    const { itemType } = props
    const [recruitingCompanies, setRecruitingCompanies] = useState([])

    useEffect(() => {
        getRecruitingCompanies()
    }, [])

    const getRecruitingCompanies = () => {
        const data = {
            amount: 3
        }
        CompanyAPI.GetRecruitingCompanies(data).then((response) => {
            setRecruitingCompanies(response.data.recruiting_companies)
        })
    }

    const moreItemsURL = () => {
        let params = ''
        if (itemType === 'first_job') {
            params += 'job_type=first_job&'
        }
        if (itemType === 'internship') {
            params += 'job_type=internship&'
        }
        if (itemType === 'student_job') {
            params += 'job_type=student_job'
        }
        if (params.endsWith('&')) {
            params = params.slice(0, -1)
        }

        return RouteMap.Index('company', params)
    }

    if (recruitingCompanies.length === 0) return null

    return (
        <RecommendedItemsContainer
            moreItemsURL={moreItemsURL()}
            type='company'
            titleId='personal_dashboard.recruiting_companies.title'
            subTitleId='personal_dashboard.recruiting_companies.sub_title'
            recommendedItemType='company'
        >
            {recruitingCompanies.map((recruitingCompany) => {
                return (
                    <CompanyCard
                        key={`company-card-${recruitingCompany.id}`}
                        company={recruitingCompany}
                    />
                )
            })}
        </RecommendedItemsContainer>
    )
}

export default RecruitingCompanies
