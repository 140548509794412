/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import SimilarItemsView from 'components/SimilarItemsView/SimilarItemsView'
import ArticleAPI from 'api/ArticleAPI'

const getSimilarArticles = (props) => {
    const { user, category, baseItem } = props
    const [articles, setArticles] = useState([])

    useEffect(() => {
        getSimilarArticles()
    }, [])

    const getSimilarArticles = () => {
        const data = {
            category: category,
            count: 3
        }
        ArticleAPI.GetRecentArticles(data).then((response) => {
            setArticles(response.data)
        })
    }

    if (articles.length === 0) return null

    return (
        <SimilarItemsView
            type='article'
            items={articles}
            baseItem={baseItem}
            userType={user && user.type}
            titleId='transl_internship.job_show.card_slider.title'
            moreItemsId={`job_show_similar_items.see_more_articles.${category}`}
        />
    )
}

export default getSimilarArticles
