import React from 'react'

import { useStyles } from './CompanyFiltersView_style'
import { Box, Hidden } from '@material-ui/core'

import CityFilter from '../shared/CityFilter/CityFilter'
import TextFilter from '../shared/TextFilter/TextFilter'
import MultipleSelectFilter from '../shared/MultipleSelectFilter/MultipleSelectFilter'
import CollectionHandler from 'assets/CollectionHandler.js'
import CompanyFiltersModal from 'modals/FilterModals/CompanyFiltersModal/CompanyFiltersModal'
import MoreFilters from '../shared/MoreFilters/MoreFilters'
import ResetFilters from '../shared/ResetFilters/ResetFilters'
import CompanyQuickFilters from './CompanyQuickFilters'
import FiltersViewWrapper from 'shared/components/FiltersViewWrapper/FiltersViewWrapper.jsx'

const CompanyFiltersView = (props) => {
    const { debouncedFilterChange, offerCount, handleFilterChange, filters, setFilters, reset, handleInputSearchCity, searchCities, setSearchCities, citiesInput, setCitiesInput, showCompanyFiltersModal, setShowCompanyFiltersModal, selectedLocationTag, type } = props
    const classes = useStyles()

    const companySizeCollection = CollectionHandler.Get('companySizeCollection')
    const sectorCollection = CollectionHandler.Get('sectorCollection')
    const jobTypeCollection = CollectionHandler.Get('jobTypeCollection')

    return (
        <>
            <FiltersViewWrapper titleId='item_offers.company.page_title_v2'>
                <CompanyFiltersModal
                    showModal={showCompanyFiltersModal}
                    setShowModal={setShowCompanyFiltersModal}
                    debouncedFilterChange={debouncedFilterChange}
                    handleFilterChange={handleFilterChange}
                    filters={filters}
                    offerCount={offerCount}
                    handleInputSearchCity={handleInputSearchCity}
                    searchCities={searchCities}
                    citiesInput={citiesInput}
                    setSearchCities={setSearchCities}
                    setCitiesInput={setCitiesInput}
                    setFilters={setFilters}
                    reset={reset}
                />
                <Box className={classes.firstFiltersRow}>
                    {/* Company filter */}
                    <Box className={classes.companySearchFieldContainer}>
                        <TextFilter
                            debouncedFilterChange={debouncedFilterChange}
                            handleFilterChange={handleFilterChange}
                            filters={filters}
                            setFilters={setFilters}
                            filterTextId='search_bar.search.company_text'
                        />
                    </Box>
                    {/* City filter */}
                    <Hidden xsDown>
                        <Box className={classes.cityFieldContainer}>
                            <CityFilter
                                handleInputSearchCity={handleInputSearchCity}
                                searchCities={searchCities}
                                citiesInput={citiesInput}
                                setSearchCities={setSearchCities}
                                setCitiesInput={setCitiesInput}
                                setFilters={setFilters}
                                filters={filters}
                                width={500}
                            />
                        </Box>
                    </Hidden>
                    {/* Sector filter */}
                    <Hidden xsDown>
                        <Box className={classes.sectorFieldContainer}>
                            <MultipleSelectFilter
                                type='sector'
                                collection={sectorCollection}
                                filters={filters}
                                setFilters={setFilters}
                                width={500}
                                filterTextId='search_bar.sector'
                            />
                        </Box>
                    </Hidden>
                    {/* Company size filter */}
                    <Hidden smDown>
                        <Box className={classes.companySizeFieldContainer}>
                            <MultipleSelectFilter
                                type='companySize'
                                collection={companySizeCollection}
                                filters={filters}
                                setFilters={setFilters}
                                width={250}
                                filterTextId='search_bar.company_size'
                            />
                        </Box>
                    </Hidden>
                    {/* Job type filter */}
                    <Hidden mdDown>
                        <Box className={classes.jobTypeFieldContainer}>
                            <MultipleSelectFilter
                                type='jobType'
                                collection={jobTypeCollection}
                                filters={filters}
                                setFilters={setFilters}
                                width={250}
                                filterTextId='search_bar.job_type'
                            />
                        </Box>
                    </Hidden>
                    {/* More filters button */}
                    <Box className={classes.moreFiltersButtonContainer}>
                        <MoreFilters
                            setShowModal={setShowCompanyFiltersModal} filters={filters}
                        />
                    </Box>
                    {/* Reset filters button */}
                    <Hidden smDown>
                        <Box className={classes.resetFiltersButtonContainer}>
                            <ResetFilters reset={reset} />
                        </Box>
                    </Hidden>
                </Box>
            </FiltersViewWrapper>
            <CompanyQuickFilters filters={filters} setFilters={setFilters} />
        </>
    )
}

export default CompanyFiltersView
