import React from 'react'
import { Helmet } from 'react-helmet'

import ItemShowFactory from '../../router/pages/ItemShowFactory'
import PageWrapper from '../../shared/wrappers/PageWrapper/PageWrapper'
import PromotionIndex from '../../pages/PromotionIndex/PromotionIndex'

import { Switch, Route } from 'react-router-dom'

class PromotionRouter extends React.Component {
    render () {
        const pathname = window.location.pathname.replace(/%C3%A8/g, 'è').replace(/%C3%A9/g, 'é')
        const lang = pathname.match(/^\/([^\/]*)\//)[1]
        const basename = 'only-for-students'
        console.log(lang)
        return (
            <PageWrapper user={this.props.user}>
                <Switch>
                    <Route exact path={`/${basename}`}>
                        <PromotionIndex
                            promotions={this.props.promotions}
                            user={this.props.user}
                        />
                    </Route>
                    <Route
                        path={`/${basename}/:itemid/`}
                        render={(props) => (
                            <ItemShowFactory
                                {...props}
                                user={this.props.user}
                                renderType='promotion'
                                getAllInfoUrl='/api/v1/promotions/get_all_info?id='
                            />
                        )}
                    />
                </Switch>
            </PageWrapper>
        )
    }
}

export default PromotionRouter
