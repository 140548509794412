import React, { useState } from 'react'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'
import { DialogTitle, Typography, DialogContent, Box, FormControl, Select, MenuItem, DialogActions, Dialog, useMediaQuery, useTheme } from '@material-ui/core'

import Button from 'shared/components/Button/Button'
import KotOwnerAPI from 'api/KotOwnerAPI'
import CollectionHandler from 'assets/CollectionHandler.js'
import { useStyles } from '../KotListCardAlt_style'

// eslint-disable-next-line react/prop-types
const KotListCardDelete = ({ open, setOpen, kot, setIsHidden }) => {
    const [deleteKotReasons, setDeleteKotReasons] = useState('')
    const classes = useStyles()
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const deleteOfferCollection = CollectionHandler.Get('deleteOfferCollection')
    const handleClose = () => {
        setOpen(false)
    }
    const handleChangeSelect = (event) => {
        setDeleteKotReasons(event.target.value)
    }
    const handleSubmitDelete = () => {
    // eslint-disable-next-line no-undef
        axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
        const data = {
            // eslint-disable-next-line react/prop-types
            type: kot.kot_type,
            // eslint-disable-next-line react/prop-types
            id: kot.id,
            delete_reason: deleteKotReasons
        }

        KotOwnerAPI.KotOwnerKotDelete(data).then(
            (response) => {
                console.log(response)
                setIsHidden('none')
                handleClose()
            }).catch(
            (error) => {
                console.log(error)
            }
        )
    }
    return <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
        aria-labelledby="responsive-dialog-delete"
        PaperProps={{ className: classes.dialogContainer }}
    >
        <DialogTitle id="responsive-dialog-delete">
            <Typography variant={'h4'} component={'div'} color={'primary'}>
                <FormattedMessage id="delete_offer_popup.kot.title.one" />
            </Typography>
        </DialogTitle>
        <DialogContent>
            <Box mb={2} >
                <Typography variant={'body1'} component={'div'}>
                    <FormattedMessage id="delete_offer_popup.kot.text.one" />
                </Typography>
            </Box>

            <Box mb={2} >
                <Typography variant={'body2'} component={'div'}>
                    <FormattedMessage id="delete_offer_popup.kot.reason_for_delete" />
                </Typography>
                <FormControl fullWidth>
                    <Select
                        value={deleteKotReasons}
                        onChange={handleChangeSelect}
                    >
                        {
                            deleteOfferCollection.map(
                                (e, key) => {
                                    return <MenuItem key={key} value={e.value}>{e.name}</MenuItem>
                                }
                            )
                        }
                    </Select>
                </FormControl>
            </Box>
        </DialogContent>
        <DialogActions>
            <Button variant='secondary' size='small' onClick={handleClose}>
                <FormattedMessage id="delete_offer_popup.option_no" />
            </Button>
            <Button variant='cta-secondary' size='small'onClick={handleSubmitDelete} disabled={!deleteKotReasons} autoFocus>
                <FormattedMessage id="delete_offer_popup.option_yes" />
            </Button>
        </DialogActions>
    </Dialog>
}
export default KotListCardDelete
