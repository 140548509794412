import React, { useState, useEffect } from 'react'
import { Box } from '@material-ui/core'

import { useStyles } from './ProgressBar_style'

const ProgressBar = React.forwardRef((props, ref) => {
    const [progress, setProgress] = useState(0)
    const classes = useStyles()
    const COMPLETION_PERCENTAGE = 100

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)

        return () => window.removeEventListener('scroll', handleScroll)
    }, [ref])
 
    const handleScroll = () => {
        const target = ref.current
        const winTop = window.pageYOffset || document.documentElement.scrollTop
        const targetBottom = target.offsetTop + target.scrollHeight
        const windowBottom = winTop + window.outerHeight
        const articleCompletion = COMPLETION_PERCENTAGE - (((targetBottom - windowBottom + window.outerHeight / 4) / (targetBottom - window.outerHeight + window.outerHeight / 4)) * COMPLETION_PERCENTAGE)
        const newProgress = Math.min(parseInt(articleCompletion), COMPLETION_PERCENTAGE)

        setProgress(newProgress)
    }

    return(
        <Box
            className={classes.container}
            style={{width: `${progress}%`}}
        />
    )
})

export default ProgressBar
