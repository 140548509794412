import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    jobImageContainer: {
        width: '100%',
        height: '386px'
    },

    jobImageContainer: {
        width: '100%',
        height: '386px'
    },

    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        [theme.breakpoints.up('md')]: {
            borderRadius: '20px'
        }
    },

    divider: {
        width: '100%',
        height: '30px',
        backgroundColor: 'transparent'
    }
}))
