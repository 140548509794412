import React from 'react'

import { useStyles } from './MobileWalletCard_style'
import { Box, Button, Typography, useTheme } from '@material-ui/core'

import IconFactory from 'icons/IconFactory/IconFactory'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const MobileWalletCard = (props) => {
    const classes = useStyles()
    const {
        filter, index, jobFilterCard,
        name, page, secondary
    } = props
    const theme = useTheme()

    const buildRouteMapLinkProps = () => {
        switch (page) {
            case 'student-jobs':
                if (!index) {
                    return { location: filter.value_name, itemType: page }
                }
            case 'first-jobs':
            case 'internships':
                if (!index) {
                    return { domain: filter.value_name, itemType: page }
                }
            default:
                return { page: page }
        }
    }

    return (
        <RouteMapLink {...buildRouteMapLinkProps()} className={classes.link}>
            <Button className={`${classes.button} ${secondary && classes.highlightedButton}`}>
                <Box className={classes.leftContainer}>
                    <Typography variant='h3' component='p' className={`${classes.buttonText} ${secondary && classes.highlightedButtonText}`}>
                        {name}
                    </Typography>
                </Box>
                <Box className={classes.rightContainer}>
                    <IconFactory
                        icon='arrow-right'
                        color={theme.palette.fg['white']}
                        width='10px'
                        height='10px'
                    />
                </Box>
            </Button>
        </RouteMapLink>
    )
}

export default MobileWalletCard
