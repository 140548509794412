/* eslint-disable react/prop-types */
import React from 'react'

import PageWrapper from 'wrappers/PageWrapper/PageWrapper'

import HomeStudentMainPage from './HomeStudentMainPage'
import HomeStudentMainHelmet from 'helmets/HomeStudentMainHelmet'

class HomeStudentMain extends React.Component {
    render () {
        return (
            <PageWrapper user={this.props.user}>
                <HomeStudentMainHelmet />
                <HomeStudentMainPage {...this.props} />
            </PageWrapper>
        )
    }
}

export default HomeStudentMain
