import React, { useState, useEffect } from 'react'
import Lottie from 'lottie-react'

import { Box } from '@material-ui/core'

import backpackLottie from './lotties/BAGPACK-lottie.json'
import rocketLottie from './lotties/ROCKET.json'
import smileyLottie from './lotties/SMILEY-lottie.json'
import starLottie from './lotties/STAR-lottie.json'

const lottieSwitch = (lottie) => {
    switch (lottie) {
    case 'backpack': return backpackLottie
    case 'rocket': return rocketLottie
    case 'smiley': return smileyLottie
    case 'star': return starLottie

    default: return '/'
    }
}

const LottieFactory = ({ lottie, delay = 0 }) => {
    const [showAnimation, setShowAnimation] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowAnimation(true)
        }, delay)

        return () => clearTimeout(timer)
    }, [delay])

    return (
        <Box>
            {showAnimation && <Lottie animationData={lottieSwitch(lottie)} />}
        </Box>
    )
}
export default LottieFactory
