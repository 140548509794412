import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    filterWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 12,
        marginBottom: 30,
    }
}))
