import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    container: {
        background: 'white',
        width: 'fit-content'
    },
    tabContent: {
        minWidth: '500px',
        [theme.breakpoints.down('sm')]: {
            minWidth: 'unset',
        }
    },
    textareaContainer: {
        margin: '12px 0'
    },
    textarea: {
        margin: '12px 0',
        width: '100%',
        height: '100px'
    },
    preview: {
        border: '1px solid var(--grey)',
        height: 'fit-content',
        padding: '12px',
        margin: '12px 0'
    },
    submit: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}))
