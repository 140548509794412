import React from 'react'

const ArrowDownIcon = ({isHover}) => {
	return (
		<svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d='M0 1.27101C0.404329 0.867603 0.804052 0.467879 1.19825 0.0746028C3.01819 1.90283 4.83352 3.72646 6.64886 5.55008C8.53695 3.65462 10.3606 1.82455 12.1778 0C12.6539 0.493668 13.0546 0.908128 13.4377 1.30509C11.218 3.51646 8.96154 5.76284 6.71609 8C4.51117 5.79139 2.27032 3.54501 0 1.27101Z'
				fill={isHover ? 'var(--background-black)' : 'var(--background-white)'}
			/>
		</svg>
	)
}

export default ArrowDownIcon
