import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { useStyles } from './ProfileIdentityBox_style'
import UploadAvatarProfile from 'pages/user/Profile/StudentProfile/Components/UploadAvatarProfile/UploadAvatarProfile'

const ProfileIdentityBox = (props) => {
    const { user, profileId, pictureId, imageAvatar, setImageAvatar } = props
    const classes = useStyles()

    return (
        <Box className={classes.identityBox}>
            <Box className={classes.avatarBox}>
                <UploadAvatarProfile
                    user={user}
                    pictureId={pictureId}
                    profileId={profileId}
                    imageAvatar={imageAvatar}
                    setImageAvatar={setImageAvatar}
                    avatarClassName={classes.avatar}
                    fabClassName={classes.fab}
                />
            </Box>
            <Box className={classes.nameBox}>
                <Typography variant='h6' component='p' className={classes.fullName}>
                    {user.first_name}
                </Typography>
                <Typography variant='h6' component='p' className={classes.fullName}>
                    {user.last_name}
                </Typography>
                <Typography variant='body1' component='p' className={classes.email}>
                    {user.email}
                </Typography>
            </Box>
        </Box>
    )
}
export default ProfileIdentityBox
