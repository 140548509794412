import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    userProfileButton: {
        padding: '9px 14px',
        borderRadius: '8px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: 'var(--black-over)'
        }
    },
    userProfileIconButton: {
        color: 'white'
    },
    userText: {
        color: 'white'
    },
    paperMenu: {
        borderRadius: '11px',
        boxShadow: 'var(--shadow-paper)',
        marginTop: '10px',

        width: '238px'
    },
    menuList: {
        padding: '8px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px'
    },
    menuItem: {
        height: '36px',
        gap: '12px',
        padding: '8px',
        borderRadius: '6px',
        '&:hover': {
            backgroundColor: 'var(--beige)'
        }
    },
    menuItemIcon: {
        width: '25px'
    }
}))
