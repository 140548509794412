import React from 'react'

import { useStyles } from '../NavigationDropDowns_style'
import { Box } from '@material-ui/core'

import NavigationDropDownContainer from '../../shared/wrappers/NavigationDropDownContainer'
import NavigationDropDownFilters from '../../shared/NavigationDropDownFilters/NavigationDropDownFilters'
import NavigationDropDownTitle from '../../shared/NavigationDropDownTitle/NavigationDropDownTitle'
import RouteMap from 'assets/RouteMap'
import { FormattedMessage } from 'react-intl'

const NavigationDropDownFirstJobs = (props) => {
    const classes = useStyles()
    const { type } = props

    return (
        <NavigationDropDownContainer {...props}>
            <Box className={classes.dropDownFieldsContainer}>
                <NavigationDropDownTitle
                    title={<FormattedMessage id='page_footer_navbar_data.popular_domains' />}
                    seeAllButton={<FormattedMessage id='page_footer_navbar_data.see_all_domains' />}
                    pageLink={RouteMap.Page('first-jobs')}
                />
                <NavigationDropDownFilters type={type}/>
            </Box>
        </NavigationDropDownContainer>
    )
}

export default NavigationDropDownFirstJobs
