import React from 'react'
import { Box } from '@material-ui/core'
import { useStyles } from './Paper_style'

const Paper = (props) => {
    const { children, className, hidden } = props
    const classes = useStyles()

    if (hidden) {
        return children
    } else {
        return (
            <Box className={`${classes.paper} ${className}`}>
                {children}
            </Box>
        )
    }
}
export default Paper
