import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    selectedTagsContainer: { // OK
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        padding: '10px 20px 0 0',
        gap: 8
    }
}))
