import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Box, Typography } from '@material-ui/core'
import { useStyles } from './StudentRegister_style'

import ExperienceProfile from 'pages/user/Profile/StudentProfile/Components/ExperienceProfile/ExperienceProfile'
import ProfileSimpleInfo from '../../../user/Profile/StudentProfile/Components/ProfileSimpleInfo/ProfileSimpleInfo'
import ProgressCircle from 'components/ProgressCircle/ProgressCircle'
import Button from 'shared/components/Button/Button'

const StudentRegisterView = (props) => {
    const {
        handleNext,
        steps,
        activeStep,
        student,
        setStudent,
        studentFormation,
        setStudentFormation,
        loading
    } = props

    const classes = useStyles()

    const renderStudentInformation = () => {
        return (
            <ProfileSimpleInfo
                student={student}
                setStudent={setStudent}
            />
        )
    }

    const renderExperiences = () => {
        return (
            <ExperienceProfile
                setExperienceIsUpdated={() => { }}
                studentFormation={studentFormation}
                setStudentFormation={setStudentFormation}
                disabledSave
                disabledPaper
                condensed
                withCondensedStyle
            />
        )
    }

    return (
        <Box className={classes.studentRegisterViewContainer}>
            {loading ?
                <Box className={classes.progressCircleContainer}><ProgressCircle /></Box> :
                <form onSubmit={handleNext} className={classes.form}>
                    <Typography className={classes.formTitle} >
                        <FormattedMessage id="landing_login_page.sign_up.title" />
                    </Typography>
                    {renderStudentInformation()}
                    {renderExperiences()}
                    <Button className={classes.submitButton} type="submit" >
                        <FormattedMessage id="landing_login_page.confirm" />
                    </Button>
                </form>}
        </Box>
    )
}

export default StudentRegisterView
