import React, { useState } from 'react'
import { Select, MenuItem, ListItemText, FormControl, Checkbox } from '@material-ui/core'

import Tag from 'shared/components/Tag/Tag'
import IconFactory from 'icons/IconFactory/IconFactory'
import { useStyles } from './DropdownSingleSelect_style'
import OpenIcon from '../../../pages/user/Profile/StudentProfile/Components/ExperienceProfile/components/OpenIcon/OpenIcon'

const DropdownSingleSelect = (props) => {
    const { value, name, collection, handleChange, handleAttributeChange, placeholder } = props
    const classes = useStyles()
    const [open, setOpen] = useState(false)

    return (
        <FormControl className={classes.dropdownContainer} onClick={() => setOpen(!open)}>
            <Select
                value={value}
                name={name}
                displayEmpty
                disableUnderline
                label={name}
                open={open}
                onChange={handleChange}
                className={classes.select}
                classes={{ select: classes.customSelect }}
                renderValue={() => placeholder}
                IconComponent={() => <OpenIcon open={open} className={classes.icon} />}
                MenuProps={{ classes: { paper: classes.customPaper } }}
            >
                {collection.map(item => (
                    <MenuItem key={item.value} value={item.value} className={classes.menuItem}>
                        <Checkbox
                            disableRipple
                            icon={<IconFactory icon='checkbox-false' fontSize='20px' />}
                            checkedIcon={<IconFactory icon='checkbox-true-filled' color='var(--background-purple)' fontSize='20px' />}
                            color='primary'
                            checked={value === item.value}
                        />
                        <ListItemText primary={item.name} primaryTypographyProps={{ variant: 'body2' }}/>
                    </MenuItem>
                ))}
            </Select>
            {value &&
                <Tag
                    isSelected={true}
                    item={collection.find((collectionItem => collectionItem.value === value))}
                    handleClick={() => handleAttributeChange(name, null)}
                />
            }
        </FormControl>
    )
}

export default DropdownSingleSelect
