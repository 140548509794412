import React from 'react'
import { Image, Transformation } from 'cloudinary-react'
import { SMALL_SIZE } from 'assets/CloudinaryURLHelper'
import DateHandler from 'assets/DateHandler'
import RegexHelper from 'assets/RegexHelper'

export default class MessageCard extends React.Component {
    enrichMessageContent () {
        const enrichtedContent = this.props.message.content.replace(RegexHelper.urlRegex, (url) => {
            return `<a href='${url}' target='_blank' rel='noopener noreferrer'>${url}</a>`
        })
        const content = enrichtedContent.replace(/\n/g, '<br/>')

        return content
    }

    render () {
        let position = ''
        if (this.props.user.id === this.props.message.user_id) {
            position = 'right'
        }
        if (this.props.message.document) {
            if (this.props.message.document.format === 'pdf') {
                var message = (
                    <div className="msg-content">
                        <a className="msg-pdf" target="_blank" href={this.props.message.document.asset_url} noopener>
                            {this.props.message.document.name + '.pdf'}
                        </a>
                    </div>
                )
            } else {
                var message = (
                    <div className="msg-content">
                        <Image
                            className="msg-img"
                            cloudName="studentbe"
                            publicId={this.props.message.document.cloudinary_key}
                            alt={this.props.message.document.name}
                            crop="scale"
                            src={this.props.message.document.asset_url}
                        >
                            <Transformation flags="progressive" fetchFormat="auto" width={SMALL_SIZE} quality="auto:good" crop="fill" />
                        </Image>
                    </div>

                )
            }
        } else {
            var message = <div
                className="msg-content"
                dangerouslySetInnerHTML={{ __html: this.enrichMessageContent() }}
            />
        }

        return (
            <div className={`msg-container ${position}`}>
                {message}
                <div className="msg-time">
                    {DateHandler.ToBasicFormat(this.props.message.created_at)}
                </div>
            </div>
        )
    }
}
