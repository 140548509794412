import RegexHelper from 'assets/RegexHelper'

const RemoveHTML = (string) => {
    return string.replace(RegexHelper.removeHtmlRegex, '')
}

const SanitizeForApplicationJson = (string) => {
    // eslint-disable-next-line no-useless-escape
    if (!string) return 'string'
    return RemoveHTML(string).replace(RegexHelper.sanitizeForApplicationJsonRegex, '')
}

const SanitizeHtmlString = (string) => {
    let sanitizedString = string.replaceAll(RegexHelper.styleTagRegex, '')
    sanitizedString = sanitizedString.replaceAll(RegexHelper.emptyLineRegex, '')

    return sanitizedString
}

export default { RemoveHTML, SanitizeForApplicationJson, SanitizeHtmlString }
