import React from 'react'

const LinkedinSVG = (props) => (
    <svg
        width="41"
        height="40"
        viewBox="0 0 41 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_39_59896)">
            <path
                d="M34.1866 2.79102H8.97729C5.72148 2.79102 3.07031 5.41427 3.07031 8.64218V34.1492C3.07031 37.3771 5.72148 40.0003 8.97729 40.0003H34.1866C37.4424 40.0003 40.0936 37.3771 40.0936 34.1492V8.64218C40.0936 5.41427 37.4424 2.79102 34.1866 2.79102Z"
                fill="black"
            />
            <path
                d="M31.3909 0H6.18627C2.93046 0 0.279297 2.62326 0.279297 5.85116V31.3581C0.279297 34.586 2.93046 37.2093 6.18627 37.2093H31.3956C34.6514 37.2093 37.3026 34.586 37.3026 31.3581V5.85116C37.3026 2.62326 34.6514 0 31.3956 0H31.3909Z"
                fill="black"
            />
            <path
                d="M31.3953 3.7207H6.18605C4.9814 3.7207 4 4.67419 4 5.85094V31.3579C4 32.5347 4.97674 33.4881 6.18605 33.4881H31.3953C32.6047 33.4881 33.5814 32.5347 33.5814 31.3579V5.85094C33.5814 4.67419 32.6047 3.7207 31.3953 3.7207ZM12.9674 28.6416H8.49767V15.1998H12.9674V28.6416ZM10.7349 13.3626H10.7023C9.20465 13.3626 8.23256 12.33 8.23256 11.0416C8.23256 9.75326 9.23256 8.71605 10.7581 8.71605C12.2837 8.71605 13.2279 9.7207 13.2558 11.0416C13.2558 12.33 12.2837 13.3626 10.7302 13.3626H10.7349ZM29.0791 28.6416H24.6093V21.4463C24.6093 19.6416 23.9628 18.4091 22.3442 18.4091C21.1116 18.4091 20.3767 19.2416 20.0512 20.0416C19.9349 20.33 19.9023 20.73 19.9023 21.1347V28.637H15.4372C15.4372 28.637 15.4977 16.4556 15.4372 15.1998H19.9023V17.1021C20.4977 16.1858 21.5581 14.8788 23.9302 14.8788C26.8698 14.8788 29.0744 16.8044 29.0744 20.93V28.6416H29.0791Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_39_59896">
                <rect
                    width="39.814"
                    height="40"
                    fill="white"
                    transform="translate(0.279297)"
                />
            </clipPath>
        </defs>
    </svg>
)

export default LinkedinSVG
