import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    languageLine: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 10,
        paddingLeft: 24,
        width: '100%',
    },
    lineContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        maxWidth: 300,
        marginBottom: 10
    }
}))
