import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    funnelSubmitBar: {
        position: 'sticky',
        bottom: 0,
        height: 64,
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        borderTop: '2px var(--light-grey) solid',
        padding: '12px 40px',
        background: 'white',
        '& > *': {
            flexBasis: '33.33%'
        },
        [theme.breakpoints.down('sm')]: {
            padding: 12
        }
    },
    loadingOverlay: {
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 'auto',
        background: 'var(--light-grey)',
        height: '100%',
        width: '100%',
        opacity: 0.5,
        zIndex: 100,
    },
    progressBar: {
        width: (props) => `${props.width}%`,
        position: 'absolute',
        left: 0,
        top: -2,
        height: 2,
        background: 'var(--dark-grey)',
        transition: 'width 0.3s ease-in'
    },
    backButton: {},
    funnelPosition: {
        display: 'flex',
        justifyContent: 'center'
    },
    submitButton: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}))
