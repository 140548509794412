import axios from 'axios'
import ReactOnRails from 'react-on-rails'

import { DOCUMENT_API } from './env'

function postImage (data) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios.post(DOCUMENT_API, data)
}
function postDocument (data) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios.post(DOCUMENT_API, data)
}
function deleteDocumentImage (id) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios.delete('/api/v1/documents/' + id)
}
function createAvatar (data) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios.patch('/api/v1/users/update_picture', data)
}
function getSignature (data) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios.post('/api/v1/documents/generate_signature', data)
}
export default {
    postImage,
    postDocument,
    deleteDocumentImage,
    createAvatar,
    getSignature
}
