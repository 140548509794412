import React from 'react'
import { FormattedMessage } from 'react-intl'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import { Rating } from '@material-ui/lab'
import { useStyles } from './LanguageProfile_style'
import CollectionHandler from 'assets/CollectionHandler.js'
import IconFactory from 'icons/IconFactory/IconFactory'
import Button from 'components/Button/Button'
import Paper from 'components/Paper/Paper'
import { DROPDOWN_STYLE_MENU_PROPS } from 'assets/StyleHelper'

const LanguageProfileView = (props) => {
    const {
        addNewStudentLanguage,
        disabledSave,
        handleChangeLanguage,
        handleChangeRequiredLanguage,
        handleHideLanguage,
        studentLanguage,
        student
    } = props

    const classes = useStyles()

    const otherLanguagesCollection = CollectionHandler.Get('otherLanguagesCollection')
    const languageLevelCollection = CollectionHandler.Get('languageLevelCollection')

    const renderPrimaryLanguage = (language) => {
        const languageValue = parseInt(student[language.toUpperCase()])
        const languageLevel = languageLevelCollection.find(lvl => parseInt(lvl.value) === parseInt(languageValue))
        const isEmpty = !student[language.toUpperCase()]
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} md={3} className={classes.gridFlex}>
                    <Box alignSelf={'flex-end'}>
                        {CollectionHandler.Translate('defaultLanguageCollection', language.toLowerCase())}*
                    </Box>
                </Grid>
                <Grid item xs={12} md={9}>
                    <FormControl className={classes.formControl} required>
                        <Rating
                            icon={<IconFactory fontSize={30} icon='star-full' className={classes.star}/>}
                            emptyIcon={<IconFactory fontSize={30} icon='star' className={classes.starEmpty}/>}
                            name={`language-${language}`}
                            value={languageValue}
                            onChange={handleChangeRequiredLanguage(language.toUpperCase())}
                            size="large"
                        />
                        <Box ml={2} className={isEmpty ? classes.isEmpty : ''}>
                            {languageLevel?.name || !student.languageError || <strong><FormattedMessage id="landing_language_profile.choose_language" /></strong>}
                        </Box>
                    </FormControl>
                </Grid>
            </Grid>
        )
    }

    return (
        <Paper className={classes.paper}>
            <Grid container item xs={12}>
                <Typography variant='h2' component={'div'}>
                    <FormattedMessage id="landing_language_profile.language_skills" />
                </Typography>
            </Grid>
            <Box className={classes.languagesContainer}>
                <Grid container spacing={2} justify={'center'}>
                    <Grid item xs={12}>
                        {renderPrimaryLanguage('en')}
                    </Grid>
                    <Grid item xs={12} >
                        {renderPrimaryLanguage('nl')}
                    </Grid>
                    <Grid item xs={12} >
                        {renderPrimaryLanguage('fr')}
                    </Grid>
                </Grid>
                <Box mt={2}>
                    {studentLanguage.map(
                        (lng, key) => {
                            const otherLanguageLevel = languageLevelCollection.find(lvl => parseInt(lvl.value) === parseInt(lng.skill_weight))
                            return (
                                <Grid justify={'center'} container key={key.toString()} style={{ display: lng.deleted ? 'none' : 'block' } }>
                                    <Grid item xs={12} md={12} >
                                        <Grid container spacing={2} className={classes.languageContainer} >
                                            <Grid item xs={12} md={3}>
                                                <FormControl className={classes.formControl} required>
                                                    <Select
                                                        defaultValue={lng.name}
                                                        className={classes.fullWidth}
                                                        style={{ width: '100%' }}
                                                        displayEmpty
                                                        MenuProps={DROPDOWN_STYLE_MENU_PROPS}
                                                        onChange={handleChangeLanguage(key, 'name')}
                                                    >
                                                        {lng.name === '' &&
                                                                <MenuItem value=''>
                                                                    <strong><FormattedMessage id="landing_language_profile.choose" /></strong>
                                                                </MenuItem>
                                                        }
                                                        {otherLanguagesCollection.map(
                                                            (lnglvl, key) => {
                                                                return <MenuItem key={key.toString()} value={lnglvl.value.toUpperCase()}>{lnglvl.name}</MenuItem>
                                                            }
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={10} md={6}>
                                                <FormControl className={classes.formControl} required>
                                                    <Rating
                                                        icon={<IconFactory fontSize={30} icon='star-full' className={classes.star}/>}
                                                        emptyIcon={<IconFactory fontSize={30} icon='star' className={classes.starEmpty}/>}
                                                        name={`language-${lng.name}`}
                                                        value={lng.skill_weight || 0}
                                                        onChange={handleChangeLanguage(key, 'skill_weight')}
                                                        size="large"
                                                    />
                                                    <Box ml={2}>
                                                        {otherLanguageLevel?.name || student.languageError || <strong><FormattedMessage id="landing_language_profile.choose_language" /></strong>}
                                                    </Box>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={2} md={3} style={{ alignSelf: 'center' }} >
                                                <Box display={'flex'} justifyContent={'flex-start'}>
                                                    <IconButton onClick={() => handleHideLanguage(key)} >
                                                        <IconFactory icon='delete'/>
                                                    </IconButton>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        }
                    )}
                </Box>
            </Box>
            <Grid container spacing={2} >
                {!disabledSave &&
                    <Grid container item xs={12} direction="row" justify="flex-end">
                        <Button
                            type='submit'
                            size='small'
                        >
                            <FormattedMessage id="landing_language_profile.save" />
                        </Button>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Button
                        variant='secondary'
                        endIcon={<IconFactory icon='add' />}
                        size='small'
                        onClick={addNewStudentLanguage}
                    >
                        <FormattedMessage id="landing_language_profile.add_language" />
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default LanguageProfileView
