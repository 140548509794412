import React from 'react'

const PsychologySVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.98 86.04" {...props}>
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path
                    d="M85.72,49a17.14,17.14,0,0,0-3.91-8.45,16,16,0,0,0-.71-7.83,15.46,15.46,0,0,0-5.31-7.38,15.86,15.86,0,0,0-7.26-10.89A14.61,14.61,0,0,0,56.06,6.1C54.17,6,52.33,6,50.55,6H48.3a8.18,8.18,0,0,0-1.56.15,7.48,7.48,0,0,0-.82.18,7.78,7.78,0,0,0-2-.26l-2.24,0C39.85,6,38,6,36,6.1a14.28,14.28,0,0,0-12.58,8.43,15,15,0,0,0-7.17,10.76v.05a15.63,15.63,0,0,0-5.35,7.39,15.79,15.79,0,0,0-.72,7.87c-.29.35-.57.7-.84,1.07a17.78,17.78,0,0,0,2.74,23.88l.06.52A16.16,16.16,0,0,0,23.56,79.38q.54.15,1.08.27A14.63,14.63,0,0,0,36.28,86c1.14,0,2.25,0,3.35,0h4.29a8,8,0,0,0,2.15-.3,7.56,7.56,0,0,0,1.26.23c.43,0,.8.07,1.15.07H55a15.05,15.05,0,0,0,2.85-.24,14.49,14.49,0,0,0,9.49-6.14A16.44,16.44,0,0,0,74.53,76,16.06,16.06,0,0,0,79.9,65.48l0,0A17.24,17.24,0,0,0,85.72,49Z" />
                <path
                    d="M79.72,43a17.14,17.14,0,0,0-3.91-8.45,16,16,0,0,0-.71-7.83,15.46,15.46,0,0,0-5.31-7.38A15.86,15.86,0,0,0,62.53,8.49,14.61,14.61,0,0,0,50.06.1C48.17,0,46.33,0,44.55,0H42.3a8.18,8.18,0,0,0-1.56.15,7.48,7.48,0,0,0-.82.18,7.78,7.78,0,0,0-2-.26l-2.24,0C33.85,0,32,0,30,.1A14.28,14.28,0,0,0,17.43,8.53a15,15,0,0,0-7.17,10.76v.05A15.63,15.63,0,0,0,4.9,26.73a15.91,15.91,0,0,0-.73,7.87c-.28.35-.56.7-.83,1.07a18.06,18.06,0,0,0-.91,19.51,18,18,0,0,0,3.65,4.37l.06.52A16.16,16.16,0,0,0,17.56,73.38q.54.15,1.08.27A14.63,14.63,0,0,0,30.28,80c1.13,0,2.25,0,3.35,0h4.28a8,8,0,0,0,2.16-.3,7.56,7.56,0,0,0,1.26.23c.43,0,.8.07,1.15.07H49a15.05,15.05,0,0,0,2.85-.24,14.49,14.49,0,0,0,9.49-6.14A16.49,16.49,0,0,0,68.53,70,16.06,16.06,0,0,0,73.9,59.48l0,0A17.24,17.24,0,0,0,79.72,43Z" />
                <path className="cls-1" fill='white'
                    d="M42.05,8a1.57,1.57,0,0,1,.25,0c2.48,0,5,0,7.43.09a6.56,6.56,0,0,1,6.05,5.27,1.39,1.39,0,0,0,1,1.23,7.92,7.92,0,0,1,5,9c0,.17-.07.33-.11.57,2.71.87,4.84,2.43,5.79,5.2A7.9,7.9,0,0,1,66.29,37c.55.36,1.11.68,1.62,1.07a9.56,9.56,0,0,1,3.91,6.22,9.26,9.26,0,0,1-3.1,9.08,18.42,18.42,0,0,1-2.78,1.81c-.33.2-.41.33-.29.71A8,8,0,0,1,63.32,64a8.26,8.26,0,0,1-6,2.05,10.73,10.73,0,0,1-1.21-.18c-.19.62-.34,1.25-.58,1.85a6.57,6.57,0,0,1-5.16,4.25,7.07,7.07,0,0,1-1.38.1H42.49a4.2,4.2,0,0,1-.44,0Z" />
                <path className="cls-1" fill='white'
                    d="M37.92,72H36.44c-2,0-3.95,0-5.93,0a6.66,6.66,0,0,1-6.31-5.26c-.07-.3-.14-.6-.23-1a8.06,8.06,0,0,1-9.89-6.71,6.64,6.64,0,0,1,.29-3.28c.16-.43-.09-.5-.36-.61a10.16,10.16,0,0,1-4.72-4.14,10,10,0,0,1,.5-10.66,10.39,10.39,0,0,1,3.94-3.34,7.91,7.91,0,0,1-1.27-7.7,8.11,8.11,0,0,1,5.67-5.17,23.2,23.2,0,0,1,0-3.64,7.46,7.46,0,0,1,5-6,1.29,1.29,0,0,0,1-1.07A6.47,6.47,0,0,1,30.4,8.09c2.48-.12,5,0,7.52,0V72Z" />
            </g>
        </g>
    </svg>
)

export default PsychologySVG
