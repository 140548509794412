import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    paper: {
        minWidth: 300,
        height: 'fit-content',
        boxShadow: 'none',
        border: 'solid var(--foreground-silver) 1px',
        borderRadius: 20,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    sectionBox: {
        padding: 16,
        [theme.breakpoints.up('md')]: {
            minWidth: 300
        }
    },
    sectionBoxWidth: {
        [theme.breakpoints.up('md')]: {
            minWidth: 300
        }
    }
}))
