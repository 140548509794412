import React from 'react'

const LateNightSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75.33 86.04" {...props}>
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path d="M74.2,64.5a8,8,0,0,0-8.61-3.71,18.54,18.54,0,0,1-14.29-2.1,18.26,18.26,0,0,1-8.6-11.82,19.1,19.1,0,0,1,1.18-13.32A18.77,18.77,0,0,1,56.2,23a8,8,0,0,0,.15-15.36C37.2,1.87,16.87,11.7,9.08,30.52c-8,19.34.13,41.85,18.53,51.25A38.35,38.35,0,0,0,45.21,86a40.74,40.74,0,0,0,6.28-.49c9.33-1.44,17.71-6.91,21.87-11.7A8,8,0,0,0,74.2,64.5Z" />
                <path d="M68.2,58.5a8,8,0,0,0-8.61-3.71,18.54,18.54,0,0,1-14.29-2.1,18.26,18.26,0,0,1-8.6-11.82,19.1,19.1,0,0,1,1.18-13.32A18.74,18.74,0,0,1,50.2,17a8,8,0,0,0,.15-15.36C31.19-4.13,10.87,5.7,3.08,24.52c-8,19.34.13,41.85,18.53,51.25A38.35,38.35,0,0,0,39.21,80a40.74,40.74,0,0,0,6.28-.49c9.33-1.44,17.71-6.91,21.87-11.7A8,8,0,0,0,68.2,58.5Z" />
                <path fill='white' d="M48,9.31A26.58,26.58,0,0,0,30.68,24.08a27.12,27.12,0,0,0-1.75,18.7A26.75,26.75,0,0,0,61.33,62.6a30.45,30.45,0,0,1-36.09,6C10.38,61.05,4.11,43,10.47,27.58A31.07,31.07,0,0,1,48,9.31Z" />
            </g>
        </g>
    </svg>
)

export default LateNightSVG
