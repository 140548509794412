import React, {} from 'react'
import { Image, Transformation } from 'cloudinary-react'
import Box from '@material-ui/core/Box'
import IconFactory from 'shared/icons/IconFactory/IconFactory'
import { useStyles } from './UploadImageV2_style'
import { MEDIUM_SIZE, SMALL_SIZE } from 'assets/CloudinaryURLHelper'

const UploadImageItem = (props) => {
    const { image, handleDelete, isLarge } = props
    const classes = useStyles({ isLarge })

    return (
        <Box className={classes.imageContainer} key={image.id}>
            <Box className={classes.image}>
                <Image
                    cloudName='studentbe'
                    className={classes.cardMedia}
                    publicId={image.cloudinary_key}
                >
                    <Transformation flags='progressive' fetchFormat='auto' height={isLarge ? MEDIUM_SIZE : SMALL_SIZE} quality='auto:good' crop='fill' />
                </Image>
                {!isLarge &&
                    <Box className={classes.imageName}>
                        {`${image.name}.${image.format}`}
                    </Box>
                }
            </Box>
            <Box className={classes.deleteImage} onClick={handleDelete}>
                <IconFactory icon='cross' color='white' fontSize='12px' />
            </Box>
        </Box>
    )
}

export default UploadImageItem
