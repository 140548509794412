import React, { useState } from 'react'
import { List } from 'react-virtualized'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './CityFilterView_style'
import { Box, Select, Avatar, MenuItem, ListItemText, Button, Input, IconButton, FormControl, Typography, TextField, useTheme } from '@material-ui/core'

import IconFactory from 'icons/IconFactory/IconFactory'
import FilterTagList from 'components/Searchbar/shared/FilterTagList/FilterTagList'

const CityFilterView = (props) => {
    const { filters, handleSearchCities, handleInputSearchCity, searchCities, citiesInput, width, showTags, dropdownPosition, modal } = props
    const classes = useStyles()
    const [openDropdown, setOpenDropdown] = useState(false)
    const theme = useTheme()

    const selectPaperStyle = () => {
        return ({
            disableScrollLock: true,
            getContentAnchorEl: null,
            anchorOrigin: { vertical: 'bottom', horizontal: dropdownPosition || 'center' },
            transformOrigin: { vertical: -20, horizontal: dropdownPosition !== 'left' && (width / 2) },
            PaperProps: {
                style: {
                    boxShadow: `4px 4px 0px 0px ${theme.palette.bg['black']}`,
                    border: `2px solid ${theme.palette.fg['black']}`,
                    backgroundColor: theme.palette.bg['white'],
                    borderRadius: '30px'
                }
            }
        })
    }

    const rowRenderer = (row) => {
        const { key, index, style } = row
        const option = searchCities[index]

        return (
            <MenuItem
                key={key}
                style={style}
                value={option.value}
                className={classes.menuItem}
                onClick={() => handleSearchCities(option)}
            >

                <ListItemText primary={`${option.name} (${option.value})`} primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
        )
    }

    const renderArrow = () => {
        if (filters.location.length) return null

        return <IconFactory icon='down-solid' fontSize='14px' className={classes.triggerArrowIcon} />
    }

    const renderSelectValue = (selected) => {
        return (
            <Box className={classes.triggerPlaceholderContainer}>
                <Typography className={classes.triggerPlaceholderText}>
                    <FormattedMessage id='search_bar.city' />
                </Typography>

                {selected.length === 0 ?
                    renderArrow() :
                    <Avatar className={classes.tagAvatar} >{selected.length}</Avatar>}
            </Box>
        )
    }

    return (
        <Box className={classes.cityFilterViewContainer}>
            <FormControl className={classes.cityFilterViewFormControl}>
                <Button className={`${classes.cityFilterViewTrigger} ${openDropdown && classes.cityFilterViewTriggerClicked}`} disableRipple>
                    <Select
                        multiple
                        disableUnderline
                        value={filters.location}
                        displayEmpty
                        onChange={handleSearchCities}
                        input={<Input className={classes.selectInput} />}
                        classes={{ root: classes.select }}
                        focusable={false}
                        renderValue={(selected) => renderSelectValue(selected)}
                        MenuProps={selectPaperStyle()}
                        IconComponent={() => <Box />}
                        open={openDropdown}
                        onClose={() => setOpenDropdown(false)}
                        onOpen={() => setOpenDropdown(true)}
                    >
                        <Box className={classes.searchInputContainer}>
                            <Box className={`${classes.textInputContainer}`}>
                                <IconFactory icon='search-regular' color={theme.palette.fg['black']} className={classes.searchIcon} />
                                <FormattedMessage id='search_bar.search.city'>
                                    {placeholder =>
                                        <TextField
                                            InputProps={{ classes: { input: classes.textInput }, disableUnderline: true }}
                                            placeholder={placeholder}
                                            value={citiesInput}
                                            name='citySearch'
                                            onChange={handleInputSearchCity}
                                            fullWidth
                                            onKeyDown={e => e.stopPropagation()}
                                        />
                                    }
                                </FormattedMessage>
                            </Box>
                        </Box>
                        <Box className={classes.selectedItemsListContainer}>
                            <FilterTagList small handleChange={handleSearchCities} items={filters.location} />
                        </Box>
                        <List
                            width={500}
                            height={300}
                            rowCount={searchCities.length}
                            rowHeight={45}
                            rowRenderer={rowRenderer}
                        />
                    </Select>
                </Button>
            </FormControl>
            {showTags && filters.location.length > 0 &&
                <Box className={classes.filterTagList}>
                    <FilterTagList handleChange={handleSearchCities} items={filters.location} />
                </Box>
            }
        </Box>
    )
}

export default CityFilterView
