import React from 'react'

const StudentJobStatSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -10 100 100"
        x="0px"
        y="0px"
    >
        <g fill="#a000ff">
            <path
                d="M39.057,2.092a4.178,4.178,0,0,0-7.629,3.259l-6.01,6.727a1.317,1.317,0,1,0,1.964,1.754L32.73,7.846,49.123,36.239,20.887,42.126l-4.534-7.853-2.839-4.917,5.975-6.688a1.317,1.317,0,0,0-1.964-1.754l-6.469,7.241-6.236,3.6a9.648,9.648,0,0,0,4.837,18,9.554,9.554,0,0,0,4.1-.927l1.664,2.883a3.674,3.674,0,0,0,4.991,1.359l7.537,13.054a2.576,2.576,0,0,0,3.516.942l5.045-2.913a2.574,2.574,0,0,0,.145-4.368l-1.494-1L29,48.11a3.678,3.678,0,0,0,1.318-5l-.133-.23,20.448-4.263a4.151,4.151,0,0,0,1.939,1.147,4.2,4.2,0,0,0,1.088.144,4.18,4.18,0,0,0,3.612-6.269ZM7.826,46.876a7.009,7.009,0,0,1-1.691-12.84l5.279-3.048,3.5,6.07,3.5,6.07-5.279,3.048a6.963,6.963,0,0,1-5.319.7M33.441,60.8l1.67,1.117L30.2,64.754l-7.507-13,4.026-2.325,6.315,10.938a1.316,1.316,0,0,0,.408.436M28.035,44.424a1.042,1.042,0,0,1-.381,1.422l-8.533,4.926a1.042,1.042,0,0,1-1.422-.381l-1.648-2.854L20.7,44.855l6.771-1.412Zm27.109-8.3a1.545,1.545,0,0,1-2.831.373L34.1,4.954a1.545,1.545,0,1,1,2.677-1.545L54.99,34.953a1.535,1.535,0,0,1,.154,1.173"
                transform="translate(0 0)"
            />
            <path
                d="M528.564,93.582a1.316,1.316,0,0,0,1.8.482l8.506-4.911a1.317,1.317,0,0,0-1.317-2.28l-8.506,4.911a1.317,1.317,0,0,0-.482,1.8"
                transform="translate(-476.214 -78.136)"
            />
            <path
                d="M497.245,25.289a1.316,1.316,0,0,0,1.8-.482l4.053-7.019a1.317,1.317,0,0,0-2.28-1.317l-4.053,7.019a1.316,1.316,0,0,0,.482,1.8"
                transform="translate(-447.553 -14.252)"
            />
            <path
                d="M571.7,188.738h-6.41a1.317,1.317,0,0,0,0,2.633h6.41a1.317,1.317,0,1,0,0-2.633"
                transform="translate(-508.282 -170.102)"
            />
            <path
                d="M658.575,63.974c0-.043-.011-.086-.019-.128a1.418,1.418,0,0,0-.075-.246,1.347,1.347,0,0,0-.122-.227,1.148,1.148,0,0,0-.077-.1,1.131,1.131,0,0,0-.087-.081,1.34,1.34,0,0,0-.216-.148,1.384,1.384,0,0,0-.244-.076,1.251,1.251,0,0,0-.252-.019,1.426,1.426,0,0,0-.3.057,1.3,1.3,0,0,0-.253.138,1.256,1.256,0,0,0-.218.212,1.4,1.4,0,0,0-.162.249,1.384,1.384,0,0,0-.068.144,1.317,1.317,0,0,0,0,.255,1.355,1.355,0,0,0,.057.3c0,.04.011,.079,.019,.119a1.42,1.42,0,0,0,.1.245,1.317,1.317,0,0,0,.116.2,1.34,1.34,0,0,0,.146.17,1.221,1.221,0,0,0,.079.061,1.251,1.251,0,0,0,.1.067,1.354,1.354,0,0,0,.257.106,1.429,1.429,0,0,0,.333.038,1.262,1.262,0,0,0,.327-.057,1.371,1.371,0,0,0,.306-.131,1.361,1.361,0,0,0,.249-.2,1.418,1.418,0,0,0,.193-.267,1.269,1.269,0,0,0,.146-.328A1.326,1.326,0,0,0,658.575,63.974Z"
                transform="translate(-577.838 -56.936)"
            />
        </g>
    </svg>
)

export default StudentJobStatSVG
