import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Typography, MenuItem } from '@material-ui/core'
import IconFactory from 'icons/IconFactory/IconFactory'
import { useStyles } from './StudyExperienceBox_style'

const StudyExperienceBoxItemLine = (props) => {
    const { title, text, onClick } = props
    const classes = useStyles()

    return (
        <MenuItem className={classes.itemBox} onClick={onClick}>
            <Typography variant='h6' className={classes.title}>
                <FormattedMessage id={title} />
            </Typography>
            <Typography variant='body2' className={classes.chipLabelText}>
                {text}
                <IconFactory icon='edit' fontSize='12px'/>
            </Typography>
        </MenuItem>
    )
}

export default StudyExperienceBoxItemLine
