import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    main: {
        margin: '40px 0',
        display: 'flex',
        width: '100%',
        gap: 40,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            margin: '24px 0'
        }
    },
    leftSide: {
        width: '60%',
        display: 'flex',
        flexDirection: 'column',
        gap: 28,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            gap: 20
        }
    },
    rightSide: {
        width: '40%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    pageTitle: {
        fontSize: 32,
        color: 'var(--foreground-purple)',
        width: '80%',
        [theme.breakpoints.down('md')]: {
            fontSize: 24,
            width: '100%'
        }
    },
    divider: {
        border: '2px solid var(--foreground-purple)',
        width: '120px',
        borderRadius: 12,
        margin: '12px 0',
        [theme.breakpoints.down('md')]: {
            margin: '4px 0'
        }
    },
    body: {
        fontSize: '18px !important',
        maxWidth: 440,
        lineHeight: '30px',
        [theme.breakpoints.down('md')]: {
            fontSize: '16px !important',
            lineHeight: '24px'
        }
    },
    highlight: {
        backgroundColor: 'var(--foreground-purple)',
        color: 'white',
        padding: '0 2px'
    },
    faqTitle: {
        marginBottom: 12,
        marginTop: 12
    },
    questionSubtitle: {
        fontSize: '16px !important'
    },
    faqQuestion: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'unset'
        }
    },
    accordionRoot: {
        border: 'solid var(--foreground-silver) 1px',
        borderRadius: '10px !important',
        boxShadow: 'none',
        margin: '4px 0',
        padding: 4,
        '&::before': {
            height: 0
        },
        '&:last-child': {
            borderRadius: 12
        }
    },
    faqRoot: {
        flexDirection: 'column',
        gap: 16
    },
    faqAnswer: {
        fontSize: 14
    },
    faqCTA: {
        fontWeight: 'bold',
        textDecoration: 'underline',
        textUnderlineOffset: '8px',
        textDecorationThickness: '2px',
        cursor: 'pointer'
    },
    englishCTA: {
        width: 'fit-content'
    },
    video: {
        borderRadius: 20,
        marginTop: 12,
        minWidth: 300,
        maxWidth: 380,
        width: '75%'
    }
}))
