import React from 'react'

import IconFactory from 'shared/icons/IconFactory/IconFactory'
import FeedbackSnackbar from 'components/FeedbackSnackbar/FeedbackSnackbar'

import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Fab from '@material-ui/core/Fab'

import { useStyles } from './UploadAvatarProfile_style'

const UploadAvatarProfileView = (props) => {
    const {
        avatarUrl,
        feedback,
        openModal,
        closeFeedback,
        user,
        avatarClassName,
        fabClassName
    } = props

    const classes = useStyles()

    return (<>
        <FeedbackSnackbar
            open={feedback.open}
            message={feedback.message}
            severity={feedback.severity}
            closeFeedback={closeFeedback}
        />
        <Box onClick={openModal} style={{ position: 'relative' }}>
            <Box className={`${fabClassName} ${classes.fab}`} >
                <IconFactory icon='edit' color='white' />
            </Box>
            {avatarUrl
                ? <Avatar
                    onClick={openModal}
                    alt="upload avatar profiel view"
                    src={avatarUrl}
                    className={avatarClassName || classes.largeAvatar}
                />
                : <Avatar className={avatarClassName || classes.largeAvatar}>
                    {`${user.first_name[0]}${user.last_name[0]}`}
                </Avatar>
            }
        </Box>
    </>)
}

export default UploadAvatarProfileView
