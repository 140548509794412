/* eslint-disable comma-dangle */
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    statBoxContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        margin: '100px 0px',
        [theme.breakpoints.down('sm')]: {
            gap: 10,
            margin: '40px 0px',
            justifyContent: 'center'
        }
    },
    statBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        gap: 20,
        maxWidth: '25%',
        [theme.breakpoints.down('sm')]: {
            minWidth: '48%',
            width: '48%',
            justifyContent: 'flex-start',
            gap: 8,
            margin: '10px 0px'
        }
    },
    iconContainer: {
        width: 80,
        height: 80,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > svg': {
            width: 40,
            height: 50,
            [theme.breakpoints.down('xs')]: {
                width: 28,
                height: 36,
            }
        },
        [theme.breakpoints.down('xs')]: {
            width: 50,
            height: 50,
        }
    },
    statText: {
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        minWidth: 100,
        [theme.breakpoints.down('sm')]: {
            minWidth: 'none'
        }
    },
    stat: {
        color: theme.palette.fg.black,
        fontFamily: 'GreedWide-Heavy, sans-serif',
        fontSize: 40,
        lineHeight: 0.8,
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px !important'
        }
    },
    statDescription: {
        color: theme.palette.fg.black60Opacity,
        fontSize: 16,
        fontFamily: 'GreedStandard-Regular, sans-serif',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px !important'
        }
    }
}))
