import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    filters: {
        width: '100%',
        padding: '8px 0',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        columnGap: '13px',
        [theme.breakpoints.up('lg')]: {
            paddingBottom: '11px',
            columnGap: '8px'
        }
    }
}))
