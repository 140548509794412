import React from 'react'
import { Box, Grid, Typography, Hidden } from '@material-ui/core'
import { useStyles } from './RecommendedItemsContainer_style'
import { FormattedMessage } from 'react-intl'
import ItemListTitleWrapper from 'components/ItemListTitleWrapper/ItemListTitleWrapper'

const RecommendedItemsContainer = (props) => {
    const { children, moreItemsURL, titleId, subTitleId, recommendedItemType, type, textValues, cardDirection } = props
    const classes = useStyles()
    return (
        <ItemListTitleWrapper
            moreItemsURL={moreItemsURL}
            titleId={titleId}
            type={type}
            textValues={textValues}
        >
            <Typography variant='body2' className={classes.subTitle}>
                <FormattedMessage id={subTitleId} values={textValues} />
            </Typography>
            {type === 'article' &&
                <Box className={classes.desktopBox}>
                    <Grid container spacing={2} className={classes.articleGridContainer}>
                        {children.map((recommendedItemCard) => {
                            return (
                                <Grid item xs={12} md={6} lg={4} key={`item-card-${recommendedItemCard.props[recommendedItemType].id}`}>
                                    {recommendedItemCard}
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>
            }
            {type !== 'article' && <>
                {cardDirection === 'horizontal' &&
                    <Box className={classes.desktopBox}>
                        {children.map((recommendedItemCard) => {
                            return recommendedItemCard
                        })}
                    </Box>
                }
                {cardDirection !== 'horizontal' && <>
                    <Hidden mdDown>
                        <Box className={classes.desktopBox}>
                            <Grid container spacing={2}>
                                {children.map((recommendedItemCard) => {
                                    return (
                                        <Grid item xs={12} sm={6} lg={4} key={`item-card-${recommendedItemCard.props[recommendedItemType].id}`}>
                                            {recommendedItemCard}
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Box>
                    </Hidden>
                    <Hidden lgUp>
                        <Box className={classes.mobileScrollBox}>
                            {children}
                        </Box>
                    </Hidden>
                </>}
            </>}
        </ItemListTitleWrapper>
    )
}
export default RecommendedItemsContainer
