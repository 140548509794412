import React, { useState, useRef, useEffect } from 'react'
import { Image, Transformation } from 'cloudinary-react'

import { useStyles } from './JobCardV2_style'
import { Box, Typography, useTheme, useMediaQuery } from '@material-ui/core'

import { SMALL_SIZE, SMALLER_SIZE } from 'assets/CloudinaryURLHelper'
import FavoriteAction from './components/FavoriteAction/FavoriteAction'
import JobCardChips from './components/JobCardChips/JobCardChips'
import JobCardWrapper from './JobCardWrapper'
import SVGFactory from 'icons/SVGFactory/SVGFactory'
import IconFactory from 'icons/IconFactory/IconFactory'
import URLHandler from 'assets/URLHandler'
import isEmpty from 'lodash.isempty'

const MAX_NORMAL_JOB_CARD_WIDTH = 650

const JobCardV2 = (props) => {
    const { job, showJobType, onCardClick } = props
    const cardRef = useRef(null)
    const theme = useTheme()
    const [isCompact, setIsCompact] = useState(useMediaQuery(theme.breakpoints.down('xs')))
    const jobCardYoutubeId = job.job_video_youtube_id
    const jobCardImageId = job.job_image_cloudinary_key || `placeholders/${job.sector || 'other_industries'}`
    const youtubeImageUrl = URLHandler.youtubeImageUrlBuilder(jobCardYoutubeId)
    const classes = useStyles({ isCompact })

    useEffect(() => {
        const handleResize = () => {
            const cardWidth = cardRef.current?.offsetWidth || 0
            const shouldBeCompact = cardWidth < MAX_NORMAL_JOB_CARD_WIDTH
            setIsCompact(prevState => (prevState !== shouldBeCompact) ? shouldBeCompact : prevState)
        }

        handleResize()
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [cardRef])

    return (
        <JobCardWrapper
            onCardClick={onCardClick}
            jobType={job.type}
            jobUrl={job.url}
            jobId={job.id}
        >
            <Box className={classes.containerCard} ref={cardRef}>
                <Box className={classes.containerCover}>
                    {jobCardYoutubeId
                        ? <img
                            className={classes.cardMediaV2}
                            style={{
                                backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.2) 40%, rgba(0,0,0,0) 100%), url(${youtubeImageUrl})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundColor: 'black'
                            }}
                        />
                        : <Image
                            cloudName='studentbe'
                            className={`${classes.cardMediaV2} ${classes.image}`}
                            publicId={jobCardImageId}
                            alt='sector image'
                        >
                            <Transformation flags='progressive' fetchFormat='auto' height={SMALL_SIZE} quality='auto:good' crop='fill' />
                            <Transformation effect='gradient_fade' y='-0.5' />
                            <Transformation background='#222222' opacity='90' />
                            <Transformation defaultImage='placeholders:other_industries.png' />
                        </Image>
                    }
                    {job.company_logo_cloudinary_key && !isCompact &&
                        <Box className={classes.containerLogoCompany}>
                            <Image
                                className={classes.companyMedia}
                                cloudName='studentbe'
                                publicId={job.company_logo_cloudinary_key}
                                alt={`Company logo ${job.company_name}`}
                            >
                                <Transformation flags='progressive' fetchFormat='auto' width={SMALLER_SIZE} quality='auto:good' crop='fill' gravity='center' />
                                <Transformation radius='20' />
                            </Image>
                        </Box>
                    }
                </Box>
                <Box className={classes.containerBody}>
                    <Box className={classes.containerBodyTop}>
                        <Box className={classes.containerTitles}>
                            <Box className={classes.containerNameAndLogo}>
                                {job.company_logo_cloudinary_key && isCompact &&
                                    <Box className={classes.containerLogoCompany}>
                                        <Image
                                            className={classes.companyMedia}
                                            cloudName='studentbe'
                                            publicId={job.company_logo_cloudinary_key}
                                            alt={`Company logo ${job.company_name}`}
                                        >
                                            <Transformation flags='progressive' fetchFormat='auto' width={SMALLER_SIZE} quality='auto:good' crop='fill' gravity='center' />
                                            <Transformation radius='20' />
                                        </Image>
                                    </Box>
                                }
                                <Typography variant='h2' component='p' className={classes.companyName}>
                                    {job?.company?.name || ''}
                                </Typography>
                            </Box>
                            <Typography variant='h3' component='p' className={classes.title}>
                                {job.title || ''}
                            </Typography>
                        </Box>
                        <Box>
                            {job.is_impact &&
                                <Box className={classes.containerJobType}>
                                    <SVGFactory name='earth' width="25" height="25" />
                                </Box>
                            }
                        </Box>
                    </Box>
                    <Box className={classes.containerBodyBottom}>
                        <Box className={classes.containerTags}>
                            {(showJobType || (job?.job_tags && !isEmpty(job.job_tags))) &&
                                <JobCardChips showJobType={showJobType} job={job} />
                            }
                        </Box>
                        <Box className={classes.containerLocationFavorite}>
                            <Box className={classes.containerLocation}>
                                <IconFactory icon='location-pin' fontSize='14px' color={'var(--foreground-black-40-opacity)'} />
                                <Typography component='p' className={classes.location}>
                                    {job.city || ''}
                                </Typography>
                            </Box>
                            <FavoriteAction
                                userType={props.userType}
                                color='var(--black)'
                                item={job}
                                padding={'4px 0px 0px 10px'}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </JobCardWrapper>
    )
}

export default JobCardV2
