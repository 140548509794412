import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        [theme.breakpoints.up('md')]: {
            borderRadius: 20
        }
    },
    dialogContent: {
        padding: '0 24px'
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'center',
        padding: 20,
        borderBottom: '1px solid #EFEAE5'
    },
    underTitle: {
        color: '#8F95B2',
        marginTop: 4,
        marginBottom: 20
    },
    closeIcon: {
        position: 'absolute',
        top: 20,
        right: 20,
        color: 'black',
        height: 26,
        width: 26
    },
    button: {
        textTransform: 'none'
    }
}))
