import React from 'react'

const SpeakSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86.01 86" {...props}>
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path d="M84.77,37.55A15.2,15.2,0,0,0,82,32.78q0-7,0-14V14a7.94,7.94,0,0,0-7-7.94A8.46,8.46,0,0,0,73.77,6H67.48A8.72,8.72,0,0,0,61.2,8.77l-1.84,2c-1.08,1.18-2.11,2.29-3.16,3.34a40.09,40.09,0,0,1-3.3,3,22.29,22.29,0,0,1-6.59,3.59A24.08,24.08,0,0,1,38.06,22H14a8,8,0,0,0-8,8V58a8,8,0,0,0,8,8V78a8,8,0,0,0,8,8H38a8,8,0,0,0,8-8V67.25a22.33,22.33,0,0,1,8.76,5.26c1.51,1.46,3,3,4.52,4.69.65.7,1.3,1.4,2,2.08a8.72,8.72,0,0,0,6.41,2.77H74a8,8,0,0,0,8-8V69.45q0-7.11,0-14.21a14.22,14.22,0,0,0,1.29-1.76A17.51,17.51,0,0,0,84.77,37.55Z" />
                <path d="M78.77,31.55A15.2,15.2,0,0,0,76,26.78q0-7,0-13.95V8A7.94,7.94,0,0,0,69,.09,8.46,8.46,0,0,0,67.77,0H61.48A8.72,8.72,0,0,0,55.2,2.77l-1.84,2C52.28,5.92,51.25,7,50.2,8.08a40.09,40.09,0,0,1-3.3,3,22.29,22.29,0,0,1-6.59,3.59A24.08,24.08,0,0,1,32.06,16H8a8,8,0,0,0-8,8V52a8,8,0,0,0,8,8V72a8,8,0,0,0,8,8H32a8,8,0,0,0,8-8V61.25a22.33,22.33,0,0,1,8.76,5.26c1.51,1.46,3,3,4.52,4.69.65.7,1.3,1.4,2,2.08a8.71,8.71,0,0,0,6.41,2.77H68a8,8,0,0,0,8-8V63.45q0-7.11,0-14.21a14.22,14.22,0,0,0,1.29-1.76A17.51,17.51,0,0,0,78.77,31.55Z" />
                <path fill="white" d="M16,52V72H32V52h.55a32.31,32.31,0,0,1,8.3,1.13A30.83,30.83,0,0,1,54.3,60.76c2.32,2.26,4.49,4.68,6.71,7a.76.76,0,0,0,.6.26H68v-.55q0-10.78,0-21.56a.62.62,0,0,1,.37-.63,5.79,5.79,0,0,0,2.14-2.06,9.68,9.68,0,0,0,.8-8.91,6.61,6.61,0,0,0-3-3.59.62.62,0,0,1-.36-.63q0-10.8,0-21.6V8l-.21,0c-2.09,0-4.19,0-6.28,0a.77.77,0,0,0-.48.26c-1.71,1.81-3.37,3.67-5.13,5.44a48,48,0,0,1-4,3.6,30.1,30.1,0,0,1-9,4.92A32.44,32.44,0,0,1,32,24H8V52Z" />
            </g>
        </g>
    </svg>
)

export default SpeakSVG
