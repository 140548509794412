import React, { useState } from 'react'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from 'components/Button/Button'
import Paper from 'components/Paper/Paper'

import SettingsAPI from 'api/SettingsAPI'
import RouteMap from 'assets/RouteMap'

import { useStyles } from './PasswordSettings_style'
import { FormattedMessage } from 'react-intl'

const PasswordSettings = ({ props }) => {
    const email = props.user.email
    const classes = useStyles()
    const [userState, setUserState] = useState(
        {
            newPassword: '',
            confirmPassword: '',
            currentPassword: '',
            errorPassword: '',
            errors: {}
        }
    )
    const handleChangeData = prop => event => {
        setUserState({ ...userState, [prop]: event.target.value })
    }

    const canSubmit = () => {
        var canSubmit = true

        if (userState.newPassword !== userState.confirmPassword) {
            setUserState({
                ...userState,
                errorPassword: <FormattedMessage id="landing_app_setting_page.errors.new_password" />
            })
            canSubmit = false
        }
        if (userState.currentPassword === '') {
            setUserState({
                ...userState,
                errors: {
                    password: <FormattedMessage id="landing_app_setting_page.errors.missing_password" />
                }
            })
            canSubmit = false
        }
        return canSubmit
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        if (canSubmit()) {
            const data = {
                email: email,
                new_password: userState.newPassword,
                confirm_password: userState.confirmPassword,
                password: userState.currentPassword
            }
            console.log(data)
            // eslint-disable-next-line no-undef
            SettingsAPI.ChangePassword(data).then(
                (response) => {
                    window.location.href = RouteMap.Page('users/settings/edit')
                }
            ).catch(
                (error) => {
                    console.log(error.response.data)
                    setUserState({ ...userState, errors: error.response.data.errors })
                }
            )
        }
    }

    return (
        <Paper className={classes.paper}>
            <form onSubmit={handleSubmit} autoComplete="nope">
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Box mb={2}>
                            <Typography
                                variant='h3' component='h2'
                                color='primary'

                            >
                                <FormattedMessage id="landing_app_setting_page.change_password" />
                            </Typography>
                        </Box>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                {/* This input serves as a hidden hack to prevent the browser from autofilling the new password fields */}
                                <input type="password" style={{ position: 'absolute', width: '0px', height: '0px', padding: '0', margin: '0', border: 'none', opacity: 0 }}/>
                                {/* This input serves as a hidden hack to prevent the browser from autofilling the new password fields */}
                                <FormattedMessage id="landing_app_setting_page.new_password">
                                    {label =>
                                        <TextField
                                            fullWidth
                                            type={'password'}
                                            value={userState.newPassword}
                                            onChange={handleChangeData('newPassword')}
                                            label={label}
                                            error={userState.errorPassword}
                                            helperText={userState.errorPassword}
                                            autoComplete="new-password"
                                            InputProps={{
                                                classes: {
                                                    input: classes.inputBase
                                                }
                                            }}
                                            classes={{
                                                root: classes.errorText
                                            }}
                                        />
                                    }
                                </FormattedMessage>
                            </Grid>
                            <Grid item xs={12} md={6} >
                                <FormattedMessage id="landing_app_setting_page.confirm_password">
                                    {label =>
                                        <TextField
                                            fullWidth
                                            type={'password'}
                                            value={userState.confirmPassword}
                                            onChange={handleChangeData('confirmPassword')}
                                            label={label}
                                            autoComplete="new-password"
                                            InputProps={{
                                                classes: {
                                                    input: classes.inputBase
                                                }
                                            }}
                                        />
                                    }
                                </FormattedMessage>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} >
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                <FormattedMessage id="landing_app_setting_page.current_password">
                                    {label =>
                                        <TextField
                                            fullWidth
                                            type={'password'}
                                            value={userState.currentPassword}
                                            onChange={handleChangeData('currentPassword')}
                                            label={label}
                                            error={userState.errors.password}
                                            helperText={userState.errors.password}
                                            autoComplete="current-password"
                                            InputProps={{
                                                classes: {
                                                    input: classes.inputBase
                                                }
                                            }}
                                            classes={{
                                                root: classes.errorText
                                            }}
                                        />
                                    }
                                </FormattedMessage>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box display={'flex'} style={{ height: '100%' }} justifyContent='flex-end' alignItems='flex-end'>
                                    <Button
                                        variant='secondary'
                                        size='small'
                                        type='submit'
                                    >
                                        <FormattedMessage id="landing_app_setting_page.save" />
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    )
}
export default PasswordSettings
