import React from 'react'
import { Box } from '@material-ui/core'

import { useStyles } from './FunnelContentWrapper_style'

const FunnelContentWrapper = ({children}) => {
    const classes = useStyles()
    return (
		<Box className={classes.funnelContent}>
            {children}
        </Box>
    )
}

export default FunnelContentWrapper
