import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Button from 'components/Button/Button'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import JobCardV2 from 'cards/JobCard/JobCardV2'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import ConfettiCannon from 'components/ConfettiCannon/ConfettiCannon'
import RouteMap from 'assets/RouteMap.js'
import { useStyles } from './Congratulation_style'

const Congratulation = (props) => {
    const { user, items, type, job } = props
    const recommendedJobs = items.filter(item => item.id !== job.id)
    const classes = useStyles()

    useEffect(() => {
        setTimeout(() => ConfettiCannon(), 300)
    }, [])

    const moreItemsURL = () => {
        if (!job) return null
        let params = ''

        if (job.type === 'student_job') {
            if (job.type === 'student_job' && job.city) {
                return RouteMap.LocationIndex('student_job', job)
            }
        } else if (['internship', 'first_job'].includes(job.type) && job.study_domains?.length > 0) {
            if (job.study_domains?.length === 1) {
                return RouteMap.Tag(job.type, 'seoStudyDomainCollection', job.study_domains[0].name)
            }
            job.field_of_study.forEach((studyDomain) => {
                params += `study_domain=${studyDomain}&`
            })
        }

        if (params.endsWith('&')) {
            params = params.slice(0, -1)
        }

        return RouteMap.Index(job.type, params)
    }

    return (
        <PageWrapper user={user}>
            <Container maxWidth='lg'>
                <Box className={classes.titleBox}>
                    <Typography variant='h1' className={classes.title}>
                        <FormattedMessage id='congratulation.title'/>
                    </Typography>
                </Box>
                <Typography variant="h2" >
                    <FormattedMessage id="congratulation.slider.title" />
                </Typography>
                <Box my={4} className={classes.recommendationsContainer}>
                    {recommendedJobs.map(job => {
                        return (
                            <JobCardV2 job={job} showJobType key={job.id} />
                        )
                    })}
                </Box>
                <Box className={classes.userActions}>
                    <RouteMapLink page='users/candidacies'>
                        <Button size='small' variant='secondary'>
                            <FormattedMessage id='congratulation.buttons.create_alert'/>
                        </Button>
                    </RouteMapLink>
                    <RouteMapLink redirectionUrl={moreItemsURL()}>
                        <Button size='small'>
                            <FormattedMessage id='congratulation.buttons.show_offers_per_type' values={{ job_type: <FormattedMessage id={job.type + 's'} /> }} />
                        </Button>
                    </RouteMapLink>
                </Box>
            </Container>
        </PageWrapper>
    )
}

export default Congratulation
