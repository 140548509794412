import React from 'react'

import StudentProfilePage from './Components/StudentProfilePage'
import { CssBaseline } from '@material-ui/core'
// eslint-disable-next-line react/display-name
export default (props) => {
    return (
        <>
            <CssBaseline />
            <StudentProfilePage {...props} />
        </>

    )
}
