import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    loginRegisterPageContainer: {
        width: '100%',
        padding: '51px 24px 138px',
        backgroundColor: theme.palette.bg['blue'],
        backgroundImage: 'url(\'https://res.cloudinary.com/studentbe/image/upload/v1721138136/student-be-assets/Mask_group.png\')',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    selectionTitle: {
        [theme.breakpoints.down('xs')]: {
            paddingBottom: '0px !important'
        }
    },
    spacing: {
        borderBottom: '1px solid var(--grey)'
    },
    flexEnd: {
        justifyContent: 'flex-end'
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center'
    },
    textUnderline: {
        textDecoration: 'underline'
    },
    fieldTitle: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxHeight: '20px'
    }
}))
