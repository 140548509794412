import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    flexBox: {
        display: 'flex'
    },
    packagesItem: {
        color: 'grey',
        margin: 0,
        marginRight: 10,
        display: 'flex',
        alignItems: 'flex-start',
        marginTop: 2
    },
    textCenter: {
        textAlign: 'center'
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 10,
        color: 'var(--main-color)'
    },
    cardIconSize: {
        width: '100px',
        height: '100px',
        color: 'var(--main-color)'
    },
    cardTitle: {
        textAlign: 'center',
        whiteSpace: 'pre-wrap',
        lineHeight: '22px',
        margin: 0
    },
    tooltipButton: {
        padding: 0,
        backgroundColor: 'white !important'
    },
    checkRoundedIcon: {
        fontSize: 16
    },
    helpOutlineTwoTonIcon: {
        color: 'var(--grey)',
        marginTop: 3,
        marginLeft: -4
    },
    priceContainer: {
        position: 'relative',
        minHeight: 40,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    creditNum: {
        minHeight: 20
    },
    priceBox: {
        position: 'absolute',
        marginBottom: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexBasis: '100%'
    },
    priceDescription: {
        marginLeft: '7px'
    },
    priceTotal: {
        position: 'relative',
        top: '25px'
    },
    buttonSize: {
        minWidth: 120,
        textTransform: 'none'
    },
    /* Student */
    studentCard: {
        boxShadow: 'unset',
        border: '1px solid var(--background-silver)',
        borderRadius: 20,
    },
    studentCardBox: {
        padding: '16px 25px',
        display: 'flex',
        flexDirection: 'column',
        minHeight: 400
    },
    studentCardTitleHeight: {
        minHeight: 70
    },
    studentCardItemsHeight: {
        minHeight: 220
    },
    /* FirstJob */
    firstJobCard: {
        boxShadow: 'unset',
        border: '1px solid var(--background-silver)',
        borderRadius: 20,
    },
    firstJobCardBox: {
        padding: '16px 25px',
        display: 'flex',
        flexDirection: 'column',
    },
    firstJobCardTitleHeight: {
        minHeight: 50
    },
    firstJobSubtitle: {
        minHeight: 60,
        textAlign: 'center'
    },
    firstJobSubtitleColor: {
        color: 'grey'
    },
    firstJobCardItemsHeight: {
        [theme.breakpoints.up('md')]: {
            minHeight: 320
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: 20
        }
    },
    firstJobPriceContainer: {
        minHeight: 45
    },
    alignCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))
