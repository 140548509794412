const getValues = (filterArray) => {
    if (filterArray && filterArray.length) {
        return filterArray.map((option) => option.value)
    }
}

const getCityNames = (cities) => {
    if (cities && cities.length) {
        return cities.map((city) => city.name)
    }
}

export default {
    getValues,
    getCityNames
}
