import React from 'react'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

import DateFnsUtils from '@date-io/date-fns'

import { useStyles } from './InfoProfile_style'
import { FormattedMessage } from 'react-intl'

import CollectionHandler from 'assets/CollectionHandler.js'
import IconFactory from 'shared/icons/IconFactory/IconFactory'

const InfoProfileView = (props) => {
    const {
        disabledSave,
        condensed,
        handleChangeCheckBox,
        handleChangeData,
        handleChangeGender,
        handleDateBlur,
        handleDateChange,
        selectedDate,
        student
    } = props

    const classes = useStyles()

    const genderCollection = CollectionHandler.Get('genderCollection')

    return (
        <Grid container spacing={2}
            direction="row"
            justify="flex-start"
            alignItems="flex-start">
            <Grid item xs={12}>
                <Typography variant='h2' component={'div'}>
                    <FormattedMessage id="landing_info_profile.information" />
                </Typography>
            </Grid>
            <Grid item xs={12} >
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={4} className={classes.gridFlex}>
                            <Box alignSelf={'flex-end'}>
                                <FormattedMessage id="landing_info_profile.first_name" />
                            </Box>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                required
                                error={student.first_nameError}
                                helperText={student.first_nameErrorMessage}
                                className={classes.textFont}
                                fullWidth
                                type={'text'}
                                defaultValue={student.first_name}
                                onBlur={handleChangeData('first_name')}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={4} className={classes.gridFlex}>
                        <FormattedMessage id="landing_info_profile.last_name" />
                    </Grid>
                    <Grid item xs={8} >
                        <TextField
                            required
                            error={student.last_nameError}
                            helperText={student.last_nameErrorMessage}
                            className={classes.textFont}
                            fullWidth
                            type={'text'}
                            defaultValue={student.last_name}
                            onBlur={handleChangeData('last_name')}
                        />
                    </Grid>
                </Grid>
                {condensed && <>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={4} className={classes.gridFlex}>
                                <Box alignSelf={'flex-end'}>
                                    <FormattedMessage id="landing_info_profile.postal_code" />
                                </Box>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    required
                                    error={student.zipError}
                                    helperText={student.zipErrorMessage}
                                    fullWidth
                                    className={classes.textFont}
                                    type={'number'}
                                    defaultValue={student.zip}
                                    onBlur={handleChangeData('zip')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={4} className={classes.gridFlex}>
                                <Box alignSelf={'flex-end'}>
                                    <FormattedMessage id="landing_info_profile.gsm" />
                                </Box>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    required
                                    error={student.phoneNumberError}
                                    helperText={student.phoneNumberErrorMessage}
                                    className={classes.textFont}
                                    fullWidth
                                    type={'text'}
                                    defaultValue={student.phoneNumber}
                                    onBlur={handleChangeData('phoneNumber')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </>}
            </Grid>
            {!condensed &&
                <>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={4} className={classes.gridFlex}>
                                <Box alignSelf={'flex-end'}>
                                    <FormattedMessage id="landing_info_profile.address" />
                                </Box>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    className={classes.textFont}
                                    fullWidth
                                    type={'text'}
                                    defaultValue={student.street}
                                    onBlur={handleChangeData('street')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={4} className={classes.gridFlex}>
                                <Box alignSelf={'flex-end'}>
                                    <FormattedMessage id="landing_info_profile.postal_code" />
                                </Box>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    required
                                    error={student.zipError}
                                    helperText={student.zipErrorMessage}
                                    fullWidth
                                    className={classes.textFont}
                                    type={'number'}
                                    defaultValue={student.zip}
                                    onBlur={handleChangeData('zip')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={4} className={classes.gridFlex}>
                                <Box alignSelf={'flex-end'}>
                                    <FormattedMessage id="landing_info_profile.city" />
                                </Box>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    required
                                    error={student.cityError}
                                    helperText={student.cityErrorMessage}
                                    fullWidth
                                    className={classes.textFont}
                                    type={'text'}
                                    defaultValue={student.city}
                                    onBlur={handleChangeData('city')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={4} className={classes.gridFlex}>
                                <Box alignSelf={'flex-end'}>
                                    <FormattedMessage id="landing_info_profile.country" />
                                </Box>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    required
                                    error={student.countryError}
                                    helperText={student.countryErrorMessage}
                                    className={classes.textFont}
                                    fullWidth
                                    type={'text'}
                                    defaultValue={student.country}
                                    onBlur={handleChangeData('country')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={4} className={classes.gridFlex}>
                                <Box alignSelf={'flex-end'}>
                                    <FormattedMessage id="landing_info_profile.gsm" />
                                </Box>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    required
                                    error={student.phoneNumberError}
                                    helperText={student.phoneNumberErrorMessage}
                                    className={classes.textFont}
                                    fullWidth
                                    type={'text'}
                                    defaultValue={student.phoneNumber}
                                    onBlur={handleChangeData('phoneNumber')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={4} className={classes.gridFlex}>
                                <Box alignSelf={'flex-end'}>
                                    <FormattedMessage id="landing_info_profile.gender" />
                                </Box>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl className={classes.formControl} required>
                                    <Select
                                        value={student.gender ? student.gender : ''}
                                        displayEmpty
                                        className={classes.textFont}
                                        inputProps={{ MenuProps: { disableScrollLock: true } }}
                                        onChange={handleChangeGender('gender')}
                                    >
                                        {student.gender === '' &&
                                            <MenuItem value="">
                                                <em><FormattedMessage id="landing_info_profile.choose" /></em>
                                            </MenuItem>
                                        }
                                        {genderCollection.map(
                                            (gender, key) => {
                                                return <MenuItem key={key.toString()} value={gender.value}>{gender.name}</MenuItem>
                                            }
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={4} className={classes.gridFlex}>
                                <Box alignSelf={'flex-end'} className={classes.wordBreak}>
                                    <FormattedMessage id="landing_info_profile.birth_date" />
                                </Box>
                            </Grid>
                            <Grid item xs={8}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container justify="space-around">
                                        <KeyboardDatePicker
                                            keyboardIcon={<IconFactory icon='date'/>}
                                            className={classes.date}
                                            margin="normal"
                                            format="dd/MM/yyyy"
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            onBlur={handleDateBlur('birthDate')}
                                            KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                            placeholder="01/01/1990"
                                            error={student.birthDateError}
                                            helperText={student.birthDateErrorMessage}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} className={`${classes.gridFlex} ${classes.wordBreak}`}>
                                <FormattedMessage id="landing_info_profile.more_info" />
                            </Grid>
                            <Box className={classes.checkBoxBox}>
                                <Box className={classes.checkBoxLine}>
                                    <Checkbox
                                        color='primary'
                                        icon={<Box className={classes.checkBox} />}
                                        checkedIcon={<Box className={classes.checkBoxFull} />}
                                        checked={student.bike}
                                        onChange={handleChangeCheckBox('bike')}
                                    />
                                    <Typography variant='body2' style={{ fontSize: 12 }} >
                                        <FormattedMessage id="landing_info_profile.bicycle" />
                                    </Typography>
                                </Box>
                                <Box className={classes.checkBoxLine}>
                                    <Checkbox
                                        color='primary'
                                        icon={<Box className={classes.checkBox} />}
                                        checkedIcon={<Box className={classes.checkBoxFull} />}
                                        checked={student.car}
                                        onChange={handleChangeCheckBox('car')}
                                    />
                                    <Typography variant='body2' style={{ fontSize: 12 }} >
                                        <FormattedMessage id="landing_info_profile.car" />
                                    </Typography>
                                </Box>
                                <Box className={classes.checkBoxLine}>
                                    <Checkbox
                                        color='primary'
                                        icon={<Box className={classes.checkBox} />}
                                        checkedIcon={<Box className={classes.checkBoxFull} />}
                                        checked={student.drivingLicence}
                                        onChange={handleChangeCheckBox('drivingLicence')}
                                    />
                                    <Typography variant='body2' style={{ fontSize: 12 }} >
                                        <FormattedMessage id="landing_info_profile.driver_license" />
                                    </Typography>
                                </Box>
                            </Box>
                            {student.additionalInfoError &&
                                <Grid container item xs={12} alignItems={'center'}>
                                    <Typography color={'error'} component={'div'}>
                                        {student.additionalInfoErrorMessage}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </>
            }
            {!disabledSave &&
                <Grid container item xs={12} direction="row" justify="flex-end">
                    <Button type={'submit'} className={classes.buttonOutlined} variant={'outlined'} size={'small'} color={'primary'} ><FormattedMessage id="landing_info_profile.save" /></Button>
                </Grid>
            }
        </Grid>
    )
}

export default InfoProfileView
