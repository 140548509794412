import React, { useState, useEffect, useRef, useContext } from 'react'
import ProfileEditFormStepStudyExperienceView from './ProfileEditFormStepStudyExperienceView'
import UserAPI from 'api/UserAPI'
import ExperienceHelper from 'assets/ExperienceHelper'
import ProfileEditFormHelper from '../../helpers/ProfileEditFormHelper'
import { UserContext } from 'contexts/UserContext'
import AnalyticsHelper from 'assets/AnalyticsHelper'

const ProfileEditFormStepStudyExperience = (props) => {
    const { shortProfile, setExperiences, step, nextStep, previousStep, highlightInputField } = props
    const [loading, setLoading] = useState(false)
    const [experienceIsUpdated, setExperienceIsUpdated] = useState(false)
    const inputRefs = { studyCycle: useRef(null), studyType: useRef(null), school: useRef(null), campus: useRef(null), otherSchool: useRef(null), highSchool: useRef(null), studyField: useRef(null), otherStudyField: useRef(null), specialization: useRef(null), startYear: useRef(null), endYear: useRef(null), highSchoolStudyType: useRef(null), highSchoolStudyField: useRef(null), highSchoolYear: useRef(null) }
    const [errors, setErrors] = useState({ studyCycle: false, lastName: false, school: false, campus: false, otherSchool: false, highSchool: false, studyField: false, otherStudyField: false, specialization: false, startYear: false, endYear: false, highSchoolStudyType: false, highSchoolStudyField: false, highSchoolYear: false })
    const userContext = useContext(UserContext)

    const validateStepStudyExperience = () => {
        const newErrors = {
            studyCycle: inputRefs.studyCycle.current?.value === '',
            studyType: inputRefs.studyType.current?.value === '',
            school: inputRefs.school.current?.value === '',
            campus: inputRefs.campus.current?.value === '',
            otherSchool: inputRefs.otherSchool.current?.value === '',
            studyField: inputRefs.studyField.current?.value === '',
            specialization: inputRefs.specialization.current?.value === '',
            otherStudyField: inputRefs.otherStudyField.current?.value === '',
            startYear: inputRefs.startYear.current?.value === '',
            endYear: inputRefs.endYear.current?.value === '',
            highSchoolStudyType: inputRefs.highSchoolStudyType.current?.value === '',
            highSchoolStudyField: inputRefs.highSchoolStudyField.current?.value === '',
            highSchool: inputRefs.highSchool.current?.value === '',
            highSchoolYear: inputRefs.startYear.current?.value || !inputRefs.highSchoolYear.current?.value
        }
        setErrors(newErrors)
        return ProfileEditFormHelper.objectHasTrueValue(newErrors)
    }

    const atLeastOneErrorPresent = () => { return ProfileEditFormHelper.objectHasTrueValue(errors) }

    useEffect(() => {
        if (atLeastOneErrorPresent()) {
            validateStepStudyExperience()
        }
    }, [shortProfile.experiences])

    const handleSubmit = (event) => {
        if (validateStepStudyExperience()) return false

        const data = { experiences: ExperienceHelper.PrepareExperiencesDataToSave(shortProfile.experiences) }

        UserAPI.UpdateExperiences(data)
            .then((response) => {
                const initializedExperiences = ExperienceHelper.InitializeExperiences([response.data.current_experience])
                setExperiences(initializedExperiences)
                userContext.setUser({
                    ...userContext.user,
                    current_experience: initializedExperiences[0]
                })
                setLoading(false)
                nextStep('Personal Dashboard step 3 experience')
                const analObject = AnalyticsHelper.constructAccountUpdatedAnalyticsAttributes(
                    userContext.user,
                    userContext.user,
                    userContext.user.current_experience,
                    userContext.user.profile.birth_date || new Date(),
                    'Personal Dashboard ProfileEditFormStepStudyExperience step 3'
                )
                experienceIsUpdated && ExperienceHelper.SendExperienceUpdatedGTMEvent(!!userContext.originalUserProps.current_experience, analObject)
                AnalyticsHelper.sendGTMEvent('Account Updated', analObject)
            })
    }

    return (
        <ProfileEditFormStepStudyExperienceView
            inputRefs={inputRefs}
            errors={errors}
            shortProfile={shortProfile}
            handleSubmit={handleSubmit}
            setExperiences={setExperiences}
            step={step}
            loading={loading}
            previousStep={previousStep}
            setExperienceIsUpdated={setExperienceIsUpdated}
            highlightInputField={highlightInputField}
        />
    )
}

export default ProfileEditFormStepStudyExperience
