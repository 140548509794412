import React from 'react'

import JobCardV2 from 'cards/JobCard/JobCardV2'
import KotCard from 'cards/KotCard/KotCard'
import CheckButton from 'buttons/CheckButton/CheckButton'
import IconFactory from 'icons/IconFactory/IconFactory'

import css from './actioncarddecorator.scss'
import css_responsive from './actioncarddecorator_responsive.scss'

export default class ActionCardDecorator extends React.Component {
    constructor (props) {
        super(props)
        this.setCard = this.setCard.bind(this)
        this.job = this.job.bind(this)
        this.kot = this.kot.bind(this)
        this.createAction = this.createAction.bind(this)
        this.checkRef = React.createRef()
        this.state = this.setCard()
        this.cardContainerRef = React.createRef()
    }

    setCard () {
        let res = {
            createCard: this.job,
        }
        if (this.props.item.type == 'kot') {
            res = {
                createCard: this.kot,
            }
        }
        return res
    }

    job (job) {
        return <JobCardV2
            job = {job}
            id = {'jobcard_' + job.id}
            key = {'jobcard_' + job.id}
            userType={this.props.user.type}
        />
    }

    kot (kot) {
        return <KotCard
            kot = {kot}
            id = {'kotcard_' + kot.id}
            key = {'kotcard_' + kot[this.props.idKey]}
            displayShow={this.props.displayShow}
            forwardRef={this.cardContainerRef}
            userType={this.props.user.type}
        />
    }

    setCheck (check) {
        this.checkRef.current.setCheck(check)
    }

    createAction (id) {
        const actions = []
        if (this.props.type == 'favourite') {
            /* if(this.props.item.is_candidacy){
                actions.push(<a href="/Messages"><IconFactory icon='comment'/></a>);
            }
            else{
                actions.push(<div className="apply" key={"apply-" + id}>Postuler</div>)
            } */
        } else {
            actions.push(<a href="/Messages"><IconFactory icon='comment'/></a>)
        }
        return actions
    }

    render () {
        let checkBox = null
        if (this.props.type === 'favourite') {
            checkBox = (
                <CheckButton
                    onClick={() => this.props.onCheck(this.props.forwardRef, this.props.item)}
                    ref={this.checkRef}
                    key={'check-' + this.props.item[this.props.idKey]}
                />
            )
        }
        return (
            <div className={'card-action-decorator-container' + (this.props.type === 'favourite' ? ' favourite' : '')} ref={this.cardContainerRef}>
                <div className="card-container-desktop">
                    {this.state.createCard(this.props.item)}
                </div>
                <div className="card-container-mobile">
                    {this.props.type !== 'favourite' && checkBox}
                    {this.state.createCard(this.props.item)}
                </div>
                {this.props.type !== 'favourite' &&
                    <div className="hover-action-container">
                        {this.createAction(this.props.item[this.props.idKey])}
                    </div>
                }
            </div>
        )
    }
}
