import React from 'react'
import PropTypes from 'prop-types'

import IndexKotOwnerByKot from './IndexKotOwnerByKot'
import IndexByMsg from '../MessagesMain/components/IndexByMsg'
import IndexByFocus from '../MessagesMain/components/IndexByFocus'

export default class MessagesKotOwnerIndex extends React.Component {
    render () {
        switch (this.props.indexType) {
        case 'byKot':
            return (
                <IndexKotOwnerByKot
                    openItem={this.props.openItem}
                    kots={this.props.kots}
                />
            )
        case 'byMsg':
            return (
                <IndexByMsg
                    allCandidacies={this.props.allCandidacies}
                />
            )
        case 'byFocus':
            return (
                <IndexByFocus
                    switchIndexType={() => {this.props.switchIndexType('byKot')}}
                    itemFocus={this.props.itemFocus}
                    candidacies={this.props.candidacies}
                    itemType={this.props.itemType}
                    loadMore={this.props.loadMore}
                    lastPage={this.props.lastPage}
                />
            )
        }
    }
}
