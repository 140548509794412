/* eslint-disable react/prop-types */
import React from 'react'

import CreateAlertPopupView from './CreateAlertPopupView'

const CreateAlertPopup = (props) => {
    return <CreateAlertPopupView
        filters={props.filters}
        jobType={props.jobType}
        actionPopup={props.actionPopup}
        getPopupContent={props.getPopupContent}
    />
}

export default CreateAlertPopup
