import React from 'react'
import { Box, Typography } from '@material-ui/core'

import { Image, Transformation } from 'cloudinary-react'
import { FormattedMessage } from 'react-intl'

import FavoriteAction from 'components/FavoriteAction/FavoriteAction'
import { useStyles } from './SmallJobCard_style'
import { SMALLER_SIZE } from 'assets/CloudinaryURLHelper'
import JobCardWrapper from 'cards/JobCard/JobCardWrapper'
import JobCardChips from 'cards/JobCard/components/JobCardChips/JobCardChips'
import DateHandler from 'assets/DateHandler'

const SmallJobCard = (props) => {
    const classes = useStyles()
    const { job, onCardClick } = props

    const getDaysStillActiveCount = () => {
        const dayCount = DateHandler.getDaysUntilDate(job.active_until)

        if (dayCount < 90) {
            return dayCount
        } else {
            return false
        }
    }

    return (
        <JobCardWrapper onCardClick={onCardClick} jobType={job.type} jobUrl={job.url} jobId={job.id}>
            <Box className={classes.container}>
                <Box className={classes.jobCardText}>
                    <Box className={classes.logoAndTitle}>
                        {job.company_logo_cloudinary_key &&
                            <Box className={classes.logoContainer}>
                                <Image
                                    cloudName="studentbe"
                                    publicId={job.company_logo_cloudinary_key}
                                    alt={`Company logo ${job.company_name}`}
                                    className={classes.logo}
                                >
                                    <Transformation flags="progressive" fetchFormat="auto" width={SMALLER_SIZE} quality="auto:good" crop="fill" gravity="center" />
                                    <Transformation radius="20" />
                                </Image>
                            </Box>
                        }
                        <Typography variant='h4' className={classes.jobTitle}>
                            {job.title}
                        </Typography>
                    </Box>
                    <Box>
                        <Box my={1} className={classes.jobDetails}>
                            <Typography variant='subtitle2' className={classes.detailsText}>{job.city}</Typography>
                            {getDaysStillActiveCount() && <>
                                <Typography variant='span' className={classes.bullet}>&nbsp;•&nbsp;</Typography>
                                <Typography variant='subtitle2' className={classes.detailsText}>
                                    <FormattedMessage
                                        id='job_card.days_to_apply'
                                        values={{ count: getDaysStillActiveCount() }}
                                    />
                                </Typography>
                            </>}
                            {job.people_wanted > 1 && <>
                                <Typography variant='span' className={classes.bullet}>&nbsp;•&nbsp;</Typography>
                                <Typography variant='subtitle2' className={classes.detailsText}>
                                    {job.people_wanted} <FormattedMessage id={`job_card.people_wanted.${job.type}`} />
                                </Typography>
                            </>}
                        </Box>
                        <Box className={classes.jobDetails}>
                            {job.job_tags &&
                                <JobCardChips job={job} />
                            }
                            <Box className={classes.jobFavourite}>
                                <FavoriteAction
                                    userType={props.userType}
                                    color='var(--black)'
                                    item={job}
                                    onItemCard
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </JobCardWrapper>
    )
}

export default SmallJobCard
