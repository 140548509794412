import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    CompanyOtherJobsContainer: {
        width: '100%',
        paddingBottom: '81px',
    },
    titleContainer: {
        paddingBottom: '26px',
        display: 'flex',
        columnGap: '12px',
    },
    iconContainer: {
        width: '28px',
        height: '28px',
        borderRadius: '50%',
        backgroundColor: theme.palette.bg['greyLight'],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        color: theme.palette.fg['black'],
        fontSize: '18px',
        lineHeight: '28px'
    },
    jobsContainer: {
        width: '100%',
        paddingBottom: '42px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '24px'
    },
    linkContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    link: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '8px',
        '&:hover $linkText': {
            transition: 'all 0.3s ease',
            color: theme.palette.fg['purple'],
        },
        '&:hover $linkIconContainer': {
            transition: 'all 0.3s ease',
            backgroundColor: theme.palette.bg['purple'],
        },
    },
    linkText: {
        color: theme.palette.fg['black'],
        fontSize: '18px',
        lineHeight: '28px'
    },
    linkIconContainer: {
        width: '24px',
        minWidth: '24px',
        height: '24px',
        minHeight: '24px',
        borderRadius: '50px',
        backgroundColor: theme.palette.bg['black'],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}))
