import React from 'react'

const InternshipStatSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 90 80"
        x="0px"
        y="0px"
    >
        <g fill="#a000ff">
            <path d="M32,56a34.116,34.116,0,0,1-13.583-2.5l-.834,1.818A35.645,35.645,0,0,0,32,58a43.7,43.7,0,0,0,7.165-.574l-.33-1.973A41.673,41.673,0,0,1,32,56Z" />
            <path d="M14.78,50.941l-1.56,1.252a9.537,9.537,0,0,0,2.232,1.959l1.1-1.672A7.606,7.606,0,0,1,14.78,50.941Z" />
            <path d="M60.447,22.105l-28-14a1,1,0,0,0-.894,0l-28,14a1,1,0,0,0,0,1.79L14,29.118V45c0,5.047,7.907,9,18,9s18-3.953,18-9V29.118l5-2.5V40.184a2.987,2.987,0,0,0-1.124,4.932l-1.8,4.513A1,1,0,0,0,53,51h6a1,1,0,0,0,.929-1.371l-1.8-4.513A2.987,2.987,0,0,0,57,40.184V25.618L60.447,23.9a1,1,0,0,0,0-1.79ZM48,45c0,3.381-6.429,7-16,7s-16-3.619-16-7v-.826C18.963,47.063,24.941,49,32,49s13.037-1.937,16-4.826Zm0-5c0,3.381-6.429,7-16,7s-16-3.619-16-7V30.118L31.553,37.9a1,1,0,0,0,.894,0L48,30.118Zm8,2a1,1,0,1,1-1,1A1,1,0,0,1,56,42Zm1.523,7H54.477l1.213-3.031c.1.01.2.031.31.031s.207-.021.31-.031ZM55.812,23.976,36.748,22.07C36.122,20.847,34.3,20,32,20c-2.851,0-5,1.29-5,3s2.149,3,5,3c2.211,0,3.986-.781,4.678-1.927l15.783,1.578L32,35.882,6.236,23,32,10.118,57.764,23ZM35,23c0,.249-1.064,1-3,1s-3-.751-3-1,1.064-1,3-1S35,22.751,35,23Z" />
            <rect
                x="55"
                y="15.882"
                width="2"
                height="2.236"
                transform="translate(15.751 59.485) rotate(-63.435)"
            />
            <rect
                x="44.5"
                y="3.365"
                width="2"
                height="16.771"
                transform="translate(14.642 47.192) rotate(-63.435)"
            />
        </g>
    </svg>
)

export default InternshipStatSVG
