import React from 'react'
import { Box } from '@material-ui/core'

import { useStyles } from './JobCreationFunnelWrapper_style'

const JobCreationFunnelWrapper = ({children}) => {
    const classes = useStyles()

    return (
		<Box className={classes.container}>
            {children}
        </Box>
    )
}

export default JobCreationFunnelWrapper
