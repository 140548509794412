import React from 'react'
import { FormattedMessage } from 'react-intl'

import Typography from '@material-ui/core/Typography'

export default class OfferMessageCard extends React.Component {
    render () {
        if (this.props.unseenCount === 0) {
            var notificationDisplay = 'hide-notification'
        } else {
            var notificationDisplay = 'show-notification'
        }

        return (
            <div className="conversation_card" onClick={() => this.props.openItem(this.props.offer.type, this.props.offer.id)}>
                <div className={`message-choice-card ${this.props.offer.type ? this.props.offer.type : this.props.offer.address.replace(' ', '_')}`}>
                    <div className="message-card-first-infos">
                        {this.props.offer.type === 'kot' &&
                            <div className="message-card-picture">
                                <div
                                    className={`kot-message-notification ${this.props.offer.type ? this.props.offer.type.replace(' ', '_') : this.props.offer.address.replace(' ', '_')} ${notificationDisplay}`}
                                >
                                    {this.props.offer.unseenCount}
                                </div>
                            </div>
                        }
                        <div className="message-card-content-container">
                            <div className="message-time">{this.props.offer.candidacies_count + ' conversations'}</div>
                            <div className="message-content">
                                <div className="message-card-information">
                                    <Typography
                                        variant='body2'
                                        className="message-card-name"
                                    >
                                        {this.props.offer.title}
                                    </Typography>
                                    {this.props.offer.type
                                        ? <div className={`message-card-title ${this.props.offer.type.replace(' ', '_')}`}><FormattedMessage id={`chat.${this.props.offer.type}`} /></div>
                                        : <div className={`message-card-title ${this.props.offer.address.replace(' ', '_')}`}>{this.props.offer.address}</div>
                                    }
                                </div>
                                <div className="message-arrow"><i className="fas fa-chevron-right"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
