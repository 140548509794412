import React from 'react'
import { Box, Modal, Typography, IconButton } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import IconFactory from 'icons/IconFactory/IconFactory'
import { useStyles } from '../StudentJobSearchStatus_style'
import Button from 'components/Button/Button'

const StudentJobSearchStatusModal = (props) => {
    const { modalOpen, handleModalClose, dashboard } = props
    const classes = useStyles()

    return (
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={`${classes.modalContent} ${dashboard && classes.modalDashboardContent}`}>
                {dashboard &&
                    <Box className={classes.modalDashboardTextBox}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" className={classes.modalTitle}>
                            <FormattedMessage id='job_search_status_response.title'/>
                        </Typography>
                        <FormattedMessage id={'job_search_status_response.context.dashboard'} className={classes.modalDescription}>
                            {translatedText => <Typography dangerouslySetInnerHTML={{ __html: translatedText }}/>}
                        </FormattedMessage>
                        <Button
                            size='small'
                            variant='cta-secondary'
                            onClick={handleModalClose}
                            className={classes.modalDashboardButton}
                        >
                            <FormattedMessage id={'student_job_search_status.modal.button'} />
                        </Button>
                        <IconButton aria-label="close" onClick={handleModalClose} className={classes.modalClose}>
                            <IconFactory icon='close' />
                        </IconButton>
                    </Box>
                }
                {!dashboard && <>
                    <Typography id="modal-modal-title" variant="h6" component="h2" className={classes.modalTitle}>
                        <FormattedMessage id='job_search_status_response.title'/>
                    </Typography>
                    <FormattedMessage id={'job_search_status_response.context.actively_looking'} className={classes.modalDescription}>
                        {translatedText => <Typography dangerouslySetInnerHTML={{ __html: translatedText }}/>}
                    </FormattedMessage>
                    <Typography className={classes.cta}>
                        <FormattedMessage id='job_search_status_response.cta.for_all' />
                    </Typography>
                    <Button
                        size='small'
                        variant='cta-secondary'
                        onClick={handleModalClose}
                        className={classes.modalButton}
                    >
                        <FormattedMessage id={'student_job_search_status.modal.button'} />
                    </Button>
                    <IconButton aria-label="close" onClick={handleModalClose} className={classes.modalClose}>
                        <IconFactory icon='close' />
                    </IconButton>
                </>}
            </Box>
        </Modal>
    )
}

export default StudentJobSearchStatusModal
