import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    clickableCarousel: {
        cursor: 'pointer',
        position: 'relative'
    },
    fullScreenButton: {
        position: 'absolute',
        bottom: 12,
        right: 16,
        zIndex: 2,
        padding: 8,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.65)'
        },
        [theme.breakpoints.down('md')]: {
            bottom: 10,
            right: 10
        }
    },
    iconImageCaroussel: {
        height: 20,
        width: 20,
        color: '#fff'
    },
    textCaroussel: {
        color: '#fff',
        fontSize: '12px !important',
        fontWeight: 600,
        [theme.breakpoints.down('md')]: {
            fontSize: '10px !important'
        }
    },
    dialogContent: {
        borderRadius: 12,
        height: 680,
        width: 1000,
        display: 'flex',
        flexDirection: 'column'
    },
    imageContainer: {
        height: 500,
        width: 800,
        textAlign: 'center'
    },
    media: {
        objectFit: 'contain',
        height: '100%',
        width: '100%'
    },
    mediaDialog: {
        display: 'block',
        margin: '0 auto',
        maxWidth: '100%',
        maxHeight: '100%'
    },
    arrowLeft: {
        left: 0,
        zIndex: 2,
        position: 'absolute',
        top: '50%',
        marginLeft: 20
    },
    arrowRight: {
        right: 0,
        top: '50%',
        zIndex: 2,
        position: 'absolute',
        marginRight: 20
    },
    arrowBtn: {
        height: 30,
        width: 30,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }
    },
    arrows: {
        width: 32,
        height: 32,
        color: 'rgba(255, 255 ,255 , 0.8)',
        '&:hover': {
            color: '#fff'
        }
    },
    dialogContainer: {
        maxWidth: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    dialogThumbnailContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        overflowX: 'auto',
        width: '100%',
        marginTop: 48,
        whiteSpace: 'nowrap'
    },
    dialogThumbnailSelected: {
        border: '5px solid var(--background-purple)'
    },
    dialogImageThumbnail: {
        height: 90,
        width: 140,
        marginLeft: 16,
        flex: '0 0 auto',
        transition: 'opacity 0.3s',
        '&:hover': {
            opacity: 0.6,
            cursor: 'pointer'
        }
    },
    dialogTitle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    closeButton: {
        padding: 6,
        margin: 0,
        '&:hover': {
            backgroundColor: 'transparent'
        },
        borderRadius: 0
    },
    dialogImageCounter: {
        color: '#fff',
        fontWeight: 600,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        minWidth: 50,
        textAlign: 'center',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        padding: '4px 8px',
        top: 510
    },
    iconImage: {
        marginRight: 8
    },
    dialog: {
        position: 'relative'
    }
}))
