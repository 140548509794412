/* eslint-disable react/prop-types */
import React from 'react'

import Box from '@material-ui/core/Box'
import Fab from '@material-ui/core/Fab'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { injectIntl, FormattedMessage } from 'react-intl'

import { useStyles } from './ChatWindow_style'
import IconFactory from 'icons/IconFactory/IconFactory'
import MessagesDiscussion from 'pages/user/Messages/MessagesMain/MessagesDiscussion'

const ChatWindow = (props) => {
    const { user, job, openChat, handleOpenChat } = props
    if (user && (user.type !== 'student')) return null
    if (!job.chat_only) return null
    if (job.application_url) return null

    const classes = useStyles()

    const talkingWith = {
        name: job.company_name,
        user_id: job.company_user_id
    }

    const toggleOpenChat = () => { handleOpenChat(!openChat) }

    return (
        <Box className={classes.chatWindowMainContainer} >
            {user &&
                <Box mb={2} style={{ display: openChat ? 'block' : 'none' }}>
                    <Paper elevation={4} className={classes.paper}>
                        <MessagesDiscussion
                            chatContext='ChatWindow'
                            chatTitle={job.title}
                            chatType={job.type}
                            talkingWith={talkingWith}
                            user={user}
                            job={job}
                            conversationId={job.conversation_id}
                            candidacyType={job.type}
                        />
                    </Paper>
                </Box>
            }
            <Fab color='secondary' variant='extended' onClick={toggleOpenChat}>
                {openChat
                    ? <>
                        <IconFactory icon='cross' />
                        <Typography variant='body2' className={classes.fabTypography} noWrap>
                            <FormattedMessage id='active_popup.close_button' />
                        </Typography>
                    </>
                    : <>
                        <IconFactory icon='chat' />
                        <Typography variant='body2' className={classes.fabTypography} noWrap>
                            <FormattedMessage id='job_chat.button' />
                        </Typography>
                    </>

                }
            </Fab>
        </Box>
    )
}

export default injectIntl(ChatWindow)
