import React, { useEffect } from 'react'

import { Box, Container, Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './EmployerContactForm_style'
import { usePipedrive } from 'hooks/usePipedrive'

const EmployerContactForm = (props) => {
    const classes = useStyles()
    const { getUrlEmployersContactForm, instantiateContactForm } = usePipedrive()
    const {
        title = <FormattedMessage id="landing_employer.want_to_know_more.title" />,
        subTitle = <FormattedMessage id="landing_employer.want_to_know_more.subtitle" />,
        color = 'white',
        background = 'var(--foreground-purple)'
    } = props

    useEffect(() => {
        instantiateContactForm()
    })

    return (
        <Box id='questions' className={classes.contactContainer} style={{background: background }}>
            <Container maxWidth="lg" className={classes.contactBox}>
                <Box>
                    <Typography className={`${classes.subTitle}`} style={{ color: color }} variant="h6">
                        {title}
                    </Typography>
                </Box>
                <Typography className={`${classes.description}`} style={{ color: color }} >
                    {subTitle}
                </Typography>
                <div className="pipedriveWebForms" data-pd-webforms={getUrlEmployersContactForm()} />
            </Container>
        </Box>
    )
}

export default EmployerContactForm
