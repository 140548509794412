import React, { useEffect, useState } from 'react'

import { Dialog, DialogTitle, DialogContent } from '@material-ui/core'

import { useStyles } from './EmployerLeadGenerationModal_style'
import IconButton from '@material-ui/core/IconButton'
import IconFactory from 'shared/icons/IconFactory/IconFactory'
import EmployerContactForm from 'components/EmployerContactForm/EmployerContactForm'
import { FormattedMessage } from 'react-intl'

const EmployerLeadGenerationModal = (props) => {
    const classes = useStyles()
    const { showModal, closeModal } = props

    return (
        <Dialog
            open={showModal}
            onClose={closeModal}
            className={classes.container}
        >
            <DialogTitle classes={{ root: classes.dialogTitleRoot }} disableTypography>
                <IconButton
                    aria-label="close"
                    onClick={closeModal}
                    style={{ position: 'absolute', right: 5, top: 5 }}
                >
                    <IconFactory icon='close' />
                </IconButton>
            </DialogTitle>
            <DialogContent classes={{root: classes.dialogContentRoot }}>
                <EmployerContactForm
                    title={<FormattedMessage id='employer_lead_generation_modal.contact_form_title' />}
                    subTitle=''
                    color='var(--black)'
                    background='var(--beige)'
                />
            </DialogContent>
        </Dialog>
    )
}

export default EmployerLeadGenerationModal
