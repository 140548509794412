import React, { useState } from 'react'
import { Image } from 'cloudinary-react'

import { useStyles } from './MobileAppBar_style'
import { useStyles as useDarkModeStyles } from './MobileAppBarDarkMode_style'
import {
    AppBar, Hidden,
    IconButton, Box
} from '@material-ui/core'

import IconFactory from 'icons/IconFactory/IconFactory'
import MobileBurgerMenu from '../MobileBurgerMenu/MobileBurgerMenu'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import ProfileHelper from 'assets/ProfileHelper'

const MobileAppBar = (props) => {
    const { altLangUrls, user, paramsForUrlTranslation, darkMode } = props
    const classes = darkMode ? useDarkModeStyles() : useStyles()
    const [open, setOpen] = useState(false)

    const openModal = () => {
        setOpen(true)
    }

    const closeModal = () => {
        setOpen(false)
    }

    const getUserButtonURL = () => {
        if (user) {
            return ProfileHelper.GetProfileUrl(user.type)
        } else {
            return 'login'
        }
    }

    return (
        <Hidden mdUp>
            <MobileBurgerMenu
                open={open}
                closeModal={closeModal}
                user={user}
                altLangUrls={altLangUrls}
                paramsForUrlTranslation={paramsForUrlTranslation}
            />
            <AppBar position='static' color='white' elevation={0} className={classes.appBar}>
                <RouteMapLink page='/'>
                    <Image
                        className={classes.logo}
                        cloudName='studentbe'
                        publicId='student-be-logos/Student_2024_Logo_Main.png'
                        alt='Student.be'
                    />
                </RouteMapLink>
                <Box className={classes.iconsContainer}>
                    <RouteMapLink page={getUserButtonURL()}>
                        <IconButton>
                            <IconFactory icon='account' className={classes.burgerIcon} />
                        </IconButton>
                    </RouteMapLink>
                    <IconButton
                        edge='start'
                        onClick={openModal}
                        className={classes.button}
                        disableRipple
                    >
                        <IconFactory icon='bars' className={classes.burgerIcon} />
                    </IconButton>
                </Box>
            </AppBar>
        </Hidden>
    )
}

export default MobileAppBar
