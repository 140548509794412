export const MAX_EXPORT_SIZE = 160000

export const waitMessages = [
    'Please wait while the minions do their work..',
    'What do you call 8 Hobbits? A Hobbyte..',
    'Sit back, relax and enjoy the ride..',
    'Keep calm and have a cookie..',
    'Brought to you by your favourite dev team..',
    'A student.be production..',
    'Grabbing extra minions..',
    'Doing some heavy lifting..',
    "You are top of our priority list..",
    'Waking up the minions..',
    'You are number 12 in the queue..',
    'Please wait while Matth does his VLOOKUP..',
    'When bored, talk to Matth..',
    'Max begrijpt deze zin (nog) niet..',
    'Gimme gimme gimme..',
    'Bada bing, bada boom..',
    'We will make you an export you cannot refuse..',
    "Gelukkig met je ges, gelukkig met je leven..",
    "De data is onderweg wi..",
    "Ma how seg..",
    'Boooooooooooooring..',
    'Straight out of Poelkapelle ©',
    'We do the work, Ben brings the "sfeer"..',
    'Héloise Loumaye is officialy the worst loser of Student.be, fortunately for us she wins a lot..'
]
