import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    main: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        margin: '40px 0',
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: 'unset',
            display: 'flex',
            flexDirection: 'column',
            margin: 0
        }
    },
    content: {
    },
    pageTitle: {
        fontSize: 32,
        color: 'var(--red)',
        width: '80%',
        [theme.breakpoints.down('md')]: {
            fontSize: 28,
            width: '100%'
        }
    },
    divider: {
        border: '2px solid var(--red)',
        width: '120px',
        borderRadius: 12,
        margin: '12px 0'
    },
    loginForm: {
        backgroundColor: 'var(--beige)',
        borderRadius: 12,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 12,
        [theme.breakpoints.down('md')]: {
            backgroundColor: 'white',
        }
    },
    alreadyLoggedIn: {
        padding: 40,
        '& > p': {
            margin: '20px 0',
        },
        [theme.breakpoints.down('md')]: {
            padding: 32,
            backgroundColor: 'var(--beige)',
            borderRadius: 12,
        }
    },
    wheelOfFortune: {
        width: 400,
        animation: 'spin 20s linear 0s infinite',
        margin: '40px',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            width: '100%',
            margin: '12px auto',
            maxWidth: 400,
        }
    },
}))
