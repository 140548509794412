import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    articleHeaderContainer: {
        marginBottom: 30
    },
    mainGridContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    backButtonContainer: {
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            height: 44
        }
    },
    backButtonBox: {
        position: 'absolute',
        left: 0,
        top: 16
    },
    leaderBoardAd: {
        [theme.breakpoints.up('lg')]: {
            marginLeft: '-90px'
        }
    },
    articleContentContainer: {
        margin: 'auto'
    },
    imageStyle: {
        width: '100%',
        borderRadius: '12px',
        marginBottom: 20
    },
    articleTextContainer: {
        overflowY: 'visible'
    },
    ebookDownloadContainer: {
        borderRadius: '20px',
        backgroundColor: 'var(--background-yellow)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
        [theme.breakpoints.up('md')]: {
            padding: '30px'
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: '20px',
            padding: '16px'
        }
    },
    ebookDownloadButton: {
        '& span': {
            display: 'flex',
            alignItems: 'center',
            gap: 8
        }
    },
    CTAAlert: {
        backgroundColor: 'var(--background-purple)',
        '& p': {
            color: 'white'
        }
    },
    rightColumn: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20
    },
    recommendedJobsBox: {
        marginTop: 100,
        [theme.breakpoints.down('sm')]: {
            marginTop: 40
        }
    }
}))
