import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: 'transparent'
    },
    container: {
        padding: '14px 0',
        gap: '20px',

        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        zIndex: '10 !important'
    },
    logoImage: {
        width: '112px'
    },
    navigationLeftContainer: {
        gap: '24px',

        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    navigationButtonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: '2px'
    }
}))
