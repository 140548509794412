import React from 'react'
import {
    Switch,
    Route
} from 'react-router-dom'

import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import ProgressCircle from 'components/ProgressCircle/ProgressCircle'
import MessagesHeader from '../MessagesHeader/MessagesHeader'
import MessagesEmployerIndex from '../EmployerSpecific/MessagesEmployerIndex'
import MessagesDiscussion from './MessagesDiscussion'

import ConversationAPI from 'api/ConversationAPI'

export default class MessageMainEmployer extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            offers: [],
            indexType: 'byMsg',
            focusCandidacies: [],
            candidacies: [],
            itemFocus: '',
            itemType: '',
            itemId: null,
            isChatting: 'notChatting',
            messages: [],
            chatId: '',
            chatType: '',
            talkingWith: {},
            chatTitle: '',
            conversationPanelPage: 1,
            loadingPanel: true,
            lastPage: false,
            conversationsLoaded: false
        }
        this.switchIndexType = this.switchIndexType.bind(this)
        this.openItem = this.openItem.bind(this)
        this.getCandidacies = this.getCandidacies.bind(this)
        this.getOffers = this.getOffers.bind(this)
        this.loadMore = this.loadMore.bind(this)
        this.pathname = window.location.pathname
        this.lang = this.pathname.match(/^\/([^\/]*)\//)[1]
    }

    componentDidMount () {
        if (this.state.indexType === 'byOffer') {
            this.getOffers()
        } else if (this.state.indexType === 'byMsg') {
            this.getCandidacies()
        }
    }

    switchIndexType (type) {
        this.setState({
            indexType: type
        })
    }

    getCandidacies () {
        this.setState({ loadingPanel: true })
        ConversationAPI.GetCandidacies({
            page: this.state.conversationPanelPage
        }).then(response => {
            this.setState({
                loadingPanel: false,
                indexType: 'byMsg',
                candidacies: this.state.candidacies.concat(response.data.candidacies),
                lastPage: response.data.last_page,
                conversationPanelPage: this.state.conversationPanelPage + 1,
                conversationsLoaded: true
            })
        })
    }

    getOffers () {
        this.setState({ loadingPanel: true })
        ConversationAPI.GetOffers().then(response => {
            this.setState({
                loadingPanel: false,
                indexType: 'byMsg',
                offers: response.data.offers
            })
        })
    }

    openItem (type, id, itemFocus, page = 1) {
        ConversationAPI.GetConversations({
            item_id: id,
            item_type: type,
            page: page
        }).then(response => {
            let newCandidacies = []
            if (page > 1) {
                newCandidacies = this.state.candidacies.concat(response.data.conversations)
            } else {
                newCandidacies = response.data.conversations
            }
            this.setState({
                indexType: 'byFocus',
                itemType: response.data.type,
                itemId: id,
                itemFocus: response.data.item_focus,
                candidacies: newCandidacies,
                conversationPanelPage: page,
                lastPage: response.data.last_page
            })
        })
    }

    loadMore () {
        this.setState({
            conversationPanelPage: this.state.conversationPanelPage + 1
        }, () => {
            this.openItem(
                this.state.itemType,
                this.state.itemId,
                this.state.itemFocus,
                this.state.conversationPanelPage
            )
        })
    }

    render () {
        return (
            <PageWrapper user={this.props.user} disableFooter>
                <div className="messages-container messages-kot-owner">
                    <div className="messages-index-container">
                        <MessagesHeader
                            switchIndexType={this.switchIndexType}
                            switchIndexTypeString="byOffer"
                            type="employer"
                            user={this.props.user}
                            byOfferStatus={true}
                        />
                        <div className="candidacy-message-index">
                            {this.state.loadingPanel && <ProgressCircle small />}
                            <MessagesEmployerIndex
                                openItem={this.openItem}
                                loadMore={this.loadMore}
                                loadMoreByMsg={this.getCandidacies}
                                switchIndexType={this.switchIndexType}
                                indexType={this.state.indexType}
                                itemFocus={this.state.itemFocus}
                                itemType={this.state.itemType}
                                offers={this.state.offers}
                                candidacies={this.state.candidacies}
                                lastPage={this.state.lastPage}
                                loading={this.state.loadingPanel}
                            />
                        </div>
                    </div>
                    <div className={`messages-discussion-and-apllications ${this.state.chatType.replace(' ', '_')}`}>
                        <div className="messages-discussion">
                            {this.state.conversationsLoaded &&
                                <Switch>
                                    <Route
                                        path='/messages/:candiid/:candiType/:conversationid'
                                        render={(props) =>
                                            <MessagesDiscussion
                                                key={`conversation-${this.state.chatId}`}
                                                {...props}
                                                user={this.props.user}
                                                messages={this.messages}
                                            />
                                        }
                                    />
                                </Switch>
                            }
                        </div>
                        <div className="messages-applications"></div>
                    </div>
                </div>
            </PageWrapper>
        )
    }
}
