/* eslint-disable react/prop-types */
import React from 'react'

import RouteMap from 'assets/RouteMap.js'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import SharedTabs from 'components/SharedTabs/SharedTabs'
import Searchbar from 'components/Searchbar/Searchbar'
import ItemListTitleWrapper from 'components/ItemListTitleWrapper/ItemListTitleWrapper'
import ListContainer from 'containers/ListContainer/ListContainer'
import CheckButton from 'buttons/CheckButton/CheckButton'
import LineButton from 'buttons/LineButton/LineButton'
import FilledButton from 'buttons/FilledButton/FilledButton'
import PopupFactory from 'modals/Popup/PopupFactory'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import FeedbackFactory from 'components/FeedbackFactory/FeedbackFactory'
import SVGFactory from 'icons/SVGFactory/SVGFactory'
import AttentionBanner from 'components/AttentionBanner/AttentionBanner'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Container from '@material-ui/core/Container'

import { FormattedMessage } from 'react-intl'

import axios from 'axios'

import css from './myitemselectionindexmain.scss'
import css_responsive from './myitemselectionindexmain_responsive.scss'

export default class MyItemSelectionIndexMain extends React.Component {
    constructor (props) {
        super(props)

        this.types = [
            [<FormattedMessage id='chat.first_job'/>, 'first_job'],
            [<FormattedMessage id='chat.internship'/>, 'internship'],
            [<FormattedMessage id='chat.student_job'/>, 'student_job']
        ]
        if (props.type === 'favourite') {
            this.types.push(['Kot', 'kot'])
        } else if (props.type === 'kot_alt') {
            this.types = [['Kot', 'kot']]
        }
        this.paginate = this.paginate.bind(this) // should be before state
        this.noPaginate = this.noPaginate.bind(this)
        this.byType = this.byType.bind(this)
        this.notByType = this.notByType.bind(this)
        this.state = {
            numberOfCredits: props.user.number_of_credits,
            myItemSelection: props.myItemSelection,
            type: '',
            title: '',
            checkedItems: {},
            currentPage: 0,
            /* PAGINATION: to add in pagination, "add && props.type != "TYPE"" to the following line */
            paginate: window.innerWidth >= 1140 && ['alert', 'candidacy', 'kot_alt', 'favourite'].includes(props.type) ? this.paginate : this.noPaginate,
            itemDivSelected: '',
            itemSelected: '',
            byType: props.type === 'favourite' ? this.byType : this.notByType,
            sortbykey: 'updated_at',
            sortbyopp: function (x) { return -x },
            itemsToRemove: null,
            showPopuDelete: false,
            showPopupShare: false
        }
        this.listCardsRef = []
        for (let i = 0; i < this.types.length; i++) {
            this.listCardsRef.push(React.createRef())
        }
        this.checkAllRef = React.createRef()
        this.indexRef = React.createRef()
        this.showRef = React.createRef()
        this.feedbackRef = React.createRef()

        this.setNewState = this.setNewState.bind(this)
        this.handleFilterChange = this.handleFilterChange.bind(this)
        this.checkItem = this.checkItem.bind(this)
        this.checkAll = this.checkAll.bind(this)
        this.remove = this.remove.bind(this)
        this.removeprocess = this.removeprocess.bind(this)
        this.add = this.add.bind(this)
        this.applyAll = this.applyAll.bind(this)
        this.changeCheckAll = this.changeCheckAll.bind(this)
        this.createListCardsPaginate = this.createListCardsPaginate.bind(this)
        this.createListCard = this.createListCard.bind(this)
        this.nextPage = this.nextPage.bind(this)
        this.previousPage = this.previousPage.bind(this)
        this.setPage = this.setPage.bind(this)
        this.createPageBubble = this.createPageBubble.bind(this)
        this.setSortBy = this.setSortBy.bind(this)
        this.renderPopup = this.renderPopup.bind(this)
        this.duplicateItem = this.duplicateItem.bind(this)
        this.handleCloseAlert = this.handleCloseAlert.bind(this)
    }

    componentDidMount () {
        axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()

        if (this.props.paymentSessionId) {
            this.feedbackRef.current.triggerFeedback(true, <FormattedMessage id="job_form.job_posted" />)
        }
        this.setSortBy('active', true)
        window.addEventListener('resize',
            () => {
                this.setState(
                    window.innerWidth >= 1140 && this.props.type !== 'kot_alt'
                        ? { paginate: this.noPaginate }
                        : { paginate: this.paginate }
                )
            })
    }

    componentWillUnMount () {
        window.removeEventListener('resize',
            () => {
                this.setState(
                    window.innerWidth >= 1140 && this.props.type !== 'kot_alt'
                        ? { paginate: this.noPaginate }
                        : { paginate: this.paginate }
                )
            })
    }

    setNewState (newState) {
        this.handleFilterChange(newState)
    }

    setSortBy (key, initial = false) {
        if (initial) {
            this.setState({ sortbykey: key, sortbyopp: function (x) { return -x } })
        } else if (this.state.sortbykey !== key) {
            this.setState({ sortbykey: key, sortbyopp: function (x) { return x } })
        } else if (this.state.sortbyopp(1) === 1) {
            this.setState({ sortbykey: key, sortbyopp: function (x) { return -x } })
        } else {
            this.setState({ sortbykey: key, sortbyopp: function (x) { return x } })
        }
    }

    moveTo (className) {
        const toGo = document.querySelector(className)
        if (toGo) {
            toGo.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }

    nextPage () {
        if (this.state.currentPage < this.state.myItemSelection.length / this.props.itemPerPage - 1) {
            this.setPage(this.state.currentPage + 1)
        }
    }

    previousPage () {
        if (this.state.currentPage > 0) {
            this.setPage(this.state.currentPage - 1)
        }
    }

    setPage (page) {
        if (page > -1 && page > -1) {
            this.setState({
                currentPage: page,
                checkedItems: {}
            }, () => {
                this.moveTo('.top')
                this.changeCheckAll()
            })
        }
    }

    getMyItemSelection (type, title) {
        let res = this.props.myItemSelection
        if (type === this.state.type && title > this.state.title) {
            res = this.state.myItemSelection
        }
        return res
    }

    changeCheckAll () {
        if (this.checkAllRef.current) {
            if (this.state.paginate === this.paginate) {
                const end = this.props.itemPerPage * (this.state.currentPage + 1)
                const onPage = end > this.state.myItemSelection.length ? this.props.itemPerPage - (end - this.state.myItemSelection.length) : this.props.itemPerPage
                this.checkAllRef.current.setCheckNoFire(Object.keys(this.state.checkedItems).length === onPage)
            } else {
                this.checkAllRef.current.setCheckNoFire(Object.keys(this.state.checkedItems).length === this.state.myItemSelection.length)
            }
        }
    }

    remainingCheckedItem (newItemSelection) {
        const remainingCheckedItem = {}
        const oldCheckedItem = this.state.checkedItems
        newItemSelection.forEach((item) => {
            if (item[this.props.idKey] in oldCheckedItem) {
                remainingCheckedItem[item[this.props.idKey]] = item
            }
        })
        return remainingCheckedItem
    }

    handleFilterChange (event) {
        const type = event.type
        const title = event.title
        const newItemSelection = this.getMyItemSelection(type, title).filter(item => {
            return ((type === '' || item[this.props.type === 'kot_alt' ? 'kot_type' : this.props.itemTypeKey] === type) &&
                (title === '' || (item[this.props.itemTitleKey] && item[this.props.itemTitleKey].toLowerCase().includes(title.toLowerCase()))))
        })
        this.setState({
            myItemSelection: newItemSelection,
            type: type,
            title: title,
            checkedItems: this.remainingCheckedItem(newItemSelection),
            currentPage: 0
        }, this.changeCheckAll())
    }

    checkItem (ref, item) {
        const newCheckedItems = this.state.checkedItems
        if (item[this.props.idKey] in newCheckedItems) {
            delete newCheckedItems[item[this.props.idKey]]
            this.changeCheckAll()
        } else {
            newCheckedItems[item[this.props.idKey]] = item
        }
        this.setState({ checkedItems: newCheckedItems }, this.changeCheckAll())
    }

    checkAll (check) {
        this.listCardsRef.forEach(function (listRef) {
            if (listRef.current) {
                listRef.current.listCardsRef.forEach(function (elem) {
                    if (elem.current) {
                        elem.current.setCheck(check)
                    }
                })
            }
        })
    }

    add (itemToAdd) {
        itemToAdd[this.props.idKey] = itemToAdd.type + '-' + itemToAdd.id
        this.props.myItemSelection.push(itemToAdd)
    }

    toDoToItems (items, axiosParm, axiosThen) {
        const idByType = {}
        this.types.forEach((type) => {
            idByType[type[1]] = []
        })
        items.forEach((item) => {
            idByType[item[this.props.itemTypeKey]].push(item.id)
        })
        const promises = []
        this.types.forEach((type) => {
            const idImpact = idByType[type[1]]
            if (idImpact.length !== 0) {
                promises.push(axiosParm(type[1], idImpact))
            }
        })
        this.axiosSequence(promises, 0, axiosThen)
    }

    toArray (itemsToArray) {
        let res = itemsToArray
        if (!Array.isArray(res)) {
            res = [res]
        }
        return res
    }

    renderPopup (popupType, infos = null) {
        this.setState({
            infos: infos,
            [popupType]: !this.state[popupType]
        })
    }

    duplicateItem (item) {
        axios({
            method: 'post',
            url: this.props.duplicateUrl[item.type],
            params: {
                id: item.id
            }
        }).then((response) => {
            window.location.href = RouteMap.Edit(response.data.type, response.data.id)
        })
    }

    removeprocess (deleteInfos) {
        var itemsToRemove = this.state.itemsToRemove

        const newItemSelection = this.state.myItemSelection
        const newCheckedItems = this.state.checkedItems
        itemsToRemove.forEach((item) => {
            newItemSelection.splice(newItemSelection.indexOf(item), 1)
            if (newItemSelection !== this.props.myItemSelection) {
                this.props.myItemSelection.splice(this.props.myItemSelection.indexOf(item), 1)
            }
            delete newCheckedItems[item[this.props.idKey]]
        })
        this.toDoToItems(itemsToRemove, (type, id) => {
            return {
                url: this.props.deleteUrl,
                method: 'delete',
                params: {
                    type: type,
                    id: id,
                    delete_reason: deleteInfos
                }
            }
        }, function () {})
        this.setState({
            myItemSelection: newItemSelection,
            checkedItems: newCheckedItems,
            itemsToRemove: null
        }, this.changeCheckAll())

        this.feedbackRef.current.triggerFeedback(true, <FormattedMessage id="delete_offer_popup.company-job.success_message" />)
    }

    remove (itemsToRemove) {
        itemsToRemove = this.toArray(itemsToRemove)
        this.setState({
            itemsToRemove: itemsToRemove
        })
    }

    axiosSequence (promises, index, axiosThen) {
        if (promises.length > index) {
            axios(promises[index]).then((response) => {
                axiosThen(response.data)
                this.axiosSequence(promises, index + 1, axiosThen)
            })
        }
    }

    getAllSelected () {
        const itemSelected = []
        Object.keys(this.state.checkedItems).forEach((key) => {
            itemSelected.push(this.state.checkedItems[key])
        })
        return itemSelected
    }

    applyAll (functionToCall) {
        functionToCall(this.getAllSelected())
    }

    paginate (item, start, end, current) {
        if (current + item.length < start || current > end) {
            return []
        }
        return item.slice(Math.max(0, start - current), end - current)
    }

    noPaginate (item) {
        return item
    }

    byType (start, end, current, res) {
        this.types.forEach((type, index) => {
            let itemOfType = this.state.myItemSelection.filter(item => item[this.props.itemTypeKey] === type[1])
            const toAdd = itemOfType.length
            itemOfType = this.state.paginate(itemOfType, start, end, current)
            current += toAdd
            if (itemOfType.length !== 0) {
                this.createListCard(res, type, itemOfType, index)
            } else if (res.length === 0 && this.state.myItemSelection.length === 0) {
                res.push(
                    <Box my={8}>
                        <Typography align='center'>
                            <FormattedMessage id='item_index.no_found_favourite' />
                        </Typography>
                    </Box>
                )
            }
        })
    }

    notByType (start, end, current, res, type) {
        let items = this.state.myItemSelection

        if (this.state.sortbykey !== '') {
            // items = items.slice();
            items.sort((item1, item2) => {
                let v1 = item1[this.state.sortbykey]
                let v2 = item2[this.state.sortbykey]
                if (this.state.sortbykey === 'company') {
                    v1 = item1.company.name; v2 = item2.company.name
                }
                if (v1 > v2) {
                    return this.state.sortbyopp(1)
                } else if (v1 < v2) {
                    return this.state.sortbyopp(-1)
                }
                return 0
            })
        }

        items = this.state.paginate(items, start, end, current)
        this.createListCard(res, type, items)
    }

    createListCardsPaginate () {
        const start = this.props.itemPerPage * this.state.currentPage
        const end = this.props.itemPerPage * (this.state.currentPage + 1)
        const current = 0
        const res = []

        this.state.byType(start, end, current, res)
        return res
    }

    createListCard (res, type, items, index = 0) {
        if (this.props.type === 'alert') {
            var tagInfos = {
                types: this.props.types,
                fieldOfStudy: this.props.fieldOfStudy,
                roomCounts: this.props.roomCounts,
                prices: this.props.prices,
                disponibilities: this.props.disponibilities
            }
        }

        const itemType = (type ? type[1] : '')

        res.push(
            <ItemListTitleWrapper
                hidden={this.props.type !== 'favourite'}
                type={itemType}
                titleId={`type.${itemType}.plural`}
                moreItemsURL={RouteMap.Index(itemType)}
                seeMoreMobileClassName='see-more-mobile-box'
                titleBoxClassName='item-list-box'
            >
                <ListContainer
                    type={this.props.type}
                    listCards={items}
                    ad={false}
                    tagsInfos= {tagInfos}
                    onCheck={this.checkItem}
                    ref={this.listCardsRef[index]}
                    remove={this.remove}
                    key={'listCards-' + (type ? type[1] : 'only')}
                    idKey={this.props.idKey}
                    popupCall={this.renderPopup}
                    duplicate={this.duplicateItem}
                    jobResultsUrl={this.props.jobResultsUrl}
                    showHeadhunt={this.props.user.show_headhunt}
                    headhuntUrl={this.props.headhuntUrl}
                    user={this.props.user}
                />
            </ItemListTitleWrapper>
        )
    }

    createPageBubble () {
        const res = []
        let offset = 1
        const pagesNumber = Math.ceil(this.state.myItemSelection.length / this.props.itemPerPage - 1)
        if (this.state.currentPage !== 0) {
            offset -= 1
        }
        if (this.state.currentPage === pagesNumber && pagesNumber > 1) {
            offset -= 1
        }
        for (let i = 0; i < Math.min(3, pagesNumber + 1); i++) {
            const page = this.state.currentPage + offset + i
            res.push(<p className={'page-bubble' + (i + offset === 1 ? ' current' : '')} key={'page-' + page} onClick={() => this.setPage(page - 1)}>{page}</p>)
        }
        return res
    }

    changeDiv (itemDivRef, item) {
        const scrollParams = item ? true : { behaviour: 'smooth', block: 'center', inline: 'nearest' }
        const toScroll = item ? this.showRef.current : this.state.itemDivSelected.current
        this.setState({
            itemDivSelected: item ? itemDivRef : this.state.itemDivSelected,
            itemSelected: item
        }, (this.props.changeStage ? () =>
            this.props.changeStage(toScroll, scrollParams) : function () {
            document.querySelector('.banner').classList.toggle('responsive-hide')
            document.querySelector('.nav-bar-container').classList.toggle('responsive-pos-absolute')
            this.indexRef.current.classList.toggle('hide')
            if (toScroll) {
                toScroll.scrollIntoView(scrollParams)
            }
        }))
    }

    createArrows () {
        if (!this.state.myItemSelection.length > this.props.itemPerPage) {
            return <div className="empty" />
        }
        return (
            <div className="arrow-container">
                <div className="single-arrow-container">
                    {this.state.currentPage > 0 &&
                        <>
                            <SVGFactory
                                forwardRef={this.leftArrow}
                                onClick={this.previousPage}
                                name="left-long-arrow"
                                className="arrow"
                            />
                            <div
                                className={'arrow-text ' + (window.innerWidth >= 1140 ? '' : 'hide')}
                                onClick={this.previousPage}
                            >
                                <FormattedMessage id="landing_login_page.preview" />
                            </div>
                        </>
                    }
                </div>
                <div className="page-container">
                    {this.createPageBubble()}
                </div>
                <div className="single-arrow-container">
                    {this.state.currentPage !== Math.floor(this.state.myItemSelection.length / this.props.itemPerPage) &&
                        <>
                            <div
                                className={'arrow-text ' + (window.innerWidth >= 1140 ? '' : 'hide')}
                                onClick={this.nextPage}
                            >
                                <FormattedMessage id="landing_login_page.next" />
                            </div>
                            <SVGFactory
                                forwardRef={this.rightArrow}
                                onClick={this.nextPage}
                                name="right-long-arrow"
                                className="arrow"
                            />
                        </>
                    }
                </div>
            </div>
        )
    }

    createDeleteButton () {
        return (
            <div className="actions-buttons-container">
                <LineButton name=<FormattedMessage id="item_index.button.delete" /> onClick={() => this.applyAll(this.remove)}/>
            </div>
        )
    }

    createActionAll () {
        return (
            <Hidden mdDown>
                <Box mb={3} >
                    <Grid container>
                        <Grid item xs={12} >
                            {this.state.myItemSelection.length !== 0 && this.props.type === 'alert' &&
                                <Box width='100%' height='100%' display='flex' alignItems='center' >
                                    <CheckButton
                                        onClick={this.checkAll}
                                        ref={this.checkAllRef}
                                    />
                                    <Typography
                                        variant='body2'
                                        color='primary'
                                    >
                                        <FormattedMessage id="item_index.select_all" />
                                    </Typography>
                                    <div className="action-all-checked-container">
                                        {Object.keys(this.state.checkedItems).length !== 0 &&
                                            this.createDeleteButton()
                                        }
                                    </div>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Hidden>
        )
    }

    handleCloseAlert (event, reason) {
        if (reason === 'clickaway') {
            return
        }

        this.setState({
            openFeedback: false
        })
    }

    render () {
        return (
            <PageWrapper user={this.props.user} disableFooterLists>
                <SharedTabs user={this.props.user} currentTab={window.location.pathname.replace(/^\/(fr|en|nl)\//g, '')} />
                <AttentionBanner user={this.props.user} />
                <Container maxWidth='lg' className="top">
                    <Box mb={10} className={'my-items-selection-container ' + this.props.type }>
                        <FeedbackFactory ref={this.feedbackRef} status />
                        {this.state.itemsToRemove != null &&
                            <PopupFactory
                                type="delete-offer"
                                itemType={this.props.type}
                                itemsLen={this.state.itemsToRemove.length}
                                popupExit={() => this.setState({ itemsToRemove: null })}
                                actionPopup={this.removeprocess}
                                openShare={this.state.itemsToRemove}
                                popupTitle={<FormattedMessage values={{ count: this.state.itemsToRemove.length }} id={`delete_offer_popup.${this.props.type}.title.${this.state.itemsToRemove.length === 1 ? 'one' : 'more'}`}/>}
                            />
                        }
                        {this.state.showPopupShare &&
                            <PopupFactory
                                type="share-offer"
                                infos={this.state.infos}
                                openShare={this.state.showPopupShare}
                                popupExit={() => this.renderPopup('showPopupShare')}
                            />
                        }
                        <div className="my-items-selection-index" ref={this.indexRef}>
                            {this.props.type !== 'favourite' &&
                                <Grid container>
                                    {(this.props.type !== 'candidacy' || this.props.myItemSelection.length > 0) &&
                                        <Grid item xs={12} md={(this.props.user && this.props.user.type === 'employer') ? 9 : 12 }>
                                            <Searchbar
                                                refreshOnKeyUp
                                                type={this.props.type === 'alert' ? this.props.type : 'myItemSelection'}
                                                searchParams={
                                                    this.props.type === 'kot_alt'
                                                        ? ''
                                                        : this.types.slice(0)
                                                }
                                                state={this.state}
                                                setState={this.setNewState}
                                                handleFilterChange={this.handleFilterChange}
                                                searchPlaceholderFR={this.props.searchPlaceholderFR}
                                            />
                                        </Grid>
                                    }
                                    {this.props.user && this.props.user.type === 'employer' &&
                                        <Grid item xs={12} md={3} style={{ display: 'flex', alignItems: 'center' }}>
                                            <RouteMapLink page='employer/new-ad' style={{ width: '100%', padding: 'auto 40px', marginLeft: '4px' }}>
                                                <FilledButton
                                                    name={<FormattedMessage id='item_index.post_offer' />}
                                                    color='secondary'
                                                    style={{ color: 'white', width: '100%' }}
                                                />
                                            </RouteMapLink>
                                        </Grid>
                                    }
                                </Grid>
                            }
                            {!['alert', 'favourite'].includes(this.props.type) && this.createActionAll()}
                            {this.createListCardsPaginate()}
                            {this.state.myItemSelection.length > this.props.itemPerPage &&
                                this.createArrows()
                            }
                        </div>
                    </Box>
                </Container>
            </PageWrapper>

        )
    }
}
