import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    resetButton: {
        width: (props) => props.smallIcon ? 28 : 49,
        minWidth: (props) => props.smallIcon ? 28 : 49,
        height: (props) => props.smallIcon ? 28 : 49,
        padding: '0px',
        backgroundColor: theme.palette.bg.black,
        borderRadius: '80px',
        border: `1px solid ${theme.palette.fg.black}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: theme.palette.bg.black,
        },
    },

    resetIcon: {
        width: (props) => props.smallIcon ? 16 : 21,
        height: (props) => props.smallIcon ? 16 : 21,
    }
}))
