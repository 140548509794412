/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import React from 'react'
import PropTypes from 'prop-types'

import ObjectTag from './ObjectTag/ObjectTag'

import css from './objecttaglist.scss'
import css_responsive from './objecttaglist_responsive.scss'

import Button from '@material-ui/core/Button'
import { FormattedMessage } from 'react-intl'

export default class ObjectTagList extends React.Component {
    /*

    COLLECTION: you pass an ARRAY of OBJECTS.
    Each OBJECT has a :name
    Each OBJECT has a :value

    */

    constructor (props) {
        super(props)

        this.state = {
            selectedTags: this.props.value
        }

        this.activateAll = this.activateAll.bind(this)
        this.resetAll = this.resetAll.bind(this)
        this.getSelectedTags = this.getSelectedTags.bind(this)
        this.handleFilterChange = this.handleFilterChange.bind(this)
        this.createSelectAllButton = this.createSelectAllButton.bind(this)
    }

    componentDidMount () {
        this.props.onRef(this)
    }

    componentWillUnmount () {
        this.props.onRef(undefined)
    }

    activateAll () {
        for (var ref in this.refs) {
            this.refs[ref].activate()
        }
        this.setState({
            selectedTags: this.props.collection.map(tag => tag.value)
        }, () => {
            this.handleFilterChange()
        })
    }

    resetAll () {
        for (var ref in this.refs) {
            this.refs[ref].reset()
        }
        this.setState({
            selectedTags: []
        }, () => {
            this.handleFilterChange()
        })
    }

    handleFilterChange () {
        if (this.props.onFilterChange) {
            this.props.onFilterChange(this.getSelectedTags())
        }
        if (this.props.canSubmit) {
            this.props.canSubmit()
        }
    }

    getSelectedTags () {
        const selectedTags = []
        for (var ref in this.refs) {
            if (this.refs[ref].state.checked) {
                selectedTags.push(this.refs[ref].props.item.value)
            }
        }
        return selectedTags
    }

    createTagList () {
        const value = this.props.value
        const tags = []
        this.props.collection.forEach((item, index) => {
            tags.push(
                <ObjectTag
                    item={item}
                    key={item.value}
                    name={item.value}
                    onFilterChange={this.props.onFilterChange}
                    value={this.props.value && this.props.value.includes(item.value)}
                    onTagClick={this.handleFilterChange}
                    ref={item.value}
                    articleTag={this.props.articleTag}
                    onEngineerClick={this.props.onEngineerClick}
                />
            )
        })
        return tags
    }

    createSelectAllButton () {
        if (this.props.enableSelectAll) {
            const selectAll = this.state.selectedTags.length < this.props.collection.length
            return (
                <Button
                    color='primary'
                    onClick={selectAll ? this.activateAll : this.resetAll }
                    size='small'
                    style={{ display: 'flex', justifyContent: 'flex-start', width: 'fit-content' }}
                >
                    {
                        selectAll
                            ? <FormattedMessage id="object_tag_list.select_all_1" />
                            : <FormattedMessage id="object_tag_list.select_all_2" />
                    }
                </Button>
            )
        }
    }

    render () {
        return (
            <div className="tag-list-content">
                <div className={'tag-list' + (this.props.open ? ' open' : '')}>
                    <div className="tags-container">
                        {this.createTagList()}
                    </div>
                    {
                        !this.props.disabledSelectAll && this.createSelectAllButton()
                    }
                </div>
                <p className="error">
                    {this.props.error}
                </p>
            </div>
        )
    }
}

ObjectTagList.defaultProps = {
    open: true,
    translation: {
        objectTagList: {
            select_all_1: 'Tout sélectionner',
            select_all_2: 'Tout déselectionner'
        }
    }
}
