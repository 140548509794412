/* eslint-disable react/prop-types */

import React from 'react'

import css from './openquestions.scss'
import Field from 'inputs/Field/Field'
import IconFactory from 'shared/icons/IconFactory/IconFactory'
import FeedbackFactory from 'components/FeedbackFactory/FeedbackFactory'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Button from 'shared/components/Button/Button'
import { FormattedMessage, injectIntl } from 'react-intl'

class OpenQuestions extends React.Component {
    constructor (props) {
        super(props)

        this.state = {
            question: {
                id: null,
                name: '',
                answers: [
                    { name: '', id: '' },
                    { name: '', id: '' }
                ]
            },
            validatedQuestions: this.props.openQuestions || [],
            questionNumber: 0,
            questionToEdit: false
        }
        this.addNewAnswer = this.addNewAnswer.bind(this)
        this.renderAnswersFields = this.renderAnswersFields.bind(this)
        this.handleAnswerChange = this.handleAnswerChange.bind(this)
        this.handleQuestionChange = this.handleQuestionChange.bind(this)
        this.validateQuestion = this.validateQuestion.bind(this)
        this.editQuestion = this.editQuestion.bind(this)
        this.deleteQuestion = this.deleteQuestion.bind(this)

        this.feedbackRef = React.createRef()
    }

    getQuestions () {
        return this.state.validatedQuestions
    }

    handleQuestionChange (event) {
        var newQuestion = this.state.question
        const { name, value } = event.target
        newQuestion.name = value

        this.setState({ question: newQuestion, validated: false })
    }

    handleAnswerChange (event, answerId) {
        var newQuestion = this.state.question
        var newAnswers = newQuestion.answers

        const { name, value } = event.target
        newAnswers[answerId] = { name: value, id: value }
        newQuestion.answers = newAnswers
        this.setState({ question: newQuestion })
    }

    validateQuestion () {
        const { formatMessage } = this.props.intl
        const validatedQuestion = this.state.question
        if (validatedQuestion.id == null && this.props.jobIsActive) {
            let questionError = 'cannot_add_question_when_edit_job'

            this.feedbackRef.current.triggerFeedback(false, <FormattedMessage id={`open_questions.error.${questionError}`} />)
        } else {
            let questionError = false
            if (validatedQuestion.id == null) {
                validatedQuestion.id = validatedQuestion.name
            }
            var newValidatedQuestions = this.state.validatedQuestions

            if (validatedQuestion.name == '') {
                questionError = 'title_missing'
            } else {
                for (var answer in validatedQuestion.answers) {
                    if (validatedQuestion.answers[answer].name == '') {
                        questionError = 'complete_all_question'
                    }
                }
            }

            if (questionError) {
                this.feedbackRef.current.triggerFeedback(false, <FormattedMessage id={`open_questions.error.${questionError}`} />)
            } else if (this.state.questionToEdit) {
                var newValidatedQuestions = []
                for (var elem in this.state.validatedQuestions) {
                    if (this.state.validatedQuestions[elem].id != (this.state.questionToEdit)) {
                        newValidatedQuestions.push(this.state.validatedQuestions[elem])
                    }
                }
                newValidatedQuestions.push(validatedQuestion)
                this.setState({
                    validatedQuestions: newValidatedQuestions,
                    questionToEdit: false,
                    question: {
                        id: null,
                        name: '',
                        answers: [
                            { name: '', id: '' },
                            { name: '', id: '' }
                        ]
                    }
                })
                this.props.handleQuestionsValidated(newValidatedQuestions)
            } else {
                newValidatedQuestions.push(validatedQuestion)
                this.setState({
                    validatedQuestions: newValidatedQuestions,
                    question: {
                        id: null,
                        name: '',
                        answers: [
                            { name: '', id: '' },
                            { name: '', id: '' }
                        ]
                    }
                })
                this.props.handleQuestionsValidated(newValidatedQuestions)
            }
        }
    }

    addNewAnswer (indexQuestion) {
        var newQuestion = this.state.question
        newQuestion.answers.push('')

        this.setState({ question: newQuestion })
    }

    editQuestion (questionId) {
        const newQuestion = this.state.validatedQuestions.find((q) => {
            return q.id === questionId
        })
        newQuestion.index = questionId
        this.setState({
            question: newQuestion,
            questionToEdit: questionId
        })
    }

    deleteQuestion () {
        var newValidatedQuestions = []
        for (var elem in this.state.validatedQuestions) {
            if (this.state.validatedQuestions[elem].id != (this.state.questionToEdit)) {
                newValidatedQuestions.push(this.state.validatedQuestions[elem])
            }
        }
        this.setState({
            validatedQuestions: newValidatedQuestions,
            questionToEdit: false,
            question: {
                id: null,
                name: '',
                answers: [
                    { name: '', id: '' },
                    { name: '', id: '' }
                ]
            }
        })
        this.props.handleQuestionsValidated(newValidatedQuestions)
    }

    deleteAnswer (answerId) {
        const newQuestion = this.state.question
        const oldAnswers = newQuestion.answers
        const newAnswers = []
        for (var elem in oldAnswers) {
            if (elem != answerId) {
                newAnswers.push(oldAnswers[elem])
            }
        }
        newQuestion.answers = newAnswers
        this.setState({
            question: newQuestion
        })
    }

    renderAnswersFields () {
        const questionStruct = this.state.question
        const showDeleteIcon = questionStruct.answers.length > 2
        return questionStruct.answers.map((answer, index) => {
            return (
                <div className="open-question-content" key={index}>
                    <div className="open-question-circle">&#x25CB;</div>
                    <FormattedMessage id="open_questions.add_answer_placeholder">
                        {placeholder =>
                            <Field
                                placeholder={placeholder}
                                value={answer.name}
                                handleChange={
                                    (event) => {
                                        this.handleAnswerChange(event, index)
                                    }
                                }
                                autocomplete="off"
                            />
                        }
                    </FormattedMessage>
                    {!this.props.jobIsActive && showDeleteIcon &&
                        <div className="delete-question-icon">
                            <IconFactory
                                className="exit-icon"
                                icon='close'
                                onClick={() => this.deleteAnswer(index)}
                            />
                        </div>
                    }
                </div>
            )
        })
    }

    renderQuestionBox () {
        return (
            <Paper style={{ padding: '20px' }}>
                <div className="open-question-box-container">
                    <Typography
                        variant='h4'
                        component='h2'
                        color='primary'
                    >
                        {this.state.questionToEdit
                            ? <FormattedMessage id="open_questions.edit_question" />
                            : <FormattedMessage id="open_questions.add_question" />
                        }
                    </Typography>
                    <div className="open-question-main-content">
                        <FormattedMessage id="open_questions.question_placeholder">
                            {placeholder =>
                                <Field
                                    placeholder={placeholder}
                                    name="question-value"
                                    handleChange={this.handleQuestionChange}
                                    value={this.state.question.name}
                                />
                            }
                        </FormattedMessage>
                    </div>

                    {this.renderAnswersFields()}
                    {!this.props.jobIsActive &&
                        <div
                            className="open-question-new-button-container"
                            onClick={() => this.addNewAnswer(0)}
                        >
                            <div className="cross-div">
                                <IconFactory
                                    className="exit-icon"
                                    icon='plus-circle'
                                />
                            </div>
                            <Typography className="open-question-new-button-text" variant='body2'>
                                <FormattedMessage id="open_questions.add_answer" />
                            </Typography>
                        </div>
                    }
                    <Box mt={4}>
                        <Button
                            variant='secondary'
                            onClick={() => this.validateQuestion()}
                            className="validate-button"
                            size='small'
                        >
                            <FormattedMessage id="open_questions.validate_question" />
                        </Button>
                    </Box>
                </div>
            </Paper>
        )
    }

    renderQuestionsTabs () {
        var questionsTabs = []
        let i = 1
        this.state.validatedQuestions.forEach((question, index) => {
            questionsTabs.push(
                <div className="open-question-tab-delete" style={{ marginBottom: '10px' }}>
                    <div
                        key={'question-' + question.id}
                        className={'open-question-tab ' + (this.state.questionToEdit === question.id ? 'current' : '')}
                        onClick={() => this.editQuestion(question.id)}
                    >
                        {(i) + ' : ' + question.name}
                    </div>
                    {!this.props.jobIsActive && this.state.questionToEdit && this.state.question.index === question.id &&
                        <div className="delete-question-icon">
                            <IconFactory
                                className="exit-icon"
                                style={{ cursor: 'pointer' }}
                                icon='delete'
                                onClick={() => this.deleteQuestion()}
                            />
                        </div>
                    }
                </div>
            )
            i++
        })

        let result = (
            <Box>
                <Typography variant='h4' component='h2' color='primary' style={{ marginBottom: 20 }}>
                    <FormattedMessage id="open_questions.my_questions" />
                </Typography>
                {questionsTabs}
            </Box>
        )

        if (questionsTabs.length === 0) {
            result = null
        }

        return result
    }

    render () {
        return (
            <div className={'open-questions-container ' + this.props.type}>
                <FeedbackFactory ref={this.feedbackRef} />
                <Box>
                    <div className="open-question-tabs-container">
                        {this.renderQuestionsTabs()}
                    </div>
                    {this.renderQuestionBox()}
                </Box>
                {this.props.error &&
                    <Typography color='error' variant='body2'>
                        {this.props.error}
                    </Typography>
                }
            </div>
        )
    }
}

export default injectIntl(OpenQuestions)
