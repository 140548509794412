import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    sortByContainer: {
        width: '250px'
    },
    justifyBox: {
        display: 'flex',
        alignItems: 'center'
    },
    orderBox: {
        cursor: 'pointer',
        marginLeft: '40px'
    }
}))
