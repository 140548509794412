import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useStyles } from './PreviewBanner_style'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const PreviewBanner = (props) => {
    const { userValidated } = props
    const classes = useStyles()

    return (
        <Box className={classes.aboutPageBanner}>
            <Container maxWidth="md">
                <Typography variant='h2' component='p' className={classes.titlePage}>
                    {userValidated
                        ? <FormattedMessage id='preview_banner.employer.activate_job'/>
                        : <FormattedMessage id='preview_banner.employer.title'/>
                    }
                </Typography>
                <Typography className={classes.subTitle}>
                    <FormattedMessage id='preview_banner.employer.sub_title' />
                </Typography>
            </Container>
        </Box>
    )
}

export default PreviewBanner
