import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  inputRoot: {
    width: '100%',
    height: '42px',
    padding: '0px 23px',
    borderRadius: '78px',
    border: `1px solid ${theme.palette.fg['black']}`,
    [theme.breakpoints.up('sm')]: {
      height: '50px'
    }
  }
}))
