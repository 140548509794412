export const FUNNEL_NEW = 'new'
export const FUNNEL_EDIT = 'edit'
export const FUNNEL_TYPES = [FUNNEL_NEW, FUNNEL_EDIT]
export const COMPANY_STEP = 'company'
export const JOB_STEP = 'job'
export const CANDIDATE_STEP = 'candidate'
export const OFFER_STEP = 'offer'
export const SETTINGS_STEP = 'settings'
export const PUBLICATION_STEP = 'publication'
export const ESSENTIAL_STEP = 'essential'
export const EXTRA_STEP = 'extra'
export const DIRECTION_FORWARD = 'forward'
export const DIRECTION_BACK = 'back'
export const FIRST_JOB = 'first_job'
export const INTERNSHIP = 'internship'
export const STUDENT_JOB = 'student_job'
export const QUILL_MODULES = {
    clipboard: { matchVisual: false },
    toolbar: {
        container: [
            [{ header: [3, false] }],
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['clean']
        ]
    }
}
