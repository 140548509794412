import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
    keyInfoContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 12,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    keyInfoLeftSideContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '60%',
        gap: 20,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    keyInfoLeftSide: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12
    },
    fontSize16: {
        fontSize: 16
    },
    keyInfoRightSide: {
        display: 'flex',
        flexDirection: 'column',
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    introImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    registrationButtonBox: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    registrationButton: {
        color: 'white !important',
        backgroundColor: '#A000FF !important',
        borderRadius: 20,
        width: 'fit-content',
        padding: '6px 20px',
        textTransform: 'initial',
        fontSize: 16,
        fontWeight: 'bold',
        maxHeight: 40,
        '&:hover': {
            border: '1px solid black',
            boxShadow: '4px 4px 0px 0px black'
        }
    },
    scheduleContainer: {
        margin: '40px auto'
    },
    imageStyle: {
        width: '100%'
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 40
    },
    spanBold: {
        fontWeight: 'bold'
    },
    titleEvent: {
        fontSize: '24px !important'
    },
    datesInfo: {},
    infoTitle: {
        marginTop: 20,
        marginBottom: 20
    },
    subListTitle: {
        marginTop: 20,
    },
    subListLeft: {
        paddingLeft: 15
    },
    partnersBanner: {
        margin: '40px 0px',
        backgroundColor: '#7EE8FC',
        padding: 12,
        textAlign: 'center',
    },
    partnersList: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        gap: 40
    },
    partnerBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '80%',
    },
    extraInfoContainer: {},
    partnerImageBox: {
        width: '100%',
        '& a': {
            display: 'flex'
        }
    },
    partnerImage: {
        width: '80%',
        objectFit: 'contain',
        margin: '1rem auto',
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.05)',
            transition: '.2s'
        }
    },
    typeformWidgetContainer: {
        margin: '20px auto'
    },
    typeformWidget: {
        width: '100%',
        minHeight: 600,
        height: '80vh'
    }
}))
