/* eslint-disable react/prop-types */
import React from 'react'
import TextFilterView from './TextFilterView'

const TextFilter = (props) => {
    const { debouncedFilterChange, filters, setFilters, filterTextId, modal } = props

    const handleInputChange = (event) => {
        const { name, value } = event.target
        setFilters({ ...filters, [name]: value })
        debouncedFilterChange({ ...filters, [name]: value })
    }

    return (
        <TextFilterView
            handleInputChange={handleInputChange}
            filterTextId={filterTextId}
            filters={filters}
            modal={modal}
        />
    )
}

export default TextFilter
