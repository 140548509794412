import React from 'react'
import { Box } from '@material-ui/core'

import Tag from '../Tag/Tag'
import { useStyles } from './TagSingleSelect_style'

const TagSingleSelect = (props) => {
    const classes = useStyles()
    const { collection, value, setValue } = props

    const handleChange = (selectedItem) => {
        if (value === selectedItem.value) {
            setValue('')
        } else {
            setValue(selectedItem.value)
        }
    }

    return (
		<Box className={classes.selectedTagsContainer}>
            {collection.map((item) => {
                const isSelected = item.value === value

                return (
                    <Tag
                        item={item}
                        isSelected={isSelected}
                        handleClick={(item) => handleChange(item)}
                    />
                )
            })}
        </Box>
    )
}

export default TagSingleSelect
