import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    link: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '350px',
        },
    },

    button: {
        width: '100%',
        height: '53px',
        borderRadius: '36px',
        backgroundColor: theme.palette.bg['white'],
        border: `1px solid ${theme.palette.fg['silver']}`,
        padding: '0px 21px 0px 25px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },

    highlightedButton: {
        backgroundColor: theme.palette.bg['purple'],
        '&:hover': {
            backgroundColor: theme.palette.bg['purple'],
        },
    },

    leftContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '10px',
    },

    buttonText: {
        color: theme.palette.fg['black'],
        fontSize: '16px',
        lineHeight: '18px',
    },

    highlightedButtonText: {
        color: theme.palette.fg['white'],
    },

    rightContainer: {
        width: '24px',
        height: '24px',
        borderRadius: '50px',
        backgroundColor: theme.palette.bg['black'],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))
