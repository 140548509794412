import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    boxContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    titlePage: {
        color: theme.palette.fg['black'],
        fontSize: '18px',
        lineHeight: '28px'
    },
    titleContainer: {
        paddingBottom: '18px'
    },
    renderArticles: {
        display: 'flex',
        flexDirection: 'column',
        gap: '23px',
        [theme.breakpoints.up(1228)]: {
            gap: '34px',
        }
    }
}))
