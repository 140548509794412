import React from 'react'

const BuildingSVG = (props) => (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600">
        <path d="M302.1,552.52c17.01,0,31.03,9.41,38.66,23.03,1.84,3.29,5.37,5.26,9.14,5.26h78.45s-54.52-143.93-54.52-143.93l-94.55-8-49.27,151.93h20.75c5.28,0,10.27-2.6,13.16-7.02,8.24-12.64,22.99-21.26,38.16-21.26Z"/>
        <path d="M496.16,93.28H184.41c-14.88,0-26.95,12.06-26.95,26.95v433.63c0,14.88,12.06,26.95,26.95,26.95h54.88c12.67,0,20.85-5.92,29.66-15.02,5.86-6.06,17.53-23.56,25.64-25.8v-54.11c0-14.88,12.06-26.95,26.95-26.95h37.52c14.88,0,26.95,12.06,26.95,26.95l-36.36,80.69c-3.01,6.68,1.88,14.25,9.21,14.25h138.71c14.12,0,25.56-11.44,25.56-25.56V120.22c0-14.88-12.06-26.95-26.95-26.95ZM279.34,340.56c0,14.88-12.06,26.95-26.95,26.95h-7.05c-14.88,0-26.95-12.06-26.95-26.95v-7.05c0-14.88,12.06-26.95,26.95-26.95h7.05c14.88,0,26.95,12.06,26.95,26.95v7.05ZM279.34,218.68c0,14.88-12.06,26.95-26.95,26.95h-7.05c-14.88,0-26.95-12.06-26.95-26.95v-7.05c0-14.88,12.06-26.95,26.95-26.95h7.05c14.88,0,26.95,12.06,26.95,26.95v7.05ZM370.75,340.56c0,14.88-12.06,26.95-26.95,26.95h-7.05c-14.88,0-26.95-12.06-26.95-26.95v-7.05c0-14.88,12.06-26.95,26.95-26.95h7.05c14.88,0,26.95,12.06,26.95,26.95v7.05ZM370.75,218.68c0,14.88-12.06,26.95-26.95,26.95h-7.05c-14.88,0-26.95-12.06-26.95-26.95v-7.05c0-14.88,12.06-26.95,26.95-26.95h7.05c14.88,0,26.95,12.06,26.95,26.95v7.05ZM462.17,340.56c0,14.88-12.06,26.95-26.95,26.95h-7.05c-14.88,0-26.95-12.06-26.95-26.95v-7.05c0-14.88,12.06-26.95,26.95-26.95h7.05c14.88,0,26.95,12.06,26.95,26.95v7.05ZM462.17,218.68c0,14.88-12.06,26.95-26.95,26.95h-7.05c-14.88,0-26.95-12.06-26.95-26.95v-7.05c0-14.88,12.06-26.95,26.95-26.95h7.05c14.88,0,26.95,12.06,26.95,26.95v7.05Z"/>
        <path d="M438.1,52.46H152.01c-21.97,0-39.78,17.81-39.78,39.78v407.97c0,21.97,17.81,39.78,39.78,39.78h84.51c7.09,0,12.83-5.74,12.83-12.83v-69.27c0-21.97,17.81-39.78,39.78-39.78h11.86c21.97,0,39.78,17.81,39.78,39.78v69.27c0,7.09,5.74,12.83,12.83,12.83h84.51c21.97,0,39.78-17.81,39.78-39.78V92.23c0-21.97-17.81-39.78-39.78-39.78Z" fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="44"/>
        <g>
            <rect x="350.09" y="143.87" width="60.94" height="60.94"/>
            <rect x="258.68" y="143.87" width="60.94" height="60.94"/>
            <rect x="350.09" y="265.75" width="60.94" height="60.94"/>
            <rect x="258.68" y="265.75" width="60.94" height="60.94"/>
            <rect x="167.26" y="265.75" width="60.94" height="60.94"/>
            <rect x="167.26" y="143.87" width="60.94" height="60.94"/>
        </g>
    </svg>
)

export default BuildingSVG
