import React, { useState, useRef } from 'react'

import { useStyles } from './NavigationButton_style'
import { useStyles as useDarkModeStyles } from './NavigationButtonDarkMode_style'
import { Box, Button } from '@material-ui/core'

import NavigationDropDownMenu from '../NavigationDropDownMenu/NavigationDropDownMenu'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const NavigationButton = (props) => {
    const { name, page, type, darkMode } = props
    const classes = darkMode ? useDarkModeStyles() : useStyles()
    const [isMenuModalOpen, setMenuModal] = useState(false)
    const delayRef = useRef(null)

    const handleMouseEnter = () => {
        delayRef.current = setTimeout(() => {
            openModal()
        }, 300)
    }

    const handleMouseLeave = () => {
        clearTimeout(delayRef.current)
        closeModal()
    }

    const openModal = () => setMenuModal(true)
    const closeModal = () => setMenuModal(false)

    const isCurrentNavigationButtonActive = () => {
        return page === type
    }

    return (
        <Box onMouseLeave={closeModal}>
            <RouteMapLink page={type} className={classes.link}>
                <Button
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    className={`${classes.navigationButton} ${isCurrentNavigationButtonActive() ? classes.navigationButtonActive : classes.navigationButtonNonActive}`}
                    disableRipple
                >
                    {name}
                </Button>
            </RouteMapLink>
            <NavigationDropDownMenu
                openModal={openModal}
                closeModal={closeModal}
                isMenuModalOpen={isMenuModalOpen}
                type={type}
            />
        </Box>
    )
}

export default NavigationButton
