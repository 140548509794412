const STEP_MARKS = [
    { value: 0, emoji: 'Intro' },
    { value: 1, emoji: 'Name' },
    { value: 2, emoji: 'Study information' },
    { value: 3, emoji: 'Status' },
    { value: 4, emoji: 'Location' }
]

const calculateScore = (user, statusConfig) => {
    let score = 0

    if (user.first_name && user.last_name) score++
    if (user.experiences.length > 0 && (user.experiences[0].end_year || user.experiences[0].high_school_year > 0)) score++
    if (statusConfig.actively_looking_first_job || statusConfig.actively_looking_internship || statusConfig.actively_looking_student_job || statusConfig.actively_looking_kot) score++
    if (user.city) score++

    return score
}

const calculatePercentage = (user, statusConfig) => {
    const score = calculateScore(user, statusConfig)

    return `${Math.round(100 / 4 * score)}%`
}

const getEmoji = (step) => {
    const matchingEmoji = STEP_MARKS.find(mark => mark.value === step)

    return matchingEmoji.emoji
}

const objectHasTrueValue = (obj) => {
    return Object.keys(obj).some(key => obj[key] === true)
}

const MAX_STEP = Object.keys(STEP_MARKS).length

export default {
    STEP_MARKS,
    MAX_STEP,
    getEmoji,
    objectHasTrueValue,
    calculatePercentage
}
