import axios from 'axios'
import { KOT_RECOMMENDED_API, KOT_SIMILAR_API } from './env'
import RouteMap from 'assets/RouteMap.js'

const GetRecommendedKots = (data) => {
    return axios({
        url: KOT_RECOMMENDED_API,
        method: 'get',
        params: data
    })
}

const GetSimilarKots = (data) => {
    return axios({
        url: KOT_SIMILAR_API,
        method: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

export default { GetRecommendedKots, GetSimilarKots }
