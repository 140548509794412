import React from 'react'
import StudentJobFiltersModalView from './StudentJobFiltersModalView'

const StudentJobFiltersModal = (props) => {
    const { reset, debouncedFilterChange, offerCount, showModal, setShowModal, filters, handleFilterChange, handleInputSearchCity, searchCities, citiesInput, setSearchCities, setCitiesInput, setFilters } = props
    if (!showModal) return null

    const closeModal = () => setShowModal(false)

    const setFiltersPerType = (newSelectedItems, type) => { setFilters({ ...filters, [type]: newSelectedItems })}

    return (
        <StudentJobFiltersModalView
            reset={reset}
            showModal={showModal}
            closeModal={closeModal}
            offerCount={offerCount}
            filters={filters}
            debouncedFilterChange={debouncedFilterChange}
            handleFilterChange={handleFilterChange}
            handleInputSearchCity={handleInputSearchCity}
            searchCities={searchCities}
            citiesInput={citiesInput}
            setSearchCities={setSearchCities}
            setCitiesInput={setCitiesInput}
            setFilters={setFilters}
            setFiltersPerType={setFiltersPerType}
        />
    )
}

export default StudentJobFiltersModal
