import React from 'react'

const TiktokSVG = (props) => (
    <svg
        width="37"
        height="40"
        viewBox="0 0 37 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_39_59892)">
            <path
                d="M32.559 9.9166C31.8985 9.9166 31.2659 9.7259 30.7218 9.36776L30.6706 9.3352C30.2845 9.08869 29.959 8.76776 29.6985 8.38171C29.4473 8.00497 29.2659 7.56776 29.1869 7.14916C29.1497 6.95381 29.1357 6.74916 29.1357 6.5445C29.145 5.55381 28.7543 4.59567 28.0566 3.89334C27.359 3.19102 26.4055 2.79102 25.4148 2.79102H20.2985C18.2427 2.79102 16.5776 4.45613 16.5776 6.51195V13.6654C14.3031 13.5538 12.0566 14.0375 10.0148 15.0701C7.61012 16.2887 5.66128 18.1864 4.37756 20.5538C3.09384 22.9259 2.57291 25.5957 2.87059 28.2747C3.16826 30.9538 4.26128 33.4468 6.02872 35.4747C7.80082 37.5073 10.1124 38.9306 12.7311 39.5957C13.8008 39.8654 14.8845 40.0003 15.9683 40.0003C17.5311 40.0003 19.0892 39.7212 20.5822 39.1631C23.1078 38.2189 25.252 36.5492 26.7869 34.3352C28.3217 32.1213 29.1357 29.5259 29.1357 26.8282V22.0794C30.2427 22.3213 31.3776 22.4422 32.5311 22.4422H32.559C34.6101 22.4422 36.2752 20.7724 36.2752 18.7212V13.6375C36.2752 11.5817 34.6101 9.9166 32.5543 9.9166H32.559Z"
                fill="black"
            />
            <path
                d="M29.7678 7.12558C29.1074 7.12558 28.4748 6.93488 27.9306 6.57675L27.8794 6.54419C27.4934 6.29767 27.1678 5.97675 26.9074 5.5907C26.6562 5.21395 26.4748 4.77674 26.3957 4.35814C26.3585 4.16279 26.3446 3.95814 26.3446 3.75349C26.3539 2.76279 25.9632 1.80465 25.2655 1.10233C24.5678 0.4 23.6143 0 22.6236 0H17.5074C15.4515 0 13.7864 1.66512 13.7864 3.72093V10.8744C11.512 10.7628 9.26549 11.2465 7.22363 12.2791C4.81898 13.4977 2.87014 15.3953 1.58642 17.7628C0.307352 20.1349 -0.213578 22.8047 0.0794448 25.4837C0.377119 28.1628 1.47014 30.6558 3.23758 32.6837C5.00968 34.7163 7.32131 36.1395 9.93991 36.8047C11.0097 37.0744 12.0934 37.2093 13.1771 37.2093C14.7399 37.2093 16.298 36.9302 17.7911 36.3721C20.3167 35.4279 22.4608 33.7581 23.9957 31.5442C25.5306 29.3302 26.3446 26.7349 26.3446 24.0372V19.2884C27.4515 19.5302 28.5864 19.6512 29.7399 19.6512H29.7678C31.819 19.6512 33.4841 17.9814 33.4841 15.9302V10.8465C33.4841 8.7907 31.819 7.12558 29.7632 7.12558H29.7678Z"
                fill="black"
            />
            <path
                d="M29.7677 15.93C27.2096 15.9347 24.7119 15.1347 22.6282 13.6463V24.037C22.6282 25.9626 22.0375 27.8416 20.9398 29.423C19.8422 31.0044 18.2887 32.2137 16.484 32.8881C14.6794 33.5626 12.7166 33.6695 10.8468 33.1998C8.98169 32.7254 7.30262 31.6928 6.04215 30.2416C4.77703 28.7905 3.98634 26.9858 3.77703 25.0742C3.56773 23.1626 3.94448 21.2277 4.86076 19.5347C5.77703 17.8416 7.18634 16.4695 8.90727 15.5998C10.6235 14.73 12.5677 14.4091 14.4747 14.6695V19.8974C13.6003 19.623 12.6654 19.6323 11.7956 19.9207C10.9259 20.2137 10.1724 20.7672 9.64215 21.5114C9.11192 22.2556 8.82355 23.1486 8.83285 24.0649C8.8375 24.9812 9.13517 25.8695 9.67471 26.6044C10.2142 27.344 10.977 27.8928 11.8468 28.1719C12.7166 28.4509 13.6561 28.4463 14.5259 28.1626C15.3956 27.8788 16.1538 27.3254 16.6887 26.5858C17.2235 25.8463 17.5119 24.9533 17.5119 24.037V3.7207H22.6282C22.6282 4.15326 22.6608 4.58582 22.7352 5.00908C22.9119 5.95791 23.284 6.86489 23.8235 7.66489C24.3631 8.46954 25.0608 9.15326 25.8701 9.67419C27.0235 10.4416 28.3817 10.8463 29.7677 10.8463V15.93Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_39_59892">
                <rect width="36.2791" height="40" fill="white" />
            </clipPath>
        </defs>
    </svg>

)

export default TiktokSVG
