import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './HomeStats_style'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import SVGFactory from 'icons/SVGFactory/SVGFactory'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const HomeStats = (props) => {
    const classes = useStyles()
    const HOME_STATS = [
        {
            item: 'student_jobs',
            icon: 'lightning',
            count: 4597
        },
        {
            item: 'internships',
            icon: 'graduate',
            count: 924
        },
        {
            item: 'first_jobs',
            icon: 'first-job',
            count: 831
        },
        {
            item: 'companies',
            icon: 'building',
            count: 5443
        }
    ]

    const formatNumber = (number) => {
        return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }

    return (
        <Box className={classes.statBoxContainer}>
            {HOME_STATS.map(stat => {
                return (
                    <RouteMapLink page={stat.item.replace('_', '-')} key={`home-stat-${stat.icon}`} className={classes.statBox}>

                            <Box className={classes.iconContainer} style={{ backgroundColor: 'var(--background-silver)' }}>
                                <SVGFactory name={stat.icon} width='40px' height='50px' />
                            </Box>
                            <Box className={classes.statText}>
                                <Typography variant="h2" component="p" className={classes.stat}>
                                    {formatNumber(stat.count)}
                                </Typography>
                                <Typography component="p" className={classes.statDescription}>
                                    <FormattedMessage id={`landing_student.${stat.item}`} />
                                </Typography>
                            </Box>
                    </RouteMapLink>
                )
            })}
        </Box>
    )
}
export default HomeStats
