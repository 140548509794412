import React from 'react'

const Package2SVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 96 120"
        x="0px"
        y="0px"
    >
        <title>Artboard 18</title>
        <path d="M56.15,64l.34-.34ZM72.3,69.9c0,1.13,1.2-.41-21.74,22.54a1.92,1.92,0,0,1-3.27-1.35V76.3c-7.89,3.59-8.55,4.32-9.79,3.08L16.61,58.49c-1.25-1.25-.43-2.05,3.08-9.78H4.92a1.92,1.92,0,0,1-1.35-3.27C26.5,22.5,25,23.7,26.1,23.7H42.72C57,9.4,73.49,3,91.09,3A1.91,1.91,0,0,1,93,4.92C93,22.56,86.57,39,72.3,53.29Zm-50.77-25L38.89,27.53h-12L9.54,44.88Zm23.59,28.2L22.92,50.87l-2.66,5.85,19,19Zm23.35-16L51.12,74.47v12L68.47,69.11Zm2.25-7.67A62.54,62.54,0,0,0,89.14,6.86,62.54,62.54,0,0,0,46.55,25.28L24.65,47.18,48.82,71.35l5.8-5.8-.23.23-.44.44.44-.44ZM55.26,64.91l-.55.55Zm.88-.88-.7.7ZM69.81,39.35a9.3,9.3,0,1,1,0-13.15A9.31,9.31,0,0,1,69.81,39.35ZM67.1,28.9a5.47,5.47,0,1,0,0,7.74A5.48,5.48,0,0,0,67.1,28.9ZM19.23,74.07,8.78,84.51a1.91,1.91,0,0,0,2.71,2.71L21.93,76.77A1.91,1.91,0,0,0,19.23,74.07Zm7.83,6.09-8.7,8.7a1.91,1.91,0,0,0,2.71,2.71l8.7-8.7A1.91,1.91,0,0,0,27.06,80.16ZM13.13,66.23l-8.7,8.7a1.91,1.91,0,0,0,2.71,2.71l8.7-8.7A1.91,1.91,0,0,0,13.13,66.23Z" />
    </svg>
)

export default Package2SVG
