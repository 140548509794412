import React from 'react'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'

const ProgressCircle = (props) => {
    const circleDimension = props.small ? '40px' : '80px'
    return (
        <Box my={props.disableMargin ? 1 : 8} display="flex" justifyContent="center">
            <CircularProgress color='primary' style={{ width: circleDimension, height: circleDimension, marginTop: props.disableMargin ? '' : '50px' }}/>
        </Box>
    )
}

export default ProgressCircle
