import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    locationSubtitle: {
        color: 'var(--foreground-black-60-opacity)'
    },
    formLabelSelect: {
        fontSize: 20
    },
    formLabelText: {
        fontSize: 20,
    },
    selectionContainer: {
        display: 'flex',
        gap: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        }
    },
    addVideo: {
        flex: '1 1 0px',
    },
    addImage: {
        flex: '1 1 0px',
    }
}))
