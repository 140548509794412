import React from 'react'

import { useStyles } from './AIGuideline_style'
import { Box } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

const AIGuideline = (props) => {
    const classes = useStyles()

    return (
        <Box className={classes.container}>
            <FormattedMessage id='job_creation_funnel.job_offer_generator.ai_guideline' />
        </Box>
    )
}

export default AIGuideline
