import React from 'react'

import ExperienceProfileViewDefault from './ExperienceProfileViewDefault'
import ExperienceProfileViewBlack from './ExperienceProfileViewBlack'
import ExperienceProfileViewCondensed from './ExperienceProfileViewCondensed'

const ExperienceProfileView = (props) => {
    const { black, withCondensedStyle = false } = props

    if (withCondensedStyle) {
        return <ExperienceProfileViewCondensed {...props} />
    }
    if (black) {
        return <ExperienceProfileViewBlack {...props} />
    }
    return <ExperienceProfileViewDefault {...props} />

}

export default ExperienceProfileView
