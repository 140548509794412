import React, { useRef, useState, useEffect } from 'react'
import { Box, Typography } from '@material-ui/core'

import IconFactory from 'shared/icons/IconFactory/IconFactory'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import { useStyles } from './JobCardMinimalist_style.js'
import JobCardChips from './components/JobCardChips/JobCardChips'

const JobCardMinimalist = (props) => {
    const { job } = props
    const cardRef = useRef(null)
    const [isWide, setIsWide] = useState(false)
    const classes = useStyles({ isWide })

    useEffect(() => {
        const handleResize = () => {
            if (cardRef.current) {
                if (cardRef.current.offsetWidth > 400) {
                    setIsWide(true)
                }
            }
        }

        handleResize() // Set initial width
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <RouteMapLink id={job.id} showItemType={job.type} showItemUrl={job.url} className={classes.jobLinkContainer}>
            <Box ref={cardRef} className={classes.jobBox}>
                <Typography className={classes.jobTitle} variant='h3' component='h3'>
                    {job?.title}
                </Typography>
                <Box className={classes.jobInfoContainer}>
                    <JobCardChips showJobType job={job} />
                    <Box className={classes.locationContainer}>
                        <IconFactory icon='location-pin' fontSize='14px' color={'var(--foreground-black-40-opacity)'} />
                        <Typography component='p' className={classes.location}>
                            {job?.city}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </RouteMapLink>
    )
}

export default JobCardMinimalist
