import React from 'react'

import css from './map.scss'


export default class Map extends React.Component {
    constructor (props) {
        super(props)
        console.log(props.mapProviderProps)
    }

    componentDidMount () {
        this.props.mapProviderProps.createMap()
    }

    render () {
        if (this.props.mapProviderProps.map != null) { this.props.mapProviderProps.refreshMarkers() }
        return (
            <div id='map' className="item-index-map"></div>
        )
    }
}
