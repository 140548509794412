/* eslint-disable react/prop-types */

import React from 'react'
import axios from 'axios'

import Button from 'shared/components/Button/Button'
import CloudinaryHelper from 'assets/CloudinaryHelper'

export default class CompanyPicturesUploader extends React.Component {
    constructor (props) {
        super(props)
        console.log(this)

        this.updateSelectedPictures = this.updateSelectedPictures.bind(this)
        this.submitPicture = this.submitPicture.bind(this)
    }

    shouldComponentUpdate (nextProps, nextState) {
        if (this.props.picturesKeys === nextProps.picturesKeys) {
            return false
        } else {
            return true
        }
    }

    updateSelectedPictures (document) {
        this.props.handleSelectedPictureChange(document)
    }

    submitPicture (response) {
        console.log('submitPicture', response)
        axios({
            url: '/api/v1/documents',
            method: 'post',
            data: {
                company_id: this.props.companyId,
                cloudinary_key: response.public_id,
                name: response.original_filename,
                format: response.format,
                width: response.width,
                height: response.height,
                is_used: true
            }
        }).then((axios_response) => {
            console.log('submitPicture RESPONSE', axios_response)
            this.updateSelectedPictures(axios_response.data.document_object)
        })
    }

    render () {
        var myWidget = CloudinaryHelper.createImageUploadWidget({
            cropping: false,
            multiple: true
        },
        (error, result) => {
            if (!error && result && result.event === 'success') {
                this.submitPicture(result.info)
            }
        }
        )
        return (
            <div
                className="profile-image-overlay"
                id="upload_widget"
                onClick={myWidget.open}
            >
                <Button variant='cta-primary' size='small' type="submit">Upload</Button>
            </div>
        )
    }
}
