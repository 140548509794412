import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    table: {
        height: '50vh',
    },
    noWordBreak: {
        '& th, td': {
            whiteSpace: 'nowrap',
        }
    },
    stickyHeader: {
        position: "sticky",
        left: 0,
        background: "white",
        zIndex: 10,
        whiteSpace: 'nowrap',
    },
    stickyRow: {
        position: "sticky",
        left: 0,
        background: "white",
        zIndex: 9,
    },
}))
