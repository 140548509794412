import React from 'react'
import { Chip } from '@material-ui/core'

import { FormattedMessage } from 'react-intl'

import { useStyles } from './JobCardChips_style'

const JobCardChip = (props) => {
    const classes = useStyles()
    const { name, color = 'var(--foreground-grey-dark)', background = 'var(--background-grey-light)' } = props

    return (
        <Chip
            classes={{ root: classes.chipRoot, label: classes.chipLabel }}
            style={{ backgroundColor: background }}
            className={classes.chip}
            label={<FormattedMessage id={`job_card.tags.${name}`} />}
            key={name}
            name={name}
            color={color}
        />
    )
}

export default JobCardChip
