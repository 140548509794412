import React from 'react'
import KotMessageUserCard from '../../MessagesCard/KotMessageUserCard'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { FormattedMessage } from 'react-intl'

export default class IndexByMsg extends React.Component {
    render () {
        return (
            <div className="kot-owner-index-main">
                {this.props.candidacies?.map((kot) => {
                    return (
                        <KotMessageUserCard
                            key={`${kot.title}-${kot.id}`}
                            candidacy={kot}
                            itemType={kot.type}
                        />
                    )
                })}
                {!this.props.lastPage && !this.props.loading &&
                    <Box m={1}>
                        <Button
                            color={'primary'}
                            variant={'contained'}
                            fullWidth
                            onClick={this.props.loadMore}
                        >
                            <FormattedMessage id="item_index.show_more_button" />
                        </Button>
                    </Box>
                }
            </div>
        )
    }
}
