import React from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import RouteMap from 'assets/RouteMap'
import DefaultHelmet from './DefaultHelmet'

class PodcastHelmet extends React.Component {
    render () {
        const { formatMessage } = this.props.intl

        return <>
            <DefaultHelmet
                title={formatMessage({ id: 'helmet.landing_pages.podcast.title' })}
                description={formatMessage({ id: 'helmet.landing_pages.podcast.description' })}
                imageCloudinaryKey='assets/hello-future-lets-talk-student-be'
            />
            <Helmet>
                <meta property='og:type' content='website' />
                <meta property="og:url" content={RouteMap.Page('podcast')} />
                <link rel="canonical" href={RouteMap.Page('podcast')} />
                <link rel="alternate" hrefLang="nl-BE" href={RouteMap.Page('podcast', 'nl')} />
                <link rel="alternate" hrefLang="fr-BE" href={RouteMap.Page('podcast', 'fr')} />
                <link rel="alternate" hrefLang="en-BE" href={RouteMap.Page('podcast', 'en')} />
                <link rel="alternate" hrefLang="x-default" href={RouteMap.Page('podcast', 'en')} />
            </Helmet>
        </>
    }
}

export default injectIntl(PodcastHelmet)
