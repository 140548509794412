/* eslint-disable react/prop-types */
import React, { useState } from 'react'

import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Box from '@material-ui/core/Box'
import LineButton from 'buttons/LineButton/LineButton'

import ShareModal from 'modals/ShareModal/ShareModal'
import IconFactory from 'icons/IconFactory/IconFactory'

import { FormattedMessage } from 'react-intl'

const ShareAction = (props) => {
    const { size, color, padding, infos, fullButton, buttonClassName, iconButtonClassName } = props
    const [showShareModal, setShowShareModal] = useState(false)
    const fontSize = size || '1.2rem'
    const colorStyle = color || 'var(--black)'
    const paddingStyle = padding || '12px'

    return (
        <>
            <ShareModal
                infos={infos}
                showModal={showShareModal}
                setShowModal={setShowShareModal}
            />
            {fullButton &&
                <LineButton
                    newStyle
                    fullWidth
                    onClick={() => setShowShareModal(true)}
                    className={buttonClassName}
                    name={
                        <Box display='flex' alignItems='center'>
                            <Box mr={1}>
                                <IconFactory
                                    icon='share'
                                    fontSize={fontSize}
                                    color={color}
                                />
                            </Box>
                            <FormattedMessage id='share_popup.share'/>
                        </Box>
                    }
                />
            }
            {!fullButton &&
                <FormattedMessage id={infos.shareText ? infos.shareText : 'share_popup.share'}>
                    {tooltip =>
                        <Tooltip title={tooltip}>
                            <IconButton
                                onClick={() => setShowShareModal(true)}
                                style={iconButtonClassName ? {} : { borderRadius: '4px', padding: paddingStyle, backgroundColor: 'white' }}
                                className={iconButtonClassName}
                            >
                                <IconFactory icon='share' fontSize={fontSize} color={colorStyle} />
                            </IconButton>
                        </Tooltip>
                    }
                </FormattedMessage>
            }
        </>
    )
}

export default ShareAction


/* ===== DOC =====

    USAGE :

    <ShareAction
        infos={{title: this.props.job.title}}
    />

    Mandatory:
        @infos: an object with the title of the item

    OPTIONAL:
        @size: ['small', 'large'], materialUI fontSize props

    ===== END =====
*/