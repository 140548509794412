import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    videosContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: 30
    },
    title: {
        color: 'var(--main-color)'
    },
    arrowBtn: {
        height: 40,
        width: 40,
        backgroundColor: 'white',
        zIndex: 2,
        border: 'solid 1px var(--light-grey)'
    },
    arrows: {
        width: 30,
        height: 30,
        color: 'var(--black) !important'
    }
}))
