import React, { useRef } from 'react'
import { Box, IconButton } from '@material-ui/core'
import { useStyles } from './AboutPublishStudentJob_style'
import { Image, Transformation } from 'cloudinary-react'
import IconFactory from 'icons/IconFactory/IconFactory'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import { MEDIUM_SIZE } from 'assets/CloudinaryURLHelper'

const ScrollableCompanyLogoList = (props) => {
    const classes = useStyles()
    const boxRef = useRef(null)

    const partners = [
        { name: 'manpowergroup-belgium', imageId: 'manpower-logo-publish-a-student-job' },
        { name: 'superprof-sas', imageId: 'superprof-logo-publish-a-student-job' },
        { name: 'zest-fresh-juices', imageId: 'zest-logo-publish-a-student-job' },
        { name: 'delhaize-f7b36849-43d7-4d27-8170-d2fe06757dda', imageId: 'delhaize-logo-publish-a-student-job' },
        { name: 'the-barn', imageId: 'the-barn-logo-publish-a-student-job' },
        { name: 'hawaiian-poke-bowl-39b3edb1-cf3a-4b9a-a89b-620e7455d13f', imageId: 'hawaiian-logo-publish-a-student-job' },
        { name: 'de-pistolei-c496e29a-6831-4767-9ebe-95a0cdfaa7c6', imageId: 'pistolei-logo-publish-a-student-job' },
        { name: 'clouds-bv', imageId: 'bavet-logo-publish-a-student-job' },
        { name: 'o-learys-gent', imageId: 'olearys-logo-publish-a-student-job' }
    ]

    const handleScroll = (scrollOffset) => {
        const newScrollLeft = boxRef.current.scrollLeft + scrollOffset
        boxRef.current.scrollTo({
            left: newScrollLeft,
            behavior: 'smooth'
        })
    }

    return (
        <Box className={classes.partnerContainer}>
            <IconButton onClick={() => handleScroll(-250)} className={classes.sliderArrowBox}>
                <IconFactory icon='left-regular'/>
            </IconButton>
            <Box className={classes.partnerBox} ref={boxRef}>
                {partners.map(partner =>
                    <Box key={partner.imageId} className={classes.partnerImageBox}>
                        <RouteMapLink showItemType={'company'} showItemUrl={partner.name} target={'_blank'}>
                            <Image
                                className={classes.partnerImage}
                                cloudName='studentbe'
                                publicId={`partner-logo/${partner.imageId}`}
                                alt={`partner image ${partner.imageId}`}
                            >
                                <Transformation flags='progressive' fetchFormat='auto' width={MEDIUM_SIZE} quality='auto:best' crop='fill' />
                            </Image>
                        </RouteMapLink>
                    </Box>
                )}
            </Box>
            <IconButton onClick={() => handleScroll(250)} className={classes.sliderArrowBox}>
                <IconFactory icon='right-regular'/>
            </IconButton>
        </Box>
    )
}
export default ScrollableCompanyLogoList
