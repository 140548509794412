import React from 'react'
import { FormattedMessage } from 'react-intl'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import Button from 'components/Button/Button'

export default class MessagesHeader extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            byOfferStatus: props.byOfferStatus
        }

        this.searchTroughCards = this.searchTroughCards.bind(this)
        this.renderStudentHeaders = this.renderStudentHeaders.bind(this)
        this.handleClick = this.handleClick.bind(this)
    }

    searchTroughCards () {
        var items = document.querySelectorAll('.conversation_card')
        var text = event.target.value
        var pat = new RegExp(text, 'i')
        for (var i = 0; i < items.length; i++) {
            var item = items[i]
            var item_title = items[i].children[0].children[0].children[1].children[0].innerText
            var item_subtitle = items[i].children[0].children[0].children[1].children[1].innerText
            if (text === '') {
                items.forEach(function (item) {
                    item.className = item.className.replace(/\s+?hidden/, '')
                })
            } else if (pat.test(item_title) || pat.test(item_subtitle)) {
                item.className = item.className.replace(/\s+?hidden/, '')
            } else {
                item.className = item.className + ' hidden'
            }
        }
    }

    sortByType () {
        var cards = document.querySelectorAll('.conversation_card')
        cards.forEach(function (card) {
            if (card.querySelector('.typeSubName').value.toLowerCase().replace(':', '').replace(' ', '').includes(event.target.children[event.target.selectedIndex].value.toLowerCase().replace(' ', ''))) {
                card.style.display = 'block'
            } else if (event.target.children[event.target.selectedIndex].value === 'All') {
                card.style.display = 'block'
            } else {
                card.style.display = 'none'
            }
        })
    }

    handleClick (tab) {
        this.setState({ byOfferStatus: tab === this.props.switchIndexTypeString })
        this.props.switchIndexType(tab)
    }

    renderStudentHeaders () {
        return (
            <div className="messages-headers">
                <select name="" id="" className="type-of-messages" onChange={() => this.sortByType()}>
                    <option value="All"><FormattedMessage id="chat.option_all" /></option>
                    <option value="First Job"><FormattedMessage id="chat.option_first_job" /></option>
                    <option value="Student Job"><FormattedMessage id="chat.option_internship" /></option>
                    <option value="Internship"><FormattedMessage id="chat.option_student_job" /></option>
                    <option value="Kot"><FormattedMessage id="chat.option_kot" /></option>
                </select>
            </div>
        )
    }

    render () {
        return (
            <>
                {this.props.user.is_student_kot_owner &&
                    <div className="messages-header-container">
                        <div className="student-kot-owner-button-container">
                            <RouteMapLink
                                page='messages'
                                paramString={this.props.type === 'kot_owner' ? '' : '?userType=kot_owner'}
                            >
                                <Button
                                    variant='cta-primary'
                                    size='small'
                                    type='submit'
                                    color='primary'
                                    style={{ fontSize: '13px', width: '100%' }}
                                >
                                    {this.props.type === 'kot_owner'
                                        ? 'Show messages for my candidacies'
                                        : 'Show messages for my kots'
                                    }
                                </Button>
                            </RouteMapLink>
                        </div>
                    </div>
                }
            </>

        )
    }
}
