import React, { useState, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Hidden from '@material-ui/core/Hidden'
import { useStyles } from './kotShow_style'
import KotCaroussel from './components/KotCaroussel/KotCaroussel'
import ApplyBox from './components/ApplyBox/ApplyBox'
import KotDescription from './components/KotDescription/KotDescription'
import SimilarKots from './components/SimilarKots/SimilarKots'
import KotShowHelmet from 'helmets/KotShowHelmet'
import KotSummary from './components/KotSummary/KotSummary'
import PopupFactory from 'modals/Popup/PopupFactory'
import NewsAndTips from 'components/NewsAndTips/NewsAndTips'
import RecentArticles from 'containers/RecentArticles/RecentArticles'
import AdBanner from 'components/AdBanner/AdBanner'
import KotShowMap from './components/KotShowMap/KotShowMap'
import CloudinaryURLHelper from 'assets/CloudinaryURLHelper'
import RouteMap from 'assets/RouteMap'
import DateHandler from 'assets/DateHandler'
import URLParamsHandler from 'assets/URLParamsHandler.js'
import { LoginRegisterContext } from 'shared/contexts/LoginRegisterContext'
import KotShowBanner from './components/KotShowBanner/KotShowBanner'

const KotShow = (props) => {
    const { user, kot, ads, unavailable } = props
    const classes = useStyles()
    const [showPopupAction, setShowPopupAction] = useState(user?.type === 'student' && URLParamsHandler.loadProcessFromURL() === 'message_to_owner')

    const context = useContext(LoginRegisterContext)

    const openLoginModal = () => {
        context.openLoginRegisterModal({
            origin: 'kot_show',
            afterAuthAction: afterAuthAction
        })
    }

    const afterAuthAction = () => {
        if (kot.application_type === 'application_url') {
            window.location.href = kot.application_url
        } else {
            URLParamsHandler.reloadPageAndAddParams({ process: 'message_to_owner' })
        }
    }

    const renderPopup = (popupType) => {
        setShowPopupAction(!showPopupAction)
    }

    const createKotPicturesArray = () => {
        const documentsArray = kot.documents
        const picturesArray = []

        documentsArray.forEach(elem => {
            picturesArray.push({
                originalAlt: elem.name ? elem.name : elem.cloudinary_key,
                src: CloudinaryURLHelper.getImageUrl('KOT_IMAGE', elem.cloudinary_key),
                thumbnail: CloudinaryURLHelper.getImageUrl('KOT_IMAGE_THUMBNAIL', elem.cloudinary_key)
            })
        })

        return picturesArray
    }

    const renderApplyBox = () => {
        return (
            <ApplyBox
                className={classes.iconsButtonBox}
                popupCall={renderPopup}
                applyText={<FormattedMessage id='landing_card_kot_page.contact_owner' />}
                apply={() => renderPopup('showPopupAction')}
                userType={user ? user.type : 'not_connected'}
                applyType='kot'
                kot={kot}
                unavailable={unavailable}
                openLoginModal={openLoginModal}
            />
        )
    }

    const halfPageAds = () => {
        if (!ads || ads.length === 0) {
            return <RecentArticles category={kot.type} />
        }

        const locale = RouteMap.GetLocaleFromUrl()
        const localizedAds = ads.filter(ad => ad.langage === locale)
        const places = []

        localizedAds.forEach(ad => {
            ad.places.forEach(place => {
                if (place.includes('Show_Half page')) {
                    places.push(place)
                }
            })
        })

        places.sort()

        return places.map((place, index) => (
            <AdBanner key={`ad-banner-${index}`} ads={localizedAds} place={place} device='desktop' style={{ marginBottom: '20px' }} width={300} />
        ))
    }

    return (
        <Box>
            {showPopupAction && (
                <PopupFactory
                    type='kot-offer-message'
                    kot={kot}
                    popupExit={() => renderPopup('showPopupAction')}
                    openShare={showPopupAction}
                />
            )}
            <KotShowHelmet kot={kot} noindex={unavailable} />
            {/* Banner */}
            {!unavailable &&
                <KotShowBanner
                    kot={kot}
                    unavailable={unavailable}
                    date={DateHandler.ToBasicFormat(kot.updated_at)}
                    renderApplyBox={renderApplyBox}
                    user={user}
                />
            }
            <Container maxWidth='lg'>
                <Box className={classes.columnContainer}>
                    <Box className={classes.leftContainer}>
                        {/* Tester les AdBanner */}
                        <AdBanner key='ad-banner' ads={ads} place='Kots_Show_Leaderboard' device='desktop' width={728} />
                        <AdBanner key='ad-banner-1' ads={ads} place='Kots_Show_Mobile_1' device='mobile' width={300} />
                        {unavailable && (
                            <Box mt={4}>
                                <Paper elevation={4} style={{ backgroundColor: 'var(--red)', padding: '6px 16px', display: 'flex', justifyContent: 'center' }} >
                                    <Typography variant='body2' style={{ color: 'white' }}>
                                        <FormattedMessage id='transl_internship.completed_kot_banner' />
                                    </Typography>
                                </Paper>
                            </Box>
                        )}
                        {!unavailable && (
                            <Box className={`${classes.stickyContainer}`}>
                                <Box mt={5} className={classes.kotDescription}>
                                    <Box className={classes.kotDescriptionText}>
                                        {createKotPicturesArray().length > 0 &&
                                            <KotCaroussel images={createKotPicturesArray()}/>
                                        }
                                        <KotSummary kot={kot}/>
                                        <KotDescription kot={kot} user={!!user}/>
                                        <KotShowMap kot={kot} />
                                        <Box className={classes.applyBox}>
                                            {renderApplyBox()}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        <AdBanner key='ad-banner-2' ads={ads} place='Kots_Show_Mobile_2' device='mobile' width={320} />
                    </Box>
                    {/* Right side */}
                    <Hidden smDown>
                        <Box className={classes.rightContainer}>
                            <Box mt={5}>
                                {halfPageAds()}
                            </Box>
                        </Box>
                    </Hidden>
                </Box>
            </Container>
            <Box mt={5}>
                <SimilarKots kot={kot} user={user}/>
            </Box>
            <AdBanner key='ad-banner-3' ads={ads} place='Kots_Show_Mobile_3' device='mobile' width={320} />
            <NewsAndTips item={kot} />
            <AdBanner key='ad-banner-4' ads={ads} place='Kots_Show_Mobile_4' device='mobile' width={320} />
        </Box>
    )
}

export default KotShow
