import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    paper: {
        [theme.breakpoints.down('md')]: {
            padding: 15
        },
        [theme.breakpoints.up('md')]: {
            padding: '30px 50px'
        }
    }
}))
