import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, FormControl, MenuItem, Select, Typography, useTheme } from '@material-ui/core'

import JobCardV2 from 'cards/JobCard/JobCardV2'
import { useStyles } from './CompanyJobs_style'
import OpenIcon from '../../Profile/StudentProfile/Components/ExperienceProfile/components/OpenIcon/OpenIcon'
import CollectionHandler from 'assets/CollectionHandler.js'
import ResetFilters from 'shared/components/Searchbar/shared/ResetFilters/ResetFilters'
import { Pagination } from '@material-ui/lab'
import CompanyAPI from 'api/CompanyAPI'
import Bugsnag from '@bugsnag/js'

const COMPANY_SHOW_JOBS_PER_PAGE = 12

const CompanyJobs = (props) => {
    const classes = useStyles()
    const { companyId, jobs, totalJobsCount } = props
    const [filteredJobs, setFilteredJobs] = useState(jobs)
    const [jobsCount, setJobsCount] = useState(totalJobsCount)
    const [isFirstRender, setIsFirstRender] = useState(true)
    const [fetchingJobs, setFetchingJobs] = useState(false)
    const [openJobType, setOpenJobType] = useState(false)
    const [openJobLanguage, setOpenJobLanguage] = useState(false)
    const jobTypesCollection = CollectionHandler.Get('jobTypeCollection')
    const languageCollection = CollectionHandler.Get('defaultLanguageCollection')
    const defaultFilters = {
        jobType: '',
        jobLanguage: '',
        page: 1
    }
    const [filters, setFilters] = useState(defaultFilters)
    const theme = useTheme()

    useEffect(() => {
        if (isFirstRender) return setIsFirstRender(false)
        if (fetchingJobs) return

        setFetchingJobs(true)
        totalJobsCount <= COMPANY_SHOW_JOBS_PER_PAGE ? filterLocally() : filterViaAPI()
    }, [filters])

    const filterLocally = () => {
        setTimeout(() => {
            let filteredJobs = jobs
            if (filters.jobType) {
                filteredJobs = filteredJobs.filter((job) => job.type === filters.jobType)
            }
            if (filters.jobLanguage) {
                filteredJobs = filteredJobs.filter((job) => job[filters.jobLanguage] === true)
            }
            setFetchingJobs(false)
            setFilteredJobs(filteredJobs)
            setJobsCount(filteredJobs.length)
        }, 300)
    }

    const filterViaAPI = () => {
        const data = {
            id: companyId,
            filters: {
                job_type: filters.jobType,
                job_language: filters.jobLanguage,
                page: filters.page
            }
        }
        CompanyAPI.GetCompanyJobs(data).then(response => {
            setFetchingJobs(false)
            setFilteredJobs(response.data.jobs)
            setJobsCount(response.data.count)
        }).catch((error) => {
            Bugsnag.notify(error)
            setFetchingJobs(false)
        })
    }

    const handleChange = (event) => {
        const { value, name } = event.target
        setFilters({
            ...filters,
            [name]: value
        })
    }

    const handleChangePage = (_, newPage) => {
        setFilters({
            ...filters,
            page: newPage
        })
    }

    const resetFilters = () => {
        setFilters(defaultFilters)
    }

    const selectPaperStyle = () => {
        return ({
            disableScrollLock: true,
            getContentAnchorEl: null,
            PaperProps: {
                style: {
                    maxHeight: 350,
                    boxShadow: `4px 4px 0px 0px ${theme.palette.bg.black}`,
                    border: `2px solid ${theme.palette.fg.black}`,
                    backgroundColor: theme.palette.bg.white,
                    borderRadius: 16
                }
            }
        })
    }

    return (
        <Box>
            <Box className={classes.jobFiltersContainer}>
                <Box className={classes.jobFilters}>
                    <FormControl onClick={() => setOpenJobType(!openJobType)}>
                        <Select
                            value={filters.jobType}
                            name={'jobType'}
                            inputProps={{ MenuProps: selectPaperStyle() }}
                            open={openJobType}
                            disableUnderline
                            onChange={handleChange}
                            IconComponent={() => <OpenIcon open={openJobType}/>}
                            displayEmpty
                        >
                            <MenuItem value='' className={classes.defaultFilterSelection}>
                                <FormattedMessage id='company_jobs.filters.all_job_types' />
                            </MenuItem>
                            {jobTypesCollection.map((item, itemKey) => {
                                return <MenuItem className={classes.menuItem} key={itemKey.toString()} value={item.value}>{item.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControl onClick={() => setOpenJobLanguage(!openJobLanguage)}>
                        <Select
                            value={filters.jobLanguage}
                            name={'jobLanguage'}
                            inputProps={{ MenuProps: selectPaperStyle() }}
                            open={openJobLanguage}
                            disableUnderline
                            onChange={handleChange}
                            IconComponent={() => <OpenIcon open={openJobLanguage}/>}
                            displayEmpty
                        >
                            <MenuItem value='' className={classes.defaultFilterSelection}>
                                <FormattedMessage id='company_jobs.filters.all_languages' />
                            </MenuItem>
                            {languageCollection.map((item, itemKey) => {
                                return <MenuItem className={classes.menuItem} key={itemKey.toString()} value={item.value}>{item.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <ResetFilters reset={resetFilters} smallIcon />
                </Box>
                <Box>
                    <FormattedMessage id={`company_jobs.matching_jobs.${jobsCount === 1 ? 'one' : 'other'}`} values={{ count: jobsCount }} />
                </Box>
            </Box>
            <Box>
                {fetchingJobs
                    ? <span>Loading ...</span>
                    : <Box className={classes.jobsList}>
                        {filteredJobs.map(offer =>
                            <JobCardV2 job={offer} showJobType key={offer.id} />
                        )}
                        {jobsCount > 0
                            ? <Box className={classes.paginationContainer}>
                                {filters.page > 1 &&
                                    <Pagination
                                        defaultPage={defaultFilters.page}
                                        count={Math.ceil(jobsCount / COMPANY_SHOW_JOBS_PER_PAGE)}
                                        page={Math.min(filters.page, Math.ceil(jobsCount / COMPANY_SHOW_JOBS_PER_PAGE))}
                                        onChange={handleChangePage}
                                    />
                                }
                            </Box>
                            : <Box className={classes.noSearchResults}>
                                <Typography variant='h6'><FormattedMessage id='company_jobs.no_results' /></Typography>
                            </Box>
                        }
                    </Box>
                }
            </Box>
        </Box>
    )
}

export default CompanyJobs
