/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import JobAPI from 'api/JobAPI'
import SimilarItemsView from 'components/SimilarItemsView/SimilarItemsView'

const SimilarJobs = (props) => {
    const { user, job } = props
    const [similarJobs, setSimilarJobs] = useState([])

    useEffect(() => {
        getSimilarJobs()
    }, [])

    const getSimilarJobs = () => {
        const data = {
            id: job?.id,
            field_of_study_id: job?.field_of_study_id,
            per_page: 3,
            job_type: job.type
        }
        JobAPI.GetSimilarJobs(data).then((response) => {
            setSimilarJobs(response.data)
        })
    }

    if (similarJobs.length === 0) return null

    return (
        <SimilarItemsView
            type={job.type}
            baseItem={job}
            items={similarJobs}
            userType={user && user.type}
            titleId={`transl_internship.job_show.preview_card_2_title.${job.type}`}
            moreItemsId={`job_show_similar_jobs.see_all_similar_jobs.${job.type}`}
        />
    )
}

export default SimilarJobs
