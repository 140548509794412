/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import RouteMapLink from '../../../../../shared/wrappers/RouteMapLink/RouteMapLink'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { useStyles } from './HeadhuntResponsePage_style'
import { FormattedMessage } from 'react-intl'

const HeadhuntResponsePage = (props) => {
    const renderNotInterested = () => {
        return (
            <>
                <Box mb={5} mt={5}>
                    <Typography color='primary'><FormattedMessage id="headhunt_response.not_interested.text" /></Typography>
                </Box>
                <Box mb={5} mt={5}>
                    <RouteMapLink page='first-jobs'>
                        <Button
                            variant='contained'
                            color='primary'
                            style={{ textTransform: 'none' }}
                        >
                            <FormattedMessage id="headhunt_response.not_interested.view_jobs_button" />
                        </Button>
                    </RouteMapLink>
                </Box>
            </>
        )
    }
    const renderInterested = () => {
        return (
            <>
                <Box mb={5} mt={5}>
                    <Typography color='primary'><FormattedMessage id="headhunt_response.interested.text" /></Typography>
                </Box>
                <Box mb={5} mt={5}>
                    <RouteMapLink showItemType='student_job' showItemUrl={props.studentJob.url}>
                        <Button
                            variant='contained'
                            color='primary'
                            style={{ textTransform: 'none' }}
                        >
                            <FormattedMessage id="headhunt_response.interested.view_student_job_button" />
                        </Button>
                    </RouteMapLink>
                </Box>
                <Box mb={5} mt={5}>
                    <RouteMapLink page={props.chatUrl}>
                        <Button
                            variant='contained'
                            color='primary'
                            style={{ textTransform: 'none' }}
                        >
                            <FormattedMessage id="headhunt_response.interested.view_chat_button" />
                        </Button>
                    </RouteMapLink>
                </Box>
            </>
        )
    }

    return (
        <Container maxWidth='lg'>
            {props.interested
                ? renderInterested()
                : renderNotInterested()
            }
        </Container>
    )
}

export default HeadhuntResponsePage
