import React from 'react'

const KiteSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 -1 30 30">
        <path
            className="cls-1"
            d="M10.702,22.71586q-3.89164-.00041-7.78329-.00038c-.07115,0-.14272.003-.21336-.0034a.46065.46065,0,0,1-.11494-.89064,1.001,1.001,0,0,1,.30491-.04179q4.88153-.00309,9.76307-.00139,2.86279.001,5.72559.00792a.98189.98189,0,0,0,.763-.26131.85823.85823,0,0,0,.20679-.96375.84172.84172,0,0,0-.7807-.572c-.08377-.00564-.16809-.00439-.25215-.00439q-5.23092-.00011-10.46181-.00523a3.51713,3.51713,0,0,1-.93715-.12554,2.47649,2.47649,0,0,1-1.70855-3.16178,2.02438,2.02438,0,0,1,.63559-1.11267,4.62933,4.62933,0,0,0,1.36814-2.2758C8.46707,9.73843,9.74741,6.18388,11.01713,2.6256a1.214,1.214,0,0,1,1.2994-.91731q3.42568.00271,6.85135.00714A1.077,1.077,0,0,1,20.348,2.88067q.00587,3.41608.00012,6.8322A1.3552,1.3552,0,0,1,19.3684,11.073Q13.455,13.1845,7.54278,15.29949a.8531.8531,0,0,0-.2895.18168c-.23928.22691-.47667.45725-.69755.70183A1.7582,1.7582,0,0,0,6.206,18.17809a1.48162,1.48162,0,0,0,1.22257.84127,2.79717,2.79717,0,0,0,.483.03328q5.23091.00243,10.46181-.00222a1.85078,1.85078,0,0,1,1.619.75027,1.755,1.755,0,0,1,.16632,1.88992,1.68443,1.68443,0,0,1-1.57659,1.01615c-2.18665.01548-4.37347.008-6.56022.00933Q11.36193,22.7165,10.702,22.71586Zm-1.72-8.92378,9.993-3.56938L15.7632,7.011Zm-.681-.77,6.73169-6.72981L11.84238,3.1027Zm4.57136-10.3656,2.88416,2.884,2.88389-2.884ZM19.40424,9.194V3.3893L16.50252,6.29105Z"
        />
    </svg>
)

export default KiteSVG
