import parsePhoneNumber from 'libphonenumber-js'

const FormatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return ''

    const phoneNumberObject = parsePhoneNumber(phoneNumber)
    const sanizitedPhoneNumber = phoneNumber.replace(/[\D\s]/g, '').replace('+', '00')
    const mobilePhoneRegEx = /^(0|32)?4(\d{2})(\d{2})(\d{2})(\d{2})/

    if (sanizitedPhoneNumber.match(mobilePhoneRegEx)) {
        return sanizitedPhoneNumber.replace(mobilePhoneRegEx, '+32 4$2 $3 $4 $5')
    } else if (!phoneNumberObject) {
        return phoneNumber
    } else {
        return phoneNumberObject.formatInternational()
    }
}

export default { FormatPhoneNumber }
