import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './Input_style'
import { FormControl, FormHelperText, Input as NativeInput, InputLabel } from '@material-ui/core'

const Input = (props) => {
    const { id, name, required = false, handleChange, error, labelTextId } = props
    const classes = useStyles()

    return (
        <FormControl>
            <InputLabel htmlFor={id} className={classes.inputLabel} >
                <FormattedMessage id={labelTextId} />
            </InputLabel>
            <NativeInput
                classes={{ input: classes.input, underline: classes.inputUnderline }}
                id={id}
                name={name}
                required={required}
                fullWidth
                type='text'
                error={error}
                onChange={handleChange}
            />
            <FormHelperText className={classes.errorText}>{error}</FormHelperText>
        </FormControl>
    )

}

export default Input
