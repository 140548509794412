import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    articleContainer: {
        overflow: 'hidden',
        width: '100%',
        maxWidth: '708px',
        borderRadius: '20px',
        border: `1px solid ${theme.palette.fg['silver']}`,
        backgroundColor: theme.palette.bg['white'],
        transition: 'all 0.3s ease',
        '&:hover': {
            cursor: 'pointer',
            borderColor: `1px solid ${theme.palette.fg.black}`
        },
        [theme.breakpoints.up('md')]: {
            width: '55%',
            maxWidth: 'unset'
        }
    },
    image: {
        display: 'block',
        width: '100%',
        height: '308px',
        objectFit: 'cover'
    },
    articleContent: {
        width: '100%',
        padding: '17px 27px 29px'
    },
    chipRoot: {
        height: 'fit-content',
        backgroundColor: theme.palette.bg['silver'],
        borderRadius: '42px',
        padding: '0px 27px',
        cursor: 'pointer'
    },
    chipLabel: {
        padding: 0,
        color: theme.palette.fg['black'],
        fontFamily: 'GreedStandard-Medium',
        fontSize: '8px',
        lineHeight: '15px',
        textTransform: 'capitalize',
        [theme.breakpoints.up('md')]: {
            fontSize: '12px',
            lineHeight: '18px'
        }
    },
    title: {
        paddingTop: '12px',
        display: '-webkit-box',
        lineClamp: '2',
        boxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: theme.palette.fg['black'],
        fontSize: '21px',
        lineHeight: '24px'
    },
    footer: {
        paddingTop: '10px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        columnGap: '14px'
    },
    infoContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '5px'
    },
    infoText: {
        color: theme.palette.fg['black40Opacity'],
        fontSize: '13px',
        lineHeight: '16px'
    }
}))
