import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    fieldsContainer: {
        display: 'flex',
        rowGap: '12px',
        columnGap: '4px',
        flexWrap: 'wrap',
        width: '591px'
    },
    fieldCardContainer: {
        width: '194px'
    }
}))
