import React from 'react'
import { Box, Container } from '@material-ui/core'
import { useStyles } from './PaperFormWrapper_style'
import Paper from 'components/Paper/Paper'

const PaperFormWrapper = (props) => {
    const { children, hidden } = props
    if (hidden) return children
    const classes = useStyles()

    return (
        <Box className={classes.background}>
            <Container maxWidth='lg'>
                <Paper className={classes.mainPaperBox}>
                    {children}
                </Paper>
            </Container>
        </Box>
    )
}
export default PaperFormWrapper
