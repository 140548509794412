import React from 'react'
import { useStyles } from './CustomCheckbox_style'
import { Checkbox } from '@material-ui/core'
import IconFactory from 'icons/IconFactory/IconFactory'

const CustomCheckbox = (props) => {
    const classes = useStyles()

    return (
        <Checkbox
            color='primary'
            disableRipple
            classes={{ root: classes.checkbox }}
            icon={<IconFactory icon='checkbox-false' fontSize='20px' />}
            checkedIcon={<IconFactory icon='checkbox-true-filled' color='var(--background-purple)' fontSize='20px' />}
            {...props}
        />
    )
}

export default CustomCheckbox
