import { useState } from 'react'
import Bugsnag from '@bugsnag/js'

export const useLocalStorage = (key, defaultValue = null) => {
    const itemStr = localStorage.getItem(key)
    if (!itemStr) return defaultValue

    const item = JSON.parse(itemStr)
    const now = new Date()

    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key)
        return defaultValue
    }

    return item.value
}
