import React from 'react'

const PackageSaucerSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 512 640"
    >
        <g fill="#a000ff">
            <rect
                x="485.7"
                y="483"
                transform="matrix(0.8627 -0.5057 0.5057 0.8627 -180.3242 316.7495)"
                width="15"
                height="15"
            />
            <rect x="248.5" y="364.3" width="15" height="110.2" />
            <rect x="248.5" y="489.5" width="15" height="15" />
            <rect
                x="386.8"
                y="482.9"
                transform="matrix(0.8628 -0.5056 0.5056 0.8628 -193.8822 266.6778)"
                width="15"
                height="15.2"
            />
            <rect
                x="335.7"
                y="324.8"
                transform="matrix(0.8628 -0.5056 0.5056 0.8628 -156.7893 228.8486)"
                width="15"
                height="156.9"
            />
            <rect
                x="284.6"
                y="308.6"
                transform="matrix(0.8628 -0.5056 0.5056 0.8628 -119.7719 191.0689)"
                width="15"
                height="15.1"
            />
            <rect
                x="110.2"
                y="483"
                transform="matrix(0.5056 -0.8628 0.8628 0.5056 -364.9622 344.118)"
                width="15.2"
                height="15"
            />
            <rect
                x="90.4"
                y="395.8"
                transform="matrix(0.5056 -0.8628 0.8628 0.5056 -264.4368 345.0632)"
                width="156.9"
                height="15"
            />
            <rect
                x="212.3"
                y="308.7"
                transform="matrix(0.5056 -0.8628 0.8628 0.5056 -164.0911 346.0532)"
                width="15.1"
                height="15"
            />
            <rect
                x="11.3"
                y="483"
                transform="matrix(0.5056 -0.8628 0.8628 0.5056 -413.8973 258.7419)"
                width="15"
                height="15"
            />
            <path d="M414.1,170.6c-7.4-7.3-15.2-14.1-23.4-20.3l13.6-19.7l-12.3-8.5l-13.6,19.6c-15.5-10.1-32.1-18.2-49.5-24.1l9.5-19.6   l-13.5-6.5l-10.6,21.8c-16.4-4.4-33.4-6.9-50.8-7.5V73.4c14.8-3.4,25.9-16.7,25.9-32.5c0-18.4-15-33.4-33.4-33.4   s-33.4,15-33.4,33.4c0,15.8,11.1,29.1,25.9,32.5v32.3c-17.4,0.6-34.4,3.1-50.8,7.5l-10.6-21.8l-13.5,6.5l9.5,19.6   c-17.4,5.9-34,14-49.5,24.1L120,122l-12.3,8.5l13.6,19.7c-8.2,6.2-16,12.9-23.4,20.3H7.5v10.6l2.2,2.2   c37.1,37.1,82.6,65.6,131.9,82.8L23.7,467.3l12.9,7.6L157,269.5h91.4v-15h-94.6c-29.1-9.3-56.2-22.3-81.3-38.9h366.7   c-25,16.5-52.2,29.6-81.3,38.9h-94.6v15h91.4l120.4,205.4l12.9-7.6L370.4,266.1c49.3-17.1,94.8-45.6,131.9-82.8l2.2-2.2v-10.6   h-90.4V170.6z M237.6,40.9c0-10.1,8.2-18.4,18.4-18.4c10.2,0,18.4,8.2,18.4,18.4s-8.2,18.4-18.4,18.4S237.6,51,237.6,40.9z    M460.2,200.6H51.8c-6.1-4.8-12.1-9.8-18-15h214.7v-15H120c37.9-32.4,85.6-50,136-50s98.1,17.7,136,50H263.5v15h214.7   C472.3,190.8,466.3,195.8,460.2,200.6z" />
        </g>
    </svg>
)

export default PackageSaucerSVG
