/* eslint-disable react/prop-types */

import React from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useStyles } from '../FilledButton/FilledButton_style'

const LineButton = (props) => {
    const { children, color, disabled, style, newStyle, className, onClick, href, name, typoVariant } = props
    const classes = useStyles()

    return (
        <Button
            {...props}
            variant='outlined'
            color={color}
            disabled={disabled}
            style={{ ...style, textTransform: 'none', borderColor: 'var(--black)' }}
            className={newStyle ? `${classes.newStyleButton} ${className}` : className}
            onClick={onClick}
            href={href}
        >
            {children}
            {name &&
                <Typography variant={typoVariant} noWrap>
                    {name}
                </Typography>
            }
        </Button>
    )
}

LineButton.defaultProps = {
    color: 'primary',
    typoVariant: 'body2',
    style: {},
    className: {}
}

export default LineButton
