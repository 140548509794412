
import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconFactory from 'icons/IconFactory/IconFactory'
import { FormattedMessage } from 'react-intl'

const NoNotificationsCard = (props) => {
    return (
        <Box style={{ borderTop: '1px solid var(--grey)', maxWidth: '300px' }}>
            <MenuItem>
                <IconFactory icon='unicorn' fontSize='1.5rem'/>
                <Box my={2} ml={1}>
                    <Typography variant='body2' style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                        <FormattedMessage id='notifications.nothing_to_see'/>
                    </Typography>
                </Box>
            </MenuItem>
        </Box>

    )
}
export default NoNotificationsCard
