import React from 'react'

import { useStyles } from './NavigationUserActions_style'
import { Box } from '@material-ui/core'

import NavigationUserActionsLoggin from './NavigationUserActionsLoggin/NavigationUserActionsLoggin'
import NavigationUserActionsLogout from './NavigationUserActionsLogout/NavigationUserActionsLogout'
import LanguageDropdown from 'inputs/LanguageDropdown/LanguageDropdown'

const NavigationUserActions = (props) => {
    const classes = useStyles()
    const { altLangUrls, user, paramsForUrlTranslation, darkMode } = props

    return (
        <Box className={classes.userActionsContainer}>
            <LanguageDropdown
                altLangUrls={altLangUrls}
                allLangsFlat
                paramsForUrlTranslation={paramsForUrlTranslation}
            />
            {user
                ? <NavigationUserActionsLoggin user={user} darkMode={darkMode} />
                : <NavigationUserActionsLogout darkMode={darkMode} />
            }
        </Box>
    )
}

export default NavigationUserActions
