import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    link: {
        '&:hover': {
            textDecoration: 'none'
        }
    },
    container: {
        cursor: 'pointer',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        height: 480,
        minWidth: 315,
        maxWidth: 360,
        backgroundColor: 'white',
        border: 'var(--background-silver) 1px solid',
        borderRadius: 19,
        [theme.breakpoints.down('sm')]: {
            maxWidth: 300
        },
        '&:hover': {
            cursor: 'pointer',
            borderColor: `1px solid ${theme.palette.fg.black}`
        }
    },
    cardImageContainer: {
        height: '40%'
    },
    cardMedia: {
        height: '100%',
        width: '100%',
        borderRadius: '19px 19px 0 0',
        objectFit: 'cover'
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexGrow: 1,
        padding: '23px 30px',
        gap: 24,
        [theme.breakpoints.down('sm')]: {
            padding: 24
        }
    },
    cardText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: 24
    },
    cardTitleContainer: {
        display: 'flex',
        gap: 14,
        alignItems: 'center'
    },
    iconContainer: {
        width: 54,
        height: 54,
        backgroundColor: 'var(--background-grey-light)',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    cardDescriptionContainer: {
        display: 'flex'
    },
    cardTitle: {
        fontSize: 24,
        lineHeight: '32px',
        maxWidth: 200
    },
    cardDescription: {
        color: theme.palette.primary.light,
        lineHeight: '20px',
        [theme.breakpoints.down('sm')]: {
            lineHeight: '24px'
        }
    },
    bottomCardContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16
    },
    jobAvailableContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        gap: 16
    },
    jobAvailable: {
        display: 'flex',
        flexDirection: 'column',
        width: 90
    },
    jobAvailableNumber: {
        fontSize: 18,
        lineHeight: '18px',
        fontFamily: 'GreedStandard-Bold'
    },
    jobAvailableText: {
        fontSize: 12,
        textTransform: 'lowercase',
        color: theme.palette.primary.light
    }
}))
