import RouteMap from 'assets/RouteMap'

const formatDateObject = (date) => {
    return new Date(date).toLocaleDateString(
        RouteMap.GetLocaleFromUrl(),
        {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        }
    )
}

const isBlank = (value) => {
    return (
        value == null ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0)
    )
}

const isPresent = (value) => {
    return !isBlank(value)
}

export default { formatDateObject, isBlank, isPresent }
