import React from 'react'
import NewArticle from '../../pages/user/NewArticle/NewArticle'

const AdminEditArticle = (props) => {
    return (
        <NewArticle
            user={props.user}
            companyId={props.company_id}
            action={props.mod}
            article={props.article}
            companies={props.companies}
            admin
            disableFooter
            disableNavbar
            linkedCompanies={props.linkedCompanies}
        />
    )
}
export default AdminEditArticle
