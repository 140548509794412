/* eslint-disable react/react-in-jsx-scope */
import React from 'react'
import { FormattedMessage } from 'react-intl'

const GetProfileUrl = (userType) => {
    switch (userType) {
    case 'employer':
        return 'users/company_jobs'
    case 'kot_owner':
        return 'users/kots'
    case 'student':
        return 'users/dashboard'
    }
}

const GetProfileName = (user) => {
    if (user.type === 'student') {
        return user.first_name
            ? user.first_name
            : <FormattedMessage id="landing_connected_user.my_account" />
    } else {
        return <FormattedMessage id="landing_connected_user.my_account" />
    }
}

export default { GetProfileUrl, GetProfileName }
