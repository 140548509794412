import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    styledLink: {
        width: '36px',
        height: '36px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 8,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
        }
    }
}))
