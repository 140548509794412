import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({

    tag: {
        width: 'fit-content',
        color: theme.palette.fg['black'],
        backgroundColor: theme.palette.bg['white'],
        border: `1px solid ${theme.palette.fg['black']}`,
        fontFamily: 'GreedStandard-Medium',
        fontSize: '16px',
        lineHeight: '18px',
        display: 'flex',
        alignItems: 'center',
        columnGap: '10px',
        cursor: 'pointer',
        borderRadius: 50,
        padding: '6px 8px'
    },

    selectedTag: {
        backgroundColor: theme.palette.bg['purple'],
        color: theme.palette.fg['white'],
        fontFamily: 'GreedStandard-Bold',
        boxShadow: `2px 2px 0px 0px ${theme.palette.bg['black']}`
    },
}))
