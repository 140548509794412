import React from 'react'
import { injectIntl } from 'react-intl'
import { Box, useTheme, DialogTitle, Typography, Dialog, IconButton } from '@material-ui/core'

import IconFactory from 'icons/IconFactory/IconFactory'
import ActivateCompanyJobModalStep1View from './ActivateCompanyJobModalStep1View'
import PaymentCompanyJobModalStep2View from 'modals/BoostCompanyJobModal/BoostCompanyJobModalView/PaymentCompanyJobModalStep2View'
import Payment from 'pages/user/JobCreationFunnel/shared/forms/Payment/Payment'
import { useStyles } from './ActivateCompanyJobModalView_style'

const ActivateCompanyJobModalView = (props) => {
    const { info, creditCount, closeModal, activateOrDeactivateJob, loading, step, setStep, user, buyCredits, selectedPack, setSelectedPack, company } = props
    const classes = useStyles()
    const theme = useTheme()
    if (!info) return null

    return (
        <Dialog
            maxWidth={step === 1 ? 'sm' : 'md'}
            open
            onClose={closeModal}
            disableScrollLock
            aria-labelledby="responsive-dialog-delete"
            fullWidth
            PaperProps={{ className: classes.dialogContainer }}
        >
            <DialogTitle onClose={closeModal}>
                <Typography variant='h2' style={{ marginTop: '1rem' }}>
                    {info.title}
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={closeModal}
                    style={{
                        position: 'absolute',
                        right: theme.spacing(1),
                        top: 5,
                        color: theme.palette.grey[500]
                    }}
                >
                    <IconFactory icon='close' />
                </IconButton>
            </DialogTitle>
            {step === 1 &&
                <ActivateCompanyJobModalStep1View
                    creditCount={creditCount}
                    loading={loading}
                    closeModal={closeModal}
                    setStep={setStep}
                    activateOrDeactivateJob={activateOrDeactivateJob}
                    info={info}
                />
            }
            {step === 2 && info.jobToActivate.type !== 'first_job' &&
                <PaymentCompanyJobModalStep2View
                    creditCount={creditCount}
                    loading={loading}
                    closeModal={closeModal}
                    setStep={setStep}
                    user={user}
                    buyCredits={buyCredits}
                    selectedPack={selectedPack}
                    setSelectedPack={setSelectedPack}
                />
            }
            {step === 2 && info.jobToActivate.type === 'first_job' &&
                <Box py={2} px={3}>
                    <Payment
                        jobType={info.jobToActivate.type}
                        jobInfos={info.jobToActivate}
                        credits={creditCount}
                        user={user}
                        updateIsBuyingCredits={() => {}}
                        company={company}
                    />
                </Box>
            }
        </Dialog>
    )
}

export default injectIntl(ActivateCompanyJobModalView)
