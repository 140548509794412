import React from 'react'
import { Image, Transformation } from 'cloudinary-react'

import { useStyles } from './NavigationDropDownArticleCard_style'
import { Box, Button, Typography } from '@material-ui/core'

import DateHandler from 'assets/DateHandler'
import IconFactory from 'shared/icons/IconFactory/IconFactory'

const NavigationDropDownArticleCard = (props) => {
    const classes = useStyles()
    const { article } = props

    return (
        <Box>
            <Button className={classes.articleCardContainer} href={article.full_url}>
                <Box className={classes.articleCard}>
                    <Box className={classes.articlePictureContainer}>
                        <Image
                            className={classes.articlePicture}
                            cloudName="studentbe"
                            publicId={article.cover_image.cloudinary_key}
                            alt={article.cover_image.cloudinary_key}
                        >
                            <Transformation
                                flags="progressive"
                                fetchFormat="auto"
                                width='132'
                                height='88'
                                quality="auto:best"
                                crop="fill"
                            />
                        </Image>
                    </Box>
                    <Box className={classes.articleContent}>
                        <Box>
                            <Typography variant='h3' component='div' className={classes.articleTypograhy}>
                                {article.title}
                            </Typography>
                        </Box>
                        <Box className={classes.articleReadTimeContainer}>
                            <Typography variant='h5' component='div' className={classes.articleTypograhy}>
                                {DateHandler.ToBasicFormat(article.date)}
                            </Typography>
                            <Box>
                                <IconFactory
                                    icon='clock'
                                    fontSize='12px'
                                    color='var(--grey)'
                                    style={{
                                        fontFamily: 'GintoNormal-Medium, sans-serif !important',
                                        margin: '0 4px'
                                    }}
                                />
                                <Typography variant='body2' component='span' className={classes.articleReadTime}>
                                    {`${article.read_time} min`}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Button>
        </Box>
    )
}

export default NavigationDropDownArticleCard
