/* eslint-disable react/prop-types */
import React from 'react'
import MultipleSelectFilterView from './MultipleSelectFilterView'

const MultipleSelectFilter = (props) => {
    const { setFilters, filters, type, collection, filterTextId, width, showTags, dropdownPosition, modal } = props

    const handleFiltersArray = (type, event) => {
        const newSelectedItems = event.target.value

        handleTagChange(newSelectedItems.pop())
    }

    const handleTagChange = (item) => {
        if (item === undefined) return null

        const selectedItems = filters[type]
        let newSelectedItems = []
        if (selectedItems.some(selectedItem => selectedItem.value === item.value)) {
            newSelectedItems = selectedItems.filter(selectedItem => selectedItem.value !== item.value)
        } else {
            newSelectedItems = [...selectedItems, item]
        }
        setFilters({ ...filters, [type]: newSelectedItems })
    }

    return (
        <MultipleSelectFilterView
            handleFiltersArray={handleFiltersArray}
            handleTagChange={handleTagChange}
            type={type}
            filters={filters}
            collection={collection}
            filterTextId={filterTextId}
            width={width}
            showTags={showTags}
            dropdownPosition={dropdownPosition}
            modal={modal}
        />
    )
}

export default MultipleSelectFilter
