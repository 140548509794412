import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    kotShowBanner: {
        backgroundColor: 'white',
        zIndex: 9,
        position: 'sticky',
        top: 0
    },
    bannerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: 16,
        paddingTop: 32,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 16,
            flexDirection: 'column'
        }
    },
    bannerContainerSticky: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: 16,
        paddingTop: 16
    },
    infoBannerContainer: {
        display: 'flex'
    },
    kotInfoContainer: {
        marginLeft: 20,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0
        }
    },
    kotDate: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        fontSize: 12,
        color: 'var(--foreground-grey-dark)'
    },
    titleBoxSticky: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: 12,
            marginBottom: 12
        },
        gap: 12
    },
    kotTitleBox: {
        fontFamily: 'GreedWide-Bold',
        fontSize: 36,
        marginBottom: 16,
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
            marginTop: 20,
            marginBottom: 12,
            marginRight: 12
        }
    },
    kotTitleBoxSticky: {
        fontSize: 18,
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            marginBottom: 0
        }

    },
    kotLocalisationContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 16,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 12
        }
    },
    kotLocalisationContainerSticky: {
        marginBottom: 0
    },
    iconContainer: {
        marginLeft: 12,
        marginRight: 24
    },
    mapIcon: {
        marginRight: '8px',
        fontSize: '22px',
        [theme.breakpoints.down('sm')]: {
            marginRight: 8
        }
    },
    mapIconSticky: {
        marginRight: 8
    },
    address: {
        textTransform: 'capitalize',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px !important'
        }
    },
    addressSticky: {
        fontSize: '12px !important'
    },
    applyBox: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            marginTop: 14
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: 12
        }
    },
    applyBoxSticky: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 0
        },
        [theme.breakpoints.up('md')]: {
            alignItems: 'center'
        }
    },
    kotPriceContainer: {
        marginTop: 0,
        height: 'fit-content',
        backgroundColor: 'var(--background-yellow)',
        padding: '14px 28px',
        borderRadius: 50,
        [theme.breakpoints.up('md')]: {
            marginLeft: 24,
            marginRight: 24
        }
    },
    kotPrice: {
        fontFamily: 'GreedWide-Bold',
        fontSize: 18,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px !important',
            display: 'flex',
            justifyContent: 'center'
        }
    },
    kotPriceSticky: {
        fontSize: 14
    },
    kotTitleAndPriceContainer: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
        }
    },
    textLight: {
        color: 'var(--grey)',
        maxWidth: 280,
        marginLeft: 12,
        marginTop: 12,
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px !important',
            maxWidth: '100%',
            marginLeft: 0
        }
    },
    applyBoxButton: {
        width: '100%',
        whiteSpace: 'nowrap'
    }
}))
