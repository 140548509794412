import React from 'react'

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'

import { useStyles } from './AdminDataTable_style'

const AdminDataTable = (props) => {
    const classes = useStyles()
    const { results } = props

    const dataFields = [
        { header: 'First name', identifier: 'first_name' },
        { header: 'Last name', identifier: 'last_name' },
        { header: 'Email', identifier: 'email' },
        { header: 'Zip', identifier: 'zip_complet' },
        { header: 'Province Complet', identifier: 'province_complet' },
        { header: 'Province Living', identifier: 'province_living' },
        { header: 'Province Studying', identifier: 'province_studying' },
        { header: 'Phone number', identifier: 'phone_number' },
        { header: 'Language', identifier: 'language' },
        { header: 'Dutch knowledge', identifier: 'dutch_knowledge' },
        { header: 'French knowldege', identifier: 'french_knowledge' },
        { header: 'School or Uni', identifier: 'school_or_uni' },
        { header: 'School type', identifier: 'school_type' },
        { header: 'Study cluster', identifier: 'study_cluster' },
        { header: 'Field of study', identifier: 'field_of_study' },
        { header: 'Specialization', identifier: 'specialization' },
        { header: 'IT', identifier: 'IT' },
        { header: 'High school type', identifier: 'high_school_type' },
        { header: 'High school field of study', identifier: 'high_school_field_of_study' },
        { header: 'Current scholar year', identifier: 'current_scholar_year' },
        { header: 'Graduation year', identifier: 'graduation_year' },
        { header: 'Year current sign in', identifier: 'year_current_sign_in_at' },
        { header: 'Current sign in at', identifier: 'current_sign_in_at' },
        { header: 'Year created at', identifier: 'year_created_at' },
        { header: 'Created at', identifier: 'created_at' },
        { header: 'Birthdate', identifier: 'birthdate' },
        { header: 'Gender', identifier: 'gender' },
        { header: 'Driving Licence', identifier: 'driving_licence' },
        { header: 'CV url', identifier: 'cv_url' },
    ]

    return (
		<TableContainer className={classes.table} component={Paper}>
            <Table aria-label="simple table" stickyHeader>
                <TableHead>
                    <TableRow className={classes.noWordBreak}>
                        {dataFields.map((dataField, index) => {
                            if (index === 0) {
                                return <TableCell className={classes.stickyHeader}>{dataField.header}</TableCell>
                            } else {
                                return <TableCell align="right">{dataField.header}</TableCell>
                            }
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {results.items.map((row) => (
                        <TableRow key={row.id} className={classes.noWordBreak}>
                            {dataFields.map((dataField, index) => {
                                if (index === 0) {
                                    return <TableCell className={classes.stickyRow} component="th" scope="row">{row[dataField.identifier]}</TableCell>
                                } else {
                                    const data = row[dataField.identifier]
                                    return <TableCell align="right">{typeof data == "boolean" ? data?.toString() : data}</TableCell>
                                }
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default AdminDataTable
