import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    jobContentContainer: {
        width: '100%',
        padding: '0px 28px 35px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '35px',
        [theme.breakpoints.up('md')]: {
            padding: '0px',
            rowGap: '45px'
        }
    },

    section: {
        width: '100%'
    },

    sectionTitleContainer: {
        paddingBottom: '13px',
        display: 'flex',
        columnGap: '8px',
        [theme.breakpoints.up('md')]: {
            columnGap: '12px'
        }
    },

    iconContainer: {
        width: '28px',
        height: '28px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    sectionTitle: {
        color: theme.palette.fg.black,
        fontSize: '18px',
        lineHeight: '28px'
    },

    sectionBody: {
        color: theme.palette.fg.black,
        fontSize: '16px',
        lineHeight: '20px'
    }
}))
