import React, { useState } from 'react'
import CompanyJobAPI from 'api/CompanyJobAPI'
import DeleteCompanyJobsModalView from './DeleteCompanyJobsModalView'
import { injectIntl } from 'react-intl'

const DeleteCompanyJobsModal = (props) => {
    const { checkedItems, checkedJobsLength, showModal, setShowModal, setFeedback, removeJobsFromItems } = props
    if (!showModal) return null

    const { formatMessage } = props.intl

    const [deleteInfo, setDeleteInfo] = useState('')
    const [loading, setLoading] = useState(false)

    const removeCheckedJobs = () => {
        setLoading(true)
        const formattedCheckedItems = checkedItems.map(job => { return { id: job.id, type: job.type } })
        const data = {
            items: formattedCheckedItems,
            delete_reason: deleteInfo
        }
        CompanyJobAPI.removeCheckedJobs(data).then((response) => {
            setLoading(false)
            setShowModal(false)
            setFeedback({
                open: true,
                severity: 'success',
                message: formatMessage({ id: 'delete_offer_popup.company-job.success' })
            })
            removeJobsFromItems(formattedCheckedItems)
        })
    }

    const handleDeleteInfoChange = (event) => {
        setDeleteInfo(event.target.value)
    }

    const closeModal = () => {
        setShowModal(false)
    }

    return (
        <DeleteCompanyJobsModalView
            removeCheckedJobs={removeCheckedJobs}
            checkedJobsLength={checkedJobsLength}
            closeModal={closeModal}
            loading={loading}
            handleDeleteInfoChange={handleDeleteInfoChange}
        />
    )
}

export default injectIntl(DeleteCompanyJobsModal)
