import React from 'react'
import axios from 'axios'
import { Route } from 'react-router-dom'

import ConversationAPI from 'api/ConversationAPI'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import MessagesHeader from '../MessagesHeader/MessagesHeader'
import MessagesStudentIndex from './MessagesStudentIndex'
import MessagesDiscussion from './MessagesDiscussion'

export default class MessageMainStudent extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            indexType: 'byKot',
            focusCandidacies: [],
            kotFocus: '',
            isChatting: 'notChatting',
            messages: [],
            candidacies: null,
            conversationPanelPage: 1,
            loadingPanel: true,
            lastPage: false,
            conversationsLoaded: false
        }
        axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
        this.loadMore = this.loadMore.bind(this)
        this.getOffers = this.getOffers.bind(this)
        this.pathname = window.location.pathname
        this.lang = this.pathname.match(/^\/([^\/]*)\//)[1]
    }

    componentDidMount () {
        this.getOffers()
    }

    getOffers (page = 1) {
        this.setState({ loadingPanel: true })
        ConversationAPI.GetOffers({
            page: page,
            userType: this.props.userType
        }).then(response => {
            let newCandidacies = []
            if (page > 1) {
                newCandidacies = this.state.candidacies.concat(response.data.offers)
            } else {
                newCandidacies = response.data.offers
            }
            this.setState({
                loadingPanel: false,
                conversationPanelPage: page,
                candidacies: newCandidacies,
                lastPage: response.data.last_page,
                conversationsLoaded: true
            })
        })
    }

    loadMore () {
        this.setState({
            conversationPanelPage: this.state.conversationPanelPage + 1
        }, () => {
            this.getOffers(
                this.state.conversationPanelPage
            )
        })
    }

    render () {
        return (
            <PageWrapper user={this.props.user} disableFooter>
                <div className="messages-container messages-kot-owner">
                    <div className="messages-index-container">
                        <MessagesHeader
                            user={this.props.user}
                            type="student"
                        />
                        <div className="candidacy-message-index">
                            <MessagesStudentIndex
                                candidacies={this.state.candidacies}
                                lastPage={this.state.lastPage}
                                loadMore={this.loadMore}
                            />
                        </div>
                    </div>
                    <div className='messages-discussion-and-apllications'>
                        <div className="messages-discussion">
                            {this.state.conversationsLoaded &&
                                <Route
                                    path='/messages/:candiid/:candiType/:conversationid'
                                    render={(props) =>
                                        <MessagesDiscussion
                                            {...props}
                                            key='MessagesDiscussion'
                                            user={this.props.user}
                                        />
                                    }
                                />
                            }
                        </div>
                        <div className="messages-applications"></div>
                    </div>
                </div>
            </PageWrapper>
        )
    }
}
