/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react'

import PageWrapper from '../../../shared/wrappers/PageWrapper/PageWrapper'
import SharedTabs from '../../../shared/components/SharedTabs/SharedTabs'
import KotOwnerProfile from './KotOwnerProfile/KotOwnerProfile'
import StudentProfile from './StudentProfile/StudentProfile'

export default class Profile extends React.Component {
    renderStudentProfile () {
        return (
            <StudentProfile
                {...this.props}
            />
        )
    }

    renderKotOwnerProfile () {
        return (
            <KotOwnerProfile
                {...this.props}
            />
        )
    }

    renderProfile () {
        switch (this.props.userType) {
        case 'kot_owner':
            return this.renderKotOwnerProfile()
        case 'student':
            return this.renderStudentProfile()
        default:
        }
    }

    render () {
        return (
            <div className="desktop-profile profile-container">
                <PageWrapper user={this.props.user} disableFooterLists>
                    <SharedTabs user={this.props.user} currentTab='users/profile/edit' />
                    {this.renderProfile()}
                </PageWrapper>
            </div>
        )
    }
};
