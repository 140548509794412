import React from 'react'
import { Typography, Box } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { useStyles } from '../../PersonalDashboard_style'
import Button from 'shared/components/Button/Button.jsx'
import ItemIcon from 'components/ItemIcon/ItemIcon'

const RecommendedJobsForUserSectionCTA = (props) => {
    const { openProfileEditFormOnStep, titleId, textId, openOnStep, type } = props
    const classes = useStyles()

    return (<>
        <Box className={classes.iconTitleBox}>
            <ItemIcon type={type} width={14} height={14} />
            <Typography variant='h1' component='h2' className={classes.title}>
                <FormattedMessage id={titleId} />
            </Typography>
        </Box>
        <Typography variant='h2' className={classes.recommendedJobsCtaText}>
            <FormattedMessage id={textId} />
            <Button
                variant='secondary'
                onClick={() => openProfileEditFormOnStep(openOnStep, true)}
                // className={classes.recommendedJobsCtaButton}
            >
                <FormattedMessage id='personal_dashboard.recommend_jobs.student_jobs_for_user_school_button' />
            </Button>
        </Typography>
    </>)
}

export default RecommendedJobsForUserSectionCTA
