import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    textInputContainer: {
        width: '100%',
        height: '49px',
        padding: '0px 14px',
        border: `2px solid ${theme.palette.fg['black']}`,
        backgroundColor: theme.palette.bg['white'],
        borderRadius: '80px',
        display: 'flex',
        alignItems: 'center',
        columnGap: '5px',
        transition: 'box-shadow 0.3s ease-in-out',
        '&:hover': {
            boxShadow: `4px 4px 0px 0px ${theme.palette.bg['black']}`,
        },
    },

    searchIcon: {
        width: '21px',
        height: '21px',
    },

    textInput: {
        flex: 1,
        height: '100%',
        padding: '0px',
        fontFamily: 'GreedStandard-Regular',
        fontSize: '16px',
        lineHeight: '18px',
        color: theme.palette.fg['greyDark'],
        '&::placeholder': {
            fontFamily: 'GreedStandard-Regular',
            fontSize: '16px',
            lineHeight: '18px',
            color: theme.palette.fg['greyDark'],
            opacity: 1
        },
    }
}))
