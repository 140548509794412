import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    languageFilter: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: "'GintoNormal-Medium', sans-serif !important",
        gap: 5,
        '& .circle': {
            minWidth: '20px',
            height: '20px',
            padding: '5px',
            borderRadius: '50%',
            background: 'black',
            lineHeight: 1,
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '10px'
        },
        '& .circleDisabled': {
            background: 'var(--grey) !important'
        },
        '& .languageText': {
            fontSize: '12px',
            lineHeight: 1,
            textTransform: 'uppercase'
        }
    },
    button: {
        backgroundColor: 'var(--light-grey)',
        '&:focus': {
            backgroundColor: 'var(--light-grey)',
            color: 'black'
        }
    },
    buttonSelected: {
        backgroundColor: 'var(--red)',
        color: 'white',
        '&:focus': {
            backgroundColor: 'var(--red)',
            color: 'white'
        }
    }
}))
