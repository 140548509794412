import React from 'react'
import { useStyles } from './CheckboxList_style'
import { Box, Grid, FormControl, Typography, useTheme } from '@material-ui/core'
import IconFactory from 'icons/IconFactory/IconFactory'
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox'

const CheckboxListView = (props) => {
    const { selectedItems, collection, handleCheckboxChange } = props
    const classes = useStyles()
    const theme = useTheme()

    return (
        <FormControl fullWidth >
            <Grid container>
                {collection.map((domain, key) => (
                    <Grid item xs={12} md={6} key={key}>
                        <Box className={`${classes.checkboxLine} checkbox-list-line`} onClick={() => handleCheckboxChange(domain)}>
                            <CustomCheckbox
                                className={classes.checkbox}
                                icon={<IconFactory icon='checkbox-false' fontSize='20px' />}
                                checkedIcon={<IconFactory icon='checkbox-true-filled' color={theme.palette.fg.purple} fontSize='20px' />}
                                color={theme.palette.fg.black}
                                value={domain.value}
                                checked={selectedItems.some(elem => elem.value === domain.value)}
                            />
                            <Typography variant='body2' className={classes.checkboxText}>{domain.name}</Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </FormControl>
    )
}

export default CheckboxListView
