/* eslint-disable react/prop-types */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useStyles } from './SimilarItemsView_style'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import CardFactory from 'cards/CardFactory/CardFactory'
import MoreItemsCard from 'components/MoreItemsCard/MoreItemsCard'
import Hidden from '@material-ui/core/Hidden'
import RouteMap from 'assets/RouteMap.js'

const SimilarItemsView = (props) => {
    const { type, baseItem, userType, items, titleId, moreItemsId, title, moreItemsText, fullWidth, textValues } = props
    const classes = useStyles()

    const moreItemsURL = () => {
        if (!baseItem) return null
        let params = ''

        if (type === 'student_job') {
            if (type === 'student_job' && baseItem.city) {
                return RouteMap.LocationIndex('student_job', baseItem.address)
            }
        } else if (['internship', 'first_job'].includes(type) && baseItem.study_domains?.length > 0) {
            if (baseItem.study_domains?.length === 1) {
                return RouteMap.Tag(type, 'seoStudyDomainCollection', baseItem.study_domains[0].name)
            }
            baseItem.field_of_study.forEach((studyDomain) => {
                params += `study_domain=${studyDomain}&`
            })
        } else if (type === 'company') {
            return RouteMap.Show('company', baseItem.url)
        } else if (type === 'article') {
            return RouteMap.ArticleCategory(baseItem.type)
        } else if (type === 'kot' && baseItem.address) {
            return RouteMap.LocationIndex('kot', baseItem.address)
        }

        if (params.endsWith('&')) {
            params = params.slice(0, -1)
        }

        return RouteMap.Index(type, params)
    }

    return (
        <Box className={fullWidth && classes.fullWidth}>
            <Box className={classes.mainContainer}>
                <Box className={classes.mainBox}>
                    <Box className={classes.titleBox}>
                        <Typography className={classes.title} variant='h1' component='h2'>
                            {title}
                            {titleId && <FormattedMessage id={titleId} />}
                        </Typography>
                    </Box>
                    <Grid container className={classes.cardGrid}>
                        <Grid item className={classes.jobCardContainer}>
                            <CardFactory
                                showJobType
                                type={items[0].type}
                                card={items[0]}
                                userType={userType}
                            />
                        </Grid>
                        {items[1] &&
                            <Hidden only='sm'>
                                <Grid item className={classes.jobCardContainer}>
                                    <CardFactory
                                        showJobType
                                        type={items[0].type}
                                        card={items[1]}
                                        userType={userType}
                                    />
                                </Grid>
                            </Hidden>
                        }
                        {items[2] &&
                            <Hidden mdDown>
                                <Grid item className={classes.jobCardContainer}>
                                    <CardFactory
                                        showJobType
                                        type={items[0].type}
                                        card={items[2]}
                                        userType={userType}
                                    />
                                </Grid>
                            </Hidden>
                        }
                        <Grid item>
                            <MoreItemsCard
                                title={moreItemsText}
                                titleId={moreItemsId}
                                moreItemsURL={moreItemsURL()}
                                textValues={textValues}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}

export default SimilarItemsView
