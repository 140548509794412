import React from 'react'

const MoneySVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86 64.62" {...props}>
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path d="M78,6H14a8,8,0,0,0-8,8V56.62a8,8,0,0,0,8,8H78a8,8,0,0,0,8-8V14A8,8,0,0,0,78,6Z" />
                <path d="M72,0H8A8,8,0,0,0,0,8V50.62a8,8,0,0,0,8,8H72a8,8,0,0,0,8-8V8A8,8,0,0,0,72,0Z" />
                <path className="cls-1" fill="white" d="M72,16a8,8,0,0,1-8-8H16a8,8,0,0,1-8,8V42.62a8,8,0,0,1,8,8H64a8,8,0,0,1,8-8h0V16ZM40,40A10.69,10.69,0,1,1,50.67,29.28,10.74,10.74,0,0,1,40,40Z" />
            </g>
        </g>
    </svg>
)

export default MoneySVG
