import React from 'react'
import KotMessageCard from '../MessagesCard/KotMessageCard'

export default class IndexKotOwnerByKot extends React.Component {
    render () {
        if (!this.props.kots) { return null }
        let i = 0
        let kotCards = this.props.kots.sort((a, b) => {
            if (a.last_message_time === null) {
                return 1
            } else if (b.last_message_time === null) {
                return -1
            }
            return Date.parse(b.last_message_time) - Date.parse(a.last_message_time)
        })
        kotCards = this.props.kots.map((kot) => {
            i++
            return (
                <KotMessageCard
                    kot={kot}
                    openItem={this.props.openItem}
                    type='kot'
                    address={kot.address ? kot.address.street : 'kot'}
                    key={'offer-message-card-' + i}
                />
            )
        })

        return (
            <div className="kot-owner-index-main">
                {kotCards}
            </div>
        )
    }
}
