import React from 'react'
import { Helmet } from 'react-helmet'
import RouteMap from 'assets/RouteMap'
import DefaultHelmet from './DefaultHelmet'

class JobfairsHelmet extends React.Component {
    render () {
        return <>
            <DefaultHelmet
                title='Student.be at your jobfair!'
                description="The Student.be team is here to make jobfairs much more fun and interesting. Get info, ask for a CV check and win prizes."
                imageCloudinaryKey='assets/wheel_of_fortune'
            />
            <Helmet>
                <meta property='og:type' content='website' />
                <meta property="og:url" content={`https://www.student.be${window.location.pathname}`} />
                <link rel="canonical" href={`https://www.student.be${window.location.pathname}`} />
                <link rel="alternate" noindex hrefLang="en-BE" href={RouteMap.Page('jobfairs-2023', 'en')} />
                <link rel="alternate" noindex hrefLang="x-default" href={RouteMap.Page('jobfairs-2023', 'en')} />
            </Helmet>
        </>
    }
}

export default JobfairsHelmet
