import React from 'react'

const ImageSVG = (props) => (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <rect x="79.09" y="120.11" width="419.47" height="360.01" rx="40.13" ry="40.13"/>
        <rect x="54.55" y="89.59" width="384.36" height="336.32" fill="#fff"/>
        <path d="M415.88,105.48c7.53,0,13.68,6.16,13.68,13.68v273.5l-4.28-5.56-116.31-150.52c-3.85-5.05-9.92-7.95-16.25-7.95s-12.32,2.91-16.25,7.95l-70.98,91.85-26.08-36.52c-3.85-5.39-10.01-8.55-16.68-8.55s-12.83,3.16-16.68,8.64l-68.42,95.78-3.85,5.3V119.16c0-7.53,6.16-13.68,13.68-13.68h328.41ZM87.47,64.43c-30.19,0-54.73,24.54-54.73,54.73v273.67c0,30.19,24.54,54.73,54.73,54.73h328.41c30.19,0,54.73-24.54,54.73-54.73V119.16c0-30.19-24.54-54.73-54.73-54.73H87.47ZM155.89,228.63c22.67,0,41.05-18.38,41.05-41.05s-18.38-41.05-41.05-41.05-41.05,18.38-41.05,41.05,18.38,41.05,41.05,41.05Z"/>
    </svg>
)

export default ImageSVG
