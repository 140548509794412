import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './TagMultiSelect_style'
import { Box, Button } from '@material-ui/core'

import Tag from '../Tag/Tag'

const TagMultiSelectView = (props) => {
    const { handleChange, collection, enableSelectAll, selectedAll, handleToggle, selectedItems } = props
    const classes = useStyles()
    return (
        <Box className={classes.selectedTagsContainer}>
            {collection.map((item) => {
                const itemIsSelected = selectedItems.some(selectedItem => selectedItem.value === item.value)

                return (
                    <Tag
                        key={item.value}
                        item={item}
                        isSelected={itemIsSelected}
                        handleClick={() => handleChange(item)}
                    />
                )
            })}
            {enableSelectAll &&
                <Button
                    color='primary'
                    size='small'
                    onClick={handleToggle}
                    style={{ display: 'flex', justifyContent: 'flex-start', width: 'fit-content' }}
                >
                    {selectedAll
                        ? <FormattedMessage id="tag_toggle_list.deselect_all" />
                        : <FormattedMessage id="tag_toggle_list.select_all" />
                    }
                </Button>
            }
        </Box>
    )
}

export default TagMultiSelectView
