
import React from 'react'

import { styles } from './KotCard_style'
import { withStyles } from '@material-ui/core/styles'
import { Card, CardMedia, Typography, Box, useTheme } from '@material-ui/core'

import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import CollectionHandler from 'assets/CollectionHandler.js'
import IconFactory from 'icons/IconFactory/IconFactory'
import CloudinaryURLHelper from 'assets/CloudinaryURLHelper'
import FavoriteAction from 'components/FavoriteAction/FavoriteAction'
import CardImageCarousel from './components/CardImageCarousel'

const KotCard = (props, thisRef) => {
    const { kot, id } = props
    const { classes } = props
    const theme = useTheme()

    const handleMouseOver = () => {
        if (props.mapProviderProps) {
            props.mapProviderProps.flyToCard(kot.latitude, kot.longitude)
        }
    }
    return (
        <Box id={id} onMouseOver={handleMouseOver} className={`${classes.wrapper} kot-card`}>
            <Card className={classes.cardRoot}>
                <Box className={classes.mediaContainer}>
                    {kot.images && kot.images.length > 0
                        ? <CardImageCarousel kot={kot} />
                        : <CardMedia
                            className={classes.media}
                            image={CloudinaryURLHelper.getImageUrl(
                                'DEFAULT_LOGO_STUDENT',
                                'default-company-logo-black'
                            )}
                            alt='default company logo black'
                        />
                    }
                </Box>
                <RouteMapLink
                    showItemType='kot'
                    showItemUrl={kot.url}
                    className={classes.kotCardInfo}
                >
                    <Typography
                        variant='h3'
                        component='p'
                        className={classes.title}
                    >
                        {kot.kot_type
                            ? kot.title.substring(0, 60) +
                            (kot.title.length > 60
                                ? '...'
                                : ''
                            )
                            : ''
                        }
                    </Typography>
                    <Box className={classes.downContainer}>
                        <Typography className={classes.type}>
                            {kot.kot_type
                                ? CollectionHandler.Translate('kotTypeCollection', kot.kot_type.toLowerCase())
                                : ''
                            }
                        </Typography>
                        <Box className={classes.kotDetails}>
                            <Box className={classes.leftContainer}>
                                <Box className={classes.roomInfoContainer}>
                                    <IconFactory icon='bed-front' fontSize='14px' color={theme.palette.fg['black40Opacity']} />
                                    <Typography
                                        component='span'
                                        className={classes.infoText}
                                    >
                                        {kot.available_rooms}
                                    </Typography>
                                </Box>
                                <Box className={classes.cityInfoContainer}>
                                    <IconFactory icon='marker' fontSize='14px' color={theme.palette.fg['black40Opacity']} />
                                    <Typography
                                        component='span'
                                        className={classes.infoText}
                                    >
                                        {kot.city.toLowerCase()}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className={classes.rightContainer}>
                                <FavoriteAction
                                    item={props.kot}
                                    userType={props.userType}
                                    color={theme.palette.fg['black']}
                                    onItemCard
                                />
                            </Box>
                        </Box>
                    </Box>
                </RouteMapLink>
            </Card>
        </Box>
    )
}

export default withStyles(styles)(KotCard)
