import axios from 'axios'
import ReactOnRails from 'react-on-rails'

import {
    GET_COMPANY_JOBS_API,
    API_LINKS,
    COMPANY_JOBS_API
} from './env'

const GetCompanyJobs = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: GET_COMPANY_JOBS_API,
        method: 'get',
        params: data
    })
}

const removeCheckedJobs = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: COMPANY_JOBS_API,
        method: 'delete',
        data: data
    })
}

const activateJob = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: COMPANY_JOBS_API,
        method: 'patch',
        data: data
    })
}

const DuplicateCompanyJob = (job) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: API_LINKS[job.type],
        method: 'post',
        data: { id: job.id }
    })
}

export default {
    GetCompanyJobs,
    removeCheckedJobs,
    DuplicateCompanyJob,
    activateJob
}
