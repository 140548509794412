import React from 'react'
import { Typography } from '@material-ui/core'

import { useStyles } from './FieldError_style'

const FieldError = (props) => {
    const classes = useStyles()
    const { errorMessage } = props
    if (!errorMessage) return null

    return (
        <Typography id='fieldError' variant='body2' className={classes.errorMessage}>
            {errorMessage}
        </Typography>
    )
}

export default FieldError
