/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import Picker from 'emoji-picker-react'
import { Box, ClickAwayListener, Popper } from '@material-ui/core'

const MessagesEmojiPicker = (props) => {
    const { addEmoji } = props
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const onEmojiClick = (event, emojiObject) => {
        addEmoji(emojiObject.emoji)
    }

    const handleClick = (e) => {
        setOpen(!open)
        setAnchorEl(e.currentTarget)
    }

    return (
        <Box>
            <Box onClick={handleClick} fontSize='1.5rem'>😁</Box>
            <Popper placement='top' open={open} anchorEl={anchorEl} transition disablePortal>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <Box>
                        <Picker onEmojiClick={onEmojiClick} />
                    </Box>
                </ClickAwayListener>
            </Popper>
        </Box>

    )
}

export default MessagesEmojiPicker
