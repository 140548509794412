import React from 'react'

import PageWrapper from 'shared/wrappers/PageWrapper/PageWrapper'
import HomeEmployerPage from './HomeEmployerPage'
import HomeEmployerPageHelmet from 'helmets/HomeEmployerPageHelmet'

const HomeEmployer = (props) => {
    const { user, employerArticles } = props

    return (
        <PageWrapper user={user} disableFooterLists>
            <HomeEmployerPageHelmet />
            <HomeEmployerPage
                user={user}
                employerArticles={employerArticles}
            />
        </PageWrapper>
    )
}

export default HomeEmployer
