import React from 'react'
import { Box } from '@material-ui/core'

import CollectionHandler from 'assets/CollectionHandler'
import { useStyles } from './AdminHealthMetrics_style'

const AdminHealthMetrics = (props) => {
    const { users, companies, candidacies, offer_and_demand } = props.data
    const classes = useStyles()
    const studyDomains = CollectionHandler.Get('studyDomainCollection')
    const sortArray = (array) => {
        return array.sort((a, b) => b[1] - a[1])
    }
    const formatNumber = (number) => {
        return number.toLocaleString('be-NL')
    }
    const formatPercentage = (percentage) => {
        return percentage.toLocaleString("en", {style: "percent", maximumFractionDigits: 0 })
    }
    return (
        <div className={classes.main}>
            <h2>👩‍🚀 Users</h2>
            <h3>new users per year</h3>
            <ul>
                <li>2024 👉 <span>{formatNumber(users._2024)}</span> ~ YTD <span>{formatNumber(users.ytd_2024)}</span></li>
                <li>2023 👉 <span>{formatNumber(users._2023)}</span> ~ YTD <span>{formatNumber(users.ytd_2023)}</span></li>
                <li>2022 👉 <span>{formatNumber(users._2022)}</span> ~ YTD <span>{formatNumber(users.ytd_2022)}</span></li>
                <li>2021 👉 <span>{formatNumber(users._2021)}</span> ~ YTD <span>{formatNumber(users.ytd_2021)}</span></li>
                <li>2020 👉 <span>{formatNumber(users._2020)}</span> ~ YTD <span>{formatNumber(users.ytd_2020)}</span></li>
                <li>2019 👉 <span>{formatNumber(users._2019)}</span> ~ YTD <span>{formatNumber(users.ytd_2019)}</span></li>
                <li>2018 👉 <span>{formatNumber(users._2018)}</span> ~ YTD <span>{formatNumber(users.ytd_2018)}</span></li>
                <li>2017 👉 <span>{formatNumber(users._2017)}</span> ~ YTD <span>{formatNumber(users.ytd_2017)}</span></li>
                <li>pre 2017 👉 <span>{formatNumber(users.pre_2017)}</span></li>
            </ul>
            <h3>distribution of all users by type</h3>
            <ul>
                <li>students 👉 <span>{formatPercentage(users.types.all_time.student)}</span> all time ~ <span>{formatPercentage(users.types.last_2_years.student)}</span> last 2 years </li>
                <li>employers 👉 <span>{formatPercentage(users.types.all_time.company)}</span> all time ~ <span>{formatPercentage(users.types.last_2_years.company)}</span> last 2 years </li>
                <li>kot owners 👉 <span>{formatPercentage(users.types.all_time.kot_owner)}</span> all time ~ <span>{formatPercentage(users.types.last_2_years.kot_owner)}</span> last 2 years </li>
            </ul>
            <h2>🛸 Companies</h2>
            <h3>new companies per year</h3>
            <ul>
                <li>2024 👉 <span>{formatNumber(companies._2024)}</span> ~ YTD <span>{formatNumber(companies.ytd_2024)}</span></li>
                <li>2023 👉 <span>{formatNumber(companies._2023)}</span> ~ YTD <span>{formatNumber(companies.ytd_2023)}</span></li>
                <li>2022 👉 <span>{formatNumber(companies._2022)}</span> ~ YTD <span>{formatNumber(companies.ytd_2022)}</span></li>
                <li>2021 👉 <span>{formatNumber(companies._2021)}</span> ~ YTD <span>{formatNumber(companies.ytd_2021) }</span></li>
                <li>2020 👉 <span>{formatNumber(companies._2020)}</span> ~ YTD <span>{formatNumber(companies.ytd_2020) }</span></li>
                <li>2019 👉 <span>{formatNumber(companies._2019)}</span> ~ YTD <span>{formatNumber(companies.ytd_2019) }</span></li>
            </ul>
            <h3>new jobs per year</h3>
            <ul>
                <li>
                    student jobs
                    <ul>
                        <li>2024 👉 <span>{formatNumber(companies.new_jobs.student_job._2024)}</span> ~ YTD <span>{formatNumber(companies.new_jobs.student_job.ytd_2024)}</span></li>
                        <li>2023 👉 <span>{formatNumber(companies.new_jobs.student_job._2023)}</span> ~ YTD <span>{formatNumber(companies.new_jobs.student_job.ytd_2023)}</span></li>
                        <li>2022 👉 <span>{formatNumber(companies.new_jobs.student_job._2022)}</span> ~ YTD <span>{formatNumber(companies.new_jobs.student_job.ytd_2022)}</span></li>
                        <li>2021 👉 <span>{formatNumber(companies.new_jobs.student_job._2021)}</span> ~ YTD <span>{formatNumber(companies.new_jobs.student_job.ytd_2021)}</span></li>
                        <li>2020 👉 <span>{formatNumber(companies.new_jobs.student_job._2020)}</span> ~ YTD <span>{formatNumber(companies.new_jobs.student_job.ytd_2020)}</span></li>
                        <li>2019 👉 <span>{formatNumber(companies.new_jobs.student_job._2019)}</span> ~ YTD <span>{formatNumber(companies.new_jobs.student_job.ytd_2019)}</span></li>
                    </ul>
                </li>
                <li>
                    internships
                    <ul>
                        <li>2024 👉 <span>{formatNumber(companies.new_jobs.internship._2024)}</span> ~ YTD <span>{formatNumber(companies.new_jobs.internship.ytd_2024)}</span></li>
                        <li>2023 👉 <span>{formatNumber(companies.new_jobs.internship._2023)}</span> ~ YTD <span>{formatNumber(companies.new_jobs.internship.ytd_2023)}</span></li>
                        <li>2022 👉 <span>{formatNumber(companies.new_jobs.internship._2022)}</span> ~ YTD <span>{formatNumber(companies.new_jobs.internship.ytd_2022)}</span></li>
                        <li>2021 👉 <span>{formatNumber(companies.new_jobs.internship._2021)}</span> ~ YTD <span>{formatNumber(companies.new_jobs.internship.ytd_2021)}</span></li>
                        <li>2020 👉 <span>{formatNumber(companies.new_jobs.internship._2020)}</span> ~ YTD <span>{formatNumber(companies.new_jobs.internship.ytd_2020)}</span></li>
                        <li>2019 👉 <span>{formatNumber(companies.new_jobs.internship._2019)}</span> ~ YTD <span>{formatNumber(companies.new_jobs.internship.ytd_2019)}</span></li>
                    </ul>
                </li>
                <li>
                    starter jobs
                    <ul>
                        <li>2024 👉 <span>{formatNumber(companies.new_jobs.first_job._2024)}</span> ~ YTD <span>{formatNumber(companies.new_jobs.first_job.ytd_2024)}</span></li>
                        <li>2023 👉 <span>{formatNumber(companies.new_jobs.first_job._2023)}</span> ~ YTD <span>{formatNumber(companies.new_jobs.first_job.ytd_2023)}</span></li>
                        <li>2022 👉 <span>{formatNumber(companies.new_jobs.first_job._2022)}</span> ~ YTD <span>{formatNumber(companies.new_jobs.first_job.ytd_2022)}</span></li>
                        <li>2021 👉 <span>{formatNumber(companies.new_jobs.first_job._2021)}</span> ~ YTD <span>{formatNumber(companies.new_jobs.first_job.ytd_2021)}</span></li>
                        <li>2020 👉 <span>{formatNumber(companies.new_jobs.first_job._2020)}</span> ~ YTD <span>{formatNumber(companies.new_jobs.first_job.ytd_2020)}</span></li>
                        <li>2019 👉 <span>{formatNumber(companies.new_jobs.first_job._2019)}</span> ~ YTD <span>{formatNumber(companies.new_jobs.first_job.ytd_2019)}</span></li>
                    </ul>
                </li>
            </ul>
            <h2>🏃🏾 Activity</h2>
            <h3>% users with candidacy</h3>
            <ul>
                <li>2024 👉 <span>{formatPercentage(users.with_candidacy_2024)}</span></li>
                <li>2023 👉 <span>{formatPercentage(users.with_candidacy_2023)}</span></li>
                <li>2022 👉 <span>{formatPercentage(users.with_candidacy_2022)}</span></li>
                <li>2021 👉 <span>{formatPercentage(users.with_candidacy_2021)}</span></li>
                <li>2020 👉 <span>{formatPercentage(users.with_candidacy_2020)}</span></li>
                <li>2019 👉 <span>{formatPercentage(users.with_candidacy_2019)}</span></li>
            </ul>
            <h3>users who logged in</h3>
            <ul>
                <li>last 3 months 👉 <span>{formatNumber(users.current_sign_in_3_months)}</span></li>
                <li>last 6 months 👉 <span>{formatNumber(users.current_sign_in_6_months)}</span></li>
                <li>last 9 months 👉 <span>{formatNumber(users.current_sign_in_9_months)}</span></li>
                <li>last year 👉 <span>{formatNumber(users.current_sign_in_12_months)}</span></li>
                <li>last 2 years 👉 <span>{formatNumber(users.current_sign_in_24_months)}</span></li>
            </ul>
            <h3>users with job search status <span>{formatNumber(users.with_job_search_status)}</span>👇 </h3>
            <ul>
                <li>student job 👀 <span>{formatPercentage(users.with_job_search_status_sj)}</span></li>
                <li>internship 👀 <span>{formatPercentage(users.with_job_search_status_int)}</span></li>
                <li>1st job 👀 <span>{formatPercentage(users.with_job_search_status_first)}</span></li>
                <li>kot 👀 <span>{formatPercentage(users.with_job_search_status_kot)}</span></li>
                <li>not looking 👀 <span>{formatPercentage(users.with_job_search_status_not)}</span></li>
            </ul>
            <h3>candidacies per job type</h3>
            <ul>
                <li>
                    studentjobs
                    <ul>
                        <li>2024 👉 <span>{formatNumber(candidacies.student_job._2024)}</span> ~ YTD <span>{formatNumber(candidacies.student_job.ytd_2024)}</span> ~ users <span className={classes.greyTag}>{formatNumber(candidacies.student_job.ytd_2024_uniq)}</span></li>
                        <li>2023 👉 <span>{formatNumber(candidacies.student_job._2023)}</span> ~ YTD <span>{formatNumber(candidacies.student_job.ytd_2023)}</span> ~ users <span className={classes.greyTag}>{formatNumber(candidacies.student_job.ytd_2023_uniq)}</span></li>
                        <li>2022 👉 <span>{formatNumber(candidacies.student_job._2022)}</span> ~ YTD <span>{formatNumber(candidacies.student_job.ytd_2022)}</span> ~ users <span className={classes.greyTag}>{formatNumber(candidacies.student_job.ytd_2022_uniq)}</span></li>
                        <li>2021 👉 <span>{formatNumber(candidacies.student_job._2021)}</span> ~ YTD <span>{formatNumber(candidacies.student_job.ytd_2021)}</span> ~ users <span className={classes.greyTag}>{formatNumber(candidacies.student_job.ytd_2021_uniq)}</span></li>
                        <li>2020 👉 <span>{formatNumber(candidacies.student_job._2020)}</span> ~ YTD <span>{formatNumber(candidacies.student_job.ytd_2020)}</span> ~ users <span className={classes.greyTag}>{formatNumber(candidacies.student_job.ytd_2020_uniq)}</span></li>
                        <li>2019 👉 <span>{formatNumber(candidacies.student_job._2019)}</span> ~ YTD <span>{formatNumber(candidacies.student_job.ytd_2019)}</span> ~ users <span className={classes.greyTag}>{formatNumber(candidacies.student_job.ytd_2019_uniq)}</span></li>
                    </ul>
                </li>
                <li>
                    internships
                    <ul>
                        <li>2024 👉 <span>{formatNumber(candidacies.internship._2024)}</span> ~ YTD <span>{formatNumber(candidacies.internship.ytd_2024)}</span> ~ users <span className={classes.greyTag}>{formatNumber(candidacies.internship.ytd_2024_uniq)}</span></li>
                        <li>2023 👉 <span>{formatNumber(candidacies.internship._2023)}</span> ~ YTD <span>{formatNumber(candidacies.internship.ytd_2023)}</span> ~ users <span className={classes.greyTag}>{formatNumber(candidacies.internship.ytd_2023_uniq)}</span></li>
                        <li>2022 👉 <span>{formatNumber(candidacies.internship._2022)}</span> ~ YTD <span>{formatNumber(candidacies.internship.ytd_2022)}</span> ~ users <span className={classes.greyTag}>{formatNumber(candidacies.internship.ytd_2022_uniq)}</span></li>
                        <li>2021 👉 <span>{formatNumber(candidacies.internship._2021)}</span> ~ YTD <span>{formatNumber(candidacies.internship.ytd_2021)}</span> ~ users <span className={classes.greyTag}>{formatNumber(candidacies.internship.ytd_2021_uniq)}</span></li>
                        <li>2020 👉 <span>{formatNumber(candidacies.internship._2020)}</span> ~ YTD <span>{formatNumber(candidacies.internship.ytd_2020)}</span> ~ users <span className={classes.greyTag}>{formatNumber(candidacies.internship.ytd_2020_uniq)}</span></li>
                        <li>2019 👉 <span>{formatNumber(candidacies.internship._2019)}</span> ~ YTD <span>{formatNumber(candidacies.internship.ytd_2019)}</span> ~ users <span className={classes.greyTag}>{formatNumber(candidacies.internship.ytd_2019_uniq)}</span></li>
                    </ul>
                </li>
                <li>
                    starter jobs
                    <ul>
                        <li>2024 👉 <span>{formatNumber(candidacies.first_job._2024)}</span> ~ YTD <span>{formatNumber(candidacies.first_job.ytd_2024)}</span> ~ users <span className={classes.greyTag}>{formatNumber(candidacies.first_job.ytd_2024_uniq)}</span></li>
                        <li>2023 👉 <span>{formatNumber(candidacies.first_job._2023)}</span> ~ YTD <span>{formatNumber(candidacies.first_job.ytd_2023)}</span> ~ users <span className={classes.greyTag}>{formatNumber(candidacies.first_job.ytd_2023_uniq)}</span></li>
                        <li>2022 👉 <span>{formatNumber(candidacies.first_job._2022)}</span> ~ YTD <span>{formatNumber(candidacies.first_job.ytd_2022)}</span> ~ users <span className={classes.greyTag}>{formatNumber(candidacies.first_job.ytd_2022_uniq)}</span></li>
                        <li>2021 👉 <span>{formatNumber(candidacies.first_job._2021)}</span> ~ YTD <span>{formatNumber(candidacies.first_job.ytd_2021)}</span> ~ users <span className={classes.greyTag}>{formatNumber(candidacies.first_job.ytd_2021_uniq)}</span></li>
                        <li>2020 👉 <span>{formatNumber(candidacies.first_job._2020)}</span> ~ YTD <span>{formatNumber(candidacies.first_job.ytd_2020)}</span> ~ users <span className={classes.greyTag}>{formatNumber(candidacies.first_job.ytd_2020_uniq)}</span></li>
                        <li>2019 👉 <span>{formatNumber(candidacies.first_job._2019)}</span> ~ YTD <span>{formatNumber(candidacies.first_job.ytd_2019)}</span> ~ users <span className={classes.greyTag}>{formatNumber(candidacies.first_job.ytd_2019_uniq)}</span></li>
                    </ul>
                </li>
            </ul>
            <h3>offer & demand per study domain (last 2 years)</h3>
            <p className={classes.infoSection}>
                offer 👉 % of students that study in that domain<br />
                demand 👉 % of jobs looking for people within a study domain<br />
                remember 💡 1 job can have many study domains<br />
                contains data of the <strong>last 2 years</strong><br />
                excluded jobs that have more than 10 related study domains
            </p>
            <Box className={classes.offerAndDemand}>
                <Box ml={2} mr={10}>
                    <h4>Most on offer (% of students per study domain)</h4>
                    <ol>
                        {sortArray(Object.entries(offer_and_demand.students_per_study_domain)).map((offer) => {
                            const [ domainName, percentage ] = offer
                            const studyDomain = studyDomains.find(studyDomain => studyDomain.value === domainName)
                            return (
                                <li key={percentage}>
                                    {studyDomain.name} 👉 <strong>{formatPercentage(percentage)}</strong>
                                </li>
                            )
                        })}
                    </ol>
                </Box>
                <Box>
                    <h4>Most in demand <strong>first jobs</strong> <span className={classes.greyTag}>internships</span></h4>
                    <ol>
                        {sortArray(Object.entries(offer_and_demand.first_jobs_per_study_domain)).map((offer) => {
                            const [ domainName, percentage ] = offer
                            const studyDomain = studyDomains.find(studyDomain => studyDomain.value === domainName)
                            const internshipsDemandForStudyDomain = offer_and_demand.internships_per_study_domain[domainName]
                            return (
                                <li key={percentage}>
                                    {studyDomain.name} 👉 <strong>{formatPercentage(percentage)}</strong> <span className={classes.greyTag}>{formatPercentage(internshipsDemandForStudyDomain)}</span>
                                </li>
                            )
                        })}
                    </ol>
                </Box>
            </Box>
        </div>
    )
}

export default AdminHealthMetrics
