import React from 'react'

const SettingsSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84.51 86" {...props}>
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path
                    d="M81.78,49.88h0l-.16-.14-.35-.32L77.46,46,79,44.6l2.64-2.42a8.4,8.4,0,0,0,1.66-10.52Q80,26,76.66,20.24A8.38,8.38,0,0,0,66.6,16.41l-5.27,1.72c-.18-.86-.37-1.71-.55-2.57l-.64-3c-.07-.28-.14-.57-.23-.86A8,8,0,0,0,52.23,6h-14a8,8,0,0,0-7.75,6c-.05.19-.09.35-.14.57l0,.14c-.39,1.82-.78,3.63-1.18,5.45l-5.81-1.81A8,8,0,0,0,14.06,20l-7,12.12A8,8,0,0,0,8.55,42l.25.24.13.11L9,42.4l4,3.63-2.2,2-2.28,2.1A8,8,0,0,0,7.07,60l7,12.11a8.13,8.13,0,0,0,9.16,3.68l.38-.11.15,0,2.49-.81,2.93-1,.64,2.9c.19.91.39,1.83.6,2.74l0,.19A8,8,0,0,0,38.26,86h14A8,8,0,0,0,60,79.94c0-.14.08-.28.11-.41a.51.51,0,0,0,0-.13l1.2-5.57,1.48.46,4.34,1.35A8,8,0,0,0,76.45,72l5.73-9.91,1.26-2.18A8,8,0,0,0,81.78,49.88Z" />
                <path
                    d="M75.78,43.88h0a1.07,1.07,0,0,0-.16-.14l-.35-.32L71.46,40,73,38.6l2.64-2.42a8.4,8.4,0,0,0,1.66-10.52Q74,20,70.66,14.24A8.38,8.38,0,0,0,60.6,10.41l-5.27,1.72c-.18-.86-.37-1.71-.55-2.57l-.64-3c-.07-.28-.14-.57-.23-.86A8,8,0,0,0,46.23,0h-14a8,8,0,0,0-7.75,6c-.05.19-.09.35-.14.57l0,.14c-.39,1.82-.79,3.63-1.18,5.45l-5.81-1.81A8,8,0,0,0,8.06,14l-7,12.12A8,8,0,0,0,2.55,36l.25.24.13.11L3,36.4l4,3.63-2.2,2-2.28,2.1A8,8,0,0,0,1.07,54l7,12.11a8.13,8.13,0,0,0,9.16,3.68l.38-.11.15,0,2.49-.81,2.93-1,.64,2.9c.19.91.39,1.83.6,2.74l0,.19A8,8,0,0,0,32.26,80h14A8,8,0,0,0,54,73.94c0-.14.08-.28.11-.41a.51.51,0,0,0,0-.13l1.2-5.57,1.48.46,4.34,1.35A8,8,0,0,0,70.45,66l5.73-9.91,1.26-2.18A8,8,0,0,0,75.78,43.88Z" />
                <path fill='white'
                    d="M32.27,8h14c0,.09.06.18.08.27.66,3.05,1.33,6.1,2,9.15a.61.61,0,0,0,.39.48,21.69,21.69,0,0,1,5,2.94.45.45,0,0,0,.48.09l9-2.92c.32-.1.47-.07.65.24Q67,24,70.34,29.68c.15.27.1.39-.11.58-2.32,2.12-4.63,4.25-7,6.37a.6.6,0,0,0-.22.6,18.64,18.64,0,0,1,0,5.58.58.58,0,0,0,.23.57l7,6.29a3.09,3.09,0,0,0,.25.23l-7,12.1-3.09-1-6.25-1.95a.53.53,0,0,0-.55.09,20,20,0,0,1-5,2.83.53.53,0,0,0-.37.44c-.66,3.1-1.33,6.19-2,9.29,0,.08-.05.17-.07.26h-14c-.21-1-.42-1.91-.62-2.86-.49-2.2-1-4.4-1.46-6.6a.61.61,0,0,0-.3-.38,23.28,23.28,0,0,1-5-2.95.61.61,0,0,0-.63-.11l-9,2.95-.28.08L8,50l2.27-2.08,5-4.59a.51.51,0,0,0,.17-.52,18.51,18.51,0,0,1,0-5.61.54.54,0,0,0-.19-.55l-7-6.28L8,30.12,15,18c.65.2,1.31.39,2,.6l7.36,2.3a.49.49,0,0,0,.52-.06,19.58,19.58,0,0,1,5-2.84.54.54,0,0,0,.38-.44c.65-3.05,1.31-6.11,2-9.16l.1-.41Zm17,32.05A10,10,0,1,0,39.17,50a10,10,0,0,0,10.09-10Z" />
            </g>
        </g>
    </svg>
)

export default SettingsSVG
