import React from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import RouteMap from 'assets/RouteMap'
import DefaultHelmet from './DefaultHelmet'

class HomeStudentMainHelmet extends React.Component {
    constructor (props) {
        super(props)
        this.getCanonicalURL = this.getCanonicalURL.bind(this)
    }

    getCanonicalURL () {
        let pathname = window.location.pathname
        const lastCharacter = pathname.charAt(pathname.length - 1)
        if (lastCharacter !== '/') {
            pathname = pathname + '/'
        }
        return `https://www.student.be${pathname}`
    }

    render () {
        const { formatMessage } = this.props.intl
        return <>
            <DefaultHelmet
                title={formatMessage({ id: 'helmet.home.main.title' })}
                description={formatMessage({ id: 'helmet.home.main.description' })}
                imageCloudinaryKey='assets/hello-future-lets-talk-student-be'
            />
            <Helmet>
                <meta property='og:type' content='website' />
                <meta property="og:url" content={this.getCanonicalURL()} />
                <link rel="canonical" href={this.getCanonicalURL()} />
                <link rel="alternate" hrefLang="fr-BE" href={RouteMap.Home('fr')} />
                <link rel="alternate" hrefLang="en-BE" href={RouteMap.Home('en')} />
                <link rel="alternate" hrefLang="nl-BE" href={RouteMap.Home('nl')} />
                <link rel="alternate" hrefLang="x-default" href='https://www.student.be/' />
            </Helmet>
            <script type="application/ld+json">
                {`{
                  "@context" : "http://schema.org",
                  "@type" : "Organization",
                  "name" : "Student.be",
                  "url" : "http://www.student.be/",
                  "sameAs" : [
                    "http://www.facebook.com/student.be",
                    "http://www.instagram.com/student_be",
                    "http://www.linkedin.com/company/student-be"
                  ]
                }`}
            </script>
        </>
    }
}

export default injectIntl(HomeStudentMainHelmet)
