import React from 'react'

const CalendarSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78.04 86" {...props}>
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path d="M77.75,36A7.76,7.76,0,0,0,78,33.9v-12a8,8,0,0,0-8-8h-4a8,8,0,0,0-8-7.93H50a8,8,0,0,0-8,7.93h0A8,8,0,0,0,34,6H26a8,8,0,0,0-8,8H14a8,8,0,0,0-8,8V33.9a8,8,0,0,0,.27,2A7.94,7.94,0,0,0,6,38.06V78a8,8,0,0,0,8,8H70a8,8,0,0,0,8-8V38.06A8.13,8.13,0,0,0,77.75,36Z" />
                <path d="M71.75,30A7.76,7.76,0,0,0,72,27.9v-12a8,8,0,0,0-8-8h-4a8,8,0,0,0-8-7.93H44a8,8,0,0,0-8,7.93h0A8,8,0,0,0,28,0H20a8,8,0,0,0-8,8H8a8,8,0,0,0-8,8V27.9a8,8,0,0,0,.27,2A7.94,7.94,0,0,0,0,32.06V72a8,8,0,0,0,8,8H64a8,8,0,0,0,8-8V32.06A8.13,8.13,0,0,0,71.75,30Z" />
                <path className="cls-1" fill="white" d="M8,72V32.07H64V72Z" />
                <path className="cls-1" fill="white" d="M20,16V8h8v7.92H44V8h8v7.93H64v12H8V16Z" />
            </g>
        </g>
    </svg>
)

export default CalendarSVG
