import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    container: {},
    dialogTitleRoot: {
        marginBottom: 8
    },
    dialogContentRoot: {
        paddingBottom: 16
    },
    dialogContentTextRoot: {
        marginBottom: 24
    },
    dividerText: {
        fontWeight: 'bold',
        fontSize: 16,
    }
}))
