/* eslint-disable react/prop-types */
import React from 'react'
import InfoLineView from './InfoLineView/InfoLineView'
import { FormattedMessage } from 'react-intl'
import CollectionHandler from 'assets/CollectionHandler.js'

const StudyDomainLine = (props) => {
    const { job } = props
    if (!job.study_domains.length) return null
    if (job.study_domains.length > 4) return null

    const getText = () => {
        let studyDomains = job.study_domains.map((studyDomain) => {
            return CollectionHandler.Translate('studyDomainCollection', studyDomain.name)
        })
        studyDomains = studyDomains.join(', ')

        return <>
            <span><FormattedMessage id='job_results.sort_by.domain'/></span>:{' '}
            <span>{studyDomains}</span>
        </>
    }

    return (
        <InfoLineView
            icon='graduation-cap'
            text={getText()}
        />
    )
}

export default StudyDomainLine
