import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Typography } from '@material-ui/core'
import { useStyles } from './JobSearchStatusBox_style'
import StudentJobSearchStatus from 'components/StudentJobSearchStatus/StudentJobSearchStatus'

const JobSearchStatusBox = (props) => {
    const { user } = props
    const classes = useStyles()

    return (
        <Box>
            <Typography variant='h6' className={classes.title}>
                <FormattedMessage id='personal_dashboard.job_search_status_title' />
            </Typography>
            <StudentJobSearchStatus user={user} simple dashboard context='Dashboard ProfilePassport' />
        </Box>
    )
}
export default JobSearchStatusBox
