import React, { useEffect, useState } from 'react'
import NotificationsView from './NotificationsView'
import NotificationAPI from 'api/NotificationAPI'

const Notifications = (props) => {
    const { user, darkMode, iconColor } = props
    const [notifications, setNotifications] = useState([])
    const [notificationCount, setNotificationCount] = useState(0)
    const [page, setPage] = useState(1)
    const [isLastPage, setIsLastPage] = useState(true)

    useEffect(() => {
        startNotificationStream(user)
    }, [])

    useEffect(() => {
        getNotifications(page)
    }, [page])

    const startNotificationStream = (user) => {
        App.cable.subscriptions.create({
            channel: 'NotificationRoomsChannel',
            notification_room_id: user.notification_room_id
        }, {
            connected: function () {
                console.log('connected')
            },
            disconnected: function () {},
            received: function (newNotification) {
                const currentNotifications = notifications.filter((notif) => {
                    return notif.id !== newNotification.id
                })
                currentNotifications.unshift(newNotification)
                setNotifications(currentNotifications)

                const newNotificationCount = currentNotifications.filter((notif) => {
                    return !notif.seen
                })
                setNotificationCount(newNotificationCount.length)
            }
        })
    }

    const getNotifications = (page) => {
        const data = { page: page }
        NotificationAPI.GetNotifications(data).then(response => {
            setNotifications(notifications.concat(response.data.notifications))
            setNotificationCount(response.data.notification_count)
            setIsLastPage(response.data.is_last_page)
        })
    }

    const seeNotifications = (notificationIds) => {
        NotificationAPI.SeeNotifications({
            notification_ids: notificationIds
        }).then(response => {
            setNotificationCount(0)
        })
    }

    const loadMore = () => {
        setPage(page + 1)
    }

    return (
        <NotificationsView
            notifications={notifications}
            notificationCount={notificationCount}
            seeNotifications={seeNotifications}
            loadMore={loadMore}
            isLastPage={isLastPage}
            darkMode={darkMode}
            iconColor={iconColor}
        />
    )
}

export default Notifications
