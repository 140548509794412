/* eslint-disable react/prop-types */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'

import ArrowButton from 'buttons/ArrowButton/ArrowButton'
import CollectionHandler from 'assets/CollectionHandler'
import PaymentAPI from 'api/PaymentAPI'
import PaymentCard from 'containers/PaymentCard/PaymentCard'
import RouteMap from 'assets/RouteMap'

import FieldTitle from '../../components/FieldTitle/FieldTitle'
import FieldSubtitle from '../../components/FieldSubtitle/FieldSubtitle'
import { withStyles } from '@material-ui/core/styles'
import { useStyles } from './Payment_style'
import AnalyticsHelper from 'assets/AnalyticsHelper'

class Payment extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            selectedPack: null,
            isBuyingCredits: this.props.credits < 1,
            comingFromCredits: false,
            errorList: null
        }
        this.submittable = true
        this.canSubmit = this.canSubmit.bind(this)
        this.startPayment = this.startPayment.bind(this)
        this.frontValidation = this.frontValidation.bind(this)
        this.payWithCredits = this.payWithCredits.bind(this)
        this.getDataParams = this.getDataParams.bind(this)

        this.firstJobPackages = CollectionHandler.Get('firstJobPackagesCollection')
        this.studentJobPackages = CollectionHandler.Get('studentJobPackagesCollection')
        this.internshipPackages = CollectionHandler.Get('internshipPackagesCollection')
        this.creditsValue = CollectionHandler.Get('creditsValue')

        this.hasLicence = (this.props.user.gold_licence || (this.props.user.silver_licence && this.props.jobType !== 'first_job'))
    }

    getDataParams () {
        return {
            selectedPack: this.state.selectedPack ? this.state.selectedPack : false
        }
    }

    canSubmit () {
        return this.submittable
    }

    payWithCredits (selectedPackage) {
        PaymentAPI.payWithCredits({
            job_id: this.props.jobInfos.id,
            job_type: this.props.jobType,
            package_name: selectedPackage.name,
            package_credits_cost: selectedPackage.credits
        })
            .then((response) => {
                window.location.href = RouteMap.Page('users/company_jobs')
            }).catch((error) => {
                this.setState({ errorList: error.response.data })
            })
    }

    startPayment (selectedPackage) {
        PaymentAPI.newPayment({
            // === API CALL DATA ===
            package_name: selectedPackage.name,
            package_credits_cost: selectedPackage.credits,
            job_id: this.props.jobInfos.id,
            job_type: this.props.jobType,

            success_url: window.location.href,
            cancel_url: RouteMap.Edit(this.props.jobType, this.props.jobInfos.id)
        },
        AnalyticsHelper.constructJobFormCheckoutAnalyticsAttributes(
            this.props.user,
            this.props.company,
            this.props.jobType,
            selectedPackage,
            this.state.isBuyingCredits
        ))
        AnalyticsHelper.sendGTMEvent(
            'Stripe Begin Checkout',
            AnalyticsHelper.constructStripeCheckoutAnalyticsAttributes(selectedPackage, this.props.user, this.props.company, 'JobFunnel')
        )
    }

    renderPaymentInfos () {
        let infosContent = null

        if (this.props.user.gold_licence) {
            infosContent = (
                <FieldSubtitle subtitle={<FormattedMessage id="item_index.gold_licence" />} />
            )
        } else if (this.props.user.silver_licence) {
            infosContent = (
                <FieldSubtitle subtitle={<FormattedMessage id="item_index.silver_licence" />} />
            )
        } else {
            infosContent = (
                <FieldSubtitle
                    subtitle={<>
                        <FormattedMessage id={'job_form.payment.info_content.' + this.props.jobType}>
                            {text => text + ' '}
                        </FormattedMessage>
                        <FormattedMessage id="job_form.payment.more_info" />
                        <b><FormattedMessage id="job_form.payment.phone_number" /></b>
                    </>}
                />
            )
        }
        return (
            <Box pb={2}>
                {infosContent}
            </Box>
        )
    }

    frontValidation () {
        return {}
    }

    createPackageTabs () {
        var packageSelected
        switch (this.props.jobType) {
        case 'first_job':
            packageSelected = this.firstJobPackages
            break
        case 'student_job':
            packageSelected = this.studentJobPackages
            break
        case 'internship':
            packageSelected = this.internshipPackages
            break
        }
        return (
            <Grid container spacing={2} style={{ justifyContent: 'center' }}>
                {
                    packageSelected.map((key, index) => {
                        if (this.props.jobType === 'first_job') {
                            return (
                                <Grid item xs={12} lg={12 / packageSelected.length} key={index}>
                                    <PaymentCard
                                        credits={this.props.credits}
                                        selectedPackage={packageSelected[index]}
                                        index={index}
                                        startPayment={this.startPayment}
                                        payWithCredits={this.payWithCredits}
                                        type='payement'
                                    />
                                </Grid>
                            )
                        } else {
                            if (packageSelected[index].price != null) {
                                return (
                                    <Grid item xs={12} lg key={index}>
                                        <PaymentCard
                                            credits={this.props.credits}
                                            selectedPackage={packageSelected[index]}
                                            index={index}
                                            startPayment={this.startPayment}
                                            payWithCredits={this.payWithCredits}
                                            type='offer'
                                            selectedPack={this.state.selectedPack}
                                        />
                                    </Grid>
                                )
                            }
                        }
                    })
                }
            </Grid>
        )
    }

    renderPayWithCredit () {
        const { classes } = this.props
        if (this.props.jobType === 'first_job') {
            return (
                <Grid container spacing={2}>
                    {this.firstJobPackages.map((key, index) => {
                        return (
                            <Grid item xs={12} lg={12 / this.firstJobPackages.length} key={index}>
                                <PaymentCard
                                    credits={this.props.credits}
                                    selectedPackage={this.firstJobPackages[index]}
                                    index={index}
                                    startPayment={this.startPayment}
                                    payWithCredits={this.payWithCredits}
                                    type='payement'
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            )
        } else {
            return (
                <Box className={classes.creditsContainer}>
                    <Card className={classes.creditsCard}>
                        <Box className={classes.cardHeader}>
                            <Typography variant='h6' component='h2'>
                                <FormattedMessage id="job_form.payment.price_to_pay" />
                            </Typography>
                            <Typography variant='h6' component='h2'>
                                <FormattedMessage id="job_form.payment.one_credit" />
                            </Typography>
                        </Box>
                        <Box className={classes.cardCreditsCost} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <Box className={classes.cardCreditsInDetail}>
                                <Typography variant='h4' className={classes.whiteColor}>
                                    <FormattedMessage id="job_form.payment.current_credit" />
                                </Typography>
                                <Typography variant='h4' className={classes.whiteColor}>
                                    {this.props.credits}
                                    <FormattedMessage id="job_form.payment.credit" />
                                </Typography>
                            </Box>
                            <Box className={classes.cardCreditsInDetail}>
                                <Typography variant='h4' className={classes.whiteColor}>
                                    <FormattedMessage id="job_form.payment.current_credit_after" />
                                </Typography>
                                <Typography variant='h4' className={classes.whiteColor}>
                                    {this.props.credits - 1}
                                    <FormattedMessage id="job_form.payment.credit" />
                                </Typography>
                            </Box>
                        </Box>
                    </Card>
                    <ArrowButton
                        name={<FormattedMessage id="job_form.payment.buy_more_credit" />}
                        direction='right'
                        onClick={() => {
                            this.setState({ isBuyingCredits: true, comingFromCredits: true })
                            this.props.updateIsBuyingCredits(true)
                        }}
                    />
                </Box>
            )
        }
    }

    render () {
        const { classes } = this.props
        return (
            <Container maxWidth='md' className={classes.paymentFormContainer}>
                <Box className={classes.paymentTitleContainer}>
                    <FieldTitle title={<FormattedMessage id="job_form.payment.title" />} />
                    {!this.hasLicence &&
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <Divider orientation='vertical' style={{ margin: '0 10px', width: 30, height: 2, backgroundColor: 'var(--background-purple)' }}/>
                            <Typography variant='h6'>
                                {<FormattedMessage id='jobcreationfunnel.titles.payment_credits_left' values={{ credits: this.props.credits }} />}
                            </Typography>
                        </Box>
                    }
                </Box>
                <Box>
                    {this.props.type === 'first_job' &&
                        <Typography variant='h6' component='h2' className={classes.remainingCredit}>
                            {this.props.credits}
                            <FormattedMessage id="job_form.payment.remaining_credit" />
                            {this.props.credits * this.creditsValue + '€)'}
                        </Typography>}

                    {this.renderPaymentInfos()}

                    <Box my={4}>
                        {(this.state.comingFromCredits) &&
                            <ArrowButton
                                name={<FormattedMessage id="job_form.payment.balance" />}
                                direction='left'
                                onClick={() => {
                                    this.setState({ isBuyingCredits: false, comingFromCredits: false })
                                    this.props.updateIsBuyingCredits(false)
                                }}
                            />
                        }
                        {!this.hasLicence && (
                            !this.state.isBuyingCredits
                                ? this.renderPayWithCredit()
                                : this.createPackageTabs()
                        )}
                        {this.state.errorList &&
                            <Typography color='error'>
                                {this.state.errorList.message}
                            </Typography>
                        }
                    </Box>
                </Box>
            </Container>
        )
    }
}

export default withStyles(useStyles)(Payment)
