import React from 'react'

const LawSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86 86" {...props}>
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path d="M83.66,36.34l-7-7A8,8,0,0,0,71,27h0a8.3,8.3,0,0,0-2.17.31l-4.2-4.2a7.75,7.75,0,0,0,.3-2.15,8,8,0,0,0-2.34-5.66L55.65,8.35A8,8,0,0,0,50,6h0a8,8,0,0,0-5.66,2.34l-17.93,18a8,8,0,0,0,0,11.3l3.94,3.95a8.14,8.14,0,0,0-3,1.9l-19,19a8,8,0,0,0,0,11.31l9.91,9.92a8,8,0,0,0,11.31,0l19-19a8.07,8.07,0,0,0,1.88-3l3.94,3.94a8,8,0,0,0,5.66,2.35h0a8,8,0,0,0,5.66-2.35L83.66,47.64A8,8,0,0,0,83.66,36.34Z" />
                <path d="M77.66,30.34l-7-7A8,8,0,0,0,65,21h0a8.3,8.3,0,0,0-2.17.31l-4.2-4.2a7.75,7.75,0,0,0,.3-2.15,8,8,0,0,0-2.34-5.66L49.65,2.35A8,8,0,0,0,44,0h0a8,8,0,0,0-5.66,2.34l-17.93,18a8,8,0,0,0,0,11.3l3.94,3.95a8.14,8.14,0,0,0-3,1.9l-19,19a8,8,0,0,0,0,11.31l9.91,9.92a8,8,0,0,0,11.31,0l19-19a8.07,8.07,0,0,0,1.88-3l3.94,3.94a8,8,0,0,0,5.66,2.35h0a8,8,0,0,0,5.66-2.35L77.66,41.64A8,8,0,0,0,77.66,30.34Z" />
                <path className="cls-1" fill="white" d="M26.06,25.94l7,7L35.9,30,50,44.12,47.13,47l6.94,6.94L72,36l-7-7-2.9,2.91L48,17.81l2.89-2.88L44,8ZM37.33,37.05,29.07,45.2,27,43.1l-19,19L17.9,72l19-19-2.06-2.05L43,42.65l-5.66-5.6Z" />
            </g>
        </g>
    </svg>
)

export default LawSVG
