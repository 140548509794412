import React, { useState, useEffect } from 'react'
import NewsAndTipsView from 'components/NewsAndTipsView/NewsAndTipsView.jsx'
import ArticleAPI from 'api/ArticleAPI'
import RouteMap from 'assets/RouteMap.js'

const NewsAndTips = (props) => {
    const { item, propArticles, subTitleId } = props
    const [articles, setArticles] = useState(propArticles || [])

    useEffect(() => {
        getSimilarArticles()
    }, [])

    const getSimilarArticles = () => {
        const data = {
            category: item.type,
            count: 3
        }
        ArticleAPI.GetRecentArticles(data).then((response) => {
            setArticles(response.data?.slice(0, 4))
        })
    }

    if (articles.length === 0) return null

    return (
        <NewsAndTipsView
            articles={articles}
            moreItemsURL={RouteMap.ArticleCategory(item.type)}
            titleId='job_show.news_and_tips.title'
            subTitleId={subTitleId || 'job_show.news_and_tips.description'}
        />
    )
}

export default NewsAndTips
