import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './FiltersModals_style'
import { useMediaQuery, DialogTitle, Typography, DialogContent, Box, DialogActions, Dialog, IconButton, useTheme } from '@material-ui/core'

import IconFactory from 'icons/IconFactory/IconFactory'
import ResetFilters from '../../components/Searchbar/shared/ResetFilters/ResetFilters'
import Button from 'shared/components/Button/Button.jsx'

const FiltersModalWrapper = ({ children, ...props }) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const classes = useStyles()
    const { titleId, showModal, closeModal, offerCount, reset, type } = props

    return (
        <Dialog
            PaperProps={{ className: classes.dialogContainer }}
            fullScreen={fullScreen}
            maxWidth='md'
            open={showModal}
            onClose={closeModal}
            aria-labelledby='responsive-dialog-delete'
            fullWidth
        >
            <DialogTitle onClose={closeModal} disableTypography className={classes.dialogTitleContainer}>
                <Typography variant='h6' className={classes.dialogTitle}>
                    <FormattedMessage id={titleId} />
                </Typography>
                <IconButton
                    aria-label='close'
                    onClick={closeModal}
                    className={classes.closeIcon}
                    disableRipple
                >
                    <IconFactory icon='close' color={theme.palette.fg['black']} fontSize='14px' />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                {children}
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <Box className={classes.buttonContainer}>
                    <ResetFilters reset={reset} />

                    <Button
                        className={classes.showButton}
                        onClick={closeModal}
                    >
                        <FormattedMessage id={`filters_modal.show_jobs.${type}`} values={{ count: offerCount }} />
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

const SectionTitle = (props) => {
    const { id } = props
    const classes = useStyles()

    return (
        <Typography className={classes.sectionTitle}>
            <FormattedMessage id={id} />
        </Typography>
    )
}

const SectionDescription = (props) => {
    const { id } = props
    const classes = useStyles()

    return (
        <Typography className={classes.sectionDescription}>
            <FormattedMessage id={id} />
        </Typography>
    )
}

export { FiltersModalWrapper, SectionTitle, SectionDescription }
