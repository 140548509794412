import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative'
    },
    dropdown: {
        top: '30px !important',
        right: '30px !important',
        left: 'unset !important',
        border: '2px var(--grey) solid',
        borderRadius: '4px',
        background: 'white'
    },
    htmlContentSelector: {
        padding: '12px'
    }
}))
