import React from 'react'

const ThumbsUpSVG = (props) => (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86 78.06">
        <g id="Calque_2">
            <g id="Calque_1-2">
                <path d="M78,24h-14.39c.17-1.25.29-2.52.33-3.78.05-1.5.05-3,.06-4.37v-1.68c0-.67-.08-1.35-.25-2-.85-3.6-4.05-6.15-7.75-6.17h-12c-2.93.02-5.62,1.64-7,4.23-.66,1.18-1,2.51-1,3.86v4.03c.03,1.25-.16,2.49-.56,3.67-.42,1.21-1.08,2.32-1.94,3.26-.42.44-.88.87-1.36,1.3-.71-.22-1.45-.34-2.2-.35h-15.94c-4.42,0-8,3.58-8,8v36c0,4.42,3.58,8,8,8h15.94c3.25-.01,6.17-1.99,7.39-5l5.17,3.42c1.52.99,3.29,1.53,5.1,1.54h22.76c4.26-.19,7.62-3.69,7.64-7.96v-.75c1.9-1.52,3-3.82,3-6.25v-2.74c1.9-1.52,3-3.83,3-6.26v-4.76c1.28-1.45,1.99-3.31,2-5.24v-12c0-4.42-3.58-8-8-8Z"/>
                <path d="M72,18h-14.4c.18-1.25.29-2.51.34-3.78.05-1.5.05-3,.06-4.37v-1.68c0-.67-.08-1.35-.25-2-.85-3.6-4.05-6.15-7.75-6.17h-12c-2.93.02-5.62,1.64-7,4.23-.66,1.18-1,2.51-1,3.86v4.03c.03,1.25-.16,2.49-.56,3.67-.42,1.21-1.08,2.32-1.95,3.26-.41.44-.87.87-1.35,1.3-.71-.22-1.45-.34-2.2-.35H8C3.58,20,0,23.58,0,28v36C0,68.42,3.58,72,8,72h15.94c3.25-.01,6.17-1.99,7.39-5l5.17,3.42c1.52.99,3.29,1.53,5.1,1.54h22.76c4.26-.19,7.62-3.69,7.64-7.96v-.75c1.9-1.52,3-3.82,3-6.25v-2.74c1.9-1.52,3-3.83,3-6.26v-4.76c1.28-1.45,1.99-3.31,2-5.24v-12c0-4.42-3.58-8-8-8Z"/>
                <path d="M38,8h12v.17c0,1.93,0,3.87-.06,5.8-.11,3.37-.84,6.68-2.16,9.78-.26.62-.55,1.22-.83,1.83-.06.13-.12.26-.2.45h25.25v11.97h-2v10h-3v9h-3v7h-22.41c-.25,0-.5-.09-.71-.23-3.61-2.37-7.22-4.76-10.82-7.14-.61-.41-1.23-.8-1.83-1.23-.13-.1-.21-.24-.23-.4v-25.63c.01-.17.1-.33.23-.44,1.81-1.33,3.51-2.81,5.08-4.42,1.61-1.73,2.84-3.77,3.63-6,.72-2.08,1.06-4.28,1-6.48v-4.03s.06,0,.06,0Z" fill="#fff"/>
                <path d="M8,28h15.94v36H8s0-36,0-36Z" fill="#fff"/>
            </g>
        </g>
    </svg>
)

export default ThumbsUpSVG
