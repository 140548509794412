import { createContext, useState } from 'react'
import DashboardAPI from 'api/DashboardAPI'
import ExperienceHelper from 'assets/ExperienceHelper'

export const UserContext = createContext(null)

export const useUserContext = (userProps) => {
    if (!userProps) return null
    const originalUserProps = userProps
    const [user, setUser] = useState(userProps)
    const [userLoaded, setUserLoaded] = useState(false)
    const [statusConfig, setStatusConfig] = useState({
        actively_looking_student_job: userProps.actively_looking_student_job,
        actively_looking_internship: userProps.actively_looking_internship,
        actively_looking_first_job: userProps.actively_looking_first_job,
        actively_looking_kot: userProps.actively_looking_kot,
        not_looking_any_job: userProps.not_looking_any_job
    })
    const [statusConfigSaved, setStatusConfigSaved] = useState(false)

    const userHasNotSelectedAJobType = () => {
        return !(statusConfig.actively_looking_student_job || statusConfig.actively_looking_internship || statusConfig.actively_looking_first_job || statusConfig.actively_looking_kot)
    }

    const getUserInfo = () => {
        DashboardAPI.GetUserInfo()
            .then((response) => {
                const initializedExperiences = ExperienceHelper.InitializeExperiences([response.data.user.current_experience])
                setUser({
                    ...response.data.user,
                    experiences: initializedExperiences,
                    current_experience: response.data.user.current_experience ? initializedExperiences[0] : null
                })
                setStatusConfig({
                    actively_looking_student_job: response.data.user.actively_looking_student_job,
                    actively_looking_internship: response.data.user.actively_looking_internship,
                    actively_looking_first_job: response.data.user.actively_looking_first_job,
                    actively_looking_kot: response.data.user.actively_looking_kot,
                    not_looking_any_job: response.data.user.not_looking_any_job
                })
                setUserLoaded(true)
            })
    }

    return {
        user,
        setUser,
        statusConfig,
        setStatusConfig,
        userHasNotSelectedAJobType,
        statusConfigSaved,
        setStatusConfigSaved,
        originalUserProps,
        getUserInfo,
        userLoaded
    }
}
