/* eslint-disable react/prop-types */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './JobPassport_style'
import { Box, Hidden, Typography } from '@material-ui/core'

import ApplyButton from '../ApplyButton/ApplyButton'
import ContractTypeLine from './components/ContractTypeLine'
import DeadlineLine from './components/DeadlineLine'
import DurationLine from './components/DurationLine'
import FunctionTitleLine from './components/FunctionTitleLine'
import LanguageLine from './components/LanguageLine/LanguageLine'
import LocationLine from './components/LocationLine'
import MobilityLines from './components/MobilityLines'
import OpenPositionsLine from './components/OpenPositionsLine'
import PaidLine from './components/PaidLine'
import RegimeLine from './components/RegimeLine'
import ScheduleLine from './components/ScheduleLine'
import StartDateLine from './components/StartDateLine'
import StudyDomainLine from './components/StudyDomainLine'

const JobPassport = (props) => {
    const { job, user } = props
    const classes = useStyles()

    const renderStudentJobInfo = () => {
        return <Box className={classes.jobInfoContainer}>
            <LocationLine job={job} />
            <ScheduleLine job={job} />
            <StartDateLine job={job} />
            <MobilityLines job={job} />
            <LanguageLine job={job} />
            <StudyDomainLine job={job} />
        </Box>
    }

    const renderInternshipInfo = () => {
        return <Box className={classes.jobInfoContainer}>
            <LocationLine job={job} />
            <DurationLine job={job} />
            <RegimeLine job={job} />
            <PaidLine job={job} />
            <StudyDomainLine job={job} />
        </Box>
    }

    const renderFirstJobInfo = () => {
        return <Box className={classes.jobInfoContainer}>
            <LocationLine job={job} />
            <RegimeLine job={job} />
            <ContractTypeLine job={job} />
            <FunctionTitleLine job={job} />
            <StudyDomainLine job={job} />
            <OpenPositionsLine job={job} />
            <DeadlineLine job={job} />
        </Box>
    }

    const renderJobInfo = () => {
        if (job.type === 'student_job') return renderStudentJobInfo()
        else if (job.type === 'internship') return renderInternshipInfo()
        else if (job.type === 'first_job') return renderFirstJobInfo()
    }

    return (
        <Box className={classes.cardContainer}>
            <Typography variant='h1' component='h2' className={classes.title}>
                <FormattedMessage id='job_show.job_info_card.title' />
            </Typography>
            {renderJobInfo()}
            <Hidden smDown>
                <Box className={classes.applyButtonBox}>
                    <ApplyButton
                        containerClass={classes.applyButtonContainer}
                        buttonsClass={classes.applyButton}
                        job={job}
                        user={user}
                        buttonSize='small'
                    />
                </Box>
            </Hidden>
        </Box>
    )
}

export default JobPassport
