import React from 'react'

import css from './navigationcard.scss'
import css_responsive from './navigationcard_responsive.scss'

export default class NavigationCard extends React.Component {
    render () {
        return (
            <a
                className="navigation-card-container"
                href={this.props.url}
            >
                <div className="navigation-card-header">
                    <div className="navigation-card-title">{this.props.title}</div>
                    <div className="navigation-card-content">{this.props.content}</div>
                </div>
                <div className="navigation-bottom dynamic-lightBlue-back"/>
            </a>
        )
    }
}
