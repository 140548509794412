/* eslint-disable react/prop-types */
import React from 'react'
import { useStyles } from './ItemListTitleWrapper_style'
import { FormattedMessage } from 'react-intl'
import { Box, Typography, Hidden } from '@material-ui/core'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import IconFactory from 'icons/IconFactory/IconFactory'
import ItemIcon from 'components/ItemIcon/ItemIcon'

const ItemListTitleWrapper = (props) => {
    const { children, hidden, moreItemsURL, titleId, type, textValues, seeMoreMobileClassName, titleBoxClassName } = props
    if (hidden) return children
    const classes = useStyles()

    return (
        <Box className={titleBoxClassName}>
            <Box className={classes.titleBox}>
                <Box className={classes.iconTitleBox}>
                    <ItemIcon type={type} width={14} height={14} />
                    <Typography variant='h1' component='h2' className={classes.title}>
                        <FormattedMessage id={titleId} values={textValues} />
                    </Typography>
                </Box>
                {moreItemsURL &&
                <Hidden smDown>
                    <RouteMapLink redirectionUrl={moreItemsURL} className={classes.seeMoreBox}>
                        <Box className={classes.seeMore}>
                            <Typography className={classes.arrowText} variant='h1' component='span'>
                                <FormattedMessage id={`personal_dashboard.recommend_jobs.see_more.${type}`}/>
                            </Typography>
                            <Box className={classes.arrowBubble} style={{ backgroundColor: 'black' }}>
                                <IconFactory color='white' icon='arrow-right' fontSize='12px'/>
                            </Box>
                        </Box>
                    </RouteMapLink>
                </Hidden>
                }
            </Box>
            {children}
            <Hidden mdUp><>
                {moreItemsURL &&
                <RouteMapLink redirectionUrl={moreItemsURL} className={classes.seeMoreBox}>
                    <Box className={`${seeMoreMobileClassName} ${classes.seeMoreMobile}`}>
                        <Typography className={classes.arrowText} variant='h1' component='span'>
                            <FormattedMessage id={`personal_dashboard.recommend_jobs.see_more.${type}`}/>
                        </Typography>
                        <Box className={classes.arrowBubble} style={{ backgroundColor: 'black' }}>
                            <IconFactory color='white' icon='arrow-right' fontSize='12px'/>
                        </Box>
                    </Box>
                </RouteMapLink>
                }
            </></Hidden>
        </Box>
    )
}

export default ItemListTitleWrapper
