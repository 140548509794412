import React from 'react'
import { useStyles } from './RecommendedJobsView_style'
import { Box, Typography } from '@material-ui/core'
import Button from 'shared/components/Button/Button.jsx'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import KotCard from 'cards/KotCard/KotCard'
import JobCardV2 from 'cards/JobCard/JobCardV2'

const RecommendedJobsView = (props) => {
    const { user, moreItemsURL, title, moreItemsText, items } = props
    const classes = useStyles()
    if (items.length === 0) return null

    return (
        <Box className={classes.RecommendedJobsContainer} disableGutters>
            <Box className={items[0].type === 'kot' ? classes.innerBoxKot : classes.innerBox}>
                <Typography
                    className={classes.title}
                    component='h2'
                    variant='h1'
                >
                    {title}
                </Typography>
                <Box className={classes.cardsContainer}>
                    {items[0].type === 'kot' &&
                        <Box className={classes.recommendedItemsRowBox}>
                            {items.map(kot => <KotCard key={kot.id} kot={kot} userType={user?.type} />)}
                        </Box>
                    }
                    {items[0].type !== 'kot' &&
                        items.map(job => <JobCardV2 key={job.id} job={job} />)
                    }
                </Box>
                <Box className={classes.buttonContainer}>
                    <RouteMapLink redirectionUrl={moreItemsURL}>
                        <Button variant='cta-primary'>
                            {moreItemsText}
                        </Button>
                    </RouteMapLink>
                </Box>
            </Box>
        </Box>
    )
}

export default RecommendedJobsView
