import React from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './NoJobShow_style'
import { Box, Container, Grid, Paper, Typography } from '@material-ui/core'

import SimilarArticles from '../SimilarArticles/SimilarArticles'
import SimilarJobs from '../SimilarJobs/SimilarJobs'
import URLHandler from 'assets/URLHandler.js'
import RecommendedJobs from 'shared/components/RecommendedJobs/RecommendedJobs'


const NoJobShow = (props) => {
    const { user } = props
    const jobType = URLHandler.getJobTypeFromUrl()
    const classes = useStyles()

    return (<>
        <Helmet>
            <meta name='robots' content='noindex' />
            <meta name='googlebot' content='noindex' />
        </Helmet>
        <Box className={classes.mainContainer}>
            <Container maxWidth='lg'>
                <Typography variant='h1' className={classes.bannerText}>
                    <FormattedMessage id='transl_jobs.does_not_exist_banner' />
                </Typography>
            </Container>
        </Box>
        <Box className={classes.similarJobsBox}>
            <SimilarJobs user={user} job={{ type: jobType }} />
            {/* <RecommendedJobs job={job} /> */}
        </Box>
        <Box className={classes.similarArticlesBox}>
            <SimilarArticles user={user} category={jobType} baseItem={{ type: jobType }}/>
        </Box>
    </>)
}

export default NoJobShow
