import React from 'react'
import { Helmet } from 'react-helmet'

import LoginRegisterPage from './LoginRegisterPage'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'

const LoginRegister = (props) => {
    return (
        <PageWrapper user={props.user}>
            <Helmet>
                <meta name='robots' content='noindex' />
                <meta name='googlebot' content='noindex' />
            </Helmet>
            <LoginRegisterPage {...props} />
        </PageWrapper>
    )
}

export default LoginRegister
