import axios from 'axios'
import ReactOnRails from 'react-on-rails'
import Bugsnag from '@bugsnag/js'

import RouteMap from '../assets/RouteMap.js'
import AnalyticsHelper from 'assets/AnalyticsHelper'

import { NEW_PAYMENT_API, PAY_WITH_CREDITS, ACTIVATE_WITH_CREDITS, BOOST_JOB } from './env'

const newPayment = (data, analyticsAttributes = null) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    axios({
        method: 'post',
        url: NEW_PAYMENT_API,
        params: { locale: RouteMap.GetLocaleFromUrl(), ...data }
    }).then((response) => {
        // test public key
        // var stripe = Stripe('pk_test_AqKRnvIaEMGE5NNszWi6YDeY')

        // live public key
        var stripe = Stripe('pk_live_ihDHXaepQBKt4mWSSbLA5O39')

        AnalyticsHelper.sendGTMEvent('begin_checkout', analyticsAttributes)
        stripe.redirectToCheckout({
            ...response.data.params,
            ...{ sessionId: response.data.checkout_session_id }
        }).then((result) => {
            console.log(result)
        }).catch((error) => {
            console.log('===== STRIP ERROR =====')
            console.log(error)
            Bugsnag.notify(error)
        })
    })
}

const payWithCredits = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        method: 'post',
        url: PAY_WITH_CREDITS,
        params: data
    })
}

const activateWithCredits = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        method: 'post',
        url: ACTIVATE_WITH_CREDITS,
        params: data
    })
}

const boostJob = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        method: 'post',
        url: BOOST_JOB,
        params: data
    })
}

export default { newPayment, payWithCredits, activateWithCredits, boostJob }
