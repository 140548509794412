import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    minHalfWidth: {
        minWidth: '50%',
        '& .MuiFormControl-root': {
            width: '100%'
        }
    },
    fullWidth: {
        width: '100%'
    },
    htmlArea: {
        minHeight: '200px'
    }
}))
