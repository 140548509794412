import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './JobAlertModal_style'
import { Dialog, DialogContent, DialogTitle, Typography, Box, Chip, IconButton, Hidden, useTheme } from '@material-ui/core'

import Button from 'shared/components/Button/Button.jsx'
import IconFactory from 'icons/IconFactory/IconFactory.jsx'
import SVGFactory from 'icons/SVGFactory/SVGFactory'

const JobAlertModal = (props) => {
    const { filters, jobType, actionPopup, open, popupExit } = props
    const classes = useStyles()
    const theme = useTheme()

    const renderFilterTags = () => {
        if (!filters) return <></>

        let numberOfFilters
        let filterTags
        switch (jobType) {
        case 'first_job':
            numberOfFilters =
                    filters.studyDomain.length +
                    filters.location.length +
                    filters.companySize.length +
                    filters.sector.length +
                    (filters.quickFilter === '' ? 0 : 1)

            filterTags = (<>
                {renderQuickFilterTag(filters.quickFilter)}
                {renderTag(filters.studyDomain)}
                {renderTag(filters.location)}
                {renderTag(filters.companySize)}
                {renderTag(filters.sector)}
            </>)
            break
        case 'internship':
            numberOfFilters =
                    filters.studyDomain.length +
                    filters.location.length +
                    filters.companySize.length +
                    (filters.quickFilter === '' ? 0 : 1)

            filterTags = (<>
                {renderQuickFilterTag(filters.quickFilter)}
                {renderTag(filters.studyDomain)}
                {renderTag(filters.location)}
                {renderTag(filters.companySize)}
            </>)
            break
        case 'student_job':
            numberOfFilters =
                    filters.studyDomain.length +
                    filters.location.length +
                    filters.jobSchedule.length +
                    (filters.quickFilter === '' ? 0 : 1)

            filterTags = (<>
                {renderQuickFilterTag(filters.quickFilter)}
                {renderTag(filters.studyDomain)}
                {renderTag(filters.jobSchedule)}
                {renderTag(filters.location)}
            </>)
            break
        }
        if (numberOfFilters === 0) {
            filterTags = <Chip
                key={jobType}
                label={<FormattedMessage id={`filter_tags.default.${jobType}`} />}
                className={classes.tagFilter}
            />
        }

        return (
            <Box className={classes.boxTags}>
                {filterTags}
            </Box>
        )
    }

    const renderTag = (tagsList) => {
        return (tagsList.map((tag) => {
            return <Chip
                key={tag.value}
                label={tag.name}
                className={classes.tagFilter}
            />
        }))
    }

    const renderQuickFilterTag = (quickFilter) => {
        if (quickFilter === '') return null

        return <Chip
            key={quickFilter}
            label={<FormattedMessage id={`quick_filters.${quickFilter}`} />}
            className={classes.tagFilter}
        />
    }

    return (
        <Dialog
            open={open}
            onClose={popupExit}
            disableScrollLock={true}
            fullWidth
            PaperProps={{ className: classes.dialogContainer }}
        >
            <IconButton aria-label="close" onClick={popupExit} className={classes.closeIconButton} disableRipple>
                <IconFactory icon='close' color={theme.palette.fg.black} fontSize='14px' />
            </IconButton>

            <DialogTitle className={classes.titleContainer} disableTypography onClose={popupExit}>
                <Box className={classes.envelopeIconContainer}>
                    <SVGFactory name='envelope' width='58px' height='35px' />
                </Box>
                <FormattedMessage id='create_alert_popup.title_v2' >
                    {text =>
                        <Typography 
                            variant='h2'
                            className={classes.title} 
                            dangerouslySetInnerHTML={{ __html: text }}
                        />
                    }
                </FormattedMessage>
            </DialogTitle>

            <DialogContent className={classes.modalContent}>
                <Typography className={classes.description}>
                    <FormattedMessage id={`create_alert_popup.${jobType}.body`} />
                </Typography>
                {renderFilterTags()}
                <Box className={classes.submitButtonContainer}>
                    <Button onClick={actionPopup} className={classes.submitButton}>
                        <FormattedMessage id={'item_offers.create_my_job_alert'} />
                    </Button>
                </Box>
                <Hidden smDown>
                    <Typography
                        variant='body2'
                        className={classes.footerText}
                    >
                        <FormattedMessage id={'create_alert_popup.warning'} />
                    </Typography>
                </Hidden>
            </DialogContent>
        </Dialog>
    )
}

export default JobAlertModal
