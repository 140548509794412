import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    paperStyle: {
        margin: 0,
        boxShadow: 'none',
        backgroundColor: 'transparent'
    },
    dialogContainer: {
        padding: 0
    }
}))
