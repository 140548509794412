import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    titleBox: {
        marginBottom: 16,
        marginTop: 16
    },
    title: {
        margin: '20px 0'
    },
    recommendationsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
    },
    userActions: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 12,
        [theme.breakpoints.up('sm')]: {
            marginBottom: 12
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            gap: 16,
            alignItems: 'center'
        }
    }
}))
