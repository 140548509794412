import React from 'react'
import { Typography } from '@material-ui/core'

import { useStyles } from './FieldSubtitle_style'

const FieldSubtitle = (props) => {
    const classes = useStyles()
    const { subtitle, className, dangerouslySetInnerHTML } = props

    return (
        <Typography
            variant='body1'
            component='h6'
            className={`${classes.subtitle} ${className ? className : ''}`}
            dangerouslySetInnerHTML={dangerouslySetInnerHTML}
        >
            {subtitle}
        </Typography>
    )
}

export default FieldSubtitle
