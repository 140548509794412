/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import ArticleAPI from 'api/ArticleAPI'
import NewsAndTipsView from 'components/NewsAndTipsView/NewsAndTipsView'
import RouteMap from 'assets/RouteMap.js'

const ArticleSimilarArticles = (props) => {
    const { userType, article } = props
    const [similarArticles, setSimilarArticles] = useState([])

    useEffect(() => {
        getSimilarArticles()
    }, [])

    const getSimilarArticles = () => {
        const data = {
            id: article.id
        }
        ArticleAPI.GetSimilarArticles(data).then((response) => {
            setSimilarArticles(response.data)
        })
    }

    if (similarArticles.length === 0) return null

    return (
        <NewsAndTipsView
            articles={similarArticles}
            moreItemsURL={RouteMap.ArticleCategory(article.category)}
            titleId='landing_article_show.interest_article'
            subTitleId='landing_article_show.see_all_articles'
            cards={similarArticles}
            userType={userType}
            type="article"
            fullWidth
        />
    )
}

export default ArticleSimilarArticles
