import React from 'react'

const DesktopSVG = (props) => (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600">
        <g id="Calque_2">
            <g id="Calque_1-2">
                <path d="M544.71,81.22H102.41c-30.53,0-55.29,24.75-55.29,55.29v315.21c0,30.53,24.75,55.29,55.29,55.29h43.06v17.28c0,30.53,24.75,55.29,55.29,55.29h245.55c30.53,0,55.29-24.75,55.29-55.29v-17.42h43.12c30.53,0,55.29-24.75,55.29-55.29V136.51c0-30.53-24.75-55.29-55.29-55.29Z"/>
                <path d="M503.25,39.76H60.94c-30.53,0-55.29,24.75-55.29,55.29v315.21c0,30.53,24.75,55.29,55.29,55.29h43.06v17.28c0,30.53,24.75,55.29,55.29,55.29h245.55c30.53,0,55.29-24.75,55.29-55.29v-17.42h43.12c30.53,0,55.29-24.75,55.29-55.29V95.04c0-30.53-24.75-55.29-55.29-55.29Z"/>
                <path d="M159.28,434.51h75.26c1.41.25,2.76-.67,3.04-2.07,2.14-6.57,4.42-12.99,6.91-19.49l.9-2.76H60.94V95.04h442.31v315.07h-184.32c0,.83.41,1.45.62,2.07,2.42,6.91,4.84,13.82,6.91,20.73.24,1.21,1.41,2.01,2.63,1.8h75.74v48.38h-245.55v-48.58ZM454.25,143.42H109.94v169.67h344.31v-169.67Z" fill="#fff"/>
            </g>
        </g>
    </svg>

)

export default DesktopSVG
