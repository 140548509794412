import React from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import RouteMap from 'assets/RouteMap'
import DefaultHelmet from './DefaultHelmet'

class PromotionShowHelmet extends React.Component {
    render () {
        if (this.props.promotion.is_samsung) {
            return (
                <Helmet>
                    <meta name="robots" content="noindex"/>
                    <meta name="googlebot" content="noindex"/>
                </Helmet>
            )
        } else {
            return <>
                <DefaultHelmet
                    title={this.props.promotion.title}
                    description={this.props.promotion.meta_description}
                    imageCloudinaryKey='assets/hello-future-lets-talk-student-be'
                />
                <Helmet>
                    <meta property='og:type' content='website' />
                    <meta property="og:url" content={`https://www.student.be${window.location.pathname}`} />
                    <meta property='og:updated_time' content={this.props.promotion.updated_at} /> {/* When the promotion was last changed. */}
                    <link rel="canonical" href={`https://www.student.be${window.location.pathname}`} />
                    <link rel="alternate" hrefLang="fr-BE" href={RouteMap.Page('student-job', 'fr')} />
                    <link rel="alternate" hrefLang="en-BE" href={RouteMap.Page('student-job', 'en')} />
                    <link rel="alternate" hrefLang="nl-BE" href={RouteMap.Page('student-job', 'nl')} />
                    <link rel="alternate" hrefLang="x-default" href={RouteMap.Page('student-job', 'en')} />
                </Helmet>
            </>
        }
    }
}

export default injectIntl(PromotionShowHelmet)
