import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    itemOffersMainContainer: {
        paddingBottom: '21px',
        [theme.breakpoints.down('md')]: {
            paddingBottom: '27px',
        },
        display: 'flex',
        alignItems: 'center'
    },
    itemOffersTextBox: {
        display: 'flex',
        flexDirection: 'column'
    },
    pageSubTitle: {
        fontSize: '14px',
        lineHeight: '28px',
        [theme.breakpoints.up('md')]: {
            fontSize: '18px',
        },
        '& .titleSpan': {
            color: theme.palette.fg['purple'],
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: 10
        }

    },
    button: {
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}))
