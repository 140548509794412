/* eslint-disable react/prop-types */
import React from 'react'
import InfoLineView from './InfoLineView/InfoLineView'
import { FormattedMessage } from 'react-intl'

const LocationLine = (props) => {
    const { job } = props
    if (!job.paid) return null

    return (
        <InfoLineView
            icon='euro'
            text={<FormattedMessage id='job_show.job_info_card.paid' />}
        />
    )
}

export default LocationLine
