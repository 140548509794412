import React from 'react'
import { Image, Transformation } from 'cloudinary-react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './TrendMainArticleCard_style'
import { Box, Chip, Typography, useTheme } from '@material-ui/core'

import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import { SMALL_SIZE } from 'assets/CloudinaryURLHelper'
import CollectionHandler from 'assets/CollectionHandler'
import IconFactory from 'shared/icons/IconFactory/IconFactory'
import DateHandler from 'assets/DateHandler'

const TrendMainArticleCard = (props) => {
    const classes = useStyles()
    const { article } = props
    const theme = useTheme()

    return (
        <RouteMapLink
            showItemType='article'
            showItemUrl={article.url}
            className={classes.articleContainer}
        >
            <Image
                cloudName="studentbe"
                className={classes.image}
                publicId={article.cover_image.cloudinary_key}
                alt='sector image'
            >
                <Transformation flags="progressive" fetchFormat="auto" height={SMALL_SIZE} quality="auto:good" crop="fill" />
            </Image>
            <Box className={classes.articleContent}>
                <Chip
                    classes={{ root: classes.chipRoot, label: classes.chipLabel }}
                    label={CollectionHandler.Translate('articleCategoryCollection', article.category)}
                />
                <Typography variant='h3' className={classes.title}>
                    {article.title}
                </Typography>
                <Box className={classes.footer}>
                    <Box className={classes.infoContainer}>
                        <IconFactory icon='calendar' fontSize='12px' color={theme.palette.fg['greySlate']} />
                        <Typography className={classes.infoText}>
                            {DateHandler.toReadingFormat(article.date)}
                        </Typography>
                    </Box>
                    <Box className={classes.infoContainer}>
                        <IconFactory icon='clock' fontSize='12px' color={theme.palette.fg['greySlate']} />
                        <Typography className={classes.infoText}>
                            {article.read_time} <FormattedMessage id='news_index.article.read_time' />
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </RouteMapLink>
    )
}

export default TrendMainArticleCard
