import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    divider: {
        border: '1px solid var(--background-silver)',
        width: '100%',
        borderRadius: 12,
        marginTop: 'calc(40px - 12px)', // (margin - gap between flex components)
        marginBottom: 40,
        [theme.breakpoints.down('sm')]: {
            marginTop: 'calc(24px - 12px)', // (margin - gap between flex components)
            marginBottom: 24,
        }
    }
}))
