import React from 'react'
import { Link } from 'react-router-dom'
import Sanitizer from 'assets/Sanitizer'
import DateHandler from 'assets/DateHandler'

import CloudinaryURLHelper from 'assets/CloudinaryURLHelper'

export default class KotMessageUserCard extends React.Component {
    lastMessageContent () {
        const text = this.props.candidacy.last_message && Sanitizer.RemoveHTML(this.props.candidacy.last_message)
        return text
    }

    render () {
        var backPic = {
            backgroundImage: `url(${CloudinaryURLHelper.getImageUrl('MESSAGE_CARD', this.props.candidacy.picture || 'assets/pngfind.com-default-image-png-6764065_1')})`
        }

        if (this.props.candidacy.unseen_count === 0) {
            var notificationDisplay = 'hide-notification'
        } else {
            var notificationDisplay = 'show-notification'
        }
        return (
            <Link to={`/${this.props.candidacy.chat_url}`}>
                <div className={`conversation_card chat_card_${this.props.candidacy.conversation_id}`}>
                    <div className={`message-choice-card ${this.props.itemType?.replace(' ', '_')}`}>
                        <div className="message-card-first-infos">
                            <div className={`message-card-picture ${this.props.itemType?.replace(' ', '_')}`} style={backPic}/>
                            <div className="message-card-content-container">
                                <div className="message-time">{this.props.candidacy.last_message_time && DateHandler.ToBasicFormat(this.props.candidacy.last_message_time)}</div>
                                <div className="message-content">
                                    <div className="message-card-information">
                                        <div className={`message-card-name ${notificationDisplay}`}>{this.props.candidacy.user_full_name}</div>
                                    </div>
                                    <div className="message-arrow"><i className="fas fa-chevron-right"></i></div>
                                </div>

                                <div className={`kot-message-notification ${this.props.itemType?.replace(' ', '_')} ${notificationDisplay}`}>
                                    {this.props.candidacy.unseen_count}
                                </div>
                                <div className="message-card-title truncate">{this.lastMessageContent()}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        )
    }
}
