import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({

    searchInternshipSectionContainer: {
        width: '100%',
        padding: '32px 0px 43px'
    },

    searchInternshipField: {
        width: '100%',
        paddingTop: '32px',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '442px',
        }
    },

    fieldOfStudySectionContainer: {
        width: '100%',
        paddingBottom: '43px',
        borderBottom: `0.5px solid ${theme.palette.fg['beigeLight']}`,
    },

    checkboxListContainer: {
        paddingTop: '32px',
    },

    locationSectionContainer: {
        width: '100%',
        padding: '11px 0px 65px',
        borderBottom: `0.5px solid ${theme.palette.fg['beigeLight']}`,
    },

    locationField: {
        width: '100%',
        paddingTop: '14px',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '387px',
        }
    },

    companySizeSectionContainer: {
        width: '100%',
        padding: '12px 0px 65px',
        borderBottom: `0.5px solid ${theme.palette.fg['beigeLight']}`,
    },

    tagsMultiSelectContainer: {
        paddingTop: '32px',
    },

    paidInternshipsSection: {
        width: '100%',
        padding: '12px 0px 80px',
    }

}))
