import React, { useRef, useEffect } from 'react'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import AboutPublishStudentJobHelmet from 'helmets/AboutPublishStudentJobHelmet'
import SectionMainTitle from 'components/SectionMainTitle/SectionMainTitle'
import { Box, Typography, Container, Grid, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import IconFactory from 'icons/IconFactory/IconFactory'
import RouteMap from 'assets/RouteMap'
import ScrollableCompanyLogoList from './ScrollableCompanyLogoList'
import { FormattedMessage } from 'react-intl'
import { Image, Transformation } from 'cloudinary-react'
import { useStyles } from './AboutPublishStudentJob_style'
import { usePipedrive, CHAT_EMPLOYER_GENERAL } from 'hooks/usePipedrive'
import EmployerContactForm from 'shared/components/EmployerContactForm/EmployerContactForm'
import CreditPackages from 'containers/CreditPackages/CreditPackages'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import Button from 'components/Button/Button'

const AboutPublishStudentJob = (props) => {
    const { user } = props
    const classes = useStyles()
    const contactRef = useRef(null)

    useEffect(() => {
        const { instantiateChat } = usePipedrive()
        instantiateChat(CHAT_EMPLOYER_GENERAL)
    }, [])

    const scrollToContactForm = () => { contactRef.current.scrollIntoView({ behavior: 'smooth' }) }

    const postStudentJobUrl = () => {
        const targetUrl = RouteMap.Page('users/student_jobs/new')

        if (user) {
            return targetUrl
        } else {
            return `${RouteMap.Page('login')}?userType=employer&redirect=${targetUrl}`
        }
    }

    const sendAnalyticsClickFreeJobs = () => {
        if (!user) AnalyticsHelper.sendGTMEvent('Clicked Publish Jobs Free')
    }

    const redirectToRegisterLogin = () => {
        const registerForFreeCreditsUrl = user
            ? RouteMap.Page('employer/new-ad')
            : `${RouteMap.Page('login')}?userType=employer`

        window.location.href = registerForFreeCreditsUrl
    }

    const handleClickFreejobs = () => {
        sendAnalyticsClickFreeJobs()
        redirectToRegisterLogin()
    }

    return (
        <PageWrapper user={user}>
            <AboutPublishStudentJobHelmet />

            {/* INTRO */}
            <Container disableGutters maxWidth='xl' className={classes.introSectionContainer}>
                <Box className={classes.titleBox}>
                    <Typography component='h1' variant='h6' className={classes.title}>
                        <FormattedMessage id='publish_a_student_job.intro.title' />
                    </Typography>
                    <Typography className={classes.subTitle}>
                        <FormattedMessage component='span' variant='h2' id='publish_a_student_job.intro.sub_title' />
                    </Typography>
                    <Typography className={classes.subTitle}>
                        <Button
                            disabled={!!user}
                            className={classes.paymentButton}
                            onClick={handleClickFreejobs}
                            variant='primary'
                        >
                            <FormattedMessage id='credit_packages.publish_free' />
                        </Button>
                    </Typography>
                </Box>
            </Container>

            {/* PAYMENT PACKAGES */}
            <Box className={classes.beigeContainer}>
                <Container maxWidth='lg'>
                    <Box className={classes.packagesTextBox}>
                        <Typography component='h2' variant='h6' className={classes.paymentTitle}>
                            <FormattedMessage id="publish_a_student_job.packages.title" />
                        </Typography>
                        <Typography variant='body1' component='p' className={classes.whyText}>
                            <FormattedMessage id="landing_payment_page.subtitle_3" />
                        </Typography>
                        <Typography variant='body1' component='p' className={classes.whyText}>
                            <FormattedMessage id="landing_payment_page.subtitle_4" />
                        </Typography>
                    </Box>
                    <CreditPackages noFirstJobPackage analyticsId='PublishStudentJobPage' />
                </Container>
            </Box>

            {/* WHY STUDENT.BE */}
            <Container maxWidth='lg' className={classes.mainContainer}>
                <SectionMainTitle className={classes.sectionMainTitle} textId='publish_a_student_job.why.title' marginBottom='32px'/>
                <Grid container spacing={4} className={classes.whyContainer}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Box className={classes.whyBox}>
                            <Box className={classes.whyImageBox}>
                                <Image
                                    cloudName='studentbe'
                                    publicId='assets/publish-a-student-job-visits'
                                    alt='student.be publish a student job visits'
                                    className={classes.whyImage}
                                >
                                    <Transformation flags='progressive' fetchFormat='auto' quality='auto:good' width={140} crop='fill' />
                                </Image>
                            </Box>
                            <Box className={classes.whyTextBox}>
                                <Typography component='h2' variant='h3' className={classes.whyTitle}>
                                    <FormattedMessage id='publish_a_student_job.why.visits.title' />
                                </Typography>
                                <Typography component='p' variant='body1' className={classes.whyText}>
                                    <FormattedMessage id='publish_a_student_job.why.visits.text' />
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Box className={classes.whyBox}>
                            <Box className={classes.whyImageBox}>
                                <Image
                                    cloudName='studentbe'
                                    publicId='assets/publish-a-student-job-fast'
                                    alt='student.be publish a student job fast'
                                    className={classes.whyImage}
                                >
                                    <Transformation flags='progressive' fetchFormat='auto' quality='auto:good' width={140} crop='fill' />
                                </Image>
                            </Box>
                            <Box className={classes.whyTextBox}>
                                <Typography component='h2' variant='h3' className={classes.whyTitle}>
                                    <FormattedMessage id='publish_a_student_job.why.fast.title' />
                                </Typography>
                                <Typography component='p' variant='body1' className={classes.whyText}>
                                    <FormattedMessage id='publish_a_student_job.why.fast.text' />
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Box className={classes.whyBox}>
                            <Box className={classes.whyImageBox}>
                                <Image
                                    cloudName='studentbe'
                                    publicId='assets/publish-a-student-job-boost'
                                    alt='student.be publish a student job boost'
                                    className={classes.whyImage}
                                >
                                    <Transformation flags='progressive' fetchFormat='auto' quality='auto:good' width={140} crop='fill' />
                                </Image>
                            </Box>
                            <Box className={classes.whyTextBox}>
                                <Typography component='h2' variant='h3' className={classes.whyTitle}>
                                    <FormattedMessage id='publish_a_student_job.why.boost.title' />
                                </Typography>
                                <Typography component='p' variant='body1' className={classes.whyText}>
                                    <FormattedMessage id='publish_a_student_job.why.boost.text' />
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Box className={classes.whyBox}>
                            <Box className={classes.whyImageBox}>
                                <Image
                                    cloudName='studentbe'
                                    publicId='assets/publish-a-student-job-companies'
                                    alt='student.be publish a student job companies'
                                    className={classes.whyImage}
                                >
                                    <Transformation flags='progressive' fetchFormat='auto' quality='auto:good' width={140} crop='fill' />
                                </Image>
                            </Box>
                            <Box className={classes.whyTextBox}>
                                <Typography component='h2' variant='h3' className={classes.whyTitle}>
                                    <FormattedMessage id='publish_a_student_job.why.companies.title' />
                                </Typography>
                                <Typography component='p' variant='body1' className={classes.whyText}>
                                    <FormattedMessage id='publish_a_student_job.why.companies.text' />
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Box className={classes.whyButtonBox}>
                    <RouteMapLink className={classes.bigButton} redirectionUrl={postStudentJobUrl()}>
                        <Button variant='tertiary'>
                            <FormattedMessage id='publish_a_student_job.post_a_job_button' />
                        </Button>
                    </RouteMapLink>
                </Box>
            </Container>

            {/* COMPANIES */}
            <Container maxWidth='lg' className={classes.mainContainer}>
                <SectionMainTitle className={classes.sectionMainTitle} textId='publish_a_student_job.companies.title' marginBottom='32px'/>
                <ScrollableCompanyLogoList />
                <Box className={classes.companiesCtaBox}>
                    <Typography variant='h1' component='span'>
                        <FormattedMessage id='publish_a_student_job.companies.cta' />
                    </Typography>
                    <RouteMapLink className={classes.bigButton} redirectionUrl={postStudentJobUrl()}>
                        <Button variant='tertiary'>
                            <FormattedMessage id='publish_a_student_job.companies.button' />
                        </Button>
                    </RouteMapLink>
                </Box>
            </Container>

            {/* FAQ */}
            <Container maxWidth='lg' className={classes.mainContainer}>
                <SectionMainTitle className={classes.sectionMainTitle} textId='publish_a_student_job.faq.title' marginBottom='32px'/>
                <Box className={classes.accordionBox}>
                    <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                            <Typography component='h2' variant='h3' className={classes.whyTitle}>
                                <FormattedMessage id='publish_a_student_job.faq.pricing.title' />
                            </Typography>
                        </AccordionSummary>
                        <Box className={classes.answerContainer}>
                            <AccordionDetails>
                                <RouteMapLink page='payment'>
                                    <Typography className={classes.faqCta}>
                                        <FormattedMessage id='publish_a_student_job.faq.pricing.cta' />
                                    </Typography>
                                </RouteMapLink>
                            </AccordionDetails>
                        </Box>
                    </Accordion>
                    <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                            <Typography component='h2' variant='h3' className={classes.whyTitle}>
                                <FormattedMessage id='publish_a_student_job.faq.how_to.title' />
                            </Typography>
                        </AccordionSummary>
                        <Box className={classes.answerContainer}>
                            <AccordionDetails className={classes.accordionDetails}>
                                <Typography className={classes.accordionText}>
                                    <FormattedMessage id='publish_a_student_job.faq.how_to.text' />
                                </Typography>
                                <RouteMapLink redirectionUrl={postStudentJobUrl()}>
                                    <Typography className={classes.faqCta}>
                                        <FormattedMessage id='publish_a_student_job.faq.how_to.cta' />
                                    </Typography>
                                </RouteMapLink>
                            </AccordionDetails>
                        </Box>
                    </Accordion>
                    <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                            <Typography component='h2' variant='h3' className={classes.whyTitle}>
                                <FormattedMessage id='publish_a_student_job.faq.boost.title' />
                            </Typography>
                        </AccordionSummary>
                        <Box className={classes.answerContainer}>
                            <AccordionDetails className={classes.accordionDetails}>
                                <Typography className={classes.accordionText}>
                                    <FormattedMessage id='publish_a_student_job.faq.boost.text' />
                                </Typography>
                                <Typography
                                    className={classes.faqCta}
                                    onClick={scrollToContactForm}
                                >
                                    <FormattedMessage id='publish_a_student_job.faq.boost.cta' />
                                </Typography>
                            </AccordionDetails>
                        </Box>
                    </Accordion>
                    <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                            <Typography component='h2' variant='h3' className={classes.whyTitle}>
                                <FormattedMessage id='publish_a_student_job.faq.how_long.title' />
                            </Typography>
                        </AccordionSummary>
                        <Box className={classes.answerContainer}>
                            <AccordionDetails className={classes.accordionDetails}>
                                <Typography className={classes.accordionText}>
                                    <FormattedMessage id='publish_a_student_job.faq.how_long.text' />
                                </Typography>
                            </AccordionDetails>
                        </Box>
                    </Accordion>
                    <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                            <Typography component='h2' variant='h3' className={classes.whyTitle}>
                                <FormattedMessage id='publish_a_student_job.faq.applications.title' />
                            </Typography>
                        </AccordionSummary>
                        <Box className={classes.answerContainer}>
                            <AccordionDetails className={classes.accordionDetails}>
                                <FormattedMessage id='publish_a_student_job.faq.applications.text'>
                                    {translatedText => <Typography className={classes.accordionText} dangerouslySetInnerHTML={{ __html: translatedText }}/>}
                                </FormattedMessage>
                            </AccordionDetails>
                        </Box>
                    </Accordion>
                    <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                            <Typography component='h2' variant='h3' className={classes.whyTitle}>
                                <FormattedMessage id='publish_a_student_job.faq.invoice.title' />
                            </Typography>
                        </AccordionSummary>
                        <Box className={classes.answerContainer}>
                            <AccordionDetails className={classes.accordionDetails}>
                                <Typography className={classes.accordionText}>
                                    <FormattedMessage id='publish_a_student_job.faq.invoice.text' />
                                </Typography>
                            </AccordionDetails>
                        </Box>
                    </Accordion>
                </Box>
            </Container>

            {/* CONTACT */}
            <Box ref={contactRef}>
                <EmployerContactForm />
            </Box>
        </PageWrapper>
    )
}
export default AboutPublishStudentJob
