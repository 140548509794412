import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 16
    },
    condensedPadding: {
        paddingLeft: '0 !important'
    },
    noPaperPadding: {
        [theme.breakpoints.down('md')]: {
            padding: '0 !important'
        }
    },
    gridFlex: {
        alignSelf: 'center'
    },
    gridElem: {
        marginBottom: '12px !important'
    },
    boxContainer: {
        [theme.breakpoints.down('md')]: {
            padding: '24px 10px 0 10px'
        },
        [theme.breakpoints.up('md')]: {
            padding: '24px',
            border: '1px solid var(--light-grey)'
        }
    },
    buttonsContainer: {
        [theme.breakpoints.down('md')]: {
            borderBottom: '1px dashed var(--light-grey)'
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 16
        }
    },
    formControl: {
        width: '100%',
        margin: 0,
        '& .MuiInputBase-root': {
            cursor: 'pointer'
        }
    },
    studentType: {
        color: 'var(--red)'
    },
    datePicker: {
        color: 'white',
        textColor: 'white',
        calendarTextColor: 'white',
        selectColor: 'white',
        selectTextColor: 'white',
        calendarYearBackgroundColor: 'white',
        headerColor: 'white'
    },
    buttonOutlined: {
        borderColor: 'var(--dark-grey)'

    },
    textWhite: {
        fontSize: '16px !important',
        color: 'white'
    },
    textFont: {
        '&::placeholder': {
            color: 'black !important'
        }
    },
    placeholderTextSmallClassName: {
        fontSize: 14
    },
    textFontBlack: {
        backgroundColor: 'white',
        padding: '14px 24px !important',
        fontSize: 16,
        height: 51,
        borderRadius: 80,
        border: 'solid black 2px',
        boxSizing: 'border-box',
        '&:hover': {
            boxShadow: `4px 4px 0px 0px ${theme.palette.bg.black}`,
            border: `2px solid ${theme.palette.fg.black}`,
            backgroundColor: theme.palette.bg.white,
            color: 'var(--foreground-purple) !important',
            '& svg': {
                color: 'var(--foreground-purple) !important'
            }
        },
        '& .MuiInputBase-input': {
            fontSize: '16px !important',
            padding: 0,
            '&::placeholder': {
                fontSize: 16,
                color: 'var(--dark-beige) !important',
                opacity: '1 !important'
            }
        },
        '&::placeholder': {
            color: 'var(--dark-beige) !important',
            opacity: '1 !important'
        }
    },
    textFieldError: {
        boxShadow: 'inset 0px 0px 0px 2px var(--red) !important',
        '& .MuiInputBase-input::placeholder': {
            color: 'var(--red) !important'
        },
        '& span': {
            color: 'var(--red) !important'
        },
        '&::placeholder': {
            color: 'var(--red) !important',
            opacity: '1 !important'
        }
    },
    placeholderText: {
        fontSize: 16,
        color: 'var(--dark-beige)'
    },
    date: {
        width: '100%',
        fontSize: 16
    },
    dateBlack: {
        backgroundColor: 'white',
        width: '100%',
        borderRadius: 80,
        border: 'solid black 2px',
        '&:hover': {
            boxShadow: `4px 4px 0px 0px ${theme.palette.bg.black}`,
            border: `2px solid ${theme.palette.fg.black}`,
            backgroundColor: theme.palette.bg.white,
            color: 'var(--foreground-purple) !important',
            '& svg': {
                color: 'var(--foreground-purple) !important'
            }
        },
        '& .MuiInputBase-input': {
            fontSize: 16,
            padding: '14px 24px'
        }
    },
    adornmentContainer: {
        paddingRight: '11px'
    },
    autocomplete: {
        margin: 0
    },
    displayNone: {
        display: 'none'
    },
    highSchoolYearInputBoxBlack: {
        maxWidth: 200,
        width: '100%'
    },
    highSchoolYearInputBoxContainerBlack: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    menuItem: {
        fontSize: '16px !important',
        backgroundColor: 'white !important',
        '&:hover': {
            backgroundColor: 'white',
            color: 'var(--foreground-purple) !important'
        }
    },
    customPaper: {
        maxHeight: 350,
        boxShadow: `4px 4px 0px 0px ${theme.palette.bg.black}`,
        border: `2px solid ${theme.palette.fg.black}`,
        backgroundColor: theme.palette.bg.white,
        borderRadius: 16,
        fontSize: '16px !important'
    },
    experienceProfileViewCondensedContainer: {
        width: '100%',
        paddingBottom: '57px',
        [theme.breakpoints.up('sm')]: {
            paddingBottom: '97px',
        }
    },
    title: {
        paddingBottom: '22px',
        color: theme.palette.fg['black'],
        fontSize: '18px',
        lineHeight: '28px'
    },
    inputPlaceholder: {
        display: 'block',
        color: theme.palette.fg['black'],
        fontFamily: 'GreedStandard-Medium',
        lineHeight: '17px'
    },
    input: {
        paddingBottom: '12px !important',
        '&::placeholder': {
            display: 'block',
            paddingBottom: '19px !important',
            color: theme.palette.fg['black'],
            fontFamily: 'GreedStandard-Medium',
            lineHeight: '17px',
            opacity: '1 !important'
        }
    },
    inputUnderline: {
        '&::after': {
            borderBottom: `0.5px solid ${theme.palette.fg['greySlate']}`
        },
    },
    chevronIcon: {
        paddingRight: '17px'
    },
    fieldsDivider: {
        height: '15px',
        backgroundColor: 'transparent'
    }
}))
