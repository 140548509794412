import React from 'react'

import { FormattedMessage } from 'react-intl'

import InfoProfileView from './InfosProfileView'

const InfoProfile = (props) => {
    const {
        student,
        setStudent,
        selectedDate,
        handleDateChange,
        handleDateBlur,
        disabledSave,
        condensed
    } = props

    const handleChangeCheckBox = prop => event => {
        setStudent({ ...student, updated: true, [prop]: event.target.checked })
    }

    const handleChangeData = prop => event => {
        if (event.target.value !== '' && event.target.name !== 'street') {
            setStudent({
                ...student,
                updated: true,
                [prop]: event.target.value,
                [prop + 'Error']: false,
                [prop + 'ErrorMessage']: '',
            })
        } else {
            setStudent({
                ...student,
                updated: true,
                [prop + 'Error']: true,
                [prop + 'ErrorMessage']: <FormattedMessage id="landing_info_profile.required" />
            })
        }
    }

    const handleChangeGender = prop => event => {
        setStudent({ ...student, gender: event.target.value })
    }

    return (
        <InfoProfileView
            disabledSave={disabledSave}
            condensed={condensed}
            handleChangeCheckBox={handleChangeCheckBox}
            handleChangeData={handleChangeData}
            handleChangeGender={handleChangeGender}
            handleDateBlur={handleDateBlur}
            handleDateChange={handleDateChange}
            selectedDate={selectedDate}
            student={student}
        />
    )
}

export default InfoProfile
