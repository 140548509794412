import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    articleCardWrapper: {
        display: 'flex'
    },
    articleContainer: {
        width: '100%',
        borderRadius: '20px',
        border: `1px solid ${theme.palette.fg.silver}`,
        backgroundColor: theme.palette.bg.white,
        transition: 'all 0.3s ease',
        '&:hover': {
            cursor: 'pointer',
            borderColor: `1px solid ${theme.palette.fg.black}`
        }
    },
    image: {
        borderRadius: '20px 20px 0 0',
        display: 'block',
        width: '100%',
        height: '141px',
        objectFit: 'cover',
        [theme.breakpoints.up('md')]: {
            height: '173px'
        }
    },
    articleContent: {
        width: '100%',
        height: '167px',
        padding: '18px 16px 23px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.up('md')]: {
            height: '150px',
            paddingBottom: '14px'
        }
    },
    upContent: {
        width: '100%'
    },
    downContent: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        columnGap: '14px'
    },
    chipRoot: {
        height: 'fit-content',
        backgroundColor: theme.palette.bg.silver,
        borderRadius: '42px',
        padding: '0px 27px',
        cursor: 'pointer'
    },
    chipLabel: {
        padding: 0,
        color: theme.palette.fg.black,
        fontFamily: 'GreedStandard-Medium',
        fontSize: '8px',
        lineHeight: '15px',
        textTransform: 'capitalize',
        [theme.breakpoints.up('md')]: {
            fontSize: '12px',
            lineHeight: '18px'
        }
    },
    title: {
        paddingTop: '21px',
        display: '-webkit-box',
        lineClamp: '2',
        boxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: theme.palette.fg.black,
        fontSize: '20px',
        lineHeight: '24px',
        [theme.breakpoints.up('md')]: {
            paddingTop: '12px'
        }
    },
    infoContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '5px'
    },
    infoText: {
        color: theme.palette.fg.black40Opacity,
        fontSize: '13px',
        lineHeight: '16px'
    }
}))
