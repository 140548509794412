import React from 'react'

const PackageTelescopeSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 512 640"
    >
        <g fill="#a000ff">
            <circle cx="246.3" cy="206.2" r="7.5" />
            <path d="M267.8,240.8v-3.5c5.8-4,10.5-9.6,13.3-16.2h32v8.9h15v-32.8h-15v8.9h-28.9c0-12.9-6.5-24.3-16.4-31.1v-33l52.9-19.3   l120.2-20.8l-14.8-40.6L412,66.4l8.7,23.8l-38.1,6.6l-18.1-49.7L398,27.7l9,24.6l14.1-5.1L405.8,5.8L300.4,67L163,117l-6.7-18.5   l19.6-7.1l-5.1-14.1l-82.3,30l5.1,14.1l48.6-17.7l6.7,18.5l-64.3,23.4l7.6,20.8l-21,7.6l5.1,14.1l21-7.6l7.6,20.8l120-43.7V175   c-7.9,5.5-13.7,13.9-15.7,23.7h-35l-27.1,27.1L133.4,212l-42.8,42.8l38,38l42.8-42.8l-13.6-13.6l22.7-22.7h28.8   c2,9.8,7.7,18.2,15.7,23.7v3.5L90.2,474.1c-5.9,10.2-2.4,23.4,7.8,29.3c3.3,1.9,7,2.9,10.7,2.9c1.9,0,3.7-0.2,5.6-0.7   c5.5-1.5,10.2-5,13-10l54.8-94.9l42.7,15.3v68.8c0,11.8,9.6,21.4,21.4,21.4s21.4-9.6,21.4-21.4V416l42.7-15.3l54.8,94.9   c2.9,5,7.5,8.5,13,10c1.8,0.5,3.7,0.7,5.6,0.7c3.7,0,7.4-1,10.7-2.9c10.2-5.9,13.8-19,7.8-29.3L267.8,240.8z M128.6,271.6   l-16.8-16.8l21.6-21.6l16.8,16.8L128.6,271.6z M114.4,488c-0.9,1.5-2.2,2.6-3.9,3c-1.7,0.4-3.4,0.2-4.9-0.6   c-3.1-1.8-4.1-5.7-2.4-8.8l3.2-5.6l11.2,6.4L114.4,488z M224.9,400l-35.1-12.6l35.1-60.8V400z M225,296.6L225,296.6l-99.9,172.9   L114,463l111-192.2V296.6z M351.2,54.8l16.2,44.6l-43.8,7.6l-10.9-29.9L351.2,54.8z M113.8,182.1l-10-27.6l15-5.5l10,27.6   L113.8,182.1z M142.9,171.5l-10-27.6l166.2-60.5l10,27.6L142.9,171.5z M252.8,147.5v21.4c-2.1-0.4-4.2-0.6-6.4-0.6   s-4.3,0.2-6.4,0.6v-16.7L252.8,147.5z M252.7,259.6h-12.8v-16.1c2.1,0.4,4.2,0.6,6.4,0.6c2.2,0,4.3-0.2,6.4-0.6V259.6z    M252.8,274.6v188.8h-12.9V274.6H252.8z M252.9,484.8h-0.1c0,3.5-2.9,6.4-6.4,6.4s-6.4-2.9-6.4-6.4v-6.4h12.9V484.8z M246.3,229   c-12.6,0-22.9-10.3-22.9-22.9c0-12.6,10.3-22.9,22.9-22.9c12.6,0,22.9,10.3,22.9,22.9C269.2,218.7,259,229,246.3,229z M267.8,400   v-73.4l35.1,60.8L267.8,400z M367.6,469.5l-99.8-172.9v-25.8l111,192.2L367.6,469.5z M387.1,490.4c-1.5,0.9-3.2,1.1-4.9,0.6   c-1.7-0.4-3-1.5-3.9-3l-3.2-5.6l11.2-6.4l3.2,5.6C391.2,484.7,390.2,488.6,387.1,490.4z" />
        </g>
    </svg>
)

export default PackageTelescopeSVG
