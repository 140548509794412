
import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles((theme) => ({
    articleCardContainer: {
        padding: '14px',
        gap: '24px',
        cursor: 'pointer',
        textTransform: 'none',
        borderRadius: '12px',
        width: '100%',

        '&:hover': {
            backgroundColor: theme.palette.bg.greyPale
        }
    },
    articleCard: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        gap: '24px'
    },
    articlePictureContainer: {
        display: 'flex'
    },
    articlePicture: {
        borderRadius: '8px'
    },
    articleContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '88px',
        justifyContent: 'space-between',
        padding: '4px 0',
        width: '100%'
    },
    articleTypograhy: {
        textAlign: 'left',
        lineHeight: '18.33px'
    },
    articleReadTimeContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    articleReadTime: {
        fontFamily: 'GintoNormal-Medium, sans-serif !important',
        fontSize: '12px',
        color: 'var(--grey)'
    }
}))
