import React from 'react'

const FirstJobSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 86 85.98"
        {...props}
    >
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path d="M85.81,20.79a44.76,44.76,0,0,0-1.08-7.27,8.48,8.48,0,0,0-5.94-6.15C78,7.16,77.32,7,76.87,6.93a54.33,54.33,0,0,0-7.1-.83,40.68,40.68,0,0,0-11,.7,50.36,50.36,0,0,0-6.55,1.92,34.4,34.4,0,0,0-11,6.48A45.87,45.87,0,0,0,34.84,22H24.35a8.52,8.52,0,0,0-7.69,4.75Q13.3,33.5,9.92,40.25L7,46.09l-.24.52-.06.13A8,8,0,0,0,14,58h6.22a8.64,8.64,0,0,0,2.37,4.43q3.3,3.27,6.58,6.58A8.9,8.9,0,0,0,34,71.58V78a8,8,0,0,0,8,8,8.14,8.14,0,0,0,3.35-.72l.06,0L46,85l4.66-2.34,14.62-7.29A8.52,8.52,0,0,0,70,67.6q0-3.39,0-6.78V57.17a47,47,0,0,0,5.81-5.27,34.63,34.63,0,0,0,5.25-7.2,42.14,42.14,0,0,0,4.26-12,35.78,35.78,0,0,0,.68-7C86,24.26,85.93,22.7,85.81,20.79Z" />
                <path d="M79.81,14.79a44.76,44.76,0,0,0-1.08-7.27,8.48,8.48,0,0,0-5.94-6.15C72,1.16,71.32,1,70.87.93A54.33,54.33,0,0,0,63.77.1a40.68,40.68,0,0,0-11,.7,50.36,50.36,0,0,0-6.55,1.92,34.4,34.4,0,0,0-11,6.48A45.87,45.87,0,0,0,28.84,16H18.35a8.52,8.52,0,0,0-7.69,4.75Q7.3,27.5,3.92,34.25L1,40.09l-.24.52-.06.13A8,8,0,0,0,8,52h6.22a8.57,8.57,0,0,0,2.37,4.43q3.3,3.27,6.58,6.58A8.9,8.9,0,0,0,28,65.58V72a8,8,0,0,0,8,8,8.14,8.14,0,0,0,3.35-.72l.06,0L40,79l4.66-2.34,14.62-7.29A8.52,8.52,0,0,0,64,61.6q0-3.39,0-6.78V51.17a47,47,0,0,0,5.81-5.27,34.63,34.63,0,0,0,5.25-7.2,42.14,42.14,0,0,0,4.26-12,35.78,35.78,0,0,0,.68-7C80,18.26,79.93,16.7,79.81,14.79Z" />
                <path
                    className="cls-1"
                    fill="white"
                    fillRule="evenodd"
                    d="M36,72V55.65l-.18.05-.25.08-1.6.51-4.17,1.35a.8.8,0,0,1-.95-.25q-3.3-3.33-6.63-6.63a.48.48,0,0,1-.13-.57c.52-1.45,1-2.9,1.52-4.35.16-.47.32-.94.49-1.41,0-.08.05-.17.08-.26a1.42,1.42,0,0,1,0-.16H8l.07-.15.1-.22,1.61-3.21,8-16.11a.5.5,0,0,1,.53-.32H32.66a.54.54,0,0,0,.53-.29,38.81,38.81,0,0,1,7.27-8.48,26.79,26.79,0,0,1,8.46-5,41.67,41.67,0,0,1,5.49-1.62,32.82,32.82,0,0,1,8.85-.55,47.91,47.91,0,0,1,6.09.7c.44.09.89.18,1.33.3a.46.46,0,0,1,.26.27,36.49,36.49,0,0,1,.89,6c.09,1.49.16,3,.17,4.47a27.32,27.32,0,0,1-.54,5.4A34,34,0,0,1,68,34.91,26.69,26.69,0,0,1,64,40.46a42.33,42.33,0,0,1-6.28,5.46,15.55,15.55,0,0,1-1.32.85.65.65,0,0,0-.35.64q0,3.71,0,7.41v6.8a.54.54,0,0,1-.35.58l-16,8-3.21,1.6-.25.12L36,72ZM56,29a5,5,0,1,0-5-5A5,5,0,0,0,56,29Z"
                />
            </g>
        </g>
    </svg>
)

export default FirstJobSVG
