import React from 'react'

const VisitStatSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 640"
        x="0px"
        y="0px"
    >
        <g fill="#a000ff">
            <path d="M256,104a111.135,111.135,0,0,1,73.337,27.349L317.986,142.7a95.993,95.993,0,0,0-127.878,3.483l10.984,11.634a80.006,80.006,0,0,1,105.538-3.76L295.237,165.45a64,64,0,0,0-72.226-4.3l8.259,13.7a48.019,48.019,0,0,1,52.538,2.026l-17.457,17.458a24.006,24.006,0,1,0,6.619,38.635h0a24.035,24.035,0,0,0,4.693-27.321l85.816-85.816L352.167,108.52l-11.495,11.5A128,128,0,0,0,153.6,139.2L166.39,148.8A111.149,111.149,0,0,1,256,104Zm5.657,117.656h0A8,8,0,1,1,264,216,7.944,7.944,0,0,1,261.656,221.657Z" />
            <path d="M48,312H464a32.036,32.036,0,0,0,32-32V264a8,8,0,0,0-8-8H472V48a32.036,32.036,0,0,0-32-32H72A32.036,32.036,0,0,0,40,48V256H24a8,8,0,0,0-8,8v16A32.036,32.036,0,0,0,48,312ZM56,48A16.019,16.019,0,0,1,72,32H440a16.019,16.019,0,0,1,16,16V256H352a7.994,7.994,0,0,0-3.578.845L334.111,264H177.889l-14.311-7.155A7.994,7.994,0,0,0,160,256H56ZM32,272H158.111l14.311,7.155A7.994,7.994,0,0,0,176,280H336a7.994,7.994,0,0,0,3.578-.845L353.889,272H480v8a16.019,16.019,0,0,1-16,16H48a16.019,16.019,0,0,1-16-16Z" />
            <path d="M424,240h16V56a8,8,0,0,0-8-8H80a8,8,0,0,0-8,8V240H88V64H424Z" />
            <rect x="192" y="328" width="16" height="32" />
            <rect x="328" y="328" width="16" height="32" />
            <rect x="256" y="448" width="16" height="24" />
            <path d="M211.571,377.338l8.858,13.324A40.007,40.007,0,0,1,282.542,424a40.474,40.474,0,0,1-.553,6.676l15.779,2.648A56.042,56.042,0,0,0,242.542,368,55.758,55.758,0,0,0,211.571,377.338Z" />
            <path d="M123.188,469.334l15.086,5.332a40.02,40.02,0,0,1,75.452,0l15.086-5.332a56.02,56.02,0,0,0-105.624,0Z" />
            <path d="M362.731,432a56.1,56.1,0,0,0-52.811,37.334l15.085,5.332a40.02,40.02,0,0,1,75.453,0l15.085-5.332A56.1,56.1,0,0,0,362.731,432Z" />
            <path d="M64,440c.571,0,1.141.015,1.709.039.434.019.87.05,1.306.084.117.009.235.014.352.024.516.044,1.032.1,1.55.164l.116.013c.5.064,1,.14,1.508.222a39.9,39.9,0,0,1,20.124,9.638l10.67-11.922a55.862,55.862,0,0,0-20.943-11.8,40.007,40.007,0,0,1,59.6-29.115l8.012-13.85A55.876,55.876,0,0,0,128,376.58V328H112v48.574A56.125,56.125,0,0,0,64.569,424L64,424a56.206,56.206,0,0,0-48.5,27.994l13.849,8.012A40.161,40.161,0,0,1,64,440Z" />
            <path d="M448,424.006V424c-.072,0-.144,0-.217,0-.117,0-.234,0-.352,0A56.125,56.125,0,0,0,400,376.574V328H384v48.58a55.876,55.876,0,0,0-20.006,6.917l8.012,13.85a40.009,40.009,0,0,1,59.6,29.114,55.88,55.88,0,0,0-20.945,11.8l10.67,11.922a39.9,39.9,0,0,1,20.124-9.638c.5-.082,1-.158,1.508-.222l.116-.013c.518-.064,1.034-.12,1.55-.164.117-.01.235-.015.352-.024.436-.034.872-.065,1.306-.084.568-.024,1.138-.039,1.709-.039a40.161,40.161,0,0,1,34.654,20.006l13.849-8.012A56.142,56.142,0,0,0,448,424.006Z" />
            <rect x="168" y="392" width="16" height="16" />
            <rect x="320" y="384" width="16" height="16" />
            <rect x="144" y="344" width="16" height="16" />
            <rect x="256" y="336" width="16" height="16" />
            <rect x="136" y="208" width="16" height="16" />
            <rect x="168" y="208" width="16" height="16" />
            <rect x="200" y="208" width="16" height="16" />
            <rect x="296" y="208" width="16" height="16" />
            <rect x="328" y="208" width="16" height="16" />
            <rect x="360" y="208" width="16" height="16" />
        </g>
    </svg>
)

export default VisitStatSVG
