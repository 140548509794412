/* eslint-disable react/prop-types */
import React from 'react'
import SettingsPage from './components/SettingsPage/SettingsPage'
import PageWrapper from '../../../shared/wrappers/PageWrapper/PageWrapper'
import SharedTabs from '../../../shared/components/SharedTabs/SharedTabs'

export default class Settings extends React.Component {
    // eslint-disable-next-line no-useless-constructor
    constructor (props) {
        super(props)
    }

    render () {
        return (
            <div className="desktop-profile profile-container" style={{ backgroundColor: 'var(--super-light-grey)' }}>
                <PageWrapper user={this.props.user} >
                    <SharedTabs user={this.props.user} currentTab='users/settings/edit' />
                    <SettingsPage props={this.props} />
                </PageWrapper>
            </div>
        )
    }
}
