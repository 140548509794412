import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    userActionsLogginContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '8px'
    },
    loginButtonDashboardButton: {
        '& p': {
            fontFamily: "'GintoNormal-Medium', sans-serif !important"
        },
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: 'var(--black-over)'
        },
        borderColor: 'white !important',
        color: 'white !important'
    }
}))
