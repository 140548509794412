import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    subTitle: {
        marginTop: 8,
        marginBottom: 16
    },
    desktopBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: 24
    },
    mobileScrollBox: {
        display: 'flex',
        overflowX: 'scroll',
        marginLeft: '-16px',
        marginRight: '-24px',
        padding: '0px 16px 40px 16px',
        gap: 12
    },
    articleGridContainer: {
        [theme.breakpoints.down('sm')]: {
            '& div:nth-child(n+5) ': {
                display: 'none'
            }
        }
    }
}))
