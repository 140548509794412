import React from 'react'

const PersonSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61.97 86" {...props}>
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path
                    d="M61.34,44.78l0-.08a9.07,9.07,0,0,0-.58-1.12l-.06-.1-.73-1.15L49.51,26a8.35,8.35,0,0,0-2.06-2.25A14.21,14.21,0,0,0,48,20,14,14,0,0,0,24.16,10,14.14,14.14,0,0,0,20,20h0a14,14,0,0,0,.52,3.76A8.28,8.28,0,0,0,18.47,26q-3,4.66-5.93,9.33l-2.27,3.56C9.55,40,8.83,41.12,8.1,42.25l-.84,1.32A8,8,0,0,0,9.74,54.65l6.72,4.22c.18.11.36.22.54.31V78a8,8,0,0,0,8,8h8a7.77,7.77,0,0,0,1-.06A7.17,7.17,0,0,0,35,86h8a8,8,0,0,0,8-8V59.18a5.54,5.54,0,0,0,.53-.3L58,54.79a8,8,0,0,0,3.31-10Z" />
                <path
                    d="M55.34,38.78l0-.08a9.07,9.07,0,0,0-.58-1.12l-.06-.1-.73-1.15L43.51,20a8.51,8.51,0,0,0-2.06-2.25A14.21,14.21,0,0,0,42,14,14,14,0,0,0,18.16,4,14.14,14.14,0,0,0,14,14h0a14.39,14.39,0,0,0,.51,3.76A8.4,8.4,0,0,0,12.47,20q-3,4.66-5.93,9.33L4.27,32.85C3.55,34,2.83,35.12,2.1,36.25l-.84,1.32A8,8,0,0,0,3.74,48.65l6.72,4.22c.18.11.36.22.54.31V72a8,8,0,0,0,8,8h8a7.77,7.77,0,0,0,1-.06A7.17,7.17,0,0,0,29,80h8a8,8,0,0,0,8-8V53.18a5.54,5.54,0,0,0,.53-.3L52,48.79a8,8,0,0,0,3.31-10Z" />
                <path className="cls-1" fill='white'
                    fillRule='evenodd'
                    d="M22,14A6,6,0,1,0,34,14,6,6,0,1,0,22,14ZM43.3,44.82,48,41.89h0a.16.16,0,0,0,0-.06l0-.06-.73-1.14Q42,32.41,36.72,24.21a.5.5,0,0,0-.37-.19c-5.57,0-11.14,0-16.7,0a.61.61,0,0,0-.43.24q-3,4.66-5.93,9.32L11,37.14,8.84,40.57,8,41.88l6.71,4.22,4.19-6.51h0l.09,0V72h8V52h2V72h8V39.59h.09l4.19,6.53Z" />
            </g>
        </g>
    </svg>
)

export default PersonSVG
