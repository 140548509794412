import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    iconBubble: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 50,
        width: 24,
        height: 24
    }
}))
