import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    aboutPageBanner: {
        height: '66px',
        background: 'var(--background-yellow)',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2.5rem'
    },
    titlePage: {
        [theme.breakpoints.up('sm')]: {
            textAlign: 'center'
        }
    },
    sectionMainTitle: {
        '& h2': {
            fontSize: '24px !important',
            [theme.breakpoints.down('sm')]: {
                fontSize: '18px !important'
            }
        }
    }
}))
