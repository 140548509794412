import React, { useState, useEffect } from 'react'
import { useStyles } from './ArticleReaction_style'
import { FormattedMessage } from 'react-intl'
import ArticleAPI from 'api/ArticleAPI'
import { Box, Typography } from '@material-ui/core'
import SVGFactory from 'icons/SVGFactory/SVGFactory'

const emojiToImageMapping = (emoji) => {
    if (emoji === 'funny') return 'laugh'
    if (emoji === 'great') return 'fire'
    if (emoji === 'interesting') return 'light-bulb'
    if (emoji === 'like') return 'thumbs-up'
    if (emoji === 'ugh') return 'rolling-eyes'
}

const ArticleReaction = (props) => {
    const { articleId } = props
    const classes = useStyles()
    const reactionIdentifier = `articleReactionFor_${articleId}`
    const reactionIdIdentifier = `articleReactionIdFor_${articleId}`
    const [reaction, setReaction] = useState(localStorage.getItem(reactionIdentifier))
    const [animate, setAnimate] = useState(false)
    const [reactionOptions, setReactionOptions] = useState()
    const [articleReactionId, setArticleReactionId] = useState(localStorage.getItem(reactionIdIdentifier))

    useEffect(() => {
        ArticleAPI.getReactionOptions().then((response) => {
            setReactionOptions(response.data.options)
        })
    }, [])

    const handleArticleReaction = (newReaction) => {
        const data = {
            article_id: articleId,
            article_reaction_id: articleReactionId,
            emotion: newReaction
        }
        localStorage.setItem(reactionIdentifier, newReaction)
        setReaction(newReaction)
        setAnimate(true)
        ArticleAPI.addReactionToArticle(data).then(response => {
            if (!articleReactionId) {
                const reactionId = response.data.article_reaction_id
                localStorage.setItem(reactionIdIdentifier, reactionId)
                setArticleReactionId(reactionId)
            }
        })
    }

    return (
        <>
            {reactionOptions &&
                <Box className={classes.container}>
                    <Box className={classes.titleBox}>
                        <Typography variant='h3' component='p'>
                            <FormattedMessage id='article_reaction.title' />
                        </Typography>
                    </Box>
                    <Box className={classes.reactionBox}>
                        {reactionOptions.map(option => {
                            const isSelected = reaction === option.value
                            return (
                                <Box
                                    key={`emoji ${option.value} box`}
                                    onClick={() => handleArticleReaction(option.value)}
                                    className={`
                                        ${classes.emoji}
                                        ${!reaction ? classes.noneSelected : ''}
                                        ${isSelected ? classes.selected : ''}
                                        ${isSelected && animate ? classes.animated : ''}
                                    `}
                                >
                                    <SVGFactory name={emojiToImageMapping(option.value)} className={classes.emojiImage} alt={`emoji ${option.value}`} />
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
            }
        </>
    )
}

export default ArticleReaction
