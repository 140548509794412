import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    root: {
        position: 'relative',
        width: '100%',
        height: '52px',
        [theme.breakpoints.up('sm')]: {
            width: '389px'
        }
    },
    searchBarContainer: {
        width: '100%',
        height: '100%'
    },
    searchBarInput: {
        width: '100%',
        height: '100%',
        borderRadius: '80px',
        border: `2px solid ${theme.palette.fg['black']}`,
        backgroundColor: theme.palette.bg['white'],
        padding: '16px 18px 18px 24px',
        display: 'flex',
        alignItems: 'center',
        transition: 'box-shadow 0.3s ease-in-out',
        '&:hover': {
            boxShadow: `4px 4px 0px 0px ${theme.palette.bg['black']}`,
        },
        [theme.breakpoints.up('sm')]: {
            padding: '16px 21px 16px 24px'
        }
    },
    input: {
        flex: 1,
        height: '100%',
        padding: '0px',
        '&::placeholder': {
            color: theme.palette.fg['black'],
            opacity: 1,
            fontFamily: 'GreedStandard-Medium',
            fontSize: '16px',
            lineHeight: '18px'
        }
    },
    searchBarSupplement: {
        position: 'absolute',
        left: '0px',
        bottom: '-14px',
        transform: 'translateY(100%)',
        width: '100%',
        padding: '12px 16px 16px 16px',
        boxShadow: `4px 4px 0px 0px ${theme.palette.bg['black']}`,
        borderRadius: '30px',
        border: `2px solid ${theme.palette.fg['black']}`,
        backgroundColor: theme.palette.bg['white'],
        display: 'flex',
        flexDirection: 'column',
        gap: 20
    },
    sectionContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16
    },
    sectionTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8
    },
    sectionTitle: {
        fontFamily: 'GreedStandard-Bold'
    },
    sectionCounter: {
        backgroundColor: theme.palette.bg['purple'],
        color: theme.palette.fg['white'],
        fontFamily: 'GreedStandard-Bold',
        fontSize: 10,
        width: 22,
        height: 22
    },
    categoryChipsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 8
    },
    chipRoot: {
        height: 'fit-content',
        backgroundColor: theme.palette.bg['greyPale'],
        borderRadius: '73px',
        padding: '0px 27px',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        '&:hover': {
            backgroundColor: theme.palette.bg['purple'],
            boxShadow: `4px 4px 0px 0px ${theme.palette.bg['black']}`,
            '& span': {
                color: theme.palette.fg['white']
            },
        },
        [theme.breakpoints.up('sm')]: {
            borderRadius: '67px'
        }
    },
    chipLabel: {
        padding: 0,
        color: theme.palette.fg['black'],
        fontFamily: 'GreedStandard-Medium',
        fontSize: '15px',
        lineHeight: '26px',
        textTransform: 'capitalize',
        transition: 'all 0.3s ease',
        [theme.breakpoints.up('sm')]: {
            fontSize: '13px',
            lineHeight: '24px'
        }
    },
    articlesContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    menuItem: {
        borderRadius: 6,
        padding: '8px 10px',
        minHeight: 54,
        whiteSpace: 'normal'
    },
    articleTitle: {
        fontFamily: 'GreedStandard-Medium'
    },
}))
