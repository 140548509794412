import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    studentRegisterViewContainer: {
        width: '100%'
    },
    progressCircleContainer: {
        width: '100%',
        height: '595px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    formTitle: {
        paddingBottom: '47px',
        fontFamily: 'GreedWide-Bold',
        color: theme.palette.fg['black'],
        fontSize: '29px',
        lineHeight: '49px',
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            paddingBottom: '87px'
        }
    },
    submitButton: {
        width: '100% !important',
        maxWidth: '345px',
        textTransform: 'capitalize !important'
    }
}))
