/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react'
import {
    Box, ClickAwayListener, Popper,
    InputBase, Avatar, Typography, MenuItem,
    ListItemText, IconButton
} from '@material-ui/core'
import IconFactory from 'icons/IconFactory/IconFactory'
import { List } from 'react-virtualized'
import { FormattedMessage } from 'react-intl'
import FilterTagList from 'components/Searchbar/shared/FilterTagList/FilterTagList'
import { useStyles } from './CityFilterView_style'

const CityFilterSearchView = (props) => {
    const { filters, handleSearchCities, handleInputSearchCity, searchCities, citiesInput, showTags, open, setOpen, className } = props
    const classes = useStyles()
    const inputRef = useRef(null)
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (e) => {
        setOpen(true)
        setAnchorEl(e.currentTarget)
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }

    const rowRenderer = (row) => {
        const { key, index, style } = row
        const option = searchCities[index]

        return (
            <MenuItem
                key={key}
                style={style}
                value={option.value}
                className={classes.menuItem}
                onClick={() => handleSearchCities(option)}
            >
                <ListItemText primary={`${option.name} (${option.value})`} primaryTypographyProps={{ variant: 'body2' }}/>
            </MenuItem>
        )
    }

    return (<>
        <Box
            onClick={handleClick}
            className={`${classes.textInputSearchContainer} ${className}`}
        >
            <IconFactory icon='location-pin' color='primary' fontSize='15px' className={classes.searchIcon}/>
            <Typography variant='subtitle1' className={classes.selectPlaceholder}>
                <FormattedMessage id="search_bar.search.city">
                    {placeholder =>
                        <InputBase
                            classes={{ input: classes.textInputSearch }}
                            placeholder={placeholder}
                            name="citySearch"
                            onChange={handleInputSearchCity}
                            value={citiesInput}
                            fullWidth
                            onKeyDown={e => e.stopPropagation()}
                            inputRef={inputRef}
                        />
                    }
                </FormattedMessage>
            </Typography>
            {filters.location.length > 0 &&
                    <Avatar className={classes.tagAvatar}>{filters.location.length}</Avatar>
            }
        </Box>
        <Popper
            className={classes.cityFilterDropdown}
            placement='bottom'
            open={open}
            anchorEl={anchorEl}
            transition
            disablePortal
        >
            <ClickAwayListener onClickAway={() => setOpen(false)}>
                <Box>
                    {filters.location.length > 0 && <Box className={classes.selectedItemsListContainer}>
                        <FilterTagList small handleChange={handleSearchCities} items={filters.location}/>
                    </Box>}
                    <List
                        width={400}
                        height={300}
                        rowCount={searchCities.length}
                        rowHeight={45}
                        rowRenderer={rowRenderer}
                    />
                </Box>
            </ClickAwayListener>
        </Popper>
        {showTags &&
            <Box className={classes.filterTagList}>
                <FilterTagList handleChange={handleSearchCities} items={filters.location} />
            </Box>
        }
    </>)
}

export default CityFilterSearchView
