import React from 'react'

const GraduateSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 86 66.31"
        {...props}
    >
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path d="M86,25.82a8.51,8.51,0,0,0-5.82-8.5Q64.6,11.94,49,6.52a9.1,9.1,0,0,0-6.07,0l-11.4,4-6.42,2.22c-4.47,1.56-8.94,3.11-13.45,4.66A8.37,8.37,0,0,0,6,25.57c0,.68,0,1.37,0,2a8.38,8.38,0,0,0,4.82,7.92v10.2l-.29.74a37.85,37.85,0,0,1-2.86,5.43l-.2.34-.07.1a8,8,0,0,0,5.1,12.29l1.32.26L19.29,66a8.57,8.57,0,0,0,6.55-.72,8,8,0,0,0,2.54-2.18A39.9,39.9,0,0,0,40.5,66c1.84.18,3.68.27,5.5.27,1.42,0,2.82-.06,4.21-.17a42.06,42.06,0,0,0,12-2.53,21.51,21.51,0,0,0,7-4,11.23,11.23,0,0,0,3.85-9.66L72,38.76l8.28-2.87A8.37,8.37,0,0,0,86,27.61C86,26.9,86,26.33,86,25.82Z" />
                <path d="M80,19.82a8.51,8.51,0,0,0-5.82-8.5Q58.6,5.94,43,.52A9.1,9.1,0,0,0,37,.52l-11.4,4L19.15,6.71C14.68,8.27,10.21,9.82,5.7,11.37A8.37,8.37,0,0,0,0,19.57c0,.68,0,1.37,0,2a8.38,8.38,0,0,0,4.82,7.92v10.2l-.29.74a38.88,38.88,0,0,1-2.86,5.43l-.2.34-.07.1A8,8,0,0,0,6.5,58.61l1.32.26L13.29,60a8.57,8.57,0,0,0,6.55-.72,8,8,0,0,0,2.54-2.18A39.9,39.9,0,0,0,34.5,60c1.84.18,3.68.27,5.5.27,1.42,0,2.82-.06,4.21-.17a42.06,42.06,0,0,0,12-2.53,21.37,21.37,0,0,0,7-4,11.22,11.22,0,0,0,3.86-9.66L66,32.76l8.28-2.87A8.37,8.37,0,0,0,80,21.61C80,20.9,80,20.33,80,19.82Z" />
                <path
                    className="cls-1"
                    fill="white"
                    d="M20.87,26.67l.33.14,18.55,6.4a.89.89,0,0,0,.51,0c.87-.28,1.74-.59,2.61-.89l28.76-10c.27-.09.38-.2.37-.49,0-.78,0-1.55,0-2.33a.55.55,0,0,0-.44-.63L40.4,8.07a1.1,1.1,0,0,0-.8,0L24.54,13.31,8.34,18.92A.42.42,0,0,0,8,19.4q0,1.2,0,2.4a.45.45,0,0,0,.37.53c1.36.46,2.71,1,4.06,1.42a.48.48,0,0,1,.4.52q0,8.29,0,16.57a1.36,1.36,0,0,1-.06.45c-.24.68-.5,1.36-.76,2a48.78,48.78,0,0,1-3.71,7.09c-.07.11-.14.22-.23.34l7,1.38c.32.06.72.23.94.11s.28-.56.4-.86a15,15,0,0,0,1.19-7.51,11.51,11.51,0,0,0-1.41-4.49,1.07,1.07,0,0,1-.13-.53c0-4.48,0-9,0-13.45,0-.22,0-.36.28-.43a9.17,9.17,0,0,0,.91-.38l23.37-9.11.38-.13c.4,1,.79,2,1.2,3L20.87,26.67Zm1.54,3.89c-.16,1.44-.32,2.82-.47,4.21-.34,3.18-.66,6.37-1.05,9.55a3.49,3.49,0,0,0,1,3,9.35,9.35,0,0,0,2.61,1.84,31,31,0,0,0,10.81,2.86,45.91,45.91,0,0,0,8.29.1,35.06,35.06,0,0,0,9.82-2,13.69,13.69,0,0,0,4.46-2.49,3.35,3.35,0,0,0,1.29-2.91c-.08-.77-.15-1.54-.23-2.31-.33-3.18-.65-6.36-1-9.53-.08-.77-.16-1.53-.25-2.34l-.46.15c-5.29,1.84-10.59,3.65-15.87,5.52a3.54,3.54,0,0,1-2.62,0c-4.59-1.65-9.21-3.22-13.82-4.82l-2.52-.86Z"
                />
            </g>
        </g>
    </svg>
)

export default GraduateSVG
