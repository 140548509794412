export const useStyles = theme => ({
    aboutPageBanner: {
        minHeight: '66px',
        background: 'var(--background-yellow)',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2.5rem'
    },
    titlePage: {
        padding: '1rem 0',
        [theme.breakpoints.up('sm')]: {
            textAlign: 'center'
        }
    },
    pageBox: {
        marginBottom: '5rem'
    },
    pressContainer: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: '130px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0
        }
    },
    pressBox: {
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column'
    },
    date: {
        color: 'rgba(165, 165, 165, 0.88)'
    },
    bullet: {
        backgroundColor: 'rgba(165, 165, 165, 0.88)',
        height: 7,
        width: 7,
        borderRadius: 100,
        margin: '9px 0.9em 0'
    },
    titleOfPress: {
        fontFamily: "'GreedStandard-Medium', sans-serif !important",
        fontSize: 'var(--regular-font-size) !important'
    },
    mediaAndDate: {
        display: 'flex',
        flexDirection: 'row'
    },
    contactTitle: {
        margin: '40px 0 1.43rem',

        [theme.breakpoints.up('sm')]: {
            marginLeft: '47px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0
        }
    },
    contactInfo: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: '130px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0
        }
    }
})
