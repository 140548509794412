import axios from 'axios'
import ReactOnRails from 'react-on-rails'

import {
    USER_SETTINGS_BASIC_API,
    USER_SETTINGS_EMAIL_API,
    USER_SETTINGS_PASSWORD_API,
    USER_SETTINGS_DELETE_ACCOUNT_API
} from './env'

function EditSettings (data) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: USER_SETTINGS_BASIC_API,
        method: 'patch',
        params: data
    })
}

function ChangeEmail (data) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: USER_SETTINGS_EMAIL_API,
        method: 'patch',
        params: {
            email: data.email,
            password: data.password,
            new_email: data.new_email,
            confirm_email: data.confirm_email
        }
    })
}

function ChangePassword (data) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: USER_SETTINGS_PASSWORD_API,
        method: 'patch',
        params: {
            email: data.email,
            password: data.password,
            new_password: data.new_password,
            confirm_password: data.confirm_password
        }
    })
}

function DeleteAccount (data) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: USER_SETTINGS_DELETE_ACCOUNT_API,
        method: 'patch',
        params: {
            email: data.email,
            delete_string: data.delete_string,
            password: data.password
        }
    })
}

export default {
    EditSettings,
    ChangeEmail,
    ChangePassword,
    DeleteAccount
}
