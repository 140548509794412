import React from 'react'

const UmbrellaBeachSVG = (props) => (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" {...props}>
        <path d="M509.28,435.19h-220.33l63.5-174.39.05.02,141.13,51.3c14.03,5.11,29.45-3.25,30.69-18.13,6.9-83.7-40.3-165.23-120.67-199.1-3.02-1.32-9.14-3.49-12.32-4.65-84.56-30.94-177.01,2.48-226.76,72.85-8.6,12.17-2.17,28.52,11.86,33.63l141.07,51.36-68.06,187.1H100.09c-10.31,0-18.6,8.29-18.6,18.6s8.29,18.6,18.6,18.6h409.19c10.31,0,18.6-8.29,18.6-18.6s-8.29-18.6-18.6-18.6Z" fill='#000' strokeWidth='0px' />
        <path d="M493.22,417.74h-220.33l63.5-174.39.05.02,141.13,51.3c14.03,5.11,29.45-3.25,30.69-18.13,6.9-83.7-40.3-165.23-120.67-199.1-3.02-1.32-9.14-3.49-12.32-4.65-84.56-30.94-177.01,2.48-226.76,72.85-8.6,12.17-2.17,28.52,11.86,33.63l141.07,51.36-68.06,187.1H84.02c-10.31,0-18.6,8.29-18.6,18.6s8.29,18.6,18.6,18.6h409.19c10.31,0,18.6-8.29,18.6-18.6s-8.29-18.6-18.6-18.6Z" fill='#000' strokeWidth='0px' />
        <path d="M373.83,72.79c-84.56-30.94-177.01,2.48-226.76,72.85-8.6,12.17-2.17,28.52,11.86,33.63l141.07,51.36-68.06,187.1H82.59c-10.31,0-18.6,8.29-18.6,18.6s8.29,18.6,18.6,18.6h409.19c10.31,0,18.6-8.29,18.6-18.6s-8.29-18.6-18.6-18.6h-220.33l63.5-174.39.05.02,141.13,51.3c14.03,5.11,29.45-3.25,30.69-18.13,6.9-83.7-40.3-165.23-120.67-199.1-3.02-1.32-9.14-3.49-12.32-4.65Z" fill='#fff' strokeMiterlimit={10} strokeWidth={'14px'} />
        <path d="M374.57,72.71h0c1.01.39,2.01.7,3.1,1.08,3.18,1.16,6.28,2.32,9.3,3.64h0c80.37,33.87,127.56,115.4,120.67,199.1-1.24,14.88-16.66,23.25-30.69,18.13l-141.13-51.3-34.95-12.71-141.13-51.38c-14.03-5.11-20.46-21.47-11.86-33.63,49.75-70.37,141.67-102.3,226.76-72.85l-.08-.08ZM288.62,100.46c-37.2,4.96-71.84,22.86-97.49,50.68l53.32,19.37c11-25.5,25.88-49.13,44.17-70.06ZM385.11,221.66c7.28-25.19,10.23-51.46,8.68-77.65-.85-14.1-9.3-26.5-21.85-32.47-2.32-.93-4.65-1.86-6.97-2.71-.77-.31-1.55-.54-2.25-.77-14.1-4.57-29.68-.7-39.91,10.15-18.06,19.06-32.7,41.15-43.32,65.1l105.63,38.36ZM420.14,234.37l50.76,18.52c-1.7-36.42-15.73-71.22-39.52-98.58.31,27.12-3.49,54.09-11.24,80.06h0ZM492.6,417.74c10.31,0,18.6,8.29,18.6,18.6s-8.29,18.6-18.6,18.6H83.4c-10.31,0-18.6-8.29-18.6-18.6s8.29-18.6,18.6-18.6h149.34l72.65-199.72,34.77,13.24-67.9,186.48h220.33Z" fill='#000' strokeWidth='0px' />
    </svg>
)

export default UmbrellaBeachSVG
