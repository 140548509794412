import axios from 'axios'
import ReactOnRails from 'react-on-rails'

import { 
    ADMIN_VIDEO_FETCH,
    ADMIN_VIDEO_CREATE,
    ADMIN_VIDEO_UPDATE
 } from './env'

const FetchVideo = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: ADMIN_VIDEO_FETCH,
        method: 'get',
        params: data
    })
}

const CreateVideo = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: ADMIN_VIDEO_CREATE,
        method: 'post',
        data: data
    })
}
const UpdateVideo = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: ADMIN_VIDEO_UPDATE,
        method: 'patch',
        data: data
    })
}

export default {
    CreateVideo,
    UpdateVideo,
    FetchVideo
}
