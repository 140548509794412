import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    promotionPageContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 50,
        marginBottom: 60
    },
    titleContainer: {
        width: '100%',
        height: '350px',
        backgroundColor: 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }
    },
    pageContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 50,
        [theme.breakpoints.down('sm')]: {
            padding: '0 10px'
        }
    },
    text: {
        fontSize: 16,
    },
    breaklines: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        padding: 30,
        [theme.breakpoints.down('sm')]: {
            margin: '0 14px'
        }
    },
    breaklineTitle: {
        fontSize: '20px !important',
        fontFamily: 'GreedWide-Bold, sans-serif !important'
    },
    promotionTitle: {
        fontFamily: 'GreedWide-Bold, sans-serif !important',
        fontSize: 36,
        color: 'white'
    },
    promotionCountTitle: {
        fontFamily: 'GreedWide-Bold, sans-serif !important',
        fontSize: '24px !important',
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        },
        // .promotionSpan defined on hard in Phrase
        // Requires direct access to class
        '& .promotionsSpan': {
            color: 'var(--foreground-purple)'
        }
    },
    promotionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 32
    },
    promotionCardsContainer: {
        [theme.breakpoints.up('md')]: {
            padding: '0 80px'
        }
    },
    promotionItem: {
        width: 300
    },
    CTAContainer: {
        [theme.breakpoints.up('md')]: {
            margin: '0 80px'
        },
        [theme.breakpoints.down('sm')]: {
            margin: '0 14px'
        }
    },
    aboutPageBanner: {
        minHeight: '66px',
        background: '#FF6762',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2.5rem'
    },
    titlePage: {
        color: '#FFFFFF',
        padding: '1rem 0',
        [theme.breakpoints.up('sm')]: {
            textAlign: 'center'
        }
    },
    pressContent: {
        marginLeft: '24px',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 'unset'
        }
    },
    pressImg: {
        width: '100%',
        objectFit: 'cover'
    },
    pressImgBox: {
        width: '260px',
        height: '368',
        background: 'rgba(241, 237, 232, 0.1)',
        margin: '0 auto'
    },
    alignCenter: {
        height: '3.5rem',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    alignTextCenter: {
        textAlign: 'center'
    }
}))
