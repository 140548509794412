import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { FormattedMessage } from 'react-intl'

import { useStyles } from '../../ExperienceProfile_style'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

import IconFactory from 'icons/IconFactory/IconFactory'

const ExperienceYearInput = (props) => {
    const { className, inputRef, error, onChange, value, disableUnderline, placeholderTextId = ' ', inputClasses, formattedMessageValues } = props
    const classes = useStyles()

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {<FormattedMessage id={placeholderTextId} values={formattedMessageValues}>
                {placeholderText =>
                    <KeyboardDatePicker
                        error={false}
                        keyboardIcon={<IconFactory icon='date' color='black' />}
                        className={`${className} ${error && classes.textFieldError}`}
                        required
                        views={['year']}
                        openTo='year'
                        format='yyyy'
                        value={value}
                        onChange={onChange}
                        KeyboardButtonProps={{ 'aria-label': 'change year' }}
                        InputProps={{ disableUnderline: disableUnderline, classes: inputClasses && inputClasses }}
                        inputRef={inputRef}
                        invalidDateMessage=''
                        maxDateMessage=''
                        minDateMessage=''
                        placeholder={placeholderText}
                    />
                }
            </FormattedMessage>}
        </MuiPickersUtilsProvider>
    )
}

export default ExperienceYearInput
