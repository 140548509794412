import React from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import DefaultHelmet from './DefaultHelmet'

class KotShowHelmet extends React.Component {
    render () {
        let firstImageCloudinaryKey = 'student-be-logo'
        if (this.props.kot.documents.length > 0) {
            firstImageCloudinaryKey = this.props.kot.documents[0].cloudinary_key
        }

        return <>
            <DefaultHelmet
                title={`${this.props.kot.title} | Kot | Student.be`}
                description={this.props.kot.description.replace(/<\/?[^>]+(>|$)/g, '').substring(0, 155)}
                imageCloudinaryKey={firstImageCloudinaryKey}
                noindex={this.props.noindex}
            />
            <Helmet>
                <meta property="og:type" content="website" />
                <meta property="og:url" content={`https://www.student.be${window.location.pathname}`} />
                <meta property='og:updated_time' content={this.props.kot.updated_at} /> {/* When the kot was last changed. */}
                <link rel="canonical" href={`https://www.student.be${window.location.pathname}`} />
                <link rel="alternate" hrefLang="en-BE" href={`https://www.student.be/en/student-rooms/${this.props.kot.url}`} />
                <link rel="alternate" hrefLang="fr-BE" href={`https://www.student.be/fr/kots-a-louer/${this.props.kot.url}`} />
                <link rel="alternate" hrefLang="nl-BE" href={`https://www.student.be/nl/koten/${this.props.kot.url}`} />
                <link rel="alternate" hrefLang="x-default" href={`https://www.student.be/en/student-rooms/${this.props.kot.url}`} />
            </Helmet>
        </>
    }
}

export default injectIntl(KotShowHelmet)
