import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    errorText: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        '& .Mui-error': {
            fontSize: '14px !important'
        }
    },
    inputBase: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        '&::placeholder': {
            fontStyle: 'italic',
            fontSize: '14px !important'
        }
    }
}))
