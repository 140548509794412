import React from 'react'

import PageWrapper from 'shared/wrappers/PageWrapper/PageWrapper'

const RecruitmentSelectionPage = (props) => {
    return (
        <PageWrapper>
            DEFAULT PAGE
        </PageWrapper>
    )
}

export default RecruitmentSelectionPage
