import axios from 'axios'
import ReactOnRails from 'react-on-rails'
import RouteMap from '../assets/RouteMap.js'

import { ANALYTICS_API } from './env'

const sendData = (action, data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: ANALYTICS_API,
        method: 'post',
        params: {
            segment_action: action,
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

export default { sendData }
