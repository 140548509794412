import React from 'react'
import isNil from 'lodash.isnil'
import MessageFileUploadModal from 'inputs/Uploader/MessageFileUploadModal/MessageFileUploadModal'
import MessageCard from '../MessagesCard/MessageCard'
import { injectIntl, FormattedMessage } from 'react-intl'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import FilledButton from 'buttons/FilledButton/FilledButton'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

class MessageDisplay extends React.Component {
    shouldComponentUpdate (nextProps) {
        if (this.props.messages === nextProps.messages && this.props.addFile === nextProps.addFile) {
            return false
        } else {
            return true
        }
    }

    render () {
        if (isNil(this.props.messages)) { return null }
        let inputFile = ''
        if (this.props.addFile === true) {
            console.log('MessageDisplay', this.props.addFile)
            inputFile = <div className="add-attached-file-container">
                <div className="closeattachedFile" onClick={() => this.props.addFileFunction(false)}>X</div>
                <MessageFileUploadModal
                    functionOnSuccess={this.props.functionOnSuccess}
                    addFileFunction={this.props.addFileFunction}
                    type={'button'}
                    name={'Add File'}
                />
            </div>
        }
        const messages = this.props.messages.reverse().map((message) => {
            return <MessageCard user={this.props.user} message={message} key={message.id}/>
        })

        return (
            <div className="messages-list-container">
                <div className="messages-display-container">
                    {messages}
                    <Hidden mdUp>
                        {this.props.candidacy?.chat_only && this.props.user.type === 'employer' &&
                            <Box mb={1.5} className="CompanyUser" display='flex' alignItems='center'>
                                <FormattedMessage id='job_chat.employer_chat' />
                            </Box>
                        }
                        {this.props.candidacy?.chat_only && this.props.user.type === 'student' &&
                            <Box mb={1.5} className="CompanyUser" display='flex' alignItems='center'>
                                <RouteMapLink showItemType={this.props.chatType} showItemUrl={this.props.itemUrl}>
                                    <FilledButton
                                        color='secondary'
                                        name={<FormattedMessage id="job_chat.apply_to_this_job" />}
                                    />
                                </RouteMapLink>
                            </Box>
                        }
                        {this.props.candidacy && this.props.user.type === 'employer' &&
                            <Box mb={1.5} className="CompanyUser" display='flex' alignItems='center'>
                                <FormattedMessage id='chat.job_title' values={{ job_title: this.props.chatTitle }}/>
                            </Box>
                        }
                    </Hidden>
                </div>
                {inputFile}
            </div>

        )
    }
}

export default injectIntl(MessageDisplay)
