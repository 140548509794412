import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    companyBodyTabsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            gap: 8,
            marginBottom: 20,
        }
    },
    companyBodyTabs: {
        display: 'flex',
        gap: 12,
        border: '1px solid var(--foreground-silver)',
        borderRadius: 'var(--border-radius)',
        width: 'fit-content',
        padding: 4,
    },
    companyTab: {
        cursor: 'pointer',
        padding: '5px 20px',
        color: 'var(--foreground-black-40-opacity)',
        borderRadius: 'var(--border-radius)',
        background: 'var(--background-silver)'
    },
    selectedTab: {
        color: 'var(--background-white)',
        background: 'var(--background-purple)'
    },
    jobsTab: {
        paddingRight: 10
    },
    jobsCount: {
        display: 'inline-block',
        background: 'var(--background-purple)',
        color: 'var(--foreground-white)',
        borderRadius: '50%',
        marginLeft: 10,
        fontSize: 12,
        width: 20,
        height: 20,
        textAlign: 'center',
        lineHeight: 1.6,
    },
    selectedJobsCount: {
        color: 'var(--foreground-black-40-opacity)',
        background: 'var(--background-white)'
    }
}))
