/* eslint-disable react/prop-types */
import React from 'react'

import { FormattedMessage } from 'react-intl'
import { useStyles } from './AttentionBanner_style'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const AttentionBanner = (props) => {
    const classes = useStyles()
    if (!props.user.show_attention_banner) {
        return null
    }
    const bannerText = props.user.type === 'employer' ? 'attention_banner.employer.title' : 'attention_banner.kot_owner'
    return (
        <Box className={classes.aboutPageBanner}>
            <Container maxWidth="md">
                <Typography variant='h2' component='p' className={classes.titlePage}>
                    <FormattedMessage id={bannerText} />
                </Typography>
                {props.user.type === 'employer' &&
                    <Typography className={classes.subTitle}>
                        <FormattedMessage id='attention_banner.employer.sub_title' />
                    </Typography>
                }
            </Container>
        </Box>
    )
}

export default AttentionBanner
