import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import RecommendedJobsView from 'components/RecommendedJobsView/RecommendedJobsView'
import RouteMap from 'assets/RouteMap'
import KotAPI from 'api/KotAPI'

const KotShow = (props) => {
    const { user, kot } = props
    const [similarKots, setSimilarKots] = useState([])

    useEffect(() => {
        getSimilarKots()
    }, [])

    const getSimilarKots = () => {
        const data = {
            id: kot.id,
            per_page: 3
        }

        KotAPI.GetSimilarKots(data).then((response) => {
            setSimilarKots(response.data)
        })
        sendGTMEvent()
    }

    const sendGTMEvent = () => {
        AnalyticsHelper.sendGTMEvent(
            'Kot Details Viewed',
            AnalyticsHelper.constructKotDetailsViewedAnalyticsAttributes(user)
        )
    }

    console.log(RouteMap.Index('kot'))
    console.log(kot)

    return (
        <RecommendedJobsView
            items={similarKots}
            user={user}
            title={<FormattedMessage id='landing_card_kot_page.similar_kot_upp' />}
            moreItemsText={<FormattedMessage id='landing_card_kot_page.all_similar_kot' />}
            moreItemsURL={RouteMap.LocationIndex('kot', kot?.address)}
        />
    )
}

export default KotShow
