import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    promotionContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '25px',
        gap: '25px'
    },
    promotionTitle: {
        fontSize: 38,
        textAlign: 'center',
        marginBottom: '15px'
    },
    // === IMPORTANT - TO READ BEFORE CHANGE ===
    // The styling of the content is similar the article show content style
    // with some small differences. However, both styles should be updated
    // when changing the promotion content style
    promotionContent: {
        textAlign: 'justify',
        '& span': {
            ...theme.typography.body1
        },
        '& p': {
            ...theme.typography.body1,
            fontSize: '16px !important',
            fontFamily: theme.typography.body1.fontFamily,
            margin: 0,
            lineHeight: '28px !important',
            color: 'var(--black)',
            [theme.breakpoints.down('md')]: {
                lineHeight: '24px !important'
            }
        },
        '& .ql-align-center': {
            textAlign: 'center'
        },
        '& .ql-align-justify': {
            textAlign: 'justify'
        },
        '& .ql-align-right': {
            textAlign: 'right'
        },
        '& li': {
            ...theme.typography.body1,
            fontFamily: theme.typography.body1.fontFamily,
            fontSize: '16px !important',
            lineHeight: '28px !important',
            color: 'var(--black)',
            [theme.breakpoints.down('md')]: {
                fontSize: '16px !important',
                lineHeight: '24px !important'
            }
        },
        '& h1': {
            ...theme.typography.h1,
            [theme.breakpoints.down('md')]: {
                fontSize: '24px !important'
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '22px !important'
            }
        },
        '& h2': {
            ...theme.typography.h2,
            [theme.breakpoints.down('md')]: {
                fontSize: '20px !important'
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '18px !important'
            }
        },
        '& h3': {
            ...theme.typography.h3,
            [theme.breakpoints.down('md')]: {
                fontSize: '18px !important'
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '18px !important'
            }
        },
        '& img': {
            borderRadius: 12,
            height: '100% !important',
            flexGrow: 1
        },
        '& .images-container': {
            display: 'flex',
            overflowY: 'auto'
        }
    },
    promotionCTAContainer: {
        borderRadius: '12px',
        backgroundColor: 'var(--red)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            padding: '30px'
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            gap: '20px',
            padding: '16px'
        }
    },
    promotionButton: {
        padding: '10px 34px',
        borderRadius: '8px',
        backgroundColor: 'white !important',
        color: 'black',
        height: '40px',
        boxShadow: 'none',
        '& p': {
            fontSize: '12px !important',
            fontFamily: 'GreedStandard-Medium, sans-serif !important'
        },
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#fafafa !important'
        },
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    promotionLink: {
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    CTAContainerTitle: {
        color: 'white',
        fontSize: '16px',
        fontFamily: 'GreedWide-Bold, sans-serif !important'
    }
}))
