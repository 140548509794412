import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    arrowLeft: {
        left: -10,
        zIndex: 2,
        top: '20%',
        position: 'absolute',
        marginLeft: 8,
        '&:hover': {
            boxShadow: '0px 0px 20px 0px var(--background-white)',
            borderRadius: '50%'
        }
    },
    arrowRight: {
        right: -5,
        zIndex: 2,
        top: '11%',
        position: 'absolute',
        marginRight: 8,
        '&:hover': {
            boxShadow: '0px 0px 20px 0px var(--background-white)',
            borderRadius: '50%'
        }
    },
    arrowBtn: {
        height: 40,
        width: 40,
        backgroundColor: 'var(--background-black)',
        '&:hover': {
            backgroundColor: 'var(--background-black)',
            height: 40,
            width: 40
        }
    },
    arrows: {
        width: '18px',
        color: 'var(--background-white)',
    },
    atScrollEnd: {
        maskImage: 'linear-gradient(to left, transparent, black 20px, black 90%, transparent)'
    },
    atScrollStart: {
        maskImage: 'linear-gradient(to right, transparent, black 20px, black 90%, transparent)'
    }
}))
