/* eslint-disable react/prop-types */
import React from 'react'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import { FormattedMessage } from 'react-intl'
import IconButton from '@material-ui/core/IconButton'
import { useStyles } from './CvButton_style'

const CvButton = (props) => {
    const classes = useStyles()
    if (!props.cvUrl && !props.oldCvUrl) { return null }

    const getCvUrl = () => {
        return props.cvUrl || props.oldCvUrl
    }

    return (
        <Tooltip title={<FormattedMessage id="job_results.items_card.tooltip.download_cv" />} >
            <IconButton target='_blank' href={getCvUrl()} className={classes.styledLink} download>
                <Typography
                    variant='body2'
                    style={{ fontSize: 16 }}
                >
                    CV
                </Typography>
            </IconButton>
        </Tooltip>
    )
}

export default CvButton
