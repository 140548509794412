import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './CTABannerButton_style'
import { Box } from '@material-ui/core'

import { JOB_TYPES_LIST } from 'assets/ItemHelper'
import { useWindowSize } from 'hooks/useWindowSize'
import Button from 'shared/components/Button/Button.jsx'
import RouteMap from 'assets/RouteMap'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const CTABannerButton = (props) => {
    const { userType, type, textId } = props
    const classes = useStyles()
    const { isLargeScreen } = useWindowSize()

    const renderButton = (redirectionUrl, id) => {
        return (
            <RouteMapLink
                redirectionUrl={redirectionUrl}
            >
                <Button variant='cta-primary' className={classes.ctaButton}>
                    <FormattedMessage id={textId} />
                </Button>
            </RouteMapLink>
        )
    }

    const renderCTABannerButton = () => {
        const newEmployerUrl = userType
            ? RouteMap.Page('employer/new-ad')
            : `${RouteMap.Page('login')}?redirect=${RouteMap.Page('employer/new-ad')}&userType=employer`
        const newKotUrl = userType
            ? RouteMap.Page('users/kots/new')
            : `${RouteMap.Page('login')}?redirect=${RouteMap.Page('users/kots/new')}&userType=kot_owner`
        let offerButton

        if (userType) {
            if (userType === 'employer' && JOB_TYPES_LIST.includes(type)) {
                offerButton = renderButton(
                    newEmployerUrl,
                    `item_offers.${type}.new_ad`
                )
            } else if (userType === 'kot_owner' && type === 'kot') {
                offerButton = renderButton(
                    newKotUrl,
                    'item_offers.kot.new_ad'
                )
            }
        } else {
            if (JOB_TYPES_LIST.includes(type)) {
                offerButton = renderButton(
                    newEmployerUrl,
                    `item_offers.${type}.new_ad_disconnected`
                )
            } else if (type === 'kot' && isLargeScreen) {
                offerButton = renderButton(
                    newKotUrl,
                    'item_offers.kot.new_ad'
                )
            }
        }
        return offerButton
    }

    return (
        <Box className={classes.buttonContainer}>
            {renderCTABannerButton()}
        </Box>
    )
}

export default CTABannerButton
