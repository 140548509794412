import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './KotShowMap_style'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import IconFactory from 'icons/IconFactory/IconFactory'
import KotShowSectionTitle from '../KotShowSectionTitle/KotShowSectionTitle'

const TOKEN = process.env.MAPBOX_SECRET_KEY
const STYLE_ID = process.env.MAPBOX_STYLE_LINK

const KotShowMap = (props) => {
    const { kot } = props
    const classes = useStyles()
    const [mapLoaded, setMapLoaded] = useState(false)

    const handleClickMap = () => {
        if (!mapLoaded) {
            setMapLoaded(true)

            mapboxgl.accessToken = TOKEN
            const map = new mapboxgl.Map({
                container: 'mapShow', // container id
                style: `mapbox://styles/${STYLE_ID}`, // stylesheet location
                center: [kot.longitude, kot.latitude], // starting position [lng, lat]
                zoom: 13 // starting zoom
            })

            const elShow = document.createElement('div')
            elShow.insertAdjacentHTML('beforeend', '<div class="marker"></div>')
            map.addControl(new mapboxgl.NavigationControl())

            new mapboxgl.Marker(elShow).setLngLat([kot.longitude, kot.latitude]).addTo(map)

            map.scrollZoom.disable()
        }
    }

    return (
        <Box className={classes.kotShowMapContainer}>
            <Box className={classes.mapContainer}>
                <Box className={`${classes.titleSection}`}>
                    <KotShowSectionTitle textId='landing_card_kot_page.localisation' />
                </Box>
                <Box className={classes.kotLocalisationContainer} onClick={handleClickMap}>
                    <Box className={classes.kotIconContainer}>
                        <Box className={classes.mapIcon}>
                            <IconFactory icon='marker' />
                        </Box>
                    </Box>
                    <Typography className={classes.address}>
                        {kot.city.toLowerCase()} {kot.zip}, {kot.street.toLowerCase()}
                    </Typography>
                </Box>
                <Box className={classes.loadMap} onClick={handleClickMap}>
                    <Box id='mapShow' className={classes.mapShow}>
                        {!mapLoaded && <FormattedMessage id='landing_card_kot_page.display_map' />}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
export default KotShowMap
