import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    moreFilterButton: {
        position: 'relative',
        width: '63px',
        minWidth: '63px',
        height: '49px',
        padding: '0px',
        backgroundColor: theme.palette.bg['black'],
        borderRadius: '80px',
        border: `1px solid ${theme.palette.fg['black']}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: theme.palette.bg['black'],
        },
    },

    filterIcon: {
        width: '18px',
        height: '18px'
    },

    appliedFiltersCount: {
        position: 'absolute',
        backgroundColor: theme.palette.bg['white'],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.fg['black'],
        fontSize: 14,
        borderRadius: 50,
        width: 20,
        height: 18,
        top: -5,
        right: -5,
        zIndex: 1,
    },
}))
