import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    titleBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            marginBottom: 16
        }
    },
    iconTitleBox: {
        display: 'flex',
        alignItems: 'center',
        gap: 12
    },
    iconBubble: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 50,
        width: 24,
        height: 24
    },
    arrowBubble: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 50,
        width: 28,
        height: 28
    },
    arrowText: {
        fontSize: 18
    },
    title: {
        fontSize: 24,
        display: 'block !important',
        lineHeight: 'normal',
        '&:first-letter': {
            textTransform: 'uppercase'
        }
    },
    seeMore: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 8,
        cursor: 'pointer',
        color: 'black'
    },
    seeMoreMobile: {
        marginTop: 32,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 8,
        cursor: 'pointer',
        color: 'black'
    },
    seeMoreBox: {
        borderRadius: 16,
        '&:hover': {
            '& div': {
                color: 'var(--foreground-purple) !important'
            },
            '& span': {
                color: 'var(--foreground-purple) !important'
            },
            '& div > div': {
                backgroundColor: 'var(--foreground-purple) !important'
            }
        }
    }
}))
