/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import KotListCardAlt from 'cards/KotListCardAlt/KotListCardAlt'
import Box from '@material-ui/core/Box'
import MyKotIndexSearchBar from './MyKotIndexSearchBar'
import { FormattedMessage } from 'react-intl'

const MyKotSelectionIndexPage = ({ props }) => {
    const [allKot, setAllKot] = useState(props.myItemSelection)
    return (
        <>
            <Container maxWidth='lg'>
                <Box mb={15}>
                    <MyKotIndexSearchBar allKot={allKot} setAllKot={setAllKot} />
                    {allKot.length > 0
                        ? <Grid container spacing={3}>
                            <Grid item xs={12} md={9} xl={9}>
                                <Grid container spacing={3}>
                                    {allKot.map(
                                        (kot, key) => {
                                            return <KotListCardAlt
                                                key={kot.id.toString()}
                                                kot={kot}
                                                timer={key}
                                                type={props.type}
                                            />
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        : <FormattedMessage id="landing_about_pages.card_container" />
                    }
                </Box>
            </Container>
        </>
    )
}
export default MyKotSelectionIndexPage
