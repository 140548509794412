/* eslint-disable react/display-name */
import React from 'react'

import ApplyPopupComponent from './ApplyPopupComponent'

export default (props) => {
    return (
        <ApplyPopupComponent {...props} />
    )
}
