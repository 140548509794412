import React, { useState, useEffect, useRef, useContext } from 'react'
import UserAPI from 'api/UserAPI'
import ProfileEditFormHelper from '../../helpers/ProfileEditFormHelper'
import ProfileEditFormStepLocationView from './ProfileEditFormStepLocationView'
import { UserContext } from 'contexts/UserContext'
import AnalyticsHelper from 'assets/AnalyticsHelper'

const ProfileEditFormStepLocation = (props) => {
    const { shortProfile, setShortProfile, step, nextStep, previousStep, highlightInputField } = props
    const [cityRef] = Array.from({ length: 2 }, () => useRef(null))
    const [errors, setErrors] = useState({ city: false })
    const [loading, setLoading] = useState(false)
    const userContext = useContext(UserContext)

    const validateStepLocation = () => {
        const newErrors = { city: cityRef.current?.value === '' }

        setErrors(newErrors)
        return ProfileEditFormHelper.objectHasTrueValue(newErrors)
    }

    const atLeastOneErrorPresent = () => { return ProfileEditFormHelper.objectHasTrueValue(errors) }

    useEffect(() => {
        if (atLeastOneErrorPresent()) {
            validateStepLocation()
        }
    }, [shortProfile])

    const handleSubmit = (event) => {
        if (validateStepLocation()) return false
        setLoading(true)

        const data = { city: shortProfile.city }

        UserAPI.UpdateCity(data)
            .then((response) => {
                setLoading(false)
                nextStep('Personal Dashboard step 5 location')
                userContext.setUser({
                    ...userContext.user,
                    city: shortProfile.city,
                    address: response.data.address
                })

                AnalyticsHelper.sendGTMEvent(
                    'Account Updated',
                    AnalyticsHelper.constructAccountUpdatedAnalyticsAttributes(
                        userContext.user,
                        userContext.user,
                        userContext.user.current_experience,
                        userContext.user.profile.birth_date || new Date(),
                        'Personal Dashboard ProfileEditFormStepLocation step 5'
                    )
                )
            })
    }

    const handleChange = (event) => {
        setShortProfile({ ...shortProfile, city: event.target.value })
    }

    return (
        <ProfileEditFormStepLocationView
            step={step}
            handleSubmit={handleSubmit}
            previousStep={previousStep}
            city={shortProfile.city}
            handleChange={handleChange}
            loading={loading}
            cityRef={cityRef}
            errors={errors}
            highlightInputField={highlightInputField}
        />
    )
}
export default ProfileEditFormStepLocation
