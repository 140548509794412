import React from 'react'

import { useStyles } from './KotFiltersView_style'
import { Box, Hidden, Divider } from '@material-ui/core'

import CityFilter from '../shared/CityFilter/CityFilter'
import MultipleSelectFilter from '../shared/MultipleSelectFilter/MultipleSelectFilter'
import CollectionHandler from 'assets/CollectionHandler.js'
import KotFiltersModal from 'modals/FilterModals/KotFiltersModal/KotFiltersModal'
import MoreFilters from '../shared/MoreFilters/MoreFilters'
import ResetFilters from '../shared/ResetFilters/ResetFilters'
import FiltersViewWrapper from 'shared/components/FiltersViewWrapper/FiltersViewWrapper.jsx'


const KotFiltersView = (props) => {
    const { offerCount, debouncedFilterChange, handleFilterChange, filters, setFilters, reset, handleInputSearchCity, searchCities, setSearchCities, citiesInput, setCitiesInput, showKotFiltersModal, setShowKotFiltersModal, selectedLocationTag, type } = props
    const classes = useStyles()

    const kotDisponibilityCollection = CollectionHandler.Get('kotDisponibilityCollection')
    const kotPriceCollection = CollectionHandler.Get('kotPriceCollection')
    const kotRoomCountCollection = CollectionHandler.Get('kotRoomCountCollection')
    const kotTypeCollection = CollectionHandler.Get('kotTypeCollection')

    return (
        <>
            <FiltersViewWrapper titleId='item_offers.kot.page_title_v2'>
                <KotFiltersModal
                    showModal={showKotFiltersModal}
                    setShowModal={setShowKotFiltersModal}
                    debouncedFilterChange={debouncedFilterChange}
                    handleFilterChange={handleFilterChange}
                    filters={filters}
                    offerCount={offerCount}
                    handleInputSearchCity={handleInputSearchCity}
                    searchCities={searchCities}
                    citiesInput={citiesInput}
                    setSearchCities={setSearchCities}
                    setCitiesInput={setCitiesInput}
                    setFilters={setFilters}
                    reset={reset}
                />
                <Box className={classes.firstFiltersRow}>
                    <Box className={classes.cityFieldContainer}>
                        <CityFilter
                            handleInputSearchCity={handleInputSearchCity}
                            searchCities={searchCities}
                            citiesInput={citiesInput}
                            setSearchCities={setSearchCities}
                            setCitiesInput={setCitiesInput}
                            setFilters={setFilters}
                            filters={filters}
                            width={350}
                        />
                    </Box>
                    <Hidden xsDown>
                        <Box className={classes.kotTypeFieldContainer}>
                            <MultipleSelectFilter
                                type='kotType'
                                collection={kotTypeCollection}
                                filters={filters}
                                setFilters={setFilters}
                                width={250}
                                filterTextId='search_bar.kot_type.title'
                            />
                        </Box>
                        <Box className={classes.disponibilityFieldContainer}>
                            <MultipleSelectFilter
                                type='disponibility'
                                collection={kotDisponibilityCollection}
                                filters={filters}
                                setFilters={setFilters}
                                width={400}
                                filterTextId='search_bar.disponibility.title'
                            />
                        </Box>
                    </Hidden>
                    <Hidden mdDown>
                        <Box className={classes.priceFieldContainer}>
                            <MultipleSelectFilter
                                type='price'
                                collection={kotPriceCollection}
                                filters={filters}
                                setFilters={setFilters}
                                width={250}
                                filterTextId='search_bar.price.title'
                            />
                        </Box>
                        <Box className={classes.roomCountFieldContainer}>
                            <MultipleSelectFilter
                                type='roomCount'
                                collection={kotRoomCountCollection}
                                filters={filters}
                                setFilters={setFilters}
                                width={250}
                                filterTextId='search_bar.rooms.title'
                            />
                        </Box>
                    </Hidden>
                    <Box className={classes.moreFiltersButtonContainer}>
                        <MoreFilters
                            setShowModal={setShowKotFiltersModal} filters={filters}
                        />
                    </Box>
                    <Hidden smDown>
                        <Box className={classes.resetFiltersButtonContainer}>
                            <ResetFilters reset={reset} />
                        </Box>
                    </Hidden>
                </Box>
            </FiltersViewWrapper>
            <Divider className={classes.divider} />
        </>
    )
}

export default KotFiltersView
