import React from 'react'

const FireSVG = (props) => (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78 86">
        <g id="Calque_2">
            <g id="Calque_1-2">
                <path d="M78,48.1c-.1-1.75-.32-3.48-.64-5.2-.69-3.74-2-7.34-3.88-10.65-2.42-4.29-5.31-8.3-8.62-11.94-1.22-1.34-2.46-2.56-3.65-3.73l-1.1-1.07c-.43-.43-.88-.83-1.34-1.23l-.39-.35c-2.92-2.63-7.32-2.74-10.38-.27l-.47.38-.2-.2c-1.31-1.31-2.64-2.6-4-3.89l-1.69-1.67c-3.05-2.96-7.88-3.03-11-.15l-.72.67c-.39.36-.75.71-1.14,1-3.51,3.12-6.8,6.5-9.83,10.09-2.91,3.46-5.51,7.18-7.75,11.11-2.09,3.48-3.6,7.28-4.47,11.25-.52,2.27-.76,4.59-.73,6.91.06,1.78.22,3.55.47,5.31.52,3.7,1.61,7.3,3.25,10.66,2.48,5.2,6.13,9.77,10.66,13.33,3.55,2.83,7.63,4.92,12,6.17,4.35,1.27,8.91,1.67,13.42,1.15,3.63-.4,7.16-1.37,10.48-2.89,5.24-2.41,9.85-6.02,13.45-10.53,2.47-3.06,4.44-6.51,5.81-10.2,1.14-3.08,1.89-6.29,2.22-9.55v-.35c.18-1.38.26-2.77.24-4.16h0Z"/>
                <path d="M72,42.1c-.11-1.74-.32-3.48-.64-5.2-.69-3.74-2-7.34-3.88-10.65-2.42-4.29-5.31-8.3-8.62-11.94-1.22-1.34-2.46-2.56-3.65-3.73l-1.1-1.07c-.43-.43-.88-.83-1.34-1.23l-.39-.35c-2.92-2.63-7.32-2.74-10.38-.27l-.47.34-.2-.2c-1.33-1.27-2.64-2.56-3.97-3.8l-1.72-1.72c-3.05-2.96-7.88-3.03-11-.15l-.72.67c-.39.36-.75.71-1.14,1.05-3.51,3.12-6.8,6.5-9.83,10.09-2.91,3.45-5.5,7.15-7.75,11.06-2.09,3.48-3.6,7.28-4.47,11.25C.21,38.52-.03,40.84,0,43.16c.06,1.78.22,3.55.47,5.31.52,3.7,1.61,7.3,3.25,10.66,2.48,5.2,6.13,9.77,10.66,13.33,3.55,2.83,7.63,4.92,12,6.17,4.35,1.27,8.91,1.67,13.42,1.15,3.63-.4,7.16-1.37,10.48-2.89,5.24-2.41,9.85-6.02,13.45-10.53,2.47-3.06,4.44-6.51,5.81-10.2,1.14-3.08,1.89-6.29,2.22-9.55v-.35c.18-1.38.26-2.77.24-4.16Z"/>
                <path d="M30.05,8c-.72.67-1.34,1.27-2,1.84-3.22,2.87-6.23,5.97-9,9.27-2.6,3.08-4.91,6.4-6.9,9.9-1.65,2.75-2.86,5.74-3.58,8.87-.39,1.64-.57,3.32-.55,5,.05,1.5.18,3,.39,4.49.41,2.87,1.26,5.67,2.53,8.28,1.96,4.11,4.83,7.71,8.41,10.52,7.82,6.22,18.47,7.55,27.58,3.44,4.12-1.9,7.74-4.72,10.57-8.26,1.93-2.4,3.46-5.11,4.53-8,.91-2.44,1.51-4.98,1.77-7.57.14-1.08.21-2.18.2-3.27-.09-1.4-.26-2.79-.52-4.17-.54-2.87-1.56-5.62-3-8.16-2.13-3.76-4.68-7.28-7.58-10.49-1.42-1.56-3-3-4.46-4.5-.45-.45-1-.86-1.44-1.3-2.1,1.56-4.05,3.31-5.83,5.23-1.85-1.89-3.66-3.77-5.51-5.62s-3.66-3.65-5.61-5.5Z" fill="#fff"/>
            </g>
        </g>
    </svg>
)

export default FireSVG
