import React from 'react'

import { useStyles } from './Switch_style'
import { Switch as NativeSwitch } from '@material-ui/core';

const Switch = (props) => {
    const classes = useStyles()

    return (
        <NativeSwitch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                input: classes.input,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,

            }}
            {...props}
        />
    )
}

export default Switch