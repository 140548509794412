import React from 'react'
import { Helmet } from 'react-helmet'
import RouteMap from 'assets/RouteMap'
import DefaultHelmet from './DefaultHelmet'

class HomeMyBSHelmet extends React.Component {
    render () {
        return <>
            <DefaultHelmet
                title='Meet top business schools at the myBS event'
                description="MyBS is the biggest business school event of Belgium. This is your chance to meet the world's top business schools from the most popular destinations."
                imageCloudinaryKey='my_bs/my-business-school-event-student-be'
            />
            <Helmet>
                <meta property='og:type' content='website' />
                <meta property="og:url" content={`https://www.student.be${window.location.pathname}`} />
                <link rel="canonical" href={RouteMap.Page('mybusinessschoolevent', 'en')} />
                <link rel="alternate" hrefLang="en-BE" href={RouteMap.Page('mybusinessschoolevent', 'en')} />
                <link rel="alternate" hrefLang="x-default" href={RouteMap.Page('mybusinessschoolevent', 'en')} />
            </Helmet>
        </>
    }
}

export default HomeMyBSHelmet
