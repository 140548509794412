import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import Button from 'shared/components/Button/Button'
import ProgressCircle from 'components/ProgressCircle/ProgressCircle'

import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import DialogActions from '@material-ui/core/DialogActions'
import PaymentCard from 'containers/PaymentCard/PaymentCard'
import CollectionHandler from 'assets/CollectionHandler'

const BoostCompanyJobModalStep2View = (props) => {
    const { closeModal, loading, user, buyCredits, selectedPack, setSelectedPack } = props

    const studentJobPackages = CollectionHandler.Get('studentJobBoostPackagesCollection')

    return (<>
        {loading && <ProgressCircle />}
        {!loading && <>
            <DialogContent fullWidth>
                <Grid container spacing={2}>
                    {
                        studentJobPackages.map((key, index) => {
                            return (
                                <Grid item mx={2} xs={12} lg={12 / studentJobPackages.length} key={index}>
                                    <Box mx={1}>
                                        <PaymentCard
                                            credits={0}
                                            selectedPackage={studentJobPackages[index]}
                                            index={index}
                                            startPayment={buyCredits}
                                            type='offer'
                                            setPackage={setSelectedPack}
                                            selectedPack={selectedPack}
                                            user={user}
                                            buyingCredits
                                        />
                                    </Box>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='secondary' size='small' onClick={closeModal}>
                    <FormattedMessage id="delete_offer_popup.option_no" />
                </Button>
            </DialogActions>
        </>}
    </>)
}

export default injectIntl(BoostCompanyJobModalStep2View)
