import React from 'react'

const ImpactSVG = (props) => (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84.84 84.84" {...props}>
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <circle cx="45.42" cy="45.42" r="39.42" />
                <path d="M15.82,33.83Z" />
                <path className="cls-1" fill="white" d="M15.82,33.83Z" />
                <circle cx="39.42" cy="39.42" r="39.42" />
                <path d="M9.82,27.83Z" />
                <path className="cls-1" fill="white"
                    d="M49.62,27.82A55.56,55.56,0,0,0,39.4,8C35,14.08,31.16,20.46,29.23,27.82Z" />
                <path className="cls-1" fill="white"
                    d="M54.69,31.86H70.43a32.7,32.7,0,0,1,0,16H54.69A43.31,43.31,0,0,0,54.69,31.86Z" />
                <path className="cls-1" fill="white" d="M28.17,47.82a43.63,43.63,0,0,1,0-15.95H50.66a42.78,42.78,0,0,1,0,16Z" />
                <path className="cls-1" fill="white"
                    d="M24.14,31.87H8.41a32.62,32.62,0,0,0,0,16H24.14A43.63,43.63,0,0,1,24.14,31.87Z" />
                <path className="cls-1" fill="white"
                    d="M39.39,71.69C35,65.61,31.16,59.23,29.23,51.87H49.61A55.24,55.24,0,0,1,39.39,71.69Z" />
                <path className="cls-1" fill="white"
                    d="M34.23,71.43A31.84,31.84,0,0,1,9.83,51.86H24.66c.3,0,.4.1.47.37a56.91,56.91,0,0,0,3.79,10.26A68,68,0,0,0,34,71l.16.24.11.16Z" />
                <path className="cls-1" fill="white" d="M9.82,27.83Z" />
                <path className="cls-1" fill="white"
                    d="M9.82,27.83A31.87,31.87,0,0,1,34.22,8.27l-.44.66h0c-.33.51-.67,1-1,1.51A55.64,55.64,0,0,0,27.4,20.66c-.64,1.69-1.19,3.41-1.73,5.14-.18.55-.35,1.1-.53,1.65-.1.3-.21.39-.52.39H9.82Z" />
                <path className="cls-1" fill="white"
                    d="M69,51.86A32.3,32.3,0,0,1,44.61,71.44l.2-.3h0l.48-.73a66.13,66.13,0,0,0,5.25-9.23,57.62,57.62,0,0,0,3.12-8.84c.1-.37.23-.48.62-.48H69Z" />
                <path className="cls-1" fill="white"
                    d="M68.66,27.83H69a31.29,31.29,0,0,0-9.69-12.94A32.3,32.3,0,0,0,44.58,8.23l.07.11.14.2.68,1.05c.52.78,1,1.56,1.53,2.36a56.4,56.4,0,0,1,3.88,7.33,61.23,61.23,0,0,1,2.83,8.21.37.37,0,0,0,.44.35H68.66Z" />
            </g>
        </g>
    </svg>
)

export default ImpactSVG
