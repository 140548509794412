/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import TagMultiSelectView from './TagMultiSelectView'

const TagMultiSelect = (props) => {
    const { setSelectedItems, selectedItems, collection, enableSelectAll, width } = props
    const [selectedAll, setSelectedAll] = useState(false)

    const handleTagChange = (item) => {
        let newSelectedItems = []
        if (selectedItems.some(selectedItem => selectedItem.value === item.value)) {
            newSelectedItems = selectedItems.filter(selectedItem => selectedItem.value !== item.value)
        } else {
            newSelectedItems = [...selectedItems, item]
        }
        setSelectedItems(newSelectedItems)
    }

    const handleToggle = () => {
        selectedAll ? setSelectedItems([]) : setSelectedItems(collection)
        setSelectedAll(!selectedAll)
    }

    return (
        <TagMultiSelectView
            handleChange={handleTagChange}
            selectedItems={selectedItems}
            collection={collection}
            enableSelectAll={enableSelectAll}
            selectedAll={selectedAll}
            handleToggle={handleToggle}
            width={width}
        />
    )
}

export default TagMultiSelect
