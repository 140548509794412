import React from 'react'

import { useStyles } from '../../ExperienceProfile_style'
import { InputAdornment, useTheme } from '@material-ui/core'

import IconFactory from 'icons/IconFactory/IconFactory'

const EndAdornment = (props) => {
    const classes = useStyles()
    const theme = useTheme()

    return (
        <InputAdornment position='end' className={classes.adornmentContainer}>
            <IconFactory icon='search' fontSize='1rem' color={theme.palette.fg['black']} />
        </InputAdornment>
    )
}

export default EndAdornment
