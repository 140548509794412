/* eslint-disable react/prop-types */
import React from 'react'

/* eslint-disable-next-line no-unused-vars */
import css from './showdocumentpopup.scss'

import { Image } from 'cloudinary-react'

import IconFactory from '../../../../shared/icons/IconFactory/IconFactory'

export default class ShowDocumentPopup extends React.Component {
    constructor (props) {
        super(props)
        console.log(props.documentId)
    }

    render () {
        return (
            <div className="popup-container show-document-popup-container">
                <div className="popup-content show-document">
                    <div className="cross-div"><IconFactory className="exit-icon" icon='close' onClick={this.props.popupExit}/></div>
                    <Image cloudName="studentbe" publicId={this.props.documentId} width="300" crop="scale" />
                </div>
            </div>

        )
    }
}
