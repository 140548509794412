import React, { useState } from 'react'

import ConversationAPI from 'api/ConversationAPI'
import MessageAllModalView from './MessageAllModalView'

const MessageAllModal = (props) => {
    const {
        items,
        openShare,
        popupExit
    } = props

    const [message, setMessage] = useState('')

    const closeModal = () => {
        popupExit()
    }

    const handleMessageChange = (event) => {
        setMessage(event.target.value)
    }

    const sendMessageToSelected = () => {
        const conversations = []
        Object.keys(items).forEach((conv) => {
            const item = {
                conv_id: items[conv].conversation_id,
                user_id: items[conv].user_id
            }
            conversations.push(item)
        })

        ConversationAPI.SendMessageToSelection({
            candidacies: conversations,
            message: message
        }).then((_) => {
            popupExit()
        })
    }

    return (
        <MessageAllModalView
            closeModal={closeModal}
            handleMessageChange={handleMessageChange}
            message={message}
            openShare={openShare}
            sendMessageToSelected={sendMessageToSelected}
        />
    )
}

export default MessageAllModal
