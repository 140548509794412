
import React from 'react'
import StudentMessageCard from '../MessagesCard/StudentMessageCard'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { FormattedMessage } from 'react-intl'

export default class MessagesStudentIndex extends React.Component {
    render () {
        if (!this.props.candidacies) { return null }
        let i = 0
        const studentMessageCards = this.props.candidacies.map((candidacy) => {
            i++
            return (
                <StudentMessageCard
                    key={`candidacy-${i}`}
                    candidacy={candidacy}
                />
            )
        })

        return (
            <div className="kot-owner-index-main">
                {studentMessageCards}
                {!this.props.lastPage && this.props.candidacies > 0 &&
                    <Box mt={2}>
                        <Button
                            color={'primary'}
                            variant={'contained'}
                            fullWidth={true}
                            onClick={this.props.loadMore}
                        >
                            <FormattedMessage id="item_index.show_more_button" />
                        </Button>
                    </Box>
                }
            </div>
        )
    }
}
