import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 20
    },
    card: {
        display: 'flex',
        '& div': {
            boxShadow: 'none'
        },
        '& iframe': {
            borderRadius: 20,
            borderWidth: 0
        }
    },
    arrowLeft: {
        left: 0,
        zIndex: 2,
        position: 'absolute',
        top: '60%',
        [theme.breakpoints.down('lg')]: {
            left: 10
        }
    },
    arrowRight: {
        right: 0,
        top: '60%',
        zIndex: 2,
        position: 'absolute',
        [theme.breakpoints.down('lg')]: {
            right: 10
        }
    },
    arrowBtn: {
        height: 40,
        width: 40,
        backgroundColor: 'var(--background-black)',
        borderRadius: '50%',
        border: '2px solid var(--foreground-black)',
        '&:hover': {
            backgroundColor: 'var(--background-white)',
            transition: 'all 0.3s ease',
            boxShadow: `4px 4px 0px 0px ${theme.palette.bg.black}`,
            '& svg': {
                color: 'var(--foreground-black)'
            }
        }
    },
    arrows: {
        color: 'var(--foreground-white)'
    },
    disabled: {
        backgroundColor: 'var(--light-grey) !important',
        opacity: 0.5
    }
}))
