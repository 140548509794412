import React from 'react'

const TotebagShoppingSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78.66 86" {...props}>
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path d="M70.66,26H64.38l-.06-.58A21.17,21.17,0,0,0,58.53,13,21.51,21.51,0,0,0,45,6.16a21.81,21.81,0,0,0-18,5.92A21.09,21.09,0,0,0,20.29,26H14a8,8,0,0,0-8,8V78a8,8,0,0,0,8,8H70.66a8,8,0,0,0,8-8V34A8,8,0,0,0,70.66,26Z" />
                <path d="M64.66,20H58.38l-.06-.58A21.23,21.23,0,0,0,52.53,7,21.53,21.53,0,0,0,39,.16,21.81,21.81,0,0,0,21,6.08,21,21,0,0,0,14.29,20H8a8,8,0,0,0-8,8V72a8,8,0,0,0,8,8H64.66a8,8,0,0,0,8-8V28A8,8,0,0,0,64.66,20Z" />
                <path fill="white" d="M50.5,28H64.66V72H8V28H22.14v-.69c0-2.17,0-4.33.11-6.49a13,13,0,0,1,4.28-8.95,13.86,13.86,0,0,1,11.6-3.78,13.69,13.69,0,0,1,8.59,4.38,13.34,13.34,0,0,1,3.65,7.83c.15,1.32.11,2.65.13,4S50.5,26.7,50.5,28Zm-6,0c0-1.51,0-3,0-4.41a19,19,0,0,0-.14-2.83,8.17,8.17,0,0,0-9.25-6.67,8.08,8.08,0,0,0-6.74,7c-.17,2.25-.09,4.51-.12,6.76a1,1,0,0,0,.05.18Zm3,12a3,3,0,0,0,3-3,3,3,0,0,0-6.06,0A3,3,0,0,0,47.48,40ZM28.22,37a2.94,2.94,0,0,0-3.06-3,2.79,2.79,0,0,0-3,3,2.81,2.81,0,0,0,3,3,3,3,0,0,0,3-3Z" />
            </g>
        </g>
    </svg>
)

export default TotebagShoppingSVG
