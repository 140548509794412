import React from 'react'

import { useStyles } from './FunnelOverviewWrapper_style'
import { Box } from '@material-ui/core'

const FunnelOverviewWrapper = ({children}) => {
    const classes = useStyles()
    return (
		<Box className={classes.funnelOverview}>
            {children}
        </Box>
    )
}

export default FunnelOverviewWrapper
