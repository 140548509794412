import React from 'react'
import { Box } from '@material-ui/core'
import StudyExperienceBoxItemLine from './StudyExperienceBoxItemLine'
import { useStyles } from './StudyExperienceBox_style'
import CollectionHandler from 'assets/CollectionHandler'

const StudyExperienceBox = (props) => {
    const { user, openProfileEditFormOnStep } = props
    const classes = useStyles()

    const getStudyFieldTranslationText = () => {
        let labelText = CollectionHandler.Translate('academicAndHighSchoolStudyFieldCollection', user.current_experience?.field_of_study?.value)

        if (labelText === '/') labelText = CollectionHandler.Translate('academicAndHighSchoolStudyFieldCollection', user.current_experience?.other_field_of_study)
        if (labelText === '/') labelText = user.current_experience.study_field

        return labelText
    }

    const getStudyTypeTranslationText = () => {
        let labelText = CollectionHandler.Translate('academicAndHighSchoolStudyTypeCollection', user.current_experience?.study_type)

        if (labelText === '/') labelText = user.current_experience.study_type

        return labelText
    }

    return (
        <Box className={classes.itemLineBox}>
            <StudyExperienceBoxItemLine
                title='personal_dashboard.study_experience_box.studies'
                text={getStudyFieldTranslationText()}
                onClick={() => openProfileEditFormOnStep(2)}
            />
            <StudyExperienceBoxItemLine
                title='personal_dashboard.study_experience_box.study_level'
                text={getStudyTypeTranslationText()}
                onClick={() => openProfileEditFormOnStep(2)}
            />
            <StudyExperienceBoxItemLine
                title='personal_dashboard.study_experience_box.city'
                text={user.city}
                onClick={() => openProfileEditFormOnStep(4)}
            />
        </Box>
    )
}
export default StudyExperienceBox
