/* eslint-disable react/prop-types */
import React from 'react'
import { useInView } from 'react-intersection-observer'

import { useStyles } from './KotShowBanner_style'
import Hidden from '@material-ui/core/Hidden'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import KotShowBannerDesktop from './components/KotShowBannerDesktop'
import KotShowBannerDesktopSticky from './components/KotShowBannerDesktopSticky'
import KotShowBannerMobile from './components/KotShowBannerMobile'
import KotShowBannerMobileSticky from './components/KotShowBannerMobileSticky'

const KotShowBanner = (props) => {
    const { kot, unavailable, date, renderApplyBox, user } = props
    const classes = useStyles()
    const { ref, inView } = useInView({})

    return (
        <>
            <Box className={classes.kotShowBanner}>
                <Container maxWidth='lg' >
                    <Hidden smDown>
                        {inView
                            ? <KotShowBannerDesktop kot={kot} unavailable={unavailable} date={date} renderApplyBox={renderApplyBox} user={user}/>
                            : <KotShowBannerDesktopSticky kot={kot} unavailable={unavailable} renderApplyBox={renderApplyBox}/>
                        }
                    </Hidden>
                    <Hidden mdUp>
                        {inView
                            ? <KotShowBannerMobile kot={kot} unavailable={unavailable} date={date} renderApplyBox={renderApplyBox} user={user}/>
                            : <KotShowBannerMobileSticky kot={kot} unavailable={unavailable} date={date} renderApplyBox={renderApplyBox}/>
                        }
                    </Hidden>
                </Container>
            </Box>
            <Box ref={ref} />
        </>
    )
}

export default KotShowBanner
