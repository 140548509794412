import React from 'react'
import { Box, Container, Grid, Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { Image, Transformation } from 'cloudinary-react'

import { useStyles } from './JobSearchStatusResponse_style'
import PageWrapper from 'shared/wrappers/PageWrapper/PageWrapper'
import FilledButton from 'buttons/FilledButton/FilledButton'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import PreviewCard from 'shared/components/PreviewCard/PreviewCard'

const JobSearchStatusResponse = (props) => {
    const classes = useStyles()
    const { jobSearchStatus, user, recentFirstJobs } = props

    return (
        <PageWrapper user={user}>
            <Container className={classes.main}>
                <Box className={classes.text}>
                    <Typography variant='h1' className={classes.title}>
                        <FormattedMessage id='job_search_status_response.title'/>
                    </Typography>
                    <FormattedMessage id={`job_search_status_response.context.${jobSearchStatus}`}>
                        {translatedText => <Typography dangerouslySetInnerHTML={{ __html: translatedText }} className={classes.status}/>}
                    </FormattedMessage>
                    <Typography className={classes.cta}>
                        <FormattedMessage id={'job_search_status_response.cta.for_all'} />
                    </Typography>
                    <Box my={2}>
                        <RouteMapLink page={user ? 'users/profile/edit' : 'login'}>
                            <FilledButton
                                color='secondary'
                                name={<FormattedMessage id={'job_search_status_response.button.actively_looking'} />}
                            />
                        </RouteMapLink>
                    </Box>
                </Box>
                <Box className={classes.image}>
                    <Image
                        cloudName="studentbe"
                        publicId='assets/clapping_slow.gif'
                        alt='congrats status'
                    >
                        <Transformation auto='eco'/>
                    </Image>
                </Box>
            </Container>
            <Container className={classes.firstJobs}>
                <Grid item xs={12}>
                    {recentFirstJobs.length > 0 &&
                        <PreviewCard
                            cards={recentFirstJobs}
                            type='normal'
                            edit={false}
                            title={<FormattedMessage id='transl_internship.job_show.preview_card_2_title' />}
                            subTitle={<FormattedMessage id='transl_internship.job_show.preview_card_2_subtitle' />}
                            userType={user?.type}
                            fullWidth
                        />
                    }
                </Grid>
            </Container>
        </PageWrapper>
    )
}

export default JobSearchStatusResponse
