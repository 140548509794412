import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        top: 12,
        right: 12,
        zIndex: 10,
        borderRadius: 20,
        width: 'fit-content',
        color: theme.palette.fg.white,
        backgroundColor: theme.palette.bg.black,
        padding: '4px 10px',
        [theme.breakpoints.down('sm')]: {
            top: 18
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    }
}))
