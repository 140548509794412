import React, { useState, useContext } from 'react'
import ProfileEditFormView from './ProfileEditFormView'
import ProfileEditFormHelper from './helpers/ProfileEditFormHelper'
import { UserContext } from 'contexts/UserContext'
import AnalyticsHelper from 'assets/AnalyticsHelper'

const ProfileEditForm = (props) => {
    const { setShowProfileEditForm, step, setStep, highlightInputField } = props
    const userContext = useContext(UserContext)
    const [shortProfile, setShortProfile] = useState(userContext.user)

    const setExperiences = (newExperiences) => {
        setShortProfile({ ...shortProfile, experiences: newExperiences })
    }

    const handleStepChange = (event, value) => {
        if (value !== ProfileEditFormHelper.MAX_STEP) setStep(value)
    }

    const nextStep = (analId) => {
        AnalyticsHelper.sendGTMEvent(analId)

        if ((step + 1) !== ProfileEditFormHelper.MAX_STEP) setStep(step + 1)
        if ((step + 1) === ProfileEditFormHelper.MAX_STEP) setShowProfileEditForm(false)
    }

    const previousStep = () => {
        if (step !== 0) setStep(step - 1)
    }

    return (
        <ProfileEditFormView
            shortProfile={shortProfile}
            setShortProfile={setShortProfile}
            setExperiences={setExperiences}
            step={step}
            nextStep={nextStep}
            previousStep={previousStep}
            handleStepChange={handleStepChange}
            highlightInputField={highlightInputField}
        />
    )
}
export default ProfileEditForm
