/* eslint-disable react/prop-types */
import React from 'react'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import Field from 'inputs/Field/Field'
import UploadImage from 'modals/UploadImage/UploadImage'
import ResponsiveVideo from 'components/ResponsiveVideo/ResponsiveVideo'

import { FormattedMessage } from 'react-intl'

import { withStyles } from '@material-ui/core/styles'
import { useStyles } from './CompanyProfileFormImage_style'

const CompanyProfileFormImage = (props) => {
    const { handleChange, handleVideoChange, handleImagesChange } = props
    const { mainShowVideo, companyImages, mainVideoUrlEn, mainVideoUrlFr, mainVideoUrlNl } = props.company

    const renderImageForm = () => {
        return (
            <Box>
                <UploadImage
                    imageArray={companyImages}
                    setImageArray={handleImagesChange}
                    maxPictures={1}
                />
            </Box>
        )
    }

    const renderVideoForm = () => {
        return (
            <Box>
                <Box my={2}>
                    <Typography variant='subtitle1'>
                        video (EN)
                    </Typography>
                    <FormattedMessage id="company_profile.company_page_description.video_manager_pop_up.video_form_placeholder">
                        {placeholder =>
                            <Field
                                name="mainVideoUrlEn"
                                value={mainVideoUrlEn}
                                type="input"
                                placeholder={placeholder}
                                handleChange={handleChange}
                            />
                        }
                    </FormattedMessage>
                    {mainVideoUrlEn &&
                        <Box>
                            <ResponsiveVideo video={{ url: mainVideoUrlEn }} />
                        </Box>
                    }
                </Box>
                <Box my={2}>
                    <Typography variant='subtitle1'>
                        video (FR)
                    </Typography>
                    <FormattedMessage id="company_profile.company_page_description.video_manager_pop_up.video_form_placeholder">
                        {placeholder =>
                            <Field
                                name="mainVideoUrlFr"
                                value={mainVideoUrlFr}
                                type="input"
                                placeholder={placeholder}
                                handleChange={handleChange}
                            />
                        }
                    </FormattedMessage>
                    {mainVideoUrlFr &&
                        <Box>
                            <ResponsiveVideo video={{ url: mainVideoUrlFr }} />
                        </Box>
                    }
                </Box>
                <Box my={2}>
                    <Typography variant='subtitle1'>
                        video (NL)
                    </Typography>
                    <FormattedMessage id="company_profile.company_page_description.video_manager_pop_up.video_form_placeholder">
                        {placeholder =>
                            <Field
                                name="mainVideoUrlNl"
                                value={mainVideoUrlNl}
                                type="input"
                                placeholder={placeholder}
                                handleChange={handleChange}
                            />
                        }
                    </FormattedMessage>
                    {mainVideoUrlNl &&
                        <Box>
                            <ResponsiveVideo video={{ url: mainVideoUrlNl }} />
                        </Box>
                    }
                </Box>
            </Box>
        )
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant='h4' component='h3' color='primary'>
                    <FormattedMessage id="company_profile.company_page_description.description_popup.description_image_or_video" />
                </Typography>
                <Typography variant='subtitle1'>
                    <FormattedMessage id="company_profile.company_page_description.video_manager_pop_up.description" />
                </Typography>

                <FormattedMessage id="company_profile.company_page_description.video_manager_pop_up.video_or_image">
                    {placeholder =>
                        <Field
                            name="mainShowVideo"
                            value={mainShowVideo}
                            type="radio"
                            placeholder={placeholder}
                            handleChange={handleVideoChange}
                            size="2"
                            options={[
                                {
                                    name: <FormattedMessage id="company_profile.company_page_description.video_manager_pop_up.video" />,
                                    value: true
                                },
                                {
                                    name: <FormattedMessage id="company_profile.company_page_description.video_manager_pop_up.image" />,
                                    value: false
                                }
                            ]}
                        />
                    }
                </FormattedMessage>

                {mainShowVideo
                    ? renderVideoForm()
                    : renderImageForm()
                }
            </Grid>
        </Grid>
    )
}

export default withStyles(useStyles)(CompanyProfileFormImage)
