/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback } from 'react'
import InternshipFiltersView from './InternshipFiltersView'
import CollectionHandler from 'assets/CollectionHandler.js'
import debounce from 'lodash.debounce'

const InternshipFilters = (props) => {
    const { user, handleFilterChange, offerCount, setFilters, filters, type, selectedLocationTag } = props
    const [searchCities, setSearchCities] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [citiesInput, setCitiesInput] = useState('')
    const [showInternshipFiltersModal, setShowInternshipFiltersModal] = useState(false)

    const cityCollection = CollectionHandler.Get('cityCollection')

    let searchText
    if (window.location.search.includes('?search=', 0)) {
        searchText = window.location.search.replace('?search=', '')
    }

    useEffect(() => {
        initSetupState()
    }, [])

    useEffect(() => {
        if (isLoaded) {
            handleFilterChange(filters)
        }
    }, [filters.location, filters.studyDomain, filters.sectors, filters.companySize, filters.studyType, filters.paid, filters.quickFilter])

    const initSetupState = () => {
        const fields = initState()

        setFilters(fields)
        setSearchCities(cityCollection)
        setIsLoaded(true)
    }

    const initState = (reset) => {
        let searchField = {}
        let locationArray = []
        let studyDomainArray = []
        let quickFilter = ''
        if (props.location) {
            if (props.location.type === 'location') {
                locationArray = props.location
                    ? [props.location]
                    : locationArray
            }
            if (props.location.type === 'studyDomain') {
                studyDomainArray = props.location
                    ? [props.location]
                    : studyDomainArray
            }
            if (props.location.type === 'freeText') {
                searchText = props.location.name
            }
            if (props.location.type === 'quickFilter') {
                quickFilter = props.location.value
            }
        }
        if (reset) {
            locationArray = []
            studyDomainArray = []
        }

        searchField = {
            title: searchText || '',
            studyDomain: studyDomainArray,
            location: locationArray,
            companySize: [],
            studyType: [],
            quickFilter: quickFilter,
            paid: null
        }
        if (!reset && props.urlFilters) {
            searchField = {
                ...searchField,
                ...props.urlFilters
            }
        }

        setFilters(searchField)
        return searchField
    }

    const reset = () => {
        const newFilters = initState(true)
        setFilters(newFilters)
        handleFilterChange(newFilters)
        window.history.pushState('', '', location.pathname)
    }

    const debouncedFilterChange = useCallback(
        debounce(handleFilterChange, 1000), []
    )

    return (
        <>
            {isLoaded &&
                <InternshipFiltersView
                    debouncedFilterChange={debouncedFilterChange}
                    offerCount={offerCount}
                    searchCities={searchCities}
                    citiesInput={citiesInput}
                    setSearchCities={setSearchCities}
                    setCitiesInput={setCitiesInput}
                    setFilters={setFilters}
                    filters={filters}
                    selectedLocationTag={selectedLocationTag}
                    type={type}
                    handleFilterChange={handleFilterChange}
                    showInternshipFiltersModal={showInternshipFiltersModal}
                    setShowInternshipFiltersModal={setShowInternshipFiltersModal}
                    reset={reset}
                    user={user}
                />
            }
        </>
    )
}

export default InternshipFilters
