import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    profileSimpleInfoViewContainer: {
        width: '100%',
        paddingBottom: '46px',
        [theme.breakpoints.up('sm')]: {
            paddingBottom: '69px',
        }
    },
    title: {
        paddingBottom: '6px',
        color: theme.palette.fg['black'],
        fontSize: '18px',
        lineHeight: '28px'
    },
    inputsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '13px'
    },
    inputLabel: {
        color: theme.palette.fg['black'],
        fontFamily: 'GreedStandard-Medium',
        lineHeight: '17px'
    },
    input: {
        paddingBottom: '12px'
    },
    inputUnderline: {
        '&::after': {
            borderBottom: `0.5px solid ${theme.palette.fg['greySlate']}`
        },
    },
}))
