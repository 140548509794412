import React from 'react'

const EnvelopeSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" {...props}>
        <g>
            <g id="Layer_1" data-name="Layer 1">
                <g>
                    <g>
                        <g>
                            <rect x="92.46" y="120.36" width="424.26" height="273.62" rx="44.74" ry="44.74"
                                fill='#000' strokeWidth='0px' />
                            <path
                                d="M471.98,409.41H137.19c-33.18,0-60.17-26.99-60.17-60.17v-184.15c0-33.18,26.99-60.17,60.17-60.17h334.78c33.18,0,60.17,26.99,60.17,60.17v184.15c0,33.18-26.99,60.17-60.17,60.17ZM137.19,135.79c-16.16,0-29.31,13.15-29.31,29.31v184.15c0,16.16,13.15,29.31,29.31,29.31h334.78c16.16,0,29.31-13.15,29.31-29.31v-184.15c0-16.16-13.15-29.31-29.31-29.31H137.19Z"
                            />
                        </g>
                        <g>
                            <rect x="70.41" y="98.31" width="424.26" height="273.62" rx="44.74" ry="44.74"
                                fill='#000' strokeWidth='0px' />
                            <path
                                d="M449.93,387.36H115.15c-33.18,0-60.17-26.99-60.17-60.17v-184.15c0-33.18,26.99-60.17,60.17-60.17h334.78c33.18,0,60.17,26.99,60.17,60.17v184.15c0,33.18-26.99,60.17-60.17,60.17ZM115.15,113.74c-16.16,0-29.31,13.15-29.31,29.31v184.15c0,16.16,13.15,29.31,29.31,29.31h334.78c16.16,0,29.31-13.15,29.31-29.31v-184.15c0-16.16-13.15-29.31-29.31-29.31H115.15Z"
                                fill='#000' strokeWidth='0px' />
                        </g>
                    </g>
                    <g>
                        <path
                            d="M104.92,122.49h365.12c-1.38,1.25-2.16,2.06-3.04,2.76-55.42,44.23-110.85,88.46-166.28,132.68-9.75,7.77-16.63,7.81-26.35.06-55.55-44.29-111.08-88.6-166.6-132.91-.8-.64-1.53-1.38-2.85-2.58Z"
                            fill='#fff' strokeWidth='0px' />
                        <path
                            d="M250.69,255.49c5.55,4.41,10.95,8.7,16.34,13.01,12.86,10.28,27.79,10.36,40.67.19,5.52-4.36,11.01-8.75,16.59-13.2,47.27,29.85,94.33,59.57,142.34,89.89H108.38c47.93-30.27,95.04-60.03,142.31-89.88Z"
                            fill='#fff' strokeWidth='0px' />
                        <path
                            d="M93.56,136.49c46.28,36.92,91.75,73.2,137.68,109.83-45.63,28.82-90.87,57.39-137.21,86.65-.58-3.77-1.47-6.87-1.48-9.98-.08-57.06-.06-114.11-.06-171.17,0-2.92-.11-5.84.04-8.76.1-1.89.57-3.76,1.02-6.58Z"
                            fill='#fff' strokeWidth='0px' />
                        <path
                            d="M343.18,246.37c46.13-36.8,91.79-73.23,138.18-110.24.44,2.82,1.05,4.91,1.06,7.01.05,60.46.06,120.93-.02,181.39,0,2.68-.93,5.35-1.57,8.75-46.27-29.22-91.68-57.89-137.65-86.91Z"
                            fill='#fff' strokeWidth='0px' />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default EnvelopeSVG
