import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    firstFiltersRow: {
        width: '100%',
        paddingBottom: '19px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        columnGap: '13px',
        [theme.breakpoints.up('lg')]: {
            paddingBottom: '11px',
            columnGap: '8px',
        },
    },

    jobSearchFieldContainer: {
        flex: 1,
        [theme.breakpoints.up('sm')]: {
            maxWidth: '268px',
        },
    },

    cityFieldContainer: {
        width: '154px',
    },

    timeFieldContainer: {
        width: '157px',
    },

    moreFiltersButtonContainer: {

    },

    resetFiltersButtonContainer: {
        paddingLeft: '4px',
        [theme.breakpoints.up(1366)]: {
            paddingLeft: '1px',
        },
        [theme.breakpoints.up(1440)]: {
            paddingLeft: '3px',
        },
    },

    secondFiltersRow: {
        width: '100%',
    },
}))
