import React from 'react'
import Box from '@material-ui/core/Box'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './MultipleSelect_style'
import CollectionHandler from 'assets/CollectionHandler'
import IconFactory from 'icons/IconFactory/IconFactory'

const MenuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
    },
    PaperProps: {
        style: {
            maxHeight: 350,
            width: 500
        }
    }
}

const MultipleSelect = (props) => {
    if (props.values === null) { return null }

    const classes = useStyles()
    const valueCollection = CollectionHandler.Get(props.valueCollectionString)

    const getTranslatedValueArray = () => {
        let result = props.values.map((valueString) => {
            return CollectionHandler.Translate(props.valueCollectionString, valueString)
        }).join(', ')

        if (result.length > 80) {
            result = result.substring(0, 80) + '...'
        }
        return result
    }

    return (
        <Box className={classes.boxContainer} >
            <Box className={classes.boxInput}>
                <Box className={classes.locationsDropdown}>
                    <FormControl error={!!props.error} style={{ m: 1, width: '100%' }} >
                        <Box style={{ marginBottom: 2 }}>
                            <Typography color='primary' variant='h4'>
                                {props.placeholder}
                            </Typography>
                        </Box>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={props.values}
                            name={props.name}
                            onChange={props.handleChange}
                            renderValue={getTranslatedValueArray}
                            className={{ borderColor: props.error ? 'borderColor' : '' }}
                            MenuProps={MenuProps}
                        >
                            {valueCollection.map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    <Checkbox
                                        icon={<IconFactory icon='checkbox-false' />}
                                        checkedIcon={<IconFactory icon='checkbox-true' />}
                                        checked={props.values.indexOf(item.value) > -1}
                                        color='primary'
                                    />
                                    <ListItemText
                                        className={classes.inputBase}
                                        primaryTypographyProps={{ variant: 'body2' }}
                                        primary={item.name}
                                    />
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText className='error'>{props.error}</FormHelperText>
                    </FormControl>
                </Box>
            </Box>
        </Box>
    )
}

export default MultipleSelect
