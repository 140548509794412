import axios from 'axios'
import ReactOnRails from 'react-on-rails';

import {
    ALERT_API
} from './env'

/**
    Axios request to Alert#Create
    @param data define the http request parameters
 */
function CreateAlert (data) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: ALERT_API,
        method: 'post',
        data: data
    })
}

export default {
    CreateAlert
}
