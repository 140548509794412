import React, { useState } from 'react'
import CandidacyAPI from 'api/CandidacyAPI'
import StudentNoteModalView from './StudentNoteModalView'

const StudentNoteModal = (props) => {
    const [studentNote, setStudentNote] = useState(props.studentNote)

    const handleChange = (event) => {
        setStudentNote(event.target.value)
    }

    const closeModal = () => {
        props.popupExit()
    }

    const updateStudentNote = () => {
        CandidacyAPI.updateCandidacy({
            candidacy_note: studentNote,
            candidacy_id: props.candidacyId
        })
        props.setStudentNote(studentNote)
        props.popupExit()
    }

    return (
        <StudentNoteModalView
            openShare={props.openShare}
            studentNote={studentNote}
            handleChange={handleChange}
            updateStudentNote={updateStudentNote}
            closeModal={closeModal}
        />
    )
}

export default StudentNoteModal
