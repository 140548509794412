import React from 'react'
import ForgotPasswordPage from './ForgotPasswordPage'
import PageWrapper from '../../../shared/wrappers/PageWrapper/PageWrapper'

// eslint-disable-next-line react/display-name
export default (props) => {
    return (
        <PageWrapper user={props.user}>
            <ForgotPasswordPage {...props} />
        </PageWrapper>
    )
}
