import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    selectionContainer: {
        display: 'flex',
        gap: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        }
    },
    addVideo: {
        flex: '1 1 0px',
    },
    addImage: {
        flex: '1 1 0px',
    }
}))
