import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 280,
        maxWidth: 320,
        height: 140,
        width: '100%',
        backgroundColor: 'white',
        boxShadow: 'var(--shadow-paper)',
        borderRadius: 12,
        margin: '0 auto'
    },
    jobCardText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexGrow: 1,
        padding: 16
    },
    logoAndTitle: {
        display: 'flex',
        gap: 10
    },
    logoContainer: {
        width: 40,
        borderRadius: 12
    },
    logo: {
        width: '100%'
    },
    jobTitle: {
        width: '100%',
        flexGrow: 1,
        overflow: 'hidden',
        textOverflow: 'clip',
        fontSize: 14,
        lineHeight: 1,
        maxHeight: 'calc(16px * 1.4 * 3)', // title max 3 lines -> calc(fontSize * lineHeight * number of lines)
        wordBreak: 'break-word',
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical'
    },
    jobFavourite: {
        display: 'flex',
        alignItems: 'baseline'
    },
    jobDetails: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: 'var(--medium-grey)',
        fontSize: '12px !important',
        whiteSpace: 'nowrap',
        overflowX: 'clip'
    },
    detailsText: {
        fontFamily: "'GintoNormal-Light', sans-serif !important",
        fontSize: 12,
        color: 'var(--dark-beige)'
    },
    bullet: {
        color: 'var(--dark-beige)',
        fontSize: 6,
        margin: '0 4px'
    },
    peopleWanted: {
        fontFamily: "'GintoNormal-Medium', sans-serif !important"
    },
    chips: {
        display: 'flex',
        gap: 10,
        width: '100%',
        overflowX: 'scroll',
        overflowY: 'hidden',
        scrollbarWidth: 'none', /* Firefox */
        '-ms-overflow-style': 'none', /* IE 10+ */
        '&::-webkit-scrollbar': { /* Chrome/Safari/Webkit */
            height: 0,
            background: 'transparent'
        }
    },
    chipRoot: {
        height: 'fit-content',
        cursor: 'pointer'
    },
    chipLabel: {
        padding: 0
    },
    chip: {
        backgroundColor: 'var(--beige)',
        borderRadius: 6,
        fontSize: 10,
        padding: '6px 10px'
    }
}))
