import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    selectedTagsContainer: { // OK
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        padding: '0px',
        [theme.breakpoints.down('md')]: {
            rowGap: 12,
            columnGap: 10
        },
        [theme.breakpoints.up('md')]: {
            rowGap: 10,
            columnGap: 10
        },
    },
    menuItem: {
        padding: '6px 20px'
    },
    tagList: {
        marginLeft: 20
    }
}))
