import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    dropDownArticlesContainer: {
        maxWidth: '625px',
        minWidth: '545px',
        padding: '20px',
        gap: '12px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    articlesContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '14px',
        justifyContent: 'space-between',
        height: '100%'
    }
}))
