import React from 'react'

const FacebookSVG = (props) => (
    <svg
        width="25"
        height="40"
        viewBox="0 0 25 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_39_59906)">
            <path
                d="M23.3523 13.8375C23.8082 13.2189 24.0779 12.4561 24.0779 11.6282V6.53055C24.0779 4.47939 22.4221 2.81892 20.371 2.80962L15.7756 2.79102C9.00816 2.79102 5.91514 7.63288 5.81746 12.5119C4.1384 12.8701 2.87793 14.3631 2.87793 16.1492V21.405C2.87793 23.191 4.1384 24.684 5.81746 25.0422V36.2794C5.81746 38.3352 7.48258 40.0003 9.53839 40.0003H15.7198C17.7756 40.0003 19.4407 38.3352 19.4407 36.2794V25.1259H19.8919C21.7989 25.1259 23.3989 23.684 23.5942 21.7864L24.1337 16.5306C24.2314 15.5631 23.9477 14.6003 23.3477 13.8422L23.3523 13.8375Z"
                fill="black"
            />
            <path
                d="M20.5625 11.0465C21.0184 10.4279 21.2881 9.66512 21.2881 8.83721V3.73954C21.2928 1.68837 19.6323 0.027907 17.5858 0.0186047L12.9904 0C6.22301 0 3.12998 4.84186 3.03231 9.72093C1.35324 10.0791 0.0927734 11.5721 0.0927734 13.3581V18.614C0.0927734 20.4 1.35324 21.893 3.03231 22.2512V33.4884C3.03231 35.5442 4.69742 37.2093 6.75324 37.2093H12.9346C14.9904 37.2093 16.6556 35.5442 16.6556 33.4884V22.3349H17.1067C19.0137 22.3349 20.6137 20.893 20.8091 18.9953L21.3486 13.7395C21.4463 12.7721 21.1625 11.8093 20.5625 11.0512V11.0465Z"
                fill="black"
            />
            <path
                d="M17.6453 13.3579H12.9337V10.2695C12.9337 9.1114 13.7011 8.83698 14.2453 8.83698H17.5709V3.73931L12.9941 3.7207C7.91041 3.7207 6.75692 7.52535 6.75692 9.95791V13.3579H3.81738V18.6137H6.75692V33.4881H12.9383V18.6137H17.1104L17.6499 13.3579H17.6453Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_39_59906">
                <rect
                    width="24.0651"
                    height="40"
                    fill="white"
                    transform="translate(0.0927734)"
                />
            </clipPath>
        </defs>
    </svg>
)

export default FacebookSVG
