import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    NewsAndTipsContainer: {
        height: '100%',
        padding: '57px 28px 89px',
        [theme.breakpoints.up('md')]: {
            paddingTop: '82px',
            paddingBottom: '108px'
        },
    },

    title: {
        paddingBottom: '44px',
        textAlign: 'center',
        color: theme.palette.fg['black'],
        fontFamily: 'GreedWide-Heavy',
        fontSize: '32px',
        lineHeight: '50px',
        textTransform: 'uppercase',
        [theme.breakpoints.up('md')]: {
            paddingBottom: '15px',
            fontSize: '40px',
            lineHeight: '50px'
        },
    },

    description: {
        paddingBottom: '65px',
        textAlign: 'center',
        color: theme.palette.fg['black'],
        fontSize: '16px',
        lineHeight: '20px'
    },

    cardsContainer: {
        paddingBottom: '38px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexWrap: 'wrap',
        rowGap: '26px',
        [theme.breakpoints.up('sm')]: {
            paddingBottom: '65px',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: '24px'
        },
    },

    buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    }
}))
