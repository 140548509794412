import React from 'react'

const CleaningSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86 78.79" {...props}>
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path
                    d="M86,19.31a8,8,0,0,0-2.35-5.66L78.33,8.34A8,8,0,0,0,67,8.34L50.31,25A8.23,8.23,0,0,0,46,23.74c-.92,0-1.85,0-2.79,0h-.69a8,8,0,0,0-7.94,7,8.5,8.5,0,0,0-.09,1.22v.27a23.15,23.15,0,0,0-5.68,1.37,24.41,24.41,0,0,0-7.69,4.71,24,24,0,0,0-5.88,8.06,24.84,24.84,0,0,0-2,7.47c-.09.74-.1,1.39-.12,1.91l0,.5a7.62,7.62,0,0,0,0,1.07L8.79,61.2A8.37,8.37,0,0,0,6,67.28c0,1,0,1.9,0,2.88v.63a8,8,0,0,0,8,8H35.06a33.53,33.53,0,0,0,3.88-.23,22.42,22.42,0,0,0,7.37-2.19,23.93,23.93,0,0,0,7.81-6,24.22,24.22,0,0,0,4.51-7.75,23.31,23.31,0,0,0,1.17-5.13h.48a8,8,0,0,0,8-8v-.8c0-.88,0-1.74,0-2.57A8.82,8.82,0,0,0,67,41.64L83.65,25A8,8,0,0,0,86,19.31Z" />
                <path
                    d="M80,13.31a8,8,0,0,0-2.35-5.66L72.33,2.34A8,8,0,0,0,61,2.34L44.31,19A8.23,8.23,0,0,0,40,17.74c-.92,0-1.85,0-2.79,0h-.7a8,8,0,0,0-7.93,7,8.5,8.5,0,0,0-.09,1.22v.27a23.15,23.15,0,0,0-5.68,1.37,24.41,24.41,0,0,0-7.69,4.71,24,24,0,0,0-5.88,8.06,24.84,24.84,0,0,0-2,7.47,18.35,18.35,0,0,0-.12,1.91l0,.5a7.62,7.62,0,0,0,0,1.07L2.79,55.2A8.37,8.37,0,0,0,0,61.28c0,.95,0,1.9,0,2.88v.63a8,8,0,0,0,8,8H29.06a33.53,33.53,0,0,0,3.88-.23,22.42,22.42,0,0,0,7.37-2.19,24,24,0,0,0,7.81-6,24.4,24.4,0,0,0,4.51-7.75,23.31,23.31,0,0,0,1.17-5.13h.48a8,8,0,0,0,8-8v-.8c0-.88,0-1.74,0-2.57A8.82,8.82,0,0,0,61,35.64L77.65,19A8,8,0,0,0,80,13.31Z" />
                <path className="cls-1" fill='white'
                    d="M8,64.79H29a24.54,24.54,0,0,0,3-.18,14.51,14.51,0,0,0,4.78-1.42A16.47,16.47,0,0,0,45.08,54a16.63,16.63,0,0,0,.86-6.53.78.78,0,0,0-.21-.47Q39.39,40.6,33,34.26a.8.8,0,0,0-.47-.2c-.78,0-1.57,0-2.36,0a14.56,14.56,0,0,0-4.52,1,16.23,16.23,0,0,0-5.17,3.18,15.86,15.86,0,0,0-3.92,5.39,17,17,0,0,0-1.34,5.09c-.07.6-.07,1.21-.1,1.88l10.44-5.21a.36.36,0,0,1-.12.23l-8.79,7.89q-4.23,3.8-8.45,7.6c-.09.08-.19.19-.19.28,0,1.13,0,2.25,0,3.42ZM36.54,25.72a1.12,1.12,0,0,0,0,.19c0,1.79,0,3.58,0,5.37a.69.69,0,0,0,.22.43q5.76,5.76,11.54,11.52a.88.88,0,0,0,.56.23c1.68,0,3.35,0,5,0h.42c0-1.12,0-2.18,0-3.24A.83.83,0,0,0,54,39.7c-1.3-1.32-2.62-2.62-3.93-3.92l-.3-.32L72,13.31,66.68,8,44.42,30.21a2.13,2.13,0,0,0-.23-.33c-1.31-1.32-2.62-2.62-3.94-3.93-.09-.09-.23-.22-.35-.22Z" />
            </g>
        </g>
    </svg>
)

export default CleaningSVG
