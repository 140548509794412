import React from 'react'

import CloudinaryURLHelper from 'assets/CloudinaryURLHelper'

export default class KotMessageCard extends React.Component {
    render () {
        var backPic = {
            backgroundImage: `url(${CloudinaryURLHelper.getImageUrl('MESSAGE_CARD', this.props.kot.picture)})`,
            borderRadius: '50%'
        }

        if (this.props.kot.unseen === 0) {
            var notificationDisplay = 'hide-notification'
        } else {
            var notificationDisplay = 'show-notification'
        }

        return (
            <div className="conversation_card" onClick={() => this.props.openItem('kot', this.props.kot.id)}>
                <div className={`message-choice-card kot`}>

                    <div className="message-card-first-infos">
                        <div className="message-card-picture" style={backPic}/>
                        <div className="message-card-content-container">
                            <div className="message-time">{this.props.kot.candidacies_count + ' conversations'}</div>
                            <div className="message-card-arrow-and-time">
                                <div className="message-card-information">
                                    <div className={`message-card-name ${notificationDisplay}`}> {this.props.kot.title} </div>
                                    <div className={`message-card-title ${this.props.address.replace(' ', '_')}`}>{this.props.address}</div>
                                </div>
                                <div className="message-arrow"><i className="fas fa-chevron-right"></i></div>
                            </div>
                            <div className={`kot-message-notification ${this.props.address.replace(' ', '_')} ${notificationDisplay}`}>{this.props.kot.unseen}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
