import React from 'react'
import { Helmet } from 'react-helmet'
import CloudinaryURLHelper from 'assets/CloudinaryURLHelper'

const DefaultHelmet = (props) => {
    const { noindex, title, description, imageCloudinaryKey } = props

    const getImageUrl = () => {
        if (imageCloudinaryKey) {
            return CloudinaryURLHelper.getImageUrl('META_IMAGE', imageCloudinaryKey)
        } else {
            return CloudinaryURLHelper.getImageUrl('META_IMAGE', 'assets/hello-future-lets-talk-student-be')
        }
    }

    return (
        <Helmet>
            <meta property='og:site_name' content='Student.be'/>
            <meta name='robots' content={noindex ? 'noindex' : 'index'}/>
            <meta name='googlebot' content={noindex ? 'noindex' : 'index'}/>
            <title>{title}</title>
            <meta name='description' content={description} />
            <meta property='og:description' content={description} />
            <meta property='og:title' content={title} />
            <meta property='og:image' content={getImageUrl()} />
            <meta name='title' content={title} />
            <meta name='image' content={getImageUrl()} />
            <meta name='twitter:site' content='@student_be' />
            <meta name='twitter:card' content='summary' />
            <meta name='twitter:title' content={title} />
            <meta name='twitter:description' content={description} />
            <meta name='twitter:image' content={getImageUrl()} />
        </Helmet>
    )
}

export default DefaultHelmet
