import React from 'react'

const ZeppelinSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 80"
        x="0px"
        y="0px"
    >
        <g fill="#a000ff">
            <path d="M61.817,21.424a1,1,0,0,0-1.06-.394l-4,1a1,1,0,0,0-.464.263l-1.921,1.921A21.064,21.064,0,0,0,46.645,22.3l-26.7-1.27A21.5,21.5,0,0,0,3.274,27.775,4.66,4.66,0,0,0,2.015,30.9c0,.034-.015.066-.015.1s.014.066.015.1a4.66,4.66,0,0,0,1.259,3.125A21.53,21.53,0,0,0,18.847,41c.363,0,.729-.009,1.094-.028l5.246-.25,1.864,5.594A1,1,0,0,0,28,47H42a1,1,0,0,0,.961-.726l1.853-6.484,1.7-.081a21.093,21.093,0,0,0,7.818-1.96l1.958,1.958a1,1,0,0,0,.464.263l4,1A1.01,1.01,0,0,0,61,41a1,1,0,0,0,.832-1.555c-.921-1.382-.887-3.014-.848-4.906.008-.379.016-.761.016-1.144v-4.79c0-.383-.008-.765-.016-1.144-.039-1.892-.073-3.524.848-4.906A1,1,0,0,0,61.817,21.424ZM4.5,31.545c-.347-.2-.5-.42-.5-.545s.156-.342.5-.545h0A29.152,29.152,0,0,1,18.845,27q.51,0,1.022.016l26.7.705a30.159,30.159,0,0,1,11.367,2.437l.323.145c.5.224.73.495.739.656,0,.01,0,.019,0,.029-.014.159-.235.419-.712.638l-.3.138a30,30,0,0,1-11.546,2.519l-26.573.7A29.508,29.508,0,0,1,4.5,31.545ZM57.511,23.9l1.762-.44a13.3,13.3,0,0,0-.3,3.607l-.007-.007a22.1,22.1,0,0,0-2.734-1.886Zm-37.665-.877,26.7,1.27a19.143,19.143,0,0,1,9.12,2.884,34.023,34.023,0,0,0-9.041-1.459l-26.7-.705A34.316,34.316,0,0,0,7.074,27.042,19.3,19.3,0,0,1,19.846,23.026ZM41.246,45H28.721l-.334-1H33V42H27.721l-.459-1.376,15.444-.734Zm5.171-7.289L19.841,38.974A19.327,19.327,0,0,1,7.063,34.949,33.739,33.739,0,0,0,18.848,37c.357,0,.714-.006,1.072-.016l26.573-.7a33.992,33.992,0,0,0,9.061-1.467A19.126,19.126,0,0,1,46.417,37.711Zm11.094.386L56.2,36.781a22.1,22.1,0,0,0,2.785-1.957,13.6,13.6,0,0,0,.293,3.713Z" />
            <rect x="49" y="30" width="7" height="2" />
            <path d="M4.325,12.818l.185.078a.871.871,0,0,0,.085.032,2.486,2.486,0,0,0,.421.1,2.4,2.4,0,0,0,.348.025A2.482,2.482,0,0,0,5.872,13l7.706.02a.977.977,0,0,0,.295-.044,3,3,0,0,0,.213-5.663,3.1,3.1,0,0,0-1.188-.2,3.993,3.993,0,0,0-7.242-.45,4.086,4.086,0,0,0-.447,1.4,2.5,2.5,0,0,0-.884,4.761Zm1.111-2.76a.476.476,0,0,1,.234.106A1,1,0,0,0,7.258,9.11,1.982,1.982,0,0,1,7.426,7.6,1.939,1.939,0,0,1,8.858,6.583a2,2,0,0,1,2.306,1.8,1,1,0,0,0,1.417.819.985.985,0,0,1,.791-.024,1,1,0,0,1,.033,1.838L5.759,11a1.093,1.093,0,0,0-.265.035.49.49,0,0,1-.2.013.237.237,0,0,1-.054-.013L5.15,11a.5.5,0,0,1-.281-.515A.508.508,0,0,1,5.436,10.058Z" />
            <path d="M43.645,18.9a.889.889,0,0,0,.086.032,2.373,2.373,0,0,0,.421.1,2.41,2.41,0,0,0,.349.025A2.48,2.48,0,0,0,45.008,19l7.706.02a.837.837,0,0,0,.3-.044,3,3,0,0,0,.212-5.663,3.035,3.035,0,0,0-1.187-.2,4,4,0,0,0-4.3-2.511,3.969,3.969,0,0,0-2.94,2.061,4.115,4.115,0,0,0-.447,1.395,2.5,2.5,0,0,0-.885,4.761Zm.927-2.838a.483.483,0,0,1,.235.106,1,1,0,0,0,1.587-1.054,1.986,1.986,0,0,1,.167-1.515A1.942,1.942,0,0,1,48,12.583a2,2,0,0,1,2.3,1.8,1,1,0,0,0,1.417.819,1,1,0,1,1,.825,1.814L44.9,17c-.1.021-.179.012-.266.035a.487.487,0,0,1-.2.013.313.313,0,0,1-.053-.012L44.287,17a.5.5,0,0,1-.282-.515A.5.5,0,0,1,44.572,16.058Z" />
            <path d="M31.086,54.313a3.084,3.084,0,0,0-1.188-.2,3.993,3.993,0,0,0-7.242-.45,4.086,4.086,0,0,0-.447,1.4,2.5,2.5,0,0,0-.884,4.761l.185.078a.871.871,0,0,0,.085.032,2.486,2.486,0,0,0,.421.1,2.4,2.4,0,0,0,.348.025A2.482,2.482,0,0,0,22.872,60l7.706.02c.082.044.2-.015.3-.044a3,3,0,0,0,.213-5.663Zm-.681,3.707L22.759,58a.639.639,0,0,0-.265.035.49.49,0,0,1-.2.013.237.237,0,0,1-.054-.013L22.15,58a.5.5,0,0,1-.281-.515.506.506,0,0,1,.567-.424.476.476,0,0,1,.234.106,1,1,0,0,0,1.588-1.054,1.982,1.982,0,0,1,.168-1.515,1.939,1.939,0,0,1,1.432-1.012,2,2,0,0,1,2.306,1.8,1,1,0,0,0,1.417.819.989.989,0,0,1,.791-.024,1,1,0,0,1,.033,1.838Z" />
            <rect x="35" y="42" width="2" height="2" />
        </g>
    </svg>
)

export default ZeppelinSVG
