import React from 'react'

const LaughSVG = (props) => (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99.38 86">
        <g id="Calque_2">
            <g id="Calque_1-2">
                <path d="M99.36,50.61c-.13-3.65-1.93-7.04-4.89-9.18-.71-.51-1.46-.95-2.25-1.31-3.36-21.83-23.78-36.81-45.62-33.45C29.35,9.32,15.8,22.86,13.15,40.12c-.79.36-1.54.8-2.24,1.31-2.96,2.14-4.77,5.53-4.91,9.18-.32,4.83,2.19,9.41,6.44,11.73,1.4.82,2.95,1.35,4.56,1.57,9.84,19.72,33.81,27.73,53.53,17.89,7.74-3.87,14.02-10.14,17.89-17.89,1.6-.22,3.13-.75,4.52-1.57,4.23-2.34,6.73-6.91,6.42-11.73Z"/>
                <path d="M93.36,44.61c-.13-3.65-1.93-7.04-4.89-9.18-.71-.51-1.46-.95-2.25-1.31C82.86,12.29,62.44-2.69,40.6.67,23.35,3.32,9.8,16.86,7.15,34.12c-.79.36-1.54.8-2.24,1.31-2.96,2.14-4.77,5.53-4.91,9.18-.31,4.83,2.2,9.41,6.45,11.73,1.4.82,2.95,1.35,4.55,1.57,9.84,19.72,33.81,27.73,53.53,17.89,7.74-3.87,14.02-10.14,17.89-17.89,1.6-.22,3.13-.75,4.52-1.57,4.23-2.34,6.73-6.91,6.42-11.73Z"/>
                <path d="M75,39.63c.87.13,1.72.23,2.55.4,1.45.3,2.9.6,4.33,1,.68.18,1.32.48,1.89.88.98.73,1.57,1.87,1.6,3.09.14,1.83-.84,3.56-2.47,4.4-1.72,1.02-3.92.75-5.34-.66-.53-.52-.9-1.18-1.07-1.91-.43-1.69-.8-3.39-1.18-5.08-.13-.59-.2-1.19-.29-1.79v-.36s-.02.03-.02.03Z" fill="#fff"/>
                <path d="M18.37,39.63c-.86.13-1.71.23-2.54.4-1.45.3-2.91.6-4.33,1-.68.18-1.32.48-1.89.88-.98.73-1.57,1.87-1.61,3.09-.14,1.83.84,3.56,2.47,4.4,1.72,1.02,3.92.75,5.34-.66.53-.52.9-1.19,1.06-1.91.43-1.69.81-3.39,1.18-5.08.13-.59.2-1.19.3-1.79v-.36s.02.03.02.03Z" fill="#fff"/>
                <path d="M72.07,54.59c-1.64-1.56-2.79-3.56-3.33-5.76-.46-1.79-.85-3.57-1.23-5.3-.16-.73-.26-1.37-.33-1.88l-.06-.42c-.07-.43-.11-.86-.11-1.3-.17-4.42,3.28-8.13,7.69-8.3.5-.02,1.01,0,1.51.09l.71.1.74.11c-4.45-17.1-21.92-27.36-39.02-22.92-11.23,2.92-20,11.69-22.92,22.92l.74-.11.71-.1c4.37-.67,8.45,2.34,9.11,6.7.08.5.1,1,.09,1.51-.01.44-.05.88-.11,1.31l-.06.39c-.08.52-.17,1.16-.33,1.88-.38,1.75-.78,3.52-1.23,5.32-.54,2.2-1.7,4.2-3.34,5.76-.67.63-1.41,1.19-2.2,1.66,8.95,15.24,28.56,20.33,43.8,11.38,4.7-2.76,8.62-6.68,11.38-11.38-.79-.47-1.53-1.03-2.21-1.66ZM56.47,28c2.09-.21,3.96,1.32,4.17,3.41,0,.1.02.21.02.31.21,2.07-1.27,3.93-3.33,4.2-2.17.44-4.28-.96-4.71-3.13-.44-2.17.96-4.28,3.13-4.71.24-.05.48-.07.73-.08,0,0,0,0-.01,0ZM36.67,28c2.21,0,4,1.79,4,4s-1.79,4-4,4c-1.09.03-2.14-.41-2.9-1.2-.69-.77-1.06-1.77-1.03-2.8-.04-2.16,1.67-3.96,3.84-4h.09ZM46.9,59c-8.56.03-15.73-6.48-16.53-15h33.16c-.8,8.56-8.03,15.08-16.63,15Z" fill="#fff" fillRule="evenodd"/>
            </g>
        </g>
    </svg>
)

export default LaughSVG
