/* eslint-disable react/prop-types */
import React from 'react'

import Field from 'inputs/Field/Field'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import CollectionHandler from 'assets/CollectionHandler.js'
import { FormattedMessage } from 'react-intl'

const DeleteOfferPopupView = ({
    handleChange,
    itemType,
    itemsLen,
    emptyError
}) => {
    const deleteReasonCollection = CollectionHandler.Get('deleteReasonCollection')

    const renderDeleteAlert = () => {
        return (
            <div className="content alert">
                <Typography variant='body2'>
                    <FormattedMessage id={`delete_offer_popup.${itemType}.text.${itemsLen === 1 ? 'one' : 'more'}`}/>
                </Typography>
            </div>
        )
    }

    const renderDeleteCandidacy = () => {
        return (
            <div className="content candidacy">
                <Typography variant='body2'>
                    <FormattedMessage id={`delete_offer_popup.${itemType}.text.${itemsLen === 1 ? 'one' : 'more'}`}/>
                </Typography>
            </div>
        )
    }

    const renderArticleDelete = () => {
        return (
            <div className="content article">
                <Typography variant='body2'>
                    <FormattedMessage id="delete_offer_popup.article.text.one"/>
                </Typography>
            </div>
        )
    }

    const renderGeneralDelete = () => {
        return (
            <div className="content general">
                <Typography variant='body2'>
                    <FormattedMessage values={{ count: itemsLen }} id={`delete_offer_popup.${itemType}.text.${itemsLen === 1 ? 'one' : 'more'}`}/>
                </Typography>

                <Field
                    type="hashes-select"
                    title={<FormattedMessage id="delete_offer_popup.reason_for_delete" />}
                    different
                    blank
                    optionSelect={deleteReasonCollection}
                    name="deleteInfos"
                    handleChange={handleChange}
                />
            </div>
        )
    }

    const renderDeleteInfo = () => {
        if (itemType === 'article') {
            return renderArticleDelete()
        } else if (itemType === 'candidacy') {
            return renderDeleteCandidacy()
        } else if (itemType === 'alert') {
            return renderDeleteAlert()
        } else {
            return renderGeneralDelete()
        }
    }

    return (
        <Box>
            { renderDeleteInfo() }

            { emptyError &&
                <div className="error-container">
                    <FormattedMessage id="delete_offer_popup.error_message" />
                </div>
            }
        </Box>
    )
}

export default DeleteOfferPopupView
