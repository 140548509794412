import React from 'react'

const RightLongArrowSVG = (props) => (
    <svg>
        <line
            stroke={props.fill}
            strokeMiterlimit="10"
            x1="51.34"
            y1="3.8"
            x2="0.69"
            y2="3.8"
        />
        <path
            {...props}
            d="M51.3,3.8,48.54,6.55a.42.42,0,0,0,0,.6.42.42,0,0,0,.59,0l3-3.05a.35.35,0,0,0,.12-.3.38.38,0,0,0-.12-.31l-3-3a.4.4,0,0,0-.59,0,.4.4,0,0,0,0,.59Z"
        />
    </svg>
)

export default RightLongArrowSVG
