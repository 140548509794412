import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    seoBlockContainer: {
        paddingTop: 30,
        marginBottom: 30,
        width: '100%'
    },
    seoTextContainer: {
        paddingBottom: 20
    },
    seoTextBlock: {
        marginTop: 12
    },
    accordion: {
        border: 'solid var(--foreground-silver) 1px',
        boxShadow: 'none',
        marginBottom: 16,
        borderRadius: '20px !important',
        '&:before': {
            height: 0
        }
    },
    accordionTitle: {
        display: 'flex',
        flexDirection: 'column'
    },
    accordionText: {
        '& a': {
            color: theme.palette.fg['purple'],
            fontWeight: 'bold'
        }
    },
    accordionDetails: {
        cursor: 'pointer'
    }
}))
