
import React from 'react'
import { FormattedMessage } from 'react-intl'

import './cardcontainer.scss'
import { Box, Grid } from '@material-ui/core'

import CardFactory from 'cards/CardFactory/CardFactory'

export default class CardContainer extends React.Component {
    /**
         * @param props - Comes from your rails view.
         */
    constructor (props) {
        super(props)
        this.scroll = this.scroll.bind(this)
        this.onclick = this.onclick.bind(this)
        this.state = {}
        this.cardsRef = []
        this.bubbleRef = []
        this.flexRef = React.createRef()
        this.currentCard = 0
        for (let i = 0; i < this.props.cards.length; i++) {
            this.cardsRef.push(React.createRef())
            this.bubbleRef.push(React.createRef())
        }
    }

    scroll (event) {
        const scrollWidth = this.flexRef.current.scrollWidth
        const scrollLeft = this.flexRef.current.scrollLeft
        const next = Math.round(scrollLeft / (scrollWidth / this.props.cards.length))
        if (this.currentCard !== next) {
            this.bubbleRef[this.currentCard].current.classList.remove('active')
            this.bubbleRef[next].current.classList.add('active')
            this.currentCard = next
        }
    }

    onclick (index) {
        this.cardsRef[index].current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }

    loadContainerClass () {
        if (this.props.type === 'company') return 'company-card-container'
        if (this.props.type === 'kot') return 'kot-card-container'
        if (this.props.type === 'article') return 'kot-card-container'
        return 'card-container'
    }

    render () {
        if (this.cardsRef.length === 0) {
            for (let i = 0; i < this.props.cards.length; i++) {
                this.cardsRef.push(React.createRef())
                this.bubbleRef.push(React.createRef())
            }
        }

        return (
            <Box ref={this.props.forwardRef} className={this.loadContainerClass()}>
                {!this.props.flexRow && this.props.cards.length !== 0 && this.props.cards.map((card, index) => {
                    return (
                        <CardFactory
                            type={this.props.type}
                            cardsRef={this.cardsRef[index]}
                            card={card}
                            displayShow={this.props.displayShow}
                            userType={this.props.userType}
                            user={this.props.user}
                            userAddress={this.props.userAddress}
                            remove={this.props.remove}
                            articleOwner={this.props.articleOwner}
                            mapProviderProps={this.props.mapProviderProps}
                        />
                    )
                })}
                {this.props.flexRow && this.props.cards.length !== 0 &&
                    <Grid container spacing={2}>
                        {this.props.cards.map((card, index) => {
                            return (
                                <Grid key={`article-card-${card.id}`} item lg={4} md={6} sm={6} xs={12}>
                                    <CardFactory
                                        type={this.props.type}
                                        cardsRef={this.cardsRef[index]}
                                        card={card}
                                        displayShow={this.props.displayShow}
                                        userType={this.props.userType}
                                        user={this.props.user}
                                        userAddress={this.props.userAddress}
                                        remove={this.props.remove}
                                        articleOwner={this.props.articleOwner}
                                        mapProviderProps={this.props.mapProviderProps}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                }
                {this.props.cards.length === 0 &&
                    <div className="card-container">
                        {this.props.noCardsText ? this.props.noCardsText : <FormattedMessage id="landing_about_pages.card_container" />}
                    </div>
                }
                <div className="bubble-selection-container">
                    {this.props.cards.map((card, index) => {
                        return (
                            <div
                                className={'bubble-selection' + (index === 0 ? ' active' : '')}
                                onClick={() => this.onclick(index)}
                                key={index}
                                ref={this.bubbleRef[index]}
                            />
                        )
                    })}
                </div>
            </Box>
        )
    }
}

CardContainer.defaultProps = {
    translation: {
        aboutPages: {
            card_container: <FormattedMessage id="landing_about_pages.card_container" />
        }
    },
    cards: [{ title: 'Courier Operations Representative', date: '5/11/2019', category: 'Jeune diplômé', type: 'First job', address: 'Brussels (200m)', company: { name: 'Puratos', logo_uid: '' } },
        { title: 'Courier Operations Representative', date: '5/11/2019', category: 'Jeune diplômé', type: 'First job', address: 'Brussels (200m)', company: { name: 'Puratos', logo_uid: '' } },
        { title: 'Courier Operations Representative', date: '5/11/2019', category: 'Jeune diplômé', type: 'First job', address: 'Brussels (200m)', company: { name: 'Puratos', logo_uid: '' } }],
    forwardRef: React.createRef()
}
