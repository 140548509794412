import React, { useEffect, useState } from 'react'

import { useStyles } from './NavigationDropDownArticles_style'
import { Box } from '@material-ui/core'

import { FormattedMessage } from 'react-intl'
import ArticleAPI from 'api/ArticleAPI'
import NavigationDropDownArticleCard from '../NavigationDropDownArticleCard/NavigationDropDownArticleCard'
import NavigationDropDownTitle from '../NavigationDropDownTitle/NavigationDropDownTitle'
import RouteMap from 'assets/RouteMap'

const NavigationDropDownArticles = (props) => {
    const classes = useStyles()
    const { type } = props

    const [articles, setArticles] = useState(false)

    useEffect(() => {
        ArticleAPI.GetRecentArticles({
            category: transformCategoryFormat(type),
            count: 2
        }).then((response) => {
            setArticles(response.data)
        })
    }, [])

    const transformCategoryFormat = (category) => {
        if (category === 'student-rooms') {
            category = 'kots'
        }
        return category.slice(0, -1).replace('-', '_')
    }

    const renderArticleCards = () => {
        return articles.map((article, index) =>
            <NavigationDropDownArticleCard
                article={article}
                key={`navigation-article-card-${index}`}
            />
        )
    }

    return (
        <Box className={classes.dropDownArticlesContainer}>
            <NavigationDropDownTitle
                title={<FormattedMessage id='page_footer_navbar_data.recent_news' />}
                seeAllButton={<FormattedMessage id='page_footer_navbar_data.see_all_news' />}
                pageLink={RouteMap.ArticleCategory(transformCategoryFormat(type))}
            />
            <Box className={classes.articlesContainer}>
                {articles && renderArticleCards()}
            </Box>
        </Box>
    )
}

export default NavigationDropDownArticles
