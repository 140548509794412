import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    regularLine: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '6px'
    },
    text: {
        color: theme.palette.fg.black90Opacity,
        fontSize: '14px',
        lineHeight: '15px'
    },
    iconBox: {
        minWidth: 18
    }
}))
