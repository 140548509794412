import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    banner: {
        display: 'flex',
        width: '100%',
        height: 350,
        backgroundColor: 'var(--background-purple)',
        backgroundImage: 'url(\'https://res.cloudinary.com/studentbe/image/upload/v1721138136/student-be-assets/Mask_group.png\')',
        [theme.breakpoints.down('sm')]: {
            height: 'fit-content',
        },
    },
    bannerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 50
    },
    bannerText: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',   
        textAlign: 'center'
    },
    pageTitle: {
        fontSize: 60,
        marginBottom: 12,
        color: 'var(--foreground-white)',
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
            fontSize: 40,
            textAlign: 'center',
            lineHeight: 1.2,
            maxWidth: 280
        }
    },
    pageSubTitle: {
        fontSize: 18,
        color: 'var(--foreground-white)',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            textAlign: 'center'
        }
    },
    creditPackagesContainer: {
        margin: '80px 0',
        [theme.breakpoints.down('sm')]: {
            margin: '40px 0'
        }
    },
    containerOverride: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: 'inherit',
        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
            paddingRight: 0,
            margin: 0
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    publishJob: {
        marginTop: 24,
        '& p': {
            fontWeight: 'bold'
        }
    },
    marginBottom1: {
        marginBottom: '20px'
    },
    packagesContainer: {
        margin: '50px 0'
    },
    descriptionText: {
        color: 'grey',
        marginBottom: 40,
        width: '80%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    textMaxWidth: {
        width: '80%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    textItalic: {
        fontStyle: 'italic'
    },
    homeStatsContainer: {
        margin: '60px auto'
    }
}))
