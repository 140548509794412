import React from 'react'

import { useStyles } from './Button_style'

import { Button as NativeButton } from '@material-ui/core'

const ButtonVariants = Object.freeze({
    primary: 'primary',
    secondary: 'secondary',
    tertiary: 'tertiary',
    ctaPrimary: 'cta-primary',
    ctaSecondary: 'cta-secondary'
})

const ButtonSizes = Object.freeze({
    big: 'big',
    small: 'small'
})

const Button = (props) => {
    const { children, variant = ButtonVariants.primary, size = ButtonSizes.big, className, ...rest } = props

    const classes = useStyles()

    const loadVariantStyle = () => {
        switch (variant) {
        case ButtonVariants.primary:
            return classes.primaryButton

        case ButtonVariants.secondary:
            return classes.secondaryButton

        case ButtonVariants.tertiary:
            return classes.tertiaryButton

        case ButtonVariants.ctaPrimary:
            return classes.ctaPrimaryButton

        case ButtonVariants.ctaSecondary:
            return classes.ctaSecondaryButton

        default:
            return classes.primaryButton
        }
    }

    const loadSizeStyle = () => {
        switch (size) {
        case ButtonSizes.big:
            return classes.bigButton

        case ButtonSizes.small:
            return classes.smallButton

        default:
            return classes.bigButton
        }
    }

    return (
        <NativeButton
            classes={{ disabled: classes.buttonDisabled }}
            className={`${classes.baseButton} ${loadVariantStyle()} ${loadSizeStyle()} ${className || ''}`}
            disableRipple
            {...rest}
        >
            {children}
        </NativeButton>
    )
}

export default Button
