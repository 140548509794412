import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import Bugsnag from '@bugsnag/js'

import ActivateCompanyJobModalView from './ActivateCompanyJobModalView/ActivateCompanyJobModalView'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import CompanyJobAPI from 'api/CompanyJobAPI'
import PaymentAPI from 'api/PaymentAPI'
import RouteMap from 'assets/RouteMap'
import URLParamsHandler from 'assets/URLParamsHandler'

const ActivateCompanyJobModal = (props) => {
    const { showModal, setShowModal, setFeedback, jobToActivate, user, toggleActiveJobStatus, creditCount, setCreditCount, filters, company } = props
    if (!showModal) return null

    const { formatMessage } = props.intl

    const [info, setInfo] = useState(null)
    const [errors, setErrors] = useState({})
    const [step, setStep] = useState(1)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getInfo()
    }, [showModal])

    const closeModal = () => {
        setShowModal(false)
    }

    const getInfo = () => {
        const itemsLength = jobToActivate.length
        const newInfo = {
            offersNumber: itemsLength,
            jobToActivate: jobToActivate,
            title: jobToActivate.title,
            jobType: jobToActivate.type,
            current_step: jobToActivate.current_step,
            goldLicence: user.gold_licence,
            silverLicence: user.silver_licence,
            needsCreditsForActivation: true
        }

        // 1. DETERMINE STATUS: ACTIVATE, ACTIVATE_UNTIL?
        if (jobToActivate.active) {
            newInfo.status = 'deactivate'
        } else {
            newInfo.status = 'activate_until'
        }

        // 2. IS JOB INFORMATION COMPLETE?
        if (!jobToActivate.current_step || jobToActivate.current_step > -1) {
            newInfo.jobInfoIsComplete = false
        } else {
            newInfo.jobInfoIsComplete = true
        }

        // 3. DETERMINE CREDIT COST
        if (jobToActivate.type === 'first_job') {
            if (user.gold_licence) {
                newInfo.creditsCost = 0
            } else {
                newInfo.creditsCost = 10
            }
        } else {
            if (user.gold_licence || user.silver_licence) {
                newInfo.creditsCost = 0
            } else {
                newInfo.creditsCost = 1
            }
        }

        // 4. DO WE NEED CREDITS TO ACTIVATE?
        newInfo.needsCreditsForActivation = newInfo.creditsCost > 0

        // If the first job has been deactivated, but it is within the active_until period,
        // you can activate job without credits
        if (new Date(jobToActivate.active_until) >= new Date()) {
            newInfo.creditsCost = 0
            newInfo.needsCreditsForActivation = false
        }

        // 6. DO WE HAVE ENOUGH CREDITS TO ACTIVATE?
        newInfo.enoughCredits = creditCount >= newInfo.creditsCost

        setInfo(newInfo)
    }

    const activateOrDeactivateJob = () => {
        if (info.status === 'deactivate') {
            deactiveJob()
        } else {
            activateJob()
        }
    }

    const deactiveJob = () => {
        toggleStatus()
        setShowModal(false)
        setLoading(false)
    }

    const activateJob = () => {
        setLoading(true)

        if (!info.jobInfoIsComplete) {
            window.open(RouteMap.Edit(info.jobToActivate.type, info.jobToActivate.id), '_self')
        } else if (info.needsCreditsForActivation && !info.enoughCredits) {
            setStep(2)
            setLoading(false)
        } else if (info.needsCreditsForActivation && info.enoughCredits) {
            PaymentAPI.activateWithCredits({
                items: [{
                    id: info.jobToActivate.id,
                    type: info.jobToActivate.type
                }]
            }).then((response) => {
                setCreditCount(response.data.credit_count)
                toggleStatus()
                setShowModal(false)
                setLoading(false)
            }).catch((error) => {
                setErrors(error.response.data)
                Bugsnag.notify(error)
            })
        } else {
            toggleStatus()
            setShowModal(false)
            setLoading(false)
        }
    }

    const toggleStatus = () => {
        const data = {
            update_active: true,
            active: !jobToActivate.active, // considere that everyone are the same
            type: jobToActivate.type,
            id: jobToActivate.id
        }
        CompanyJobAPI.activateJob(data).then((response) => {
            toggleActiveJobStatus(
                response.data.type,
                response.data.id,
                response.data.status,
                response.data.new_credit_count,
                response.data.active_until
            )

            setFeedback({
                open: true,
                severity: 'success',
                message: response.data.status ? formatMessage({ id: 'active_popup.success' }) : formatMessage({ id: 'active_popup.success_deactivated' })
            })
            window.scrollTo(0, 0)
        }).catch((error) => {
            setErrors(error.response.data)
        })
    }

    const buyCredits = (selectedPackage) => {
        PaymentAPI.newPayment({
            package_name: selectedPackage.name,
            package_credits_cost: selectedPackage.credits,
            process: 'buying_credits',
            success_url: '/',
            succes_params: `${URLParamsHandler.getURLSearchParams('company_job', filters)}&process=buy_credits_and_activate_job&job_id=${jobToActivate.id}&job_type=${jobToActivate.type}`,
            cancel_url: window.location.href
        },
        AnalyticsHelper.constructModalCheckoutAnalyticsAttributes(
            user,
            company,
            selectedPackage,
            jobToActivate.type
        ))
    }

    return (
        <ActivateCompanyJobModalView
            closeModal={closeModal}
            company={company}
            loading={loading}
            creditCount={creditCount}
            user={user}
            info={info}
            activateOrDeactivateJob={activateOrDeactivateJob}
            buyCredits={buyCredits}
            errors={errors}
            step={step}
            setStep={setStep}
        />
    )
}

export default injectIntl(ActivateCompanyJobModal)
