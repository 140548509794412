/* eslint-disable react/prop-types */
import React from 'react'

import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import IconFactory from 'icons/IconFactory/IconFactory'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

const ArrowButton = (props) => {
    const renderButton = () => {
        return (
            <Button
                {...props}
                startIcon={props.direction === 'left' && <IconFactory icon='back' />}
                endIcon={props.direction === 'right' && <IconFactory icon='forward' />}
                onClick={props.onClick}
                style={{ ...props.style, ...props.className, textTransform: 'none' }}
            >
                <Typography
                    variant='body1'
                    component='a'
                    color='primary'
                >
                    {props.name}
                </Typography>
            </Button>
        )
    }

    const renderArrowButton = () => {
        if (props.redirectionUrl) {
            return <RouteMapLink
                redirectionUrl={props.redirectionUrl}
            >
                {renderButton()}
            </RouteMapLink>
        } else {
            return renderButton()
        }
    }

    return (
        renderArrowButton()
    )
}

ArrowButton.defaultProps = {
    color: 'primary',
    direction: 'right'
}

export default ArrowButton
