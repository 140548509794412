import React from 'react'

import { useStyles } from './ResetFilters_style'
import { Button, useTheme } from '@material-ui/core'

import IconFactory from 'icons/IconFactory/IconFactory'

const ResetFilters = (props) => {
    const { reset, smallIcon } = props
    const classes = useStyles({ smallIcon })
    const theme = useTheme()

    return (
        <Button className={classes.resetButton} onClick={reset} disableRipple>
            <IconFactory
                icon='reset'
                color={theme.palette.fg.white}
                className={classes.resetIcon}
            />
        </Button>
    )
}

export default ResetFilters
