import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { useStyles } from '../../ProfileEditForm_style'
import StepButtons from '../StepButtons/StepButtons'

const ProfileEditFormStepIntro = (props) => {
    const { step, nextStep } = props
    const classes = useStyles()

    return (
        <Box className={classes.mainStepBox}>
            <Typography variant='h1' className={classes.title}>
                <FormattedMessage id='personal_dashboard.profile_edit_form.name_step.title' />
            </Typography>
            <Typography variant='h4' className={classes.text}>
                <FormattedMessage id='personal_dashboard.profile_edit_form.name_step.description' />
            </Typography>
            <StepButtons
                step={step}
                nextStep={() => nextStep('Personal Dashboard step 1 intro')}
            />
        </Box>
    )
}
export default ProfileEditFormStepIntro
