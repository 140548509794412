import RouteMap from 'assets/RouteMap.js'
export const CHAT_EMPLOYER_GENERAL = 'EmployerGeneral'
export const CHAT_EMPLOYER_ARTICLES = 'EmployerArticles'

export const usePipedrive = () => {
    const getIdChatEmployerGeneral = () => {
        const language = RouteMap.GetLocaleFromUrl()

        if (language === 'fr') return '6639c208-41fd-4cef-b4ac-eef5e08bfc24'
        if (language === 'nl') return 'e8811ecc-69da-4276-a12e-e376b3d75d40'
        if (language === 'en') return 'e8811ecc-69da-4276-a12e-e376b3d75d40'
    }

    const getIdChatEmployerArticles = () => {
        const language = RouteMap.GetLocaleFromUrl()

        if (language === 'fr') return 'c5bc1af8-fc68-4891-984d-6ec4f25b30df'
        if (language === 'nl') return 'c6db0f6c-b23c-4d17-87c8-44cfae7fa86e'
        if (language === 'en') return 'c6db0f6c-b23c-4d17-87c8-44cfae7fa86e'
    }

    const getChatId = (type) => {
        if (type === CHAT_EMPLOYER_GENERAL) return getIdChatEmployerGeneral()
        if (type === CHAT_EMPLOYER_ARTICLES) return getIdChatEmployerArticles()
    }

    const getUrlEmployersContactForm = () => {
        const baseUrl = 'https://webforms.pipedrive.com/f/'
        const language = RouteMap.GetLocaleFromUrl()
        let formId

        if (language === 'nl') formId = '1wAzLteXvSJ8QxKdlNo2DLjIsGeU3vH8ws3rdbU6br0aEPou7yF3KpMnHNHNhOzu3'
        if (language === 'fr') formId = '31lkg3G3qLbhIGmqOvDICIs56LK6BzwUzLtdi1F2u3sSNbqcv4hcINT7XK7Vr2rEn'
        if (language === 'en') formId = '2VMfCHok5yOrrLEG1wD0UW1zX0uxzOwUTApagaAqxlgJ6pRzl4oFWNCzgxCuD5TRV'

        return baseUrl + formId
    }

    const instantiateContactForm = () => {
        const script = document.createElement('script')

        script.src = 'https://webforms.pipedrive.com/f/loader'
        script.async = true

        const pipedrive = document.querySelector('.pipedriveWebForms')
        pipedrive.appendChild(script)
    }

    const instantiateChat = (type) => {
        const chatId = getChatId(type)
        const script = document.createElement('script')
        const script2 = document.createElement('script')
        script.type = 'text/javascript'
        script.innerHTML = `window.pipedriveLeadboosterConfig = {base: 'leadbooster-chat.pipedrive.com',companyId: 6316802,playbookUuid: '${chatId}',version: 2};(function () {var w = window;if (w.LeadBooster) {console.warn('LeadBooster already exists');} else {w.LeadBooster = {q: [],on: function (n, h) {this.q.push({ t: 'o', n: n, h: h });},trigger: function (n) {this.q.push({ t: 't', n: n });},};}})();`
        script.async = true

        script2.src = 'https://leadbooster-chat.pipedrive.com/assets/loader.js'
        script2.async = true

        document.body.appendChild(script)
        document.body.appendChild(script2)
    }

    return {
        getIdChatEmployerGeneral,
        getIdChatEmployerArticles,
        getUrlEmployersContactForm,
        instantiateContactForm,
        instantiateChat
    }
}
