import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    noHoverEffects: {
        '&:hover': {
            border: 'none'
        }
    },
    pageTitle: {
        textTransform: 'capitalize',
        fontFamily: "'GintoNord-Bold', sans-serif !important",
        fontSize: 22,
        color: 'white'
    },
    textField: {
        display: 'flex',
        justifyContent: 'left',
        width: '85%',
        marginTop: '30px',
        backgroundColor: '#fcfcfc',
        borderColor: '#f7f7f7',
        '& .MuiInputBase-root': {
            borderRadius: 'var(--border-radius)'
        },
    },
    wordCount: {
        right: '0',
        top: '0',
        position: 'relative',
        width: '84%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '13px'
    },
    indication_title: {
        width: '84%',
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: '43px',
        marginBottom: '6px',
        fontWeight: 'bold',
        fontSize: '16px'
    },
    indicationContent: {
        width: '84%',
        display: 'flex',
        justifyContent: 'flex-start'
    },
    example: {
        width: '84%',
        fontWeight: 'bold',
        fontSize: '15px',
        marginTop: '44px',
        marginBottom: '15px',
        display: 'flex',
        justifyContent: 'flex-start'
    },
    exampleEntry: {
        width: '84%',
        fontSize: '13px',
        marginTop: '10px',
        marginBottom: '10px',
        display: 'flex',
        padding: '15px 0 15px 0',
        justifyContent: 'flex-start',
        backgroundColor: 'rgba(150,150,150, 0.1)'
    },
    parent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    input: {
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.5',
        color: '#333'
    },
    helperText: {
        marginTop: '10px',
        fontSize: '20px',
        color: '#999'
    }
}))
