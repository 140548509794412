import React from 'react'
import { useStyles } from './MyAlertsFilters_style'
import { Box } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import CollectionHandler from 'assets/CollectionHandler.js'
import SelectFilter from 'components/Searchbar/shared/SelectFilter/SelectFilter'
import ResetFilters from 'components/Searchbar/shared/ResetFilters/ResetFilters'

const MyAlertsFilters = (props) => {
    const { state, setState } = props
    const classes = useStyles()
    const jobTypeCollection = CollectionHandler.Get('jobTypeCollection')

    const reset = () => {
        setState({ ...state, type: '' })
    }

    const handleTypeFilterChange = (event) => {
        const { name, value } = event.target
        setState({ ...state, [name]: value })
    }

    return null

    return (
        <Box className={classes.filters}>
            <SelectFilter
                name='type'
                value={state.type}
                collection={jobTypeCollection}
                onChange={handleTypeFilterChange}
                placeholder={<FormattedMessage id='search_bar.job_type' />}
            />
            <ResetFilters reset={reset} />

        </Box>
    )
}

export default MyAlertsFilters
