import React from 'react'

const PackagePlaneSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 80"
        x="0px"
        y="0px"
    >
        <g fill="#a000ff">
            <path d="M4.6,37.23l16.75,2.311L13.176,51.434a1,1,0,0,0,.581,1.536l4,1a1,1,0,0,0,.908-.222L32.908,41.087l7.965.724c1.021.093,2.3.137,3.621.137a49.4,49.4,0,0,0,5.657-.294l2.643-.33a19.933,19.933,0,0,0,5.383-1.459l1.9-.814a2.447,2.447,0,0,0,.269-4.363l-5.782-3.373A17.022,17.022,0,0,0,46,29H38.452L26.873,15.852a1.055,1.055,0,0,0-.769-.339l-4.122.074A1,1,0,0,0,21.087,17l5.37,12H12.618L7.9,19.553A1,1,0,0,0,7,19H3a1,1,0,0,0-.986,1.165L3.892,31.436l-.226.113A3.014,3.014,0,0,0,4.6,37.23ZM17.73,51.9l-2.09-.523,9.852-14.33,8.118.738-1.383,1.229L32.09,39l-.013.145Zm5.8-34.342,2.148-.039L35.787,29H28.648ZM5.986,31.835,4.181,21h2.2l4.723,9.447A1,1,0,0,0,12,31H46a15.009,15.009,0,0,1,7.347,1.93l.311.623L52.764,34H49v2h4a1,1,0,0,0,.447-.1l2-1a.988.988,0,0,0,.45-.487l3.441,2.008a.447.447,0,0,1-.049.8l-1.9.814a17.978,17.978,0,0,1-4.843,1.313l-2.643.33a49.541,49.541,0,0,1-8.849.149l-6.1-.554,1.707-1.517A1,1,0,0,0,36.09,36l-11-1a1,1,0,0,0-.914.43L22.62,37.7,4.875,35.249a1.014,1.014,0,0,1-.314-1.911l.886-.443A1,1,0,0,0,5.986,31.835Z" />
            <rect x="15" y="32" width="2" height="2" />
            <rect x="19" y="32" width="2" height="2" />
            <rect x="23" y="32" width="2" height="2" />
            <rect x="27" y="32" width="2" height="2" />
            <rect x="31" y="32" width="2" height="2" />
            <rect x="35" y="32" width="2" height="2" />
            <rect x="39" y="32" width="2" height="2" />
            <path d="M56.887,11.055a4,4,0,0,0-7.374-1.011,3.021,3.021,0,0,0-2.752.967,3.5,3.5,0,0,0-1.3,6.832A1,1,0,0,0,46,18H58a.929.929,0,0,0,.5-.145A3.5,3.5,0,0,0,57.5,11,3.468,3.468,0,0,0,56.887,11.055ZM59,14.5a1.5,1.5,0,0,1-1.2,1.47,1.088,1.088,0,0,0-.112.03H46.313a1.059,1.059,0,0,0-.114-.03A1.5,1.5,0,0,1,46.5,13a1.466,1.466,0,0,1,.427.074,1,1,0,0,0,1.183-.513,1.01,1.01,0,0,1,1.43-.4,1,1,0,0,0,1.518-.614,1.995,1.995,0,0,1,3.9.824,1,1,0,0,0,1.609.965A1.487,1.487,0,0,1,59,14.5Z" />
            <path d="M37.5,54a3.468,3.468,0,0,0-.613.055,4,4,0,0,0-7.374-1.011,3.021,3.021,0,0,0-2.752.967,3.5,3.5,0,0,0-1.3,6.832A1,1,0,0,0,26,61H38a.929.929,0,0,0,.5-.145A3.5,3.5,0,0,0,37.5,54Zm.3,4.97a1.088,1.088,0,0,0-.112.03H26.313a1.059,1.059,0,0,0-.114-.03A1.5,1.5,0,0,1,26.5,56a1.466,1.466,0,0,1,.427.074,1,1,0,0,0,1.183-.513,1.009,1.009,0,0,1,1.43-.4,1,1,0,0,0,1.518-.614,1.995,1.995,0,0,1,3.9.824,1,1,0,0,0,1.609.965A1.5,1.5,0,1,1,37.8,58.97Z" />
            <path d="M16.887,5.055A4,4,0,0,0,9.513,4.044a3.019,3.019,0,0,0-2.752.967,3.5,3.5,0,0,0-1.3,6.832A1,1,0,0,0,6,12H18a.929.929,0,0,0,.5-.145A3.5,3.5,0,0,0,17.5,5,3.468,3.468,0,0,0,16.887,5.055ZM19,8.5a1.5,1.5,0,0,1-1.2,1.47,1.088,1.088,0,0,0-.112.03H6.313A1.059,1.059,0,0,0,6.2,9.97,1.5,1.5,0,0,1,6.5,7a1.466,1.466,0,0,1,.427.074A1,1,0,0,0,8.11,6.561a1.009,1.009,0,0,1,1.43-.4,1,1,0,0,0,1.518-.614,1.995,1.995,0,0,1,3.9.824,1,1,0,0,0,1.609.965A1.487,1.487,0,0,1,19,8.5Z" />
        </g>
    </svg>
)

export default PackagePlaneSVG
