import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    textWhite: {
        color: 'white'
    },
    subTitle: {
        textAlign: 'center',

        [theme.breakpoints.up('lg')]: {
            fontSize: '38px'
        },
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: '30px'
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: '25px'
        }
    },
    description: {
        width: '80%',
        margin: '0 auto',
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            fontSize: '20px !important'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px !important'
        }
    },
    contactContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '4rem 0',
        [theme.breakpoints.down('sm')]: {
            padding: '1rem 0',
        }
    },
    contactBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        textAlign: 'center',
        flexDirection: 'column',
        '& .pipedriveWebForms': {
            marginTop: 30,
            '& iframe': {
                background: 'white',
                borderRadius: 12,
                minHeight: 700,
                padding: 50,
                [theme.breakpoints.down('sm')]: {
                    padding: 25,
                },
            }
        }
    }
}))
