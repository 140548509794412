import { makeStyles } from '@material-ui/core/styles'

export const useStyles = (color) => {
    return makeStyles((theme) => ({
        select: {
            '& .MuiPaper-root': {
                backgroundColor: 'red !important'
            },
            '& .MuiList-root': {
                backgroundColor: 'red !important'
            },
            '&:before': {
                borderColor: color
            },
            '&:after': {
                borderColor: color
            }
        },
        icon: {
            fill: color
        },
        paper: {
            backgroundColor: 'red',
            color: 'red'
        },
        langTypography: {
            color: 'var(--black)'
        },
        langsFlatten: {
            display: 'flex',
            flexDirection: 'row',
            gap: 5
        },
        linkSeperator: {
            color: 'white'
        }

    }))
}

export const selectPaperStyle = () => {
    return ({
        getContentAnchorEl: null,
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
        }
    })
}
