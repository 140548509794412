import React, { useState, useContext, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { Helmet } from 'react-helmet'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import RecommendedKots from 'components/RecommendedKots/RecommendedKots'
import RecommendedKotsForUser from 'pages/user/PersonalDashboard/components/RecommendedKotsForUser/RecommendedKotsForUser'
import { FormattedMessage } from 'react-intl'
import RouteMap from 'assets/RouteMap'
import { Image, Transformation } from 'cloudinary-react'
import { useStyles } from '../Lost_style'
import PreviewCard from 'components/PreviewCard/PreviewCard'
import axios from 'axios'

const LostPage = (props) => {
    const { user } = props
    const classes = useStyles()
    const [similarKot, setSimilarKot] = useState([])

    const usefulPages = [
        { page: '/', title: 'acceuil_page'},
        { page: 'first-jobs', title: 'first_job'},
        { page: 'internships', title: 'stage'},
        { page: 'student-jobs', title: 'student_job'},
        { page: 'student-rooms', title: 'kot'},
        { page: 'student-life', title: 'blog'},
    ]

    const displayUsefulPages = () => {
        return usefulPages.map((elem) => (
            <Typography key={elem.title}>
                <RouteMapLink className={classes.redirectLink} page={elem.page}>
                    <FormattedMessage id={`landing_aboutlost.lost_info.redirect_link_${elem.title}`} />
                </RouteMapLink>
            </Typography>
        ))
    }

    return (
        <Container maxWidth="md" className={classes.aboutLostContainer}>
            <Box className={classes.aboutLostContent}>
                <Box className={classes.title}><FormattedMessage id="landing_aboutlost.banner" /></Box>
                <Box className={classes.lostInfoContainer}>
                    <Typography variant="h1"><FormattedMessage id="landing_aboutlost.lost_info.head_1" /></Typography>
                    <Typography variant="h2"><FormattedMessage id="landing_aboutlost.lost_info.head_2" /></Typography>
                    { displayUsefulPages() }
                </Box>
            </Box>
            <Box className={classes.aboutLostImg}>
                <Image
                    cloudName="studentbe"
                    publicId='404_page.gif'
                    alt='404-lost-on-student-be'
                >
                    <Transformation q_auto='eco'/>
                </Image>
            </Box>
        </Container>
    )
}

export default LostPage
