import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import Button from 'shared/components/Button/Button'
import IconFactory from 'icons/IconFactory/IconFactory'
import ProgressCircle from 'components/ProgressCircle/ProgressCircle'

import Typography from '@material-ui/core/Typography'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import DialogActions from '@material-ui/core/DialogActions'

const BoostCompanyJobModalStep1View = (props) => {
    const { creditCount, closeModal, loading, boostJob } = props

    return (<>
        {loading && <ProgressCircle />}
        {!loading && <>
            <DialogContent>
                <Box>
                    <Grid container spacing={5} style={{ marginBottom: 20 }}>
                        <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
                            <Box style={{ display: 'flex', justifyContent: 'center', marginBottom: 15 }}>
                                <IconFactory icon='to-top' fontSize='1.5rem'/>
                            </Box>
                            <Typography variant='body2' style={{ textAlign: 'center' }}>
                                <FormattedMessage id='boost_popup.top_list' />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
                            <Box style={{ display: 'flex', justifyContent: 'center', marginBottom: 15 }}>
                                <IconFactory icon='view' fontSize='1.5rem' />
                            </Box>
                            <Typography variant='body2' style={{ textAlign: 'center' }}>
                                <FormattedMessage id="boost_popup.offer_visibility" />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Typography variant='subtitle1' style={{ textAlign: 'center' }} >
                        <FormattedMessage
                            id="boost_popup.current_credits"
                            values={{ userCredits: creditCount }}
                        />
                    </Typography>
                    <Typography variant='subtitle1' style={{ textAlign: 'center' }} >
                        <FormattedMessage
                            id='boost_popup.cost_credit'
                            values={{ costCredits: 1 }}
                        />
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant='secondary' size='small' onClick={closeModal}>
                    <FormattedMessage id="delete_offer_popup.option_no" />
                </Button>
                <Button variant='cta-secondary' size='small' onClick={boostJob}>
                    {creditCount < 1 ? <FormattedMessage id='active_popup.no_credits_button' /> : 'Boost'}
                </Button>
            </DialogActions>
        </>}
    </>)
}

export default injectIntl(BoostCompanyJobModalStep1View)
