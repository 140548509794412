import React, { useState, useEffect } from 'react'
import DashboardAPI from 'api/DashboardAPI'
import JobCardV2 from 'cards/JobCard/JobCardV2'
import RecommendedItemsContainer from 'components/RecommendedItemsContainer/RecommendedItemsContainer'
import RouteMap from 'assets/RouteMap'
import CollectionHandler from 'assets/CollectionHandler.js'

const RecommendedJobsForUser = (props) => {
    const { user, type, textValues, componentType, location } = props
    const [recommendedJobs, setRecommendedJobs] = useState([])
    const cityCollection = CollectionHandler.Get('cityCollection')

    useEffect(() => {
        getRecommendedJobs()
    }, [user, user.school_location, user.city])

    const getRecommendedJobs = () => {
        const data = {
            type: type,
            component_type: componentType,
            amount: 3
        }

        DashboardAPI.GetRecommendedJobs(data).then((response) => {
            setRecommendedJobs(response.data.recommended_jobs)
        })
    }

    if (recommendedJobs.length === 0) return null

    const moreItemsURL = () => {
        const params = ''
        if (type === 'student_job') {
            if (location) {
                const foundCity = cityCollection.find(city => Object.values(city).includes(location))
                if (foundCity) {
                    return RouteMap.Tag('student_job', 'cityCollection', foundCity.value_name)
                }
            }
        } else if (type === 'internship') {
            if (location) {
                const foundCity = cityCollection.find(city => Object.values(city).includes(location))
                if (foundCity) {
                    return RouteMap.Tag('internship', 'cityCollection', foundCity.value_name)
                }
            }
            if (user.current_experience?.study_domain) {
                return RouteMap.Tag('internship', 'studyDomainCollection', user.current_experience.study_domain)
            }
        } else {
            if (user.current_experience?.study_domain) {
                return RouteMap.Tag('first_job', 'studyDomainCollection', user.current_experience.study_domain)
            }
        }

        return RouteMap.Index(type, params)
    }

    return (
        <RecommendedItemsContainer
            moreItemsURL={moreItemsURL()}
            user={user}
            type={type}
            titleId={`personal_dashboard.recommend_jobs.title.${componentType}`}
            subTitleId={`personal_dashboard.recommend_jobs.sub_title.${componentType}`}
            textValues={textValues}
            recommendedItemType='job'
            key={type}
            cardDirection='horizontal'
        >
            {recommendedJobs.map((recommendedJob) => {
                return (
                    <JobCardV2
                        key={`job-card-${recommendedJob.id}-${type}`}
                        job={recommendedJob}
                        userType={user.type}
                    />
                )
            })}
        </RecommendedItemsContainer>
    )
}
export default RecommendedJobsForUser
