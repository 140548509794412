import React from 'react'

import HomeMyBSHelmet from 'helmets/HomeMyBSHelmet'
import PageWrapper from 'shared/wrappers/PageWrapper/PageWrapper'
import HomeMyBS from './HomeMyBS'

const HomeMyBSPage = (props) => {
    const { user } = props

    return (
        <PageWrapper user={user}>
            <HomeMyBSHelmet />
            <HomeMyBS />
        </PageWrapper>
    )
}

export default HomeMyBSPage
