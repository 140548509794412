import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    jobLinkContainer: {
        width: '100%'
    },
    jobBox: {
        width: '100%',
        paddingBottom: '20px',
        borderBottom: `0.5px solid ${theme.palette.fg.silver}`
    },
    jobTitle: {
        paddingBottom: '18px',
        color: theme.palette.fg.black,
        fontSize: '18px',
        lineHeight: '23px',
        '&:hover': {
            color: theme.palette.fg.purple
        }
    },
    jobInfoContainer: {
        display: 'flex',
        flexDirection: (props) => props.isWide ? 'row' : 'column',
        alignItems: (props) => props.isWide ? 'center' : 'flex-start',
        gap: 12,
    },
    tagsContainer: {
        overflowX: 'auto',
        display: 'flex',
        columnGap: '8px'
    },
    chip: {
        height: 'fit-content',
        backgroundColor: theme.palette.bg.greyLight,
        borderRadius: '50px',
        padding: '2px 10px',
        fontFamily: 'GreedStandard-Medium',
        fontSize: '10px',
        lineHeight: '18px',
        textTransform: 'capitalize',
    },
    chipLabel: {
        padding: 0
    },
    locationContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '7px'
    },
    location: {
        color: theme.palette.fg.black40Opacity,
        fontSize: '12px',
        lineHeight: '15px',
        textTransform: 'capitalize'
    },
}))
