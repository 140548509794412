import React from 'react'

import { useStyles } from './KotFiltersModalView_style'
import { Box } from '@material-ui/core'

import CityFilter from 'components/Searchbar/shared/CityFilter/CityFilter'
import TagMultiSelect from 'shared/components/TagMultiSelect/TagMultiSelect'
import CollectionHandler from 'assets/CollectionHandler.js'
import { FiltersModalWrapper, SectionTitle, SectionDescription } from 'modals/FilterModals/FiltersModalWrapper'

const KotFiltersModalView = (props) => {
    const classes = useStyles()
    const { reset, showModal, closeModal, offerCount, filters, handleInputSearchCity, searchCities, citiesInput, setSearchCities, setCitiesInput, setFilters, setFiltersPerType } = props

    const kotDisponibilityCollection = CollectionHandler.Get('kotDisponibilityCollection')
    const kotPriceCollection = CollectionHandler.Get('kotPriceCollection')
    const kotRoomCountCollection = CollectionHandler.Get('kotRoomCountCollection')
    const kotTypeCollection = CollectionHandler.Get('kotTypeCollection')

    return (
        <FiltersModalWrapper
            type='kot'
            titleId='job_filters.more_filters'
            buttonId='job_results.popup.save_button'
            showModal={showModal}
            closeModal={closeModal}
            offerCount={offerCount}
            reset={reset}
        >
            {/* Location section */}
            <Box className={classes.locationSectionContainer}>
                <SectionTitle id='kot_filters.text_filter.city.title' />
                <SectionDescription id='kot_filters.text_filter.city.description' />
                <Box className={classes.locationField}>
                    <CityFilter
                        handleInputSearchCity={handleInputSearchCity}
                        searchCities={searchCities}
                        citiesInput={citiesInput}
                        setSearchCities={setSearchCities}
                        setCitiesInput={setCitiesInput}
                        setFilters={setFilters}
                        filters={filters}
                        width={500}
                        showTags
                        dropdownPosition='left'
                        modal
                    />
                </Box>
            </Box>
            {/* Kot type section */}
            <Box className={classes.defaultSectionContainer}>
                <SectionTitle id='search_bar.kot_type.title' />
                <SectionDescription id='search_bar.kot_type.description' />
                <Box className={classes.tagsMultiSelectContainer}>
                    <TagMultiSelect
                        setSelectedItems={(selectedItems) => { setFiltersPerType(selectedItems, 'kotType') }}
                        selectedItems={filters.kotType}
                        collection={kotTypeCollection}
                        width={600}
                    />
                </Box>
            </Box>
            {/* Disponibility section */}
            <Box className={classes.defaultSectionContainer}>
                <SectionTitle id='search_bar.disponibility.title' />
                <SectionDescription id='search_bar.disponibility.description' />
                <Box className={classes.tagsMultiSelectContainer}>
                    <TagMultiSelect
                        setSelectedItems={(selectedItems) => { setFiltersPerType(selectedItems, 'disponibility') }}
                        selectedItems={filters.disponibility}
                        collection={kotDisponibilityCollection}
                        width={600}
                    />
                </Box>
            </Box>
            {/* Price section */}
            <Box className={classes.defaultSectionContainer}>
                <SectionTitle id='search_bar.price.title' />
                <SectionDescription id='search_bar.price.description' />
                <Box className={classes.tagsMultiSelectContainer}>
                    <TagMultiSelect
                        setSelectedItems={(selectedItems) => { setFiltersPerType(selectedItems, 'price') }}
                        selectedItems={filters.price}
                        collection={kotPriceCollection}
                        width={600}
                    />
                </Box>
            </Box>
            {/* Room count section */}
            <Box className={classes.roomCountSectionContainer}>
                <SectionTitle id='search_bar.rooms.title' />
                <SectionDescription id='search_bar.rooms.description' />
                <Box className={classes.tagsMultiSelectContainer}>
                    <TagMultiSelect
                        setSelectedItems={(selectedItems) => { setFiltersPerType(selectedItems, 'roomCount') }}
                        selectedItems={filters.roomCount}
                        collection={kotRoomCountCollection}
                        width={600}
                    />
                </Box>
            </Box>
        </FiltersModalWrapper>
    )
}

export default KotFiltersModalView
