import React, { useEffect, useState } from 'react'

import { useStyles } from './NavigationDropDownFilters_style'
import { Box } from '@material-ui/core'

import NavigationAPI from 'api/NavigationAPI'
import NavigationDropDownFilterCard from '../NavigationDropDownFilterCard/NavigationDropDownFilterCard'
import RouteMap from 'assets/RouteMap'

const NavigationDropDownFilters = (props) => {
    const classes = useStyles()
    const { type } = props

    const [filters, setFilters] = useState(false)

    useEffect(() => {
        NavigationAPI.GetNavigationCollection({
            locale: RouteMap.GetLocaleFromUrl(),
            type: type
        }).then((response) => {
            setFilters(response.data.filters)
        })
    }, [])

    const renderFilterCards = () => {
        return filters.map((filter, index) =>
            <Box
                className={classes.fieldCardContainer}
                key={`navigation-filter-card-${index}`}
            >
                <NavigationDropDownFilterCard
                    type={type}
                    filter={filter}
                />
            </Box>
        )
    }

    return (
        <Box className={classes.fieldsContainer}>
            {filters && renderFilterCards()}
        </Box>
    )
}

export default NavigationDropDownFilters
