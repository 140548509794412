/* eslint-disable react/prop-types */
import React, { useRef } from 'react'

import CompanyJobAPI from 'api/CompanyJobAPI'
import RouteMap from 'assets/RouteMap'
import IconFactory from 'icons/IconFactory/IconFactory'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import DateHandler from 'assets/DateHandler'
import ShareAction from 'components/ShareAction/ShareAction'
import Button from 'components/Button/Button'
import Paper from 'components/Paper/Paper'
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox'
import EmployerJobIndexHelper from 'pages/user/EmployerJobIndex/EmployerJobIndexHelper'

import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import { FormattedMessage } from 'react-intl'
import { useStyles } from './companyjobcard_style'

const CompanyJobCard = (props) => {
    const { job, user, toggleActive, handleJobCheck, checked, toggleBoost, showHeadhunt, removeCompanyJob } = props
    const containerRef = useRef(null)
    const classes = useStyles()

    const showUrl = RouteMap.Show(job.type, job.url)

    const duplicateJob = (job) => {
        CompanyJobAPI.DuplicateCompanyJob(job).then((response) => {
            window.location.href = RouteMap.Edit(response.data.type, response.data.id)
        })
    }

    const renderStatus = () => {
        let statusContainer

        if (user.show_attention_banner) {
            statusContainer = (
                <Box className={classes.waitingApproval}>
                    <FormattedMessage id='attention_banner.waiting_approval' />
                </Box>
            )
        } else {
            if ((new Date(job.active_until) < new Date(2100, 1, 1)) || job.deadline) {
                if (job.active) {
                    let statusDate
                    if (job.active_until) {
                        statusDate = DateHandler.ToBasicFormat(job.active_until)
                    }
                    if (job.deadline) {
                        statusDate = DateHandler.ToBasicFormat(job.deadline)
                    }

                    statusContainer = (
                        <Box className={`${classes.companyJobCardColumn} ${classes.companyJobStatus}`}>
                            <span><FormattedMessage id="company_job_card.active_until" /></span>
                            <span className={classes.date}>{statusDate}</span>
                        </Box>
                    )
                } else {
                    statusContainer = (
                        <Box className={`${classes.companyJobCardColumn} ${classes.companyJobStatus}`}>
                            <span><FormattedMessage id="company_job_card.deactivated_caps" /></span>
                        </Box>
                    )
                }
            } else {
                const statusText = job.active ? <FormattedMessage id="company_job_card.activated" /> : <FormattedMessage id="company_job_card.deactivated" />

                statusContainer = (
                    <Box className={`${classes.companyJobCardColumn} ${classes.companyJobStatus}`}>
                        <span>{statusText}</span>
                    </Box>
                )
            }
        }

        return statusContainer
    }

    const statusToggle = (status) => {
        return (
            <Box onClick={() => toggleActive(job)} className={`${classes.statusToggle} ${classes[status]}`}>
                <IconFactory
                    style={{ margin: 5 }}
                    icon={status === 'active' ? 'toggle-on' : 'toggle-off'}
                /> {status === 'active' ? <FormattedMessage id="company_job_card.activated" /> : <FormattedMessage id="company_job_card.deactivated" />}
            </Box>
        )
    }

    const getActiveColor = () => {
        return job.active ? 'var(--black)' : 'var(--grey)'
    }

    const activeStatus = job.active ? 'active' : 'inactive'
    const jobType = job.type?.replace('_', '-')

    return (
        <Box className={classes.companyJobCardContainer} ref={containerRef}>
            <Hidden mdDown>
                <Box className={classes.checkContainer}>
                    <CustomCheckbox
                        color='primary'
                        onChange={() => handleJobCheck(job)}
                        checked={checked}
                    />
                </Box>
            </Hidden>
            <Paper elevation={3} className={`${classes.companyJobCard} ${jobType}`}>
                <Hidden lgUp>
                    <Box className={`${classes.colorContainer} ${classes[activeStatus]}`}></Box>
                </Hidden>
                <Box className={`${classes.companyJobCardColumn} ${classes.companyJobType} ${classes[activeStatus]}`}>
                    <Typography variant='h5' component='div' className={classes.jobTypeText} style={{ wordBreak: 'normal' }}>
                        <FormattedMessage id={`type.${job.type}.singular`}>
                            {type => type.toUpperCase()}
                        </FormattedMessage>
                    </Typography>
                </Box>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} lg={3} className={`${classes.companyJobCardColumn} ${classes.companyJobName}`}>
                        <RouteMapLink showItemType={job.type} showItemUrl={job.url} >
                            {job.title}
                        </RouteMapLink>
                    </Grid>
                    <Hidden mdDown>
                        <Grid item xs={2}>
                            {renderStatus()}
                        </Grid>
                    </Hidden>
                    <Hidden lgUp>
                        {renderStatus()}
                    </Hidden>
                    <Grid item xs={12} lg={3} className={`${classes.companyJobCardColumn} ${classes.companyJobStats}`}>
                        <Box><span>{job.index_count || 0}</span> <FormattedMessage id="company_job_card.stats.view" /></Box>
                        <Box><span>{job.show_count || 0}</span> <FormattedMessage id="company_job_card.stats.clics" /></Box>
                        <Box><span>{job.candidacy_count || 0}</span> <FormattedMessage id="company_job_card.stats.candidats" /></Box>
                        <Box><span>{job.favourite_count || 0}</span> <FormattedMessage id="company_job_card.stats.favorites" /></Box>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        {job.last_candidacy_date &&
                            <Box className={classes.lastCandidacyDate}>
                                <FormattedMessage key='active' id='company_job_card.last_candidacy_date' />: {DateHandler.ToBasicFormat(job.last_candidacy_date)}
                            </Box>
                        }
                        <Box className={`${classes.companyJobCardColumn} ${classes.companyJobButtons}`}>
                            <Box display="flex">
                                {EmployerJobIndexHelper.userHasAccessToBoost(job, user, true) &&
                                    <Button
                                        variant='secondary'
                                        size='small'
                                        jobType={jobType + '-color'}
                                        status={activeStatus}
                                        onClick={() => toggleBoost(job)}
                                        disabled={!EmployerJobIndexHelper.isJobBoostable(job)}
                                        className={!EmployerJobIndexHelper.isJobBoostable(job) && classes.inactive}
                                    >
                                        {EmployerJobIndexHelper.isJobBoostable(job)
                                            ? <FormattedMessage id="company_job_card.buttons.boost" />
                                            : <FormattedMessage id="company_job_card.buttons.boosted" />
                                        }
                                    </Button>
                                }
                                {job.type === 'student_job' && showHeadhunt && job.active &&
                                    <Box ml={1}>
                                        <Button
                                            variant='secondary'
                                            size='small'
                                            jobType={jobType + '-color'}
                                            href={RouteMap.Page('users') + 'headhunt?jobId=' + job.id + '&jobTitle=' + job.title}
                                        >
                                            <FormattedMessage id="company_job_card.buttons.headhunt" />
                                        </Button>
                                    </Box>
                                }
                            </Box>
                            <Box ml={1}>
                                <Button
                                    variant='secondary'
                                    size='small'
                                    status={activeStatus}
                                    href={RouteMap.Page('users') + 'job_results?jobId=' + job.id + '&jobType=' + job.type}
                                >
                                    <FormattedMessage id="company_job_card.buttons.candidats" />
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
            <Box className={`${classes.hoverActionContainer} ${jobType} ${classes[activeStatus]}`}>
                <Box className={classes.actionIconContainer}>
                    <Tooltip title={<FormattedMessage id="company_job_card.tooltips.show_offer" />}>
                        <IconButton
                            href={showUrl}
                            target='_blank'
                            style={{ color: getActiveColor(), padding: 5 }}
                        >
                            <IconFactory icon='view' />
                        </IconButton>
                    </Tooltip>
                    <ShareAction
                        infos={{
                            title: job.title,
                            url: RouteMap.Show(job.type, job.url),
                            type: job.type
                        }}
                        tooltip='Partager'
                        size='18px'
                        color={getActiveColor()}
                        padding='5px'
                    />
                    <Tooltip title={<FormattedMessage id="company_job_card.tooltips.edit_offer" />}>
                        <IconButton
                            href={RouteMap.Edit(job.type, job.id)}
                            target='_blank'
                            style={{ color: getActiveColor(), padding: 5 }}
                        >
                            <IconFactory icon='edit' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id="company_job_card.tooltips.duplicate_offer" />}>
                        <IconButton
                            onClick={() => duplicateJob(job)}
                            style={{ color: getActiveColor(), padding: 5 }}
                        >
                            <IconFactory icon='duplicate' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id="company_job_card.tooltips.delete_offer" />}>
                        <IconButton
                            onClick={() => removeCompanyJob(job)}
                            style={{ color: getActiveColor(), padding: 5 }}
                        >
                            <IconFactory icon='delete' />
                        </IconButton>
                    </Tooltip>
                </Box>
                {statusToggle(activeStatus)}
            </Box>
        </Box>
    )
}

export default CompanyJobCard
