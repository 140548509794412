/* eslint-disable react/prop-types */
import React from 'react'
import InfoLineView from './InfoLineView/InfoLineView'

const PaidLine = (props) => {
    const { job } = props

    return (
        <InfoLineView
            icon='location-pin'
            text={job.address.city}
        />
    )
}

export default PaidLine
