import React from 'react'
import OfferMessageCard from '../MessagesCard/OfferMessageCard'

export default class IndexEmployerByOffer extends React.Component {
    render () {
        if (!(this.props.offers && this.props.offers.length > 0)) { return null }
        let i = 0
        let jobCards = this.props.offers.sort((a, b) => {
            if (a.last_message_time === null) {
                return 1
            } else if (b.last_message_time === null) {
                return -1
            }
            return Date.parse(b.last_message_time) - Date.parse(a.last_message_time)
        })
        jobCards = jobCards.map((offer) => {
            i++
            return (
                <OfferMessageCard
                    offer={offer}
                    openItem={this.props.openItem}
                    key={'offer-message-card-' + i}
                />
            )
        })

        return (
            <div className="kot-owner-index-main">
                {jobCards}
            </div>
        )
    }
}
