import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './Welcome_style'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Container } from '@material-ui/core'

import PageWrapper from 'shared/wrappers/PageWrapper/PageWrapper'
import RouteMap from 'assets/RouteMap'
import Button from 'shared/components/Button/Button'

class Welcome extends React.Component {
    constructor(props) {
        super(props)
        this.createPageContent = this.createPageContent.bind(this)
        this.userStoredLocation = window.location.search.replace('?redirect=', '').replace('null', '')
        console.log(this.userStoredLocation)
    }

    redirectUser(redirectionUrl = null) {
        if (this.userStoredLocation) {
            window.location.href = this.userStoredLocation
        } else {
            window.location.href = RouteMap.Page('employer/new-ad')
        }
    }

    createPageContent() {
        const { classes } = this.props
        return (
            <Container maxWidth="lg" className={classes.welcomeContainer}>
                <Typography className={classes.pageTitle} variant="h1">
                    <FormattedMessage id="landing_login_page.sign_up.employer_title" />
                </Typography>
                <div>
                    <Typography className={classes.textContent}>
                        <FormattedMessage id="landing_login_page.sign_up.description" />
                    </Typography>
                </div>
                <div>
                    <Typography variant='h2' component='h3' className={classes.subtitle}>
                        <FormattedMessage id="landing_login_page.sign_up.how" />
                    </Typography>
                    <Typography className={classes.textContent}>
                        <FormattedMessage id="landing_login_page.sign_up.text_info" />
                    </Typography>
                </div>
                <div>
                    <Typography variant='h2' component='h3' className={classes.subtitle}>
                        <FormattedMessage id="landing_login_page.sign_up.more" />
                    </Typography>
                    <Typography className={classes.textContent}>
                        <FormattedMessage id="landing_login_page.sign_up.more_text" />
                    </Typography>
                </div>
                <Button onClick={() => this.redirectUser()}>
                    <FormattedMessage id="landing_login_page.sign_up.post_annonce_button_text" />
                </Button>
            </Container>
        )
    }

    render() {
        return (
            <PageWrapper user={this.props.user}>
                {this.createPageContent()}
            </PageWrapper>
        )
    }
}
export default withStyles(useStyles)(Welcome)
