/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react'

import Carousel from 'react-material-ui-carousel'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import CardFactory from '../../cards/CardFactory/CardFactory'
import { useWindowSize } from '../../../../hooks/useWindowSize'

const CarouselCards = (props) => {
    const { windowSize } = useWindowSize()
    const [numberCards, setNumberCards] = useState(4)
    const [maxBullets, setMaxBullets] = useState(24)
    const [cards, setCards] = useState([])

    useEffect(
        () => {
            initState()
            window.addEventListener('resize', screenSizeListener)

            return () => window.removeEventListener('resize', screenSizeListener)
        }, [windowSize, props.cards]
    )

    const screenSizeListener = () => {
        const width = windowSize.width

        let numberCardsTmp = 4
        let maxBulletsTmp = 8
        if (props.fullWidth) {
            if (width < 580) {
                numberCardsTmp = 1
                maxBulletsTmp = 10
            } else if (width < 900) {
                numberCardsTmp = 2
                maxBulletsTmp = 8
            } else if (width < 1200) {
                numberCardsTmp = 3
                maxBulletsTmp = 8
            }
        } else {
            if (width < 580) {
                numberCardsTmp = 1
                maxBulletsTmp = 10
            } else if (width < 1250) {
                numberCardsTmp = 2
                maxBulletsTmp = 8
            } else {
                numberCardsTmp = 3
                maxBulletsTmp = 8
            }
        }

        setNumberCards(numberCardsTmp)
        setMaxBullets(maxBulletsTmp)
        setCards(props.cards.slice(0, maxBulletsTmp * numberCardsTmp))
    }

    const initState = () => {
        screenSizeListener()
    }

    // cardsRef={props.cardsRef[indexCard + i]}
    // TODO: include refs in CardFactory
    const generateCards = (indexCard) => {
        const cardsContainer = []
        for (var i = 0; i < numberCards; i++) {
            if (indexCard + i < cards.length) {
                cardsContainer.push(
                    <Grid item xs={12 / numberCards} key={indexCard + i}>
                        <CardFactory
                            card={cards[indexCard + i]}
                            type={props.type}
                            cardsRef={props.cardsRef ? props.cardsRef[indexCard + i] : null}
                            displayShow={props.displayShow}
                            userType={props.userType}
                            userAddress={props.userAddress}
                            showJobType
                            key={indexCard + i}
                        />
                    </Grid>
                )
            }
        }
        return cardsContainer
    }

    const generateContainer = () => {
        const gridContainer = []
        for (var i = 0; i < cards.length; i += numberCards) {
            gridContainer.push(
                <Grid container spacing={2} style={{ padding: '1px 0' }} key={'gridContainer-' + i}>
                    {generateCards(i)}
                </Grid>
            )
        }
        return gridContainer
    }

    return (
        <Box id={props.id}>
            <Carousel
                id={props.id}
                autoPlay={false}
                animation='slide'
                navButtonsAlwaysInvisible
                navButtonsAlwaysVisible={false}
                indicators={cards.length > numberCards}
                indicatorContainerProps={{
                    className: 'indicatorsContainer',
                    style: { marginTop: 20 }
                }}
            >
                {generateContainer()}
            </Carousel>
        </Box>
    )
}

export default CarouselCards
