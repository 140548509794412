import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    boxContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px 0',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-start',
            width: '49%'
        },
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    borderColor: {
        color: 'e32b47'
    },
    boxInput: {
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            marginBottom: 10
        }
    },
    titleTextBox: {
        marginBottom: '10px'
    },
    inputBase: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        [theme.breakpoints.up('md')]: {
            marginRight: 10
        },
        '&::placeholder': {
            fontStyle: 'italic',
            fontSize: 14
        }
    },
    locationsDropdown: {
        width: '100%',
        [theme.breakpoints.down('md')]: {
            marginBottom: 10
        },
        [theme.breakpoints.up('md')]: {
            marginRight: 10
        }
    },
}))
