import React from 'react'

const TraineeSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 78.02 86"
        {...props}
    >
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path d="M78,42.58a32.67,32.67,0,0,0-.18-3.28,23,23,0,0,0-2.1-7.61,23.29,23.29,0,0,0-9.52-10.42A24.07,24.07,0,0,0,61,19V14a8,8,0,0,0-8-8H31a8,8,0,0,0-8,8v5a22.83,22.83,0,0,0-4.73,2,23.26,23.26,0,0,0-9.17,9.1A24.33,24.33,0,0,0,6,42.18Q6,55.49,6,68.79v8.86c0,.23,0,.46,0,.63A8,8,0,0,0,14,86h8a7.8,7.8,0,0,0,2-.27A7.94,7.94,0,0,0,26,86H58a8,8,0,0,0,2-.27,8,8,0,0,0,2,.27h8a8,8,0,0,0,8-8V42.58Z" />
                <path d="M72,36.58a32.67,32.67,0,0,0-.18-3.28,23,23,0,0,0-2.1-7.61,23.29,23.29,0,0,0-9.52-10.42A24.07,24.07,0,0,0,55,13V8a8,8,0,0,0-8-8H25a8,8,0,0,0-8,8v5A23.47,23.47,0,0,0,3.1,24.11,24.22,24.22,0,0,0,0,36.18Q0,49.49,0,62.79v8.86c0,.23,0,.46,0,.63A8,8,0,0,0,8,80h8a7.86,7.86,0,0,0,2-.27A7.94,7.94,0,0,0,20,80H52a8,8,0,0,0,2-.27,8,8,0,0,0,2,.27h8a8,8,0,0,0,8-8V36.58Z" />
                <path
                    className="cls-1"
                    fill="white"
                    d="M64,36.59c0-.83-.06-1.67-.14-2.5a15.2,15.2,0,0,0-1.37-5,15.47,15.47,0,0,0-6.3-6.92A16.07,16.07,0,0,0,49,20.05L47,20V8H25V20l-.11,0h-.57a17,17,0,0,0-8.19,2,15.4,15.4,0,0,0-6.08,6A16.5,16.5,0,0,0,8,36.19Q8,53.92,8,71.65c0,.12,0,.24,0,.35h8V44H56.05V72h8v-.48Q64,54,64,36.59ZM31,14H41v6H31ZM48,34H24V30H48Z"
                />
                <path className="cls-1" d="M20,72H52V60H20Z" />
                <path className="cls-1" d="M20,56H52V48H20Z" />
            </g>
        </g>
    </svg>

)

export default TraineeSVG
