import React from 'react'

import { useStyles } from './CompanyFiltersModalView_style'
import { Box } from '@material-ui/core'

import TextFilter from 'components/Searchbar/shared/TextFilter/TextFilter'
import CityFilter from 'components/Searchbar/shared/CityFilter/CityFilter'
import TagMultiSelect from 'shared/components/TagMultiSelect/TagMultiSelect'
import CollectionHandler from 'assets/CollectionHandler.js'
import { FiltersModalWrapper, SectionTitle, SectionDescription } from 'modals/FilterModals/FiltersModalWrapper'
import MultipleSelectFilter from 'components/Searchbar/shared/MultipleSelectFilter/MultipleSelectFilter'

const CompanyFiltersModalView = (props) => {
    const classes = useStyles()
    const { reset, showModal, closeModal, offerCount, debouncedFilterChange, handleFilterChange, filters, handleInputSearchCity, searchCities, citiesInput, setSearchCities, setCitiesInput, setFilters, setFiltersPerType } = props

    const companySizeCollection = CollectionHandler.Get('companySizeCollection')
    const sectorCollection = CollectionHandler.Get('sectorCollection')
    const jobTypeCollection = CollectionHandler.Get('jobTypeCollection')

    return (
        <FiltersModalWrapper
            type='company'
            titleId='job_filters.more_filters'
            buttonId='job_results.popup.save_button'
            showModal={showModal}
            closeModal={closeModal}
            offerCount={offerCount}
            reset={reset}
        >
            {/* Search a company section */}
            <Box className={classes.searchCompanySectionContainer}>
                <SectionTitle id='job_filters.text_filter.title' />
                <SectionDescription id='job_filters.text_filter.description' />
                <Box className={classes.searchCompanyField}>
                    <TextFilter
                        debouncedFilterChange={debouncedFilterChange}
                        handleFilterChange={handleFilterChange}
                        filters={filters}
                        setFilters={setFilters}
                        filterTextId='search_bar.search.company_text'
                        modal
                    />
                </Box>
            </Box>
            {/* Location section */}
            <Box className={classes.locationSectionContainer}>
                <SectionTitle id='job_filters.location.title' />
                <SectionDescription id='job_filters.location.description' />
                <Box className={classes.locationField}>
                    <CityFilter
                        handleInputSearchCity={handleInputSearchCity}
                        searchCities={searchCities}
                        citiesInput={citiesInput}
                        setSearchCities={setSearchCities}
                        setCitiesInput={setCitiesInput}
                        setFilters={setFilters}
                        filters={filters}
                        width={500}
                        showTags
                        dropdownPosition='left'
                        modal
                    />
                </Box>
            </Box>
            {/* Sector section */}
            <Box className={classes.sectorSectionContainer}>
                <SectionTitle id='job_filters.sector.title' />
                <SectionDescription id='job_filters.sector.description' />
                <Box className={classes.sectorField}>
                    <MultipleSelectFilter
                        type='sector'
                        collection={sectorCollection}
                        filters={filters}
                        setFilters={setFilters}
                        width={500}
                        filterTextId='search_bar.sector'
                        showTags
                        dropdownPosition='left'
                        modal
                    />
                </Box>
            </Box>
            {/* Company size section */}
            <Box className={classes.companySizeSectionContainer}>
                <SectionTitle id='job_filters.type_of_company.title' />
                <SectionDescription id='job_filters.type_of_company.description' />
                <Box className={classes.tagsMultiSelectContainer}>
                    <TagMultiSelect
                        setSelectedItems={(selectedItems) => { setFiltersPerType(selectedItems, 'companySize') }}
                        selectedItems={filters.companySize}
                        collection={companySizeCollection}
                        width={600}
                    />
                </Box>
            </Box>

            {/* Job type section */}
            <Box className={classes.jobTypeSectionContainer}>
                <SectionTitle id='job_filters.searching_job_type.title' />
                <SectionDescription id='job_filters.searching_job_type.description' />
                <Box className={classes.tagsMultiSelectContainer}>
                    <TagMultiSelect
                        setSelectedItems={(selectedItems) => { setFiltersPerType(selectedItems, 'jobType') }}
                        selectedItems={filters.jobType}
                        collection={jobTypeCollection}
                        width={600}
                    />
                </Box>
            </Box>
        </FiltersModalWrapper>
    )
}

export default CompanyFiltersModalView
