import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    jobCardWrapper: {
        cursor: 'pointer',
        width: '100%'
    },
    routeMapLinkStyle: {
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: 320,
        minWidth: 280,
        maxWidth: 320,
        width: '100%',
        backgroundColor: 'white',
        boxShadow: 'var(--shadow-paper)',
        borderRadius: 12,
        margin: '0 auto'
    },
    jobCardImage: {
        height: '45%',
        position: 'relative'
    },
    cardMedia: {
        height: '100%',
        width: '100%',
        borderRadius: '12px 12px 0 0',
        objectFit: 'cover'
    },
    company: {
        position: 'absolute',
        left: 12,
        bottom: 12,
        display: 'flex',
        alignItems: 'flex-end',
        '& img': {
            width: 55,
            height: 55,
            objectFit: 'contain',
            borderRadius: 10,
            backgroundColor: 'white'
        }
    },
    companyInfo: {
        color: 'white',
        margin: '0 12px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end'
    },
    companyInfoName: {
        color: 'white',
        textTransform: 'uppercase'
    },
    companyInfoType: {
        color: 'white',
        fontFamily: "'GintoNormal-Light', sans-serif !important",
        fontSize: 12,
        fontWeight: 300
    },
    jobCardText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexGrow: 1,
        padding: 16
    },
    jobTitleAndFavourite: {
        display: 'flex',
        flexDirection: 'row'
    },
    jobTitle: {
        flexGrow: 1,
        overflow: 'hidden',
        textOverflow: 'clip',
        lineHeight: 1.4,
        maxHeight: 'calc(16px * 1.4 * 3)' // title max 3 lines -> calc(fontSize * lineHeight * number of lines)
    },
    jobFavourite: {
        display: 'flex',
        alignItems: 'baseline'
    },
    jobDetails: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: 'var(--medium-grey)',
        fontSize: '12px !important',
        whiteSpace: 'nowrap',
        overflowX: 'clip'
    },
    city: {
        fontFamily: "'GintoNormal-Light', sans-serif !important",
        fontSize: '12px !important',
        fontWeight: 300
    },
    bullet: {
        fontSize: 6,
        margin: '0 4px'
    },
    peopleWanted: {
        fontFamily: "'GintoNormal-Medium', sans-serif !important"
    },
    noMargin: {
        margin: 0
    },

    chipRoot: {
        height: 'fit-content',
        cursor: 'pointer'
    },
    chipLabel: {
        padding: 0
    },
    chip: {
        backgroundColor: 'var(--beige)',
        borderRadius: 6,
        fontSize: 10,
        padding: '6px 10px'
    }
}))
