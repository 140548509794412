import axios from 'axios'
import RouteMap from '../assets/RouteMap.js'

import { ARTICLE_RECENT_API, ARTICLE_MOST_READ_API, ARTICLE_EBOOK_VIA_EMAIL_API, ARTICLE_SIMILAR_JOBS_API, ARTICLE_SIMILAR_ARTICLES_API, ARTICLE_REACTION_OPTIONS, ARTICLE_ADD_REACTION, ARTICLE_FILTER, ARTICLE_SEARCH_ARTICLES_WITH_TAGS_API, ARTICLE_SUBSCRIBE_TO_NEWSLETTER, ARTICLE_CLASSICS_API } from './env'

const GetRecentArticles = (data) => {
    return axios({
        url: ARTICLE_RECENT_API,
        method: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

const getMostReadArticles = (data) => {
    return axios({
        url: ARTICLE_MOST_READ_API,
        get: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

const getClassicArticles = (data) => {
    return axios({
        url: ARTICLE_CLASSICS_API,
        get: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

const GetSimilarJobs = (data) => {
    return axios({
        url: ARTICLE_SIMILAR_JOBS_API,
        method: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

const GetSimilarArticles = (data) => {
    return axios({
        url: ARTICLE_SIMILAR_ARTICLES_API,
        method: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

const sendEbookViaEmail = (data) => {
    return axios({
        url: ARTICLE_EBOOK_VIA_EMAIL_API,
        method: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

const getReactionOptions = () => {
    return axios({
        url: ARTICLE_REACTION_OPTIONS,
        method: 'get'
    })
}

const addReactionToArticle = (data) => {
    return axios({
        url: ARTICLE_ADD_REACTION,
        method: 'post',
        params: {
            ...data
        }
    })
}

const filterArticle = (data) => {
    return axios({
        url: ARTICLE_FILTER,
        method: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

const searchArticlesWithTags = (data) => {
    return axios({
        url: ARTICLE_SEARCH_ARTICLES_WITH_TAGS_API,
        method: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

const subscribeToNewsletter = (data) => {
    return axios({
        url: ARTICLE_SUBSCRIBE_TO_NEWSLETTER,
        method: 'post',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

export default {
    GetRecentArticles,
    getMostReadArticles,
    getClassicArticles,
    GetSimilarArticles,
    GetSimilarJobs,
    addReactionToArticle,
    filterArticle,
    getReactionOptions,
    sendEbookViaEmail,
    searchArticlesWithTags,
    subscribeToNewsletter
}
