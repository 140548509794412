import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: 'var(--background-yellow)',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            height: '157px',
        },
        height: '172px',
        borderRadius: '20px',
        display: 'flex',
        flex: 1,
    },
    containerLeft: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            alignItems: 'center',
            padding: '30px',
        },
        gap: '15px',
        paddingLeft: '46px',
        paddingTop: '42px',
    },
    containerRight: {
        display: 'flex',
        flex: 1.25,
    },
    image: {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
        textAlign: 'center',
    },
    alertContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '26px 34px',
        backgroundColor: 'var(--background-yellow)',
        borderRadius: '20px',
        height: '100%',
        gap: '17px',
    },
    alertButtonStyle: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '234px',
        },
    },
    alertText: {
        lineHeight: 1.2,
        color: 'var(--background-black)',
        fontSize: '20px',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    textAlertButton: {
        color: 'var(--background-white)',
        fontSize: '14px',
        textTransform: 'none',
    },
}))
