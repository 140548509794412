import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    largeAvatar: {
        width: 125,
        height: 125,
        cursor: 'pointer'
    },
    fab: {
        width: 40,
        height: 40,
        zIndex: 9,
        position: 'absolute',
        right: 0,
        backgroundColor: 'black',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))
