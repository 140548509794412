import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.fg['black'],
        fontSize: '18px',
        lineHeight: '28px',
        textAlign: 'center'
    },
    button: {
        textTransform: 'capitalize !important'
    }
}))
