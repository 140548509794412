import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    companyEditBannerContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: 20,
        margin: '24px auto',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 20,
            flexDirection: 'column'
        }
    },
    companyEditBanner: {
        display: 'flex',
        alignItems: 'center',
        gap: 20
    },
    logoBox: {
        position: 'relative',
        overflow: 'hidden',
        display: 'block',
        width: 80,
        minWidth: 80,
        height: 80,
        background: 'var(--background-white)',
        boxShadow: 'var(--box-shadow)',
        border: 'var(--border)',
        borderRadius: '50%',
        [theme.breakpoints.down('sm')]: {
            width: 60,
            minWidth: 60,
            height: 60,
        }
    },
    logo: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
    companyName: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    bannerTitle: {
        textTransform: 'capitalize'
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: 8
        }
    },
    customButton: {
        minWidth: 180,
        '&:hover': {
            border: 'var(--border)'
        }
    }
}))
