import React from 'react'

const EventPaidSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86 82.24" {...props}>
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path d="M83.63,34.38a8,8,0,0,0,0-11.37l-1.78-1.77A15.84,15.84,0,0,0,70.5,16.58a16.05,16.05,0,0,0-5.6,1A16.24,16.24,0,0,0,61.65,13L56.9,8.31a8,8,0,0,0-11.26,0L42.53,11.4a7.14,7.14,0,0,0-.77.88,8,8,0,0,0-8.14,1.91L30.5,17.27A8,8,0,0,0,28.13,23a8.14,8.14,0,0,0,.29,2.15,8.67,8.67,0,0,0-1.33.18,8,8,0,0,0-5.77,5.09L6.48,71.52A8,8,0,0,0,16.67,81.78L58.24,67.09a8,8,0,0,0,5.09-9.54l1.23-1.22L66.29,58a8,8,0,0,0,11.25,0L80.66,55a8,8,0,0,0,0-11.38l-4-4a8,8,0,0,0,3.88-2.13Z" />
                <path d="M77.63,28.38a8,8,0,0,0,0-11.37l-1.79-1.77A15.8,15.8,0,0,0,64.5,10.58a16.05,16.05,0,0,0-5.6,1A16.24,16.24,0,0,0,55.65,7L50.9,2.31a8,8,0,0,0-11.26,0L36.53,5.4a7.14,7.14,0,0,0-.77.88,8,8,0,0,0-8.14,1.91L24.5,11.27A8,8,0,0,0,22.12,17a8.15,8.15,0,0,0,.3,2.15,8.5,8.5,0,0,0-1.33.18,8,8,0,0,0-5.77,5.09L.48,65.52A8,8,0,0,0,10.67,75.78L52.24,61.09a8,8,0,0,0,5.14-5.8,7.88,7.88,0,0,0-.05-3.74l1.23-1.22L60.29,52a8,8,0,0,0,11.25,0L74.66,49a8,8,0,0,0,0-11.38l-4-4a8,8,0,0,0,3.88-2.13Z" />
                <path fill='white' d="M8,68.23,22.85,27.1,49.58,53.54ZM45.27,40.47l-3.12-3.09L58.78,20.93a8.14,8.14,0,0,1,11.44,0L72,22.69l-3.12,3.09L67.1,24a3.8,3.8,0,0,0-5.2,0ZM33.39,28.72l-3.12-3.09,1.79-1.76a3.52,3.52,0,0,0,0-5L30.13,17l3.11-3.08,1.93,1.91a7.73,7.73,0,0,1,0,11.16Zm5.94,5.87-3.12-3.08,10.7-10.58a3.47,3.47,0,0,0,1-2.57,3.46,3.46,0,0,0-1-2.57l-4.76-4.7L45.27,8,50,12.7a7.65,7.65,0,0,1,2.38,5.66A7.63,7.63,0,0,1,50,24ZM51.21,46.34l-3.12-3.08,4.76-4.7a8.12,8.12,0,0,1,11.43,0L69,43.26l-3.12,3.08-4.75-4.7a3.8,3.8,0,0,0-5.2,0Z" />
            </g>
        </g>
    </svg>
)

export default EventPaidSVG
