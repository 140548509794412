import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    boxContainer: {
        margin: '24px 0'
    },
    dialogContainer: {
        backgroundColor: theme.palette.bg['white'],
        padding: '20px',
        [theme.breakpoints.up('md')]: {
            boxShadow: `4px 4px 0px 0px ${theme.palette.bg['black']}`,
            borderRadius: "19.5px",
            border: `2px solid ${theme.palette.fg['black']}`,
            padding: '44px 74px 44px 56px',
        },
    },
    dialogContent: {
        padding: '0px',
    },
    dialogTitleContainer: {
        padding: '0px 0px 20px',
        borderBottom: `0.5px solid ${theme.palette.fg['beigeLight']}`
    },
    dialogTitle: {
        color: theme.palette.fg['black'],
        fontFamily: 'GreedWide-Bold',
        fontSize: '29px',
        lineHeight: "49px",
    },
    underTitle: {
        color: '#8F95B2',
        marginTop: 4,
        marginBottom: 20
    },
    divider: {
        margin: '36px 0',
        [theme.breakpoints.up('md')]: {
            margin: '48px 0'
        }
    },
    closeIcon: {
        height: '18px',
        width: '18px',
        position: 'absolute',
        top: 31,
        right: 40,
        backgroundColor: 'transparent',
        color: theme.palette.fg['black'],
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    filterBox: {
        marginTop: 20,
    },
    showButton: {
        [theme.breakpoints.up('sm')]: {
            width: '346px !important',
        },
    },
    cancelButton: {
        borderBottom: '2px solid',
        paddingBottom: 4,
        cursor: 'pointer',
        color: 'black'
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: '20px',
        width: '100%',
        paddingTop: '20px',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: '44px',
        }
    },
    dialogAction: {
        padding: 0,
        borderTop: `0.5px solid ${theme.palette.fg['beigeLight']}`,
    },
    sectionTitle: {
        color: theme.palette.fg['black'],
        fontFamily: 'GreedWide-Bold',
        fontSize: '29px',
        lineHeight: "49px"
    },
    sectionDescription: {
        color: theme.palette.fg['black'],
        fontSize: '16px',
        lineHeight: "18px"
    }
}))
