import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    inputLabel: {
        color: theme.palette.fg['black'],
        fontFamily: 'GreedStandard-Medium',
        lineHeight: '17px'
    },
    input: {
        paddingBottom: '12px !important'
    },
    inputUnderline: {
        '&::after': {
            borderBottom: `0.5px solid ${theme.palette.fg['greySlate']}`
        },
    },
    chevronIcon: {
        paddingRight: '17px'
    },
    errorText: {
        color: theme.palette.error.main
    }
}))
