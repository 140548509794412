import { defaultLocale } from '../assets/i18n/default'
import RegexHelper from 'assets/RegexHelper'

const GetLocaleFromUrl = (defaultLang) => {
    const lang = window.location.pathname.match(RegexHelper.languageRegex) && window.location.pathname.match(RegexHelper.languageRegex)[1]
    if (['en', 'fr', 'nl'].includes(lang.toLowerCase())) {
        return lang.toLowerCase()
    } else if (['en', 'fr', 'nl'].includes(defaultLang)) {
        return defaultLang
    } else if (['en', 'fr', 'nl'].includes(defaultLocale)) {
        return defaultLocale
    } else {
        return 'nl'
    }
}

const removeLastSlash = () => {
    const pathname = location.pathname
    return pathname.slice(-1) === '/'
        ? pathname.slice(0, -1)
        : pathname
}

const getJobTypeFromUrl = () => {
    let pathname = location.pathname
    pathname = pathname.slice(1)
    const [_, jobTypePluralized] = pathname.split('/')
    if (jobTypePluralized.match(/jobs-etudiants|studentenjobs|student-jobs/)) { return 'student_job' }
    if (jobTypePluralized.match(/stages|internships/)) { return 'internship' }
    if (jobTypePluralized.match(/premiers-emplois|eerste-jobs|first-jobs/)) { return 'first_job' }

    return null
}

const addEndingBackslashWhenMissing = (url) => {
    if (url.endsWith('/')) {
        return url
    } else {
        return url + '/'
    }
}

const getImageUrlForVideo = (url) => {
    const info = getVideoSourceInfo(url)
    if (!info) return

    const urlForSource = buildUrlForSource(info.source, info.sourceId)
    return urlForSource
}

const getVideoSourceInfo = (url) => {
    const youtubeParsed = getYouTubeVideoID(url)
    if (youtubeParsed) return { source: 'youtube', sourceId: youtubeParsed }

    const dailymotionParsed = getDailyMotionVideoID(url)
    if (dailymotionParsed) return { source: 'dailymotion', sourceId: dailymotionParsed }

    const vimeoParsed = getVimeoVideoID(url)
    if (vimeoParsed) return { source: 'vimeo', sourceId: vimeoParsed }
}

const getYouTubeVideoID = (url) => {
    const match = RegexHelper.youTubeRegex.exec(url)
    return match ? match[1] : null
}

const getDailyMotionVideoID = (url) => {
    const match = RegexHelper.dailyMotionRegex.exec(url)
    if (!match) return false

    return match[2] || match[1]
}

const getVimeoVideoID = (url) => {
    const match = RegexHelper.vimeoRegex.exec(url)
    if (!match) return false
    if (match[6]) return `${match[5]}?h=${match[6]}`

    return match[5]
}

const buildUrlForSource = (source, sourceId) => {
    if (source === 'youtube') return youtubeImageUrlBuilder(sourceId)
    if (source === 'vimeo') return vimeoImageUrlBuilder(sourceId)
    if (source === 'dailymotion') return dailyMotionUrlBuilder(sourceId)
}

const youtubeImageUrlBuilder = (sourceId, quality = 'hqdefault') => {
    return `https://img.youtube.com/vi/${sourceId}/${quality}.jpg`
}

const vimeoImageUrlBuilder = (sourceId, quality = 'hqdefault') => {
    return 'https://res.cloudinary.com/studentbe/image/upload/v1729840726/assets/video_thumbnail_default.gif'
}

const dailyMotionUrlBuilder = (sourceId) => {
    return `https://www.dailymotion.com/thumbnail/video/${sourceId}`
}

const EncodeTagToUrl = (tag) => {
    return tag.toLowerCase()
        .replace(/[^\w\u00C0-\u017F]+/g, '-') // Replaces any non char to dash (keeping accents char)
        .replace(/\-\-+/g, '-') // Then replace double dash to single dash.
        .replace(/(^-|-$)/g, '') // Removes any dashes at the start or end of the string
        .replace(/[éèë]/g, 'e')
}

export default {
    GetLocaleFromUrl,
    removeLastSlash,
    getJobTypeFromUrl,
    getImageUrlForVideo,
    getVideoSourceInfo,
    getYouTubeVideoID,
    getDailyMotionVideoID,
    getVimeoVideoID,
    youtubeImageUrlBuilder,
    EncodeTagToUrl,
    addEndingBackslashWhenMissing
}
