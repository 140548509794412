import React from 'react'
import Bugsnag from '@bugsnag/js'
import axios from 'axios'

import LineButton from 'buttons/LineButton/LineButton'
import FilledButton from 'buttons/FilledButton/FilledButton'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'

import MenuItem from '@material-ui/core/MenuItem'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import { FormattedMessage } from 'react-intl'

import RouteMap from 'assets/RouteMap'

export default class NewAdMain extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            redirectionUrl: this.props.selectedType ? this.props.urls[this.props.selectedType] : ''
        }
        this.handleChange = this.handleChange.bind(this)
        this.redirect = this.redirect.bind(this)
        this.updateUserLocation = this.updateUserLocation.bind(this)
        this.typeUrls = [
            ['new_ad.first_job', RouteMap.Page('users/first_jobs/new')],
            ['new_ad.student_job', RouteMap.Page('users/student_jobs/new')],
            ['new_ad.internship', RouteMap.Page('users/internships/new')]
        ]
    }

    componentDidMount () {
        axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    }

    handleChange (event) {
        const { name, value } = event.target
        this.setState({ [name]: value })
    }

    redirect () {
        if (this.state.redirectionUrl) {
            this.updateUserLocation()
        }
    }

    updateUserLocation () {
        axios({
            url: this.props.urls.updateUserStoredLocation,
            method: 'post',
            data: {
                location: this.state.redirectionUrl
            }
        })
            .then((response) => {
                console.log('User location updated successfully')
                window.location.href = this.state.redirectionUrl
            })
            .catch(function (error) {
                Bugsnag.notify(error)
            })
    }

    render () {
        return (
            <PageWrapper user={this.props.user}>
                <Container maxWidth='sm' style={{ margin: '15vh auto' }}>
                    <Grid container spacing={10}>
                        <Grid item xs={12}>
                            <Box display='flex' alignItems="center" flexDirection="column">
                                <Typography variant='body2'>
                                    <FormattedMessage id="new_ad.new_ad_body" />
                                </Typography>
                                <Box mt={2}>
                                    <Select
                                        value={this.state.redirectionUrl}
                                        variant='outlined'
                                        onChange={this.handleChange}
                                        name="redirectionUrl"
                                        displayEmpty
                                        color='primary'
                                        style={{ height: 35, width: 200 }}
                                        MenuProps={{
                                            disableScrollLock: true,
                                            getContentAnchorEl: null,
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left'
                                            }
                                        }}
                                    >
                                        <MenuItem disabled value="">
                                            <Typography variant='subtitle1' style={{ fontStyle: 'italic' }}>
                                                <FormattedMessage id="new_ad.job_type" />
                                            </Typography>
                                        </MenuItem>
                                        {
                                            this.typeUrls.map((url, index) => (
                                                <MenuItem key={index} value={url[1]}>
                                                    <Typography variant='body2'>
                                                        {<FormattedMessage id={url[0]} />}
                                                    </Typography>
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center'>
                                <LineButton
                                    name={<FormattedMessage id="new_ad.price_button" />}
                                    href={RouteMap.Page('payment')}
                                    style={{ marginRight: 10 }}
                                />
                                <FilledButton
                                    color='secondary'
                                    name={<FormattedMessage id="new_ad.next" />}
                                    onClick={this.redirect}
                                    style={{ marginLeft: 10 }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </PageWrapper>
        )
    }
}

