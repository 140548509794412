import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    burgerMenuContainer: {
        width: '100%'
    },

    linksContainer: {
        width: '100%',
        padding: '0px 16px 24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: '16px'
    }
}))
