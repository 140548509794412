/* eslint-disable react/prop-types */
import React from 'react'
import InfoLineView from './InfoLineView/InfoLineView'
import CollectionHandler from 'assets/CollectionHandler.js'
import { FormattedMessage } from 'react-intl'

const FunctionTitleLine = (props) => {
    const { job } = props
    if (!job.department) return null

    return (
        <InfoLineView
            icon='function'
            text={<FormattedMessage
                id='job_show.job_info_card.department'
                values={{ function: CollectionHandler.Translate('departmentCollection', job.department) }}
            />}
        />
    )
}

export default FunctionTitleLine
