import React, { useEffect } from 'react'

const AdminDashboard = (props) => {
    return (
        <div>
            <h1>For the last 30 days</h1>
            <br/>
            <p>First Job candidacies: {props.firstJobCandidacies}</p>
            <p>Internship candidacies: {props.internshipCandidacies}</p>
            <p>Student Job candidacies: {props.studentJobCandidacies}</p>
            <br/>
            <p>First Job alerts: {props.firstJobAlerts}</p>
            <p>Internship alerts: {props.internshipAlerts}</p>
            <p>Student Job alerts: {props.studentJobAlerts}</p>
        </div>
    )
}

export default AdminDashboard
