import React from 'react'

const PlaySVG = (props) => (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 538.41 547.55">
        <circle cx="282.41" cy="291.55" r="256"/>
        <circle cx="256.25" cy="255.75" r="227.79" fill="#fff"/>
        <path d="M256.25,48.25c114.88,0,208,93.12,208,208s-93.12,208-208,208S48.25,371.13,48.25,256.25,141.37,48.25,256.25,48.25ZM256.25,512.25c141.38,0,256-114.62,256-256S397.63.25,256.25.25.25,114.87.25,256.25s114.62,256,256,256Z" stroke="#000" stroke-miterlimit="10" stroke-width=".5"/>
        <polygon points="370.56 256.72 275.91 311.5 188.2 362 188.95 256.72 188.2 151.43 273.5 200.81 370.56 256.72" stroke="#000" stroke-miterlimit="10" stroke-width=".5"/>
    </svg>
)

export default PlaySVG
