import React from 'react'

import css from './checkbutton.scss'
import css_responsive from './checkbutton_responsive.scss'

import IconFactory from 'icons/IconFactory/IconFactory'
import Checkbox from '@material-ui/core/Checkbox'

export default class CheckButton extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            check: false
        }
        this.check = this.check.bind(this)
        this.setCheck = this.setCheck.bind(this)
        this.setCheckNoFire = this.setCheckNoFire.bind(this)
        this.toggle = this.toggle.bind(this)
    }

    check () {
        this.setState({
            check: !this.state.check
        }, () => {
            this.props.onClick(this.state.check)
        })
    }

    setCheck (check) {
        if (this.state.check != check) {
            this.check()
        }
    }

    setCheckNoFire (check) {
        this.setState({ check: check })
    }

    toggle () {
        this.setCheck(!this.state.check)
    }

    render () {
        return (
            <Checkbox
                color='primary'
                onClick={this.check}
                checked={this.state.check}
                icon={<IconFactory icon='checkbox-false' />}
                checkedIcon={<IconFactory icon='checkbox-true' />}
            />
        )
    }
}
