import React from 'react'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import AboutKotsHelmet from 'helmets/AboutKotsHelmet'
import Button from 'components/Button/Button'
import LocationsListContainer from 'components/LocationsListContainer/LocationsListContainer'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import { FormattedMessage } from 'react-intl'
import { useStyles } from '../AboutFirstJobs/aboutabout_style'
import Container from '@material-ui/core/Container'
import NewsAndTips from 'components/NewsAndTips/NewsAndTips'
import RecommendedJobsView from 'components/RecommendedJobsView/RecommendedJobsView'
import RouteMap from 'assets/RouteMap.js'

const AboutKots = (props) => {
    const { lastOffers, recentArticles, user } = props
    const classes = useStyles()

    return (
        <PageWrapper user={user}>
            <AboutKotsHelmet />
            <Box className={classes.aboutPageBanner}>
                <Container maxWidth='md'>
                    <Typography variant='h1' className={classes.titlePage}>
                        <FormattedMessage id='landing_about_kots.search_kots_title' />
                    </Typography>
                </Container>
            </Box>
            <Container maxWidth='md' className={classes.aboutPageBox}>
                <Box>
                    <Typography className={classes.breakline}>
                        <FormattedMessage id='landing_about_kots.want_kot' />
                        {' '}
                        <RouteMapLink page='/' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_pages.studentbe' />
                        </RouteMapLink>
                        <FormattedMessage id='landing_about_kots.student_opportunity' />
                        {' '}
                        <RouteMapLink page='student-rooms' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_pages.studentbe' />
                        </RouteMapLink>.
                    </Typography>
                    <Typography className={classes.breakline}>
                        <FormattedMessage id='landing_about_kots.go_to' />
                        <RouteMapLink page='student-rooms' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_kots.kots' />
                        </RouteMapLink>
                        <FormattedMessage id='landing_about_kots.define_search_criteria' />
                    </Typography>
                    <Typography className={classes.breakline}>
                        <FormattedMessage id='landing_about_kots.on_website' />
                        <RouteMapLink page='student-life/kot' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_kots.blog' />
                        </RouteMapLink>
                        <FormattedMessage id='landing_about_kots.discover_articles' />
                        <RouteMapLink page='student-life' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_kots.blog' />
                        </RouteMapLink>
                        <FormattedMessage id='landing_about_kots.check_it_out' />
                    </Typography>
                    <Typography className={classes.breakline}>
                        <FormattedMessage id='landing_about_kots.ideal_kots' />
                    </Typography>
                    <Typography className={classes.breakline}>
                        <FormattedMessage id='landing_about_kots.found_kots_near_you' />
                        {' '}
                        <RouteMapLink page='student-rooms' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_pages.studentbe' />
                        </RouteMapLink>
                        <FormattedMessage id='landing_about_kots.select_city' />
                    </Typography>
                </Box>
                <Box my={5}>
                    <LocationsListContainer type='student-rooms' />
                </Box>
                <Box>
                    <Typography className={classes.breakline}>
                        <FormattedMessage id='landing_about_kots.found_city' />
                        <RouteMapLink page='student-rooms' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_kots.kots' />
                        </RouteMapLink> !
                    </Typography>
                    <Typography variant='h2' className={classes.subtitle}>
                        <FormattedMessage id='landing_about_kots.kots_research' />
                    </Typography>
                    <Typography className={classes.breakline}>
                        <FormattedMessage id='landing_about_pages.studentbe' />
                        <FormattedMessage id='landing_about_pages.help_you_to_found' />
                        <RouteMapLink page='student-jobs' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_pages.student_job' />
                        </RouteMapLink>
                        <FormattedMessage id='landing_about_pages.a' />
                        <RouteMapLink page='internships' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_pages.internship' />
                        </RouteMapLink>
                        <FormattedMessage id='landing_about_pages.and_even_a' />
                        <RouteMapLink page='first-jobs' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_pages.first_job' />
                        </RouteMapLink>
                        <FormattedMessage id='landing_about_pages.trying_your_luck' />
                        {' '}
                        <RouteMapLink page='/' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_pages.studentbe' />
                        </RouteMapLink>
                        <FormattedMessage id='landing_about_pages.offers_internship' />
                    </Typography>
                </Box>
                <Box className={classes.buttonOffers}>
                    <RouteMapLink page='student-rooms'>
                        <Button variant='primary'>
                            <FormattedMessage id='landing_about_pages.consult_offers' />
                        </Button>
                    </RouteMapLink>
                </Box>
            </Container>
            <RecommendedJobsView
                items={lastOffers}
                title={<FormattedMessage id='landing_about_pages.discover_last_kots' />}
                moreItemsText={<FormattedMessage id='job_show.recommended_jobs.more_link_kot' />}
                moreItemsURL={RouteMap.Index('student_job')}
            />
            <NewsAndTips
                item={{ type: 'kot' }}
                articles={recentArticles}
                subTitleId='landing_about_pages.advice_to_find'
            />
        </PageWrapper>
    )
}

export default AboutKots
