import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    field: {
        width: '100%'
    },
    error: {
        color: 'red'
    },
    errorText: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        '& .Mui-error': {
            fontSize: '14px !important'
        }
    },
    inputBase: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        '&::placeholder': {
            fontStyle: 'italic',
            fontSize: '14px !important'
        }
    },
    paper: {
        [theme.breakpoints.down('md')]: {
            padding: 15
        },
        [theme.breakpoints.up('md')]: {
            padding: '30px 50px'
        }
    }
}))
