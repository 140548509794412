import axios from 'axios'

import {
    RESET_PASSWORD_API,
    CHANGE_PASSWORD_API
} from './env'

function RequestPasswordResetEmail (data) {
    return axios({
        url: RESET_PASSWORD_API,
        method: 'post',
        data: data
    })
}

function ChangePassword (data) {
    return axios({
        url: CHANGE_PASSWORD_API,
        method: 'post',
        data: data
    })
}

export default {
    RequestPasswordResetEmail,
    ChangePassword
}
