import React, { useState } from 'react'

import { useStyles } from './PasswordInput_style'
import { Box, FormControl, TextField, InputAdornment, IconButton } from '@material-ui/core'

import IconFactory from 'icons/IconFactory/IconFactory'

const PasswordInput = (props) => {
    const [showPassword, setShowPassword] = useState(false)
    const classes = useStyles()

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    return (
        <Box>
            <FormControl variant='outlined' fullWidth={true}>
                <TextField
                    value={props.value}
                    onChange={props.onChange}
                    error={props.error}
                    helperText={props.helperText}
                    required={true}
                    type={showPassword ? 'text' : 'password'}
                    name={props.name}
                    autoComplete='on'
                    FormHelperTextProps={{ style: { color: 'var(--error-red)' } }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                <IconFactory icon={showPassword ? 'eye' : 'eye-slash'} style={{ width: '22px' }} />
                            </IconButton>
                        </InputAdornment>,
                        classes: { underline: classes.inputUnderline }
                    }}
                />
            </FormControl>
        </Box>
    )
}

export default PasswordInput
