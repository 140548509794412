/* eslint-disable react/prop-types */
import React from 'react'
import Tag from 'components/Tag/Tag'
import { FormattedMessage } from 'react-intl'

const BooleanFilterView = (props) => {
    const { type, filters, handleTagChange, filterTextId } = props

    return (
        <Tag
            key={filters[type]}
            item={{ name: <FormattedMessage id={filterTextId} /> }}
            isSelected={filters[type]}
            handleClick={handleTagChange}
        />
    )
}

export default BooleanFilterView
