import React from 'react'
import { Helmet } from 'react-helmet'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import LostKotPage from './LostKotPage/LostKotPage'
import LostCompanyPage from './LostCompanyPage/LostCompanyPage'
import LostPage from './LostPage/LostPage'

const Lost = (props) => {
    const { user } = props

    const getPageType = () => {
        const pathname = window.location.pathname.replace(/%C3%A8/g, 'è').replace(/%C3%A9/g, 'é').replace(/\_/g, '-')
        const path = pathname.match(/^\/[^\/]{2}\/([^\/]*)/)[1]
        if (['student-jobs', 'jobs-etudiants', 'studentenjobs'].includes(path)) return 'student_job'
        if (['internships', 'stages'].includes(path)) return 'internship'
        if (['first-jobs', 'premiers-emplois', 'stages'].includes(path)) return 'first_job'
        if (['student-life'].includes(path)) return 'article'
        if (['companies', 'entreprises', 'bedrijven'].includes(path)) return 'company'
        if (['student-rooms', 'kots-a-louer', 'koten'].includes(path)) return 'kot'
        return 'default'
    }

    const pageType = getPageType()

    return (
        <PageWrapper user={user}>
            <Helmet>
                <meta name="robots" content="noindex" />
                <meta name="googlebot" content="noindex" />
            </Helmet>
            {pageType === 'default' && <LostPage user={user} />}
            {pageType === 'kot' && <LostKotPage user={user} />}
            {pageType === 'company' && <LostCompanyPage user={user} />}
        </PageWrapper>
    )
}

export default Lost
