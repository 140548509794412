import React, { useState, useRef } from 'react'
import { Box, ClickAwayListener, MenuList, Popper } from '@material-ui/core'

import StudentJobSearchStatusHelper from '../StudentJobSearchStatusHelper'
import StudentJobSearchStatusChip from './StudentJobSearchStatusChip'
import IconFactory from 'icons/IconFactory/IconFactory'
import { useStyles } from '../StudentJobSearchStatus_style'

const StudentJobSearchStatusMinimizedView = (props) => {
    const { statusConfig, handleClick } = props
    const classes = useStyles()
    const anchorRef = useRef(null)
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [anchorEl, setAnchor] = useState(null)

    const statusUsedInDropdown = Object.keys(statusConfig).find(key => statusConfig[key] === true)

    const openDropdown = (event) => {
        setDropdownOpen(true)
        setAnchor(event.currentTarget)
    }

    const closeDropdown = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) return
        setDropdownOpen(false)
        setAnchor(null)
    }

    return (<>
        <Box className={`${classes.main} ${classes.selected}`} onClick={openDropdown}>
            <IconFactory icon={StudentJobSearchStatusHelper.GetIcon(statusUsedInDropdown)} color={StudentJobSearchStatusHelper.GetColor(statusUsedInDropdown)} fontSize='20px'/>
            <IconFactory icon='down' fontSize='12px'/>
        </Box>
        <Popper className={classes.dropdown} placement={'bottom-end'} open={dropdownOpen} anchorEl={anchorEl} disablePortal>
            <ClickAwayListener onClickAway={closeDropdown}>
                <MenuList className={classes.options}>
                    <Box className={classes.chips}>
                        {Object.keys(statusConfig).map(key => {
                            return (
                                <StudentJobSearchStatusChip
                                    key={key}
                                    keyName={key}
                                    value={statusConfig[key]}
                                    handleClick={handleClick}
                                />
                            )
                        })}
                    </Box>
                </MenuList>
            </ClickAwayListener>
        </Popper>
    </>)
}

export default StudentJobSearchStatusMinimizedView
