import React from 'react'
import { Box, Button, Typography } from '@material-ui/core'

import { useStyles } from './NavigationDropDownTitle_style'
import IconFactory from 'shared/icons/IconFactory/IconFactory'

const NavigationDropDownTitle = (props) => {
    const classes = useStyles()
    const {
        pageLink,
        seeAllButton,
        title
    } = props

    return (
        <Box className={classes.dropDownArticlesContainer}>
            <Box className={classes.titleContainer}>
                <Typography variant='h6' component='div' className={classes.title}>
                    {title}
                </Typography>
                <Button
                    className={classes.seeAll}
                    href={pageLink}
                >
                    <Typography variant='h4' component='div' color='secondary'>
                        {seeAllButton}
                    </Typography>
                    <IconFactory icon='arrow-right' fontSize={10} color={'var(--background-purple)'} />
                </Button>
            </Box>
        </Box>
    )
}

export default NavigationDropDownTitle
