const isJobBoostable = (job) => {
    if (job.latest_boost_or_activation_or_sort_date) {
        const latestBoostOrActivationDate = new Date(job.latest_boost_or_activation_or_sort_date)
        latestBoostOrActivationDate.setDate(latestBoostOrActivationDate.getDate() + 7)
        const todayDate = new Date()

        return todayDate >= latestBoostOrActivationDate
    }

    return true
}

const userHasAccessToBoost = (job, user, userIsJobOwner) => {
    if (!user) return false

    const today = new Date()
    const sevenDaysAgo = today.setDate(today.getDate() - 7)
    const cooldownTimeAfterCreation = new Date(job.created_at) <= sevenDaysAgo
    const isBoostAvailable = userIsJobOwner && job.type !== 'first_job' && !user.gold_licence && !user.silver_licence && job.active && cooldownTimeAfterCreation

    return isBoostAvailable
}

export default { isJobBoostable, userHasAccessToBoost }
