import axios from 'axios'
import RouteMap from '../assets/RouteMap.js'

import {
    USER_JOB_API,
    SIMILAR_FIRST_JOBS_API,
    SIMILAR_STUDENT_JOBS_API,
    SIMILAR_INTERNSHIPS_API
} from './env'

const GetSimilarJobAPIEndpoint = (jobType) => {
    switch (jobType) {
    case 'first_job':
        return SIMILAR_FIRST_JOBS_API
    case 'student_job':
        return SIMILAR_STUDENT_JOBS_API
    case 'internship':
        return SIMILAR_INTERNSHIPS_API
    }
}

const GetSimilarJobs = (data) => {
    return axios({
        url: GetSimilarJobAPIEndpoint(data.job_type),
        method: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

const createJob = (data) => {
    return axios({
        url: USER_JOB_API,
        method: 'post',
        data: data
    })
}

const updateJob = (data) => {
    return axios({
        url: USER_JOB_API,
        method: 'patch',
        data: data
    })
}

export default { GetSimilarJobs, createJob, updateJob }
