import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    userActionsLogoutContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '4px',
        [theme.breakpoints.up('lg')]: {
            columnGap: '12px',
        }
    },
    actionButton: {
        paddingLeft: '11px',
        paddingRight: '11px',
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '16px',
            paddingRight: '16px',
        }
    }
}))
