import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    aboutPageBanner: {
        minHeight: '66px',
        background: 'var(--background-blue)',
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20,
        [theme.breakpoints.up('lg')]: {
            width: 1280,
            borderRadius: 12,
            margin: 'auto',
            marginBottom: 20,
            marginTop: 20
        },
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    titlePage: {
        color: 'black',
        padding: '1rem 0',
        [theme.breakpoints.up('sm')]: {
            textAlign: 'center'
        }
    },
    subTitle: {
        textAlign: 'center',
        color: 'black',
        paddingBottom: 10
    }
}))
