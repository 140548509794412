import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    baseButton: {
        width: 'fit-content',
        borderRadius: '50px',
        textTransform: 'none',
        '&:hover': {
            transition: 'all 0.3s ease',
            boxShadow: `4px 4px 0px 0px ${theme.palette.bg['black']}`,
        },
        '&:disabled': {
            opacity: '0.5',
        },
    },

    bigButton: {
        height: '56px',
        padding: '19px 30px',
        fontFamily: 'GreedStandard-Bold',
        fontSize: '16px',
        lineHeight: '18px',
        textTransform: 'uppercase',
    },

    smallButton: {
        height: '32px',
        padding: '7px 16px',
        fontFamily: 'GreedStandard-Medium',
        fontSize: '14px',
        lineHeight: '18px',
    },

    primaryButton: {
        border: `2px solid ${theme.palette.fg['black']}`,
        backgroundColor: theme.palette.bg['black'],
        color: theme.palette.fg['white'],
        '&:hover': {
            backgroundColor: theme.palette.bg['purple'],
        },
        '&:disabled': {
            color: theme.palette.fg['white'],
        },
    },

    secondaryButton: {
        border: `2px solid ${theme.palette.fg['black']}`,
        backgroundColor: theme.palette.bg['white'],
        color: theme.palette.fg['black'],
        '&:hover': {
            backgroundColor: theme.palette.bg['white'],
        },
        '&:disabled': {
            color: theme.palette.fg['black'],
        },
    },

    tertiaryButton: {
        border: `2px solid ${theme.palette.fg['white']}`,
        backgroundColor: theme.palette.bg['purple'],
        color: theme.palette.fg['white'],
        '&:hover': {
            borderColor: theme.palette.fg['black'],
            backgroundColor: theme.palette.bg['white'],
            color: theme.palette.fg['black'],
        },
        '&:disabled': {
            color: theme.palette.fg['white'],
        },
    },

    ctaPrimaryButton: {
        border: `2px solid ${theme.palette.fg['black']}`,
        backgroundColor: theme.palette.bg['black'],
        color: theme.palette.fg['white'],
        '&:hover': {
            backgroundColor: theme.palette.bg['white'],
            color: theme.palette.fg['black'],
        },
        '&:disabled': {
            color: theme.palette.fg['white'],
        },
    },

    ctaSecondaryButton: {
        backgroundColor: theme.palette.bg['purple'],
        color: theme.palette.fg['white'],
        '&:hover': {
            backgroundColor: theme.palette.bg['purple'],
        },
        '&:disabled': {
            color: theme.palette.fg['white'],
        },
    },

    buttonDisabled: {
        backgroundColor: theme.palette.bg['silver']
    }
}))
