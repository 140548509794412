import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    button: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            marginTop: 12,
        },
    },
    customButton: {
        width: '100%',
    },
    textButton: {
        color: 'var(--background-white)',
        fontSize: '16px',
    },
}))
