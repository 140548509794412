import RouteMap from './RouteMap'

const ToSchemaFormat = (dateString) => {
    // Return 'YYY-MM-DD' for schema.org datetime props
    return new Date(dateString).toISOString().split('T')[0]
}
const ToBasicFormat = (dateString) => {
    // Return 'DD/MM/YYYY' for standard dates
    if (!dateString) return null
    return new Date(dateString).toLocaleString('fr-BE', { day: 'numeric', month: 'numeric', year: 'numeric' })
}

const toAge = (birthday) => {
    const birthdate = new Date(birthday)
    let age = new Date().getFullYear() - birthdate.getFullYear()
    const month = new Date().getMonth() - birthdate.getMonth()
    if (month < 0 || (month === 0 && new Date().getDate() < birthdate.getDate())) {
        age--
    }
    return age
}

const toReadingFormat = (dateString) => {
    // Return '2 dec. 2022' format
    if (!dateString) return null

    const locale = RouteMap.GetLocaleFromUrl()
    const frenchOrDutch = locale.match(/^(nl|fr)/)
    const localeForTranslation = frenchOrDutch ? `${locale}-BE` : 'en-GB'
    return new Date(dateString).toLocaleString(localeForTranslation, { dateStyle: 'medium' })
}

const getDaysUntilDate = (date) => {
    const targetDate = new Date(date)
    const today = new Date()
    const differenceInTime = targetDate.getTime() - today.getTime()
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24))

    return differenceInDays
}

const isDateOlderThan = (date, monthsAgo) => {
    const providedDate = new Date(date)
    const monthsAgoDate = new Date()
    monthsAgoDate.setMonth(monthsAgoDate.getMonth() - monthsAgo)

    return providedDate < monthsAgoDate
}

const yearIsInCurrentAcademicYear = (year) => {
    if(!year) return false

    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()

    const academicStartMonth = 9 // September (1-based index)
    const academicEndMonth = 8 // August (1-based index)

    const currentAcademicEndYear = currentDate.getMonth() + 1 >= academicStartMonth ? currentYear + 1 : currentYear

    return year <= currentAcademicEndYear
}

export default { ToSchemaFormat, ToBasicFormat, toReadingFormat, toAge, isDateOlderThan, getDaysUntilDate, yearIsInCurrentAcademicYear }