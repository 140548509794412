import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './MultipleSelectFilterView_style'
import { Box, Select, Avatar, MenuItem, Button, Checkbox, ListItemText, IconButton, Input, FormControl, Typography, useTheme } from '@material-ui/core'

import IconFactory from 'icons/IconFactory/IconFactory'
import FilterTagList from 'components/Searchbar/shared/FilterTagList/FilterTagList'

const MultipleSelectFilterView = (props) => {
    const { handleFiltersArray, type, filters, collection, filterTextId, width, showTags, handleTagChange, dropdownPosition, modal } = props
    const classes = useStyles()
    const [openDropdown, setOpenDropdown] = useState(false)
    const theme = useTheme()

    const selectPaperStyle = () => {
        return ({
            disableScrollLock: true,
            getContentAnchorEl: null,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: dropdownPosition || 'center'
            },
            transformOrigin: {
                vertical: -20,
                horizontal: dropdownPosition !== 'left' && (width / 2)
            },
            MenuListProps: {
                style: {
                    paddingTop: 0,
                    paddingBottom: 0
                }
            },
            PaperProps: {
                style: {
                    borderRadius: 16,
                    maxHeight: 350,
                    width: width,
                    boxShadow: `4px 4px 0px 0px ${theme.palette.bg.black}`,
                    border: `2px solid ${theme.palette.fg.black}`,
                    backgroundColor: theme.palette.bg.white
                }
            }
        })
    }

    const renderArrow = () => {
        if (filters[type].length) return null

        return <IconFactory icon='down-solid' fontSize='14px' className={classes.triggerArrowIcon} />
    }

    const renderSelectValue = (selected) => {
        return (
            <Box className={classes.triggerPlaceholderContainer}>
                <Typography className={classes.triggerPlaceholderText}>
                    <FormattedMessage id={filterTextId} />
                </Typography>

                {selected.length === 0
                    ? renderArrow()
                    : <Avatar className={classes.tagAvatar} >{selected.length}</Avatar>}
            </Box>
        )
    }

    return (
        <Box className={classes.multiSelectFilterViewContainer}>
            <FormControl className={classes.multiSelectFilterViewFormControl}>
                <Button className={`${classes.multiSelectFilterViewTrigger} ${openDropdown && classes.multiSelectFilterViewTriggerClicked}`} disableRipple>
                    <Select
                        multiple
                        value={filters[type]}
                        displayEmpty
                        onChange={(event) => { handleFiltersArray(type, event) }}
                        input={<Input className={classes.selectInput} />}
                        classes={{ root: classes.select }}
                        disableUnderline
                        renderValue={(selected) => renderSelectValue(selected)}
                        MenuProps={selectPaperStyle()}
                        IconComponent={() => <Box />}
                        open={openDropdown}
                        onClose={() => setOpenDropdown(false)}
                        onOpen={() => setOpenDropdown(true)}
                    >
                        <Box onClick={() => setOpenDropdown(false)} className={classes.menuHeader}>
                            <MenuItem disabled value={null} />
                            <IconButton
                                aria-label='close'
                                className={classes.closeIconButton}
                            >
                                <IconFactory icon='close' />
                            </IconButton>
                        </Box>
                        {collection.map((item, key) => (
                            <MenuItem className={classes.menuItem} key={key} value={{ name: item.name, value: item.value }}>
                                <Checkbox
                                    disableRipple
                                    classes={{ root: classes.checkbox }}
                                    icon={<IconFactory icon='checkbox-false' fontSize='20px' />}
                                    checkedIcon={<IconFactory icon='checkbox-true-filled' color={theme.palette.bg.purple} fontSize='20px' />}
                                    checked={filters[type].some(elem => elem.value === item.value)}
                                />
                                <ListItemText primary={item.name} primaryTypographyProps={{ variant: 'body2' }} />
                            </MenuItem>
                        ))}
                    </Select>
                </Button>
            </FormControl>
            {showTags &&
                <FilterTagList handleChange={handleTagChange} items={filters[type]} />
            }
        </Box>
    )
}

export default MultipleSelectFilterView
