import React from 'react'

import { useStyles } from './MyMessagesIcon_style'
import { useStyles as useDarkModeStyles } from './MyMessageIconDarkMode_style'
import { Badge, Button, useTheme } from '@material-ui/core'

import IconFactory from 'icons/IconFactory/IconFactory'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const MyMessagesIcon = (props) => {
    const { user, darkMode, iconColor } = props

    const classes = darkMode ? useDarkModeStyles() : useStyles()

    const theme = useTheme()

    return (
        <RouteMapLink page='messages' key='button-connected-user-navbar-link-to-messages'>
            <Button className={classes.button} disableRipple>
                <Badge
                    badgeContent={user.unseen_messages > 20 ? '20+' : user.unseen_messages}
                    color="error"
                    overlap="circle"
                    className={classes.badge}
                >
                    <IconFactory icon='conversation' className={classes.myMessagesIcon} color={iconColor ?? theme.palette.fg['white']} />
                </Badge>
            </Button>
        </RouteMapLink>
    )
}

export default MyMessagesIcon
