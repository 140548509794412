import React, { useState } from 'react'
import PaymentAPI from 'api/PaymentAPI'
import BoostCompanyJobModalView from './BoostCompanyJobModalView/BoostCompanyJobModalView'
import { injectIntl } from 'react-intl'
import URLParamsHandler from 'assets/URLParamsHandler'
import AnalyticsHelper from 'assets/AnalyticsHelper'

const BoostCompanyJobModal = (props) => {
    const { showModal, setShowModal, setFeedback, jobToBoost, user, company, toggleActiveJobStatus, creditCount, filters } = props
    if (!showModal) return null

    const { formatMessage } = props.intl

    const [step, setStep] = useState(1)
    const [loading, setLoading] = useState(false)
    const [selectedPack, setSelectedPack] = useState(null)

    const closeModal = () => {
        setShowModal(false)
    }

    const boostJob = () => {
        setLoading(true)
        if (creditCount === 0) {
            setStep(2)
            setLoading(false)
        } else {
            const data = { items: [{
                    id: jobToBoost.id,
                    type: jobToBoost.type
                }]
            }
            PaymentAPI.boostJob(data).then((response) => {
                toggleActiveJobStatus(
                    jobToBoost.type,
                    jobToBoost.id,
                    true,
                    response.data.credit_count,
                    response.data.active_until
                )
                setShowModal(false)
                setLoading(false)
                setFeedback({
                    open: true,
                    severity: 'success',
                    message: formatMessage({ id: 'boost_popup.succeed_message' })
                })
            }).catch(error => {
                setFeedback({
                    open: true,
                    severity: 'error',
                    message: formatMessage({ id: 'boost_popup.failed_message' })
                })
            })
        }
    }

    const buyCredits = (selectedPackage) => {
        PaymentAPI.newPayment({
            package_name: selectedPackage.name,
            package_credits_cost: selectedPackage.credits,
            process: 'buying_credits',
            success_url: '/',
            succes_params: `${URLParamsHandler.getURLSearchParams('company_job', filters)}&process=buy_credits_and_boost_job&job_id=${jobToBoost.id}&job_type=${jobToBoost.type}`,
            cancel_url: window.location.href
        },
        AnalyticsHelper.constructModalCheckoutAnalyticsAttributes(
            user,
            company,
            selectedPackage,
            jobToBoost.type
        ))
    }

    return (
        <BoostCompanyJobModalView
            step={step}
            closeModal={closeModal}
            loading={loading}
            creditCount={creditCount}
            boostJob={boostJob}
            user={user}
            job={jobToBoost}
            buyCredits={buyCredits}
            selectedPack={selectedPack}
            setSelectedPack={setSelectedPack}
        />
    )
}

export default injectIntl(BoostCompanyJobModal)
