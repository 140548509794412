import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './ProfileSimpleInfo_style'
import { Typography, Box, FormControl, InputLabel, Input } from '@material-ui/core'

const ProfileSimpleInfoView = (props) => {
    const { handleChangeTextField } = props
    const classes = useStyles()

    return (
        <Box className={classes.profileSimpleInfoViewContainer}>
            <Typography variant='h1' component='div' className={classes.title}>
                <FormattedMessage id="landing_info_profile.information" />
            </Typography>
            <Box className={classes.inputsContainer}>
                <FormControl>
                    <InputLabel htmlFor="first-name" className={classes.inputLabel} ><FormattedMessage id="landing_info_profile.first_name" /></InputLabel>
                    <Input
                        classes={{ input: classes.input, underline: classes.inputUnderline }}
                        id="first-name"
                        required
                        fullWidth
                        type='text'
                        onBlur={handleChangeTextField('first_name')}
                    />
                </FormControl>
                <FormControl>
                    <InputLabel htmlFor="last-name" className={classes.inputLabel} ><FormattedMessage id="landing_info_profile.last_name" /></InputLabel>
                    <Input
                        classes={{ input: classes.input, underline: classes.inputUnderline }}
                        id="last-name"
                        required
                        type='text'
                        fullWidth
                        onBlur={handleChangeTextField('last_name')}
                    />
                </FormControl>
            </Box>
        </Box>
    )
}

export default ProfileSimpleInfoView
