import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './FooterCitiesList_style'
import { Box, useTheme, Typography } from '@material-ui/core'

import RouteMap from 'assets/RouteMap.js'
import CollectionHandler from 'assets/CollectionHandler.js'
import CitiesCard from 'shared/components/CitiesCard/CitiesCard.jsx'
import SVGFactory from 'icons/SVGFactory/SVGFactory'

const cities = CollectionHandler.Get('footerCityCollection')

const FooterCitiesList = () => {
	const theme = useTheme()
	const classes = useStyles()
	const locale = RouteMap.GetLocaleFromUrl()

	const cardsData = [
		{
			target: 'student-jobs',
			icon: <SVGFactory name='lightning' width='26.14px' height='32px' />,
			title: <FormattedMessage id='page_footer_navbar_data.student_job_button' />,
			color: theme.palette.bg['yellow'],
		},
		{
			target: 'internships',
			icon: <SVGFactory name='graduate' width='37.61px' height='29px' />,
			title: <FormattedMessage id='page_footer_navbar_data.internship_button' />,
			color: theme.palette.bg['blue'],
		},
		{
			target: 'first-jobs',
			icon: <SVGFactory name='first-job' width='32px' height='31.99px' />,
			title: <FormattedMessage id='landing_student.citylist.first_job_title' />,
			color: theme.palette.bg['green'],
		},
		{
			target: 'student-rooms',
			icon: <SVGFactory name='trainee' width='32.75px' height='31.99px' />,
			title: <FormattedMessage id='page_footer_navbar_data.kot_button' />,
			color: theme.palette.bg['orange'],
		},
	]

	return (
		<Box className={classes.FooterCitiesListContainer}>
			<Typography className={classes.title} variant='h2'>
				<FormattedMessage id='landing_student.citylist.title1' />
			</Typography>
			<Typography className={classes.description}>
				<FormattedMessage id='landing_student.citylist.title2' />
			</Typography>
			{/* Cards List */}
			<Box className={classes.cardsListContainer}>
				{cardsData.map((data) => (
					<CitiesCard
						target={data.target}
						icon={data.icon}
						title={data.title}
						color={data.color}
						citiesList={cities[locale]}
					/>
				))}
			</Box>
		</Box>
	)
}

export default FooterCitiesList
