import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    mainPageContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    pageContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginBottom: 0,
        maxWidth: 1328,
        overflow: 'unset'
    },
    mainBox: {
        flex: 1,
        overflow: 'hidden',
        [theme.breakpoints.up('md')]: {
            width: 'calc(100% - 292px)'
        }
    },
    recommendedJobsCtaText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        backgroundColor: 'var(--background-yellow)',
        borderRadius: 20,
        padding: 36,
        gap: 12,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    recommendedJobsCtaButton: {
        minWidth: 160
    },
    sectionBox: {
        marginBottom: 40,
        [theme.breakpoints.up('md')]: {
            margin: '0px 0px 60px 40px'
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: 44
        }
    },
    jobSectionBox: {
        marginBottom: 60,
        [theme.breakpoints.up('md')]: {
            marginLeft: 40
        }
    },
    ctaBox: {
        marginBottom: 48
    },
    profilePassportContainer: {
        [theme.breakpoints.up('md')]: {
            overflow: 'unset'
        }
    },
    profilePassportBox: {
        [theme.breakpoints.up('md')]: {
            width: 300,
            position: 'sticky',
            top: 24,
            marginBottom: 32
        },
        [theme.breakpoints.down('sm')]: {
            margin: 4,
            marginBottom: 32
        }
    },
    iconTitleBox: {
        display: 'flex',
        alignItems: 'center',
        gap: 12,
        marginBottom: 24
    },
    iconBubble: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 50,
        width: 24,
        height: 24
    }
}))
