import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    navigationButton: {
        minWidth: 'unset',
        padding: '10px 0px',
        color: theme.palette.fg['white'],
        fontFamily: 'GreedStandard-Medium',
        fontSize: '16px',
        lineHeight: '18px',
        textAlign: 'center',
        textTransform: 'none',
        transition: 'all 0.3s ease',
        '&:hover': {
            color: theme.palette.fg['black'],
            backgroundColor: 'transparent'
        },
    },

    navigationButtonActive: {
        color: theme.palette.fg['black'],
        fontFamily: 'GreedStandard-Bold',
    }
}))
