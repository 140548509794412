import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(
    (theme) => (
        {
            dialogContainer: {
                padding: 20,
                border: `1px solid ${theme.palette.fg.silver}`,
                borderRadius: 'var(--border-radius)'
            },
            cardRoot: {
                minHeight: 370,
                position: 'relative'
            },
            cardContent: {
                cursor: 'pointer'
            },
            media: {
                height: 0,
                paddingTop: '56.25%'
            },
            iconCard: {
                padding: '10px'
            },
            favIcon: {
                color: 'red'
            },
            addresse: {
                height: 60
            },
            iconFix: {
                marginLeft: 5
            },
            fontTitle: {
                color: 'var(--black)',
                fontSize: '1rem',
                textTransform: 'capitalize'
            },
            buttonRent: {
                '&:hover': {
                    cursor: 'initial'
                }
            },
            buttonCharges: {
                '&:hover': {
                    cursor: 'initial'
                }
            },
            boxKotType: {
                color: 'var(--red)'
            },
            iconColorBasic: {
                color: 'var(--red)'
            },
            arrowLeft: {
                zIndex: 999,
                position: 'absolute',
                top: '15%'
            },
            arrowRight: {
                right: 0,
                top: '15%',
                zIndex: 999,
                position: 'absolute'
            },
            kotDate: {
                color: 'grey'
            },
            arrows: {
                color: 'white'
            },
            kotTypeCard: {
                width: 100,
                height: 25,
                margin: 10,
                textAlign: 'center'
            },
            buttonCity: {
                textTransform: 'capitalize'
            },
            kotTitleCard: {
                height: 100
            },
            stats: {
                color: 'var(--black)'
            }
        })
)
