import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    paperContainer: {
        padding: '0 40px',
        marginBottom: '30px'
    },
    noPaperContainer: {
        boxShadow: 'none !important'
    },
    boxContainerPage: {
        [theme.breakpoints.down('md')]: {
            padding: '15px 0 !important'
        },
        [theme.breakpoints.up('md')]: {
            padding: '20px 0 !important'
        }
    },
    filterItemContainer: {
        [theme.breakpoints.down('md')]: {
            paddingBottom: '0 !important'
        }
    },
    desktopResetGridItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0
    }
}))
