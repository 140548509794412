import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'
import { Image, Transformation } from 'cloudinary-react'

import { useStyles } from './CompanyProfileFormBannerImage_style'
import SingleImageUploader from 'inputs/Uploader/SingleImageUploader/SingleImageUploader'
import { optimiseSizeForMobile, LARGER_SIZE } from 'assets/CloudinaryURLHelper'

const CompanyProfileFormBannerImage = (props) => {
    const { classes, bannerImageId, updatePictureId, companyName } = props

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
                <Typography variant='h4' component='h3' color='primary'>
                    <FormattedMessage id="company_profile.company_page_description.description_popup.banner_image" />
                </Typography>
                <Box my={2}>
                    <SingleImageUploader
                        type="button"
                        name={<FormattedMessage id="company_profile.company_page_description.description_popup.upload_banner_image" />}
                        functionOnSuccess={updatePictureId}
                    />
                </Box>
                {bannerImageId &&
                    <Box>
                        <Image
                            alt={`${companyName} banner`}
                            cloudName="studentbe"
                            publicId={bannerImageId}
                            className={classes.bannerImagePreview}
                        >
                            <Transformation flags="progressive" fetchFormat="auto" width={optimiseSizeForMobile(LARGER_SIZE)} quality="auto:best" crop="fill" />
                        </Image>
                    </Box>
                }
            </Grid>
        </Grid>
    )
}

export default withStyles(useStyles)(CompanyProfileFormBannerImage)
