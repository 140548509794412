import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    stickiBannerWrapper: {
        width: '100%',
        borderBottom: `2px solid ${theme.palette.fg['black']}`,
    },

    stickyBannerContainer: {
        padding: '7px 47px 10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        columnGap: '15px',
    },

    leftContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '25px'
    },

    rightContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '21px'
    },

    iconButtonsContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '15px'
    },

    buttonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: '21px',
        [theme.breakpoints.up(1100)]: {
            flexDirection: 'row',
        },
    },

    applyButtonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: '21px',
        [theme.breakpoints.up(1100)]: {
            flexDirection: 'row',
        },
    },

    applyButton: {
        whiteSpace: 'nowrap',
    },

    companyLogoContainer: {

    },

    logoBox: {
        overflow: 'hidden',
        display: 'block',
        width: '56px',
        height: '56px',
        border: `2px solid ${theme.palette.fg['black']}`,
        borderRadius: '50%'
    },

    logo: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },

    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '7px'
    },

    jobTitle: {
        color: theme.palette.fg['black'],
        fontFamily: 'GreedWide-Bold',
        fontSize: '22px',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        WebkitLineClamp: 3,
        textOverflow: 'ellipsis',
    },

    jobInfoContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '30px',
    },

    locationContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '3px',
    },

    tagsContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '5px',
    },

    infoText: {
        color: theme.palette.fg['black90Opacity'],
        fontSize: '13px',
        lineHeight: '14px'
    }
}))
