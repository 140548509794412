export const useStyles = theme => ({
    pressContainer: {
        marginLeft: '130px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0
        }
    },
    gridContainer: {
        display: 'flex'
    }
})
