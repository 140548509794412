import React, { useEffect, useState } from 'react'
import Gallery from 'react-photo-gallery'
import Lightbox from 'yet-another-react-lightbox'
import Thumbnails from 'yet-another-react-lightbox/dist/plugins/thumbnails'

import { Box } from '@material-ui/core'

import { useGalleryImageFormatting } from 'hooks/useGalleryImageFormatting'
import { useStyles } from './CompanyPageImages_style'
import GallerySlide from './components/GallerySlide'
import GalleryImage from './components/GalleryImage'

const CompanyPageImages = (props) => {
    const classes = useStyles()
    const { images, onLoad, limit } = props
    const [currentImage, setCurrentImage] = useState(0)
    const [lightboxOpen, setSetLightboxOpen] = useState(false)
    const [mediaItems, setMediaItems] = useState([])

    useEffect(() => {
        let limitedImages = images
        if (limit) {
            limitedImages = limitedImages.slice(0, limit)
        }
        const formattedImages = useGalleryImageFormatting(limitedImages, onLoad)
        setMediaItems(formattedImages)
    }, [images])

    const openLightbox = (index) => {
        setSetLightboxOpen(true)
        setCurrentImage(index)
    }

    const closeLightbox = () => {
        setSetLightboxOpen(false)
        setCurrentImage(0)
    }

    const renderImage = ({ index, photo }) => <GalleryImage image={photo} index={index} openLightbox={openLightbox} />
    const renderSlide = (mediaItem) => <GallerySlide mediaItem={mediaItem} />

    return (
        <Box>
            {images.length > 0 &&
                <Box className={classes.companyImagesGallery}>
                    <Gallery photos={mediaItems} renderImage={renderImage} />
                    <Lightbox
                        open={lightboxOpen}
                        close={closeLightbox}
                        slides={mediaItems}
                        plugins={[Thumbnails]}
                        render={{ slide: ({ slide }) => renderSlide(slide) }}
                        index={currentImage}
                    />
                </Box>
            }
        </Box>
    )
}
export default CompanyPageImages
