import React from 'react'

const TempWorkSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86 85.95" {...props}>
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path d="M46,6h0a40,40,0,1,0,0,80h0A40.12,40.12,0,0,0,86,46V46A40.11,40.11,0,0,0,46,6Z" />
                <path d="M40,0h0a40,40,0,1,0,0,80h0A40.12,40.12,0,0,0,80,40V40A40.11,40.11,0,0,0,40,0Z" />
                <path fill='white' d="M72,40A32,32,0,1,1,40,8,31.89,31.89,0,0,1,72,40ZM37,20v5.47c0,5.27,0,10.53,0,15.8a.58.58,0,0,0,.32.58c.42.25.83.52,1.24.79l13.91,9.24.44.28c1.08-1.68,2.16-3.34,3.24-5l-.25-.19L43.32,38.56A.62.62,0,0,1,43,38q0-8.79,0-17.57V20Z" />
            </g>
        </g>
    </svg>
)

export default TempWorkSVG
