/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useState, useImperativeHandle } from 'react'

import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

/* ===== DOC =====

    USAGE :

    <FeedbackFactory
        ref={feedbackRef}
        status
    />
    feedbackRef.current.triggerFeedback(<status>, <message>)

    Mandatory:
        @ref: React ref created on top parent
        @status: Boolean status for success or fail feedback
        @message: Message rendered in the feebdback alert

    OPTIONAL:

    ===== END =====
*/

const FeedbackFactory = React.forwardRef((props, ref) => {
    const { classes } = props
    const [openFeedback, setOpenFeedback] = useState(false)
    const [status, setStatus] = useState()
    const [message, setMessage] = useState('')

    useImperativeHandle(ref, () => ({
        triggerFeedback: (status, message) => {
            setStatus(status)
            setMessage(message)
            handleClick()
        }
    }))

    const handleClick = () => {
        setOpenFeedback(true)
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setOpenFeedback(false)
    }

    return (
        <Snackbar
            open={openFeedback}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
            <MuiAlert
                elevation={6}
                variant="filled"
                onClose={handleClose}
                severity={status ? 'success' : 'error'}
            >
                {
                    message
                }
            </MuiAlert>
        </Snackbar>
    )
})

export default FeedbackFactory
