import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    ctaButton: {
        paddingLeft: '28px',
        paddingRight: '28px',
        [theme.breakpoints.up('md')]: {
            paddingLeft: '53px',
            paddingRight: '53px'
        }
    },
    buttonContainer: {
        minHeight: 56
    }
}))
