import React from 'react'

const TechnologySVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86 78.11" {...props}>
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path
                    d="M78,6H14a8,8,0,0,0-8,8V59.61a8,8,0,0,0,8,8h6.23v2.5a8,8,0,0,0,8,8H63.76a8,8,0,0,0,8-8V67.59H78a8,8,0,0,0,8-8V14A8,8,0,0,0,78,6Z" />
                <path
                    d="M72,0H8A8,8,0,0,0,0,8V53.61a8,8,0,0,0,8,8h6.23v2.5a8,8,0,0,0,8,8H57.76a8,8,0,0,0,8-8V61.59H72a8,8,0,0,0,8-8V8A8,8,0,0,0,72,0Z" />
                <path className="cls-1"
                    fill='white'
                    d="M22.23,57.12H33.12a.38.38,0,0,0,.44-.3c.31-.95.64-1.88,1-2.82l.13-.4H8V8H72V53.59H45.33c0,.12.06.21.09.3.35,1,.7,2,1,3a.33.33,0,0,0,.38.26H57.76v7H22.23ZM64.91,15H15.09V39.55H64.91Z" />
            </g>
        </g>
    </svg>
)

export default TechnologySVG
