import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, MenuItem, Select, Typography } from '@material-ui/core'
import { useStyles } from '../CreditPackages_style'
import Button from 'shared/components/Button/Button'
import CollectionHandler from 'assets/CollectionHandler'
import ItemIcon from 'components/ItemIcon/ItemIcon'
import { DROPDOWN_STYLE_MENU_PROPS } from 'assets/StyleHelper'

const StudentInternCreditPackages = (props) => {
    const classes = useStyles()
    const { handlePayment } = props
    const creditPackagesCollection = CollectionHandler.Get('creditPackagesCollection')
    const [creditPackagesForDropdown, setCreditPackagesForDropdown] = useState([])
    const [selectedPackage, setSelectedPackage] = useState({})

    useEffect(() => {
        const selectablePackages = creditPackagesCollection.filter(creditPackage => creditPackage.selectable)
        setCreditPackagesForDropdown(selectablePackages)
    }, [])

    const handleChange = (event) => {
        const { value } = event.target
        const selectedCreditPackage = creditPackagesForDropdown.find(creditPackage => creditPackage.value === value)
        setSelectedPackage(selectedCreditPackage)
    }

    const handleClick = () => {
        handlePayment(selectedPackage)
    }

    return (
        <Box className={classes.packageCard}>
            <Box className={classes.infoSection}>
                <Box className={classes.cardIcon}>
                    <ItemIcon type='internship' width={40} height={40} />
                </Box>
                <Typography variant='h6' component='h2' color='primary' className={`${classes.cardTitle} ${classes.uppercase} ${classes.withMinHeight}`}>
                    <FormattedMessage id='credit_packages.student_intern' />
                </Typography>
                <Box className={classes.creditFeatures}>
                    <Box className={classes.creditFeature}>
                        <Typography><FormattedMessage id='credit_packages.features.1_credit' /></Typography>
                    </Box>
                    <Box className={classes.creditFeature}>
                        <Typography><FormattedMessage id='credit_packages.features.90_days' /></Typography>
                    </Box>
                    <Box className={classes.creditFeature}>
                        <Typography><FormattedMessage id='credit_packages.features.credits_valid_2_years' /></Typography>
                    </Box>
                    <Box className={classes.creditFeature}>
                        <Typography><FormattedMessage id='credit_packages.features.personalised_company_page' /></Typography>
                    </Box>
                    <Box className={classes.creditFeature}>
                        <Typography><FormattedMessage id='credit_packages.features.integration_job_alerts' /></Typography>
                    </Box>
                </Box>
            </Box>
            <Box className={classes.CTASection}>
                <Box className={classes.creditCostInfo}>
                    <Select
                        className={classes.creditSelect}
                        classes={{ icon: classes.overrideSelectIcon }}
                        value={selectedPackage}
                        onChange={handleChange}
                        displayEmpty
                        renderValue={selected =>
                            selected.value
                                ? <span className={classes.selected}>
                                    <FormattedMessage id={`credit_packages.credits_per_option.${selected.credits === 1 ? 'one' : 'other'}`} values={{ count: selected.credits }} />
                                </span>
                                : <span className={classes.placeholder}><FormattedMessage id='credit_packages.select_quantity' /></span>
                        }
                        MenuProps={DROPDOWN_STYLE_MENU_PROPS}
                        disableUnderline
                    >
                        <MenuItem value='' disabled>
                            <FormattedMessage id='credit_packages.select_quantity' />
                        </MenuItem>
                        {creditPackagesForDropdown.map((creditPackage) => {
                            return (
                                <MenuItem value={creditPackage.value} key={creditPackage.value}>
                                    <FormattedMessage id={`credit_packages.credits_per_option.${creditPackage.credits === 1 ? 'one' : 'other'}`} values={{ count: creditPackage.credits }} />
                                    &nbsp;
                                    (<FormattedMessage id='credit_packages.price_per_option' values={{ price: creditPackage.price }} />)
                                </MenuItem>
                            )
                        })}
                    </Select>
                    {selectedPackage.value
                        ? <Typography variant='h6' component='p' color='primary' className={classes.cardCTATitle}>
                            <FormattedMessage id='credit_packages.total_value' values={{ value: selectedPackage.value }} />
                        </Typography>
                        : <Typography className={classes.cardPriceSelection}>
                            <FormattedMessage id='credit_packages.starting_at_price' values={{ price: 30 }} />
                        </Typography>
                    }
                </Box>
                <Box className={classes.cardButton}>
                    <Button
                        variant='cta-primary'
                        forceWrap
                        onClick={handleClick}
                        className={classes.button}
                    >
                        <FormattedMessage id='credit_packages.buy' />
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default StudentInternCreditPackages
