import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import { Image, Transformation } from 'cloudinary-react'
import IconFactory from 'icons/IconFactory/IconFactory'

const NotificationCardAvatar = (props) => {
    const { cloudinaryKey, notificationType } = props

    const getAvatarImage = () => {
        if (notificationType === 'jobs') return <IconFactory fontSize='24px' icon='ads' />
        if (notificationType === 'articles') return <IconFactory fontSize='24px' icon='articles' />
        if (!cloudinaryKey) return <Avatar variant='square' />

        return (
            <Image
                cloudName="studentbe"
                publicId={cloudinaryKey}
                alt={cloudinaryKey + ' avatar'}
            >
                <Transformation flags="progressive" fetchFormat="auto" width="40" height="40" quality="auto:best" crop="fit" />
            </Image>
        )
    }

    return (
        <Box display='flex' justifyContent='center' alignItems='center' style={{ width: '40px', height: '40px' }}>
            {getAvatarImage()}
        </Box>
    )
}
export default NotificationCardAvatar
