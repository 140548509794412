import React from 'react'

const ClassicPlanSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86 79.21" {...props}>
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path
                    d="M84.94,34.81Q77.77,22.38,70.64,10A8,8,0,0,0,59.71,7.07l-3.8,2.2-5.69,3.29h0l-1.31.76A8,8,0,0,0,45,19.06L34.32,25.92l-6.66,4.29-13.85,8.9a8,8,0,0,0-3.67,7.09l-.19.11A8,8,0,0,0,7.09,57.24l5.58,9.59a8,8,0,0,0,10.89,2.92L24,69.5a8,8,0,0,0,2.18.74,8,8,0,0,0,8,9h5.59a8.36,8.36,0,0,0,7.7-5.26L48,72.65,48.49,74a8.4,8.4,0,0,0,7.91,5.23h5c.19,0,.43,0,.73,0A10.38,10.38,0,0,0,63.29,79a8,8,0,0,0,6-10.66c0-.09-.07-.17-.1-.25s-.11-.29-.17-.43l-.49-1.19c-.29-.72-.58-1.43-.87-2.11L67,62.89,62.78,52.58l.78-.42a8.13,8.13,0,0,0,1.56.6,8,8,0,0,0,6.07-.8l2.6-1.5,5.37-3.1L82,45.76a8,8,0,0,0,3-10.95Z" />
                <path
                    d="M78.93,28.81Q71.77,16.38,64.64,4A8,8,0,0,0,53.71,1.07l-3.8,2.2L44.22,6.56h0l-1.31.76A8,8,0,0,0,39,13.06L28.32,19.92l-6.66,4.29L7.81,33.11A8,8,0,0,0,4.14,40.2L4,40.31A8,8,0,0,0,1.09,51.24l5.58,9.59a8,8,0,0,0,10.88,2.92L18,63.5a7.91,7.91,0,0,0,2.18.74,8,8,0,0,0,8,9h5.59a8.36,8.36,0,0,0,7.7-5.26L42,66.65,42.49,68a8.4,8.4,0,0,0,7.91,5.23h5c.19,0,.43,0,.73,0A10.38,10.38,0,0,0,57.29,73a8,8,0,0,0,6-10.66l-.09-.25c0-.15-.11-.29-.17-.43l-.49-1.19c-.29-.72-.59-1.43-.87-2.11L61,56.89,56.78,46.58l.78-.42a8.13,8.13,0,0,0,1.56.6,8,8,0,0,0,6.07-.8l2.6-1.5,5.37-3.1L76,39.76a8,8,0,0,0,3-10.95Z" />
                <path className="cls-1"
                    fill='white'
                    fillRule='evenodd'
                    d="M48.21,13.49l-1.31.76h0L61.19,39l8-4.59L72,32.8Q64.85,20.37,57.71,8ZM34.55,49.56,28.12,65.2h5.6c.1,0,.25-.18.3-.3q3.88-9.45,7.74-18.9c0-.08.07-.16.11-.25l.09-.21.09.2.14.32c.47,1.16.94,2.32,1.42,3.48q3.14,7.68,6.26,15.37a.43.43,0,0,0,.47.31h5.44a.78.78,0,0,1-.05-.14c0-.07,0-.14-.08-.2-.47-1.14-.93-2.27-1.4-3.4q-3.72-9-7.45-18.08c-.14-.33-.08-.45.22-.61q3.65-1.95,7.28-3.93L56,37.91l.21-.12.07,0a.75.75,0,0,1-.07-.12c0-.06-.07-.12-.11-.17l-1.92-3.33Q52,30.34,49.8,26.55l-1.45-2.5c-1.06-1.81-2.12-3.63-3.13-5.49L26,30.93,12.13,39.84l1.49,2.59c.21.37.42.74.64,1.11L8,47.21l5.58,9.6,2.92-1.66,3.34-1.91c.28.49.55,1,.81,1.43l1,1.7,9.47-5.06,3.4-1.81,0,0Z" />
            </g>
        </g>
    </svg>
)

export default ClassicPlanSVG
