import React from 'react'
import { Box } from '@material-ui/core'

import { useStyles } from './FunnelFormWrapper_style'

const FunnelFormWrapper = ({children}) => {
    const classes = useStyles()
    return (
		<Box className={classes.funnelForm}>
            {children}
        </Box>
    )
}

export default FunnelFormWrapper
