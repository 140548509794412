import React from 'react'

const MedecineSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86 81.99" {...props}>
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path
                    d="M78,18H70V14a8,8,0,0,0-8-8H30a8,8,0,0,0-8,8v4H14a8,8,0,0,0-8,8V74a8,8,0,0,0,8,8H78a8,8,0,0,0,8-8V26A8,8,0,0,0,78,18Z" />
                <path
                    d="M72,12H64V8a8,8,0,0,0-8-8H24a8,8,0,0,0-8,8v4H8a8,8,0,0,0-8,8V68a8,8,0,0,0,8,8H72a8,8,0,0,0,8-8V20A8,8,0,0,0,72,12Z" />
                <path className="cls-1"
                    fill='white'
                    d="M8,68V20H24V8H56V20H72V68ZM52,48V40H44V32H36v8H28v8h8v8H44V48ZM50,20V14H30v6Z" />
            </g>
        </g>
    </svg>
)

export default MedecineSVG
