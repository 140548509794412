import React from 'react'

const EnterpriseStatSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 90 80"
        x="0px"
        y="0px"
    >
        <path
            fill="#a000ff"
            xmlns="http://www.w3.org/2000/svg"
            d="M59.964,54.528A3.871,3.871,0,0,0,60,54a3.992,3.992,0,0,0-7.472-1.964A3.987,3.987,0,0,0,49,53.388V11.06L50.059,11A1,1,0,0,0,51,10V7.1L53.049,7l-.1-2L48,5.242V3a1,1,0,0,0-1-1H17a1,1,0,0,0-1,1V6.8L11.951,7A1,1,0,0,0,11,8V51.382A3.983,3.983,0,0,0,4,54a3.871,3.871,0,0,0,.036.528A3.992,3.992,0,0,0,6,62H58a3.992,3.992,0,0,0,1.964-7.472ZM44.556,60H40V53h2V51H22v2h2v7H19.444A3.953,3.953,0,0,0,17,54.142V49H47v5.142A3.953,3.953,0,0,0,44.556,60ZM31,53v3H29v2h2v2H26V53Zm2,5h2V56H33V53h5v7H33ZM31,31v7H25V31Zm-6-2V22h6v7Zm22,9H41V31h6Zm-8,0H33V31h6Zm-8,2v7H25V40Zm2,0h6v7H33Zm8,0h6v7H41Zm6-11H41V22h6Zm-8,0H33V22h6Zm-6-9V12l6-.353V20Zm-2,0H25V12.473l6-.353Zm-8,0H17V12.943l6-.353Zm-6,2h6v7H17Zm0,9h6v7H17Zm0,9h6v7H17ZM47,20H41V11.531l6-.353ZM18,4H46V5.34L18,6.706ZM49,7.2V9.057L15.941,11A1,1,0,0,0,15,12V53.387a4.05,4.05,0,0,0-2-1.245V8.952ZM6,60a1.994,1.994,0,0,1-.519-3.92,1,1,0,0,0,.666-1.338A1.993,1.993,0,1,1,9.919,53.48a1,1,0,0,0,.519.634.983.983,0,0,0,.818.033,2.006,2.006,0,0,1,2.663,1.333,1,1,0,0,0,.519.634.983.983,0,0,0,.818.033A2,2,0,1,1,16,60Zm52,0H48a2,2,0,1,1,.744-3.853.983.983,0,0,0,.818-.033,1,1,0,0,0,.519-.634,2.006,2.006,0,0,1,2.663-1.333.983.983,0,0,0,.818-.033,1,1,0,0,0,.519-.634,1.993,1.993,0,1,1,3.772,1.262,1,1,0,0,0,.666,1.338A1.994,1.994,0,0,1,58,60Z"
        />
    </svg>
)

export default EnterpriseStatSVG
