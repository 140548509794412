import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    jobFiltersContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        marginBottom: 12
    },
    jobFilters: {
        display: 'flex',
        alignItems: 'center',
        gap: 20
    },
    defaultFilterSelection: {
        fontStyle: 'italic'
    },
    jobsList: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 24
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    noSearchResults: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 150
    }
}))
