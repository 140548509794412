/* eslint-disable react/prop-types */
import React from 'react'
import BooleanFilterView from './BooleanFilterView'

const BooleanFilter = (props) => {
    const { setFilters, filters, type, filterTextId } = props

    const handleTagChange = (value) => {
        setFilters({ ...filters, [type]: !filters[type] })
    }

    return (
        <BooleanFilterView
            handleTagChange={handleTagChange}
            type={type}
            filters={filters}
            filterTextId={filterTextId}
        />
    )
}

export default BooleanFilter
