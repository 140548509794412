import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './ForgotPasswordPage_style'
import { Container, Typography, FormControl, FormHelperText, InputLabel, Input, Box } from '@material-ui/core'

import ProgressCircle from 'components/ProgressCircle/ProgressCircle'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import PasswordAPI from 'api/PasswordAPI'
import Button from 'shared/components/Button/Button'

const ForgotPasswordPage = (props) => {
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [sent, setSent] = useState(false)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const classes = useStyles()

    const sendRequest = (event) => {
        event.preventDefault()
        setLoading(true)
        PasswordAPI.RequestPasswordResetEmail({
            email: email
        })
            .then((response) => {
                setLoading(false)
                setSent(true)
            }, (error) => {
                setLoading(false)
                setError(true)
                setErrorMessage(error.response.data.error)
            })
    }

    const renderEmailForm = () => {
        return (
            <Box>
                <form onSubmit={sendRequest}>
                    <Box my={4}>
                        <FormControl>
                            <InputLabel htmlFor="my-input" className={classes.inputLabel} ><FormattedMessage id="forgot_password.input_label" /></InputLabel>
                            <Input
                                classes={{ input: classes.input, underline: classes.inputUnderline, }}
                                id="my-input"
                                fullWidth
                                aria-describedby="my-helper-text"
                                type='email'
                                value={email}
                                onChange={(event) => { setEmail(event.target.value) }}
                            />
                            <FormHelperText id="my-helper-text" className={classes.helperText}><FormattedMessage id="forgot_password.helper_text" /></FormHelperText>
                        </FormControl>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Button
                            className={classes.button}
                            type="submit"
                        >
                            <FormattedMessage id="forgot_password.submit" />
                        </Button>
                    </Box>
                </form>
            </Box>
        )
    }

    const renderRetryButton = () => {
        return (
            <RouteMapLink page="password/new">
                <Button
                    className={classes.button}
                >
                    <FormattedMessage id="forgot_password.retry" />
                </Button>
            </RouteMapLink>
        )
    }

    const renderSuccess = () => {
        return (
            <Box display="flex" alignItems="center" flexDirection="column">
                <Box my={4}>
                    <Typography>
                        <FormattedMessage id="forgot_password.success" />
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="center">
                    {renderRetryButton()}
                </Box>
            </Box>
        )
    }

    const renderError = () => {
        return (
            <Box display="flex" alignItems="center" flexDirection="column">
                <Box my={4}>
                    <Typography>
                        {errorMessage}
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="center">
                    {renderRetryButton()}
                </Box>
            </Box>
        )
    }

    return (
        <Container maxWidth='sm'>
            <Box my={16} display="flex" alignItems="center" flexDirection="column">
                <Typography
                    variant='h1' component='h2'
                    className={classes.title}
                >
                    <FormattedMessage id="forgot_password.title" />
                </Typography>
                {!loading && !sent && !error &&
                    renderEmailForm()
                }
                {loading && <ProgressCircle />}
                {sent &&
                    renderSuccess()
                }
                {error &&
                    renderError()
                }
            </Box>
        </Container>

    )
}
export default ForgotPasswordPage
