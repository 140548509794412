import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    jobShowContainer: {
        height: '100%'
    },
    desktopContainer: {
        width: '100%',
        padding: '0px 30px',
        display: 'flex',
        columnGap: '36px',
        marginTop: 36,
        [theme.breakpoints.up(1340)]: {
            padding: '0px'
        }
    },
    leftContainer: {
        width: '300px',
        minWidth: '300px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '24px'
    },
    midContainer: {
        flex: 1
    },
    rightContainer: {
        width: '300px',
        minWidth: '300px'
    },
    mobileContainer: {
        marginTop: 24
    },
    applyButtonBox: {
        padding: '75px 67px 26px',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '28px',
            paddingRight: '28px'
        },
        [theme.breakpoints.up('md')]: {
            padding: '33px 0px 46px'
        }
    },
    jobPassportContainer: {
        width: '100%',
        padding: '0px 28px 49px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    letsChatCardContainer: {
        width: '100%',
        padding: '0px 28px 49px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    ctaContainer: {
        maxWidth: 1440
    },
    unavailablePaper: {
        backgroundColor: 'var(--red)',
        padding: '6px 16px',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 32
    },
    bottomAdBox: {

    },
    similarJobsBox: {
        paddingTop: 24,
        paddingBottom: 24,
        backgroundColor: 'var(--super-dark-grey)'
    },
    topSimilarJobsBox: {
        paddingTop: 80,
        paddingBottom: 24,
        backgroundColor: 'var(--super-dark-grey)'
    },
    similarArticlesBox: {
        paddingTop: 24,
        paddingBottom: 80,
        backgroundColor: 'var(--super-dark-grey)'
    },
    marginBottomBox: {
        marginBottom: 45
    },
    applyCardContainerEnd: {
        marginTop: 20
    },
    applyButtonContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row'
        }
    },
    applyButton: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '255px'
        }
    },
    adBillboardContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    adBillboard: {
        marginTop: 24,
        maxWidth: 800,
        [theme.breakpoints.up('md')]: {
            marginBottom: -12
        }
    }
}))
