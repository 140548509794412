import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Bugsnag from '@bugsnag/js'

import { useStyles } from './NewJobForm_style'
import { Badge, Box, Typography } from '@material-ui/core'

import AnalyticsHelper from 'assets/AnalyticsHelper'
import CollectionHandler from 'assets/CollectionHandler.js'
import FunnelContentWrapper from '../shared/wrappers/FunnelContentWrapper/FunnelContentWrapper'
import FunnelFormWrapper from '../shared/wrappers/FunnelFormWrapper/FunnelFormWrapper'
import FunnelOverviewWrapper from '../shared/wrappers/FunnelOverviewWrapper/FunnelOverviewWrapper'
import FunnelTableOfContents from '../shared/FunnelTableOfContents/FunnelTableOfContents'
import JobCreationFunnelWrapper from 'wrappers/JobCreationFunnelWrapper/JobCreationFunnelWrapper'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import RouteMap from 'assets/RouteMap.js'
import SessionAPI from 'api/SessionAPI'
import SVGFactory from 'icons/SVGFactory/SVGFactory'
import Button from 'shared/components/Button/Button'
import useJobIcon from 'hooks/useJobIcon'
import useJobIconColor from 'hooks/useJobIconColor'
import IconFactory from 'shared/icons/IconFactory/IconFactory'

const NewJobForm = (props) => {
    const classes = useStyles()
    const { user, selectedType, urls } = props
    const [selectedTypeOfJob, setSelectedTypeOfJob] = useState(selectedType)
    const [redirectionUrl, setRedirectionUrl] = useState(urls[selectedType])
    const [steps] = useState([{ value: 'type_of_job', isValid: false }])
    const jobTypes = CollectionHandler.Get('jobTypeCollection')
    const currentStep = steps[0].value
    const CREATION_TYPES = ['create_manually', 'generate_with_ai']
    const [selectedCreation, setSelectedCreation] = useState(CREATION_TYPES[0])

    const handleClick = (jobType) => {
        setSelectedTypeOfJob(jobType)
        setRedirectionUrl(urls[jobType])
    }

    const handleClickOnCreate = (creationType) => {
        setSelectedCreation(creationType)
        setRedirectionUrl(urls[selectedTypeOfJob])
        if (creationType === CREATION_TYPES[1]) {
            localStorage.setItem('jobType', selectedTypeOfJob)
            setRedirectionUrl('users/job_listing_generator/new')
        }
    }

    const handleSubmit = () => {
        if (redirectionUrl && selectedCreation) {
            submitForm()
        }
    }

    const submitForm = () => {
        localStorage.removeItem('jobGeneratorResponse')
        SessionAPI.UpdateUserStoredLocation({
            updateUserStoredLocation: urls.updateUserStoredLocation,
            redirectionUrl: redirectionUrl
        }).then(() => {
            sendAnalyticsFunnelContinue()
            window.location.href = RouteMap.Page(redirectionUrl)
        }).catch((error) => {
            Bugsnag.notify('Error while  updating user location url: ' + error)
        })
    }

    const sendAnalyticsFunnelContinue = () => {
        AnalyticsHelper.sendGTMEvent(
            'Clicked Job Funnel Continue',
            AnalyticsHelper.constructJobFunnelAnalyticsAttributes(
                user,
                user.company_card_info,
                selectedTypeOfJob
            ))
    }

    return (
        <PageWrapper user={user} disableFooter>
            <JobCreationFunnelWrapper>
                <FunnelOverviewWrapper>
                    <FunnelTableOfContents steps={steps} currentStep={currentStep} />
                </FunnelOverviewWrapper>
                <FunnelContentWrapper>
                    <FunnelFormWrapper>
                        <Typography variant='h2' className={classes.formTitle}>
                            <FormattedMessage id='new_job_form.title' />
                        </Typography>
                        <Box className={classes.jobSelectionForm}>
                            {jobTypes.map(jobType => {
                                const isSelected = selectedTypeOfJob === jobType.value
                                const value = jobType.value
                                return (
                                    <Box
                                        className={`${classes.jobType} ${isSelected ? classes.selectedJobType : '' }`}
                                        onClick={() => handleClick(value)}
                                        key={value}
                                    >
                                        <Box className={classes.iconContainer} style={{backgroundColor: useJobIconColor(value)}}>
                                            <SVGFactory name={useJobIcon(value)} width='40px' height='40px' />
                                        </Box>
                                        <Typography component='body1' className={classes.jobTitle}>
                                            <FormattedMessage id={`type.${value}.singular`} />
                                        </Typography>
                                    </Box>
                                )
                            })}
                        </Box>
                        <Box className={classes.createSelectionForm}>
                            {selectedTypeOfJob &&
                                CREATION_TYPES.map((creationType) => {
                                    const isSelected = selectedCreation === creationType
                                    const isGenerateWithAi = creationType === CREATION_TYPES[1]

                                    return (
                                        <Box className={`${classes.creationType} ${isSelected ? classes.selectedJobCreation : ''}`}
                                            onClick={() => handleClickOnCreate(creationType)}
                                            key={creationType}
                                        >
                                            {isGenerateWithAi ? (
                                                <Badge
                                                    badgeContent="Beta"
                                                    color="secondary"
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right'
                                                    }}
                                                    classes={{ badge: classes.badge }}
                                                >
                                                    <Box className={classes.aiInline}>
                                                        <FormattedMessage id={`job_creation_funnel.new_job.${creationType}`} />
                                                        <IconFactory icon={'AI'} className={classes.aiIcon} />
                                                    </Box>
                                                </Badge>
                                            ) : (
                                                <Box className={classes.aiInline}>
                                                    <FormattedMessage id={`job_creation_funnel.new_job.${creationType}`} />
                                                    {isGenerateWithAi && <IconFactory icon={'AI'} className={classes.aiIcon} />}
                                                </Box>
                                            )}
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                        <Button
                            variant='cta-primary'
                            onClick={handleSubmit}
                            className={classes.button}
                        >
                            <FormattedMessage id='new_job_form.create_ad' />
                        </Button>
                    </FunnelFormWrapper>
                </FunnelContentWrapper>
            </JobCreationFunnelWrapper>
        </PageWrapper>
    )
}

export default NewJobForm
