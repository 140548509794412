/* eslint-disable react/prop-types */
import React, { useState } from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import { Hidden } from '@material-ui/core'

import KotOwnerAPI from '../../../../../../api/KotOwnerAPI'
import FilledButton from 'buttons/FilledButton/FilledButton'

import { useStyles } from './AppComponentKotOwnerProfile_style'

import axios from 'axios'

import { FormattedMessage } from 'react-intl'

const AppComponentKotOwnerProfile = (props) => {
    const classes = useStyles()
    const [state, setState] = useState({
        firstName: props.profile && props.profile.first_name ? props.profile.first_name : '',
        lastName: props.profile && props.profile.last_name ? props.profile.last_name : '',
        phoneNumber: props.profile && props.profile.phone_number ? props.profile.phone_number : '',
        street: props.address && props.address.street ? props.address.street : '',
        city: props.address && props.address.city ? props.address.city : '',
        country: props.address && props.address.country ? props.address.country : '',
        zipCode: props.address && props.address.zip ? props.address.zip : '',
        canSubmit: false
    })
    const [checked, setChecked] = useState(props.profile && props.profile.show_phonenumber ? props.profile.show_phonenumber : false)

    const handleSubmit = event => {
        event.preventDefault()
        const data = {
            show_phonenumber: checked,
            street: state.street,
            city: state.city,
            country: state.country,
            zip: state.zipCode,
            first_name: state.firstName,
            last_name: state.lastName,
            phone_number: state.phoneNumber
        }
        // eslint-disable-next-line no-undef
        axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
        KotOwnerAPI.kotOwnerProfile('edit', data)
            .then(
                () => {
                    window.location.reload()
                }
            )
            .catch(
                (error) => {
                    console.log('E' + error)
                }
            )
    }

    const handleChangeData = prop => event => {
        setState({ ...state, [prop]: event.target.value })
    }

    const handleChangeCheck = (event) => {
        setChecked(event.target.checked)
    }
    console.log(props)
    return (
        <Container maxWidth='lg'>
            <Box mt={4} mb={5}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={5}>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={5}>
                                <Grid item xs={12} >
                                    <Box mb={1}>
                                        <Typography
                                            variant='h2'
                                            color={'primary'}
                                        >
                                            <FormattedMessage id="landing_kot_owner_profile.personal_information" />
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography
                                        variant='h4'
                                        component='h3'
                                        color='primary'
                                    >
                                        <FormattedMessage id="landing_kot_owner_profile.first_name" />
                                    </Typography>
                                    <TextField
                                        InputProps={{
                                            classes: {
                                                input: classes.inputBase
                                            }
                                        }}
                                        classes={{
                                            root: classes.errorText
                                        }}
                                        fullWidth
                                        required={true}
                                        type={'text'}
                                        value={state.lastName}
                                        onChange={handleChangeData('lastName')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography
                                        variant='h4'
                                        component='h3'
                                        color='primary'>
                                        <FormattedMessage id="landing_kot_owner_profile.last_name" /></Typography>
                                    <TextField
                                        InputProps={{
                                            classes: {
                                                input: classes.inputBase
                                            }
                                        }}
                                        classes={{
                                            root: classes.errorText
                                        }}
                                        fullWidth
                                        required={true}
                                        type={'text'}
                                        value={state.firstName}
                                        onChange={handleChangeData('firstName')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography
                                        variant='h4'
                                        component='h3'
                                        color='primary'
                                    >
                                        <FormattedMessage id="landing_kot_owner_profile.phone_number" />
                                    </Typography>
                                    <TextField
                                        InputProps={{
                                            classes: {
                                                input: classes.inputBase
                                            }
                                        }}
                                        classes={{
                                            root: classes.errorText
                                        }}
                                        fullWidth
                                        className={'input'}
                                        required={true}
                                        type={'text'}
                                        value={state.phoneNumber}
                                        onChange={handleChangeData('phoneNumber')}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ paddingLeft: 10 }}>
                                    <Box display={'flex'} alignItems={'center'}>
                                        <Checkbox
                                            color='primary'
                                            checked={checked}
                                            onChange={handleChangeCheck}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                        <Typography
                                            variant='subtitle2'
                                            color='primary'
                                        >
                                            <FormattedMessage id="landing_kot_owner_profile.show_phone_number_in_offer" />
                                        </Typography>
                                    </Box>

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={5}>
                                <Grid item xs={12} >
                                    <Box mb={1}>
                                        <Typography variant='h2' color={'primary'}>
                                            <FormattedMessage id="landing_kot_owner_profile.primary_address" />
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        variant='h4'
                                        component='h3'
                                        color='primary'
                                    >
                                        <FormattedMessage id="landing_kot_owner_profile.address" />
                                    </Typography>
                                    <TextField
                                        InputProps={{
                                            classes: {
                                                input: classes.inputBase
                                            }
                                        }}
                                        classes={{
                                            root: classes.errorText
                                        }}
                                        fullWidth
                                        type={'text'}
                                        value={state.street}
                                        onChange={handleChangeData('street')}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Typography
                                        variant='h4'
                                        component='h3'
                                        color='primary'
                                    >
                                        <FormattedMessage id="landing_kot_owner_profile.postal_code" />
                                    </Typography>
                                    <TextField
                                        InputProps={{
                                            classes: {
                                                input: classes.inputBase
                                            }
                                        }}
                                        classes={{
                                            root: classes.errorText
                                        }}
                                        fullWidth
                                        type={'number'}
                                        value={state.zipCode}
                                        onChange={handleChangeData('zipCode')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography
                                        variant='h4'
                                        component='h3'
                                        color='primary'
                                    >
                                        <FormattedMessage id="landing_kot_owner_profile.city" />
                                    </Typography>
                                    <TextField
                                        InputProps={{
                                            classes: {
                                                input: classes.inputBase
                                            }
                                        }}
                                        classes={{
                                            root: classes.errorText
                                        }}
                                        fullWidth
                                        type={'text'}
                                        value={state.city}
                                        onChange={handleChangeData('city')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography
                                        variant='h4'
                                        component='h3'
                                        color='primary'
                                    >
                                        <FormattedMessage id="landing_kot_owner_profile.country" />
                                    </Typography>
                                    <TextField
                                        InputProps={{
                                            classes: {
                                                input: classes.inputBase
                                            }
                                        }}
                                        classes={{
                                            root: classes.errorText
                                        }}
                                        fullWidth
                                        type={'text'}
                                        value={state.country}
                                        onChange={handleChangeData('country')}
                                    />
                                </Grid>
                            </Grid>

                        </Grid>
                        <Hidden only={['xs', 'sm']}>
                            <Grid item xs={12} >
                                <FilledButton
                                    color='secondary'
                                    name={<FormattedMessage id="landing_kot_owner_profile.save" />}
                                    type='submit'
                                />
                            </Grid>
                        </Hidden>
                        <Hidden only={['md', 'lg', 'xl']}>
                            <Grid item xs={12} >
                                <FilledButton
                                    type='submit'
                                    fullWidth
                                    color='secondary'
                                    name={<FormattedMessage id="landing_kot_owner_profile.save" />}
                                />
                            </Grid>
                        </Hidden>
                    </Grid>
                </form>
            </Box>
        </Container>
    )
}
export default AppComponentKotOwnerProfile
