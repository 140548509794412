/* eslint-disable react/prop-types */
import React from 'react'
import Box from '@material-ui/core/Box'
import AdBanner from 'components/AdBanner/AdBanner'

const LeaderBoardAd = (props) => {
    const { ads, type } = props

    const renderAdBanner = () => {
        switch (type) {
        case 'first_job':
            return <>
                <AdBanner key='ad-banner' ads={ads} place='Firstjobs_Show_Billboard' device='desktop' width={728} responsiveBanner noMarginBottom />
                <AdBanner key='ad-banner' ads={ads} place='Firstjobs_Show_Mobile_1' device='mobile' width={300} />
            </>
        case 'internship':
            return <>
                <AdBanner key='ad-banner' ads={ads} place='Internships_Show_Billboard' device='desktop' width={728} responsiveBanner noMarginBottom />
                <AdBanner key='ad-banner' ads={ads} place='Internships_Show_Mobile_1' device='mobile' width={300} />
            </>
        case 'student_job':
            return <>
                <AdBanner key='ad-banner' ads={ads} place='Studentjobs_Show_Billboard' device='desktop' width={728} responsiveBanner noMarginBottom />
                <AdBanner key='ad-banner' ads={ads} place='Studentjobs_Show_Mobile_1' device='mobile' width={300} />
            </>
        default:
            break
        }
    }

    return (
        <Box>{renderAdBanner()}</Box>
    )
}

export default LeaderBoardAd
