import React from 'react'
import axios from 'axios'
import Bugsnag from '@bugsnag/js'
import { FormattedMessage } from 'react-intl'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import CardContainer from 'containers/CardContainer/CardContainer'
import Button from 'components/Button/Button'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import PopupFactory from 'modals/Popup/PopupFactory'
import SharedTabs from 'components/SharedTabs/SharedTabs'

export default class CompanyArticles extends React.Component {
    constructor (props) {
        super(props)

        this.state = {
            showPopupDelete: false,
            articleToDelete: null
        }

        this.deleteArticle = this.deleteArticle.bind(this)
        this.deletePopup = this.deletePopup.bind(this)
    }

    deletePopup (article) {
        console.log(this)
        this.setState({ showPopupDelete: true, articleToDelete: article })
    }

    deleteArticle () {
        axios({
            url: '/api/v1/articles/' + this.state.articleToDelete.id,
            method: 'delete'
        })
            .then(function (response) {
                window.location.reload()
            })
            .catch(function (error) {
                alert(error.response.data.error)
                Bugsnag.notify(error)
            })
    }

    render () {
        return (
            <PageWrapper user={this.props.user} disableFooterLists>
                <SharedTabs user={this.props.user} currentTab='users/articles' />
                {this.state.showPopupDelete &&
                    <PopupFactory
                        type="delete-offer"
                        itemType={this.props.type}
                        itemsLen={1}
                        popupExit={() => this.setState({ showPopupDelete: false })}
                        actionPopup={this.deleteArticle}
                        popupTitle={<FormattedMessage values={{ count: 1 }} id="delete_offer_popup.article.title.one" />}
                        openShare={this.state.showPopupDelete}
                    />
                }
                <Container maxWidth='lg'>
                    <Box className="banner-container">
                        {this.props.user.create_articles &&
                            <Box className="button-container" display='flex' justifyContent='flex-end' mb={4}>
                                <Button
                                    variant='primary'
                                    size='small'
                                    onClick={() => { window.location.href = this.props.newArticleUrl }}
                                >
                                    <FormattedMessage id='company_profile.publish_new_article' />
                                </Button>
                            </Box>
                        }
                    </Box>
                    <Box className='page-card-to-show' mb={8}>
                        <CardContainer
                            key={'cardcontainer_' + 0}
                            type={this.props.type}
                            cards={this.props.items}
                            articleOwner={this.props.user.create_articles}
                            remove={this.deletePopup}
                            noCardsText={<FormattedMessage id="company_profile.no_cards" />}
                            maxLength={3}
                            flexRow
                        />
                    </Box>
                </Container>
            </PageWrapper>
        )
    }
}
