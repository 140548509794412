/* eslint-disable react/prop-types */
import React from 'react'

import { useStyles } from '../KotShowBanner_style'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import IconFactory from 'icons/IconFactory/IconFactory'

const KotShowBannerDesktopSticky = (props) => {
    const { kot, unavailable, renderApplyBox } = props
    const classes = useStyles()

    return (
        <Box className={`${classes.bannerContainer} ${classes.bannerContainerSticky}`}>
            <Box className={classes.infoBannerContainer}>
                <Box className={classes.kotInfoContainer}>
                    <Typography variant='h1' className={`${classes.kotTitleBox} ${classes.titleBoxSticky} ${classes.kotTitleBoxSticky}`}>
                        {kot.title}
                    </Typography>
                    <Box className={`${classes.kotLocalisationContainer} ${classes.kotLocalisationContainerSticky}`}>
                        <Box>
                            <Box className={`${classes.mapIcon} ${classes.mapIconSticky}`}>
                                <IconFactory icon='marker' />
                            </Box>
                        </Box>
                        <Typography className={`${classes.address} ${classes.addressSticky}`}>
                            <span className={classes.bold}>{kot.city.toLowerCase()} {kot.zip}</span>, {kot.street.toLowerCase()}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box className={classes.kotPriceContainer}>
                <Typography variant='h6' component='p' className={`${classes.kotPrice} ${classes.kotPriceSticky}`}>
                    {kot.charges === 0
                        ? <>€{kot.rent}</>
                        : <>€{kot.rent} (+{kot.charges})</>
                    }
                </Typography>
            </Box>
            {!unavailable &&
                <Box className={`${classes.applyBox} ${classes.applyBoxSticky}`}>
                    {renderApplyBox()}
                </Box>
            }
        </Box>
    )
}

export default KotShowBannerDesktopSticky
