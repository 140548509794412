import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    title: {
        color: 'var(--foreground-black)',
        fontFamily: 'GreedWide-Bold',
        fontSize: 28,
    },
    optional: {
        fontSize: 16,
        fontFamily: 'GreedStandard-Regular',
        marginLeft: 4
    }
}))
