import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    CompanyRecentJobsContainer: {
        width: '100%',
        padding: 16,
        border: '1px solid var(--background-silver)',
        borderRadius: 'var(--border-radius)'
    },
    jobsContainer: {
        width: '100%',
        paddingBottom: 30,
        display: 'flex',
        flexDirection: 'column',
        gap: 24
    },
    titleContainer: {
        paddingBottom: '26px',
        display: 'flex',
        columnGap: '12px',
    },
    iconContainer: {
        width: '28px',
        height: '28px',
        borderRadius: '50%',
        backgroundColor: theme.palette.bg.greyLight,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        color: theme.palette.fg.black,
        fontSize: '18px',
        lineHeight: '28px'
    },
    linkContainer: {
        width: '100%',
    },
    customButton: {
        width: '100%'
    }
}))
