import React from 'react'

import { useStyles } from './NavigationDropDownFilterCard_style'
import { Box, Typography, Chip, Button } from '@material-ui/core'

import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import { FormattedMessage } from 'react-intl'

const NavigationDropDownFilterCard = (props) => {
    const classes = useStyles()
    const { filter, type } = props

    const buildRouteMapLinkProps = () => {
        switch (type) {
        case 'student-jobs':
        case 'student-rooms':
            return { location: filter.value_name }
        case 'first-jobs':
        case 'internships':
            return { domain: filter.value_name }
        }
    }

    return (
        <RouteMapLink
            itemType={type}
            {...buildRouteMapLinkProps()}
        >
            <Button className={classes.fieldButton}>
                <Box className={classes.fieldContainer}>
                    <Box className={classes.cityContainer}>
                        <Typography variant='h4' component='div' align='left'>
                            {filter.name}
                        </Typography>
                        {filter.new > 0 &&
                            <Chip label={`${filter.new} NEW`} className={classes.cityChip}/>
                        }
                    </Box>
                    <Typography variant='subtitle2' component='div' className={classes.openJobs}>
                        {filter.open} <FormattedMessage id={type === 'student-rooms' ? 'page_footer_navbar_data.open_kots' : 'page_footer_navbar_data.open_jobs'} />
                    </Typography>

                </Box>
            </Button>
        </RouteMapLink>
    )
}

export default NavigationDropDownFilterCard
