import React from 'react'

const AccountingSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86 86" {...props}>
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path d="M78,15.16H63.09V14a8,8,0,0,0-8-8H36.88a8,8,0,0,0-8,8V33.47H14a8,8,0,0,0-8,8V78a8,8,0,0,0,8,8H32.16a8.18,8.18,0,0,0,2.39-.36,8.15,8.15,0,0,0,2.33.34H55.09a8,8,0,0,0,2.36-.35,8.22,8.22,0,0,0,2.34.34H78a8,8,0,0,0,8-8V23.16A8,8,0,0,0,78,15.16Z" />
                <path d="M72,9.16H57.09V8a8,8,0,0,0-8-8H30.88a8,8,0,0,0-8,8V27.47H8a8,8,0,0,0-8,8V72a8,8,0,0,0,8,8H26.16a8.23,8.23,0,0,0,2.39-.36,8.15,8.15,0,0,0,2.33.34H49.09a8,8,0,0,0,2.36-.35,8.22,8.22,0,0,0,2.34.34H72a8,8,0,0,0,8-8V17.16A8,8,0,0,0,72,9.16Z" />
                <path className="cls-1" fill="white" d="M30.88,8V72H49.09V8ZM53.79,72H72V17.16H53.79ZM26.16,72V35.47H8V72Z" />
            </g>
        </g>
    </svg>
)

export default AccountingSVG
