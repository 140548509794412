import React from 'react'

import { useStyles } from './FilterTagList_style'
import { Box } from '@material-ui/core'

import IconFactory from 'icons/IconFactory/IconFactory'

const FilterTagList = (props) => {
    const { items, handleChange, small } = props
    const classes = useStyles()

    return (
        <Box className={classes.selectedTagsContainer}>
            {items.map((item) => {
                return (
                    <Box
                        className={`${classes.selectedTag} ${small && classes.selectedTagSmall}`}
                        key={`selected-location-${item.name}`}
                        onClick={() => handleChange(item)}
                    >
                        <IconFactory icon='cross' className={classes.tagCrossIcon} fontSize='12px' />
                        {item.name}
                    </Box>
                )
            })}
        </Box>
    )
}

export default FilterTagList
