import React from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import DefaultHelmet from './DefaultHelmet'
import RouteMap from '../../assets/RouteMap'

class AboutPaymentHelmet extends React.Component {
    render () {
        const { formatMessage } = this.props.intl

        return <>
            <DefaultHelmet
                title={formatMessage({ id: 'helmet.about.payment.title' })}
                description={formatMessage({ id: 'helmet.about.payment.description' })}
                imageCloudinaryKey='assets/hello-future-lets-talk-student-be'
            />
            <Helmet>
                <meta property="og:type" content="website" />
                <meta property="og:url" content={RouteMap.Page('payment')} />
                <link rel="canonical" href={RouteMap.Page('payment')} />
                <link rel="alternate" hrefLang="fr-BE" href="https://www.student.be/fr/payment/" />
                <link rel="alternate" hrefLang="en-BE" href="https://www.student.be/en/payment/" />
                <link rel="alternate" hrefLang="nl-BE" href="https://www.student.be/nl/payment/" />
                <link rel="alternate" hrefLang="x-default" href="https://www.student.be/en/payment/" />
            </Helmet>
        </>
    }
}

export default injectIntl(AboutPaymentHelmet)
