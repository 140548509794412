import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    responsiveContainer: {
        position: 'relative',
        paddingBottom: '56.25%',
        height: 0
    },
    responsiveIframe: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    }
}))
