import React from 'react'

import { Box } from '@material-ui/core'

import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import { useStyles } from './JobCard_style'

const JobCardWrapper = (props) => {
    const classes = useStyles()
    const { children, jobType, jobUrl, jobId, onCardClick } = props

    if (onCardClick) {
        return (
            <Box onClick={onCardClick} className={classes.jobCardWrapper}>
                {children}
            </Box>
        )
    } else {
        return (
            <Box className={classes.jobCardWrapper} key={`jobcard_${jobId}`}>
                <RouteMapLink
                    showItemType={jobType}
                    showItemUrl={jobUrl}
                    className={classes.routeMapLinkStyle}
                >
                    {children}
                </RouteMapLink>
            </Box>
        )
    }
}

export default JobCardWrapper
