import axios from 'axios'
import RouteMap from '../assets/RouteMap.js'
import ReactOnRails from 'react-on-rails'

import {
    GET_NOTIFICATION_API,
    SEE_NOTIFICATION_API
} from './env'

const GetNotifications = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: GET_NOTIFICATION_API,
        method: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

const SeeNotifications = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: SEE_NOTIFICATION_API,
        method: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}
export default { GetNotifications, SeeNotifications }
