import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  FooterCitiesListContainer: {
    width: '100%',
    backgroundColor: theme.palette.bg['white'],
    paddingTop: '57px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      paddingTop: '70px',
    },
  },

  title: {
    padding: '0px 22px 16px 22px',
    color: theme.palette.fg['black'],
    textAlign: 'center',
    textTransform: 'uppercase',
    fontFamily: 'GreedWide-Heavy, sans-serif',
    fontSize: '32px',
    lineHeight: '40px',
    [theme.breakpoints.up('md')]: {
      fontSize: '40px',
      lineHeight: '56px',
    },
  },

  description: {
    padding: '0px 22px 32px 22px',
    color: theme.palette.fg['black'],
    textAlign: 'center',
    fontSize: '16px',
    lineHeight: '18px',
    [theme.breakpoints.up('md')]: {
      paddingBottom: '56px',
    },
  },

  cardsListContainer: {
    width: '100%',
    overflowX: 'auto',
    padding: '0px 22px 57px',
    display: 'flex',
    columnGap: '16px',
    [theme.breakpoints.up('md')]: {
      paddingBottom: '125px',
      columnGap: '48px',
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'center',
    },
  },
}))
