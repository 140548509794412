import React from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'
import TabButton from './TabButton'
import Paper from 'components/Paper/Paper'
import { useStyles } from './SharedTabs_style'

const SharedTabs = (props) => {
    const { user, currentTab, hidden } = props
    if (hidden) return null

    const classes = useStyles()

    const renderTabButton = (page, translateId) => {
        return (
            <TabButton
                page={page}
                translateId={translateId}
                key={`employer-connected-user-navbar-link-to-${page.replace(/\//g, '-')}`}
                currentTab={currentTab}
                user={user}
            />
        )
    }
    const renderUserTabs = () => {
        let companyUrl
        if (user.type === 'employer') {
            companyUrl = user.parent_company
                ? 'companies/' + user.parent_company.id
                : 'companies/' + user.company_id
        }

        switch (user.type) {
        case 'employer':
            return (
                [
                    renderTabButton('users/company_jobs', 'my_ads'),
                    <>
                        {!user.has_parent &&
                            renderTabButton(companyUrl, 'my_company_page')
                        }
                    </>,
                    <>
                        {(user.show_articles || user.has_articles) &&
                            renderTabButton('users/articles', 'my_articles')
                        }
                    </>
                ]
            )
        case 'student':
            return (
                [
                    renderTabButton('users/dashboard', 'my_dashboard'),
                    renderTabButton('users/profile/edit', 'my_profile'),
                    renderTabButton('users/alerts', 'my_alerts'),
                    renderTabButton('users/favourites', 'my_favourites'),
                    renderTabButton('users/candidacies', 'my_candidacies'),
                    <>
                        {user.is_student_kot_owner &&
                            renderTabButton('users/kots', 'my_kots')
                        }
                    </>
                ]
            )
        case 'kot_owner':
            return (
                [
                    renderTabButton('users/kots', 'my_kots'),
                    renderTabButton('users/profile/edit', 'my_profile'),
                    renderTabButton('messages', 'my_messages')
                ]
            )
        default:
            console.error('No user detected')
        }
    }

    return (
        <Container maxWidth='lg'>
            <Paper className={classes.paper}>
                <Box className={classes.mainTabs}>
                    {renderUserTabs()}
                </Box>
                <Box className={classes.settingTabs}>
                    <Hidden mdUp>
                        {user.type === 'employer' && renderTabButton('messages-icon', 'landing_connected_user.my_settings')}
                    </Hidden>
                    {user.admin && renderTabButton('admin', 'admin')}
                    {renderTabButton('users/settings/edit', 'landing_connected_user.my_settings')}
                    {renderTabButton('sign_out', 'landing_connected_user.disconnect')}
                </Box>
            </Paper>
        </Container>
    )
}

export default SharedTabs
