import axios from 'axios'
import ReactOnRails from 'react-on-rails'
import RouteMap from 'assets/RouteMap'

import { LOGIN_API, AUTHENTIFICATION_PARAMS_API, DOES_USER_EMAIL_ALREADY_EXISTS_API } from './env'

const Login = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: `/${RouteMap.GetLocaleFromUrl()}${LOGIN_API}`,
        method: 'post',
        data: data
    })
}

const Register = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: `/${RouteMap.GetLocaleFromUrl()}`,
        method: 'post',
        data: data
    })
}

const GetAuthentificationData = () => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: AUTHENTIFICATION_PARAMS_API,
        method: 'get'
    })
}

const DoesUserEmailAlreadyExists = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: DOES_USER_EMAIL_ALREADY_EXISTS_API,
        method: 'post',
        data: data
    })
}

const UpdateUserStoredLocation = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: data.updateUserStoredLocation,
        method: 'post',
        data: {
            location: data.redirectionUrl
        }
    })
}

export default {
    Login,
    Register,
    GetAuthentificationData,
    DoesUserEmailAlreadyExists,
    UpdateUserStoredLocation
}
