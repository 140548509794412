import React from 'react'

const VideoCard = (props) => {
    const { videoId } = props

    return (
        <iframe
            width="250"
            height="480"
            src={`https://www.youtube.com/embed/${videoId}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
        />
    )
}
export default VideoCard
