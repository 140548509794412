/* eslint-disable react/prop-types */
import React from 'react'

import { useStyles } from '../KotShowBanner_style'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const KotShowBannerMobileSticky = (props) => {
    const { kot, unavailable, renderApplyBox } = props
    const classes = useStyles()

    return (
        <Box className={classes.bannerContainer}>
            <Box className={classes.infoBannerContainer}>
                <Box className={classes.kotInfoContainer}>
                    <Box className={classes.kotTitleAndPriceContainer}>
                        <Typography variant='h6' component='h1' className={classes.kotTitleBox}>
                            {kot.title}
                        </Typography>
                        <Box className={classes.kotPriceContainer}>
                            <Typography variant='h6' component='p' className={classes.kotPrice}>
                                {kot.charges === 0
                                    ? <>€{kot.rent}</>
                                    : <>€{kot.rent} (+{kot.charges})</>
                                }
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {!unavailable &&
                <Box className={`${classes.applyBox} ${classes.applyBoxSticky}`}>
                    {renderApplyBox()}
                </Box>
            }
        </Box>
    )
}

export default KotShowBannerMobileSticky
