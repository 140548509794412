import React from 'react'

const BookSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86 72.34" {...props}>
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path d="M80.52,9.93l-.9-.37c-.71-.29-1.42-.58-2.14-.83a44.39,44.39,0,0,0-19-2.55,31.09,31.09,0,0,0-10,2.56c-.73.33-1.56.72-2.44,1.22h0A31.76,31.76,0,0,0,32.45,6.08,44.19,44.19,0,0,0,19.54,7.25,48.43,48.43,0,0,0,11.31,10,8.77,8.77,0,0,0,6,18.13Q6,36.82,6,55.49v8.85a8,8,0,0,0,11,7.43l.73-.3c.25-.11.5-.21.73-.29A44.49,44.49,0,0,1,27.79,69a17,17,0,0,1,5.12.2,38.86,38.86,0,0,1,6,1.78c.62.23,1.25.45,1.9.67l.78.27a8,8,0,0,0,4.45.24,7.93,7.93,0,0,0,4.21-.17l.12,0,.54-.18,1.46-.52,3-1.06A19.75,19.75,0,0,1,64.31,69a44.74,44.74,0,0,1,10,2.51l.39.18A8,8,0,0,0,86,64.75c0-.3,0-.54,0-.79q0-23,0-46A8.55,8.55,0,0,0,80.52,9.93Z" />
                <path d="M74.52,3.93l-.9-.37c-.71-.29-1.42-.58-2.14-.83a44.39,44.39,0,0,0-19-2.55,31.09,31.09,0,0,0-10,2.56C41.73,3.07,40.9,3.46,40,4h0A31.8,31.8,0,0,0,26.45.08,44.19,44.19,0,0,0,13.54,1.25,48.14,48.14,0,0,0,5.31,4,8.77,8.77,0,0,0,0,12.13Q0,30.82,0,49.49v8.85a8,8,0,0,0,11,7.43l.73-.3c.25-.11.5-.21.73-.29A44.49,44.49,0,0,1,21.79,63a17,17,0,0,1,5.12.2,39.2,39.2,0,0,1,6,1.78c.62.23,1.25.45,1.9.67l.78.27a8,8,0,0,0,4.45.24,7.89,7.89,0,0,0,4.2-.17l.13,0,.54-.18,1.46-.52,3-1.06A19.75,19.75,0,0,1,58.31,63a44.52,44.52,0,0,1,10,2.51l.4.18A8,8,0,0,0,80,58.75c0-.3,0-.54,0-.79q0-23,0-46A8.55,8.55,0,0,0,74.52,3.93Z" />
                <path fill='white' d="M8,58.34c.66-.27,1.25-.53,1.86-.74a52.36,52.36,0,0,1,11-2.6,24.75,24.75,0,0,1,7.49.29,45.73,45.73,0,0,1,7.23,2.13l2.58.9V13a.74.74,0,0,0,0-.26c-.06-.18-.1-.42-.23-.52A15.42,15.42,0,0,0,36.12,11,23.7,23.7,0,0,0,26,8.06,36.15,36.15,0,0,0,15.38,9a40.06,40.06,0,0,0-6.87,2.27.76.76,0,0,0-.51.82Q8,35,8,57.79v.54Zm33.79,0,.43-.14c1.53-.54,3.05-1.09,4.59-1.61A27.63,27.63,0,0,1,59.34,55a51.91,51.91,0,0,1,11.94,3c.22.08.44.19.69.31,0-.17,0-.28,0-.39q0-23,0-46a.5.5,0,0,0-.36-.55c-.93-.36-1.85-.77-2.79-1.1A36.59,36.59,0,0,0,53.23,8.15,23.2,23.2,0,0,0,45.74,10a15.78,15.78,0,0,0-3.59,2.1.93.93,0,0,0-.36.78q0,22.42,0,44.83v.56Z" />
            </g>
        </g>
    </svg>
)

export default BookSVG
