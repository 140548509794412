/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'

import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

import LineButton from 'buttons/LineButton/LineButton'
import CarouselCards from 'components/CarouselCards/CarouselCards'
import ArrowButton from 'buttons/ArrowButton/ArrowButton'
import LanguageFilter from './LanguageFilter/LanguageFilter'
import RouteMap from 'assets/RouteMap'

import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

import { withStyles } from '@material-ui/core/styles'
import { useStyles } from './PreviewCard_style'

const PreviewCard = (props) => {
    const { classes } = props
    const baseUrl = window.location.protocol + '//' + window.location.host

    return (
        <Box maxWidth='lg' className={classes.mainContainer}>
            <Container maxWidth='lg' className={classes.similarJobContainer}>
                <Grid container>
                    <Box className={classes.headerContainer}>
                        <Grid item xs={12} md={props.redirectionButton ? 4 : 6} className={`${classes.gridItem} ${classes.titleItem}`}>
                            <Typography variant='h2' className={classes.previewTitle}>
                                {props.title}
                            </Typography>
                        </Grid>
                        {props.redirectionButton && props.edit &&
                            <Grid item xs={12} md={2} className={classes.gridItem}>
                                <Box style={{ '--main-color': props.redirectionButton.color }} >
                                    <RouteMapLink
                                        redirectionUrl={props.redirectionButton.href}
                                    >
                                        <LineButton
                                            name={props.redirectionButton.name}
                                            style={{ width: 200 }}
                                        />
                                    </RouteMapLink>
                                </Box>
                            </Grid>
                        }
                        <Hidden smDown>
                            <Grid item xs={6} md={6} className={`${classes.gridItem} ${classes.buttonItem}`}>
                                {props.edit &&
                            <ArrowButton
                                redirectionUrl={props.subTitleRedirection}
                                onClick={props.closeJob}
                                name={props.subTitle}
                            />
                                }
                            </Grid>
                        </Hidden>
                    </Box>

                    <Grid item xs={12} className={classes.cardsSlider}>
                        {props.cards.length > 0
                            ? <CarouselCards
                                cards={props.cards}
                                type={props.type}
                                userType={props.userType}
                                fullWidth={props.fullWidth}
                                id='preview-card'
                            />
                            : props.displayEmpty && <Typography variant='body2'>
                                {props.noCards}
                            </Typography>
                        }
                    </Grid>

                    <Hidden mdUp>
                        <Grid item xs={12} className={`${classes.gridItem} ${classes.buttonItem}`}>
                            {props.edit &&
                        <ArrowButton
                            redirectionUrl={props.subTitleRedirection}
                            onClick={props.closeJob}
                            name={props.subTitle}
                        />
                            }
                        </Grid>
                    </Hidden>
                </Grid>
            </Container>
        </Box>
    )
}

export default withStyles(useStyles)(PreviewCard)

PreviewCard.defaultProps = {
    type: 'article',
    title: 'nos annonce',
    subTitle: 'Voir toutes les offres',
    showMoreBtnResponsiveText: 'Tous les articles similaires',
    noCards: 'Aucune carte pour le moment',
    displayEmpty: true
}
