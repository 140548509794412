import { Box, Typography } from '@material-ui/core'
import React from 'react'

const ErrorView = (props) => {
    return (
        <Box style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            flexDirection: 'column'
        }}>
            <Typography variant='h1'>
                A problem has occurred. We apologise for the inconvenience.
            </Typography>
            <Typography variant='h1'>
                The team will correct the problem as soon as possible.
            </Typography>
        </Box>
    )
}

export default ErrorView
