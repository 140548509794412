import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    icon: {
        position: 'absolute',
        right: 20,
        cursor: 'pointer',
    },
    dropdownContainer: {
        maxWidth: 400,
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        position: 'relative'
    },
    select: {
        height: 40
    },
    customSelect: {
        padding: 12,
        fontSize: 14,
        borderRadius: 20,
        fontFamily: "'GreedStandard-Regular', sans-serif",
        backgroundColor: 'var(--background-white)',
        border: '1px solid var(--background-black)',
        '&:focus': {
            background: 'var(--background-white) !important',
            borderRadius: '20px !important',
            '& svg': {
                color: 'var(--foreground-purple) !important'
            }
        },
        '&:hover': {
            color: 'var(--foreground-purple)',
            boxShadow: `4px 4px 0px 0px ${theme.palette.bg['black']}`,
            border: `2px solid ${theme.palette.fg['black']}`,
            '& svg': {
                color: 'var(--foreground-purple) !important'
            }
        }
    },
    customPaper: {
        maxHeight: 350,
        boxShadow: `4px 4px 0px 0px ${theme.palette.bg.black}`,
        border: `2px solid ${theme.palette.fg.black}`,
        backgroundColor: theme.palette.bg.white,
        borderRadius: 16,
        fontSize: '16px !important'
    },
    menuItem: {
        fontSize: '16px !important',
        backgroundColor: 'white !important',
        '&:hover': {
            backgroundColor: 'white',
            color: 'var(--foreground-purple) !important'
        }
    },
}))
