import React from 'react'
import { useStyles } from '../KotShowBanner/KotShowBanner_style'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import RouteMap from 'assets/RouteMap'
import Button from 'components/Button/Button'
import FavoriteAction from 'components/FavoriteAction/FavoriteAction'
import ShareAction from 'components/ShareAction/ShareAction'

const ApplyBox = ({ className, userType, kot, applyText, unavailable, openLoginModal, apply, setFavourite }) => {
    const classes = useStyles()

    const clickAction = () => {
        if (userType === 'not_connected') {
            openLoginModal()
        } else if (userType === 'student' && kot.application_type === 'application_url') {
            window.location.href = kot.application_url
        } else if (userType === 'student' && !kot.alreadyApplied) {
            apply()
        } else if (kot.alreadyApplied) {
            window.location.href = RouteMap.Page(kot.chat_url)
        }
    }

    return (
        <Box className={className} style={{ width: '100%' }}>
            <Box>
                <FavoriteAction
                    item={kot}
                    userType={userType}
                    size='18px'
                    setFavourite={setFavourite}
                    padding='0px'
                />
            </Box>
            <Box className={classes.iconContainer}>
                <ShareAction
                    infos={{
                        title: kot.title,
                        url: window.location.href
                    }}
                    size='18px'
                    padding='0px'
                    margin = '10px'
                />
            </Box>
            <Hidden smDown>
                <Button
                    variant='primary'
                    size='big'
                    className={classes.applyBoxButton}
                    disabled={unavailable || !['student', 'not_connected'].includes(userType)}
                    onClick={clickAction}
                >
                    {applyText}
                </Button>

            </Hidden>
            <Hidden mdUp>
                <Button
                    variant='primary'
                    size='small'
                    className={classes.applyBoxButton}
                    disabled={unavailable || !['student', 'not_connected'].includes(userType)}
                    onClick={clickAction}
                >
                    {applyText}
                </Button>

            </Hidden>
        </Box>
    )
}

export default ApplyBox
