import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    multiFonts: {
        '& .GreedCondensedRegular': {
            fontFamily: 'GreedCondensed-Regular',
            fontWeight: 400,
        },
        '& .GreedCondensedHeavy': {
            fontFamily: 'GreedCondensed-Heavy',
            fontWeight: 900,
        },
        '& .GreedNarrowHeavy': {
            fontFamily: 'GreedNarrow-Heavy',
            fontWeight: 900
        },
        '& .GreedWideLight': {
            fontFamily: 'GreedWide-Light',
            fontWeight: 300
        },
        '& .GreedWideSemiBold': {
            fontFamily: 'GreedWide-SemiBold',
            fontWeight: 600,
        },
        '& .GreedWideHeavy': {
            fontFamily: 'GreedWide-Heavy',
            fontWeight: 900,
        },
    }
}))
