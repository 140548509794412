export const useStyles = (theme) => ({
    gridContainer: {
        marginBottom: 25,
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        }
    },
    gridItem: {
        display: 'flex',
        alignItems: 'center'
    },
    noFlexWrap: {
        flexWrap: 'inherit',
        [theme.breakpoints.down("xs")]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    headerContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down("xs")]: {
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    titleItem: {
        display: 'flex',
        gap: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: 10
        }
    },
    previewTitle: {
        fontSize: '22px !important'
    },
    subTitle: {
        color: 'var(--main-color)',
    },
    buttonItem: {
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end'
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'
        }
    },
    cardsSlider: {
        [theme.breakpoints.up('md')]: {
            marginTop: 25
        },
        [theme.breakpoints.down('md')]: {
            marginTop: 10
        }
    },
    imagesEdit: {
        [theme.breakpoints.down('md')]: {
            padding: 20
        }
    },
    languagesContainer: {
        display: 'flex',
        gap: 10
    },
    companyImagesGallery: {
        '& .react-photo-gallery--gallery > div': {
            justifyContent: 'center'
        }
    },
    imageRender: {
        display: 'block',
        width: '100%',
        height: 'auto',
        borderRadius: 20
    }
})
