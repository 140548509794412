import React from 'react'
import css from './field.scss'
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'
import Checkbox from '@material-ui/core/Checkbox'
import IconFactory from 'icons/IconFactory/IconFactory'

import Typography from '@material-ui/core/Typography'

/***

    Components Field

    USAGE : type -> input type see https://www.w3schools.com/html/html_form_input_types.asp
                for type 'creatable', see https://react-select.com/creatable
            title -> optional (title="Name")
            name -> optional (name of the variable you want to deal with)
            handleChange -> function that modifie value of name (father component)
            placeholder -> optional(placeholder="i m the placeholder")
            optionSelect -> tab of string ["tata", "titi", "toto", "tutu"]

***/

export default class Field extends React.Component {
    constructor (props) {
        super(props)
        this.state = {}
        if (this.props.type === 'select') {
            var optionSelect = this.props.optionSelect
            if (this.props.blank) {
                if (this.props.different && optionSelect[0][0]) {
                    optionSelect.unshift([this.props.placeholder, ''])
                } else if (!this.props.different && optionSelect[0]) {
                    optionSelect.unshift('')
                }
            }
            const listOptionSelect = optionSelect.map((selectOption, x) => {
                return (
                    <option
                        value={this.props.different ? selectOption[selectOption.length - 1] : selectOption}
                        key={x}
                    >
                        {this.props.different ? selectOption[0] : selectOption}
                    </option>
                )
            })

            this.state = {
                listOptionSelect: listOptionSelect
            }
            if (this.props.initVar && !this.props.value) {
                var firstElement = this.props.different ? optionSelect[0][optionSelect[0].length - 1] : optionSelect[0]
                this.props.initVar(this.props.name, firstElement)
            }
        }

        this.createRadioButtons = this.createRadioButtons.bind(this)
        this.createSchoolCampusList = this.createSchoolCampusList.bind(this)
        this.createHashesList = this.createHashesList.bind(this)
    }

    createHashesList (optionSelect) {
        const tempOptionSelect = optionSelect
        if (this.props.blank) {
            if (this.props.different && tempOptionSelect[0].name) {
                tempOptionSelect.unshift([this.props.placeholder, ''])
            } else if (!this.props.different && tempOptionSelect[0]) {
                tempOptionSelect.unshift('')
            }
        }
        if (this.props.other) {
            if (tempOptionSelect[tempOptionSelect.length - 1].name != 'OTHER') {
                tempOptionSelect.push({ name: 'OTHER' })
            }
        }
        const listOptionSelect = tempOptionSelect.map((selectOption, index) => {
            // let value = selectOption['name'] ? selectOption['name'] : selectOption['location']
            // let name = selectOption['en'] ? selectOption['en'] : value

            const value = selectOption.value
            let name = selectOption.name

            if (this.props.name == 'campus') {
                name = selectOption.name
                    ? `${selectOption.name} - ${selectOption.location} `
                    : selectOption.location
            }
            if (index == 0) {
                return (
                    <option
                        value={tempOptionSelect[index].value}
                    >
                        {tempOptionSelect[index].name}
                    </option>
                )
            }
            return (
                <option
                    key={`select-option-${index}`}
                    value={value}
                >
                    {name}
                </option>
            )
        })

        return listOptionSelect
    }

    createSchoolCampusList (optionSelect) {
        const tempOptionSelect = optionSelect
        if (this.props.blank) {
            if (this.props.different && tempOptionSelect[0].name) {
                tempOptionSelect.unshift([this.props.placeholder, ''])
            } else if (!this.props.different && tempOptionSelect[0]) {
                tempOptionSelect.unshift('')
            }
        }
        if (this.props.other) {
            if (tempOptionSelect[tempOptionSelect.length - 1].name != 'OTHER') {
                tempOptionSelect.push({ name: 'OTHER' })
            }
        }
        const listOptionSelect = tempOptionSelect.map((selectOption, index) => {
            const name = selectOption.en ? selectOption.en : selectOption.name
            return (
                <option
                    value={index}
                    key={'select-option-' + { name } + '-' + index}
                >
                    {name}
                </option>
            )
        })
        return listOptionSelect
    }

    createRadioButtons () {
        var radioButtons = []
        var size = this.props.size ? parseInt(this.props.size) : this.props.options.length
        for (var i = 0; i < this.props.options.length; i += size) {
            var tempArray = this.props.options.slice(i, i + size)
            radioButtons.push(
                <div className="item-direction" key={'direction-' + i}>
                    {tempArray.map((elem, idx) => {
                        return (
                            <label className="radio-label" key={this.props.name + idx} style={{ display: 'flex' }}>
                                <Typography
                                    color='primary'
                                    variant='body2'
                                >
                                    <Checkbox
                                        color='primary'
                                        required={this.props.required}
                                        icon={<IconFactory icon='circle' fontSize='1rem'/>}
                                        checkedIcon={<IconFactory icon='circle-full' fontSize='1rem' />}
                                        checked={this.props.value === elem.value}
                                        onChange={this.props.handleChange}
                                        value={elem.value}
                                        name={this.props.name}
                                    />
                                    {elem.name}
                                </Typography>
                            </label>
                        )
                    })}
                </div>
            )
        }
        return radioButtons
    }

    render () {
        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                color: 'var(--grey)'
            }),
            control: () => ({
                width: '10vw',
                height: '22px',
                display: 'flex',
                borderBottom: '2px solid var(--grey)',
                borderRadius: 0,
                padding: 0,
                color: 'var(--grey)',
                fontFamily: 'GintoNormal-Regular',
                div: {
                    color: 'var(--grey)',
                    padding: 0
                },
                svg: {
                    width: '1.2vw',
                    heigth: '1.2vw',
                    color: 'var(--main-color)'
                }
                // none of react-select's styles are passed to <Control />
            }),
            singleValue: (provided, state) => {
                return { ...provided }
            }
        }
        if (!this.props.hide) {
            if (this.props.type == 'select') {
                var filedReturn = (
                    <div className="custom-select-container">
                        <select
                            required={this.props.required}
                            className="custom-select"
                            form={this.props.form}
                            value={this.props.value}
                            name={this.props.name}
                            onChange={this.props.handleChange}
                            inputProps={{MenuProps: {disableScrollLock: true}}}
                            style={this.props.error ? { borderColor: '#e32b47' } : { borderColor: '#ECECEC' }}
                        >
                            {this.state.listOptionSelect}
                        </select>
                        <IconFactory icon='down'/>
                    </div>
                )
            } else if (this.props.type == 'hashes-select') {
                var filedReturn = (
                    <div className="custom-select-container">
                        <select
                            required={this.props.required}
                            className="custom-select"
                            form={this.props.form}
                            value={this.props.value}
                            name={this.props.name}
                            onChange={this.props.handleChange}
                            placeholder={this.props.placeholder}
                            inputProps={{MenuProps: {disableScrollLock: true}}}
                            style={this.props.error ? { borderColor: '#e32b47' } : { borderColor: '#ECECEC' }}
                        >
                            {this.createHashesList(this.props.optionSelect)}
                        </select>
                        <IconFactory icon='down'/>
                    </div>
                )
            } else if (this.props.type == 'school-campus-select') {
                var filedReturn = (
                    <div className="custom-select-container">
                        <select
                            required={this.props.required}
                            className="custom-select"
                            value={parseInt(this.props.value, 10)}
                            form={this.props.form}
                            name={this.props.name}
                            onChange={this.props.handleChange}
                            inputProps={{MenuProps: {disableScrollLock: true}}}
                        >
                            {this.createSchoolCampusList(this.props.optionSelect)}
                        </select>
                        <IconFactory icon='down'/>
                    </div>
                )
            } else if (this.props.type == 'radio') {
                var filedReturn = (
                    <div className={'custom-radio ' + this.props.direction + '-direction'}>
                        {this.createRadioButtons()}

                    </div>
                )
            } else if (this.props.type == 'color') {
                var filedReturn = (
                    <div className="field-input-flex-color" >
                        <input
                            className="colorpicker"
                            type={this.props.type}
                            id="colorpicker"
                            name={this.props.name}
                            style={{ visibility: 'hidden' }}
                            onChange={this.props.handleChange}
                        />
                        <label
                            className="box"
                            style={{ backgroundColor: this.props.value }}
                            onClick={this.props.onClick}
                        />
                        <input
                            className="field-input"
                            type="input"
                            name={this.props.name}
                            value={this.props.value}
                            placeholder={this.props.placeholder}
                            onChange={this.props.handleChange}
                            disabled={this.props.disabled}
                        />
                    </div>
                )
            } else if (this.props.type == 'clickable') {
                var filedReturn = <div className="field-input">{this.props.placeholder}</div>
            } else if (this.props.type == 'text') {
                var filedReturn = (
                    <div className={'text-field ' + this.props.direction + '-direction'}>
                        <textarea
                            className="field-input text-input"
                            required={this.props.required}
                            type={this.props.type}
                            name={this.props.name}
                            style={this.props.error ? { borderColor: '#e32b47' } : { borderColor: '#ECECEC' }}
                            value={this.props.value}
                            placeholder={this.props.placeholder}
                            onChange={this.props.handleChange}
                            disabled={this.props.disabled}
                            maxLength={this.props.maxlength ? this.props.maxlength : -1}
                        />
                    </div>
                )
            } else if (this.props.type == 'type-to-select') {
                var filedReturn = (
                    <div className={'type-to-select-container'}>
                        <Select
                            onChange={this.props.onChange}
                            options={this.props.typeToSelectOptions}
                            styles={customStyles}
                        />
                    </div>
                )
            } else if (this.props.type == 'creatable') {
                var filedReturn = (
                    <CreatableSelect
                        isMulti
                        onChange={this.props.optionsChange}
                        options={this.props.creatableOptions}
                    />
                )
            } else {
                var filedReturn = (
                    <React.Fragment>
                        <input
                            type={this.props.type}
                            min={this.props.min}
                            className="field-input"
                            required={this.props.required}
                            style={{borderColor: this.props.error ? '#e32b47' : '#ECECEC'}}
                            name={this.props.name}
                            value={this.props.value}
                            placeholder={this.props.placeholder}
                            onChange={this.props.handleChange}
                            autoComplete={this.props.autocomplete}
                            disabled={this.props.disabled}
                        />
                    </React.Fragment>
                )
            }
            return (
                <div className="field" key={this.props.name} style={this.props.style}>
                    {this.props.type != 'type-to-select' &&
                        <div ref={this.props.ref} className="field-title">
                            <Typography color='primary' variant='h4'>
                                {this.props.title}
                            </Typography>
                        </div>

                    }
                    {this.props.additionalMessage &&
                        <Typography
                            variant='subtitle1'
                            className="additional-message"
                        >
                            {this.props.additionalIcon} {this.props.additionalMessage}
                        </Typography>
                    }
                    {filedReturn}
                    <p className="error" >
                        {this.props.error}
                    </p>
                </div>
            )
        } else {
            return null
        }
    }
}
