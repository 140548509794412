import axios from 'axios'
import ReactOnRails from 'react-on-rails'

import { JOB_RESULTS_EXPORT } from './env'

const ExportSelection = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: JOB_RESULTS_EXPORT,
        method: 'get',
        responseType: 'blob',
        params: data
    })
}

export default {
    ExportSelection
}
