import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@material-ui/core'

import { useStyles } from './FieldTitle_style'

const FieldTitle = (props) => {
    const { title, isOptional } = props
    const classes = useStyles()

    return (
        <Typography variant='h6' component='h5' className={classes.title}>
            {title}
            {isOptional
                ? <span className={classes.optional}>
                    <FormattedMessage id='jobcreationfunnel.optional' />
                </span>
                : ' *'
            }
        </Typography>
    )
}

export default FieldTitle
