import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    containerCard: {
        border: `1px solid ${theme.palette.fg.silver}`,
        height: (props) => props.isCompact ? 200 : 130,
        width: '100%',
        borderRadius: '20px',
        backgroundColor: theme.palette.bg.white,
        display: 'flex',
        transition: 'all 0.3s ease',
        '&:hover': {
            cursor: 'pointer',
            borderColor: `1px solid ${theme.palette.fg.black}`
        }
    },
    containerCover: {
        position: 'relative',
        visibility: (props) => props.isCompact ? 'hidden' : 'inherit',
        width: (props) => props.isCompact ? 0 : 140,
        minWidth: (props) => props.isCompact ? 0 : 140,
        borderTopLeftRadius: '20px',
        borderBottomLeftRadius: '20px'
    },
    containerLogoCompany: {
        border: '1px solid var(--background-silver)',
        position: (props) => props.isCompact ? 'unset' : 'absolute',
        right: (props) => props.isCompact ? '' : '-23%',
        top: (props) => props.isCompact ? '' : '12px',
        height: (props) => props.isCompact ? 40 : 64,
        width: (props) => props.isCompact ? 40 : 64,
        minWidth: (props) => props.isCompact ? 40 : 64,
        overflow: 'hidden',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'var(--background-white)'
    },
    containerBody: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        minWidth: 0,
        padding: (props) => props.isCompact ? 16 : '16px 16px 16px 50px',
        gap: 6
    },
    containerBodyTop: {
        display: 'flex',
        justifyContent: 'space-between',
        minWidth: 0,
        alignItems: (props) => props.isCompact ? 'flex-start' : 'center',
        gap: 6
    },
    containerBodyBottom: {
        display: 'flex',
        flexDirection: (props) => props.isCompact ? 'column' : 'row',
        justifyContent: (props) => props.isCompact ? 'flex-start' : 'space-between',
        width: '100%',
        alignItems: (props) => props.isCompact ? 'flex-end' : 'flex-start',
        gap: 6
    },
    cardMediaV2: {
        borderTopLeftRadius: '20px',
        borderBottomLeftRadius: '20px',
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'black'
    },
    image: {},
    companyMedia: {
        height: (props) => props.isCompact ? 40 : 55,
        width: (props) => props.isCompact ? 40 : 55,
        objectFit: 'contain',
        fontSize: 10,
        textAlign: 'center'
    },
    companyName: {
        color: theme.palette.fg.black,
        fontSize: (props) => props.isCompact ? 14 : 12,
        lineHeight: (props) => props.isCompact ? '16px' : '13px',
        textTransform: (props) => 'uppercase',
    },
    containerTitles: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: (props) => props.isCompact ? 'unset' : 'flex-start',
        gap: 6,
        flexGrow: 1
    },
    containerNameAndLogo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 16
    },
    title: {
        overflow: 'hidden',
        gap: (props) => props.isCompact ? 18 : 20,
        lineHeight: 1.4,
        maxHeight: 'calc(20px * 1.4 * 2)', // title max 2 lines -> calc(fontSize * lineHeight * number of lines)
        wordBreak: 'word-break',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        fontSize: 18
    },
    containerLocation: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 6,
        height: 16
    },
    location: {
        color: theme.palette.fg.black40Opacity,
        fontSize: 12,
        whiteSpace: 'nowrap',
        textTransform: 'capitalize'
    },
    containerTags: {
        display: 'flex',
        flexDirection: (props) => props.isCompact ? 'column' : 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: (props) => props.isCompact ? 10 : 15,
        width: (props) => props.isCompact ? '100%' : '',
        overflowX: 'scroll',
        scrollbarWidth: 'none' /* Firefox */,
        '-ms-overflow-style': 'none' /* IE 10+ */,
        '&::-webkit-scrollbar': {
            height: 0, /* Chrome/Safari/Webkit */
            background: 'transparent'
        }
    },
    containerLocationFavorite: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexGrow: 1,
        width: (props) => props.isCompact ? '100%' : 'unset'
    },
    containerJobType: {
        height: '42px',
        width: '42px',
        borderRadius: '100%',
        backgroundColor: 'var(--background-green)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))
