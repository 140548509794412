/* eslint-disable react/prop-types */
import React from 'react'

import PageWrapper from '../../../shared/wrappers/PageWrapper/PageWrapper'
import SharedTabs from '../../../shared/components/SharedTabs/SharedTabs'

import CompanyProfileForm from './CompanyProfileForm'

// eslint-disable-next-line react/display-name
export default (props) => {
    return (
        <PageWrapper user={props.user} disableFooter={props.disableFooter} disableFooterLists disableNavbar={props.disableNavbar}>
            <SharedTabs user={props.user} currentTab={`/companies/${props.user.company_id}`} />
            <CompanyProfileForm {...props} />
        </PageWrapper>
    )
}
