import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    myMessagesButton: {
        minWidth: 'unset',
        borderRadius: '8px',
        width: '36px',
        '&:hover': {
            backgroundColor: 'var(--black-over)'
        },
        backgroundColor: 'transparent'
    },
    myMessagesIcon: {
        fontSize: '24px !important',
        color: 'white'
    }
}))
