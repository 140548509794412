import React, { useState, useCallback, useRef } from 'react'
import { Box, ClickAwayListener, Popper } from '@material-ui/core'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.core.css'
import ImageResize from 'quill-image-resize'
import Bugsnag from '@bugsnag/js'

import { useStyles } from './ArticleFormTextEditor_style'
import DocumentAPI from 'api/DocumentAPI'
import CloudinaryHelper from 'assets/CloudinaryHelper'
import CloudinaryURLHelper from 'assets/CloudinaryURLHelper'
import { SurveyFormClipboard, ImageFormat, CustomButtonEmbed, CustomIframeEmbed } from 'util/quillUtil'
import ToolbarWidget from '../ToolbarWidget/ToolbarWidget'

ReactQuill.Quill.register('modules/ImageResize', ImageResize, true)
ReactQuill.Quill.register('modules/clipboard', SurveyFormClipboard, true)
ReactQuill.Quill.register(ImageFormat, true)
ReactQuill.Quill.register(CustomButtonEmbed, true)
ReactQuill.Quill.register(CustomIframeEmbed, true)

const ArticleFormTextEditor = (props) => {
    const classes = useStyles()
    const { value, onChange, isAdmin } = props
    const quillRef = useRef()
    const [anchorEl, setAnchorEl] = useState()
    const [open, setOpen] = useState(false)
    const [lastKnownCursorPosition, setLastKnownCursorPosition] = useState()

    const postQuillUpload = (uploadResult) => {
        const data = {
            cloudinary_key: uploadResult.public_id,
            format: uploadResult.format,
            name: uploadResult.original_filename,
            is_image: true
        }
        DocumentAPI.postImage(data).then((response) => {
            const quillEditor = quillRef.current.editor
            const cursorPosition = quillEditor.getSelection()
            const imageUrl = CloudinaryURLHelper.getImageUrl('ARTICLE_FORM_EDITOR', response.data.document_object.cloudinary_key)
            quillEditor.insertEmbed(cursorPosition?.index || 0, 'image', imageUrl)
        }).catch((error) => {
            alert(`An error has occurred: ${error}`)
            Bugsnag.notify(error)
        })
    }

    const handleQuillImage = useCallback(
        () => {
            const myWidget = CloudinaryHelper.createImageUploadWidget(
                {
                    cropping: true,
                    multiple: false
                },
                (error, result) => {
                    if (!error && result?.event === 'success') {
                        postQuillUpload(result.info)
                    }
                }
            )
            myWidget.open()
        },
        [quillRef]
    )

    const handleCustomHTML = (type, htmlValue) => {
        const quillEditor = quillRef.current.editor
        if (type === 'button') {
            quillEditor.insertEmbed(lastKnownCursorPosition.index || 0, 'CustomButtonEmbed', htmlValue)
        } else if (type === 'iframe') {
            quillEditor.insertEmbed(lastKnownCursorPosition.index || 0, 'CustomIframeEmbed', htmlValue)
        }
    }
    
    const openCustomHTMLWidget = useCallback(
        () => {
            const quillEditor = quillRef.current.editor
            setOpen(true)
            setAnchorEl(quillEditor)
            setLastKnownCursorPosition(quillEditor.getSelection())
        },
        [quillRef]
    )

    const modules = {
        toolbar: {
            container: [
                [{ header: [2, 3, false] }],
                ['bold', 'italic', 'underline'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['link', 'image', 'video'],
                ['clean'],
                [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                ['background']
            ],
            handlers: {
                image: handleQuillImage,
                background: openCustomHTMLWidget
            }
        },
        clipboard: { matchVisual: false },
        ImageResize: {}
    }

    return (
        <div className={classes.container}>
            <ReactQuill
                ref={quillRef}
                value={value}
                onChange={onChange}
                modules={modules}
            />
            <Popper className={classes.dropdown} placement='top' open={open} anchorEl={anchorEl} transition disablePortal>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <Box className={classes.htmlContentSelector}>
                        <ToolbarWidget isAdmin={isAdmin} handleCustomHTML={handleCustomHTML} />
                    </Box>
                </ClickAwayListener>
            </Popper>
        </div>
    )
}

export default ArticleFormTextEditor
