import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Image, Transformation } from 'cloudinary-react'

import { useStyles } from './CTADashboard_style'
import { Typography, Container, Box } from '@material-ui/core'

import Button from 'shared/components/Button/Button.jsx'

import RouteMap from 'assets/RouteMap'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const CTADashboard = (props) => {
    const { user } = props
    const classes = useStyles()

    const getUserRedirection = () => {
        if (user) {
            switch (user.type) {
            case 'student':
                return RouteMap.Page('users/dashboard')
            case 'employer':
                return RouteMap.Page('users/company_jobs')
            default:
                return RouteMap.Page('users/kots')
            }
        } else {
            return RouteMap.Page('login')
        }
    }

    return (
        <Container className={classes.bannerContainer} disableGutters>
            <Box className={classes.upContainer}>
                <FormattedMessage id='cta_banner_dashboard_title' >
                    {text =>
                        <Typography className={classes.titleContainer} variant='h1' component='p' dangerouslySetInnerHTML={{ __html: text }} />
                    }
                </FormattedMessage>
                <Typography className={classes.description}>
                    <FormattedMessage id='landing_student.alert_description' />
                </Typography>
                <RouteMapLink redirectionUrl={getUserRedirection()}>
                    <Button variant='cta-primary' className={classes.ctaButton}>
                        <FormattedMessage id='landing_student.alert_button' />
                    </Button>
                </RouteMapLink>
            </Box>

            <Box className={classes.downContainer}>
                <Image
                    cloudName='studentbe'
                    publicId='student-be-assets/girl-orange'
                    alt='student.be post 3 jobs for free'
                    className={classes.image}
                >
                    <Transformation flags='progressive' fetchFormat='auto' quality='auto:best' crop='fill' />
                </Image>
            </Box>
        </Container >

    )
}

export default CTADashboard
