import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { useStyles } from '../../ProfileEditForm_style'
import StepButtons from '../StepButtons/StepButtons'
import ExperienceProfile from 'pages/user/Profile/StudentProfile/Components/ExperienceProfile/ExperienceProfile'
import { FormattedMessage } from 'react-intl'

const ProfileEditFormStepStudyExperienceView = (props) => {
    const { shortProfile, step, handleSubmit, previousStep, setExperiences, setExperienceIsUpdated, inputRefs, errors, highlightInputField } = props
    const classes = useStyles()

    return (
        <Box className={classes.formBox}>
            <Typography variant='h1' className={classes.title}>
                <FormattedMessage id='personal_dashboard.profile_edit_form.experience_step.description' />
            </Typography>
            <ExperienceProfile
                black
                experiences={shortProfile.experiences}
                disabledSave={false}
                setStudentFormation={setExperiences}
                studentFormation={shortProfile.experiences}
                inputRefs={inputRefs}
                errors={errors}
                setExperienceIsUpdated={setExperienceIsUpdated}
                highlightInputField={highlightInputField}
            />
            <StepButtons
                step={step}
                nextStep={handleSubmit}
                previousStep={previousStep}
            />
        </Box>
    )
}
export default ProfileEditFormStepStudyExperienceView
