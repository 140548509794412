import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './HomeCompanyFilter_style'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import NewCarouselCards from 'shared/components/NewCarouselCards/NewCarouselCards'
import CollectionHandler from 'assets/CollectionHandler.js'
import HorizontalCardsSlider from '../../../../shared/components/HorizontalCardsSlider/HorizontalCardsSlider'
import CardFactory from 'shared/cards/CardFactory/CardFactory'
import CompanyCardAlt from '../../../../shared/cards/CompanyCardAlt/CompanyCardAlt'
import { Container } from '@material-ui/core'

const HomeCompanyFilter = (props) => {
    const filter = CollectionHandler.Get('homeCarouselCardCollection')
    const classes = useStyles()

    return (
        <Box className={classes.companyFilterContainer}>
            <Box className={classes.companyFilterText}>
                <Typography
                    component='h2'
                    color='primary'
                    className={classes.companyFilterTitle}
                >
                    <FormattedMessage id="landing_student.companyFilter_title" />
                </Typography>
                <Typography
                    component='p'
                    color='primary'
                    className={classes.companyFilterDescription}
                >
                    <FormattedMessage id="landing_student.companyFilter_description" />
                </Typography>
            </Box>
            <Container disableGutters maxWidth='lg' className={classes.sliderContainer}>
                <HorizontalCardsSlider home shift={500}>
                    {filter.map((comp, index) => {
                        return (
                            <Box key={`company-filter-${index}`} className={classes.companyFilterBox}>
                                <CompanyCardAlt
                                    filter={comp}
                                />
                            </Box>
                        )
                    })}
                </HorizontalCardsSlider>
            </Container>
        </Box>
    )
}
export default HomeCompanyFilter
