import React from 'react'
import { useStyles } from './aboutterms_style'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import { FormattedMessage } from 'react-intl'

const AboutTerms = ({ user }) => {
    const classes = useStyles()

    const toParagraphs = (...nodes) => {
        let key = 0
        const title = ['Article', 'Artikel'] // title starts with Article or Artikel
        const children = nodes.reduce((result, node) => (
            result.concat(
                typeof node === 'string'
                    ? node
                        .split('\n')
                        .map(paragraph => (
                            <p key={++key}>
                                {title.some(elem => paragraph.split(' ')[0].includes(elem)) ? <strong>{paragraph}</strong> : paragraph}
                            </p>
                        ))
                    : node
            )
        ), [])
        return <span>{children}</span>
    }

    return (
        <div className='name-your-parent-container'>
            <PageWrapper user={user}>
                <Box className={classes.aboutPageBanner}>
                    <Container maxWidth='sm'>
                        <Typography variant='h1' className={classes.titlePage}>
                            <FormattedMessage id='landing_about_terms.title' />
                        </Typography>
                    </Container>
                </Box>
                <Container maxWidth='sm' className={classes.pageContainer}>
                    <Typography className={classes.text}>
                        <FormattedMessage id='landing_about_terms.content'>
                            {toParagraphs}
                        </FormattedMessage>
                    </Typography>
                </Container>
            </PageWrapper>
        </div>
    )
}

export default AboutTerms
