import React from 'react'

const DrivePlanSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86 86" {...props}>
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path d="M85.81,20.79a44.59,44.59,0,0,0-1.08-7.27s0-.1,0-.14a8.41,8.41,0,0,0-5.9-6c-.61-.17-1.24-.31-1.92-.44a54.33,54.33,0,0,0-7.1-.83,40.66,40.66,0,0,0-11,.7,48.51,48.51,0,0,0-6.55,1.92,34.34,34.34,0,0,0-11,6.47A46.09,46.09,0,0,0,34.83,22L24.35,22a8.54,8.54,0,0,0-7.69,4.76q-4.47,9-9,17.93L7,46.06,7,46.19c0,.12-.11.23-.17.38A8,8,0,0,0,14,58h1.52a11.19,11.19,0,0,0-1.23,1.42,14,14,0,0,0-2.13,4.48c-.43,1.6-.75,3.13-1.1,4.82-.17.82-.28,1.57-.37,2.24l-.1.71a8,8,0,0,0,7.83,9.21h.41a9.28,9.28,0,0,0,1.26-.11l.4-.06c.52-.07,1.17-.17,1.91-.33,1.72-.38,3.5-.77,5.31-1.23a12,12,0,0,0,5.75-3.33c.19-.21.37-.43.55-.65V78a8,8,0,0,0,11.38,7.25l.38-.17L46,85l.69-.35L65.14,75.4A8.56,8.56,0,0,0,70,67.6q0-3.43,0-6.88V57.16a48.37,48.37,0,0,0,5.81-5.25,35.17,35.17,0,0,0,5.25-7.2,42.16,42.16,0,0,0,4.26-12A35.68,35.68,0,0,0,86,25.74C86,24.27,85.93,22.7,85.81,20.79Z" />
                <path d="M79.81,14.79a44.59,44.59,0,0,0-1.08-7.27s0-.1,0-.14a8.41,8.41,0,0,0-5.9-6c-.61-.17-1.24-.31-1.92-.44A54.33,54.33,0,0,0,63.77.1a40.66,40.66,0,0,0-11,.7,48.08,48.08,0,0,0-6.55,1.92,34.34,34.34,0,0,0-11,6.47A46.09,46.09,0,0,0,28.83,16L18.35,16a8.53,8.53,0,0,0-7.69,4.76q-4.47,9-9,17.93L1,40.06,1,40.19c0,.12-.11.23-.17.38A8,8,0,0,0,8,52H9.52a11.19,11.19,0,0,0-1.23,1.42,14,14,0,0,0-2.13,4.48c-.43,1.6-.75,3.13-1.1,4.82-.17.82-.28,1.57-.37,2.24l-.1.71a8,8,0,0,0,7.83,9.21h.41a9.28,9.28,0,0,0,1.26-.11l.4-.06c.52-.07,1.17-.17,1.91-.33,1.72-.38,3.5-.77,5.31-1.23a12,12,0,0,0,5.75-3.33c.19-.21.37-.43.55-.65V72a8,8,0,0,0,11.38,7.25l.38-.17L40,79l.69-.35L59.14,69.4A8.58,8.58,0,0,0,64,61.6q0-3.43,0-6.88V51.16a48.37,48.37,0,0,0,5.81-5.25,35.17,35.17,0,0,0,5.25-7.2,42.16,42.16,0,0,0,4.26-12,35.68,35.68,0,0,0,.68-7C80,18.27,79.93,16.7,79.81,14.79Z" />
                <path className="cls-1" fill="white" d="M36,72V55.64l-.43.14c-1.92.61-3.85,1.22-5.77,1.86a.81.81,0,0,1-.95-.25q-3.3-3.33-6.63-6.63a.48.48,0,0,1-.13-.57c.68-1.92,1.34-3.84,2-5.76l.13-.42H8l.17-.37Q13,34,17.82,24.32a.51.51,0,0,1,.53-.33l14.31,0a.54.54,0,0,0,.53-.29,38.81,38.81,0,0,1,7.27-8.48,26.42,26.42,0,0,1,8.46-5,41.67,41.67,0,0,1,5.49-1.62,32.82,32.82,0,0,1,8.85-.55,47.91,47.91,0,0,1,6.09.7c.44.09.89.18,1.33.3a.46.46,0,0,1,.26.27,36.33,36.33,0,0,1,.89,6c.09,1.49.16,3,.17,4.47a27.32,27.32,0,0,1-.54,5.4A34,34,0,0,1,68,34.91,26.69,26.69,0,0,1,64,40.46a43.53,43.53,0,0,1-6.28,5.46,15.55,15.55,0,0,1-1.32.85.64.64,0,0,0-.35.64c0,4.73,0,9.47,0,14.21a.54.54,0,0,1-.35.58L36.4,71.82,36,72ZM51,24a5,5,0,1,0,5-5A4.92,4.92,0,0,0,51,24Z" />
                <path className="cls-1" fill="white" d="M12.5,66.89c.13-.87.22-1.72.39-2.55.3-1.45.6-2.9,1-4.33a6,6,0,0,1,.89-1.89,4,4,0,0,1,3.12-1.6A4.55,4.55,0,0,1,22.29,59a4.39,4.39,0,0,1-.66,5.34,4,4,0,0,1-1.91,1.07c-1.68.43-3.38.8-5.08,1.17-.59.13-1.19.2-1.78.3l-.36,0Z" />
            </g>
        </g>
    </svg>
)

export default DrivePlanSVG
