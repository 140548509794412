import { isScreenSizeMobile } from './WindowHelper'

const BASE_URL = 'https://res.cloudinary.com/studentbe/image/upload'
export const TINY_SIZE = 50
export const EXTRA_SMALL_SIZE = 100
export const SMALLER_SIZE = 200
export const SMALL_SIZE = 400
export const MEDIUM_SMALL_SIZE = 600
export const MEDIUM_SIZE = 800
export const LARGE_SIZE = 1024
export const LARGER_SIZE = 1280
export const EXTRA_LARGE_SIZE = 2048

export const optimiseSizeForMobile = (width, ratio = 0.75) => {
    const optimisedWidth = isScreenSizeMobile() ? (width * ratio) : width
    return Math.round(optimisedWidth)
}

const defaultTransormations = {
    format: 'f_auto',
    compression: 'fl_progressive',
    quality: 'q_auto:good',
    width: `w_${MEDIUM_SIZE}`
}

const transformationsByContext = (context) => {
    if (context === 'NOTIFICATION_AVATAR_PICTURE') {
        return { width: `w_${EXTRA_SMALL_SIZE}` }
    }
    if (context === 'META_IMAGE') {
        return { width: `w_${MEDIUM_SIZE}` }
    }
    if (context === 'STUDENT_HOME_BANNER') {
        return { width: `w_${optimiseSizeForMobile(MEDIUM_SIZE, 0.80)}` }
    }
    if (context === 'LARGE_THUMBNAIL') {
        return { width: `w_${SMALL_SIZE}` }
    }
    if (context === 'MESSAGE_CARD') {
        return { width: `w_${EXTRA_SMALL_SIZE}` }
    }
    if (context === 'USER_AVATAR') {
        return { width: `w_${SMALLER_SIZE}` }
    }
    if (context === 'ARTICLE_CARD') {
        return { width: `w_${SMALL_SIZE}` }
    }
    if (context === 'KOT_CARD') {
        return { width: `w_${SMALL_SIZE}` }
    }
    if (context === 'UPLOAD_IMAGE') {
        return { width: `w_${SMALL_SIZE}` }
    }
    if (context === 'DEFAULT_LOGO_STUDENT') {
        return { width: `w_${SMALL_SIZE}` }
    }
    if (context === 'KOT_IMAGE') {
        return { width: `w_${LARGE_SIZE}` }
    }
    if (context === 'KOT_IMAGE_THUMBNAIL') {
        return { width: `w_${SMALLER_SIZE}` }
    }
    if (context === 'ARTICLE_FORM_EDITOR') {
        return { width: 'w_1280', quality: 'q_auto:best', crop: 'c_scale' }
    }
    if (context === 'NORMAL_CARD') {
        return { width: 'w_280', height: 'h_160', quality: 'q_auto:best', crop: 'c_fit' }
    }
    if (context === 'COMPANY_LOGO_ABOUT_PAGE') {
        return { height: 'h_160', quality: 'q_auto:best'}
    }
    if (context === 'HELMET_LOGO') {
        return { width: 'w_250' }
    }
    if (context === 'LANGUAGE_SELECTION_OVERLAY') {
        return { quality: 'q_auto:eco' }
    }
    return {}
}

const getImageUrl = (context, cloudinaryKey) => {
    const customTransformations = transformationsByContext(context)
    const transformationOptions = {
        ...defaultTransormations,
        ...customTransformations
    }
    const transformationUrl = Object.values(transformationOptions).join(',')
    return `${BASE_URL}/${transformationUrl}/${cloudinaryKey}`
}

const getDocumentUrl = (cloudinaryKey) => {
    return `https://res.cloudinary.com/studentbe/image/upload/${cloudinaryKey}`
}

export default { getImageUrl, getDocumentUrl }
