import React from 'react'
import { useStyles } from './ArticleCardV2_style'
import { Box, Grid } from '@material-ui/core'
import ArticleCardV2 from 'cards/ArticleCard/ArticleCardV2'
import IconFactory from 'shared/icons/IconFactory/IconFactory'
import RouteMap from 'assets/RouteMap'

const ArticleCardDecorated = (props) => {
    const { article, articleOwner, remove } = props
    const classes = useStyles()

    return (
        <Box className={classes.articleCardWrapper} id={`article-card-${article.id}`} key={`article-card-${article.id}`}>
            <ArticleCardV2 {...props}/>
            {articleOwner &&
                <Grid container direction='column' justify='flex-start' style={{ width: 'fit-content' }}>
                    <Grid item>
                        <IconFactory
                            button
                            icon='view'
                            className="action-icon"
                            onClick={() => { window.location.href = RouteMap.Show('article', article.url) }}
                        />
                    </Grid>
                    <Grid item>
                        <IconFactory
                            button
                            icon='edit'
                            className="action-icon"
                            onClick={() => { window.location.href = RouteMap.Edit('article', article.id) }}
                        />
                    </Grid>
                    <Grid item>
                        <IconFactory
                            button
                            icon='delete'
                            className="action-icon"
                            onClick={() => remove(article)}
                        />
                    </Grid>
                </Grid>
            }
        </Box>
    )
}

export default ArticleCardDecorated
