import { createContext, useState } from 'react'

export const LoginRegisterContext = createContext(null)

export const useLoginRegisterContext = () => {
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [loginRegisterConfig, setLoginRegisterConfig] = useState({})

    const openLoginRegisterModal = (config) => {
        setShowLoginModal(true)
        setLoginRegisterConfig(config)
    }

    return {
        showLoginModal,
        setShowLoginModal,
        loginRegisterConfig,
        openLoginRegisterModal
    }
}

/* ===== DOC =====

    USAGE :

    Open <LoginRegisterModal> from PageWrapper with function openLoginRegisterModal
    provided from LoginRegisterContext and providing the specific context modal
    information

    Example :

    loginRegisterContext.openLoginRegisterModal({
        origin: SAVE_FILTERS,
        afterAuthAction: this.afterAuthAction
    })

    ===== END =====
*/
