import React from 'react'

const ImpactSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86 85.94" {...props}>
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path
                    d="M85.72,29.75a26.76,26.76,0,0,0-2.19-7.34A8,8,0,0,0,74,18.12a8.12,8.12,0,0,0,.31-3.26,8,8,0,0,0-4.67-6.4A27.58,27.58,0,0,0,59.82,6a29.35,29.35,0,0,0-6.31.35,27.67,27.67,0,0,0-19.2,12.76,28.34,28.34,0,0,0-4.26,14.93,24.53,24.53,0,0,0,.34,4.35L9.45,59.25A8,8,0,0,0,8.34,71.48l7.6,7.61,4.5,4.5a8,8,0,0,0,5.66,2.35h.32a8,8,0,0,0,5.77-2.8l.06-.07,8.2-8.17L53.76,61.65a27.93,27.93,0,0,0,10.09-.35,27.83,27.83,0,0,0,21.3-20.64A28.87,28.87,0,0,0,85.72,29.75Z" />
                <path
                    d="M79.72,23.75a26.76,26.76,0,0,0-2.19-7.34A8,8,0,0,0,68,12.12a8.12,8.12,0,0,0,.31-3.26,8,8,0,0,0-4.67-6.4A27.58,27.58,0,0,0,53.82,0a29.35,29.35,0,0,0-6.31.35,27.67,27.67,0,0,0-19.2,12.76,28.25,28.25,0,0,0-4.26,14.93,24.53,24.53,0,0,0,.34,4.35L3.45,53.25A8,8,0,0,0,2.33,65.48l7.61,7.61,4.5,4.5a8,8,0,0,0,5.66,2.35h.32a8,8,0,0,0,5.77-2.8l.06-.07,8.2-8.17,13.3-13.24a28,28,0,0,0,10.1-.35,27.83,27.83,0,0,0,21.3-20.64A28.87,28.87,0,0,0,79.72,23.75Z" />
                <path className="cls-1"
                    fill='white'
                    d="M60.32,9.76c-.19.14-.32.22-.43.32L48.32,21.64a1,1,0,0,0-.32.76c0,3,0,6.05,0,9.08V32l.45,0h9.16a.92.92,0,0,0,.72-.31L69.9,20.13l.37-.35A18.4,18.4,0,0,1,71.8,24.9a20.52,20.52,0,0,1-.43,7.88,19.07,19.07,0,0,1-3.79,7.69A20.21,20.21,0,0,1,45.89,47a1,1,0,0,0-1.11.29Q32.62,59.44,20.42,71.57c-.12.12-.23.27-.32.37L8,59.83a1.25,1.25,0,0,0,.4-.22L33,35.19a.63.63,0,0,0,.19-.78,17.75,17.75,0,0,1-1.09-6.24,20.34,20.34,0,0,1,3-10.78,19.91,19.91,0,0,1,6.78-6.63A19.57,19.57,0,0,1,48.8,8.29,20.39,20.39,0,0,1,53.35,8a19.52,19.52,0,0,1,7,1.73ZM21,54.87,16.84,59,21,63.13,25.19,59,21,54.87Z" />
            </g>
        </g>
    </svg>
)

export default ImpactSVG
