import React, {} from 'react'

import { useStyles } from './CompanyKeyStats_style'
import { FormattedMessage } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

const CompanyKeyStats = (props) => {
    const classes = useStyles()
    const { stats } = props

    const getTranslation = (name, value) => {
        if (name === 'nbr_countries' && value > 1) return <FormattedMessage id={`company_profile.employer_banner.${name}_plural`} />

        return <FormattedMessage id={`company_profile.employer_banner.${name}`} />
    }

    return (
        <Box className={`${classes.container} ${props.vertical && classes.verticalContainer}`}>
            {stats.map(stat => {
                const name = Object.keys(stat)[0]
                const value = stat[Object.keys(stat)[0]]
                const translation = getTranslation(name, value)
                return (
                    <Box className={classes.companyStat} key={name}>
                        <Typography className={classes.companyStatText}>
                            {translation}
                        </Typography>
                        <Box className={classes.companyStatNumber}>
                            {value}
                        </Box>
                    </Box>
                )
            })}
        </Box>
    )
}

export default CompanyKeyStats
