import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import DateHandler from 'assets/DateHandler'
import CloudinaryURLHelper from 'assets/CloudinaryURLHelper'

export default class StudentMessageCard extends React.Component {
    render () {
        var backPic = {
            backgroundImage: `url(${CloudinaryURLHelper.getImageUrl('MESSAGE_CARD', this.props.candidacy.picture)})`,
            borderRadius: 0,
            backgroundSize: 'contain'
        }

        if (this.props.candidacy.unseen_count === 0) {
            var notificationDisplay = 'hide-notification'
        } else {
            var notificationDisplay = 'show-notification'
        }

        return (
            <Link to={'/' + this.props.candidacy.chat_url}>
                <div className={`conversation_card chat_card_${this.props.candidacy.conversation_id}`}>
                    <div className={'message-choice-card ' + this.props.candidacy.type.replace(' ', '_')}>
                        <div className="message-card-first-infos">
                            <div className={`message-card-picture ${this.props.candidacy.type.replace(' ', '_')}`} style={backPic}/>
                            <div className="message-card-st-content message-card-content-container">
                                <div className="message-card-information">
                                    <div className={`message-card-name ${notificationDisplay}`}>
                                        {this.props.candidacy.name}
                                    </div>
                                    <div className="message-card-title">
                                        <div className={'typeSubName ' + this.props.candidacy.type.replace(' ', '_')}>
                                            <FormattedMessage id={`chat.${this.props.candidacy.type.replace(' ', '_')}`} />
                                            {':' + ' '}
                                        </div>
                                        <div className="subName">
                                            {this.props.candidacy.candidacy_title}
                                        </div>
                                    </div>
                                </div>
                                <div className="message-card-arrow-and-time">
                                    <div className="message-time">{DateHandler.ToBasicFormat(this.props.candidacy.last_message_time)}</div>
                                    <div className="message-arrow"><i className="fas fa-chevron-right"></i></div>
                                </div>

                                <div className={`kot-message-notification ${this.props.candidacy.type.replace(' ', '_')} ${notificationDisplay}`}>
                                    {this.props.candidacy.unseen_count}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <% end %> */}
                </div>
            </Link>
        )
    }
}
