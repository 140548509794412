import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    stickyBannerContainer: {
        padding: '11px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '15px',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    },

    leftContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '16px'
    },

    rightContainer: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'fit-content',
        },
    },

    applyButtonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: '6px',
        [theme.breakpoints.up(710)]: {
            flexDirection: 'row',
        },
    },

    applyButton: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'fit-content',
            whiteSpace: 'nowrap',
        },
    },

    companyLogoContainer: {

    },

    logoBox: {
        overflow: 'hidden',
        display: 'block',
        width: '56px',
        height: '56px',
        border: `2px solid ${theme.palette.fg['black']}`,
        borderRadius: '50%'
    },

    logo: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },

    jobTitle: {
        color: theme.palette.fg['black'],
        fontFamily: 'GreedWide-Bold',
        fontSize: '16px',
        lineHeight: '18px',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        WebkitLineClamp: 3,
        textOverflow: 'ellipsis',
    },
}))
