import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography, useTheme } from '@material-ui/core'

import Button from 'shared/components/Button/Button'
import IconFactory from 'icons/IconFactory/IconFactory'
import { useStyles } from './MessageAllModalView_style'

const MessageAllModalView = (props) => {
    const theme = useTheme()
    const classes = useStyles()

    const {
        closeModal,
        handleMessageChange,
        message,
        openShare,
        sendMessageToSelected
    } = props

    return (
        <Dialog
            aria-labelledby="responsive-dialog-delete"
            disableScrollLock={true}
            fullWidth
            maxWidth='sm'
            onClose={closeModal}
            open={openShare}
            PaperProps={{ className: classes.dialogContainer }}
        >
            <DialogTitle onClose={closeModal}>
                <Typography variant='h2' style={{ marginTop: '1rem' }}>
                    <FormattedMessage id='message_all_popup.popup_title' />
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={closeModal}
                    style={{
                        color: theme.palette.grey[500],
                        height: 35,
                        position: 'absolute',
                        right: theme.spacing(1),
                        top: 5,
                        width: 35
                    }}
                >
                    <IconFactory icon='close' />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box m={2}>
                    <FormattedMessage id='message_all_popup.input_label'>
                        {label =>
                            <TextField
                                fullWidth
                                id="outlined-multiline-static"
                                label={label}
                                multiline
                                onChange={handleMessageChange}
                                rows={8}
                                value={message}
                                variant="outlined"
                            />
                        }
                    </FormattedMessage>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box>
                    <Button variant='secondary' size='small' onClick={closeModal}>
                        <FormattedMessage id="job_chat.cancel" />
                    </Button>
                    <Button variant='cta-secondary' size='small' onClick={sendMessageToSelected}  style={{ marginLeft: 10 }}>
                        <FormattedMessage id='message_all_popup.action_button' />
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

export default MessageAllModalView
