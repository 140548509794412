import React from 'react'
import PageWrapper from 'shared/wrappers/PageWrapper/PageWrapper'

const StudentJobLandingPage = (props) => {
    const { user } = props

    return (
        <PageWrapper user={user}>
            <div>LANDING STUDENT JOB</div>
        </PageWrapper>
    )
}

export default StudentJobLandingPage
