import { Box } from '@material-ui/core'
import React from 'react'

import { useStyles } from './FieldDivider_style'

const FieldDivider = () => {
    const classes = useStyles()

    return (
		  <Box className={classes.divider} />
    )
}

export default FieldDivider
