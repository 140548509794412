import React from 'react'
import NewPasswordPage from './NewPasswordPage'
import PageWrapper from '../../../shared/wrappers/PageWrapper/PageWrapper'

// eslint-disable-next-line react/display-name
export default (props, railsContext) => {
    return (
        <PageWrapper user={props.user}>
            <NewPasswordPage {...{ ...props, railsContext }} />
        </PageWrapper>
    )
}
