/* eslint-disable react/prop-types */
import React from 'react'
import InfoLineView from '../InfoLineView/InfoLineView'
import CollectionHandler from 'assets/CollectionHandler.js'
import Box from '@material-ui/core/Box'
import { FormattedMessage } from 'react-intl'
import { useStyles } from './LanguageLine_style'

const LanguageLine = (props) => {
    const { job } = props
    const classes = useStyles()
    if (!job.languages.length) return null
    const filteredLanguages = job.languages.filter(obj => obj.name && obj.skill_weight)

    const renderLanguageLines = () => {
        const lines = filteredLanguages.map((lang) => {
            return (
                <Box className={classes.languageLine}>
                    <span>{CollectionHandler.Translate('defaultLanguageCollection', lang.name)}:</span>
                    <span>{CollectionHandler.Translate('languageLevelCollection', lang.skill_weight.toString())}</span>
                </Box>
            )
        })

        return lines
    }

    return <>
        <InfoLineView
            icon='graduation-cap'
            text={<FormattedMessage id='job_form.step_3.language_title'/>}
        />
        <Box className={classes.lineContainer}>
            {renderLanguageLines()}
        </Box>
    </>
}

export default LanguageLine
