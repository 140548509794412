import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './ArticleListMinimalCard_style'
import { Box, Chip, Typography, useTheme } from '@material-ui/core'

import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import CollectionHandler from 'assets/CollectionHandler'
import IconFactory from 'shared/icons/IconFactory/IconFactory'
import DateHandler from 'assets/DateHandler'

const ArticleListMinimalCard = (props) => {
    const classes = useStyles()
    const { article } = props
    const theme = useTheme()

    return (
        <RouteMapLink
            showItemType='article'
            showItemUrl={article.url}
            className={classes.articleContainer}
        >
            <Chip
                classes={{ root: classes.chipRoot, label: classes.chipLabel }}
                label={CollectionHandler.Translate('articleCategoryCollection', article.category)}
            />
            <Typography variant='h3' className={classes.title}>
                {article.title}
                <Typography className={classes.readMore} component='span'>
                    <FormattedMessage id='article_minimal_card.title.read_more' />→
                </Typography>
            </Typography>
            <Box className={classes.footer}>
                <Box className={classes.infoContainer}>
                    <IconFactory icon='calendar' fontSize='12px' color={theme.palette.fg['greySlate']} />
                    <Typography className={classes.infoText}>
                        {DateHandler.toReadingFormat(article.date)}
                    </Typography>
                </Box>
                <Box className={classes.infoContainer}>
                    <IconFactory icon='clock' fontSize='12px' color={theme.palette.fg['greySlate']} />
                    <Typography className={classes.infoText}>
                        {article.read_time} <FormattedMessage id='news_index.article.read_time' />
                    </Typography>
                </Box>
            </Box>
        </RouteMapLink>
    )
}

export default ArticleListMinimalCard
