import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './StudyRelevantFilter_style'
import { Box, Typography } from '@material-ui/core'

import MultipleSelectFilter from '../MultipleSelectFilter/MultipleSelectFilter'
import CollectionHandler from 'assets/CollectionHandler.js'

const StudyRelevantFilter = (props) => {
    const { typeOfJob, filters, setFilters } = props

    const classes = useStyles()

    const studyDomainCollection = CollectionHandler.Get('studyDomainCollection')

    return (
        <Box className={classes.main}>
            <Typography variant='body2' className={classes.text}>
                <FormattedMessage
                    id='job_filters.study_relevant_filter.explainer'
                    values={{
                        type_of_job: <FormattedMessage id={`type.${typeOfJob}.plural`} />
                    }}
                />
            </Typography>

            <Box className={classes.fieldContainer}>
                <MultipleSelectFilter
                    type='studyDomain'
                    collection={studyDomainCollection}
                    filters={filters}
                    setFilters={setFilters}
                    width={400}
                    filterTextId='job_filters.study_fields.title'
                />
            </Box>
        </Box>
    )
}

export default StudyRelevantFilter
