import React, { useState, useEffect, useContext } from 'react'
import DashboardAPI from 'api/DashboardAPI'
import CompanyCard from 'cards/CompanyCard/CompanyCard'
import RouteMap from 'assets/RouteMap'
import RecommendedItemsContainer from 'components/RecommendedItemsContainer/RecommendedItemsContainer'
import { UserContext } from 'contexts/UserContext'

const RecruitingCompaniesForUser = (props) => {
    const { user } = props
    const [recruitingCompanies, setRecruitingCompanies] = useState([])
    const userContext = useContext(UserContext)

    useEffect(() => {
        getRecruitingCompanies()
    }, [userContext.statusConfigSaved])

    const getRecruitingCompanies = () => {
        const data = {
            user_id: user.id,
            amount: 3
        }
        DashboardAPI.GetRecruitingCompanies(data).then((response) => {
            setRecruitingCompanies(response.data.recruiting_companies)
        })
    }

    if (recruitingCompanies.length === 0) return null

    const moreItemsURL = () => {
        let params = ''
        if (user.actively_looking_first_job) {
            params += 'job_type=first_job&'
        }
        if (user.actively_looking_internship) {
            params += 'job_type=internship&'
        }
        if (user.actively_looking_student_job) {
            params += 'job_type=student_job'
        }
        if (params.endsWith('&')) {
            params = params.slice(0, -1)
        }

        return RouteMap.Index('company', params)
    }

    return (
        <RecommendedItemsContainer
            moreItemsURL={moreItemsURL()}
            user={user}
            type='company'
            titleId='personal_dashboard.recruiting_companies.title'
            subTitleId='personal_dashboard.recruiting_companies.sub_title'
            recommendedItemType='company'
        >
            {recruitingCompanies.map((recruitingCompany) => {
                return (
                    <CompanyCard
                        key={`company-card-${recruitingCompany.id}`}
                        company={recruitingCompany}
                    />
                )
            })}
        </RecommendedItemsContainer>
    )
}

export default RecruitingCompaniesForUser
