import React from 'react'

const LightningSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 70.25 86"
        {...props}
    >
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path d="M70.24,34.37c0-.16,0-.31,0-.49a8,8,0,0,0-8-7.86H50.88q1.45-4.86,2.92-9.71A8,8,0,0,0,46.14,6H18a8,8,0,0,0-7.95,7.11l-4,36A8,8,0,0,0,14,58H26.22L22.31,76.26a7.86,7.86,0,0,0,3.19,8.2l.16.11a8,8,0,0,0,7.47.88,8,8,0,0,0,3.76-3l3.43-4.07L55.13,60.83l1.94-2.3Q62.62,52,68.16,45.4l.15-.19a9,9,0,0,0,1.92-5c0-.14,0-.29,0-.43C70.25,38,70.25,36.14,70.24,34.37Z" />
                <path d="M64.24,28.37c0-.16,0-.31,0-.49a8,8,0,0,0-8-7.86H44.88q1.45-4.86,2.92-9.71A8,8,0,0,0,40.14,0H12A8,8,0,0,0,4.07,7.11l-4,36A8,8,0,0,0,8,52H20.22L16.31,70.26a7.86,7.86,0,0,0,3.19,8.2l.16.11a8,8,0,0,0,7.47.88,8,8,0,0,0,3.76-3l3.43-4.07L49.13,54.83l1.94-2.3Q56.62,46,62.16,39.4l.15-.19a9,9,0,0,0,1.92-5c0-.14,0-.29,0-.43C64.25,32,64.25,30.14,64.24,28.37Z" />
                <path
                    className="cls-1"
                    fill="white"
                    d="M24.13,71.94l.09.06s0-.1,0-.13l3.95-4.68,16.1-19L56,34.24a1,1,0,0,0,.2-.53q0-2.67,0-5.34c0-.11,0-.22,0-.35H34.12l6-20H12Q10,26,8,44H30.12Q27.12,58,24.13,71.94Z"
                />
            </g>
        </g>
    </svg>
)

export default LightningSVG
