import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'

import AdminDoc from './AdminDoc.md'
import { useStyles } from './AdminDocs_style'

const AdminDocs = (props) => {
    const classes = useStyles()
    const [documentation, setDocumentation] = useState('')

    useEffect(() => {
        fetch(AdminDoc)
            .then((result) => result.text())
            .then((text) => setDocumentation(text));
    }, [])

    return (
        <ReactMarkdown children={documentation} />
    )
}

export default AdminDocs
