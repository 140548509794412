/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useRef, useImperativeHandle } from 'react'
import { useStyles } from './AlertListCard_style'
import { FormattedMessage } from 'react-intl'
import SVGFactory from 'icons/SVGFactory/SVGFactory'
import CollectionHandler from 'assets/CollectionHandler'
import RouteMap from 'assets/RouteMap'
import URLParamsHandler from 'assets/URLParamsHandler'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import ItemIcon from 'components/ItemIcon/ItemIcon'
import IconFactory from 'icons/IconFactory/IconFactory'
import Paper from 'components/Paper/Paper'
import { Box, Typography, useTheme, Hidden, Tooltip, IconButton } from '@material-ui/core'

const AlertListCard = React.forwardRef((props, ref) => {
    const theme = useTheme()
    useImperativeHandle(ref, () => ({
        setCheck: () => {
            setCheck()
        }
    }))
    const classes = useStyles()
    const checkRef = useRef()
    const quickFilterCollection = CollectionHandler.Get('quickFilterCollection')

    const setCheck = (check) => {
        checkRef.current.setCheck(check)
    }

    const flattenLocations = (locations) => {
        return locations.map((option) => {
            return `${option.value},${option.lat},${option.long}`
        })
    }

    const getRedirectionUrl = () => {
        const urlFilters = {
            company_size: props.alert.company_sizes,
            location: flattenLocations(props.alert.locations),
            study_domain: props.alert.study_domains
        }
        const urlParams = URLParamsHandler.stringifyURLParams(urlFilters)

        return RouteMap.Show(
            props.alert.job_type,
            `?${urlParams}`
        ).slice(0, -1).replace('/?', '?')
    }

    const renderSearchParams = () => {
        let paramsFormat = ''

        if (props.alert.quick_filter) {
            paramsFormat += CollectionHandler.TranslateFromCollection(
                quickFilterCollection[props.alert.job_type.replace('_', '-') + 's'],
                props.alert.quick_filter
            ) + ' | '
        }
        props.alert.locations.map((location) => {
            paramsFormat += CollectionHandler.Translate(
                'cityCollection',
                location.value
            ) + ' | '
        })
        props.alert.study_domains.map((domain) => {
            paramsFormat += CollectionHandler.Translate(
                'studyDomainCollection',
                domain
            ) + ' | '
        })
        props.alert.company_sizes.map((size) => {
            paramsFormat += CollectionHandler.Translate(
                'companySizeCollection',
                size
            ) + ' | '
        })

        // Removes '|' char after the last array elem
        paramsFormat = paramsFormat.slice(0, -3)

        return (
            paramsFormat
        )
    }

    return (
        <Box className={`${classes.alertCardContainer} ${props.alert.job_type.replace('_', '-')}`}>
            {/*
            <Hidden mdDown>
                <Box className={classes.checkContainer}>
                    <CheckButton
                        onClick={() => props.onCheck(props.forwardRef, props.alert)}
                        ref={checkRef}
                    />
                </Box>
            </Hidden>
            */}
            <Paper className={classes.alertCard}>
                <Hidden smDown>
                    <Box className={`${classes.alertJobType} ${classes.alertCardColumn}`} >
                        <ItemIcon type={props.alert.job_type} width={30} height={30} />
                        <Typography
                            variant='h1'
                            component='span'
                            className={classes.jobType}
                        >
                            <FormattedMessage id={`type.${props.alert.job_type}.plural`} />
                        </Typography>
                    </Box>
                </Hidden>
                <Box className={classes.gridContainer}>
                    <Box className={classes.gridSubContainer}>
                        <Hidden mdUp>
                            <Box className={classes.mobileTypeContainer}>
                                <ItemIcon type={props.alert.job_type} width={14} height={14} />
                                <Typography
                                    variant='h1'
                                    component='span'
                                    className={classes.jobType}
                                >
                                    <FormattedMessage id={`type.${props.alert.job_type}.plural`} />
                                </Typography>
                            </Box>
                        </Hidden>
                        <Typography variant='body1' component='span'>
                            {renderSearchParams()}
                        </Typography>
                    </Box>
                    <RouteMapLink className={classes.matchingJobsButton} redirectionUrl={getRedirectionUrl()}>
                        <Typography
                            variant='h1'
                            component='span'
                            className={classes.jobType}
                        >
                            <FormattedMessage id='alert_card.tooltip.show_alert' />
                        </Typography>
                        <IconFactory
                            icon='circle-arrow-right'
                            color='black'
                        />
                    </RouteMapLink>
                    {/*
                        <Typography variant='body1' component='span'>
                            {
                                GenericHelper.formatDateObject(props.alert.created_at)
                            }
                        </Typography>
                    */}
                </Box>
                <Box className={classes.hoverActionContainer}>
                    <Tooltip title={<FormattedMessage id='alert_card.tooltip.delete_alert' />}>
                        <IconButton className={classes.iconButton} onClick={() => props.remove(props.alert)}>
                            <IconFactory
                                width='20px'
                                height='20px'
                                icon='delete'
                                color='black'
                            />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Paper>
        </Box>
    )
})

export default AlertListCard
