import React from 'react'
import { Grid } from '@material-ui/core'
import ArticleCardV2 from 'shared/cards/ArticleCard/ArticleCardV2'

const ArticlesList = (props) => {
    const { articles } = props

    return (
        <Grid container spacing={2}>
            {articles.map((article, index) => {
                return (
                    <Grid key={`article-card-${article.id}`} item lg={3} md={4} sm={6} xs={12}>
                        <ArticleCardV2 article={article} key={index} />
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default ArticlesList
