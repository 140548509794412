import React, { useState, useEffect } from 'react'

import { useStyles } from './ArticleListCompactCard_style'
import { Box, Chip, Typography, useTheme } from '@material-ui/core'

import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import CollectionHandler from 'assets/CollectionHandler.js'
import DateHandler from 'assets/DateHandler'
import IconFactory from 'shared/icons/IconFactory/IconFactory'

const ArticleListCompactCard = (props) => {
    const { article } = props
    const classes = useStyles()
    const [category, setCategory] = useState()
    const theme = useTheme()

    useEffect(() => {
        let categoryString = CollectionHandler.Translate('articleCategoryCollection', article.category)
        if (categoryString === '/') {
            categoryString = CollectionHandler.Translate('articleCategoryCollection', 'student_life')
        }
        setCategory(categoryString)
    }, [])

    return (
        <RouteMapLink showItemType='article' showItemUrl={article.url} style={{ width: '100%' }}>
            <Box className={classes.main}>
                <Typography variant='h3' component='div' className={classes.articleTitle}>
                    {article.title}
                </Typography>
                <Box className={classes.articleInfo}>
                    <Chip
                        classes={{ root: classes.chipRoot, label: classes.chipLabel }}
                        label={category}
                        name={category}
                    />
                    <Box className={classes.readTimeAndDate}>
                        <Box className={classes.infoContainer}>
                            <IconFactory icon='calendar' fontSize='12px' color={theme.palette.fg['greySlate']} />
                            <Typography component='span' className={classes.minorText}>
                                {`${DateHandler.toReadingFormat(article.date)}`}
                            </Typography>
                        </Box>
                        <Box className={classes.infoContainer}>
                            <IconFactory icon='clock' fontSize='12px' color={theme.palette.fg['greySlate']} />
                            <Typography component='span' className={classes.minorText}>
                                {`${article.read_time} min`}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </RouteMapLink>
    )
}

export default ArticleListCompactCard
