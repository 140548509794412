/* eslint-disable react/prop-types */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Tooltip from '@material-ui/core/Tooltip'
import { Box, IconButton, Typography, Hidden } from '@material-ui/core'
import IconFactory from 'icons/IconFactory/IconFactory'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import Paper from 'components/Paper/Paper'
import ItemIcon from 'components/ItemIcon/ItemIcon'
import GenericHelper from 'assets/GenericHelper'
import RouteMap from 'assets/RouteMap'
import { useStyles } from './candidacycard_style'

const CandidacyCard = (props) => {
    const { candidacy } = props
    const classes = useStyles()

    return (
        <Box className={classes.cardContainer}>
            <Paper elevation={1} className={classes.paper}>
                <Hidden smDown>
                    <Box className={classes.jobType}>
                        <ItemIcon type={candidacy.type} width={30} height={30} />
                        <Typography variant='h1' component='span' className={classes.jobTypeText}>
                            <FormattedMessage id={`type.${candidacy.type}.singular`} />
                        </Typography>
                    </Box>
                </Hidden>
                <Box className={classes.candidacyInfo}>
                    <Hidden mdUp>
                        <Box className={classes.mobileTypeContainer}>
                            <ItemIcon type={candidacy.type} width={14} height={14} />
                            <Typography variant='h1' component='span' className={classes.jobTypeText}>
                                <FormattedMessage id={`type.${candidacy.type}.singular`} />
                            </Typography>
                        </Box>
                    </Hidden>
                    <Box className={classes.descriptionBox}>
                        <Typography variant='h3' component='span'>
                            {candidacy.company_name}
                        </Typography>
                        <Typography component='span'>
                            {candidacy.title}
                        </Typography>
                    </Box>
                    <Hidden smDown>
                        <Box className={classes.dateBox}>
                            <Typography component='span'>
                                {GenericHelper.formatDateObject(candidacy.created_at)}
                            </Typography>
                        </Box>
                    </Hidden>
                    <Hidden mdUp>
                        <Typography component='span'>
                            <FormattedMessage id='candidacy_card.candidacy_date' />
                            {' ' + GenericHelper.formatDateObject(candidacy.created_at)}
                        </Typography>
                    </Hidden>
                </Box>
                <Box className={classes.actionBox}>
                    <Hidden mdUp>
                        <Box className={classes.actionBox}>
                            {!candidacy.job_active && (
                                <RouteMapLink className={classes.recruitmentBox} showItemType={candidacy.type} showItemUrl={candidacy.url}>
                                    <Typography component='span' className={classes.recruitmentClosed}>
                                        <FormattedMessage id='transl_internship.completed' />
                                    </Typography>
                                    <IconFactory
                                        icon='cross-solid-circle'
                                        color='var(--background-orange)'
                                        fontSize='14px'
                                    />
                                </RouteMapLink>
                            )}
                            {candidacy.job_active && (
                                <Box className={classes.recruitmentBox}>
                                    <Typography component='span' className={classes.recruitmentOpen}>
                                        <FormattedMessage id='transl_internship.open' />
                                    </Typography>
                                    <IconFactory
                                        icon='circle-arrow-right'
                                        color='black'
                                        fontSize='14px'
                                    />
                                </Box>
                            )}
                        </Box>
                    </Hidden>
                    <Box className={classes.iconBox}>
                        <RouteMapLink showItemType={candidacy.type} showItemUrl={candidacy.url}>
                            <IconFactory
                                tooltip={<FormattedMessage id='candidacy_card.check_ads' />}
                                icon='view'
                                color='black'
                                iconButtonClassName={classes.iconButtonStyle}
                            />
                        </RouteMapLink>
                        {candidacy.cv_file && (
                            <RouteMapLink target='_blank' redirectionUrl={candidacy.cv_file.asset_url}>
                                <Tooltip title={<FormattedMessage id='candidacy_card.check_cv' />}>
                                    <IconButton
                                        aria-label='CV'
                                        color='primary'
                                        className={classes.iconButtonStyle}
                                        padding='4px'
                                    >
                                        <Typography variant='body2' color='black'>
                                            CV
                                        </Typography>
                                    </IconButton>
                                </Tooltip>
                            </RouteMapLink>
                        )}
                        {candidacy.conversation_id && (
                            <RouteMapLink redirectionUrl={RouteMap.Page(candidacy.chat_url)}>
                                <IconFactory
                                    tooltip={<FormattedMessage id='candidacy_card.check_message' />}
                                    icon='chat'
                                    color='black'
                                    iconButtonClassName={classes.iconButtonStyle}
                                />
                            </RouteMapLink>
                        )}
                    </Box>
                </Box>
            </Paper>
            <Hidden smDown>
                {!candidacy.job_active && (
                    <Box className={classes.recruitmentBox}>
                        <IconFactory
                            icon='cross-solid-circle'
                            color='var(--background-orange)'
                            fontSize='24px'
                        />
                        <Typography component='span' className={classes.recruitmentClosed}>
                            <FormattedMessage id='transl_internship.completed' />
                        </Typography>
                    </Box>
                )}
                {candidacy.job_active && (
                    <RouteMapLink className={classes.recruitmentBox} showItemType={candidacy.type} showItemUrl={candidacy.url}>
                        <IconFactory
                            icon='circle-arrow-right'
                            color='black'
                            fontSize='24px'
                        />
                        <Typography component='span' className={classes.recruitmentOpen}>
                            <FormattedMessage id='transl_internship.open' />
                        </Typography>
                    </RouteMapLink>
                )}
            </Hidden>
        </Box>
    )
}

export default CandidacyCard
