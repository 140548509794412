import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    main: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            justifyContent: 'center'
        }
    },

    text: {
        color: theme.palette.fg['white'],
        fontFamily: 'GreedStandard-Medium',
        fontSize: '15px',
        lineHeight: '18px'
    },

    fieldContainer: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '183px'
        }
    }
}))
