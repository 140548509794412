import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useStyles } from '../KotShowBanner_style'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconFactory from 'icons/IconFactory/IconFactory'
import DateHandler from 'assets/DateHandler'

const KotShowBannerDesktop = (props) => {
    const { kot, unavailable, renderApplyBox, user } = props
    const classes = useStyles()

    return (
        <Box className={classes.bannerContainer}>
            <Box className={classes.infoBannerContainer}>
                <Box className={classes.kotInfoContainer}>
                    <Typography variant='h1' className={classes.kotTitleBox}>
                        {kot.title}
                    </Typography>
                    <Box className={classes.kotLocalisationContainer}>
                        <Box className={classes.mapIcon}>
                            <IconFactory icon='marker' />
                        </Box>
                        <Typography className={classes.address}>
                            {kot.city.toLowerCase()} {kot.zip}, {kot.street.toLowerCase()}
                        </Typography>
                    </Box>
                    <Typography component='p' className={classes.kotDate}>
                        <IconFactory icon='date' fontSize='20px'/>
                        <time dateTime={DateHandler.ToBasicFormat(kot.updated_at)}>
                            {DateHandler.toReadingFormat(kot.updated_at)}
                        </time>
                    </Typography>
                </Box>
            </Box>
            <Box className={classes.kotPriceContainer}>
                <Typography variant='h6' component='p' className={classes.kotPrice}>
                    {kot.charges === 0
                        ? <>€{kot.rent}</>
                        : <>€{kot.rent} (+{kot.charges})</>
                    }
                </Typography>
            </Box>
            {!unavailable &&
                <Box className={classes.applyBox}>
                    {renderApplyBox()}
                    {user && user.type === 'employer' && (
                        <Typography className={classes.textLight}>
                            <FormattedMessage id='landing_card_kot_page.employer_cannot_contact' />
                        </Typography>
                    )}
                </Box>
            }
        </Box>
    )
}

export default KotShowBannerDesktop
