import React from 'react'

const ToolboxSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86 83.13" {...props}>
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path
                    d="M86,26.21a8,8,0,0,0-8-8H70V14a8,8,0,0,0-8-8H30a8,8,0,0,0-8,8v4.22H14a8,8,0,0,0-8,8V46.55a8,8,0,0,0,.27,2.09A8.2,8.2,0,0,0,6,50.75V75.13a8,8,0,0,0,8,8H78a8,8,0,0,0,8-8V50.77a8.14,8.14,0,0,0-.28-2.11A8.14,8.14,0,0,0,86,46.55Z" />
                <path
                    d="M80,20.21a8,8,0,0,0-8-8H64V8a8,8,0,0,0-8-8H24a8,8,0,0,0-8,8v4.22H8a8,8,0,0,0-8,8V40.55a8,8,0,0,0,.28,2.09A8.2,8.2,0,0,0,0,44.75V69.13a8,8,0,0,0,8,8H72a8,8,0,0,0,8-8V44.77a8.14,8.14,0,0,0-.28-2.11A8.14,8.14,0,0,0,80,40.55Z" />
                <path className="cls-1"
                    fill="white"
                    d="M72,20.21H56V8H24V20.22H8V40.55H72Zm0,24.56H48v8.08H32v-8.1H8V69.13H72Z" />
            </g>
        </g>
    </svg>
)

export default ToolboxSVG
