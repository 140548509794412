import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './CompanyImpact_style'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import NewCarouselCards from 'shared/components/NewCarouselCards/NewCarouselCards'
import { Container } from '@material-ui/core'
import HorizontalCardsSlider from '../../../../shared/components/HorizontalCardsSlider/HorizontalCardsSlider'
import CompanyCard from 'cards/CompanyCard/CompanyCard'


const CompanyImpact = (props) => {
    const { lastCompanies } = props
    const classes = useStyles()

    return (
        lastCompanies.length > 0 &&
            <Box className={classes.companyImpactContainer}>
                <Box className={classes.companyImpactText}>
                    <Typography component="h2" className={classes.companyImpactTitle}>
                        <FormattedMessage id="landing_student.company_impact.title" />
                    </Typography>
                    <Typography
                        component='p'
                        color='primary'
                        className={classes.CompanyImpactDescription}
                    >
                        <FormattedMessage id="landing_student.company_impact.description" />
                    </Typography>
                </Box>
                <Container disableGutters maxWidth='lg' className={classes.sliderContainer}>
                    <HorizontalCardsSlider home shift={500}>
                        {lastCompanies.map((comp, index) => {
                            return (
                                <Box key={`company-card-${index}`} className={classes.companyCardBox}>
                                    <CompanyCard
                                        company={comp}
                                    />
                                </Box>
                            )
                        })}
                    </HorizontalCardsSlider>
                </Container>
            </Box>
    )
}
export default CompanyImpact
