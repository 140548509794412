import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './MobileJobWallet_style'
import { Box } from '@material-ui/core'

import MobileWalletCard from '../shared/MobileWalletCard/MobileWalletCard'
import NavigationAPI from 'api/NavigationAPI'
import RouteMap from 'assets/RouteMap'

const MobileJobWallet = (props) => {
    const { jobTypeSelection } = props
    const classes = useStyles()
    const [filters, setFilters] = useState(false)

    useEffect(() => {
        NavigationAPI.GetNavigationCollection({
            locale: RouteMap.GetLocaleFromUrl(),
            type: jobTypeSelection?.tag
        }).then((response) => {
            setFilters(response.data.filters)
        })
    }, [])

    const renderWalletCards = () => {
        return filters.map((filter, index) =>
            <MobileWalletCard
                key={`navigation-wallet-card-${index}`}
                page={jobTypeSelection?.tag}
                filter={filter}
                name={filter.name}
                jobFilterCard
            />
        )
    }

    return (<Box className={classes.cardsContainer}>
        <MobileWalletCard
            secondary
            index
            jobFilterCard
            name={<FormattedMessage id={`page_footer_navbar_data.see_all.${jobTypeSelection?.tag?.replace('-', '_')}`} />}
            page={jobTypeSelection?.tag}
        />
        {filters && renderWalletCards()}
    </Box>)
}

export default MobileJobWallet
