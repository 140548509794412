import React from 'react'

import { useStyles } from './PromotionCard_style'
import { Box, Typography } from '@material-ui/core'
import { Image, Transformation } from 'cloudinary-react'
import FilledButton from '../../buttons/FilledButton/FilledButton'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const PromotionCard = ({ promotion }) => {
    const classes = useStyles()

    return (
        <RouteMapLink showItemType='promotion' showItemUrl={promotion.url} >
            <Box className={classes.promotionCardContainer}>
                <Image
                    className={classes.promotionCardImage}
                    cloudName="studentbe"
                    publicId={promotion.image_cloudinary_key}
                    alt={promotion.title ? promotion.title : promotion.image_cloudinary_key}
                >
                    <Transformation flags="progressive" fetchFormat="auto" width="260" height="350" quality="auto:good" crop="fill" />
                </Image>
                <Box className={classes.dividerContainer}>
                    <Box className={classes.leftCircle}/>
                    <Box className={classes.rightCircle}/>
                </Box>
                <Box className={classes.promotionCardTitleContainer}>
                    <Typography className={classes.promotionTitleMain}>
                        {promotion.title_card}
                    </Typography>
                </Box>
                <FilledButton
                    name={promotion.promo}
                    className={classes.promotionCardButton}
                    variant='secondary'
                />
            </Box>
        </RouteMapLink>
    )
}

export default PromotionCard
