import React, { useState, useEffect, useContext } from 'react'
import DashboardAPI from 'api/DashboardAPI'
import RecommendedItemsContainer from 'components/RecommendedItemsContainer/RecommendedItemsContainer'
import RouteMap from 'assets/RouteMap'
import { UserContext } from 'contexts/UserContext'
import ArticleListCompactCard from 'cards/ArticleListCard/ArticleListCompactCard'

const RecommendedArticlesForUser = (props) => {
    const { user, statusConfig } = useContext(UserContext)
    const [recommendedArticles, setRecommendedArticles] = useState([])

    useEffect(() => {
        getRecommendedArticles()
    }, [statusConfig])

    const categoriesBasesOnStatusConfig = () => {
        const categories = []
        if (statusConfig.actively_looking_first_job) categories.push('first_job')
        if (statusConfig.actively_looking_student_job) categories.push('student_job')
        if (statusConfig.actively_looking_internship) categories.push('internship')
        if (statusConfig.actively_looking_kot) categories.push('kot')

        return categories
    }

    const getRecommendedArticles = () => {
        const data = {
            user_id: user.id,
            categories: categoriesBasesOnStatusConfig(),
            amount: 6
        }

        DashboardAPI.GetRecommendedArticles(data).then((response) => {
            setRecommendedArticles(response.data.recommended_articles)
        })
    }

    if (recommendedArticles.length === 0) return null

    const moreItemsURL = () => {
        let params = ''
        return RouteMap.Index('article', params)
    }

    return (
        <RecommendedItemsContainer
            moreItemsURL={moreItemsURL()}
            user={user}
            type='article'
            titleId='personal_dashboard.recommend_articles.title'
            subTitleId='personal_dashboard.recommend_articles.sub_title'
            recommendedItemType='article'
            key='articles-item-container'
        >
            {recommendedArticles.map((recommendedArticle) => {
                return (
                    <ArticleListCompactCard
                        key={`article-card-${recommendedArticle.id}`}
                        article={recommendedArticle}
                    />

                )
            })}
        </RecommendedItemsContainer>
    )
}
export default RecommendedArticlesForUser
