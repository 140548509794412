import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Image } from 'cloudinary-react'

import { useStyles } from './CTAJobAlert_style'
import { Box, Typography, Hidden } from '@material-ui/core'

import Button from 'shared/components/Button/Button.jsx'

const CTAJobAlert = (props) => {
    const { handleReceiveJobs } = props
    const classes = useStyles()

    return (
        <Box className={classes.container}>
            <Box className={classes.containerLeft}>
                <Box className={classes.alertTextContainer}>
                    <Typography
                        variant='h3'
                        component='p'
                        className={classes.alertText}
                    >
                        <FormattedMessage id='item_index.alert.student_job.title' />
                    </Typography>
                </Box>
                <Button
                    variant='cta-primary'
                    size="small"
                    className={classes.alertButtonStyle}
                    onClick={handleReceiveJobs}
                >
                    <FormattedMessage id='item_offers.save_my_search' />
                </Button>
            </Box>
            <Hidden smDown>
                <Box className={classes.containerRight}>
                    <Image
                        className={classes.image}
                        cloudName='studentbe'
                        publicId='student-be-assets/lady-yellow-banner.png'
                        alt='Student.be logo'
                    />
                </Box>
            </Hidden>
        </Box>
    )
}

export default CTAJobAlert
