import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    companyImagesGallery: {
        '& .react-photo-gallery--gallery > div': {
            justifyContent: 'center',
            gap: 4
        }
    },
    imageRender: {
        display: 'block',
        width: '100%',
        height: 'auto',
        borderRadius: 20
    },
    videoIcon: {
        position: 'absolute',
        top: 20,
        right: 20,
        width: 24
    },
    slideTooltip: {
        background: 'var(--background-purple)',
        color: 'white',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translate(-50%, 0%)',
        minWidth: 250,
        textAlign: 'center',
        padding: 5,
        borderRadius: 20
    },
    vidoContainer: {
        width: '70%',
        height: 'auto',
        display: 'flex',
        justifyContent: 'center'
    }
}))
