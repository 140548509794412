import React from 'react'

import CandidateForm from '../CandidateForm/CandidateForm'
import FirstJobForm from '../FirstJobForm/FirstJobForm'
import JobCompanyForm from '../JobCompanyForm/JobCompanyForm'
import OfferForm from '../OfferForm/OfferForm'
import Payment from '../Payment/Payment'
import SettingsForm from '../SettingsForm/SettingsForm'

import {
    COMPANY_STEP,
    JOB_STEP,
    CANDIDATE_STEP,
    OFFER_STEP,
    ESSENTIAL_STEP,
    EXTRA_STEP,
    SETTINGS_STEP,
    PUBLICATION_STEP
} from '../../../settings'
import StudentJobEssentialForm from '../StudentJobEssentialForm/StudentJobEssentialForm'
import StudentJobExtraForm from '../StudentJobExtraForm/StudentJobExtraForm'

const JobFormFactory = (props) => {
    const {
        currentStep,
        company,
        jobForm,
        errors,
        handleChange,
        handleUpdate,
        handleAttributeChange,
        updateMainImage,
        updateCompanyLogo,
        updateIsBuyingCredits,
        isAiGenerated,
        companyDescriptionWithAi
    } = props

    return (<>
        {currentStep === COMPANY_STEP &&
            <JobCompanyForm
                jobForm={jobForm}
                errors={errors}
                handleChange={handleChange}
                updateCompanyLogo={updateCompanyLogo}
                handleAttributeChange={handleAttributeChange}
                isAiGenerated={isAiGenerated}
                companyDescriptionWithAi={companyDescriptionWithAi}
            />
        }
        {currentStep === JOB_STEP &&
            <FirstJobForm
                jobForm={jobForm}
                errors={errors}
                handleChange={handleChange}
                handleUpdate={handleUpdate}
                handleAttributeChange={handleAttributeChange}
                updateMainImage={updateMainImage}
                isAiGenerated={isAiGenerated}
            />
        }
        {currentStep === CANDIDATE_STEP &&
            <CandidateForm
                jobForm={jobForm}
                errors={errors}
                handleUpdate={handleUpdate}
                handleAttributeChange={handleAttributeChange}
                isAiGenerated={isAiGenerated}
            />
        }
        {currentStep === OFFER_STEP &&
            <OfferForm
                jobForm={jobForm}
                errors={errors}
                handleChange={handleChange}
                handleAttributeChange={handleAttributeChange}
                isAiGenerated={isAiGenerated}
            />
        }
        {currentStep === ESSENTIAL_STEP &&
            <StudentJobEssentialForm
                jobForm={jobForm}
                errors={errors}
                handleChange={handleChange}
                handleUpdate={handleUpdate}
                handleAttributeChange={handleAttributeChange}
                updateMainImage={updateMainImage}
                updateCompanyLogo={updateCompanyLogo}
                isAiGenerated={isAiGenerated}
            />
        }
        {currentStep === EXTRA_STEP &&
            <StudentJobExtraForm
                jobForm={jobForm}
                errors={errors}
                handleChange={handleChange}
                handleUpdate={handleUpdate}
                handleAttributeChange={handleAttributeChange}
                isAiGenerated={isAiGenerated}
                companyDescriptionWithAi={companyDescriptionWithAi}
            />
        }
        {currentStep === SETTINGS_STEP &&
            <SettingsForm
                jobForm={jobForm}
                errors={errors}
                handleChange={handleChange}
                handleAttributeChange={handleAttributeChange}
                isAiGenerated={isAiGenerated}
            />
        }
        {currentStep === PUBLICATION_STEP &&
            <Payment
                user={jobForm.user}
                jobInfos={jobForm}
                company={company}
                jobType={jobForm.jobType}
                mod={jobForm.funnelType}
                credits={jobForm.user.number_of_credits}
                updateIsBuyingCredits={updateIsBuyingCredits}
            />
        }
    </>)
}

export default JobFormFactory
