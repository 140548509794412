import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './CompanySizeSelect_style'
import { MenuItem, FormControl, FormHelperText, Select, Input, InputLabel, useTheme } from '@material-ui/core'

import IconFactory from 'icons/IconFactory/IconFactory'

const CompanySizeSelect = (props) => {
    const { name, handleChange, error, options } = props
    const [open, setOpen] = useState(false)
    const theme = useTheme()
    const classes = useStyles()

    const createMenuItems = () => {
        const tempOptionSelect = options
        const listOptionSelect = tempOptionSelect.map((selectOption, index) => {
            const value = selectOption.value
            let name = selectOption.name
            if (index == 0) {
                return (
                    <MenuItem
                        value={tempOptionSelect[index].value}
                    >
                        {tempOptionSelect[index].name}
                    </MenuItem>
                )
            }
            return (
                <MenuItem
                    key={`select-option-${index}`}
                    value={value}
                >
                    {name}
                </MenuItem>
            )
        })
        return listOptionSelect
    }

    return (
        <FormControl onClick={() => setOpen(!open)}>
            <InputLabel htmlFor="company-size" className={classes.inputLabel}><FormattedMessage id="landing_login_page.sign_up.compagny_size_v2" /></InputLabel>
            <Select
                id="company-size"
                open={open}
                name={name}
                onChange={handleChange}
                input={<Input classes={{ input: classes.input, underline: classes.inputUnderline }} />}
                inputProps={{ MenuProps: { disableScrollLock: true } }}
                IconComponent={() => open ?
                    <IconFactory icon='up-solid' fontSize='12px' color={theme.palette.fg['black']} className={classes.chevronIcon} /> :
                    <IconFactory icon='down-solid' fontSize='12px' color={theme.palette.fg['black']} className={classes.chevronIcon} />}
            >
                {createMenuItems()}
            </Select>
            <FormHelperText className={classes.errorText}>{error}</FormHelperText>
        </FormControl>
    )

}

export default CompanySizeSelect
