/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react'

import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

// severity options = 'success' | 'info' | 'warning' | 'error';

const FeedbackSnackbar = (props) => {
    const {
        open,
        message,
        closeFeedback,
        severity = 'success',
        anchor = { vertical: 'bottom', horizontal: 'left' },
        autoHideDuration = 6000
    } = props
    const handleClose = (_, reason) => {
        if (reason !== 'clickaway') closeFeedback()
    }

    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={handleClose}
            anchorOrigin={anchor}
        >
            <MuiAlert
                elevation={6}
                variant="filled"
                onClose={handleClose}
                severity={severity}
            >
                {message}
            </MuiAlert>
        </Snackbar>
    )
}

export default FeedbackSnackbar
/* USAGE

    const [feedback, setFeedback] = useState({open: false, severity: 'success', message: null })
    const closeFeedback = () => setFeedback({ ...feedback, open: false })

    const handleClick = () => {
        ...
        setFeedback({
            open: true,
            severity: 'success',
            message: <FormattedMessage id="company_profile.edit_profile.success" />
        })
    }

    ===================================

    import FeedbackSnackbar from 'components/FeedbackSnackbar/FeedbackSnackbar'

    <FeedbackSnackbar
        open={feedback.open}
        message={feedback.message}
        severity={feedback.severity} # optional because by default it's success
        closeFeedback={closeFeedback}
    />
*/
