import React from 'react'

const RollingEyesSVG = (props) => (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 538.41 547.55">
        <circle cx="282.41" cy="291.55" r="256"/>
        <circle cx="256.25" cy="256.25" r="256" fill="#fff"/>
        <path d="M256.25,48.25c114.88,0,208,93.12,208,208s-93.12,208-208,208S48.25,371.13,48.25,256.25,141.37,48.25,256.25,48.25ZM256.25,512.25c141.38,0,256-114.62,256-256S397.63.25,256.25.25.25,114.87.25,256.25s114.62,256,256,256ZM168.25,376.25c0,13.3,10.7,24,24,24h128c13.3,0,24-10.7,24-24s-10.7-24-24-24h-128c-13.3,0-24,10.7-24,24ZM160.25,272.25c-26.5,0-48-21.5-48-48,0-14.3,6.3-27.2,16.2-36-.2,1.3-.2,2.6-.2,4,0,17.7,14.3,32,32,32s32-14.3,32-32c0-1.4-.1-2.7-.2-4,10,8.8,16.2,21.7,16.2,36,0,26.5-21.5,48-48,48ZM160.25,304.25c44.18,0,80-35.82,80-80s-35.82-80-80-80-80,35.82-80,80,35.82,80,80,80ZM352.25,272.25c-26.5,0-48-21.5-48-48,0-14.3,6.3-27.2,16.2-36-.2,1.3-.2,2.6-.2,4,0,17.7,14.3,32,32,32s32-14.3,32-32c0-1.4-.1-2.7-.2-4,10,8.8,16.2,21.7,16.2,36,0,26.5-21.5,48-48,48ZM352.25,304.25c44.18,0,80-35.82,80-80s-35.82-80-80-80-80,35.82-80,80,35.82,80,80,80Z" stroke="#000" strokeMiterlimit="10" strokeWidth=".5"/>
    </svg>
)

export default RollingEyesSVG
