// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 900px
// lg, large: 1200px
// xl, extra-large: 1536px

export const BREAKPOINT_SMALL = 600
export const BREAKPOINT_MEDIUM = 900
export const BREAKPOINT_LARGE = 1200
export const BREAKPOINT_XLARGE = 1536

export const isScreenSizeMobile = () => {
    return window.innerWidth < BREAKPOINT_SMALL
}
