import React from 'react'

const RestaurantSVG = (props) => (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
        <path d="M423.47,97.61c-7.19-7.19-17.13-11.23-27.3-11.23h-1.96c-.2.01-.4.02-.6.02-10.7.36-19.93,2.79-27.57,5.12-10.22,3.13-23.64,7.74-36.76,15.75-11.81,7.2-22.72,16.26-33.32,27.7-6.46,6.96-12.02,14.76-16.62,23.31-.43-2.11-.85-4.23-1.28-6.34l-6.98-34.69c-3.61-17.89-19.33-30.75-37.58-30.75h-19.11c-3.32,0-6.55.42-9.63,1.22-3.09-.8-6.32-1.23-9.65-1.23h-19.09c-3.3,0-6.51.42-9.58,1.21-3.11-.82-6.38-1.25-9.75-1.25h-19.01c-18,0-33.57,12.49-37.44,30.07-.18.76-.35,1.51-.51,2.29l-17.29,86.28c-1.29,6.37-3.23,16-1.51,27.53,2.31,15.54,7.55,29.42,15.59,41.27,8.62,12.7,19.24,22.66,31.63,29.7v127.87c0,21.18,17.17,38.34,38.34,38.34h38.24c21.17,0,38.34-17.16,38.34-38.34v-2.31c0-41.86,0-83.72-.02-125.57,1.64-.97,3.26-1.99,4.85-3.06,5.3-3.58,10.16-7.54,14.53-11.84,0,5.13,0,10.25,0,15.38l.02,31.63c0,21.18,17.17,38.34,38.34,38.34h19.26v57.42c0,21.18,17.17,38.34,38.34,38.34h38.15c21.17,0,38.34-17.16,38.34-38.34V124.72c0-10.17-4.23-19.92-11.42-27.11Z" fill="#000" strokeWidth="0px" />
        <g>
            <path d="M394.72,68.85c-7.19-7.19-17.13-11.23-27.3-11.23h-1.96c-.2.01-.4.02-.6.02-10.7.36-19.93,2.79-27.57,5.12-10.22,3.13-23.64,7.74-36.76,15.75-11.81,7.2-22.72,16.26-33.32,27.7-6.46,6.96-12.02,14.76-16.62,23.31-.43-2.11-.85-4.23-1.28-6.34l-6.98-34.69c-3.61-17.89-19.33-30.75-37.58-30.75h-19.11c-3.32,0-6.55.42-9.63,1.22-3.09-.8-6.32-1.23-9.65-1.23h-19.09c-3.3,0-6.51.42-9.58,1.21-3.11-.82-6.38-1.25-9.75-1.25h-19.01c-18,0-33.57,12.49-37.44,30.07-.18.76-.35,1.51-.51,2.29l-17.29,86.28c-1.29,6.37-3.23,16-1.51,27.53,2.31,15.54,7.55,29.42,15.59,41.27,8.62,12.7,19.24,22.66,31.63,29.7v127.87c0,21.18,17.17,38.34,38.34,38.34h38.24c21.17,0,38.34-17.16,38.34-38.34v-2.31c0-41.86,0-83.72-.02-125.57,1.64-.97,3.26-1.99,4.85-3.06,5.3-3.58,10.16-7.54,14.53-11.84,0,5.13,0,10.25,0,15.38l.02,31.63c0,21.18,17.17,38.34,38.34,38.34h19.26v57.42c0,21.18,17.17,38.34,38.34,38.34h38.15c21.17,0,38.34-17.16,38.34-38.34V95.96c0-10.17-4.23-19.92-11.42-27.11Z" fill="#000" strokeWidth="0px" />
            <path d="M367.8,95.96c-.61,0-1.13,0-1.65,0-6.07.21-11.89,1.71-17.66,3.47-9.75,2.98-19.28,6.49-28.03,11.83-9.45,5.76-17.67,12.95-25.18,21.04-6.92,7.45-11.96,16.02-15.53,25.48-5.4,14.32-7.61,29.25-7.68,44.46-.15,34.25-.04,68.51-.04,102.76v1.92h57.6v95.76h38.15V95.96h0ZM137.73,249.32v153.36h38.24v-2.31c0-49.92,0-99.84-.03-149.76,0-1.41.35-2.01,1.82-2.32,7.17-1.5,13.88-4.24,19.93-8.32,13.78-9.3,22.38-22.13,25.2-38.58.64-3.68,1.27-7.64.58-11.22-4.95-25.93-10.24-51.79-15.43-77.67-1.09-5.46-2.2-10.94-3.31-16.43h-19.12v95.71h-9.69c-3.2-31.96-6.39-63.83-9.59-95.72h-19.09c-3.2,31.95-6.39,63.83-9.59,95.72h-9.75v-95.76h-19c-.12.53-.25,1.04-.36,1.55-5.71,28.5-11.42,56.99-17.13,85.49-1,5-2.11,9.96-1.34,15.15,1.36,9.13,4.18,17.69,9.4,25.38,5.86,8.64,13.29,15.57,22.83,19.92,4.86,2.22,10.02,3.81,15.42,5.82h0Z" fill="#fff" strokeWidth="0px" />
        </g>
    </svg>

)

export default RestaurantSVG
