import React from "react"
import { Grid, Divider as MuiDivider } from "@material-ui/core"

const Divider = ({ children, textAlign, ...props }) => (
  <Grid container alignItems="center" spacing={2} {...props}>
    <Grid item xs={textAlign === 'left' ? 1 : true}>
      <MuiDivider />
    </Grid>
    <Grid item>{children}</Grid>
    <Grid item xs={textAlign === 'right' ? 1 : true}>
      <MuiDivider />
    </Grid>
  </Grid>
)

export default Divider
