import React, { useRef } from 'react'

import { useStyles } from './HorizontalCardsSlider_style'
import { Box, IconButton, Typography, Hidden } from '@material-ui/core'
import IconFactory from '../../icons/IconFactory/IconFactory'

/* ===== COMPONENT USAGE =====

    1°) Import Component
    2°) Define following variables
        - shift: The jump space the button will have on the card slider
        - title: The title display on the top of the slider
    3°) Map and render your cards as children of the component

    === Example 1 ===
    <HorizontalCardsSlider
        shift={500}
        title='🤑 Student Deals'
    >
        {promotions.map((promo, index) => {
            return (
                <Box style={{ width: 197 }} key={`promotion_${index}`}>
                    <PromotionCard promotion={promo}/>
                </Box>
            )
        })}
    </HorizontalCardsSlider>

    === Example 2 ===
    <HorizontalCardsSlider
        shift={500}
        title='😶‍🌫️ Our Best Article'
    >
        {articles.map((article, index) => {
            return (
                <ArticleCard article={article} key={`card_${index}`}/>
            )
        })}
    </HorizontalCardsSlider>

*/

const HorizontalCardsSlider = (props) => {
    const classes = useStyles()
    const { children, shift, title, home } = props
    const sliderRef = useRef()

    const slide = (slide) => {
        sliderRef.current.scrollLeft += slide
    }

    return (
        <Box>
            {title && <Box className={classes.titleContainer}>
                <Typography
                    className={classes.title}
                    variant='h1'
                    component='h2'
                >
                    {title}
                </Typography>
                <Hidden mdDown>
                    <Box className={classes.buttonsContainer}>
                        <IconButton
                            onClick={() => slide(-shift)}
                            className={classes.button}
                        >
                            <IconFactory className={classes.icon} icon='left-regular' />
                        </IconButton>
                        <IconButton
                            onClick={() => slide(shift)}
                            className={`${classes.button} ${classes.buttonActive}`}
                        >
                            <IconFactory className={`${classes.icon} ${classes.iconActive}`} icon='right-regular' />
                        </IconButton>
                    </Box>
                </Hidden>
            </Box>}

            {home &&
                <Hidden smDown>
                    <Box className={classes.buttonHomePosition}>
                        <Box className={classes.buttonsHomeContainer}>
                            <IconButton
                                onClick={() => slide(-shift)}
                                className={`${classes.button} ${classes.buttonActive}`}
                            >
                                <IconFactory className={`${classes.icon} ${classes.iconActive}`} icon='left-regular' />
                            </IconButton>
                            <IconButton
                                onClick={() => slide(shift)}
                                className={`${classes.button} ${classes.buttonActive}`}
                            >
                                <IconFactory className={`${classes.icon} ${classes.iconActive}`} icon='right-regular' />
                            </IconButton>
                        </Box>
                    </Box>
                </Hidden>
            }

            <Box className={`${classes.cardsContainer} ${home && classes.cardContainerHome}`} ref={sliderRef}>
                {children}
            </Box>
        </Box>
    )
}

export default HorizontalCardsSlider
