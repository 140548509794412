import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    formControl: {
        cursor: 'pointer',
        '& .MuiInput-formControl': {
            '& .MuiSelect-selectMenu': {
                display: 'flex',
                alignItems: 'center',
                gap: 8
            }
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    select: {
        cursor: 'pointer',
        backgroundColor: 'white',
        padding: '14px 24px !important',
        fontSize: 16,
        height: 51,
        minWidth: 230,
        borderRadius: 80,
        border: 'solid black 2px',
        boxSizing: 'border-box',
        '&:hover': {
            boxShadow: `4px 4px 0px 0px ${theme.palette.bg.black}`,
            border: `2px solid ${theme.palette.fg.black}`,
            backgroundColor: theme.palette.bg.white,
            color: 'var(--foreground-purple) !important',
            '& svg': {
                color: 'var(--foreground-purple) !important'
            }
        },
        '& .MuiInputBase-input': {
            fontSize: '16px !important',
            padding: 0
        },
        '& div': {
            fontFamily: 'GreedStandard-Medium'
        }
    },
    menuItem: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        backgroundColor: 'white !important',
        '&:hover': {
            backgroundColor: 'white',
            color: 'var(--foreground-purple) !important'
        }
    }
}))
