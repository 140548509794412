import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    searchJobSectionContainer: {
        width: '100%',
        padding: '32px 0px 43px',
    },

    searchJobField: {
        width: '100%',
        paddingTop: '32px',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '442px',
        }
    },

    jobScheduleSectionContainer: {
        width: '100%',
        paddingBottom: '43px',
    },

    tagsMultiSelectContainer: {
        paddingTop: '32px',
    },

    fieldOfStudySectionContainer: {
        width: '100%',
        borderBottom: `0.5px solid ${theme.palette.fg['beigeLight']}`,
        paddingBottom: '51px',
        [theme.breakpoints.up('sm')]: {
            paddingBottom: '39px',
        }
    },

    checkboxListContainer: {
        paddingTop: '32px',
    },

    locationSectionContainer: {
        width: '100%',
        paddingTop: '11px',
        paddingBottom: '66px',
    },

    locationField: {
        width: '100%',
        paddingTop: '32px',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '387px',
        }
    },

}))
