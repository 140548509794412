import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    pageContainer: {
        width: '100%'
    },
    filterViewContent: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    breadcrumbsContainer: {
        position: 'absolute',
        top: '0px',
        left: '50%',
        transform: 'translateX(-50%)'
    },
    breadcrumbs: {
        fontSize: '14px',
        color: theme.palette.fg['white'],
        textAlign: 'right',
        [theme.breakpoints.up('sm')]: {
            textAlign: 'left'
        }
    },
    quickFiltersContainer: {
        width: '100%',
        padding: '35px 0px 55px 28px',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: '8px',
        [theme.breakpoints.up('sm')]: {
            padding: '37px 71px 61px',
            gap: '8px'
        }
    },
    spacingContainer: {
        height: 30,
        width: '100%'
    },
    quickFiltersTitle: {
        paddingRight: '7px',
        fontSize: '13px !important',
        lineHeight: '23px',
        [theme.breakpoints.up('sm')]: {
            fontSize: '12px !important',
            lineHeight: '21px'
        }
    },
    chipRoot: {
        height: 'fit-content',
        backgroundColor: theme.palette.bg['greyPale'],
        borderRadius: '73px',
        padding: '0px 27px',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        '&:hover': {
            backgroundColor: theme.palette.bg['blue'],
            boxShadow: `4px 4px 0px 0px ${theme.palette.bg['black']}`,
            '& span': {
                color: theme.palette.fg['black']
            },
        },
        [theme.breakpoints.up('sm')]: {
            borderRadius: '67px'
        }
    },
    chipLabel: {
        padding: 0,
        color: theme.palette.fg['black'],
        fontFamily: 'GreedStandard-Medium',
        fontSize: '15px',
        lineHeight: '26px',
        // textTransform: 'capitalize',
        transition: 'all 0.3s ease',
        [theme.breakpoints.up('sm')]: {
            fontSize: '13px',
            lineHeight: '24px'
        }
    },
    pageContent: {
        width: '100%',
        padding: '0px 22px 43px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '44px',
        [theme.breakpoints.up('sm')]: {
            rowGap: '48px',
        },
        [theme.breakpoints.up('md')]: {
            paddingBottom: '30px'
        },
        [theme.breakpoints.up(1324)]: {
            paddingLeft: '0px',
            paddingRight: '0px'
        }
    },
    articlesContainer: {
        width: '100%',
    },
    articlesContainerTitle: {
        paddingBottom: '24px',
        fontSize: '20px',
        '& .articlesSpan': {
            color: theme.palette.fg['purple']
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '24px'
        }
    },
}))
