import React from 'react'
import TagManager from 'react-gtm-module'
import AnalyticsHelper from 'assets/AnalyticsHelper'

const PageViewTracker = (props) => {
    const { user } = props

    React.useEffect(() => {
        TagManager.initialize({
            gtmId: process.env.GTM_CONTAINER
        })
        AnalyticsHelper.sendGTMEvent(
            'Page Viewed',
            AnalyticsHelper.constructPageViewedAnalyticsAttributes(user)
        )

        if (window.matchMedia('(display-mode: standalone)').matches) {
            AnalyticsHelper.sendGTMEvent('pwa-page-view')
        }
    }, [])

    return null
}

export default PageViewTracker
