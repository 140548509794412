import React, { useState, useCallback, useContext } from 'react'
import debounce from 'lodash.debounce'
import StudentJobSearchStatusHelper from './StudentJobSearchStatusHelper'
import StudentJobSearchStatusMinimizedView from './components/StudentJobSearchStatusMinimizedView'
import StudentJobSearchStatusDefaultView from './components/StudentJobSearchStatusDefaultView'
import UserAPI from 'api/UserAPI'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import { UserContext } from 'contexts/UserContext'

const StudentJobSearchStatus = (props) => {
    const { minimised, dashboard, simple, black, context } = props
    const [modalOpen, setModalOpen] = useState(false)
    const userContext = useContext(UserContext)

    const debouncedUpdate = useCallback(
        debounce((oldStatusConfig, newStatusConfig) => {
            updateProfile(oldStatusConfig, newStatusConfig)
        }, 1000), []
    )

    const updateProfile = (oldStatusConfig, newStatusConfig) => {
        const profileData = {
            only_profile: true,
            ...newStatusConfig
        }
        const noneSelected = Object.values(newStatusConfig).filter(value => value === true).length === 0
        userContext.setStatusConfigSaved(false)
        UserAPI.UserEditMyProfile(profileData).then(response => {
            AnalyticsHelper.sendGTMEvent(
                'Student Status Updated',
                AnalyticsHelper.constructStudentStatusAnalyticsAttributes(userContext.user, newStatusConfig, context)
            )
            userContext.setStatusConfigSaved(true)
            if (!minimised) {
                const result = response.data.profile
                if (noneSelected || result.not_looking_any_job) return

                const oldlookingForTotal = Object.values(oldStatusConfig).filter(Boolean).length
                const newLookingForTotal = Object.values(newStatusConfig).filter(Boolean).length
                if (newLookingForTotal > oldlookingForTotal) {
                    setModalOpen(true)
                }
            } else {
                window.location.reload()
            }
        })
    }

    const handleModalClose = () => {
        setModalOpen(false)
    }

    const handleClick = (key, value) => {
        if (key === StudentJobSearchStatusHelper.NOT_LOOKING_ANY_JOB) {
            updateNotLookingAndSetOthersFalse(value)
        } else {
            updateClickedAndSetNotLookingFalse({ [key]: value })
        }
    }

    const updateNotLookingAndSetOthersFalse = (updatedValue) => {
        const newStatusConfig = {
            actively_looking_student_job: false,
            actively_looking_internship: false,
            actively_looking_first_job: false,
            actively_looking_kot: false,
            not_looking_any_job: updatedValue
        }
        userContext.setStatusConfig(newStatusConfig)
        debouncedUpdate(userContext.statusConfig, newStatusConfig)
    }

    const updateClickedAndSetNotLookingFalse = (updatedStatus) => {
        const newStatusConfig = {
            ...userContext.statusConfig,
            ...updatedStatus,
            not_looking_any_job: false
        }
        userContext.setStatusConfig(newStatusConfig)
        debouncedUpdate(userContext.statusConfig, newStatusConfig)
    }

    if (minimised) {
        return (
            <StudentJobSearchStatusMinimizedView
                statusConfig={userContext.statusConfig}
                handleClick={handleClick}
            />
        )
    } else {
        return (
            <StudentJobSearchStatusDefaultView
                statusConfig={userContext.statusConfig}
                modalOpen={modalOpen}
                handleModalClose={handleModalClose}
                handleClick={handleClick}
                simple={simple}
                black={black}
                dashboard={dashboard}

            />
        )
    }
}

export default StudentJobSearchStatus
