import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    background: {
        display: 'flex',
        width: '100%',
        height: '100%',
        backgroundColor: 'var(--background-blue)',
        backgroundImage: "url('https://res.cloudinary.com/studentbe/image/upload/v1721138136/student-be-assets/Mask_group.png')"
    },
    mainPaperBox: {
        marginTop: 40,
        marginBottom: 100,
        backgroundColor: 'white',
        boxShadow: '4px 4px 0px 0px black',
        border: '2px solid black',
        padding: '40px 50px',
        '& * .MuiCheckbox-root': {
            margin: 9
        },
        '& * .checkbox-list-line': {
            paddingBottom: 8
        }
    }
}))
