import CollectionHandler from './CollectionHandler.js'
import URLHandler from './URLHandler.js'

const GetIndexPaths = (basePage, pageType) => {
    const quickFilterCollection = CollectionHandler.Get('quickFilterCollection')
    const trailingQuickFilterRoutePaths = quickFilterCollection[pageType].map((quickFilter) => {
        return [
            `/${basePage}/${URLHandler.EncodeTagToUrl(quickFilter.en)}/`,
            `/${basePage}/${URLHandler.EncodeTagToUrl(quickFilter.fr)}/`,
            `/${basePage}/${URLHandler.EncodeTagToUrl(quickFilter.nl)}/`
        ]
    }).flat()

    const sectorCollection = CollectionHandler.Get('fullSectorCollection')
    const trailingSectorRoutePaths = sectorCollection.map((sector) => {
        return [
            `/${basePage}/${URLHandler.EncodeTagToUrl(sector.en)}/`,
            `/${basePage}/${URLHandler.EncodeTagToUrl(sector.fr)}/`,
            `/${basePage}/${URLHandler.EncodeTagToUrl(sector.nl)}/`
        ]
    }).flat()

    const basePages = [`/${basePage}`, `/:location/${basePage}`]
    return basePages.concat(trailingQuickFilterRoutePaths).concat(trailingSectorRoutePaths)
}

export default {
    GetIndexPaths
}
