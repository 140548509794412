import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import RecommendedKots from 'components/RecommendedKots/RecommendedKots'
import RecommendedKotsForUser from 'pages/user/PersonalDashboard/components/RecommendedKotsForUser/RecommendedKotsForUser'
import { FormattedMessage } from 'react-intl'
import { Image, Transformation } from 'cloudinary-react'
import { useStyles } from '../Lost_style'

const LostKotPage = (props) => {
    const { user } = props
    const classes = useStyles()

    return (<>
        <Container maxWidth="md" className={classes.aboutLostContainer}>
            <Box className={classes.aboutLostContent}>
                <Box className={classes.title}><FormattedMessage id="landing_aboutlost.banner" /></Box>
                <Box className={classes.lostInfoContainer}>
                    <Typography variant="h2"><FormattedMessage id="transl_kots.does_not_exist_banner" /></Typography>
                </Box>
            </Box>
            <Box className={classes.aboutLostImg}>
                <Image
                    cloudName="studentbe"
                    publicId='404_page.gif'
                    alt='404-lost-on-student-be'
                >
                    <Transformation q_auto='eco'/>
                </Image>
            </Box>
        </Container>
        {user?.school_location
            ? <RecommendedKotsForUser
                fullWidth
                key='recommended_kots_for_user'
                user={user}
                textValues={{ school: user.school_location }}
                location={user.school_location}
            />
            : <RecommendedKots user={user} />
        }
    </>)
}

export default LostKotPage
