import React from 'react'
import { Helmet } from 'react-helmet'
import ItemShowFactory from './ItemShowFactory'
import { injectIntl } from 'react-intl'
import { Switch, Route } from 'react-router-dom'
import NewsIndex from '../../pages/BlogSection/NewsIndex/NewsIndex'
import NewsCategoryIndex from '../../pages/BlogSection/NewsCategoryIndex/NewsCategoryIndex'

class ArticleRouter extends React.Component {
    constructor (props) {
        super(props)

        this.renderArticleIndex = this.renderArticleIndex.bind(this)
    }

    buildHelmet () {
        const { formatMessage } = this.props.intl
        if (this.props.selectedArticleTagObject) {
            return (
                <Helmet>
                    <title>{formatMessage({ id: 'helmet.article.category_index.title' }, { category_name: this.props.selectedArticleTagObject.name })}</title>
                    <meta property="og:title" content={formatMessage({ id: 'helmet.article.category_index.title' }, { category_name: this.props.selectedArticleTagObject.name })} />
                    <meta name="description" content={formatMessage({ id: 'helmet.article.category_index.description' }, { category_name: this.props.selectedArticleTagObject.name })} />
                    <meta property="og:description" content={formatMessage({ id: 'helmet.article.category_index.description' }, { category_name: this.props.selectedArticleTagObject.name })} />
                    <link rel="canonical" href={`https://www.student.be/${this.props.lang}/student-life/`} />
                    <link rel="alternate" hrefLang="fr" href={this.props.altLangUrls.fr} />
                    <link rel="alternate" hrefLang="en" href={this.props.altLangUrls.en} />
                    <link rel="alternate" hrefLang="nl" href={this.props.altLangUrls.nl} />
                    <link rel="alternate" hrefLang="x-default" href={this.props.altLangUrls.en} />
                </Helmet>
            )
        } else {
            return (
                <Helmet>
                    <title>{formatMessage({ id: 'helmet.article.index.title' })}</title>
                    <meta property="og:title" content={formatMessage({ id: 'helmet.article.index.title' })} />
                    <meta name="description" content={formatMessage({ id: 'helmet.article.index.description' })} />
                    <meta property="og:description" content={formatMessage({ id: 'helmet.article.index.description' })} />
                    <link rel="canonical" href={`https://www.student.be/${this.props.lang}/student-life/`} />
                    <link rel="alternate" hrefLang="fr" href='https://www.student.be/fr/student-life/' />
                    <link rel="alternate" hrefLang="en" href='https://www.student.be/en/student-life/' />
                    <link rel="alternate" hrefLang="nl" href='https://www.student.be/nl/student-life/' />
                    <link rel="alternate" hrefLang="x-default" href='https://www.student.be/en/student-life/' />
                </Helmet>
            )
        }
    }

    renderArticleIndex () {
        const { formatMessage } = this.props.intl
        return (
            <>
                {this.props.selectedArticleTagObject &&
                    <Helmet>
                        <title>{formatMessage({ id: 'helmet.article.category_index.title' }, { category_name: this.props.selectedArticleTagObject.name })}</title>
                        <meta property="og:title" content={formatMessage({ id: 'helmet.article.category_index.title' }, { category_name: this.props.selectedArticleTagObject.name })} />
                        <meta name="description" content={formatMessage({ id: 'helmet.article.category_index.description' }, { category_name: this.props.selectedArticleTagObject.name })} />
                        <meta property="og:description" content={formatMessage({ id: 'helmet.article.category_index.description' }, { category_name: this.props.selectedArticleTagObject.name })} />
                        <link rel="canonical" href={`https://www.student.be/${this.props.lang}/student-life`} />
                        <link rel="alternate" hrefLang="fr-BE" href={this.props.altLangUrls.fr} />
                        <link rel="alternate" hrefLang="en-BE" href={this.props.altLangUrls.en} />
                        <link rel="alternate" hrefLang="nl-BE" href={this.props.altLangUrls.nl} />
                        <link rel="alternate" hrefLang="x-default" href={this.props.altLangUrls.en} />
                    </Helmet>
                }
                {!this.props.selectedArticleTagObject &&
                    <Helmet>
                        <title>{formatMessage({ id: 'helmet.article.index.title' })}</title>
                        <meta property="og:title" content={formatMessage({ id: 'helmet.article.index.title' })} />
                        <meta name="description" content={formatMessage({ id: 'helmet.article.index.description' })} />
                        <meta property="og:description" content={formatMessage({ id: 'helmet.article.index.description' })} />
                        <link rel="canonical" href={`https://www.student.be/${this.props.lang}/student-life/`} />
                        <link rel="alternate" hrefLang="fr-BE" href='https://www.student.be/fr/student-life/' />
                        <link rel="alternate" hrefLang="en-BE" href='https://www.student.be/en/student-life/' />
                        <link rel="alternate" hrefLang="nl-BE" href='https://www.student.be/nl/student-life/' />
                        <link rel="alternate" hrefLang="x-default" href='https://www.student.be/en/student-life/' />
                    </Helmet>
                }
                <NewsIndex
                    {...this.props}
                />
            </>
        )
    }

    render () {
        const basename = 'student-life'
        return (
            <div className='article'>
                <div ref={this.navigationRef} className="navigation-container">
                    <Switch>
                        <Route path={`/${basename}/categories/:selectedtag`} render={(props) => (
                            <>
                                {this.buildHelmet()}
                                <NewsCategoryIndex
                                    {...this.props}
                                />
                            </>
                        )} />

                        <Route
                            path={`/${basename}/:itemid`}
                            render={(props) => (
                                <ItemShowFactory
                                    {...props}
                                    renderType='article'
                                    getAllInfoUrl='/api/v1/articles/get_all_info?id='
                                    similarUrl='/api/v1/articles/similar'
                                    closeShowTag={this.closeShowTag}
                                    user={this.props.user}
                                    ads={this.props.ads}
                                    lang={this.props.lang}
                                />
                            )}
                        />

                        <Route path={`/${basename}`} component={(props) => (
                            this.renderArticleIndex()
                        )} />
                    </Switch>
                </div>
            </div>
        )
    }
}

export default injectIntl(ArticleRouter)
