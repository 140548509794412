export const useStyles = theme => ({
    linkableTag: {
        margin: '5px',
        backgroundColor: 'var(--light-grey)',
        fontSize: '1rem',
        border: '1px solid var(--grey)'
    },
    unlinkableTag: {
        margin: '5px 5px',
        backgroundColor: 'var(--light-grey)'
    }
})
