import React, { useState } from 'react'

import AdminAPI from 'api/AdminAPI'
import AdminEditAdView from './AdminEditAdView'

const AdminEditAd = (props) => {
    const { ad = {}, isNew } = props
    const [state, setState] = useState({
        ...ad,
        document: ad.document.id ? ad.document : {}
    })
    const [feedback, setFeedback] = useState({
        open: false,
        message: null,
        severity: null
    })
    const [formErrors, setFormErrors] = useState({})

    const closeFeedback = () => {
        setFeedback({ ...feedback, open: false })
    }

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleStartDateChange = (date) => setState({ ...state, start_date: date })

    const handleEndDateChange = (date) => setState({ ...state, end_date: date })

    const handleImageChange = (res) => {
        const image = res[0] || {}
        setState({
            ...state,
            document: image
        })
    }

    const handlePlacesChange = (_, value) => {
        setState({
            ...state,
            ids_selected_places: value.map(place => place.id)
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        const formattedData = {
            id: state.id,
            client: state.client,
            campaign_name: state.campaign_name,
            url: state.url,
            start_date: state.start_date,
            end_date: state.end_date,
            active: state.active,
            langage: state.langage,
            ids_selected_places: state.ids_selected_places,
            format: state.format,
            html: state.html,
            document: state.document
        }
        isNew ? createAd(formattedData) : updateAd(formattedData)
    }

    const createAd = (data) => {
        AdminAPI.AdCreate(data).then((response) => {
            const { is_success, errors, redirection_url } = response.data

            if (is_success) {
                setFeedback({
                    open: true,
                    message: 'Successfully created your Ad',
                    severity: 'success'
                })
                setTimeout(() => {
                    window.location.href = redirection_url
                }, 500)
            } else {
                setFormErrors(errors)
                setFeedback({
                    open: true,
                    message: `Error occurred: ${errors}`,
                    severity: 'error'
                })
            }
        })
    }

    const updateAd = (data) => {
        AdminAPI.AdUpdate(data).then((response) => {
            const { is_success, errors } = response.data

            if (is_success) {
                setFeedback({
                    open: true,
                    message: 'Successfully updated your Ad',
                    severity: 'success'
                })
            } else {
                setFormErrors(errors)
                setFeedback({
                    open: true,
                    message: `Error occurred: ${errors}`,
                    severity: 'error'
                })
            }
        })
    }

    return (
        <AdminEditAdView
            state={state}
            setState={setState}
            feedback={feedback}
            formErrors={formErrors}
            closeFeedback={closeFeedback}
            handleChange={handleChange}
            handleStartDateChange={handleStartDateChange}
            handleEndDateChange={handleEndDateChange}
            handleImageChange={handleImageChange}
            handlePlacesChange={handlePlacesChange}
            handleSubmit={handleSubmit}
        />
    )
}

export default AdminEditAd
