import URLHandler from '../assets/URLHandler'

export const useGalleryImageFormatting = (mediaItems, onLoad) => {
    if (!mediaItems || mediaItems.length < 0) return []

    const imageSpecs = 'c_fill,f_auto,fl_progressive,h_800,q_auto:best'
    return mediaItems.map((mediaItem, index) => {
        return {
            ...mediaItem,
            type: mediaItem.type || 'image',
            src: getImageSrc(mediaItem, imageSpecs),
            url: mediaItem.url,
            alt: `company image ${index}`,
            margin: 0,
            width: mediaItem.width || 500,
            height: mediaItem.height || 250,
            onLoad: onLoad
        }
    })
}

// PRIVATE

const getImageSrc = (mediaItem, imageSpecs) => {
    if (mediaItem.type === 'video') {
        const thumbnailUrl = URLHandler.getImageUrlForVideo(mediaItem.url)
        return thumbnailUrl
    } else {
        return `https://res.cloudinary.com/studentbe/image/upload/${imageSpecs}/${mediaItem.cloudinary_key}`
    }
}
