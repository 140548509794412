import axios from 'axios'
import ReactOnRails from 'react-on-rails';

import {
    AD_INCREASE_SHOW_COUNTER
} from './env'

/**
    Axios request to Alert#Create
    @param data define the http request parameters
 */
const IncreaseAdShowcounter = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: AD_INCREASE_SHOW_COUNTER,
        method: 'post',
        data: data
    })
}

export default {
    IncreaseAdShowcounter
}
