import axios from 'axios'
import ReactOnRails from 'react-on-rails'
import RouteMap from '../assets/RouteMap.js'

import {
    RECRUITING_COMPANIES_API,
    SIMILAR_COMPANIES_API,
    FILTER_COMPANY_JOBS_API,
    TESTIMONIES_API,
    GET_TESTIMONIES_API,
    COMPANY_API,
    JOIN_PARENT_COMPANY_API
} from './env'

const JoinParentCompany = (data) => {
    return axios({
        url: JOIN_PARENT_COMPANY_API,
        method: 'post',
        data: {
            parent_id: data.selectedCompanyId,
            child_company_id: data.companyId
        }
    })
}

function CompanyProfileUpdate (data) {
    return axios({
        url: COMPANY_API,
        method: 'patch',
        data: data
    })
}

function GetTestimonies (id) {
    return axios({
        url: GET_TESTIMONIES_API,
        method: 'get',
        params: { company_id: id }
    })
}

function SendTestimony (data) {
    return axios({
        url: TESTIMONIES_API,
        method: 'post',
        data: data
    })
}

function UpdateTestimony (data, id) {
    return axios({
        url: TESTIMONIES_API + id,
        method: 'patch',
        data: data
    })
}

function DeleteTestimony (id) {
    return axios({
        url: TESTIMONIES_API + id,
        method: 'delete'
    })
}

const GetRecruitingCompanies = (data) => {
    return axios({
        url: RECRUITING_COMPANIES_API,
        method: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

const GetSimilarCompanies = (data) => {
    return axios({
        url: SIMILAR_COMPANIES_API,
        method: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

const GetCompanyJobs = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: FILTER_COMPANY_JOBS_API,
        method: 'post',
        data: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

export default {
    GetTestimonies,
    GetCompanyJobs,
    SendTestimony,
    UpdateTestimony,
    DeleteTestimony,
    CompanyProfileUpdate,
    JoinParentCompany,
    GetRecruitingCompanies,
    GetSimilarCompanies
}
