import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
        },
        [theme.breakpoints.down('sm')]: {
        }
    },
    actionBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.up('md')]: {
            minWidth: 120
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 4,
            width: '100%'
        }
    },
    paper: {
        padding: '20px 20px 10px 20px',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            padding: 0,
            alignItems: 'center',
            justifyContent: 'space-between'
        }
    },
    iconButtonStyle: {
        width: 32,
        height: 32
    },
    candidacyInfo: {
        width: '100%',
        height: '100%',
        display: 'flex',
        gap: 8,
        [theme.breakpoints.up('md')]: {
            borderLeft: '1px solid lightgrey',
            borderRight: '1px solid lightgrey',
            padding: 20,
            minHeight: 100
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    jobType: {
        display: 'flex',
        gap: 8,
        alignItems: 'center',
        textTransform: 'capitalize',
        fontSize: 16,
        [theme.breakpoints.up('md')]: {
            gap: 20,
            padding: 20,
            height: '100%',
            minWidth: 220,
            color: 'black'
        }
    },
    descriptionBox: {
        display: 'flex',
        gap: 8,
        flexDirection: 'column',
        fontSize: 16,
        width: '100%',
        [theme.breakpoints.up('md')]: {
        }
    },
    jobTypeText: {
        textTransform: 'capitalize',
        fontSize: 16
    },
    companyName: {
        fontSize: 20
    },
    mobileTypeContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 8
    },
    recruitmentBox: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        [theme.breakpoints.up('md')]: {
            minWidth: 110,
            fontSize: 16,
            gap: 10,
            marginLeft: 24,
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center'
        }
    },
    dateBox: {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            alignItems: 'center',
            fontSize: 16,
            width: '100%',
            justifyContent: 'flex-end'
        }
    },
    recruitmentClosed: {
        color: 'var(--background-orange)'
    },
    span: {
        fontSize: 14
    },
    iconBox: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            margin: 10
        }
    }
}))
