import React from 'react'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import IconFactory from 'icons/IconFactory/IconFactory'
import RouteMap from 'assets/RouteMap'
import NotificationCardAvatar from './NotificationCardAvatar'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'

const NotificationCard = (props) => {
    const { notification } = props
    const NOTIFICATION_TEXT_ID = `notifications.new_${notification.notification_type}_with_count.${notification.count === 1 ? 'singular' : 'plural'}`

    const getEmitterName = () => {
        if (notification.notification_type === 'candidacy') {
            if (notification.emitter_name.length > 50) {
                return `'${notification.emitter_name.substring(0, 50)}...'`
            }
            return `'${notification.emitter_name}'`
        }
        return notification.emitter_name
    }

    return (
        <Box style={{ borderTop: '1px solid var(--grey)' }}>
            <RouteMapLink redirectionUrl={RouteMap.Page(notification.url).replace(/\/$/, '')} key={notification.url}>
                <MenuItem>
                    <NotificationCardAvatar
                        cloudinaryKey={notification.emitter_picture_cloudinary_key}
                        notificationType={notification.notification_type}
                    />
                    <Box my={2} ml={1}>
                        <Typography variant='body2' style={{ whiteSpace: 'pre-wrap', maxWidth: '300px' }} >
                            <FormattedMessage
                                id={NOTIFICATION_TEXT_ID}
                                values={{ count: notification.count, name: getEmitterName() }}
                            />
                        </Typography>
                    </Box>
                    {!props.notification.seen &&
                        <Box ml={2}>
                            <IconFactory icon='circle-solid-full' fontSize='12px' color='var(--red)' />
                        </Box>
                    }
                </MenuItem>
            </RouteMapLink>
        </Box>
    )
}
export default NotificationCard
