import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            justifyContent: 'unset',
        }
    },
    text: {
        '& >div': {
            marginTop: '10px',
            marginBottom: '20px',
            padding: '16px 0',
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
            }
        },
    },
    title: {
        marginTop: '20px',
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        }
    },
    status: {
        padding: '16px 0',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
        '& >ul': {
            [theme.breakpoints.down('sm')]: {
                textAlign: 'left',
            }
        }
    },
    cta: {
        padding: '16px 0',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            padding: '8px 0',
        }
    },
    image: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        '& >img': {
            height: '90%',
            [theme.breakpoints.down('sm')]: {
                height: '200px',
            }
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        }
    },
    firstJobs: {
        marginTop: '40px'
    }
}))
