import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    fullWidth: {
        width: '100%',
        fontSize: '0.875rem'
    },
    paper: {
        padding: 16
    },
    languagesContainer: {
        padding: 24,
        [theme.breakpoints.down('sm')]: {
            padding: '24px 8px'
        }
    },
    gridFlex: {
        display: 'flex',
        justifyContent: 'flex-start',
        textAlign: 'end'
    },
    formControl: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    isEmpty: {
        color: 'var(--red)'
    },
    datePicker: {
        color: 'white',
        textColor: 'white',
        calendarTextColor: 'white',
        selectColor: 'white',
        selectTextColor: 'white',
        calendarYearBackgroundColor: 'white',
        headerColor: 'white'
    },
    iconColor: {
    },
    textFont: {
        fontSize: '0.875rem'
    },
    languageContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    star: {
        color: 'var(--background-yellow)',
        stroke: 'black',
        strokeWidth: '30px'

    },
    starEmpty: {
        color: 'black'
    }

}))
