import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    chips: {
        display: 'flex',
        gap: 8,
        maxWidth: '100%',
        overflowX: 'scroll',
        overflowY: 'hidden',
        scrollbarWidth: 'none', /* Firefox */
        '-ms-overflow-style': 'none', /* IE 10+ */
        '&::-webkit-scrollbar': { /* Chrome/Safari/Webkit */
            height: 0,
            background: 'transparent'
        }
    },
    chipRoot: {
        height: 'fit-content',
        cursor: 'pointer'
    },
    chipLabel: {
        padding: 0
    },
    chip: {
        borderRadius: '50px',
        padding: '2px 12px',
        fontFamily: 'GreedStandard-Medium',
        fontSize: '10px',
        lineHeight: '18px',
        textTransform: 'capitalize',
    }
}))
