export const useStyles = (theme) => ({
    // === IMPORTANT - TO READ BEFORE CHANGE ===
    // The styling of the content is similar to the promotion show
    // with some small differences. However, both styles should be updated
    // when changing the article content style
    articleText: {
        '& span': {
            ...theme.typography.body1,

            ...{
                fontFamily: theme.typography.h1.fontFamily,
                margin: 0,
                fontSize: '18px !important',
                lineHeight: '28px !important',
                marginBottom: '22px !important',
                color: 'var(--black)',
                [theme.breakpoints.down('md')]: {
                    fontSize: '17px !important',
                    lineHeight: '24px !important',
                    marginBottom: '20px !important'
                }
            }
        },
        '& p': {
            ...theme.typography.body1,
            ...{
                fontFamily: theme.typography.subtitle1.fontFamily,
                margin: 0,
                fontSize: '18px !important',
                lineHeight: '28px !important',
                marginBottom: '22px !important',
                color: 'var(--black)',
                [theme.breakpoints.down('md')]: {
                    fontSize: '17px !important',
                    lineHeight: '24px !important',
                    marginBottom: '20px !important'
                }
            }
        },
        '& .also-read-box': {
            backgroundColor: 'var(--background-silver)',
            borderRadius: 20,
            padding: 20
        },
        '& .ql-align-center': {
            textAlign: 'center'
        },
        '& .ql-align-justify': {
            textAlign: 'justify'
        },
        '& .ql-align-right': {
            textAlign: 'right'
        },
        '& li': {
            ...theme.typography.body1,
            ...{
                fontFamily: theme.typography.subtitle1.fontFamily,
                fontSize: '18px !important',
                lineHeight: '28px !important',
                marginBottom: '12px !important',
                color: 'var(--black)',
                [theme.breakpoints.down('md')]: {
                    fontSize: '17px !important',
                    lineHeight: '24px !important',
                    marginBottom: '20px !important'
                }
            },
            '&.ql-indent-1': {
                marginLeft: '1.5rem'
            },
            '&.ql-indent-2': {
                marginLeft: '3rem'
            },
            '&.ql-indent-3': {
                marginLeft: '4.5rem'
            }
        },
        '& h1': {
            ...theme.typography.h1,
            ...{
                marginBottom: '20px !important',
                [theme.breakpoints.down('md')]: {
                    fontSize: '24px !important'
                },
                [theme.breakpoints.up('md')]: {
                    fontSize: '22px !important'
                }
            }
        },
        '& h2': {
            ...theme.typography.h2,
            ...{
                marginBottom: '20px !important',
                [theme.breakpoints.down('md')]: {
                    fontSize: '20px !important'
                },
                [theme.breakpoints.up('md')]: {
                    fontSize: '18px !important'
                }
            }
        },
        '& h3': {
            ...theme.typography.h3,
            ...{
                marginBottom: '20px !important',
                [theme.breakpoints.down('md')]: {
                    fontSize: '18px !important'
                },
                [theme.breakpoints.up('md')]: {
                    fontSize: '17px !important'
                }
            }
        },
        '& iframe': {
            marginBottom: '20px !important'
        },
        '& img': {
            maxWidth: '100%',
            borderRadius: 12
        }
    }
})
