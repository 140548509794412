import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    newStyleButton: {
        padding: '9px 14px',
        borderRadius: '8px',
        boxShadow: 'none',
        '& p': {
            fontFamily: "'GintoNormal-Medium', sans-serif !important"
        },
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: 'white',
            opacity: 0.9
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    newStyleButtonWhite: {
        backgroundColor: 'white !important',
        color: 'black',
        '&:hover': {
            boxShadow: 'none',
            opacity: 0.9
        }
    }
}))
