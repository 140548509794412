import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    bannerContainer: {
        padding: '0px 30px 0px',
    },

    companyLogoContainer: {
        paddingBottom: 12
    },

    logoBox: {
        overflow: 'hidden',
        display: 'block',
        width: '80px',
        height: '80px',
        boxShadow: `4px 4px 0px 0px ${theme.palette.fg['black']}`,
        border: `2px solid ${theme.palette.fg['black']}`,
        borderRadius: '50%'
    },

    logo: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },

    jobTitle: {
        marginBottom: '15px',
        color: theme.palette.fg['black'],
        fontFamily: 'GreedWide-Bold',
        fontSize: '24px',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        WebkitLineClamp: 4,
        textOverflow: 'ellipsis',
    },

    companyName: {
        paddingBottom: '10px',
        color: theme.palette.fg['black'],
        fontSize: '16px',
        lineHeight: '20px'
    },

    controlsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        columnGap: '30px'
    },

    leftControlsContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '18px'
    },

    rightControlsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: '6px',
        [theme.breakpoints.up(710)]: {
            flexDirection: 'row'
        }
    },

    applyButtonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: '6px',
        [theme.breakpoints.up(710)]: {
            flexDirection: 'row',
        },
    },
}))
