import React from 'react'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import AboutKotOwnersHelmet from 'helmets/AboutKotOwnersHelmet'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import { FormattedMessage } from 'react-intl'
import Button from 'components/Button/Button'
import { useStyles } from '../AboutFirstJobs/aboutabout_style'
import { Image, Transformation } from 'cloudinary-react'
import RouteMap from 'assets/RouteMap'
import NewsAndTips from 'components/NewsAndTips/NewsAndTips'

const AboutKotOwners = (props) => {
    const { user, recentArticles } = props
    const classes = useStyles()

    const callToActionUrl = user ? 'users/kots/new' : 'login'
    const redirectUrl = user ? null : `?redirect=${RouteMap.Page('users/kots/new')}`

    return (
        <PageWrapper user={user}>
            <AboutKotOwnersHelmet />
            <Box className={classes.aboutPageBanner}>
                <Container maxWidth='md'>
                    <Typography variant='h1' className={classes.titlePage}>
                        <FormattedMessage id='landing_about_kot_owners.title' />
                    </Typography>
                </Container>
            </Box>
            <Container maxWidth='md' className={classes.aboutPageBox}>
                <Box className={classes.kotBackground}>
                    <Image
                        cloudName='studentbe'
                        publicId='assets/kot-owner-about-banner'
                        alt='Kot banner'
                    >
                        <Transformation flags='progressive' fetchFormat='auto' quality='auto:best' />
                    </Image>
                </Box>
                <Typography variant='h2' className={classes.subtitle}>
                    <FormattedMessage id='landing_about_kot_owners.subtitle' />
                </Typography>
                <Typography className={classes.breakline}>
                    <FormattedMessage id='landing_about_kot_owners.description' />
                </Typography>
                <Box className={classes.buttonBox}>
                    <RouteMapLink page={callToActionUrl} paramString={redirectUrl}>
                        <Button variant='primary'>
                            <FormattedMessage id='landing_about_kot_owners.post_a_kot' />
                        </Button>
                    </RouteMapLink>
                </Box>
                <Typography variant='h2' className={classes.subtitle}>
                    <FormattedMessage id='landing_about_kot_owners.publish_kot.title' />
                </Typography>
                <Box className={classes.listContainer}>
                    <Typography className={classes.breakline}>
                        <FormattedMessage id='landing_about_kot_owners.publish_kot.step_1.title' />
                        <RouteMapLink page='login' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_kot_owners.publish_kot.step_1.here' />
                        </RouteMapLink>
                    </Typography>
                    <Typography className={classes.breakline}>
                        <FormattedMessage id='landing_about_kot_owners.publish_kot.step_2' />
                    </Typography>
                    <Typography className={classes.breakline}>
                        <FormattedMessage id='landing_about_kot_owners.publish_kot.step_3' />
                    </Typography>
                    <Typography className={classes.breakline}>
                        <FormattedMessage id='landing_about_kot_owners.publish_kot.step_4' />
                    </Typography>
                </Box>
                <Typography variant='h2' className={classes.subtitle}>
                    <FormattedMessage id='landing_about_kot_owners.ads_online.title' />
                </Typography>
                <Box className={classes.breaklines}>
                    <Typography className={classes.breakline}>
                        <FormattedMessage id='landing_about_kot_owners.ads_online.step_1' />
                    </Typography>
                    <Typography className={classes.breakline}>
                        <FormattedMessage id='landing_about_kot_owners.ads_online.step_2' />
                    </Typography>
                </Box>
                <Typography variant='h2' className={classes.subtitle}>
                    <FormattedMessage id='landing_about_kot_owners.wish_interested_students' />
                </Typography>
                <Box className={classes.buttonBox}>
                    <RouteMapLink page={callToActionUrl} paramString={redirectUrl}>
                        <Button variant='primary'>
                            <FormattedMessage id='landing_about_kot_owners.post_a_kot' />
                        </Button>
                    </RouteMapLink>
                </Box>
            </Container>
            <NewsAndTips item={{ type: 'kot' }} articles={recentArticles} />
        </PageWrapper>
    )
}

export default AboutKotOwners
