import React from 'react'

const PublicInstitutionSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86 85.89" {...props}>
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path
                    d="M82.59,65.38,78,61.93q0-8,0-16a8,8,0,0,0,7.84-6.53,8.68,8.68,0,0,0,.16-1.6v-.84c0-1.62,0-3.24,0-4.8a8.42,8.42,0,0,0-4.28-7.29L78.44,23l-6.76-3.8L50.2,7.1a8.54,8.54,0,0,0-8.5.07L39.65,8.32l-5.43,3.06L10.4,24.77A8.47,8.47,0,0,0,6,32.32C6,33.64,6,35,6,36.26V37.9a8,8,0,0,0,8,8h0v16l-1.3,1L9.56,65.27A8.68,8.68,0,0,0,6,72.39c0,1.27,0,2.54,0,3.81v1.69a8,8,0,0,0,8,8H78a8,8,0,0,0,8-8V76.78c0-1.59,0-3.16,0-4.68A8.45,8.45,0,0,0,82.59,65.38Z" />
                <path
                    d="M76.58,59.38,72,55.93q0-8,0-16a8,8,0,0,0,7.84-6.53,8.68,8.68,0,0,0,.16-1.6v-.84c0-1.62,0-3.24,0-4.8a8.42,8.42,0,0,0-4.28-7.29L72.44,17l-6.76-3.8L44.19,1.1a8.52,8.52,0,0,0-8.49.07L33.65,2.32,28.22,5.38,4.4,18.77A8.47,8.47,0,0,0,0,26.32C0,27.64,0,29,0,30.26V31.9a8,8,0,0,0,8,8H8v16l-1.3,1L3.56,59.27A8.68,8.68,0,0,0,0,66.39c0,1.27,0,2.54,0,3.81v1.69a8,8,0,0,0,8,8H72a8,8,0,0,0,8-8V70.78c0-1.59,0-3.16,0-4.68A8.43,8.43,0,0,0,76.58,59.38Z" />
                <path className="cls-1"
                    fill='white'
                    d="M16,36h8v24h5V36h8V59.91h6V36h8v24h5V36h8v.42Q64,48,64,59.54a.69.69,0,0,0,.32.63l7.46,5.6a.52.52,0,0,1,.22.33c0,1.92,0,3.84,0,5.79H8v-.34c0-1.74,0-3.49,0-5.23a.71.71,0,0,1,.33-.63l7.42-5.55a.6.6,0,0,0,.27-.55q0-11.6,0-23.2V36Z" />
                <path className="cls-1"
                    fill='white'
                    d="M72,31.9H8v-.37C8,29.78,8,28,8,26.27a.51.51,0,0,1,.31-.52q14.19-8,28.38-16c1-.56,2-1.12,3-1.7a.53.53,0,0,1,.6,0L67.75,23.53c1.34.75,2.68,1.5,4,2.26a.53.53,0,0,1,.24.34c0,1.88,0,3.76,0,5.63a.5.5,0,0,1,0,.14Zm-28-10a4,4,0,0,0-8,0,4,4,0,1,0,8,0Z" />
            </g>
        </g>
    </svg>
)

export default PublicInstitutionSVG
