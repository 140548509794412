/* eslint-disable react/prop-types */
import React from 'react'
import HeadhuntResponsePage from './components/HeadhuntResponsePage'
import PageWrapper from '../../../../shared/wrappers/PageWrapper/PageWrapper'

const HeadhuntResponse = (props) => {
    return (
        <div className="desktop-profile profile-container">
            <PageWrapper user={props.user}>
                <HeadhuntResponsePage {...props} />
            </PageWrapper>
        </div>
    )
}
export default HeadhuntResponse
