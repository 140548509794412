import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './FiltersViewWrapper_style'
import { Box, Typography } from '@material-ui/core'

const FiltersViewWrapper = (props) => {
    const { titleId, title, children } = props
    const classes = useStyles()

    return (
        <Box className={classes.filtersViewContainer}>
            <Box className={classes.inner}>
                {titleId && <FormattedMessage id={titleId}>
                    {text =>
                        <Typography className={classes.titleContainer} variant='h1' dangerouslySetInnerHTML={{ __html: text }} />
                    }
                </FormattedMessage>}
                {title &&
                    <Typography className={classes.titleContainer} variant='h1'>{title}</Typography>
                }
                {children}
            </Box>
        </Box>
    )
}

export default FiltersViewWrapper
