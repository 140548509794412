import React, { useState, useEffect } from 'react'
import { Box, Typography, Container, CardMedia, Chip } from '@material-ui/core'

import { useStyles } from './ArticleBannerCard_style.js'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import CloudinaryURLHelper from 'assets/CloudinaryURLHelper'
import DateHandler from 'assets/DateHandler'
import CollectionHandler from 'assets/CollectionHandler.js'
import IconFactory from 'shared/icons/IconFactory/IconFactory'
import { FormattedMessage } from 'react-intl'

const ArticleBannerCard = (props) => {
    const { article } = props
    const classes = useStyles()
    const [cloudinaryKey, setCloudinaryKey] = useState()
    const [category, setCategory] = useState()

    useEffect(() => {
        let cat = CollectionHandler.Translate('articleCategoryCollection', article.category)
        if (cat === '/') cat = CollectionHandler.Translate('articleCategoryCollection', 'student_life')
        setCategory(cat)
    }, [])

    useEffect(() => {
        const clKey = article?.cover_image?.cloudinary_key
        clKey ? setCloudinaryKey(clKey) : setCloudinaryKey('default-company-logo-black')
    }, [])

    return (
        <Container className={classes.mainContainer}>
            <RouteMapLink
                showItemType='article'
                showItemUrl={props.article.url}
                style={{ width: '100%' }}
            >
                <Box className={classes.main}>
                    <CardMedia
                        image={CloudinaryURLHelper.getImageUrl('LARGE_THUMBNAIL', cloudinaryKey)}
                        title={article.title}
                        alt={article.title}
                        className={classes.cardMediaContainer}
                    />
                    <Box className={classes.textContainer}>
                        <Typography
                            variant='h1'
                            component='span'
                            className={`${classes.articleTitle}`}
                            id='uniqArticleTitle'
                        >
                            {article.title}
                            <FormattedMessage id='read_now'>
                                {translatedText => <span id='readMore' dangerouslySetInnerHTML={{ __html: translatedText }}></span>}
                            </FormattedMessage>
                        </Typography>
                        <Box className={classes.articleInfo}>
                            <Chip
                                classes={{ root: classes.chipRoot, label: classes.chipLabel }}
                                className={classes.chip}
                                label={category}
                                name={category}
                                color={'var(--dark-grey)'}
                            />
                            <Box className={classes.readTimeAndDate}>
                                <Typography className={classes.minorText} variant='h1' component='span'>
                                    {`${DateHandler.toReadingFormat(article.date)}`}
                                </Typography>
                                <Typography variant='span' className={`${classes.minorText} ${classes.bullet}`}>
                                    •
                                </Typography>
                                <IconFactory
                                    icon='clock'
                                    fontSize='12px'
                                    color='var(--grey)'
                                    style={{
                                        fontFamily: 'GreedStandard-Medium, sans-serif !important',
                                        margin: '0 4px'
                                    }}
                                />
                                <Typography
                                    variant='h1'
                                    component='span'
                                    className={classes.minorText}
                                >
                                    {`${article.read_time} min`}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </RouteMapLink>
        </Container>
    )
}

export default ArticleBannerCard
