import React, { useEffect, useState } from 'react'
import { Box, Container, Typography } from '@material-ui/core'

import { useStyles } from './SimilarCompanies_style'
import Button from 'shared/components/Button/Button.jsx'
import RouteMap from 'assets/RouteMap.js'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import CompanyAPI from 'api/CompanyAPI'
import CompanyCard from 'cards/CompanyCard/CompanyCard'
import { FormattedMessage } from 'react-intl'

const SimilarCompanies = (props) => {
    const classes = useStyles()
    const { company } = props
    const [similarCompanies, setSimilarCompanies] = useState([])
    const moreItemsUrl = `${RouteMap.Index('company')}?company_size=${company.size}&sector=${company.sector}`

    useEffect(() => {
        const data = {
            company_id: company.id
        }
        CompanyAPI.GetSimilarCompanies(data).then((response) => {
            setSimilarCompanies(response.data.companies)
        })
    }, [company])

    return (
        <Box className={classes.similarItemsContainerWrapper}>
            <Container maxWidth='lg' className={classes.similarItemsContainer}>
                <Typography className={classes.title} component='h1'>
                    <FormattedMessage id='similar_companies.title' />
                </Typography>
                <Box className={classes.cardsContainer}>
                    {similarCompanies.map(company => {
                        return (
                            <Box className={classes.companyCardBox} key={company.id}>
                                <CompanyCard company={company} />
                            </Box>
                        )
                    })}
                </Box>
                <Box className={classes.buttonContainer}>
                    <RouteMapLink redirectionUrl={moreItemsUrl}>
                        <Button variant='cta-primary'>
                            <FormattedMessage id='similar_companies.button' />
                        </Button>
                    </RouteMapLink>
                </Box>
            </Container>
        </Box>
    )
}

export default SimilarCompanies
