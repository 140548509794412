import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box } from '@material-ui/core'

import { useStyles } from './CompanyTabs_style'
import Button from 'shared/components/Button/Button'

const CompanyTabs = (props) => {
    const classes = useStyles()
    const { tabs, selectedTab, setTab, jobsCount, userIsOwner } = props

    return (
        <Box className={classes.companyBodyTabsContainer}>
            <Box className={classes.companyBodyTabs}>
                {tabs.map(tab => {
                    const isSelected = tab === selectedTab
                    const isJobsTab = tab === 'jobs'

                    return (
                        <Box
                            onClick={() => setTab(tab)}
                            className={`
                                ${classes.companyTab}
                                ${isSelected ? classes.selectedTab : ''}
                                ${isJobsTab ? classes.jobsTab : ''}
                            `}
                            key={tab}
                        >
                            <FormattedMessage id={`company_tabs.tab.${tab}`} />
                            {isJobsTab && <span className={`
                                ${classes.jobsCount}
                                ${isSelected ? classes.selectedJobsCount : ''}
                            `}>
                                {jobsCount}
                            </span>}
                        </Box>
                    )
                })}
            </Box>
            {/* {userIsOwner &&
                <Button variant='cta-primary' size='small' className={classes.customButton}>
                    <FormattedMessage id="company_profile.publish_new_job" />
                </Button>
            } */}
        </Box>
    )
}

export default CompanyTabs
