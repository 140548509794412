import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    firstFiltersRow: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        columnGap: '13px',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'center',
        },
        [theme.breakpoints.up('lg')]: {
            columnGap: '8px',
        },
    },

    cityFieldContainer: {
        flex: 1,
        [theme.breakpoints.up('sm')]: {
            maxWidth: '154px',
        },
    },

    kotTypeFieldContainer: {
        width: '233px',
    },

    disponibilityFieldContainer: {
        width: '185px',
    },

    priceFieldContainer: {
        width: '116px',
    },

    roomCountFieldContainer: {
        width: '219px',
    },

    moreFiltersButtonContainer: {

    },

    resetFiltersButtonContainer: {
        paddingLeft: '3px',
    },

    divider: {
        width: '100%',
        height: '50px',
        backgroundColor: 'transparent',
        [theme.breakpoints.up('md')]: {
            height: '46px',
        },
    }

}))
