/* eslint-disable react/prop-types */
import React from 'react'
import { Box } from '@material-ui/core'

import MultipleSelectFilter from '../shared/MultipleSelectFilter/MultipleSelectFilter'
import ResetFilters from '../shared/ResetFilters/ResetFilters'

import { useStyles as useStylesLocal } from './AdminDataFilters_style'
import CollectionHandler from 'assets/CollectionHandler'

const AdminDataFilters = (props) => {
    const { children, filters, handleFilterChange, reset } = props
    const localClasses = useStylesLocal()
    const languageOptions = CollectionHandler.Get('defaultLanguageCollection')
    const provinceCollection = CollectionHandler.Get('provinceCollection')
    const studyFieldClustersCollection = CollectionHandler.Get('studyFieldClustersCollection')
    const studyCycleCollection = CollectionHandler.Get('studyCycleCollection')
    const studyFieldCollection = CollectionHandler.Get('studyFieldCollection')
    const graduationYearCollection = CollectionHandler.Get('graduationYearCollection')

    return (<>
        <Box className={localClasses.filterWrapper}>
            <MultipleSelectFilter
                type='languagePreferences'
                collection={languageOptions}
                filters={filters}
                setFilters={handleFilterChange}
                width={500}
                filterTextId='search_bar.language'
            />
            <MultipleSelectFilter
                type='provinces'
                collection={provinceCollection}
                filters={filters}
                setFilters={handleFilterChange}
                width={500}
                filterTextId='search_bar.provinces'
            />
            <MultipleSelectFilter
                type='studyCycles'
                collection={studyCycleCollection}
                filters={filters}
                setFilters={handleFilterChange}
                width={500}
                filterTextId='search_bar.study_cycles'
            />
            <MultipleSelectFilter
                type='graduationYears'
                collection={graduationYearCollection}
                filters={filters}
                setFilters={handleFilterChange}
                width={500}
                filterTextId='search_bar.graduation_year'
            />
            <MultipleSelectFilter
                type='studyClusters'
                collection={studyFieldClustersCollection}
                filters={filters}
                setFilters={handleFilterChange}
                width={500}
                filterTextId='search_bar.study_clusters'
            />
            <MultipleSelectFilter
                type='studyFields'
                collection={studyFieldCollection}
                filters={filters}
                setFilters={handleFilterChange}
                width={500}
                filterTextId='search_bar.study_fields'
            />
            <ResetFilters reset={reset} />
            {children}
        </Box>
    </>)
}

export default AdminDataFilters
