import React, { useState, useEffect } from 'react'
import DashboardAPI from 'api/DashboardAPI'
import KotCard from 'cards/KotCard/KotCard'
import RecommendedItemsContainer from 'components/RecommendedItemsContainer/RecommendedItemsContainer'
import SimilarItemsView from 'components/SimilarItemsView/SimilarItemsView'
import RouteMap from 'assets/RouteMap'
import CollectionHandler from 'assets/CollectionHandler.js'

const RecommendedKotsForUser = (props) => {
    const { user, textValues, location, fullWidth } = props
    const [recommendedKots, setRecommendedKots] = useState([])
    const cityCollection = CollectionHandler.Get('cityCollection')

    if (user.type !== 'student') return null

    useEffect(() => {
        getRecommendedKots()
    }, [user, user.school_location])

    const getRecommendedKots = () => {
        const data = {
            user_id: user.id,
            amount: 3
        }

        DashboardAPI.GetRecommendedKots(data).then((response) => {
            setRecommendedKots(response.data.recommended_kots)
        })
    }

    const moreItemsURL = () => {
        if (location) {
            const foundCity = cityCollection.find(city => Object.values(city).includes(location))
            if (foundCity) {
                return RouteMap.Tag('kot', 'cityCollection', foundCity.value_name)
            }
        }

        return RouteMap.Index('kot')
    }

    if (recommendedKots.length === 0) return null

    return (<>
        {fullWidth &&
            <SimilarItemsView
                type='kot'
                fullWidth
                items={recommendedKots}
                userType={user?.type}
                titleId='personal_dashboard.recommend_jobs.title.recommended_kots'
                moreItemsId='personal_dashboard.recommend_jobs.sub_title.recommended_kots'
                textValues={textValues}
                baseItem={{ type: 'kot', address: { city: user.school_location, zip: user.school_or_campus_zip } }}
            />
        }
        {!fullWidth &&
            <RecommendedItemsContainer
                moreItemsURL={moreItemsURL()}
                user={user}
                type='kot'
                titleId='personal_dashboard.recommend_jobs.title.recommended_kots'
                subTitleId='personal_dashboard.recommend_jobs.sub_title.recommended_kots'
                textValues={textValues}
                recommendedItemType='kot'
                key='recommended_kots'
            >
                {recommendedKots.map((recommendedKot) => {
                    return (
                        <KotCard
                            key={`job-card-${recommendedKot.id}-kot`}
                            kot={recommendedKot}
                            userType={user.type}
                        />
                    )
                })}
            </RecommendedItemsContainer>
        }
    </>)
}
export default RecommendedKotsForUser
