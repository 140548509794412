import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    container: {
        width: 'fit-content',
        background: 'var(--beige)',
        borderRadius: '4px',
        padding: '12px',
        '& label': {
            fontSize: '14px'
        }
    }
}))
