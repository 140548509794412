import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    descriptionContainer: {
        paddingBottom: '22px'
    },
    description: {
        color: theme.palette.fg.black,
        fontSize: '16px',
        lineHeight: '18px',
        '& h1, h2, h3': {
            fontSize: 16
        }
    },
    accordion: {
        '&::before': {
            display: 'none'
        }
    },
    accordionSummary: {
        minHeight: 0,
        padding: '0px',
        color: theme.palette.fg.black,
        fontSize: '16px',
        lineHeight: '18px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        columnGap: '8px',
        '&:hover $accordionSummaryContent h2': {
            transition: 'all 0.3s ease',
            color: theme.palette.fg.purple
        },
        '&:hover svg': {
            transition: 'all 0.3s ease',
            color: `${theme.palette.fg.purple} !important`
        }
    },
    accordionSummaryContent: {
        display: 'block',
        margin: '0px',
        flexGrow: 'unset'
    },
    accordionSummaryTitle: {
        color: theme.palette.fg.black,
        fontSize: '16px',
        lineHeight: '18px'
    },
    accordionSummaryIconButton: {
        margin: '0px',
        padding: '0px'
    },
}))
