import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    containerBox: {
        display: 'flex',
        width: '100%',
        borderRadius: 20,
        backgroundColor: 'var(--background-purple)',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            borderRadius: 0,
            marginBottom: 60
        }
    },
    percentageBox: {
        padding: 32,
        paddingRight: 0,
        [theme.breakpoints.down('sm')]: {
            padding: '32px 12px 20px 12px',
            display: 'flex',
            justifyContent: 'center',
            gap: 12
        }
    },
    contentBox: {
        padding: '24px 32px',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: '0 12px 32px 12px'
        }
    },
    percentageNumber: {
        fontFamily: 'GreedStandard-Heavy',
        maxWidth: 140,
        color: 'white',
        fontSize: 36,
        marginBottom: 6,
        lineHeight: 'normal',
        [theme.breakpoints.down('sm')]: {
            width: 'unset',
            fontSize: 56
        }
    },
    percentageText: {
        width: 120,
        color: 'white',
        fontSize: 16,
        lineHeight: '20px',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    formBox: {
        color: 'white'
    },
    slider: {
        marginBottom: 12,
        '& .MuiSlider-rail': {
            color: 'white',
            height: 8,
            borderRadius: 100
        },
        '& .MuiSlider-track': {
            width: 'calc(100% + 5px)',
            height: 8,
            borderRadius: '20px 0 0 20px',
            backgroundColor: 'black'
        },
        '& .MuiSlider-mark': {
            display: 'none'
        }
    },
    sliderThumb: {
        position: 'absolute',
        minWidth: 128,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        height: '32px !important',
        paddingLeft: 12,
        paddingRight: 12,
        border: '2px solid black',
        borderRadius: 100,
        marginTop: '-12px',
        '&:focus-visible': {
            outline: 'none'
        }
    },
    sliderThumbEnd: {
        left: '76% !important',
        right: 0
    },
    title: {
        color: 'white',
        marginBottom: 12
    },
    text: {
        color: 'white',
        fontSize: '16px !important',
        marginBottom: 12
    },
    startStepButtonBox: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 24
    },
    textField: {
        width: '100%',
        backgroundColor: 'white',
        color: 'black',
        fontSize: 16,
        padding: '14px 24px',
        borderRadius: 80,
        border: 'solid black 2px',
        boxSizing: 'border-box',
        '&:hover': {
            boxShadow: `4px 4px 0px 0px ${theme.palette.bg.black}`,
            border: `2px solid ${theme.palette.fg.black}`,
            backgroundColor: theme.palette.bg.white
        }
    },
    textFieldError: {
        boxShadow: 'inset 0px 0px 0px 2px red',
        '& .MuiInputBase-input::placeholder': {
            color: 'red !important'
        }
    },
    errorText: {
        color: 'red !important'
    },
    textFieldInput: {
        fontSize: 16,
        padding: 0,
        '&::placeholder': {
            color: 'var(--dark-beige)',
            opacity: '1 !important'
        }
    },
    nameInputBox: {
        display: 'flex',
        gap: 12
    }
}))
