import React from 'react'
import KotMessageUserCard from '../../MessagesCard/KotMessageUserCard'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import IconFactory from 'icons/IconFactory/IconFactory'
import { FormattedMessage } from 'react-intl'

export default class IndexByFocus extends React.Component {
    render () {
        const candidacyCard = this.props.candidacies.map((candidacy) => {
            return (
                <KotMessageUserCard
                    key={`message-user-card-${candidacy.title}`}
                    candidacy={candidacy}
                    switchIndexType={this.props.switchIndexType}
                    itemFocus={this.props.itemFocus}
                    itemType={this.props.itemType}
                />
            )
        })

        return (
            <div>
                <div className="kot-owner-subheader" onClick={this.props.switchIndexType}>
                    <Button
                        startIcon={<IconFactory icon='back'/>}
                        style={{ width: '100%', height: '100%', justifyContent: 'flex-start', textAlign: 'left' }}
                    >
                        {this.props.itemFocus}
                    </Button>
                </div>
                <div className="kot-owner-index-main">
                    {candidacyCard}
                    {!this.props.lastPage &&
                        <Box m={1}>
                            <Button
                                color={'primary'}
                                variant={'contained'}
                                fullWidth={true}
                                onClick={this.props.loadMore}
                            >
                                <FormattedMessage id="item_index.show_more_button" />
                            </Button>
                        </Box>
                    }
                </div>

            </div>

        )
    }
}
