import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    navigationButton: {
        padding: '9px 14px',
        textAlign: 'center',
        textTransform: 'capitalize',
        borderRadius: '8px',
        height: '36px'
    },
    buttonText: {
        color: 'white'
    },
    navigationButtonNonActive: {
        '&:hover': {
            backgroundColor: 'var(--black-over)'
        }
    },
    navigationButtonActive: {
        backgroundColor: 'var(--red)',
        '& div': {
            color: 'white'
        },
        '&:hover': {
            backgroundColor: 'var(--red)'
        }
    }
}))
