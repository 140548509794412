/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'

/* eslint-disable-next-line no-unused-vars */
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'

import FilledButton from 'buttons/FilledButton/FilledButton'
import RouteMapLink from '../../../../shared/wrappers/RouteMapLink/RouteMapLink'
import RouteMap from 'assets/RouteMap'



const KotPopup = (props) => {
    const [kotMessage, setKotMessage] = useState('')
    const [sending, setSending] = useState(false)
    const [sent, setSent] = useState(false)
    const [messageUrl, setMessageUrl] = useState('#')

    useEffect(
        () => {
            props.getPopupContent({
                popupTitle: <FormattedMessage id='kot_popup.contact_kot_owner' />,
                popupActionButton: <FormattedMessage id='kot_popup.send_message_button' />,
                actionPopup: () => sendMessageAction(kotMessage)
            })
        }, [kotMessage]
    )

    const sendMessageAction = (message) => {
        setSending(true)
        axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
        axios({
            url: '/api/v1/users/candidacies',
            method: 'post',
            data: {
                id: props.kot.id,
                type: 'kot',
                motivation_letter: kotMessage
            }
        }).then((response) => {
            window.location.href = RouteMap.Page(response.data.chat_url)
            props.getPopupContent({ noAction: true })
        })
    }

    const handleKotMessageChange = (event) => {
        setKotMessage(event.target.value)
    }

    return (
        <Box>
            {props.kot.show_phonenumber &&
                <Box mb={3}>
                    <FormattedMessage id='kot_popup.phone_number' />
                    {props.kot.phone_number}
                </Box>
            }
            {sent || sending ||
                <TextField
                    id='outlined-multiline-static'
                    label={<FormattedMessage id='kot_popup.message' />}
                    multiline
                    rows={8}
                    value={kotMessage}
                    onChange={handleKotMessageChange}
                    variant='outlined'
                    fullWidth
                />
            }
            {sending &&
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress color='primary' style={{ width: '80px', height: '80px' }}/>
                </Box>
            }
            {sent &&
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <RouteMapLink page={messageUrl}>
                        <FilledButton
                            name={<FormattedMessage id='kot_popup.message_sent' />}
                            color='secondary'
                        />
                    </RouteMapLink>
                </Box>
            }
        </Box>
    )
}

export default KotPopup
