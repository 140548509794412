import React from 'react'

const StarryEyesSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.95 86" {...props}>
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path d="M46,6A40,40,0,1,0,86,46h0A40,40,0,0,0,46,6Z" />
                <path d="M40,0A40,40,0,1,0,80,40h0A40,40,0,0,0,40,0Z" />
                <path className="cls-1" fill="white" fillRule="evenodd" d="M72,40A32,32,0,1,1,40,8,31.9,31.9,0,0,1,72,40ZM21.46,24.66l-.46.08,0,0,0,0h0c.44.45.87.9,1.31,1.34l.6.61,1.78,1.81a.15.15,0,0,1,.06.14c0,.29-.08.59-.13.89l-.06.44c-.12.8-.23,1.6-.34,2.4l-.18,1.19,0,.28,0,.09.06,0,.07,0,.33-.17,4.39-2.14a.19.19,0,0,1,.18,0l4.45,2.17.3.15h.06l0,0v0h0l-.13-.88c-.08-.58-.16-1.16-.25-1.74l-.12-.84-.24-1.69a.26.26,0,0,1,.09-.26l3-3,.64-.65.06-.07,0,0-.23,0-.49-.09-.58-.1-4-.69a.17.17,0,0,1-.15-.1c-.82-1.54-1.65-3.07-2.47-4.6,0,0,0-.05,0-.08l0,0,0,0a.16.16,0,0,1,0,.07l-.38.73-2.07,3.87a.19.19,0,0,1-.15.11l-.58.1-.28,0-3,.52ZM41,24.74l.46-.08,1-.17,3-.52.28,0,.58-.1a.19.19,0,0,0,.15-.11l2.07-3.87.38-.73A.16.16,0,0,0,49,19l0,0,0,0s0,.05,0,.08c.82,1.53,1.65,3.06,2.47,4.6a.17.17,0,0,0,.15.1l4,.69.58.1.49.09.23,0,0,0-.06.07-.64.65-3,3a.26.26,0,0,0-.09.26l.24,1.69h0l.12.84c.09.58.17,1.15.25,1.73l.13.89V34l0,0h-.06l-.3-.15-4.45-2.17a.19.19,0,0,0-.18,0l-4.39,2.14-.33.17-.07,0-.06,0,0-.09,0-.28.18-1.2c.11-.79.23-1.59.34-2.39l.06-.44h0c.05-.29.09-.59.13-.88a.15.15,0,0,0-.06-.14c-.59-.6-1.18-1.21-1.78-1.81h0l-.6-.61-1.31-1.33h0l0,0ZM60.23,42.51H18.15a21.18,21.18,0,0,0,24.6,18.65C53.43,59.39,59.86,50.15,60.23,42.51Z" />
            </g>
        </g>
    </svg>
)

export default StarryEyesSVG
