import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '68px',
        height: '36px',
        borderRadius: '50px',
        padding: '0px'
    },

    switchBase: {
        padding: '0px',
        left: '4px',
        top: '18px',
        transform: 'translateY(-50%)',
        width: '26px',
        height: '26px',
        transition: 'all 0.3s ease',
        '&$checked': {
            transform: 'translate(33px, -50%)',
            color: theme.palette.fg['white'],
            '& + $track': {
                backgroundColor: theme.palette.bg['purple'],
                opacity: 1,
                border: 'none'
            },
        },
    },

    input: {
        left: 'unset',
        width: '26px',
        height: '26px'
    },

    thumb: {
        width: '26px',
        height: '26px'
    },

    track: {
        borderRadius: '50px',
        border: `1px solid ${theme.palette.fg['greySlate']}`,
        backgroundColor: theme.palette.bg['greyPale'],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border'])
    },

    checked: {},

    focusVisible: {},
}))
