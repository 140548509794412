import React from 'react'

import { useStyles } from './Tag_style'
import { Box } from '@material-ui/core'

import IconFactory from 'icons/IconFactory/IconFactory'

const Tag = (props) => {
    const classes = useStyles()

    const { item, isSelected, handleClick } = props

    return (
        <Box
            className={`${classes.tag} ${isSelected && classes.selectedTag}`}
            key={`selected-location-${item.name}`}
            onClick={() => handleClick(item)}
        >
            {isSelected && <IconFactory icon='cross' fontSize='12px' />}
            {item.name}
        </Box>
    )
}

export default Tag
