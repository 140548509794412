import React, { useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from '../Searchbar_v2_style'
import { Box, Hidden, Typography, Container } from '@material-ui/core'

import CollectionHandler from 'assets/CollectionHandler.js'
import QuickFilterArrows from '../shared/QuickFilterArrows/QuickFilterArrows'
import SVGFactory from 'icons/SVGFactory/SVGFactory'

const InternshipQuickFilters = (props) => {
    const classes = useStyles()
    const { filters, setFilters } = props
    const quickFilterInternshipCollection = CollectionHandler.Get('quickFilterInternshipCollection')
    const quickFiltersRef = useRef(null)
    const [isHoverText, setIsHoverText] = useState(null)

    const setQuickFilter = (selectedQuickFilter) => {
        if (selectedQuickFilter === filters.quickFilter) {
            setFilters({
                ...filters,
                quickFilter: ''
            })
        } else {
            setFilters({
                ...filters,
                quickFilter: selectedQuickFilter
            })
        }
    }

    return (
        <Container className={classes.quickFilterContainer} maxWidth='lg' disableGutters>
            <Box className={classes.quickFiltersInner}>
                <Box className={classes.quickFilters} ref={quickFiltersRef}>
                    {quickFilterInternshipCollection.map(quickFilterOption => {
                        const isSelected = filters.quickFilter === quickFilterOption.value
                        return (
                            <Box
                                key={quickFilterOption.value}
                                className={classes.quickFilterOption}
                                onClick={() => setQuickFilter(quickFilterOption.value)}
                            >
                                <Box className={`${classes.containerIcon} ${isSelected ? classes.selected : classes.notSelected} ${isHoverText === quickFilterOption.value ? classes.selected : classes.notSelected}  ${quickFilterOption.value === 'impact' && classes.containerIconImpact} `} >
                                    <SVGFactory
                                        name={quickFilterOption.icon}
                                        height="25" width="24"
                                    />
                                </Box>
                                <Typography onMouseOver={() => setIsHoverText(quickFilterOption.value)} onMouseLeave={() => setIsHoverText(null)} variant='h3' component='p' className={classes.quickFilterText}>
                                    <FormattedMessage id={`quick_filters.${quickFilterOption.value}`} />
                                </Typography>
                            </Box>
                        )
                    })}
                </Box>
                <Hidden smDown>
                    <QuickFilterArrows quickFiltersRef={quickFiltersRef} />
                </Hidden>
            </Box>
        </Container>
    )
}

export default InternshipQuickFilters
