/* eslint-disable react/prop-types */
import React from 'react'
import InfoLineView from './InfoLineView/InfoLineView'
import CollectionHandler from 'assets/CollectionHandler.js'

const MobilityLines = (props) => {
    const { job } = props

    return <>
        {job.student_request.includes('bike_required') &&
            <InfoLineView
                icon='bicycle'
                text={CollectionHandler.Translate('studentJobAdditionalRequestsCollection', 'bike_required')}
            />
        }
        {job.student_request.includes('car_required') &&
        <InfoLineView
            icon='car'
            text={CollectionHandler.Translate('studentJobAdditionalRequestsCollection', 'car_required')}
        />
        }
        {job.student_request.includes('driving_license') &&
        <InfoLineView
            icon='licence'
            text={CollectionHandler.Translate('studentJobAdditionalRequestsCollection', 'driving_license')}
        />
        }
    </>
}

export default MobilityLines
