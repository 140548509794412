import React from 'react'

import IndexEmployerByOffer from './IndexEmployerByOffer'
import IndexByMsg from '../MessagesMain/components/IndexByMsg'
import IndexByFocus from '../MessagesMain/components/IndexByFocus'

export default class MessagesEmployerIndex extends React.Component {
    render () {
        switch (this.props.indexType) {
        case 'byOffer':
            return (
                <IndexEmployerByOffer
                    openItem={this.props.openItem}
                    offers={this.props.offers}
                />
            )
        case 'byMsg':
            return (
                <IndexByMsg
                    loadMore={this.props.loadMoreByMsg}
                    loading={this.props.loading}
                    lastPage={this.props.lastPage}
                    candidacies={this.props.candidacies}
                />
            )
        case 'byFocus':
            return (
                <IndexByFocus
                    switchIndexType={() => {this.props.switchIndexType('byOffer')}}
                    itemFocus={this.props.itemFocus}
                    itemType={this.props.itemType}
                    candidacies={this.props.candidacies}
                    loadMore={this.props.loadMore}
                    lastPage={this.props.lastPage}
                />
            )
        }
    }
}
