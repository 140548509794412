/* eslint-disable react/prop-types */

import React from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './FilledButton_style'

const FilledButton = (props) => {
    const { children, type, forceWrap, color, disabled, style, fullWidth, onMouseEnter, onMouseLeave, newStyle, newStyleWhite, className, onClick, href, name, typoVariant } = props
    const classes = useStyles()

    const getClassName = () => {
        if (newStyle) {
            if (newStyleWhite) {
                return `${classes.newStyleButtonWhite} ${classes.newStyleButton} ${className}`
            } else {
                return `${classes.newStyleButton} ${className}`
            }
        } else {
            return className
        }
    }

    return (
        <Button
            {...props}
            variant='contained'
            color={!disabled ? color : ''}
            className={getClassName()}
            style={{ ...style, textTransform: 'none' }}
            type={type}
            disabled={disabled}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            fullWidth={fullWidth}
            href={href}
        >
            {children}
            {name &&
                <Typography variant={typoVariant} noWrap={!forceWrap}>
                    {name}
                </Typography>
            }
        </Button>
    )
}

FilledButton.defaultProps = {
    color: 'primary',
    typoVariant: 'body2',
    style: {},
    className: {}

}

export default FilledButton
