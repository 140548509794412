import { createContext, useState } from 'react'
import TagManager from 'react-gtm-module'
import Bugsnag from '@bugsnag/js'
import RouteMap from 'assets/RouteMap.js'
import SessionAPI from 'api/SessionAPI'
import URLParamsHandler from 'assets/URLParamsHandler.js'

export const ApplyProcessContext = createContext(null)

export const useApplyProcessContext = (props) => {
    const { job, user, unavailable } = props
    const [showApplyModal, setShowApplyModal] = useState(user?.type === 'student' && URLParamsHandler.loadProcessFromURL() === 'apply')
    const [loginRegisterParams, setLoginRegisterParams] = useState('')

    const studentHasApplied = () => {
        if (!user) return

        return user.type === 'student' && job.alreadyApplied && !job.application_url
    }

    const applyDisabled = () => {
        if (unavailable) return true
        if (user) {
            if (['employer', 'kot_owner'].includes(user.type)) {
                return true
            } else if (studentHasApplied()) {
                return true
            }
        }
        return false
    }

    const openApplyPopup = () => {
        setShowApplyModal(true)
        window.scrollTo(0, 0)
    }

    const toggleApplyPopup = () => {
        setShowApplyModal(!showApplyModal)
        window.scrollTo(0, 0)
    }

    const initiateApplication = (id) => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'click-apply',
                language: RouteMap.GetLocaleFromUrl(),
                job_type: job.type
            }
        })
        if (!user) {
            if (!loginRegisterParams) {
                SessionAPI.GetAuthentificationData()
                    .then((response) => {
                        setLoginRegisterParams(response.data)
                        openApplyPopup()
                    })
                    .catch(function (error) {
                        alert('An error has occurred')
                        Bugsnag.notify(error)
                    })
            } else {
                openApplyPopup()
            }
        } else {
            openApplyPopup()
        }
    }

    const onClick = () => {
        if (job.redirect_to_company_website_without_apply) {
            return () => { window.open(job.application_url, '_blank') }
        } else if (job.alreadyApplied && job.application_url) {
            return () => { window.location.href = job.application_url }
        } else {
            return initiateApplication
        }
    }

    const redirectToEditJob = () => {
        window.location.href = user?.admin
            ? editJobAdminURL()
            : editJobPlatformUrl()
    }

    const editJobAdminURL = () => {
        return `/en/admin/${job.type}/${job.id}/edit`
    }

    const editJobPlatformUrl = () => {
        return RouteMap.Edit(props.job.type, props.job.id)
    }

    return {
        applyDisabled,
        studentHasApplied,
        toggleApplyPopup,
        onClick,
        redirectToEditJob,
        loginRegisterParams,
        showApplyModal
    }
}
