import axios from 'axios'
import RouteMap from '../assets/RouteMap.js'

import {
    DASHBOARD_USER_INFO_API,
    DASHBOARD_RECOMMENDED_JOBS_API,
    DASHBOARD_RECOMMENDED_ARTICLES_API,
    DASHBOARD_RECENTLY_VIEWED_JOBS_API,
    DASHBOARD_RECRUITING_COMPANIES_API,
    DASHBOARD_FAVOURITES_API,
    DASHBOARD_RECOMMENDED_KOTS_API,
    DASHBOARD_LAST_CHANCE_JOBS_API
} from './env'

const GetUserInfo = (data) => {
    return axios({
        url: DASHBOARD_USER_INFO_API,
        method: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

const GetRecommendedJobs = (data) => {
    return axios({
        url: DASHBOARD_RECOMMENDED_JOBS_API,
        method: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

const GetRecommendedArticles = (data) => {
    return axios({
        url: DASHBOARD_RECOMMENDED_ARTICLES_API,
        method: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

const GetRecentlyViewedJobs = (data) => {
    return axios({
        url: DASHBOARD_RECENTLY_VIEWED_JOBS_API,
        method: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

const GetLastChanceJobs = (data) => {
    return axios({
        url: DASHBOARD_LAST_CHANCE_JOBS_API,
        method: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

const GetRecruitingCompanies = (data) => {
    return axios({
        url: DASHBOARD_RECRUITING_COMPANIES_API,
        method: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

const GetFavoriteJobs = (data) => {
    return axios({
        url: DASHBOARD_FAVOURITES_API,
        method: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

const GetRecommendedKots = (data) => {
    return axios({
        url: DASHBOARD_RECOMMENDED_KOTS_API,
        method: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

export default { GetUserInfo, GetRecommendedJobs, GetRecommendedArticles, GetRecentlyViewedJobs, GetRecruitingCompanies, GetFavoriteJobs, GetRecommendedKots, GetLastChanceJobs }
