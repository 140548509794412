import React from 'react'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import FilledButton from 'buttons/FilledButton/FilledButton'

import { useStyles } from './PromotionShowSamsung_styles'
import { FormattedMessage } from 'react-intl'

const PromotionShowSamsung = (props) => {
    const { promotion } = props
    const classes = useStyles()

    return (
        <Box className={classes.container}>
            <Box>
                <Typography variant="h1">
                    {promotion.title}
                </Typography>
                <Typography
                    dangerouslySetInnerHTML={{ __html: promotion.promo }}
                    component='div'
                />
            </Box>
            <Typography
                dangerouslySetInnerHTML={{ __html: promotion.content }}
                component='div'
            />
            <Box mt={5} mb={2} className={classes.buttons}>
                <RouteMapLink target="_blank" redirectionUrl={promotion.link}>
                    <FilledButton
                        color='secondary'
                        name={<FormattedMessage id='promotions_for_student.samsung.webshop'/>}
                    />
                </RouteMapLink>
            </Box>
            <Box className={classes.warning}>
                <FormattedMessage id='promotions_for_student.samsung.warning'/>
            </Box>
        </Box>
    )
}

export default PromotionShowSamsung
