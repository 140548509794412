import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './CompanySectorMultipleSelect_style'
import { MenuItem, FormControl, ListItemText, FormHelperText, Select, Checkbox, Input, InputLabel, useTheme } from '@material-ui/core'

import CollectionHandler from 'assets/CollectionHandler'
import IconFactory from 'icons/IconFactory/IconFactory'

const CompanySectorMultipleSelect = (props) => {
    const { values, name, handleChange, error } = props
    if (!values) return null
    const theme = useTheme()
    const classes = useStyles()
    const valueCollection = CollectionHandler.Get('sectorCollection')

    const getTranslatedValueArray = () => {
        let result = values.map((valueString) => {
            return CollectionHandler.Translate('sectorCollection', valueString)
        }).join(', ')

        if (result.length > 80) {
            result = result.substring(0, 80) + '...'
        }
        return result
    }

    return (
        <FormControl>
            <InputLabel htmlFor="company-sector" className={classes.inputLabel}><FormattedMessage id="company_profile.company_page_description.description_popup.sector_v2" /></InputLabel>
            <Select
                id="company-sector"
                multiple
                value={values}
                name={name}
                onChange={handleChange}
                renderValue={getTranslatedValueArray}
                input={<Input classes={{ input: classes.input, underline: classes.inputUnderline }} />}
                MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                    },
                    PaperProps: {
                        style: {
                            maxHeight: 350,
                            width: 500
                        }
                    }
                }}
                IconComponent={() => open ?
                    <IconFactory icon='up-solid' fontSize='12px' color={theme.palette.fg['black']} className={classes.chevronIcon} /> :
                    <IconFactory icon='down-solid' fontSize='12px' color={theme.palette.fg['black']} className={classes.chevronIcon} />}
            >
                {valueCollection.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                        <Checkbox
                            icon={<IconFactory icon='checkbox-false' />}
                            checkedIcon={<IconFactory icon='checkbox-true' color={theme.palette.fg['purple']} />}
                            checked={values.indexOf(item.value) > -1}
                            color='primary'
                        />
                        <ListItemText
                            className={classes.inputBase}
                            primaryTypographyProps={{ variant: 'body2' }}
                            primary={item.name}
                        />
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText className={classes.errorText}>{error}</FormHelperText>
        </FormControl>
    )

}

export default CompanySectorMultipleSelect
