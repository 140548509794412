import CollectionHandler from 'assets/CollectionHandler.js'
import URLHandler from 'assets/URLHandler.js'
import AnalyticsHelper from 'assets/AnalyticsHelper'

const BLANK_FORMATION = {
    id: '',
    field_of_study: null,
    field_of_study_id: '',
    other_field_of_study: '',
    specialization: '',
    campus: '',
    start_year: null,
    end_year: null,
    showEndYear: false,
    deleted: false,
    other_school: '',
    study_type: '',
    otherStudy: '',
    school: null,
    campuses: '',
    specializationList: '',
    additionalInfoError: false,
    additionalInfoErrorMessage: false,
    study_cycle: '',
    high_school_year: 0,
    highSchoolStudyFields: []
}

const IsValidDate = (date) => {
    return (date instanceof Date && !isNaN(date))
}

const getTranslatedOther = () => {
    const other = { value: 'Other', fr: 'Autre', nl: 'Andere', en: 'Other', id: -1 }
    other.name = other[URLHandler.GetLocaleFromUrl()]

    return other
}

const OTHER = getTranslatedOther()

const AddOtherToCollection = (collection) => {
    if (!collection.some((obj) => obj.value === 'Other')) {
        collection.push(OTHER)
    }
}

const InitializeExperiences = (experiences) => {
    if (!experiences || !experiences[0] || experiences.length === 0) return [{ ...BLANK_FORMATION }]

    const highSchoolStudyTypeCollection = CollectionHandler.Get('highSchoolStudyTypeCollection')
    const schoolCollection = CollectionHandler.Get('schoolCollection')
    const studyFieldCollection = CollectionHandler.Get('studyFieldCollection')
    const studyTypeCollection = CollectionHandler.Get('studyTypeCollection')
    const initializedExperiences = []

    experiences.map((experience) => {
        let studytype = ''
        let highSchoolStudyFields = ''
        if (experience.study_cycle === 'high_school') {
            studytype = experience.study_type
            const studyTypeFound = highSchoolStudyTypeCollection.find(
                element => element.value === experience.study_type
            )
            if (studyTypeFound) {
                highSchoolStudyFields = studyTypeFound.field_of_studies
            }
        } else if (experience.study_cycle === 'higher_education') {
            const studyTypeFound = studyTypeCollection.find(element => element.value === experience.study_type)
            studytype = studyTypeFound ? studyTypeFound.value : ''
        }
        let shoolId = null
        let studyField = null
        let campuses = ''
        schoolCollection.forEach(
            (school) => {
                if (experience.school && school.name === experience.school.name) {
                    shoolId = school
                    if (shoolId.campuses) {
                        campuses = school.campuses.sort((a, b) => a.location.localeCompare(b.location))
                    }
                }
            }
        )

        if (!experience.school && experience.other_school) {
            shoolId = OTHER
        }

        if (experience.field_of_study?.name === 'Other') {
            studyField = experience.field_of_study
        } else {
            studyFieldCollection.forEach((field) => {
                if (experience.field_of_study && field.value === experience.field_of_study.name) {
                    studyField = field
                }
            })
        }

        initializedExperiences.push({
            id: experience.id,
            updated_at: experience.updated_at,
            created_at: experience.created_at,
            field_of_study: studyField,
            specialization: experience.field_of_study && experience.field_of_study.specialization ? experience.field_of_study.specialization : '',
            specializationList: studyField && studyField.specializations ? studyField.specializations : '',
            campuses: campuses,
            start_year: new Date(experience.start_date),
            end_year: new Date(experience.end_date),
            showEndYear: !!experience.end_date,
            deleted: false,
            other_school: experience.other_school ? experience.other_school : '',
            study_type: studytype || '',
            otherStudy: experience.otherStudy ? experience.otherStudy : '',
            school_location: experience.school_location || '',
            school_location_name: experience.school_location_name || '',
            school: shoolId,
            campus: experience.campus ? experience.campus.name ? `${experience.campus.location}|${experience.campus.name}` : experience.campus.location : '',
            study_cycle: experience.study_cycle ? experience.study_cycle : '',
            study_domain: experience.study_domain ? experience.study_domain : '',
            other_field_of_study: experience.other_field_of_study ? experience.other_field_of_study : '',
            high_school_year: experience.high_school_year ? experience.high_school_year : 0,
            highSchoolStudyFields: highSchoolStudyFields
        })
    })

    return initializedExperiences
}

const PrepareExperiencesDataToSave = (experiences) => {
    return experiences.map((experience) => ({
        ...experience,
        field_of_study: experience.field_of_study ? experience.field_of_study.value : '',
        school: experience.school ? experience.school.name : '',
        start_year: experience.start_year ? new Date(experience.start_year).getFullYear() : null,
        end_year: experience.end_year ? new Date(experience.end_year).getFullYear() : null
    }))
}

const SendExperienceUpdatedGTMEvent = (hasExperience, analObject) => {
    const experienceUpdatedOrCreated = hasExperience ? 'updated' : 'created'

    AnalyticsHelper.sendGTMEvent(
        'Experience Updated', 
        { ...analObject, update_type: experienceUpdatedOrCreated }
    )
}

export default {
    InitializeExperiences,
    PrepareExperiencesDataToSave,
    AddOtherToCollection,
    IsValidDate,
    SendExperienceUpdatedGTMEvent,
    OTHER,
    BLANK_FORMATION
}
