import React from 'react'
import { Box } from '@material-ui/core'
import StudentJobSearchStatusChip from './StudentJobSearchStatusChip'
import StudentJobSearchStatusModal from './StudentJobSearchStatusModal'
import { useStyles } from '../StudentJobSearchStatus_style'

const StudentJobSearchStatusDefaultView = (props) => {
    const { statusConfig, modalOpen, handleModalClose, handleClick, simple, black, dashboard } = props
    const classes = useStyles()

    return (<>
        <Box className={`${simple ? classes.simpleChips : classes.chips} ${black && classes.blackChips}`}>
            {Object.keys(statusConfig).map(key => {
                return (
                    <StudentJobSearchStatusChip
                        key={key}
                        keyName={key}
                        value={statusConfig[key]}
                        handleClick={handleClick}
                        simple={simple}
                        black={black}
                    />
                )
            })}
        </Box>
        <StudentJobSearchStatusModal
            modalOpen={modalOpen}
            handleModalClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            dashboard={dashboard}
        />
    </>)
}

export default StudentJobSearchStatusDefaultView
