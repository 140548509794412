import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    avatar: {
        width: 86,
        height: 86,
        backgroundColor: 'white',
        border: 'solid black 2px'
    },
    avatarBox: {
        cursor: 'pointer',
        marginRight: 16
    },
    fab: {
        width: '27px !important',
        height: '27px !important',
        position: 'relative',
        '& svg': {
            position: 'absolute',
            top: 6,
            left: 6,
            fontSize: '14px !important'
        }
    },
    fullName: {
        fontSize: 16,
        lineHeight: '20px'
    },
    email: {
        fontSize: 12,
        wordBreak: 'break-all'
    },
    identityBox: {
        display: 'flex'
    },
    nameBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    }
}))
