import React from 'react'

import { Image, Transformation } from 'cloudinary-react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './EmployerFindOutMoreBanner_style'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import AnalyticsHelper from 'assets/AnalyticsHelper'
import Button from 'shared/components/Button/Button'
import RouteMap from 'assets/RouteMap'

const EmployerFindOutMoreBanner = (props) => {
    const classes = useStyles()
    const { imageId, context } = props

    const handleContactClick = () => {
        AnalyticsHelper.sendGTMEvent(
            'Clicked Contact Us',
            {
                context: context
            }
        )
        window.location.href = RouteMap.Page('contact-employer')
    }

    return (
        <Box className={classes.findOutMoreContainer}>
            <Container maxWidth='lg' className={classes.containerOverride}>
                <Box className={classes.findOutMoreText}>
                    <Typography variant='h6' component='h2' className={classes.title}>
                        <FormattedMessage id="landing_employer.want_to_know_more.title" />
                    </Typography>
                    <Typography variant='body1' component='p' className={classes.subtitle}>
                        <FormattedMessage id="landing_employer.want_to_know_more.subtitle" />
                    </Typography>
                    <Button
                        variant='cta-primary'
                        className={classes.customButton}
                        onClick={handleContactClick}
                    >
                        <FormattedMessage id='page_footer_navbar_data.footer.contact_us' />
                    </Button>
                </Box>
                <Box className={classes.findOutMoreImage}>
                    <Image
                        cloudName='studentbe'
                        publicId={imageId}
                        alt={`student.be ${imageId}.`}
                        className={classes.image}
                    >
                        <Transformation flags='progressive' fetchFormat='auto' quality='auto:best' crop='fill' />
                    </Image>
                </Box>
            </Container>
        </Box>
    )
}

export default EmployerFindOutMoreBanner
