import axios from 'axios'
import RouteMap from '../assets/RouteMap.js'
import ReactOnRails from 'react-on-rails'

import { VIDEOS_API } from './env'

const GetVideos = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: VIDEOS_API,
        method: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

export default { GetVideos }
