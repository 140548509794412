/* eslint-disable react/prop-types */
import React, { useState } from 'react'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import Field from 'inputs/Field/Field'
import MultipleSelect from 'inputs/MultipleSelect/MultipleSelect'
import CollectionHandler from 'assets/CollectionHandler'

import { FormattedMessage } from 'react-intl'

import { withStyles } from '@material-ui/core/styles'
import { useStyles } from './CompanyProfileFormDescription_style'

const TabPanel = (props) => {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box pb={3}>
                    {children}
                </Box>
            )}
        </div>
    )
}

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    }
}

const CompanyProfileFormDescription = (props) => {
    const { company, errors, handleChange, handleQuillChange, classes } = props
    const [descriptionLanguage, setDescriptionLanguage] = useState(0)

    const size = CollectionHandler.Get('companySizeCollection')

    const handleDescriptionLanguage = (_, language) => {
        setDescriptionLanguage(language)
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
                <Field
                    name="name"
                    title={<FormattedMessage id="company_profile.company_page_description.description_popup.company_name" />}
                    value={company.name}
                    handleChange={handleChange}
                    placeholder="Enter company name here"
                    error={errors?.companyName}
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <FormattedMessage id="company_profile.company_page_description.description_popup.sector">
                    {placeholder =>
                        <MultipleSelect
                            values={company.sectors}
                            handleChange={handleChange}
                            valueCollectionString={'sectorCollection'}
                            title={<FormattedMessage id="company_profile.company_page_description.description_popup.sector" />}
                            name="sectors"
                            placeholder={placeholder}
                            error={errors?.companySectors}
                        />
                    }
                </FormattedMessage>
            </Grid>
            <Grid item xs={12} md={12}>
                <FormattedMessage id="company_profile.company_page_description.description_popup.size">
                    {placeholder =>
                        <Field
                            title={<FormattedMessage id="company_profile.company_page_description.description_popup.size" />}
                            name='size'
                            placeholder={placeholder}
                            value={company.size}
                            type='hashes-select'
                            different
                            blank
                            optionSelect={size}
                            handleChange={handleChange}
                            error={errors?.companySize}
                        />
                    }
                </FormattedMessage>
            </Grid>

            <Grid item xs={12} md={6}>
                <Field
                    name="street"
                    title={<FormattedMessage id="company_profile.company_page_description.description_popup.street" />}
                    value={company.street}
                    handleChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Field
                    name="zip"
                    title={<FormattedMessage id="company_profile.company_page_description.description_popup.zip" />}
                    value={company.zip}
                    handleChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Field
                    name="city"
                    title={<FormattedMessage id="company_profile.company_page_description.description_popup.city" />}
                    value={company.city}
                    handleChange={handleChange}
                    error={errors?.companyCity}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Field
                    name="country"
                    title={<FormattedMessage id="company_profile.company_page_description.description_popup.country" />}
                    value={company.country}
                    handleChange={handleChange}
                    // error={errors?.companyCity}
                />
            </Grid>

            <Grid item xs={12} md={12}>
                <Typography variant='h4' component='h3' color='primary' style={{ margin: 0 }}>
                    <FormattedMessage id="company_profile.company_page_description.description_popup.description" />
                </Typography>
                <Tabs
                    style={{ width: '100%', margin: '20px auto' }}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    value={descriptionLanguage}
                    onChange={handleDescriptionLanguage}
                >
                    <Tab
                        label={<FormattedMessage id="company_profile.company_page_description.description_popup.languages.label_fr" />}
                        {...a11yProps(0)}
                    />
                    <Tab
                        label={<FormattedMessage id="company_profile.company_page_description.description_popup.languages.label_en" />}
                        {...a11yProps(1)}
                    />
                    <Tab
                        label={<FormattedMessage id="company_profile.company_page_description.description_popup.languages.label_nl" />}
                        {...a11yProps(2)}
                    />
                </Tabs>
                <TabPanel value={descriptionLanguage} index={0}>
                    <FormattedMessage id="company_profile.company_page_description.description_popup.languages.placeholder_fr">
                        {placeholder =>
                            <ReactQuill
                                modules={{ clipboard: { matchVisual: false }}}
                                className={classes.quillStyle}
                                placeholder={placeholder}
                                value={company.descriptionFR}
                                onChange={(quillValue) => handleQuillChange(quillValue, 'FR')}
                            />
                        }
                    </FormattedMessage>
                </TabPanel>
                <TabPanel value={descriptionLanguage} index={1}>
                    <FormattedMessage id="company_profile.company_page_description.description_popup.languages.placeholder_en">
                        {placeholder =>
                            <ReactQuill
                                modules={{ clipboard: { matchVisual: false }}}
                                className={classes.quillStyle}
                                placeholder={placeholder}
                                value={company.descriptionEN}
                                onChange={(quillValue) => handleQuillChange(quillValue, 'EN')}
                            />
                        }
                    </FormattedMessage>
                </TabPanel>
                <TabPanel value={descriptionLanguage} index={2}>
                    <FormattedMessage id="company_profile.company_page_description.description_popup.languages.placeholder_nl">
                        {placeholder =>
                            <ReactQuill
                                modules={{ clipboard: { matchVisual: false }}}
                                className={classes.quillStyle}
                                placeholder={placeholder}
                                value={company.descriptionNL}
                                onChange={(quillValue) => handleQuillChange(quillValue, 'NL')}
                            />
                        }
                    </FormattedMessage>
                </TabPanel>
            </Grid>
        </Grid>
    )
}

export default withStyles(useStyles)(CompanyProfileFormDescription)
