import React from 'react'
import { Box, Typography, TextField } from '@material-ui/core'
import StepButtons from '../StepButtons/StepButtons'
import { FormattedMessage } from 'react-intl'
import { useStyles } from '../../ProfileEditForm_style'

const ProfileEditFormStepLocationView = (props) => {
    const { step, cityRef, errors, handleChange, handleSubmit, previousStep, city, loading, highlightInputField } = props
    const classes = useStyles()

    return (
        <Box className={classes.mainStepBox}>
            <Typography variant='h1' className={`${classes.nameViewCta} ${classes.title}`}>
                <FormattedMessage id='personal_dashboard.profile_edit_form.location_step.cta' />
            </Typography>
            <Box className={classes.nameInputBox}>
                <FormattedMessage id='personal_dashboard.profile_edit_form.location_step.first_name_placeholoder'>
                    {placeholder =>
                        <TextField
                            name='city'
                            value={city}
                            className={`${classes.textField} ${(errors.city || highlightInputField) && classes.textFieldError}`}
                            onChange={handleChange}
                            InputProps={{ disableUnderline: true, classes: { input: classes.textFieldInput } }}
                            placeholder={placeholder}
                            inputRef={cityRef}
                        />
                    }
                </FormattedMessage>
            </Box>
            <StepButtons
                loading={loading}
                step={step}
                nextStep={handleSubmit}
                previousStep={previousStep}
            />
        </Box>
    )
}
export default ProfileEditFormStepLocationView
