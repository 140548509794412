/* eslint-disable react/prop-types */
import React from 'react'
import InfoLineView from './InfoLineView/InfoLineView'
import CollectionHandler from 'assets/CollectionHandler.js'

const ContractTypeLine = (props) => {
    const { job } = props
    if (!job.contract_type) return null

    return (
        <InfoLineView
            icon='clipboard'
            text={CollectionHandler.Translate('contractTypeCollection', job.contract_type)}
        />
    )
}

export default ContractTypeLine
