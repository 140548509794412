import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    main: {
        width: '100%',
        paddingBottom: '21px',
        borderBottom: `0.5px solid ${theme.palette.fg['greySlate']}`,
        overflowX: 'auto',
    },
    articleInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '11px',
        flexWrap: 'wrap',
    },
    readTimeAndDate: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '22px',
    },
    infoContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '4px'
    },
    minorText: {
        color: theme.palette.fg['black40Opacity'],
        fontSize: '10px',
        lineHeight: '15px'
    },
    articleTitle: {
        paddingBottom: '28px',
        color: theme.palette.fg['black'],
        fontSize: '16px',
        lineHeight: '18px'
    },
    chipRoot: {
        height: 'fit-content',
        width: 'fit-content',
        borderRadius: '50px',
        padding: '2px 10px',
        backgroundColor: theme.palette.bg['greyLight']
    },
    chipLabel: {
        padding: 0,
        fontFamily: 'GreedStandard-Medium',
        color: theme.palette.fg['black'],
        fontSize: '10px',
        lineHeight: '18px'
    },
}))
