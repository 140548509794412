import React from 'react'

const HeartSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.01 85" {...props}>
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
                <path
                    d="M93.83,30a27.28,27.28,0,0,0-2.45-8.67A27.67,27.67,0,0,0,83.9,12a25,25,0,0,0-8.13-4.53A27.56,27.56,0,0,0,63.05,6.28a26.21,26.21,0,0,0-12,4.93c-.34.25-.66.49-1,.74A27,27,0,0,0,29.28,6.27a26.08,26.08,0,0,0-14.39,6.78A26.88,26.88,0,0,0,8,22.69a27.77,27.77,0,0,0-2,10.7,26.32,26.32,0,0,0,.89,6.53,27,27,0,0,0,7.22,12.52L36.65,75,44,82.36l.64.61a8,8,0,0,0,10.86-.19c.13-.11.25-.23.4-.38L85.86,52.48a27.61,27.61,0,0,0,5.62-8A27,27,0,0,0,93.83,30Z" />
                <path
                    d="M87.83,24a27.28,27.28,0,0,0-2.45-8.67A27.67,27.67,0,0,0,77.9,6a25,25,0,0,0-8.13-4.53A27.56,27.56,0,0,0,57.05.28,26.16,26.16,0,0,0,45,5.21c-.34.25-.66.49-1,.74A27,27,0,0,0,23.27.27,26.09,26.09,0,0,0,8.89,7.05,26.88,26.88,0,0,0,2,16.69a27.77,27.77,0,0,0-2,10.7,26.77,26.77,0,0,0,.88,6.53A27.12,27.12,0,0,0,8.11,46.44L30.65,69,38,76.36l.64.61a8,8,0,0,0,10.86-.19c.13-.11.25-.23.4-.38L79.85,46.48a27.46,27.46,0,0,0,5.63-8A27,27,0,0,0,87.83,24Z" />
                <path className="cls-1" fill='white'
                    d="M44,17c1-1,1.87-1.93,2.82-2.82a34.36,34.36,0,0,1,3-2.57A18.37,18.37,0,0,1,58.18,8.2a19.47,19.47,0,0,1,9.06.85,16.74,16.74,0,0,1,5.53,3.08,20,20,0,0,1,5.38,6.66,19.21,19.21,0,0,1,1.73,6.13,19,19,0,0,1-1.67,10.24,19.56,19.56,0,0,1-4,5.66L44.28,70.75,44,71l-.27-.25q-15-15-30-30a19.11,19.11,0,0,1-5.14-8.85A18.67,18.67,0,0,1,8,27.36a19.83,19.83,0,0,1,1.44-7.64,19.12,19.12,0,0,1,4.89-6.8,18.12,18.12,0,0,1,10-4.73,19.06,19.06,0,0,1,16.33,5.52C41.79,14.8,42.85,15.9,44,17Z" />
            </g>
        </g>
    </svg>
)

export default HeartSVG
