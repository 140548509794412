import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './ItemOffers_style'
import css from './itemoffers.scss'
import { Typography, Grid, Box, Hidden } from '@material-ui/core'

import Switch from 'shared/components/Switch/Switch.jsx'

const ItemOffers = (props) => {
    const { type, setCheckMap, isCheckMap, offerCount, selectedLocationTag } = props
    const classes = useStyles()

    const handleEvent = () => {
        var map = document.querySelector('.item-index-map')
        if (map !== null) {
            map.classList.toggle('openedMap')
        }
        var leaderboards = document.querySelectorAll('.LeaderBoard')
        leaderboards.forEach(function (x) {
            x.classList.toggle('closedAdd')
        })
        setCheckMap(!isCheckMap)
    }

    const constructSubTitle = () => {
        return `${getPageSubTitle()}.${getPluralizationSubTitle()}`
    }

    const getPageSubTitle = () => {
        if (!selectedLocationTag) {
            return `item_offers.${type}.page_sub_title`
        } else {
            switch (selectedLocationTag.type) {
                case 'location':
                    return `item_offers.${type}.page_sub_title_location`
                case 'studyDomain':
                    return `item_offers.${type}.page_sub_title_study_domain`
                default:
                    return `item_offers.${type}.page_sub_title`
            }
        }
    }

    const getFilterName = () => {
        if (!selectedLocationTag) return ''

        if (selectedLocationTag.type === 'location') {
            return selectedLocationTag.name
        } else {
            return selectedLocationTag.name.toLowerCase()
        }
    }

    const getPluralizationSubTitle = () => {
        return offerCount === 0 ? 'zero' : offerCount === 1 ? 'one' : 'other'
    }

    return (
        <Grid container className={classes.itemOffersMainContainer}>
            <Grid item xs={12} sm={6} className={classes.itemOffersTextBox}>
                <FormattedMessage id={constructSubTitle()} values={{ offer_count: offerCount, filter: getFilterName() }} >
                    {text =>
                        <Typography variant='h1' component='h2' className={classes.pageSubTitle} dangerouslySetInnerHTML={{ __html: text }} />
                    }
                </FormattedMessage>
                            
            </Grid>
            <Grid item xs={12} sm={6} className={classes.buttonContainer}>
                {type === 'kot' && <Hidden smDown>
                    <div className="rightPart">
                        <Typography
                            variant='h1'
                            component='span'
                            className='switchTitle'
                        >
                            <FormattedMessage id="item_offers.afficher_title" />
                        </Typography>
                        <Switch checked={!isCheckMap} onChange={handleEvent} />

                    </div>
                </Hidden>}
            </Grid>
        </Grid>
    )
}

export default ItemOffers
