import React from 'react'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import AboutStudentJobsHelmet from 'helmets/AboutStudentJobsHelmet'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Button from 'components/Button/Button'
import LocationsListContainer from 'components/LocationsListContainer/LocationsListContainer'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import { FormattedMessage } from 'react-intl'
import { useStyles } from '../AboutFirstJobs/aboutabout_style'
import NewsAndTips from 'components/NewsAndTips/NewsAndTips'
import RecommendedJobsView from 'components/RecommendedJobsView/RecommendedJobsView'
import RouteMap from 'assets/RouteMap.js'

const AboutStudentJobs = (props) => {
    const { lastOffers, user, recentArticles } = props
    const classes = useStyles()

    return (
        <PageWrapper user={user}>
            <AboutStudentJobsHelmet />
            <Box className={classes.aboutPageBanner}>
                <Container maxWidth='md'>
                    <Typography variant='h1' className={classes.titlePage}>
                        <FormattedMessage id='landing_about_pages.how_to_found' />
                        <FormattedMessage id='landing_about_pages.student_job' /> ?
                    </Typography>
                </Container>
            </Box>
            <Container maxWidth='md' className={classes.aboutPageBox}>
                <Box>
                    <Typography className={classes.breakline}>
                        <FormattedMessage id='landing_about_pages.student_and_broke' />
                        <RouteMapLink page='/' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_pages.studentbe' />
                        </RouteMapLink>
                        <FormattedMessage id='landing_about_pages.student_opportunity' />
                    </Typography>
                    <Typography className={classes.breakline}>
                        <FormattedMessage id='landing_about_pages.found_job_on' />
                        <RouteMapLink page='student-jobs' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_pages.studentbe' />
                        </RouteMapLink>
                        <FormattedMessage id='landing_about_pages.apply_for_free' />
                    </Typography>
                    <Typography className={classes.breakline}>
                        <FormattedMessage id='landing_about_pages.complete_profile' />
                        <RouteMapLink page='/' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_pages.studentbe' />
                        </RouteMapLink>
                        <FormattedMessage id='landing_about_pages.autorisation' />
                    </Typography>
                    <Typography className={classes.breakline}>
                        <FormattedMessage id='landing_about_pages.students_found_job' />
                        <RouteMapLink page='student-jobs' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_pages.studentbe' />
                        </RouteMapLink>
                        <FormattedMessage id='landing_about_pages.ideal_job' />
                    </Typography>
                </Box>
                <Box>
                    <Typography variant='h2' className={classes.subtitle}>
                        <FormattedMessage id='landing_about_pages.want_to_get_started' />
                    </Typography>
                    <Typography className={classes.breakline}>
                        <FormattedMessage id='landing_about_pages.found_job_near_you' />
                        <RouteMapLink page='student-jobs' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_pages.studentbe' />
                        </RouteMapLink>
                        <FormattedMessage id='landing_about_pages.select_city' />
                    </Typography>
                </Box>
                <Box my={5}>
                    <LocationsListContainer type='student-jobs' />
                </Box>
                <Box>
                    <Typography variant='h2' className={classes.subtitle}>
                        <FormattedMessage id='landing_about_pages.search_job_student' />
                    </Typography>
                    <Typography className={classes.breakline}>
                        <FormattedMessage id='landing_about_pages.studentbe' />
                        <FormattedMessage id='landing_about_pages.help_you_to_found' />
                        <RouteMapLink page='student-rooms' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_pages.kot' />
                        </RouteMapLink>
                        <FormattedMessage id='landing_about_pages.a' />
                        <RouteMapLink page='internships' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_pages.internship' />
                        </RouteMapLink>
                        <FormattedMessage id='landing_about_pages.and_even_a' />
                        <RouteMapLink page='first-jobs' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_pages.first_job' />
                        </RouteMapLink>
                        <FormattedMessage id='landing_about_pages.trying_your_luck' />
                        <RouteMapLink page='/' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_pages.studentbe' />
                        </RouteMapLink>
                        <FormattedMessage id='landing_about_pages.offers_internship' />
                    </Typography>
                </Box>
                <Box className={classes.buttonOffers}>
                    <RouteMapLink page='student-jobs'>
                        <Button variant='primary'>
                            <FormattedMessage id='landing_about_pages.consult_offers' />
                        </Button>
                    </RouteMapLink>
                </Box>
            </Container>
            <RecommendedJobsView
                items={lastOffers}
                title={<FormattedMessage id='job_show.recommended_jobs.title' />}
                moreItemsText={<FormattedMessage id='job_show.recommended_jobs.more_link' />}
                moreItemsURL={RouteMap.Index('student_job')}
            />
            <NewsAndTips item={{ type: 'student_job' }} articles={recentArticles} />
        </PageWrapper>
    )
}

export default AboutStudentJobs
