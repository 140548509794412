import React from 'react'
import { Image, Transformation } from 'cloudinary-react'

import { useStyles } from './JobShowBannerMobileSticky_style'
import { Box, Typography } from '@material-ui/core'

import ApplyButton from '../../../ApplyButton/ApplyButton'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const JobShowBannerMobileSticky = (props) => {
    const { user, job, unavailable } = props
    const classes = useStyles()

    return (
        <Box className={classes.stickyBannerContainer}>
            <Box className={classes.leftContainer}>
                <Box className={classes.companyLogoContainer}>
                    <RouteMapLink
                        showItemType='company'
                        showItemUrl={job.company_url}
                        className={classes.logoBox}
                    >
                        <Image
                            className={classes.logo}
                            cloudName="studentbe"
                            publicId={job.company_logo_cloudinary_key ? job.company_logo_cloudinary_key : 'default-company-logo-black'}
                            alt={`${job.company_logo_cloudinary_key} logo`}
                        >
                            <Transformation flags="progressive" fetchFormat="auto" width="150" quality="auto:good" crop="scale" />
                        </Image>
                    </RouteMapLink>
                </Box>
                <Typography component='h1' className={classes.jobTitle}>
                    {job.title}
                </Typography>
            </Box>
            <Box className={classes.rightContainer}>
                {!unavailable &&
                    <ApplyButton
                        containerClass={classes.applyButtonContainer}
                        buttonsClass={classes.applyButton}
                        job={job}
                        user={user}
                        buttonSize='small'
                    />
                }
            </Box>
        </Box>
    )
}

export default JobShowBannerMobileSticky
