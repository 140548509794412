import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    pageContainer: {
        marginTop: 25,
        marginBottom: 40
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    field: {
        width: '100%'
    },
    error: {
        color: 'red'
    },
    errorText: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        '& .Mui-error': {
            fontSize: '14px !important'
        }
    },
    inputBase: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        '&::placeholder': {
            fontStyle: 'italic',
            fontSize: '14px !important'
        }
    }

}))
