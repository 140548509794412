import axios from 'axios'
import RouteMap from '../assets/RouteMap.js'

import {
    PROMOTION_FILTER
} from './env'

const filterPromotion = (data) => {
    return axios({
        url: PROMOTION_FILTER,
        method: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

export default { filterPromotion }
