import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    paperContainer: {
        padding: '0 40px',
        marginBottom: '30px'
    },
    noPaperContainer: {
        boxShadow: 'none !important'
    },
    boxContainerPage: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: '15px 0 !important',
            flexDirection: 'column'
        },
        [theme.breakpoints.up('md')]: {
            padding: '20px 0 !important'
        }
    },
    filterItemContainer: {
        [theme.breakpoints.down('md')]: {
            paddingBottom: '0 !important'
        }
    },
    buttonResetMobile: {
        width: '100%'
    },
    buttonFilterMobile: {
        marginLeft: 5
    },
    selectPlaceholder: {
        fontStyle: 'italic'
    },
    placeholderHeight: {
        lineHeight: 1
    },
    menuItem: {
        height: '48px !important',
        margin: '0 !important'
    },
    boxContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row'
        }
    },
    itemCenter: {
        display: 'flex'
    },
    boxInput: {
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            marginBottom: 10
        }
    },
    boxInputSearchCity: {
        paddingRight: 25,
        paddingLeft: 10,
        display: 'flex'
    },
    inputBase: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        // fontSize: theme.typography.subtitle1.fontSize,
        [theme.breakpoints.down('md')]: {
        },
        [theme.breakpoints.up('md')]: {
            marginRight: 10
        },
        '&::placeholder': {
            fontStyle: 'italic',
            fontSize: 14
        },
    },
    formControl: {
        [theme.breakpoints.down('md')]: {
            marginBottom: 10
        },
        [theme.breakpoints.up('md')]: {
            marginRight: 10
        }
    },
    formControlHalf: {
        [theme.breakpoints.down('md')]: {
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            width: '50%'
        }
    },
    formControlBigger: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
            marginBottom: 10
        },
        [theme.breakpoints.up('md')]: {
            width: '110%',
            marginRight: 10
        }
    },
    formControlSome: {
        [theme.breakpoints.down('md')]: {
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            width: '150%'
        }
    },
    placeholderText: {
        color: theme.typography.subtitle1.color,
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontSize: theme.typography.subtitle1.fontSize
    },
    tagsNumberContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    desktopResetButton: {
        width: 200
    },
    tagText: {
        paddingRight: 20,
        fontSize: 14
    },
    tagAvatar: {
        width: 20,
        height: 20,
        fontSize: 13
    },
    resetButton: {
        width: '100%'
    },
    locationsDropdown: {
        width: '100%',
        [theme.breakpoints.down('md')]: {
            marginBottom: 10
        },
        [theme.breakpoints.up('md')]: {
            marginRight: 10
        }
    },
    checkboxContainer: {
        display: 'flex',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-start'
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'center'
        }
    },
    sliderContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    slider: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        marginLeft: 10
    },
    languageContainer: {
        width: '100%'
    },
    langContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    articleTitle: {
        width: '50% !important',
        margin: '0 !important',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    divider: {
        height: 30,
        margin: '0 3%',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    typographyCenter: {
        textAlign: 'center'
    }
}))

// grid: {
//     [theme.breakpoints.down('lg')]: {
//         justifyContent: "space-evenly",
//         marginTop: "10px",
//         marginBottom: "20px",
//     },
//     [theme.breakpoints.up('lg')]: {
//         justifyContent: "flex-start",
//
//     }
// }
