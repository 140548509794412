import React, { useState } from 'react'
import { Box, Button, FormControl, FormHelperText, Grid, Input, InputLabel } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import IconFactory from 'shared/icons/IconFactory/IconFactory'
import ProgressCircle from 'components/ProgressCircle/ProgressCircle'
import { useStyles } from './AdminExports_style'
import AdminAPI from 'api/AdminAPI'


const AdminExports = (props) => {
    const classes = useStyles()
    const { user } = props
    const [loading, setLoading] = useState()
    const [requestSuccess, setRequestSuccess] = useState(undefined)
    const defaultValues = {
        companyContactEmail: '',
        startDate: null,
        endDate: null,
        recipient: user.email
    }
    const [formValues, setFormValues] = useState(defaultValues)
    const [formErrors, setFormErrors] = useState({})

    const frontValidation = () => {
        let errors = {}

        if (!(/^.*@student.be$/.test(formValues.recipient))) errors.recipient = '👀 Only @student.be addresses allowed'

        const hasErrors = Object.entries(errors).length > 0
        hasErrors ? setFormErrors(errors) : setFormErrors({})

        return { hasErrors }
    }

    const handleChange = (e) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value
        })
    }

    const handleStartDateChange = (date) => {
        setFormValues({ ...formValues, startDate: date })
    }

    const handleEndDateChange = (date) => {
        setFormValues({ ...formValues, endDate: date })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const validation = frontValidation()
        if (validation.hasErrors) return

        setLoading(true)
        setRequestSuccess(null)

        const formattedData = {
            company_email: formValues.companyContactEmail,
            start_date: formValues.startDate,
            end_date: formValues.endDate,
            recipient: formValues.recipient
        }
        AdminAPI.GenerateCompanyExport(formattedData).then((response) => {
            const { is_success, errors } = response.data
            setLoading(false)
            setRequestSuccess(is_success)
            if (!is_success) setFormErrors(errors)
        })
    }

    return (
        <Box className={classes.container}>
            <Box>
                <p>📨 Generates an excel file that will be sent to you via email.</p>
                <p>🔎 Contains info about a company's jobs, candidacies and articles for a specified time period.</p>
            </Box>
            <Box ml={2}>
                <form onSubmit={handleSubmit}>
                    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                        <Grid item>
                            <Box my={1}>
                                <FormControl>
                                    <InputLabel htmlFor="email-input">
                                        Email of company contact
                                    </InputLabel>
                                    <Input
                                        value={formValues.companyContactEmail}
                                        name='companyContactEmail'
                                        id="email-input"
                                        aria-describedby="email-helper-text"
                                        onChange={handleChange}
                                        required
                                        error={!!formErrors.company_email}
                                    />
                                    <FormHelperText id="email-helper-text">
                                        {formErrors.company_email || '💡 check if email is linked to the parent company account'}
                                    </FormHelperText>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box my={1}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <FormControl>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    label="Start date"
                                                    keyboardIcon={<IconFactory icon='date'/>}
                                                    margin="normal"
                                                    format="dd/MM/yyyy"
                                                    value={formValues.startDate}
                                                    onChange={handleStartDateChange}
                                                    KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                                    placeholder="dd/mm/yyyy"
                                                    required
                                                />
                                            </MuiPickersUtilsProvider>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    label='End date'
                                                    keyboardIcon={<IconFactory icon='date'/>}
                                                    margin="normal"
                                                    format="dd/MM/yyyy"
                                                    value={formValues.endDate}
                                                    onChange={handleEndDateChange}
                                                    KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                                    placeholder="dd/mm/yyyy"
                                                    disableFuture
                                                    minDate={formValues.startDate}
                                                    minDateMessage="Date cannot be before start date"
                                                    required
                                                />
                                            </MuiPickersUtilsProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box my={1}>
                                <FormControl>
                                    <InputLabel htmlFor="recipient-input">
                                        Your @student.be email
                                    </InputLabel>
                                    <Input
                                        value={formValues.recipient}
                                        name='recipient'
                                        id="recipient-input"
                                        aria-describedby="recipient-helper-text"
                                        onChange={handleChange}
                                        required
                                        error={!!formErrors.recipient}
                                    />
                                    <FormHelperText id="recipient-helper-text">
                                        {formErrors.recipient || '💡 this is confidential data, do not share with anyone outside Student.be'}
                                    </FormHelperText>
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box mt={3}>
                        <Grid container justifyContent='flex-end'>
                            <Button type="submit" variant="contained" color="primary">
                                Generate & send me the report 📨
                            </Button>
                        </Grid>
                    </Box>
                </form>
            </Box>
            <Box my={2}>
                {loading && <ProgressCircle />}
                {requestSuccess === true && <p>✅ Report successfully requested 📨 Should arrive in your inbox within a few minutes.</p>}
                {requestSuccess === false && <p>👀 Something went wrong. Sure the options are correct ?</p>}
            </Box>
        </Box>
    )
}

export default AdminExports
