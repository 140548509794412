import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    cardsContainer: {
        width: '100%',
        padding: '18px 19px 8px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: '5px'
    }
}))