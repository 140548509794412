/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { useStyles } from './ArticleContent_style'

const ArticleContent = (props) => {
    const { classes, content } = props

    return (
        <Typography
            className={classes.articleText}
            dangerouslySetInnerHTML={{ __html: content }}
            component='div'
        />
    )
}

export default withStyles(useStyles)(ArticleContent)
