import React from 'react'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import PersonalDashboardPage from './PersonalDashboardPage'

const PersonalDashboard = (props) => {
    const { user } = props

    return (
        <PageWrapper user={user} disableFooterLists>
            <PersonalDashboardPage {...props} />
        </PageWrapper>
    )
}

export default PersonalDashboard
