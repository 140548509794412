import React from 'react'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import AboutFirstJobsHelmet from 'helmets/AboutFirstJobsHelmet'
import LocationsListContainer from 'components/LocationsListContainer/LocationsListContainer'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import { FormattedMessage } from 'react-intl'
import { useStyles } from './aboutabout_style'
import Container from '@material-ui/core/Container'
import Button from 'components/Button/Button'
import NewsAndTips from 'components/NewsAndTips/NewsAndTips'
import RecommendedJobsView from 'components/RecommendedJobsView/RecommendedJobsView'
import RouteMap from 'assets/RouteMap.js'

const AboutFirstJobs = (props) => {
    const { lastOffers, recentArticles, user } = props
    const classes = useStyles()

    return (
        <PageWrapper user={user}>
            <AboutFirstJobsHelmet />
            <Box className={classes.aboutPageBanner}>
                <Container maxWidth='md'>
                    <Typography variant='h1' className={classes.titlePage}>
                        <FormattedMessage id='landing_about_pages.how_to_found' />
                        <FormattedMessage id='landing_about_pages.first_job' /> ?
                    </Typography>
                </Container>
            </Box>

            <Container maxWidth='md' className={classes.aboutPageBox}>
                <Box className={classes.breaklines}>
                    <Typography className={classes.breakline}>
                        <FormattedMessage id='landing_about_first_jobs.description' />
                        <RouteMapLink page='first-jobs' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_first_jobs.link.job_ads' />
                        </RouteMapLink>
                        <FormattedMessage id='landing_about_first_jobs.and_1' />
                        <RouteMapLink page='first-jobs/articles' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_first_jobs.link.quality_content' />
                        </RouteMapLink>
                        <FormattedMessage id='landing_about_first_jobs.and_2' />
                        <RouteMapLink page='/' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_first_jobs.link.candidacy_advice' />
                        </RouteMapLink>
                    </Typography>
                    <Typography className={classes.breakline}>
                        <FormattedMessage id='landing_about_first_jobs.scan_integrate_1' />
                    </Typography>
                    <Typography className={classes.breakline}>
                        <FormattedMessage id='landing_about_first_jobs.scan_integrate_2' />
                    </Typography>
                    <Typography className={classes.breakline}>
                        <FormattedMessage id='landing_about_first_jobs.scan_integrate_3' />
                        <RouteMapLink page='/' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_first_jobs.link.scan_kazi' />
                        </RouteMapLink>
                    </Typography>
                    <Typography className={classes.breakline}>
                        <FormattedMessage id='landing_about_first_jobs.stay_up_the_date_1' />
                        <RouteMapLink page='/' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_first_jobs.link.job_alert' />
                        </RouteMapLink>
                        <FormattedMessage id='landing_about_first_jobs.stay_up_the_date_2' />
                    </Typography>
                </Box>

                <Box>
                    <Typography variant='h2' className={classes.subtitle}>
                        <FormattedMessage id='landing_about_first_jobs.search_in_city' />
                    </Typography>
                    <Typography className={classes.breakline}>
                        <FormattedMessage id='landing_about_first_jobs.select_city_where_to_work' />
                    </Typography>
                </Box>

                <Box my={5}>
                    <LocationsListContainer type='first-jobs' />
                </Box>

                <Box>
                    <Typography className={classes.breakline}>
                        <FormattedMessage id='landing_about_pages.found_city' />
                        {' '}
                        <RouteMapLink page='first-jobs' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_pages.here' />
                        </RouteMapLink>
                    </Typography>
                    <Typography variant='h2' className={classes.subtitle}>
                        <FormattedMessage id='landing_about_pages.search_job_student' />
                    </Typography>
                    <Typography className={classes.breakline}>
                        <FormattedMessage id='landing_about_pages.studentbe' />
                        <FormattedMessage id='landing_about_pages.help_you_to_found' />
                        <RouteMapLink page='student-rooms' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_pages.kot' />
                        </RouteMapLink>
                        <FormattedMessage id='landing_about_pages.a' />
                        <RouteMapLink page='student-jobs' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_pages.student_job' />
                        </RouteMapLink>
                        <FormattedMessage id='landing_about_pages.and_even_a' />
                        <RouteMapLink page='first-jobs' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_pages.first_job' />
                        </RouteMapLink>
                        <FormattedMessage id='landing_about_internship.on_website' />
                        {' '}
                        <RouteMapLink page='/' className={classes.studentFont}>
                            <FormattedMessage id='landing_about_pages.studentbe' />
                        </RouteMapLink>.
                    </Typography>
                </Box>

                <Box className={classes.buttonOffers}>
                    <RouteMapLink page='first-jobs'>
                        <Button variant='primary'>
                            <FormattedMessage id='landing_about_pages.consult_offers' />
                        </Button>
                    </RouteMapLink>
                </Box>
            </Container>
            <RecommendedJobsView
                items={lastOffers}
                title={<FormattedMessage id='job_show.recommended_jobs.title' />}
                moreItemsText={<FormattedMessage id='job_show.recommended_jobs.more_link' />}
                moreItemsURL={RouteMap.Index('first_job')}
            />
            <NewsAndTips item={{ type: 'first_job' }} articles={recentArticles} />
        </PageWrapper>
    )
}

export default AboutFirstJobs
