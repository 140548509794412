import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    jobTypesCardContainer: {
        width: '100%',
        paddingBottom: '23px',
    },

    jobTypesTitle: {
        padding: '0px 16px 23px 16px',
        color: theme.palette.fg['black'],
        fontSize: '24px',
        lineHeight: '28px'
    },

    cardsList: {
        width: '100%',
        padding: '0px 16px 10px 16px',
        display: 'flex',
        alignItems: 'center',
        columnGap: '11px',
        overflowX: 'scroll',
        [theme.breakpoints.up(429)]: {
            justifyContent: 'center',
        }
    },

    card: {
        minWidth: '125px',
        width: '125px',
        height: '109px',
        borderRadius: '20px',
        border: `1px solid ${theme.palette.fg['white']}`,
        padding: '22px 12px 13px 12px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
    },

    cardTitle: {
        color: theme.palette.fg['black'],
        fontSize: '16px',
        lineHeight: '18px',
        textAlign: 'center'
    },
}))
