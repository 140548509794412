import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    boxTitle: {
        textAlign: 'center',
        marginBottom: -14
    },
    boxCenter: {
        display: 'flex',
        justifyContent: 'center',
        margin: '20px 0',
        textAlign: 'center'
    },
    typographyWarning: {
        textAlign: 'center',
        padding: '0 20px',
        fontSize: 11,
        fontStyle: 'italic'
    },
    boxTags: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center'
    },
    flexRow: {
        justifyContent: 'row'
    },
    flewColumn: {
        flexDirection: 'column'
    },
    tagFilter: {
        borderRadius: 5,
        margin: '3px 5px'
    },
}))
