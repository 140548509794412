export const DROPDOWN_STYLE_MENU_PROPS = {
    disableScrollLock: true,
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: 'bottom'
    },
    transformOrigin: {
        vertical: -20
    },
    MenuListProps: {
        style: {
            paddingTop: 0,
            paddingBottom: 0
        }
    },
    PaperProps: {
        style: {
            borderRadius: 16,
            maxHeight: 350,
            boxShadow: '4px 4px 0px 0px var(--background-black)',
            border: '2px solid var(--foreground-black)',
            backgroundColor: 'var(--background-white)'
        }
    }
}
