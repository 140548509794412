import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import SwipeableViews from 'react-swipeable-views'

import { useStyles } from './CardImageCarousel_style'
import { Box, CardMedia, Typography, IconButton, useTheme } from '@material-ui/core'

import IconFactory from 'icons/IconFactory/IconFactory'
import CloudinaryURLHelper from 'assets/CloudinaryURLHelper'

const CardImageCarousel = ({ kot }) => {
    const classes = useStyles()
    const theme = useTheme()
    const [activeStep, setActiveStep] = useState(0)
    const maxSteps = kot.images?.length

    const handleNext = () => {
        setActiveStep((prevActiveStep) =>
            activeStep === maxSteps - 1
                ? (prevActiveStep = 0)
                : prevActiveStep + 1
        )
    }
    const handleBack = () => {
        setActiveStep((prevActiveStep) =>
            activeStep === 0
                ? (prevActiveStep = maxSteps - 1)
                : prevActiveStep - 1
        )
    }
    const handleStepChange = (step) => {
        setActiveStep(step)
    }

    return (
        <Box className={classes.topContainer}>
            <IconButton
                onClick={handleBack}
                className={`${classes.arrowBtn} ${classes.arrowLeft}`}
            >
                <IconFactory
                    icon='arrow-left'
                    color={theme.palette.fg['white']}
                    width='10px'
                    height='10px'
                />
            </IconButton>
            <IconButton
                onClick={handleNext}
                className={`${classes.arrowBtn} ${classes.arrowRight}`}
            >
                <IconFactory
                    icon='arrow-right'
                    color={theme.palette.fg['white']}
                    width='10px'
                    height='10px'
                />
            </IconButton>
            <Box className={classes.kotPriceContainer}>
                {kot.active
                    ? <Typography variant='h2' component='p' className={classes.kotPrice}>
                        {kot.charges === 0
                            ? <>€{kot.rent}</>
                            : <>€{kot.rent} (+€{kot.charges})</>
                        }
                    </Typography>
                    : <Typography variant='h2' component='p' className={classes.kotPrice}>
                        <FormattedMessage id='transl_internship.kot_completed' />
                    </Typography>
                }
            </Box>
            <SwipeableViews
                axis='x'
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                className={classes.SwipeableViews}
            >
                {kot.images.map((step, key) => {
                    return (
                        <CardMedia
                            key={key.toString()}
                            className={classes.media}
                            image={CloudinaryURLHelper.getImageUrl(
                                'KOT_CARD',
                                step.cloudinary_key
                            )}
                            alt={step.cloudinary_key}
                            id={'img-' + key}
                        />
                    )
                })}
            </SwipeableViews>
        </Box>
    )
}

export default CardImageCarousel
