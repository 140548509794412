import React from 'react'

const CandidacyStatSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 80"
        x="0px"
        y="0px"
    >
        <g fill="#a000ff">
            <rect x="31" y="52" width="2" height="2" />
            <path d="M8,50.377V54H5a1,1,0,0,0-1,1v3a4,4,0,0,0,4,4H56a4,4,0,0,0,4-4V55a1,1,0,0,0-1-1H56V29.444A3.987,3.987,0,0,0,58,26V10a4,4,0,0,0-4-4H29a4,4,0,0,0-4,4v4H12a4,4,0,0,0-4,4V29.623A11.974,11.974,0,0,0,8,50.377ZM58,56v2a2,2,0,0,1-2,2H8a2,2,0,0,1-2-2V56H22.586l1.707,1.707A1,1,0,0,0,25,58H39a1,1,0,0,0,.707-.293L41.414,56ZM17,42a3,3,0,0,1,3,3v2.981a9.991,9.991,0,0,1-2,1.178V46H16v3.8a10,10,0,0,1-4,0V46H10v3.159a9.991,9.991,0,0,1-2-1.178V45a3,3,0,0,1,3-3Zm-6-5a3,3,0,1,1,3,3A3,3,0,0,1,11,37Zm9.619,13H50a1,1,0,0,0,1-1V30h3V54H41a1,1,0,0,0-.707.293L38.586,56H25.414l-1.707-1.707A1,1,0,0,0,23,54H10V51.3A11.889,11.889,0,0,0,20.619,50ZM26.99,23.142A.969.969,0,0,0,27,23V10a2,2,0,0,1,2-2H54a2,2,0,0,1,2,2V26a2,2,0,0,1-2,2H32a.969.969,0,0,0-.142.01l-5.679.811ZM25.142,30.99,32.071,30H49V48H22.919A11.971,11.971,0,0,0,15,28.051V21H25v1.929l-.99,6.929A1,1,0,0,0,25,31,.978.978,0,0,0,25.142,30.99ZM10,18a2,2,0,0,1,2-2H25v3H14a1,1,0,0,0-1,1v8.051a11.856,11.856,0,0,0-3,.649Zm4,12a9.98,9.98,0,0,1,8,15.974V45a5,5,0,0,0-4.1-4.909A4.947,4.947,0,0,0,19,37,5,5,0,0,0,9,37a4.947,4.947,0,0,0,1.1,3.091A5,5,0,0,0,6,45v.974A9.98,9.98,0,0,1,14,30Z" />
            <rect x="32" y="11" width="2" height="2" />
            <rect x="36" y="11" width="17" height="2" />
            <rect x="30" y="15" width="23" height="2" />
            <rect x="30" y="19" width="23" height="2" />
            <rect x="31" y="34" width="2" height="2" />
            <rect x="35" y="34" width="11" height="2" />
            <rect x="29" y="38" width="17" height="2" />
            <rect x="29" y="42" width="13" height="2" />
            <rect x="30" y="23" width="19" height="2" />
            <path d="M55,2H47V4h8a5.006,5.006,0,0,1,5,5v3h2V9A7.009,7.009,0,0,0,55,2Z" />
            <rect x="4" y="19" width="2" height="9" />
            <rect x="43" y="2" width="2" height="2" />
            <rect x="58" y="33" width="2" height="12" />
            <rect x="58" y="47" width="2" height="2" />
        </g>
    </svg>
)

export default CandidacyStatSVG
