import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Typography } from '@material-ui/core'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import Button from 'components/Button/Button'
import { useStyles } from './GoToProfileBox_style'

const GoToProfileBox = (props) => {
    const classes = useStyles()

    return (
        <Box>
            <Typography variant='h6' className={classes.title}>
                <FormattedMessage id='personal_dashboard.go_to_profile_title' />
            </Typography>
            <Typography variant='body1' className={classes.text}>
                <FormattedMessage id='personal_dashboard.go_to_profile_text_2' />
            </Typography>
            <Box className={classes.buttonBox}>
                <RouteMapLink page={'users/profile/edit'}>
                    <Button variant='cta-primary' size='small' className={classes.button}>
                        <FormattedMessage id='personal_dashboard.go_to_profile_button' />
                    </Button>
                </RouteMapLink>
            </Box>
        </Box>
    )
}
export default GoToProfileBox
