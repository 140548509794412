import React from 'react'

const NewJobStatSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 640"
        x="0px"
        y="0px"
    >
        <g fill="#a000ff">
            <path
                fill="#a000ff"
                d="M410.854,285.162a8,8,0,0,0-11.315,0l-9.372,9.372-8-8v-32.4a76.285,76.285,0,0,0-76.285-76.284h-.4l-15.03-15.03a84.656,84.656,0,0,0-56.905-24.9l-13.085-13.085,9.373-9.372a8,8,0,0,0,0-11.314l-79.2-79.2L139.323,36.26l73.54,73.539L99.726,222.936,26.188,149.4,14.873,160.71l79.195,79.2a8,8,0,0,0,11.315,0l7.764-7.765,13.02,13.02v36.686a23.989,23.989,0,0,0,32,22.623v9.377a23.989,23.989,0,0,0,32,22.623v9.377a23.989,23.989,0,0,0,32,22.623v9.377a23.986,23.986,0,0,0,41.869,16h10.818l8,8-7.765,7.765a8,8,0,0,0,0,11.314l79.2,79.2L365.6,488.808l-73.54-73.539L405.2,302.132l73.538,73.539,11.315-11.314ZM246.167,385.848a8.009,8.009,0,0,1-8-8v-64h-16v32a8,8,0,0,1-16,0v-64h-16v32a8,8,0,0,1-16,0v-56h-16v24a8,8,0,0,1-16,0v-40a8,8,0,0,0-2.343-5.657l-15.363-15.363,84.686-84.686L224.51,151.5a8,8,0,0,0,5.657,2.344,68.8,68.8,0,0,1,48.97,20.284l3.716,3.716H206.167a8,8,0,0,0-8,8,60.5,60.5,0,0,0,44.485,58.243l11.515,3.14V377.848A8.009,8.009,0,0,1,246.167,385.848Zm37.657-5.657a8,8,0,0,0-5.657-2.343h-8V251.594l16,4.364v1.89a40.045,40.045,0,0,0,40,40,8,8,0,0,0,0-16,24.027,24.027,0,0,1-24-24v-8a8,8,0,0,0-5.9-7.719l-49.41-13.475A44.49,44.49,0,0,1,214.9,193.848h90.986a60.283,60.283,0,0,1,60.285,60.284v35.716a8,8,0,0,0,2.343,5.656l10.343,10.344-84.686,84.685Z"
            />
            <rect
                x="318"
                y="407.269"
                width="16"
                height="16"
                transform="translate(-198.162 352.192) rotate(-45.005)"
            />
            <rect
                x="91.726"
                y="180.995"
                width="16"
                height="16"
                transform="translate(-104.429 125.868) rotate(-44.999)"
            />
            <path d="M72,232H56V480a8,8,0,0,0,8,8H304V472H72Z" />
            <path d="M440,296h16V32a8,8,0,0,0-8-8H184V40H440Z" />
            <path d="M376,64a40,40,0,1,0,40,40A40.045,40.045,0,0,0,376,64Zm0,64a24,24,0,1,1,24-24A24.028,24.028,0,0,1,376,128Z" />
            <rect x="368" y="96" width="16" height="16" />
            <rect x="368" y="160" width="48" height="16" />
            <rect x="392" y="192" width="24" height="16" />
            <rect x="400" y="224" width="16" height="16" />
            <rect x="96" y="432" width="16" height="16" />
            <rect x="96" y="392" width="112" height="16" />
            <rect x="96" y="360" width="80" height="16" />
            <rect x="96" y="328" width="48" height="16" />
            <rect x="128" y="432" width="16" height="16" />
            <rect x="160" y="432" width="16" height="16" />
            <rect x="24" y="272" width="16" height="160" />
            <rect x="24" y="448" width="16" height="16" />
            <rect x="472" y="64" width="16" height="176" />
        </g>
    </svg>
)

export default NewJobStatSVG
