import React from 'react'

const Package1SVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 100 125"
        x="0px"
        y="0px"
    >
        <title>04</title>
        <g data-name="Group" fill="#a000ff">
            <path
                data-name="Compound Path"
                d="M58.9,85.8l14.6-26,10.7.5a10.3,10.3,0,1,0-.1-20.6h0l-10.6.5-14.6-26H44.3l8.1,27.1-18,.8L28.5,32l-8.3,2.1L26.1,50,20.2,65.9,28.5,68l5.9-10.1,18,.8L44.3,85.8ZM47,16.2H57.8L71.3,40.3l-16.8.9ZM33.3,55.8l-5.8,9.9-4.7-1.2L28.2,50,22.8,35.5l4.7-1.2,5.8,9.9,50.9-2.5a8.3,8.3,0,0,1,0,16.6Zm38,3.9L57.8,83.8H47l7.5-25Z"
            />
            <rect data-name="Path" x="10.4" y="20" width="21" height="2" />
            <rect data-name="Path" x="10.4" y="78" width="21" height="2" />
            <rect data-name="Path" x="5.5" y="49" width="10" height="2" />
        </g>
    </svg>
)

export default Package1SVG
