import React, { useState, useEffect } from 'react'
import KotAPI from 'api/KotAPI'
import SimilarItemsView from 'components/SimilarItemsView/SimilarItemsView'

const RecommendedKots = (props) => {
    const { user } = props
    const [recommendedKots, setRecommendedKots] = useState([])

    useEffect(() => {
        getRecommendedKots()
    }, [])

    const getRecommendedKots = () => {
        const data = {
            amount: 3
        }

        KotAPI.GetRecommendedKots(data).then((response) => {
            setRecommendedKots(response.data.recommended_kots)
        })
    }

    if (recommendedKots.length === 0) return null

    return (
        <SimilarItemsView
            fullWidth
            type={'kot'}
            items={recommendedKots}
            userType={user?.type}
            baseItem={{ type: 'kot' }}
            titleId='recommended_kots.title'
            moreItemsId='recommended_kots.more_items'
        />
    )
}
export default RecommendedKots
