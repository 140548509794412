/* eslint-disable react/prop-types */
import React from 'react'
import InfoLineView from './InfoLineView/InfoLineView'
import CollectionHandler from 'assets/CollectionHandler.js'

const ScheduleLine = (props) => {
    const { job } = props
    if (!job.job_schedule) return null

    const getText = () => {
        let schedules = job.job_schedule.map(schedule => CollectionHandler.Translate('studentJobSchedulesCollection', schedule))
        schedules = schedules.join(', ')

        return schedules
    }

    return (
        <InfoLineView
            icon='clock'
            text={getText()}
        />
    )
}

export default ScheduleLine
