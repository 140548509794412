import React, { useContext } from 'react'

import { Image, Transformation } from 'cloudinary-react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './EmployerPostAJobBanner_style'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import AnalyticsHelper from 'assets/AnalyticsHelper'
import Button from 'shared/components/Button/Button'
import RouteMap from 'assets/RouteMap'
import { UserContext } from 'contexts/UserContext'

const EmployerPostAJobBanner = (props) => {
    const classes = useStyles()
    const { imageId } = props
    const userContext = useContext(UserContext)
    const jobPostingUrl = userContext?.user ? RouteMap.Page('employer/new-ad') : RouteMap.Page('payment')

    const handleContactClick = () => {
        window.location.href = jobPostingUrl
    }

    return (
		<Box className={classes.postAJobContainer}>
            <Container maxWidth='lg' className={classes.containerOverride}>
                <Box className={classes.postAJobText}>
                    <Typography variant='h6' component='h2' className={classes.title}>
                        <FormattedMessage id="landing_employer.post_a_job.title" />
                    </Typography>
                    <Button
                        variant='cta-primary'
                        className={classes.customButton}
                        onClick={handleContactClick}
                    >
                        <FormattedMessage id='publish_a_student_job.post_a_job_button' />
                    </Button>
                </Box>
                <Box className={classes.postAJobImage}>
                    <Image
                        cloudName='studentbe'
                        publicId={imageId}
                        alt={`student.be ${imageId}.`}
                        className={classes.image}
                    >
                        <Transformation flags='progressive' fetchFormat='auto' quality='auto:good' crop='fill' />
                    </Image>
                </Box>
            </Container>
        </Box>
    )
}

export default EmployerPostAJobBanner
