import React from 'react'
import ShareModalView from './ShareModalView'

const ShareModal = (props) => {
    const { showModal, setShowModal, infos } = props
    if (!showModal) return null

    const closeModal = () => setShowModal(false)

    return (
        <ShareModalView
            infos={infos}
            showModal={showModal}
            setShowModal={setShowModal}
            closeModal={closeModal}
        />
    )
}

export default ShareModal
