import axios from 'axios'
import RouteMap from '../assets/RouteMap.js'
import ReactOnRails from 'react-on-rails'

import {
    CONTACT_API
} from './env'

const SubmitContactForm = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: CONTACT_API,
        method: 'post',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

export default {
    SubmitContactForm
}
