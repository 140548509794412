/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react'

import { useStyles } from './DangerZone_style'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import IconFactory from 'icons/IconFactory/IconFactory'
import ModalFactory from 'modals/ModalFactory'
import Typography from '@material-ui/core/Typography'
import Button from 'components/Button/Button'
import Paper from 'components/Paper/Paper'

import { FormattedMessage } from 'react-intl'

const DangerZone = ({ props }) => {
    const classes = useStyles()
    const [showPopup, setShowPopup] = useState(false)

    return (
        <Box>
            <ModalFactory
                user={props.user}
                type='DangerZonePopup'
                openShare={showPopup}
                popupExit={() => setShowPopup(!showPopup)}
                doublebuttons
                inversedActions
            />
            <Paper className={classes.paper}>
                <Box display='flex' flexDirection='row'>
                    <Box item mr={1}>
                        <IconFactory icon='danger' color='var(--red)' />
                    </Box>
                    <Typography
                        variant='h3' component='h2'
                        color='secondary'
                    >
                        <FormattedMessage id="landing_app_setting_page.danger_zone.title" />
                    </Typography>
                </Box>
                <Grid item xs={12} >
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <Box display='flex' style={{ height: '100%' }} justifyContent='flex-end' alignItems='flex-end'>
                                <Button
                                    variant='secondary'
                                    size='small'
                                    onClick={() => setShowPopup(!showPopup)}
                                >
                                    <FormattedMessage id="landing_app_setting_page.delete_account" />
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    )
}
export default DangerZone
