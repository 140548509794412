import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    userActionsContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '4px',
        [theme.breakpoints.up(1050)]: {
            columnGap: '14px',
        },
        [theme.breakpoints.up('lg')]: {
            columnGap: '24px'
        },
    },
}))
