import React, {} from 'react'
import ReactQuill from 'react-quill'
import { FormattedMessage } from 'react-intl'
import { Box, TextField } from '@material-ui/core'

import { useStyles } from './JobCompanyForm_style'
import { useStyles as useSharedStyles } from '../../../FirstJobFunnel/FirstJobFunnel_style'
import CollectionHandler from 'assets/CollectionHandler.js'
import FieldTitle from '../../components/FieldTitle/FieldTitle'
import FieldError from '../../components/FieldError/FieldError'
import FieldDivider from '../../components/FieldDivider/FieldDivider'
import DropdownSingleSelect from 'shared/components/DropdownSingleSelect/DropdownSingleSelect'
import TagSingleSelect from 'shared/components/TagSingleSelect/TagSingleSelect'
import UploadImageV2 from 'shared/modals/UploadImageV2/UploadImageV2'
import { QUILL_MODULES } from '../../../settings'
import FieldSubtitle from '../../components/FieldSubtitle/FieldSubtitle'

const JobCompanyForm = (props) => {
    const classes = useStyles()
    const sharedClasses = useSharedStyles()
    const { jobForm, errors, handleChange, updateCompanyLogo, handleAttributeChange, companyDescriptionWithAi } = props
    const companySizeCollection = CollectionHandler.Get('companySizeCollection')
    const sectorCollection = CollectionHandler.Get('sectorCollection')

    return (
		<Box className={sharedClasses.formContainer}>
            <Box className={sharedClasses.formField}>
                <Box className={classes.titles}>
                    <FieldTitle title={<FormattedMessage id="jobcreationfunnel.titles.company_name" />} />
                </Box>
                <FormattedMessage id="jobcreationfunnel.placeholders.company_name">
                    {placeholder =>
                        <TextField
                            name='companyName'
                            value={jobForm.companyName}
                            onChange={handleChange}
                            InputProps={{ classes: { input: `${sharedClasses.textField} ${sharedClasses.shortField}` }, disableUnderline: true }}
                            placeholder={placeholder}
                        />
                    }
                </FormattedMessage>
                <FieldError errorMessage={errors.companyName} />
                <FieldDivider />
            </Box>
            <Box className={sharedClasses.formField}>
                <FieldTitle title={<FormattedMessage id="jobcreationfunnel.titles.company_size" />} />
                <TagSingleSelect
                    value={jobForm.companySize}
                    collection={companySizeCollection}
                    setValue={(newValue) => handleAttributeChange("companySize", newValue)}
                />
                <FieldError errorMessage={errors.companySize} />
                <FieldDivider />
            </Box>
            <Box className={sharedClasses.formField}>
                <FieldTitle
                    title={<FormattedMessage id="jobcreationfunnel.titles.company_logo" />}
                    isOptional
                />
                <UploadImageV2
                    image={jobForm.companyLogo}
                    setImage={updateCompanyLogo}
                />
                <FieldError errorMessage={errors.companyLogo} />
                <FieldDivider />
            </Box>
            <Box className={sharedClasses.formField}>
                <FieldTitle title={<FormattedMessage id="jobcreationfunnel.titles.company_sector"/>}/>
                <DropdownSingleSelect
                    value={jobForm.companySector}
                    name='companySector'
                    placeholder={<FormattedMessage id="jobcreationfunnel.placeholders.sector" />}
                    collection={sectorCollection}
                    handleChange={handleChange}
                    handleAttributeChange={handleAttributeChange}
                />
                <FieldError errorMessage={errors.companySector} />
                <FieldDivider />
            </Box>
            <Box className={sharedClasses.formField}>
                <Box>
                    <Box className={sharedClasses.flexBox}>
                        <FieldTitle title={<FormattedMessage id="jobcreationfunnel.titles.company_description"/>}/>
                        {companyDescriptionWithAi && <Box className={sharedClasses.aiLabel}>
                            <FormattedMessage id="job_creation_funnel.job_offer_generator.ai_powered_label"/>
                        </Box>}
                    </Box>
                    <FieldSubtitle subtitle={<FormattedMessage id="jobcreationfunnel.subtitles.company_description" />} />
                </Box>
                <Box className={sharedClasses.quillStyling}>
                    <ReactQuill
                        modules={QUILL_MODULES}
                        value={jobForm.companyDescription || ''}
                        name="companyDescription"
                        onChange={(value) => handleAttributeChange('companyDescription', value)}
                    />
                </Box>
                <FieldError errorMessage={errors.companyDescription} />
            </Box>
        </Box>
    )
}

export default JobCompanyForm
