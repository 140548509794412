/* eslint-disable react/prop-types */
import React from 'react'

import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import { DROPDOWN_STYLE_MENU_PROPS } from 'assets/StyleHelper'

import SearchBarWrapper from 'wrappers/SearchBarWrapper/SearchBarWrapper'
import IconFactory from 'icons/IconFactory/IconFactory'
import Button from 'components/Button/Button'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

import { useStyles } from './EmployerJobIndexSearchBarView_style'
import { FormattedMessage } from 'react-intl'

const EmployerJobIndexSearchBarView = (props) => {
    const { reset, handleFilterChange, filters, onEnterKeyPress } = props
    const classes = useStyles()

    const SEARCH_PARAMS = ['first_job', 'internship', 'student_job']

    return (
        <Grid container>
            <Grid item xs={12} md={9}>
                <SearchBarWrapper reset={reset}>
                    <Box className={classes.boxContainer}>
                        <Box className={classes.boxInput}>
                            <Box className={classes.iconBox}>
                                <IconFactory icon='search' color='primary'/>
                            </Box>
                            <FormattedMessage id='search_bar.search.job'>
                                {placeholder =>
                                    <TextField
                                        InputProps={{
                                            classes: {
                                                input: classes.inputBase
                                            }
                                        }}
                                        placeholder={placeholder}
                                        value={filters.title}
                                        name='title'
                                        onChange={handleFilterChange}
                                        className={classes.inputBase}
                                        fullWidth
                                        onKeyPress={onEnterKeyPress}
                                    />
                                }
                            </FormattedMessage>
                        </Box>
                        <FormControl fullWidth className={classes.formControl}>
                            <Select
                                name='type'
                                value={filters.type}
                                onChange={handleFilterChange}
                                MenuProps={DROPDOWN_STYLE_MENU_PROPS}
                                displayEmpty
                            >
                                <MenuItem disabled value={undefined}>
                                    <Typography variant='subtitle1' className={classes.selectPlaceholder}>
                                        <FormattedMessage id='search_bar.job_type' />
                                    </Typography>
                                </MenuItem>
                                {
                                    SEARCH_PARAMS.map((type, key) => (
                                        <MenuItem value={type} key={key}>
                                            <Typography variant='body2'>
                                                <FormattedMessage id={`chat.${type}`}/>
                                            </Typography>
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </SearchBarWrapper>
            </Grid>
            <Grid item xs={12} md={3} className={classes.myAdsButtonContainer}>
                <RouteMapLink page='employer/new-ad' className={classes.myAdsButtonLink}>
                    <Button
                        variant='primary'
                        size='small'
                        className={classes.myAdsButton}
                    >
                        <FormattedMessage id='item_index.post_offer' />
                    </Button>
                </RouteMapLink>
            </Grid>
        </Grid>
    )
}

export default EmployerJobIndexSearchBarView
