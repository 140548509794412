import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    itemBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '6px 16px'
    },
    itemLineBox: {
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        fontSize: '16px !important',
        whiteSpace: 'break-spaces'
    },
    chipLabelText: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 8,
        alignItems: 'center',
        fontSize: '14px !important',
        whiteSpace: 'break-spaces',
        textAlign: 'right'
    }
}))
