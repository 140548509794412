import axios from 'axios'
import ReactOnRails from 'react-on-rails'

import { NAVIGATION_COLLECTION_API, COMPANY_FILTER_JOBS_API } from './env'

const GetNavigationCollection = (params) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: NAVIGATION_COLLECTION_API,
        method: 'get',
        params: params
    })
}

const GetCompanyFilterJobsCount = (params) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: COMPANY_FILTER_JOBS_API,
        method: 'get',
        params: params
    })
}

export default { GetNavigationCollection, GetCompanyFilterJobsCount }
