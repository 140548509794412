import React, { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'

import URLHandler from 'assets/URLHandler'
import { useWindowSize } from 'hooks/useWindowSize'
import { useStyles } from './ResponsiveVideo_style'

const ResponsiveVideo = (props) => {
    const classes = useStyles()
    const { video } = props
    const [isResponsive] = useState(!video.width)
    const [videoParams, setVideoParams] = useState({
        url: video.url,
        source: video.source,
        sourceId: video.sourceId,
        src: video.src,
        format: video.format,
        width: video.width,
        height: video.height,
        alt: video.alt
    })
    const { windowSize } = useWindowSize()

    useEffect(() => {
        if (videoParams.sourceId) return

        const sourceInfo = URLHandler.getVideoSourceInfo(videoParams.url)
        setVideoParams({
            ...videoParams,
            ...sourceInfo
        })
    }, [video])

    const calcMaxWidth = () => {
        if (!videoParams.width) return '100%'
        if (windowSize.width < videoParams.width) return '100%'

        return videoParams.width
    }

    const getIframe = () => {
        if (videoParams.source === 'youtube') return youtubeIframe()
        if (videoParams.source === 'dailymotion') return dailymotionIframe()
        if (videoParams.source === 'vimeo') return vimeoIframe()
    }

    const youtubeIframe = () => {
        return <iframe
            src={`https://www.youtube.com/embed/${videoParams.sourceId}?rel=0`}
            className={isResponsive ? classes.responsiveIframe : ''}
            width={calcMaxWidth()}
            height={videoParams.height || '100%'}
            frameBorder="0"
            allowFullScreen
            title={videoParams.alt}
        />
    }

    const dailymotionIframe = () => {
        return <iframe
            src={`https://www.dailymotion.com/embed/video/${videoParams.sourceId}`}
            className={isResponsive ? classes.responsiveIframe : ''}
            frameBorder="0"
            type="text/html"
            allowFullScreen
            width={calcMaxWidth()}
            height={videoParams.height || '100%'}
            title={videoParams.alt}
        />
    }

    const vimeoIframe = () => {
        return <iframe
            src={`https://player.vimeo.com/video/${videoParams.sourceId}`}
            className={isResponsive ? classes.responsiveIframe : ''}
            frameBorder="0"
            allow="autoplay;fullscreen; picture-in-picture"
            allowFullScreen
            width={calcMaxWidth()}
            height={videoParams.height || '100%'}
            title={videoParams.alt}
        />
    }

    return (isResponsive
        ? <Box className={classes.responsiveContainer}>
            {getIframe()}
        </Box>
        : <Box>
            {getIframe()}
        </Box>
    )
}

export default ResponsiveVideo
