import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './NavigationUserActionsLogout_style'
import { Box } from '@material-ui/core'

import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import Button from 'shared/components/Button/Button.jsx'

const NavigationUserActionsLogout = (props) => {
    const classes = useStyles()

    return (
        <Box className={classes.userActionsLogoutContainer}>
            <RouteMapLink page='employers'>
                <Button variant='tertiary' size='small' className={classes.actionButton}>
                    <FormattedMessage id="page_footer_navbar_data.employer_button" />
                </Button>
            </RouteMapLink>

            <RouteMapLink page='login'>
                <Button variant='secondary' size='small' className={classes.actionButton}>
                    <FormattedMessage id="page_footer_navbar_data.sign_in_button" />
                </Button>
            </RouteMapLink>
        </Box>
    )
}

export default NavigationUserActionsLogout
