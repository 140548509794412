import React from 'react'

const PackageSpaceshipSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 80"
        x="0px"
        y="0px"
    >
        <g>
            <path d="M55,33h-.382L52.9,29.553A1,1,0,0,0,52,29H51A19.007,19.007,0,0,0,33,10.051V8a1,1,0,0,0-2,0v2.051A19.007,19.007,0,0,0,13,29H12a1,1,0,0,0-.895.553L9.382,33H9A7,7,0,0,0,9,47h.382l1.723,3.447A1,1,0,0,0,12,51h2.638C17.581,53.438,24.3,55,32,55s14.419-1.562,17.362-4H52a1,1,0,0,0,.9-.553L54.618,47H55a7,7,0,0,0,0-14ZM32,12A17.019,17.019,0,0,1,49,29H15A17.019,17.019,0,0,1,32,12ZM12.618,31H51.382l1,2H11.618ZM32,53a39.776,39.776,0,0,1-13.257-2H45.257A39.776,39.776,0,0,1,32,53Zm19.382-4H12.618l-1-2H52.382ZM55,45H9A5,5,0,0,1,9,35H55a5,5,0,0,1,0,10Z" />
            <path d="M32,37a3,3,0,1,0,3,3A3,3,0,0,0,32,37Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,32,41Z" />
            <path d="M22,37a3,3,0,1,0,3,3A3,3,0,0,0,22,37Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,22,41Z" />
            <path d="M12,37a3,3,0,1,0,3,3A3,3,0,0,0,12,37Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,41Z" />
            <path d="M52,37a3,3,0,1,0,3,3A3,3,0,0,0,52,37Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,52,41Z" />
            <path d="M42,37a3,3,0,1,0,3,3A3,3,0,0,0,42,37Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,42,41Z" />
            <rect x="43" y="25" width="2" height="2" />
            <path d="M44.98,22.8C43.927,17.538,38.711,14,32,14v2c5.73,0,10.159,2.892,11.02,7.2Z" />
            <rect x="58" y="11" width="2" height="2" />
            <rect x="58" y="15" width="2" height="2" />
            <rect x="56" y="13" width="2" height="2" />
            <rect x="60" y="13" width="2" height="2" />
            <rect x="4" y="51" width="2" height="2" />
            <rect x="4" y="55" width="2" height="2" />
            <rect x="2" y="53" width="2" height="2" />
            <rect x="6" y="53" width="2" height="2" />
            <rect x="13" y="56" width="2" height="2" />
            <rect x="13" y="60" width="2" height="2" />
            <rect x="11" y="58" width="2" height="2" />
            <rect x="15" y="58" width="2" height="2" />
            <rect x="52" y="2" width="2" height="2" />
            <rect x="52" y="6" width="2" height="2" />
            <rect x="50" y="4" width="2" height="2" />
            <rect x="54" y="4" width="2" height="2" />
        </g>
    </svg>
)

export default PackageSpaceshipSVG
