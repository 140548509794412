import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    pointer: {
        cursor: 'pointer'
    },

    favoriteAction: {
        color: theme.palette.fg['black'],
        display: 'flex'
    },

    favorite: {
        color: 'var(--error-red)',
        display: 'flex'
    },

    heartCount: {
        fontFamily: 'GreedStandard-Regular',
        fontSize: '10px',
        lineHeight: '18px',
        minWidth: 10
    },

    disabled: {
        color: 'grey',
        display: 'flex'
    },

    smallIcon: {
        fontSize: '12px',
        padding: '4px 10px'
    },

    noFav: {
        minWidth: 9,
        minHeight: 20
    }
}))
