import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Image, Transformation } from 'cloudinary-react'

import { useStyles } from './JobfairsLandingPage_style'
import { Box, Container, Hidden, Typography } from '@material-ui/core'

import JobfairsHelmet from 'helmets/JobfairsHelmet'
import LoginRegisterPage from '../LoginRegister/LoginRegister/LoginRegisterPage'
import PageWrapper from 'shared/wrappers/PageWrapper/PageWrapper'
import SessionAPI from 'api/SessionAPI'
import URLParamsHandler from 'assets/URLParamsHandler'
import { JOB_FAIRS } from 'assets/AuthentificationHelper'

const JobfairsLandingPage = (props) => {
    const { user } = props
    const classes = useStyles()
    const [authentificationData, setAuthentificationData] = useState({})

    useEffect(() => {
        SessionAPI.GetAuthentificationData().then((response) => {
            setAuthentificationData(response.data)
        })
    }, [])

    const afterAuthAction = () => {
        URLParamsHandler.reloadPageAndAddParams({ process: JOB_FAIRS })
    }

    return (
        <PageWrapper user={user}>
            <JobfairsHelmet />
            <Container maxWidth='lg'>
                <Box className={classes.main}>
                    <Box className={classes.content}>
                        <Typography variant='h6' component='h1' className={classes.pageTitle}>
                            <FormattedMessage id='landing.job_fairs.title' />
                        </Typography>
                        <Box className={classes.divider} />
                        <Typography variant='h2'>
                            <FormattedMessage id='landing.job_fairs.sub_title' />
                        </Typography>
                        <Hidden mdUp>
                            <Box className={classes.loginForm}>
                                {user
                                    ? <Box className={classes.alreadyLoggedIn}>
                                        <Typography variant='h2' component='p'>
                                            <FormattedMessage id='landing.job_fairs.content_line1' />
                                        </Typography>
                                        <Typography variant='h2' component='p'>
                                            <FormattedMessage id='landing.job_fairs.content_line2' />
                                        </Typography>
                                        <Typography variant='h2' component='p'>
                                            <FormattedMessage id='landing.job_fairs.content_line3' />
                                        </Typography>
                                    </Box>
                                    : <LoginRegisterPage
                                        {...authentificationData}
                                        origin={JOB_FAIRS}
                                        afterAuthAction={afterAuthAction}
                                    />}
                            </Box>
                        </Hidden>
                        <Image
                            className={classes.wheelOfFortune}
                            cloudName="studentbe"
                            publicId='assets/wheel_of_fortune.png'
                            alt='congrats-status'
                        >
                            <Transformation auto='eco'/>
                            <Transformation effect="bgremoval" />
                        </Image>
                    </Box>
                    <Hidden smDown>
                        <Box className={classes.loginForm}>
                            {user
                                ? <Box className={classes.alreadyLoggedIn}>
                                    <Typography variant='h2' component='p'>
                                        <FormattedMessage id='landing.job_fairs.content_line1' />
                                    </Typography>
                                    <Typography variant='h2' component='p'>
                                        <FormattedMessage id='landing.job_fairs.content_line2' />
                                    </Typography>
                                    <Typography variant='h2' component='p'>
                                        <FormattedMessage id='landing.job_fairs.content_line3' />   
                                    </Typography>
                                </Box>
                                : <LoginRegisterPage
                                    {...authentificationData}
                                    origin={JOB_FAIRS}
                                    afterAuthAction={afterAuthAction}
                                />}
                        </Box>
                    </Hidden>
                </Box>
            </Container>
        </PageWrapper>
    )
}

export default JobfairsLandingPage
