import React from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import ItemIndexMain from 'pages/ItemIndexMain/ItemIndexMain'

class CompanyRouter extends React.Component {
    render () {
        const { formatMessage } = this.props.intl

        return (<>
            <Helmet>
                <title>{formatMessage({ id: 'helmet.first_job.company_index.title' })}</title>
                <meta property="og:title" content={formatMessage({ id: 'helmet.first_job.company_index.title' })} />
                <meta property="og:description" content={formatMessage({ id: 'helmet.first_job.company_index.description' })} />
                <meta name="description" content={formatMessage({ id: 'helmet.first_job.company_index.description' })} />
                <link rel="canonical" href={`https://www.student.be${this.props.pathname}`} />
                <link rel="alternate" hrefLang="fr-BE" href="https://www.student.be/fr/entreprises/" />
                <link rel="alternate" hrefLang="en-BE" href="https://www.student.be/en/companies/" />
                <link rel="alternate" hrefLang="nl-BE" href="https://www.student.be/nl/bedrijven/" />
                <link rel="alternate" hrefLang="x-default" href="https://www.student.be/en/companies/" />
            </Helmet>
            <ItemIndexMain
                {...this.props}
                key="companies-index-main"
                type="company"
                filterUrl="/api/v1/companies/filter"
                ads={this.props.ads}
                selectedLocationTag={this.props.selectedLocationTag}
            />
        </>)
    }
}

export default injectIntl(CompanyRouter)
