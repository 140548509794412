import React, { useState } from 'react'
import 'react-quill/dist/quill.snow.css'
import Bugsnag from '@bugsnag/js'
import ContactAPI from 'api/ContactAPI'
import { useStyles } from './ContactForm_style'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import CollectionHandler from 'assets/CollectionHandler.js'
import ProgressCircle from 'components/ProgressCircle/ProgressCircle'
import Field from 'inputs/Field/Field'
import Button from 'components/Button/Button'
import { FormattedMessage } from 'react-intl'
import TagManager from 'react-gtm-module'
import RouteMap from 'assets/RouteMap'

const ContactForm = (props) => {
    const { user } = props
    const classes = useStyles()
    const companySchool = ['company', 'school']
    const contactTypeCollection = CollectionHandler.Get('contactTypeCollection') // change the collection value with the RouteMap page ['first-jobs', 'internships', 'student-jobs', 'student-rooms']

    const [contactForm, setcontactForm] = useState({
        firstName: user?.first_name ? user.first_name : '',
        lastName: user?.last_name ? user.last_name : '',
        email: user?.email ? user.email : '',
        type: user?.user_type === 'employer' ? 'company' : (user?.user_type === 'student' ? 'student' : ''),
        organisationName: user?.company ? user.company : '',
        content: '',
        telephoneNumber: user?.phone_number ? user.phone_number : ''
    })
    const [canSend, setCanSend] = useState(false)
    const [loading, setLoading] = useState(false)
    const [sent, setSent] = useState(false)

    const validateForm = () => contactForm.type && contactForm.firstName && contactForm.lastName && contactForm.email && contactForm.content

    const handleChange = () => {
        const { name, value } = event.target

        setcontactForm({ ...contactForm, [name]: value })
        setCanSend(validateForm())
    }

    const handleSend = () => {
        setLoading(true)

        const data = {
            first_name: contactForm.firstName,
            last_name: contactForm.lastName,
            sender_email: contactForm.email,
            type: contactForm.type,
            organisation_name: contactForm.organisationName,
            content: contactForm.content,
            phone_number: contactForm.telephoneNumber
        }

        ContactAPI.SubmitContactForm(data)
            .then((response) => {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'contact-success',
                        language: RouteMap.GetLocaleFromUrl()
                    }
                })
                setLoading(false)
                setSent(true)
            })
            .catch((error) => {
                setLoading(false)
                setSent(false)
                alert('An error has occurred, please try again')
                Bugsnag.notify(error)
            })
    }

    return (
        <>
            {loading && <ProgressCircle/>}
            {sent &&
                <Box mt={8} mb={32}>
                    <Typography variant='body2'>
                        <FormattedMessage id='landing_contact_main.thanks' />
                    </Typography>
                </Box>
            }
            {!loading && !sent &&
                <Box className={classes.contactContainer}>
                    <Box className={classes.contactForm}>
                        <Box className={classes.info}>
                            <Box className={classes.item}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor='type-selection'>
                                        <FormattedMessage id='landing_contact_main.i_am' />
                                    </InputLabel>
                                    <Select
                                        native
                                        value={contactForm.type}
                                        name='type'
                                        onChange={handleChange}
                                    >
                                        <option aria-label='None' value='' />
                                        {contactTypeCollection.map((item) => (
                                            <option key={`type-selection-${item.value}`} value={item.value}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box className={classes.items}>
                                <Box className={classes.item}>
                                    <FormControl className={classes.formControl}>
                                        <FormattedMessage id='landing_contact_main.last_name'>
                                            {placeholder =>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    value={contactForm.lastName}
                                                    name='lastName'
                                                    onChange={handleChange}
                                                    label={placeholder}
                                                />
                                            }
                                        </FormattedMessage>
                                    </FormControl>

                                </Box>
                                <Box className={classes.item}>
                                    <FormControl className={classes.formControl}>
                                        <FormattedMessage id='landing_contact_main.first_name'>
                                            {placeholder =>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    value={contactForm.firstName}
                                                    name='firstName'
                                                    onChange={handleChange}
                                                    label={placeholder}
                                                />
                                            }
                                        </FormattedMessage>
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box className={classes.items}>
                                <Box className={classes.item}>
                                    <FormControl className={classes.formControl}>
                                        {companySchool.includes(contactForm.type)
                                            ? <FormattedMessage id='landing_contact_main.company_name'>
                                                {placeholder =>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        value={contactForm.organisationName}
                                                        name='organisationName'
                                                        onChange={handleChange}
                                                        label={placeholder}
                                                    />
                                                }
                                            </FormattedMessage>
                                            : <FormattedMessage id='landing_contact_main.email_address'>
                                                {placeholder =>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        value={contactForm.email}
                                                        name='email'
                                                        onChange={handleChange}
                                                        label={placeholder}
                                                    />
                                                }
                                            </FormattedMessage>
                                        }
                                    </FormControl>
                                </Box>
                                <Box className={classes.item}>
                                    <FormControl className={classes.formControl}>
                                        <FormattedMessage id='landing_contact_main.phone_number'>
                                            {placeholder =>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    value={contactForm.telephoneNumber}
                                                    name='telephoneNumber'
                                                    onChange={handleChange}
                                                    label={placeholder}
                                                />
                                            }
                                        </FormattedMessage>
                                    </FormControl>
                                </Box>
                            </Box>
                            {companySchool.includes(contactForm.type) &&
                                <Box className={classes.item}>
                                    <FormControl className={classes.formControl}>
                                        <FormattedMessage id='landing_contact_main.email_address'>
                                            {placeholder =>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    value={contactForm.email}
                                                    name='email'
                                                    onChange={handleChange}
                                                    label={placeholder}
                                                />
                                            }
                                        </FormattedMessage>
                                    </FormControl>
                                </Box>
                            }
                        </Box>
                        <Box className={classes.writeBox}>
                            <FormattedMessage id='landing_contact_main.start_write'>
                                {placeholder =>
                                    <Field
                                        type='text'
                                        name='content'
                                        value={contactForm.content}
                                        placeholder={placeholder}
                                        handleChange={handleChange}
                                    />
                                }
                            </FormattedMessage>
                        </Box>
                    </Box>
                    <Box className={classes.buttonContainer}>
                        <Button
                            variant='primary'
                            disabled={!canSend}
                            onClick={handleSend}
                        >
                            <FormattedMessage id='landing_contact_main.send' />
                        </Button>
                    </Box>
                </Box>
            }
        </>
    )
}

export default ContactForm
