/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback } from 'react'
import CompanyFiltersView from './CompanyFiltersView'
import CollectionHandler from 'assets/CollectionHandler.js'
import debounce from 'lodash.debounce'

const CompanyFilters = (props) => {
    const { handleFilterChange, offerCount, filters, setFilters, user, selectedLocationTag, type } = props
    const [searchCities, setSearchCities] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [citiesInput, setCitiesInput] = useState('')
    const [showCompanyFiltersModal, setShowCompanyFiltersModal] = useState(false)

    const cityCollection = CollectionHandler.Get('cityCollection')

    let searchText
    if (window.location.search.includes('?search=', 0)) {
        searchText = window.location.search.replace('?search=', '')
    }

    useEffect(() => {
        initSetupState()
    }, [])

    useEffect(() => {
        if (isLoaded) {
            handleFilterChange(filters)
        }
    }, [filters.location, filters.sector, filters.companySize, filters.jobType, filters.quickFilter])

    const initSetupState = () => {
        const fields = initState()

        setFilters(fields)
        setSearchCities(cityCollection)
        setIsLoaded(true)
    }

    const initState = (reset) => {
        let searchField = {}
        let locationArray = []
        let sectorArray = []
        let quickFilter = ''
        if (props.location) {
            if (props.location.type === 'location') {
                locationArray = props.location
                    ? [props.location]
                    : locationArray
            }
            if (props.location.type === 'freeText') {
                searchText = props.location.name
            }
            if (props.location.type === 'quickFilter') {
                quickFilter = props.location.value
            }
            if (props.location.type === 'sector') {
                sectorArray = [props.location]
            }
        }
        if (reset) {
            locationArray = []
        }

        searchField = {
            title: '',
            location: locationArray,
            sector: sectorArray,
            companySize: [],
            jobType: [],
            quickFilter: quickFilter
        }
        if (!reset && props.urlFilters) {
            searchField = {
                ...searchField,
                ...props.urlFilters
            }
        }

        setFilters(searchField)
        return searchField
    }

    const reset = () => {
        const newFilters = initState(true)
        setFilters(newFilters)
        handleFilterChange(newFilters)
        window.history.pushState('', '', location.pathname)
    }

    const debouncedFilterChange = useCallback(
        debounce((filters) => { handleFilterChange(filters) }, 1000), []
    )

    return (
        <>
            {isLoaded &&
                <CompanyFiltersView
                    debouncedFilterChange={debouncedFilterChange}
                    offerCount={offerCount}
                    searchCities={searchCities}
                    citiesInput={citiesInput}
                    setSearchCities={setSearchCities}
                    setCitiesInput={setCitiesInput}
                    setFilters={setFilters}
                    filters={filters}
                    selectedLocationTag={selectedLocationTag}
                    type={type}
                    handleFilterChange={handleFilterChange}
                    showCompanyFiltersModal={showCompanyFiltersModal}
                    setShowCompanyFiltersModal={setShowCompanyFiltersModal}
                    reset={reset}
                    user={user}
                />
            }
        </>
    )
}

export default CompanyFilters
