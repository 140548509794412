import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    alertContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '26px 34px',
        backgroundColor: 'var(--background-yellow)',
        borderRadius: '20px',
        gap: '17px'
    },
    alertTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center'
        }
    },
    alertButton: {
        width: '100%'
    }
}))
