import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    articleContainer: {
        width: '100%',
    },
    chipRoot: {
        height: 'fit-content',
        backgroundColor: theme.palette.bg['silver'],
        borderRadius: '42px',
        padding: '0px 27px',
        cursor: 'pointer'
    },
    chipLabel: {
        padding: 0,
        color: theme.palette.fg['black'],
        fontFamily: 'GreedStandard-Medium',
        fontSize: '8px',
        lineHeight: '15px',
        textTransform: 'capitalize',
        [theme.breakpoints.up('md')]: {
            fontSize: '12px',
            lineHeight: '18px'
        }
    },
    title: {
        paddingTop: '10px',
        display: '-webkit-box',
        lineClamp: '2',
        boxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: theme.palette.fg['black'],
        fontSize: '21px',
        lineHeight: '24px',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
            color: theme.palette.fg['purple'],
            '& span': {
                opacity: 1
            }
        }
    },
    readMore: {
        display: 'none',
        transition: 'all 0.3s ease-in-out',
        opacity: 0,
        marginLeft: 6,
        color: theme.palette.fg['purple'],
        fontSize: '12px',
        [theme.breakpoints.up('md')]: {
            display: 'inline'
        }
    },
    footer: {
        paddingTop: '20px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        columnGap: '14px'
    },
    infoContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '5px'
    },
    infoText: {
        color: theme.palette.fg['black40Opacity'],
        fontSize: '13px',
        lineHeight: '16px'
    }
}))
