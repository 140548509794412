import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    button: {
        minWidth: 'unset',
        borderRadius: '8px',
        width: '36px',
        '&:hover': {
            backgroundColor: 'var(--black-over)'
        },
        backgroundColor: 'transparent'
    },
    dropdownTitle: {
        backgroundColor: 'var(--beige)'
    },
    menuList: {
        padding: '0',
        maxHeight: '90vh',
        overflow: 'auto'
    },
    notificationIcon: {
        fontSize: '24px !important',
        color: 'white'
    }
}))
