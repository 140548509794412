import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from '../../ExperienceProfile_style'
import { FormControl, TextField, Input } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import EndAdornment from '../EndAdornment/EndAdornment'

const ExperienceSchoolInput = (props) => {
    const { inputRef, error, showEndAdornment, studentFormation, handleSchoolChange, schoolCollection, formationKey, textFontClassName, disableUnderline, placeholderTextId, inputClassName, inputUnderlineClassName } = props
    const classes = useStyles()

    return (
        <FormControl className={classes.formControl}>
            <Autocomplete
                required
                classes={{ paper: classes.customPaper }}
                disableUnderline={disableUnderline}
                disableClearable
                forcePopupIcon={false}
                options={schoolCollection}
                getOptionSelected={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                value={studentFormation[formationKey].school}
                onChange={(event, newValue) => { handleSchoolChange(newValue, formationKey) }}
                renderInput={(params) =>
                    <FormattedMessage id={placeholderTextId || 'landing_experience_profile.choose_establishment'}>
                        {placeholder =>
                            <TextField {...params}
                                required
                                placeholder={placeholder}
                                inputRef={inputRef}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: showEndAdornment && <EndAdornment />,
                                    disableUnderline: disableUnderline,
                                    classes: { input: `${textFontClassName} ${error && classes.textFieldError} ${inputClassName && inputClassName}`, underline: inputUnderlineClassName && inputUnderlineClassName }
                                }}
                            />
                        }
                    </FormattedMessage>
                }
            />
        </FormControl>
    )
}

export default ExperienceSchoolInput
