import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import IconFactory from 'icons/IconFactory/IconFactory'
import { useStyles } from './SectionTitle_style'

const SectionTitle = (props) => {
    const { variant = 'h2', component, step, title, className } = props
    const classes = useStyles()

    return (
        <>
            <Box className={`${classes.boxTitle} ${className}`}>
                <Avatar className={classes.avatarStyle}>
                    {step || <IconFactory icon='user' />}
                </Avatar>
                <Typography
                    variant={variant}
                    component={component || null}
                    color='primary'
                    className={classes.typoStyle}
                >
                    {title}
                </Typography>
            </Box>
        </>
    )
}

export default SectionTitle
