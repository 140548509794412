import React, { useState, useEffect } from 'react'

import { useStyles } from './MoreFilters_style'
import { Box, Button, useTheme } from '@material-ui/core'

import IconFactory from 'icons/IconFactory/IconFactory'

const MoreFilters = (props) => {
    const { setShowModal, filters } = props
    const classes = useStyles()
    const theme = useTheme()
    const [nbFilters, setNbFilters] = useState(0)

    useEffect(() => {
        const nbFilters = Object.values(filters).reduce((count, value) => {
            if (Array.isArray(value) && value.length > 0) {
                return count + 1
            }
            if (typeof value === 'boolean' && value) {
                return count + 1
            }
            return count
        }, 0)
        setNbFilters(nbFilters)
    }, [filters])

    return (
        <Button onClick={() => setShowModal(true)} className={classes.moreFilterButton} disableRipple>
            <IconFactory
                icon='sliders'
                color={theme.palette.fg['white']}
                className={classes.filterIcon}
            />
            {/* Applied filters count */}
            {nbFilters > 0 &&
                <Box className={classes.appliedFiltersCount}>
                    <span>{nbFilters}</span>
                </Box>
            }
        </Button>
    )
}

export default MoreFilters
