import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import KotCard from '../../cards/KotCard/KotCard'

const TOKEN = process.env.MAPBOX_SECRET_KEY
const STYLE_ID = process.env.MAPBOX_STYLE_LINK

// START IMPLEMENTATION OF TRANSLATION VIA REACT-INTL
class MapProvider extends React.Component {
    constructor (props) {
        super(props)
        this.createMap = this.createMap.bind(this)
        this.refreshMarkers = this.refreshMarkers.bind(this)
        this.flyToCard = this.flyToCard.bind(this)
        this.resize = this.resize.bind(this)
        this.focusMarker = this.focusMarker.bind(this)
        this.addCardToMarker = this.addCardToMarker.bind(this)
        console.log(this)
    }

    // CREATE MAP
    createMap () {
        mapboxgl.accessToken = TOKEN

        this.map = new mapboxgl.Map({
            container: 'map', // container id
            style: `mapbox://styles/${STYLE_ID}`, // stylesheet location // stylesheet location
            center: [4.34878, 50.85045], // starting position [lng, lat]
            zoom: 10, // starting zoom
            showZoom: true
        })
        this.map.addControl(new mapboxgl.NavigationControl())

        this.refreshMarkers()
    }

    addCardToMarker (marker) {
        const newMarker = document.getElementById('marker-popup-' + marker.properties.id)
        console.log(newMarker)
        console.log('marker: ', marker.geometry.coordinates)
        if (newMarker) {
            ReactDOM.render(<KotCard kot={marker.card_info} />, newMarker)
        }
        this.flyToCard(marker.geometry.coordinates[1], marker.geometry.coordinates[0])
    }

    refreshMarkers () {
        console.log('REFRESH MARKERS')
        mapboxgl.accessToken = TOKEN

        // deleting current markers on map
        // document.querySelectorAll('.mapboxgl-marker').forEach((marker) => {
        //     marker.remove()
        // })

        this.props.itemsGeocoding.features.forEach((marker) => {
            if (marker.geometry.coordinates[0] != null) {
                var el = document.createElement('div')
                el.insertAdjacentHTML('beforeend', `<div class="marker marker-popup-${marker.properties.id}"></div>`)
                el.className = `marker marker-${marker.properties.id}`

                const markerDiv = `<div class="cardKot" id="marker-popup-${marker.properties.id}"></div>`
                const newMarker = new mapboxgl.Marker(el, { anchor: 'bottom' })
                    .setLngLat(marker.geometry.coordinates)
                    .setPopup(new mapboxgl.Popup({ offset: 25 }) // add popups
                        .setHTML(markerDiv))
                    .addTo(this.map)

                newMarker.getElement().addEventListener('click', () => {
                    setTimeout(() => {
                        this.addCardToMarker(marker)
                    }, 100)
                })
            }
        })

        // FIX MAP SIZING ON TAB CLICK

        var resizeTab = document.querySelectorAll('.tab-list-item')
        resizeTab.forEach(function (resize) {
            resize.addEventListener('click', function () {
                setTimeout(function () { this.map.resize() }, 500)
            })
        })
        // FIX MAP SIZING ON KOT
        setTimeout(this.map.resize, 500)
    }

    flyToCard (lat, long) {
        if (this.map) {
            this.map.flyTo({
                center: [
                    long,
                    lat
                ],
                speed: 5
            })
        }
    }

    focusMarker () {
        // old not used now
        // ZOOM ON RIGHT POSITION AND FOCUS MARKER EFFECT

        var cardz = Array.from(document.querySelectorAll('.cards-and-list .cardKot'))
        var cards = cardz.concat(Array.from(document.querySelectorAll('.kot-list-card')))

        console.log('cards: ', cards)
        cards.forEach((card) => {
            console.log(card)
            card.addEventListener('mouseenter', () => {
                console.log('addEventListener', card)
                var card_id = card.id.split('_')[1]
                var cardMarker = document.querySelector(`.marker-${card_id}`)
                if (card.attributes.lat) {
                    this.map.flyTo({
                        center: [
                            card.attributes.long.value,
                            card.attributes.lat.value
                        ],
                        speed: 5
                    })
                }

                document.querySelectorAll('.marker').forEach(function (m) {
                    if (m) {
                        m.classList.remove('focusFilter')
                    }
                })
                if (cardMarker) {
                    cardMarker.classList.add('focusFilter')
                }
            })
        })
    }

    resize () {
        this.map.resize()
    }

    render () {
        return (<>
            {this.props.isCheckMap
                ? <>{this.props.children}</>
                : <div>
                    {React.Children.map(
                        this.props.children,
                        child => {
                            return React.cloneElement(child,
                                {
                                    mapProviderProps: {
                                        map: this.map,
                                        createMap: this.createMap,
                                        refreshMarkers: this.refreshMarkers,
                                        resize: this.resize,
                                        flyToCard: (lat, long) => { this.flyToCard(lat, long) },
                                        itemsGeocoding: this.props.itemsGeocoding
                                    }
                                }, null
                            )
                        }
                    )}
                </div>
            }
        </>)
    }
}

MapProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element.isRequired
    ])
}

export default MapProvider
