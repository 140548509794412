/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Bugsnag from '@bugsnag/js'

import { useStyles } from './UploadImage_style'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Grid from '@material-ui/core/Grid'

import IconFactory from 'shared/icons/IconFactory/IconFactory'
import LineButton from 'buttons/LineButton/LineButton'

import CloudinaryHelper from 'assets/CloudinaryHelper'
import CloudinaryURLHelper from 'assets/CloudinaryURLHelper'
import DocumentAPI from 'api/DocumentAPI'

// If you use this component for a single image then use the more recent UploadImageV2 instead
const UploadImage = (props) => {
    const { setImageArray, imageArray } = props
    const classes = useStyles()
    const hasMultiplePictures = props.maxPictures ? props.maxPictures > 1 : true

    const [state, setState] = useState({ imageArray: imageArray || [] })
    const [cloudy, setCloudy] = useState()

    const openModal = () => cloudy.open()

    const handleDelete = (_, key) => {
        DocumentAPI.deleteDocumentImage(state.imageArray[key].id)

        const newImageArray = state.imageArray.filter((_, index) => index !== key)
        setState({ imageArray: newImageArray })
        setImageArray(newImageArray)
    }

    useEffect(() => {
        if (cloudy) return

        setCloudy(
            CloudinaryHelper.createImageUploadWidget({
                cropping: false,
                multiple: hasMultiplePictures
            },
            (error, result) => {
                if (!error && result && result.event === 'success') {
                    try {
                        const data = {
                            cloudinary_key: result.info.public_id,
                            name: result.info.original_filename,
                            format: result.info.format,
                            is_used: true,
                            width: result.info.width,
                            height: result.info.height
                        }
                        DocumentAPI.postImage(data).then(
                            (response) => {
                                const newImageArray = state.imageArray
                                newImageArray.push({
                                    id: response.data.document_object.id,
                                    cloudinary_key: result.info.public_id,
                                    format: result.info.format,
                                    name: result.info.original_filename,
                                    is_used: true
                                })
                                setImageArray(newImageArray)

                                setState({ ...state, imageArray: newImageArray })
                            }
                        )
                    } catch (e) {
                        alert('error database')
                        Bugsnag.notify(e)
                    }
                }
            })
        )
    }, [state])

    const picturesUsed = () => {
        var count = 0
        state.imageArray.map((elem) => {
            if (elem.is_used) {
                count++
            }
        })
        return count
    }

    return (<>
        {state.imageArray.length > 0 &&
            <Box display={'flex'}>
                <Grid container spacing={5}>
                    {state.imageArray.map((element, key) => {
                        if (element.is_used) {
                            return (
                                <Box component={Grid} key={element.id} item xs={12} lg={state.imageArray.length < 4 ? (12 / state.imageArray.length) : 3 } id={'imageCloundinary' + key.toString()}>
                                    <Card style={{ maxWidth: 345 }}>
                                        <CardMedia
                                            style={{ height: 0, paddingTop: '56.25%' }}
                                            image={CloudinaryURLHelper.getImageUrl('UPLOAD_IMAGE', element.cloudinary_key)}
                                            title={element.name}
                                            alt={element.name}
                                            classes={{ root: classes.cardMedia }}
                                        />
                                        <CardContent>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Button
                                                        startIcon={ <IconFactory icon='delete' />}
                                                        color={'primary'}
                                                        variant={'outlined'}
                                                        fullWidth
                                                        size='small'
                                                        onClick={() => { handleDelete(element, key) }}
                                                    >
                                                        Supprimer
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Box>
                            )
                        }
                    })}
                </Grid>
            </Box>
        }
        <Box mt={2}>
            {props.maxPictures
                ? picturesUsed() < props.maxPictures &&
                    <LineButton
                        endIcon={<IconFactory icon='add'/>}
                        onClick={openModal}
                        color={'primary'}
                        size='small'
                        variant={'contained'}
                        name={<FormattedMessage id="landing_upload.upload" />}
                    />
                : <LineButton
                    endIcon={<IconFactory icon='add'/>}
                    onClick={openModal}
                    color={'primary'}
                    size='small'
                    variant={'contained'}
                    name={<FormattedMessage id="landing_upload.upload" />}
                />
            }
        </Box>
    </>)
}

export default UploadImage
