import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './CitiesCard_style'
import {
    Box, CardActions, Card, CardContent,
    Link, Typography, useTheme
} from '@material-ui/core'

import IconFactory from 'icons/IconFactory/IconFactory'
import RouteMap from 'assets/RouteMap.js'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const CitiesCard = (props) => {
    const { icon, title, target, citiesList, color } = props
    const locale = RouteMap.GetLocaleFromUrl()
    const classes = useStyles(color)
    const theme = useTheme()

    return (
        <Card className={classes.card}>
            {/* ### Content ### */}
            <CardContent className={classes.cardContent}>
                {/* Icon */}
                <Box className={classes.iconContainer}>{icon}</Box>
                <Typography className={classes.title} variant='h3'>
                    {title}
                </Typography>
                {/* List */}
                <ul className={classes.listContainer}>
                    {citiesList.map((city) => (
                        <li key={`${city.name} ${target} link`} className={classes.item}>
                            <RouteMapLink itemType={target} location={city.value} component='a'>
                                {city.name}
                            </RouteMapLink>
                        </li>
                    ))}
                </ul>
            </CardContent>

            {/* ### Actions ### */}
            <CardActions className={classes.cardActions}>
                <Link component='a' className={classes.linkContainer} href={RouteMap.Page(target)} hreflang={`${locale}-BE`}>
                    <Typography className={classes.linkText} variant='h1' component='p'>
                        <FormattedMessage id='page_footer_navbar_data.footer.cities_list.all_cities' />
                    </Typography>
                    <Box className={classes.linkIconContainer}>
                        <IconFactory
                            icon='arrow-right'
                            color={theme.palette.fg['white']}
                            width='10px'
                            height='10px'
                        />
                    </Box>
                </Link>
            </CardActions>
        </Card>
    )
}

export default CitiesCard
