/* eslint-disable react/prop-types */
import React from 'react'
import InfoLineView from './InfoLineView/InfoLineView'
import DateHandler from 'assets/DateHandler.js'
import { FormattedMessage } from 'react-intl'

const DeadlineLine = (props) => {
    const { job } = props
    if (!job.deadline) return null

    const getText = () => {
        const deadline = DateHandler.ToBasicFormat(job.deadline)

        return <FormattedMessage id='job_show.job_info_card.deadline' values={{ date: deadline }} />
    }

    return (
        <InfoLineView
            icon='date'
            text={getText()}
        />
    )
}

export default DeadlineLine
