import React from 'react'

const LeftLongArrowSVG = (props) => (
    <svg>
        <line
            stroke={props.fill}
            strokeMiterlimit="10"
            x1="1.65"
            y1="3.8"
            x2="52.29"
            y2="3.8"
        />
        <path
            {...props}
            d="M1.69,3.8,4.45,1a.42.42,0,0,0,0-.59.41.41,0,0,0-.59,0l-3,3a.47.47,0,0,0-.12.31.42.42,0,0,0,.12.3L3.86,7.15a.42.42,0,0,0,.59,0,.44.44,0,0,0,0-.6Z"
        />
    </svg>
)

export default LeftLongArrowSVG
