import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Image, Transformation } from 'cloudinary-react'

import { useStyles } from './JobShowBannerMobile_style'
import { Typography, Box } from '@material-ui/core'

import ApplyButton from '../../../ApplyButton/ApplyButton'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import EmployerJobIndexHelper from 'pages/user/EmployerJobIndex/EmployerJobIndexHelper'
import FavoriteAction from 'components/FavoriteAction/FavoriteAction'
import ShareAction from 'components/ShareAction/ShareAction'
import Button from 'shared/components/Button/Button.jsx'

const JobShowBannerMobile = (props) => {
    const { user, job, unavailable, toggleBoost } = props
    const classes = useStyles()
    const userHasAccessToBoost = EmployerJobIndexHelper.userHasAccessToBoost(job, user, job.current_user_is_job_owner)
    const isJobBoostable = EmployerJobIndexHelper.isJobBoostable(job)

    return (
        <Box className={classes.bannerContainer}>
            <RouteMapLink showItemType='company' showItemUrl={job.company_url}>
                <Typography component='h2' className={classes.companyName}>
                    {job.company_name?.toUpperCase()}
                </Typography>
            </RouteMapLink>
            <Typography component='h1' className={classes.jobTitle}>
                {job.title}
            </Typography>
            {!unavailable && <Box className={classes.controlsContainer}>
                <Box className={classes.leftControlsContainer}>
                    <FavoriteAction
                        userType={user?.type}
                        type={job.type}
                        id={job.id}
                        item={job}
                        size='20px'
                        padding='0px'
                    />
                    <ShareAction
                        infos={{
                            title: job.title,
                            url: window.location.href,
                            type: job.type,
                            shareText: 'transl_internship.share_button'
                        }}
                        userType={user?.type}
                        size='20px'
                        padding='0px'
                    />
                </Box>
                <Box className={classes.rightControlsContainer}>
                    <ApplyButton
                        containerClass={classes.applyButtonContainer}
                        job={job}
                        user={user}
                    />
                    {userHasAccessToBoost &&
                        <Button disabled={!isJobBoostable} onClick={toggleBoost}>
                            {isJobBoostable ? <FormattedMessage id="company_job_card.buttons.boost" /> : <FormattedMessage id="company_job_card.buttons.boosted" />}
                        </Button>}
                </Box>
            </Box>}
        </Box>
    )
}

export default JobShowBannerMobile
