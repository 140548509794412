import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from '../../ExperienceProfile_style'
import { FormControl, MenuItem, Select, useTheme } from '@material-ui/core'

import OpenIcon from '../OpenIcon/OpenIcon'

const ExperienceSelectInput = (props) => {
    const { type, inputRef, error, formation, collection, textFontClassName, disableUnderline, placeholderTextClassName, handleChange, placeholderTextId, selectClasses, iconClassName, input } = props
    const [open, setOpen] = useState(false)
    const classes = useStyles()
    const theme = useTheme()

    const selectPaperStyle = () => {
        return ({
            disableScrollLock: true,
            getContentAnchorEl: null,
            PaperProps: {
                style: {
                    maxHeight: 350,
                    // width: width,
                    boxShadow: `4px 4px 0px 0px ${theme.palette.bg['black']}`,
                    border: `2px solid ${theme.palette.fg['black']}`,
                    backgroundColor: theme.palette.bg['white'],
                    borderRadius: 16
                }
            }
        })
    }

    return (
        <FormControl className={classes.formControl} required onClick={() => setOpen(!open)}>
            <Select
                className={`${textFontClassName} ${error && classes.textFieldError}`}
                classes={selectClasses && selectClasses}
                value={formation[type]}
                inputProps={{ MenuProps: selectPaperStyle() }}
                open={open}
                disableUnderline={disableUnderline}
                onChange={handleChange}
                IconComponent={() => <OpenIcon open={open} className={iconClassName && iconClassName} />}
                displayEmpty
                inputRef={inputRef}
                input={input && input}
            >
                {formation[type] === '' &&
                    <MenuItem value=''>
                        <span className={placeholderTextClassName}>
                            <FormattedMessage id={placeholderTextId} />
                        </span>
                    </MenuItem>
                }
                {collection.map((item, itemKey) => {
                    return <MenuItem className={classes.menuItem} key={itemKey.toString()} value={item.value}>{item.name}</MenuItem>
                })}
            </Select>
        </FormControl>
    )
}

export default ExperienceSelectInput
