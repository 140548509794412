import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    topContainer: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    arrowLeft: {
        left: '11px',
    },
    arrowRight: {
        right: '11px',
    },
    arrowBtn: {
        zIndex: 2,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: '24px',
        height: '24px',
        backgroundColor: theme.palette.bg['black'],
        '&:hover': {
            backgroundColor: theme.palette.bg['black'],
        }
    },
    arrows: {
        color: 'white'
    },
    kotPriceContainer: {
        zIndex: 2,
        position: 'absolute',
        right: '12px',
        bottom: '10px',
        minWidth: '97px',
        padding: '2px 20px',
        borderRadius: '50px',
        backgroundColor: theme.palette.bg['yellow'],
    },
    kotPrice: {
        width: '100%',
        color: theme.palette.fg['black'],
        fontSize: '10px',
        lineHeight: '18px',
        textAlign: 'center'
    },
    SwipeableViews: {
        width: '100%',
        height: '100%',
        '& div': {
            height: '100%',
        }
    },
    media: {
        height: '100%'
    }
}))
