import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    title: {
        width: 'calc(300px - 32px)',
        fontSize: '16px !important'
    },
    text: {
        width: 'calc(300px - 32px)',
        fontSize: '14px !important'
    },
    buttonBox: {
        marginTop: 16
    },
    button: {
        width: '100%'
    }
}))
