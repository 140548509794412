import React, { useEffect, useState } from 'react'
import isEmpty from 'lodash.isempty'

import CollectionHandler from 'assets/CollectionHandler.js'
import DateHandler from 'assets/DateHandler'
import StudentProfilePageView from './StudentProfilePageView'
import UserAPI from 'api/UserAPI'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import ExperienceHelper from 'assets/ExperienceHelper'

import { FormattedMessage } from 'react-intl'

const StudentProfilePage = (props) => {
    const {
        address,
        documents,
        experiences,
        pictureId,
        profile,
        user,
        userLanguages
    } = props

    const schoolCollection = CollectionHandler.Get('schoolCollection')
    const studyFieldCollection = CollectionHandler.Get('studyFieldCollection')
    const hasExperience = !isEmpty(experiences)
    const [loading, setLoading] = useState(false)
    const [feedback, setFeedback] = useState({ open: false, severity: 'success', message: null })
    const [imageArray, setImageArray] = useState(documents || [])
    const [student, setStudent] = useState({
        first_name: user.first_name,
        first_nameError: false,
        first_nameErrorMessage: '',
        last_name: user.last_name,
        last_nameError: false,
        last_nameErrorMessage: '',
        gender: profile.gender ? profile.gender : 0,
        genderError: false,
        genderErrorMessage: '',
        phoneNumber: profile.phone_number ? profile.phone_number : '',
        phoneNumberError: false,
        phoneNumberErrorMessage: '',
        street: address ? address.street : '',
        streetError: false,
        streetErrorMessage: '',
        zip: address ? address.zip : '',
        zipError: false,
        zipErrorMessage: '',
        city: address ? address.city : '',
        cityError: false,
        cityErrorMessage: '',
        country: address ? address.country : '',
        countryError: false,
        countryErrorMessage: '',
        intro: profile.intro ? profile.intro : '',
        introError: false,
        introErrorMessage: '',
        drivingLicence: profile.driving_licence ? profile.driving_licence : false,
        car: profile.car ? profile.car : false,
        bike: profile.bike ? profile.bike : false,
        FR: userLanguages.fr || null,
        EN: userLanguages.en || null,
        NL: userLanguages.nl || null,
        languageError: false,
        documents: imageArray,
        birthDateError: false,
        birthDateErrorMessage: ''
    })
    const [studentFormation, setStudentFormation] = useState([])
    const [experienceIsUpdated, setExperienceIsUpdated] = useState(false)
    const [studentLanguage, setStudentLanguage] = useState([])
    const [selectedDate, setSelectedDate] = useState(profile.birthdate ? new Date(profile.birthdate) : null)

    const blankLanguage = {
        name: '',
        skill_weight: '',
        deleted: false
    }

    useEffect(() => {
        const initializedExperiences = ExperienceHelper.InitializeExperiences(experiences)

        setStudentFormation(initializedExperiences)
    }, [])

    useEffect(
        () => {
            userLanguages.other_languages.map(
                (lng) => {
                    studentLanguage.push({
                        name: lng.name.toUpperCase(),
                        skill_weight: lng.skill_weight,
                        deleted: false
                    })
                }
            )
            setStudentLanguage([...studentLanguage])
        }, []
    )

    const handleDateChange = (date) => {
        setSelectedDate(date)
    }

    const handleDateBlur = prop => (event) => {
        if (event.target.value !== '') {
            setStudent({ ...student, birthDateError: false, birthDateErrorMessage: '' })
        } else {
            setStudent({ ...student, birthDateError: true, birthDateErrorMessage: 'Incorrect input' })
        }
    }

    const validateDate = () => {
        const isValidDate = selectedDate instanceof Date && !isNaN(selectedDate.getTime())

        if (isValidDate) {
            return true
        } else {
            setStudent({ ...student, birthDateError: true, birthDateErrorMessage: 'Incorrect input' })
            return false
        }
    }

    const validateLanguageForm = () => {
        const allLanguagesPresent = student.FR && student.NL && student.EN
        if (allLanguagesPresent) return true

        setStudent({ ...student, languageError: true })
        return false
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        setLoading(true)

        if (!validateLanguageForm()) return setLoading(false)
        if (!validateDate()) return setLoading(false)

        // Untouched saved lastFormation var for Analytics usage
        const lastFormation = Object.assign({}, studentFormation.reduce((acc, exp) => {
            return (acc = acc.end_year > exp.end_year ? acc : exp)
        }))

        studentFormation.forEach(
            (formation, key) => {
                formation.field_of_study = formation.field_of_study ? formation.field_of_study.value : ''
                formation.school = formation.school ? formation.school.name : ''
                formation.start_year = formation.start_year ? new Date(formation.start_year).getFullYear() : null
                formation.end_year = formation.end_year ? new Date(formation.end_year).getFullYear() : null
            }
        )
        const newLang = []
        studentLanguage.forEach(
            (langue, key) => {
                if (langue.name === '' && langue.skill_weight !== '') {
                    studentLanguage[key].deleted = true
                }
                if (!langue.deleted) {
                    newLang.push(langue)
                }
            }
        )
        const profiledata = {
            first_name: student.first_name,
            last_name: student.last_name,
            activelyLookingStudentJob: student.actively_looking_student_job,
            activelyLookingInternship: student.actively_looking_internship,
            activelyLookingFirstJob: student.actively_looking_first_job,
            activelyLookingKot: student.actively_looking_kot,
            notLookingAnyJob: student.not_looking_any_job,
            gender: student.gender,
            phone_number: student.phoneNumber,
            street: student.street,
            city: student.city,
            country: student.country,
            zip: student.zip,
            birthdate: DateHandler.ToBasicFormat(selectedDate),
            car: student.car,
            bike: student.bike,
            driving_licence: student.drivingLicence,
            experiences: studentFormation,
            EN: student.EN,
            FR: student.FR,
            NL: student.NL,
            document: student.documents ? student.documents : [],
            other_languages: newLang
        }

        UserAPI.UserEditMyProfile(profiledata)
            .then(() => {
                const analObject = AnalyticsHelper.constructAccountUpdatedAnalyticsAttributes(
                    user,
                    student,
                    lastFormation,
                    selectedDate,
                    'StudentProfilePage'
                )
                experienceIsUpdated && ExperienceHelper.SendExperienceUpdatedGTMEvent(hasExperience, analObject)
                AnalyticsHelper.sendGTMEvent('Account Updated', analObject)

                let study = ''
                let school = ''
                studentFormation.forEach(
                    (formation) => {
                        study = studyFieldCollection.find((element) => element.value === formation.field_of_study)
                        school = schoolCollection.find((element) => element.name === formation.school)
                        formation.field_of_study = study || ''
                        formation.school = school || ''
                        formation.start_year = formation.start_year ? new Date(formation.start_year.toString()) : null
                        formation.end_year = formation.start_year ? new Date(formation.end_year.toString()) : null
                    }
                )
                handleClick()
            })
    }

    const handleClick = () => {
        setFeedback({
            ...feedback,
            open: true,
            message: <FormattedMessage id='landing_student_profile_page.success' />
        })
        window.location.reload()
    }

    const closeFeedback = () => {
        setFeedback({ ...feedback, open: false })
    }

    return (
        <StudentProfilePageView
            blankLanguage={blankLanguage}
            closeFeedback={closeFeedback}
            disabledSave
            feedback={feedback}
            handleDateBlur={handleDateBlur}
            handleDateChange={handleDateChange}
            handleSubmit={handleSubmit}
            imageArray={imageArray}
            loading={loading}
            pictureId={pictureId}
            user={user}
            profile={profile}
            selectedDate={selectedDate}
            setExperienceIsUpdated={setExperienceIsUpdated}
            setImageArray={setImageArray}
            setStudent={setStudent}
            setStudentFormation={setStudentFormation}
            setStudentLanguage={setStudentLanguage}
            student={student}
            studentFormation={studentFormation}
            studentLanguage={studentLanguage}
        />
    )
}

export default StudentProfilePage
