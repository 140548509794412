import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from '../../ExperienceProfile_style'
import { FormControl, MenuItem, Select, useTheme } from '@material-ui/core'

const ExperienceCampusSelectInput = (props) => {
    const { type, inputRef, error, formation, formationKey, placeholderTextClassName, disableUnderline, textFontClassName, handleChange, placeholderTextId, selectClasses, input } = props
    const classes = useStyles()
    const theme = useTheme()

    const selectPaperStyle = () => {
        return ({
            disableScrollLock: true,
            getContentAnchorEl: null,
            PaperProps: {
                style: {
                    maxHeight: 350,
                    // width: width,
                    boxShadow: `4px 4px 0px 0px ${theme.palette.bg.black}`,
                    border: `2px solid ${theme.palette.fg.black}`,
                    backgroundColor: theme.palette.bg.white,
                    borderRadius: 16,
                }
            }
        })
    }

    return (
        <FormControl className={classes.formControl} required >
            <Select
                className={`${textFontClassName} ${error && classes.textFieldError}`}
                classes={selectClasses && selectClasses}
                value={formation[type]}
                inputProps={{ MenuProps: selectPaperStyle() }}
                onChange={handleChange(formationKey, type)}
                displayEmpty
                disableUnderline={disableUnderline}
                inputRef={inputRef}
                input={input && input}
            >
                <MenuItem value=''>
                    <span className={placeholderTextClassName}>
                        <FormattedMessage id={placeholderTextId} />
                    </span>
                </MenuItem>
                {formation.campuses.map((campus, campusKey) => {
                    return (
                        <MenuItem
                            className={classes.menuItem}
                            key={campusKey.toString()}
                            value={`${campus.location}${campus.name ? '|' + campus.name : ''}`}
                        >
                            {`${campus.location} ${campus.name ? ' (' + campus.name + ')' : ''}`}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

export default ExperienceCampusSelectInput
