import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		position: 'relative',
	},
	label: {
		fontFamily: 'GreedStandard-medium',
		fontSize: '16px',
	},
	containerSelect: {
		display: 'flex',
		alignItems: 'center',
		gap: '4px',
		cursor: 'pointer',
	},
	containerOptions: {
		position: 'absolute',
		top: '33px',
		backgroundColor: 'white',
		minWidth: '136px',
		boxShadow: '5px 5px 0px 0px black',
		border: '2px solid black',
		borderRadius: '20px',
		zIndex: 300,
		display: 'flex',
		flexDirection: 'column',
		padding: '24px',
		gap: '16px',
	},
	option: {
		border:'3px solid white',
		cursor:'pointer',
		fontFamily: 'GreedStandard-medium',
		color: 'black',
		fontSize: '16px',
		whiteSpace: 'nowrap',
		'&:hover': {
			color:theme.palette.bg['purple']
		},
	},
	containerOptionsTop:{
		margin:'13px 0px',
		top: 'auto',
		right:'0px',
		bottom:'100%',
	}
}));


