import React, { useState, useCallback, useEffect, useRef, useContext } from 'react'
import Bugsnag from '@bugsnag/js'
import { FormattedMessage } from 'react-intl'
import { Image, Transformation } from 'cloudinary-react'
import { Box, Container, Grid, Hidden, Typography } from '@material-ui/core'

import AdBanner from 'components/AdBanner/AdBanner'
import ArticleShowCard from './components/ArticleShowCard/ArticleShowCard'
import ArticleContent from './components/ArticleContent/ArticleContent'
import ArticleSimilarJobs from './components/ArticleSimilarJobs/ArticleSimilarJobs'
import ArticleSimilarArticles from './components/ArticleSimilarArticles/ArticleSimilarArticles'
import ArticleShowHelmet from 'helmets/ArticleShowHelmet'
import CompanyCard from 'cards/CompanyCard/CompanyCard'
import FeedbackSnackbar from 'components/FeedbackSnackbar/FeedbackSnackbar'
import IconFactory from 'shared/icons/IconFactory/IconFactory'
import ObjectTagList from 'components/ObjectTagList/ObjectTagList'
import Button from 'components/Button/Button'
import CTAAlert from 'components/CTAAlert/CTAAlert'
import ProgressBar from 'shared/components/ProgressBar/ProgressBar'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import URLParamsHandler from 'assets/URLParamsHandler'
import { LARGE_SIZE, optimiseSizeForMobile } from 'assets/CloudinaryURLHelper'
import { LoginRegisterContext } from 'shared/contexts/LoginRegisterContext'
import { useStyles } from './articleShow_style'
import ArticleReaction from './components/ArticleReaction/ArticleReaction'
import EmployerContactForm from 'components/EmployerContactForm/EmployerContactForm'
import EmployerLeadGenerationModal from 'shared/modals/EmployerLeadGenerationModal/EmployerLeadGenerationModal'
import { usePipedrive, CHAT_EMPLOYER_ARTICLES } from 'hooks/usePipedrive'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { ARTICLE_SHOW_BUTTON } from 'assets/AlertsHelper'

const ArticleShow = (props) => {
    const SAP_ARTICLE_ID = 2705
    const EXPIRY_IN_HOURS = 12
    const COMPLETION_PERCENTAGE = 100
    const TRIGGER_MODAL_PERCENTAGE = 70
    const classes = useStyles()
    const { user, article, ads } = props
    const context = useContext(LoginRegisterContext)
    const articleContentRef = useRef(null)
    const [feedback, setFeedback] = useState({ open: false, severity: 'success', message: null })
    const [showedleadGenerationPopup, setShowedLeadGenerationPopup] = useState(useLocalStorage('showedleadGenerationPopup'))
    const [leadGenerationPopupOpen, setLeadGenerationPopupOpen] = useState(false)
    const [sendEbookAfterLogin, setSendEbookAfterLogin] = useState(article.ebook?.id && user && URLParamsHandler.loadProcessFromURL() === 'download_ebook')
    const [isArticleWithEmployerTag] = useState(article.tag.some(tag => tag.value === 'employer'))
    const onScroll = useCallback(() => {
        handleScroll()
    }, [showedleadGenerationPopup])

    useEffect(() => {
        sendGTMEvent()

        if (isArticleWithEmployerTag) {
            const { instantiateChat } = usePipedrive()
            instantiateChat(CHAT_EMPLOYER_ARTICLES)
        }

        if (sendEbookAfterLogin) {
            downloadEbook()
            setSendEbookAfterLogin(false)
        }

        document.body.style.overflow = 'visible'

        if (shouldRenderClientIframe()) {
            const script = document.createElement('script')
            script.src = '//js.hsforms.net/forms/embed/v2.js'
            script.async = true
            document.body.appendChild(script)

            script.addEventListener('load', () => {
                if (window.hbspt) {
                    window.hbspt.forms.create({
                        portalId: '155206',
                        formId: '674f79a2-0e3b-4755-8de1-c38df3392ce4',
                        region: 'na1',
                        target: '#hubspotForm'
                    })
                }
            })
        }

        return () => document.body.style.overflow = 'hidden'
    }, [sendEbookAfterLogin, onScroll])

    useEffect(() => {
        if (isArticleWithEmployerTag) document.addEventListener('scroll', onScroll)

        return () => {
            if (isArticleWithEmployerTag) document.removeEventListener('scroll', onScroll)
        }
    }, [onScroll])

    const handleScroll = () => {
        const target = articleContentRef.current
        const winTop = window.pageYOffset || document.documentElement.scrollTop
        const targetBottom = target.offsetTop + target.scrollHeight
        const windowBottom = winTop + window.outerHeight
        const articleCompletion = COMPLETION_PERCENTAGE - (((targetBottom - windowBottom + window.outerHeight / 4) / (targetBottom - window.outerHeight + window.outerHeight / 4)) * COMPLETION_PERCENTAGE)
        const newProgress = Math.min(parseInt(articleCompletion), COMPLETION_PERCENTAGE)

        if (!showedleadGenerationPopup && newProgress > TRIGGER_MODAL_PERCENTAGE) {
            updateLocalStorage('showedleadGenerationPopup', true)
            setLeadGenerationPopupOpen(true)
        }
    }

    const updateLocalStorage = (key, value) => {
        try {
            const itemStr = localStorage.getItem(key)
            if (!itemStr) {
                const expiryTime = new Date().getTime() + EXPIRY_IN_HOURS * 60 * 60 * 1000
                const item = {
                    value: value,
                    expiry: expiryTime
                }
                localStorage.setItem(key, JSON.stringify(item))
            } else {
                const item = JSON.parse(itemStr)
                item.value = value
                localStorage.setItem(key, JSON.stringify(item))
            }
            setShowedLeadGenerationPopup(value)
        } catch (error) {
            Bugsnag.notify(error)
        }
    }

    const sendGTMEvent = () => {
        AnalyticsHelper.sendGTMEvent(
            'Article Details Viewed',
            AnalyticsHelper.constructArticleDetailsViewedAnalyticsAttributes(user, article)
        )
    }

    const closeLeadGenerationModal = () => {
        setLeadGenerationPopupOpen(false)
    }

    const closeFeedback = () => {
        setFeedback({ ...feedback, open: false })
    }

    const afterAuthAction = () => {
        URLParamsHandler.reloadPageAndAddParams({ process: 'download_ebook' })
    }

    const createLeaderBoardAd = (placeIndex) => {
        switch (article.tag[0] && article.tag[0].value) {
        case 'first_job':
            return <AdBanner ads={ads} place={'Blog_Show_Firstjobs_Leaderboard_' + placeIndex} device='desktop' width={728} />
        case 'internship':
            return <AdBanner ads={ads} place={'Blog_Show_Internships_Leaderboard_' + placeIndex} device='desktop' width={728} />
        case 'student_job':
            return <AdBanner ads={ads} place={'Blog_Show_Studentjobs_Leaderboard_' + placeIndex} device='desktop' width={728} />
        case 'kot':
            return <AdBanner ads={ads} place={'Blog_Show_Kots_Leaderboard_' + placeIndex} device='desktop' width={728} />
        default:
            return <AdBanner ads={ads} place={'Blog_Show_Leaderboard_' + placeIndex} device='desktop' width={728} />
        }
    }

    const clickNotConnected = () => {
        AnalyticsHelper.sendGTMEvent('ebook-register-click')
        context.openLoginRegisterModal({
            origin: 'ebook_download',
            afterAuthAction: afterAuthAction
        })
    }

    const downloadEbook = () => {
        AnalyticsHelper.sendGTMEvent('ebook-download-click')
        window.open(article.ebook.url, '_blank') || window.location.replace(article.ebook.url)
    }

    const shouldRenderClientIframe = () => {
        return article.id === SAP_ARTICLE_ID
    }

    return (<>
        <Container maxWidth='lg'>
            <ProgressBar ref={articleContentRef} />
            <ArticleShowHelmet article={article} />
            <FeedbackSnackbar
                open={feedback.open}
                message={feedback.message}
                severity={feedback.severity}
                closeFeedback={closeFeedback}
            />
            <EmployerLeadGenerationModal
                showModal={leadGenerationPopupOpen}
                closeModal={closeLeadGenerationModal}
            />
            <Box className={classes.mainGridContainer}>
                <Box mt={2} className={classes.leaderBoardAd}>
                    {createLeaderBoardAd(1)}
                </Box>
                <article itemScope={true} itemType="https://schema.org/Blog">
                    <ArticleShowCard
                        title={article.title}
                        date={article.date}
                        id={article.id}
                        readingTime={article.read_time}
                        isOwner={user && user.company_id === article.company_id && user.create_articles}
                        isAdmin={user && user.admin === true}
                        companyName={article.company_name}
                        companyUrl={article.company_url}
                    />
                    <Box className={classes.articleContentContainer}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={8}>
                                <Box className={`${classes.articleTextContainer} acticle-content-container`} ref={articleContentRef}>
                                    {article.ebook.id &&
                                        <Box className={classes.ebookDownloadContainer}>
                                            <Typography variant='h3' component='p'>
                                                <FormattedMessage id='landing_article_show.download_ebook.download_title' />
                                            </Typography>
                                            {user
                                                ? <Button
                                                    variant='cta-primary'
                                                    size='small'
                                                    startIcon={<IconFactory icon='download' />}
                                                    onClick={downloadEbook}
                                                    className={classes.ebookDownloadButton}
                                                >
                                                    <IconFactory icon='download' />
                                                    <FormattedMessage id='landing_article_show.download_ebook.download_ebook' />
                                                </Button>
                                                : <Button
                                                    variant='cta-primary'
                                                    size='small'
                                                    onClick={clickNotConnected}
                                                    className={classes.ebookDownloadButton}
                                                >
                                                    <IconFactory icon='download' />
                                                    <FormattedMessage id='landing_article_show.download_ebook.not_logged_in' />
                                                </Button>
                                            }
                                        </Box>
                                    }
                                    {article.banner_image && article.banner_image.cloudinary_key &&
                                        <Image
                                            cloudName="studentbe"
                                            publicId={article.banner_image.cloudinary_key}
                                            alt={`${article.title} banner image`}
                                            className={classes.imageStyle}
                                            width={'100%'}
                                        >
                                            <Transformation
                                                flags="progressive"
                                                fetchFormat="auto"
                                                quality="auto:best"
                                                width={optimiseSizeForMobile(LARGE_SIZE)}
                                                crop="scale"
                                            />
                                        </Image>
                                    }
                                    <ArticleContent content={article.content} />
                                    <Box>
                                        <div id='hubspotForm'></div>
                                    </Box>
                                </Box>
                                <Hidden smUp>
                                    <ArticleReaction articleId={article.id} />
                                    {article.company?.name.toLowerCase() !== 'student.be' &&
                                        <Box my={2} width='fit-content'>
                                            <CompanyCard company={article.company} />
                                        </Box>
                                    }
                                </Hidden>
                                {article.tags_linked_topics && <>
                                    <Box mt={3} mb={2}>
                                        <Typography variant='h2'>
                                            <FormattedMessage id="landing_article_show.same_subject" />
                                        </Typography>
                                    </Box>
                                    <ObjectTagList
                                        collection={article.tags_linked_topics}
                                        onRef={(ref) => {}}
                                        articleTag={true}
                                    />

                                </>}
                                {createLeaderBoardAd(1)}
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.rightColumn}>
                                <Hidden smDown>
                                    {article.company && article.company.name.toLowerCase() !== 'student.be' &&
                                        <CompanyCard company={article.company} />
                                    }
                                    <CTAAlert
                                        componentType='default'
                                        className={classes.CTAAlert}
                                        type='student_job'
                                        user={user}
                                        origin={ARTICLE_SHOW_BUTTON}
                                    />
                                    <ArticleReaction articleId={article.id} />
                                </Hidden>
                                <AdBanner ads={ads} place='Article_Wide Skyscraper' device='desktop' width={300} />
                            </Grid>
                        </Grid>
                        {isArticleWithEmployerTag &&
                            <Box my={2}>
                                <EmployerContactForm color='var(--black)' background='var(--beige)' />
                            </Box>
                        }
                    </Box>
                </article>
            </Box>
        </Container>
        <Box className={classes.recommendedJobsBox}>
            <ArticleSimilarJobs article={article}/>
        </Box>
        <Box my={3}>
            <ArticleSimilarArticles article={article} userType={user && user.type}/>
        </Box>
    </>)
}

export default ArticleShow
