import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    firstFiltersRow: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        columnGap: '13px',
        [theme.breakpoints.up('lg')]: {
            columnGap: '8px',
        },
    },

    internshipSearchFieldContainer: {
        flex: 1,
        [theme.breakpoints.up('sm')]: {
            maxWidth: '268px',
        },
    },

    cityFieldContainer: {
        width: '154px',
    },

    companySizeFieldContainer: {
        width: '183px',
    },

    moreFiltersButtonContainer: {

    },

    resetFiltersButtonContainer: {
        paddingLeft: '3px',
    },

    studyDomainField: {
        width: '164px',
        [theme.breakpoints.up('md')]: {
            width: '183px',
        },
    }
}))
