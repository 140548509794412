import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Typography, Dialog, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core'

import { useStyles } from './JoinCompanyModal_style'
import CompanyAPI from 'api/CompanyAPI'
import RouteMap from 'assets/RouteMap.js'
import URLParamsHandler from 'assets/URLParamsHandler'
import Divider from 'shared/components/Divider/Divider'
import IconFactory from 'shared/icons/IconFactory/IconFactory'
import Button from 'shared/components/Button/Button'

const JoinCompanyModal = (props) => {
    const classes = useStyles()
    const { matchingCompanies, employerModalOpen, userStoredLocation, companyId, newCompanyName, fullName } = props
    const [selectedCompanyId, setSelectedCompanyId] = useState(companyId)

    useEffect(() => {
        const suggestedOption = matchingCompanies.find(company => company.predicted_main_account)
        if (suggestedOption) setSelectedCompanyId(suggestedOption.id)
    }, [matchingCompanies])

    const handleSubmit = () => {
        const data = {
            selectedCompanyId: selectedCompanyId,
            companyId: companyId
        }
        if (companyId === selectedCompanyId) {
            window.location.href = `${RouteMap.Page('users')}welcome?redirect=${userStoredLocation}`
        } else {
            CompanyAPI.JoinParentCompany(data).then(_ => {
                if (URLParamsHandler.parseURLParams().redirect) {
                    URLParamsHandler.redirectWithURL()
                } else {
                    window.location.href = `${RouteMap.Page('users/company_jobs')}?redirect=${userStoredLocation}`
                }
            })
        }
    }

    const handleCompanySelection = (selectedId) => {
        setSelectedCompanyId(selectedId)
    }

    return (
        <Dialog open={employerModalOpen} PaperProps={{ className: classes.dialogContainer }}>
            <DialogTitle classes={{ root: classes.dialogTitleRoot }}><FormattedMessage id="join_company_modal.title" /></DialogTitle>
            <DialogContent classes={{ root: classes.dialogContentRoot }}>
                <DialogContentText classes={{ root: classes.dialogContentTextRoot }}>
                    <FormattedMessage id="join_company_modal.explanation" />
                </DialogContentText>
                <Box className={classes.jobSelectionForm}>
                    <Divider textAlign="left" className={classes.dividerText}>
                        <FormattedMessage id="join_company_modal.section_title.join" />
                    </Divider>
                    {matchingCompanies.map(company => {
                        const isSelected = company.id === selectedCompanyId
                        return (
                            <Box
                                className={`
                                    ${classes.companyOption}
                                    ${isSelected ? classes.selectedCompany : ''}
                                `}
                                onClick={() => handleCompanySelection(company.id)}
                                key={company.id}
                            >
                                <Typography component='span' className={`${classes.optionDetails} ${classes.companyName}`}>
                                    <IconFactory fontSize={14} icon='company' />
                                    {company.name}
                                </Typography>
                                <Typography component='span' className={`${classes.optionDetails} ${classes.userName}`}>
                                    <IconFactory fontSize={14} icon='user' />
                                    {company.user}
                                </Typography>
                            </Box>
                        )
                    })}
                    <Divider textAlign="left" className={classes.dividerText}>
                        <FormattedMessage id="join_company_modal.section_title.create_new" />
                    </Divider>
                    <Box
                        className={`
                            ${classes.companyOption}
                            ${selectedCompanyId === companyId ? classes.selectedCompany : ''}
                        `}
                        onClick={() => handleCompanySelection(companyId)}
                    >
                        <Typography component='span' className={`${classes.optionDetails} ${classes.companyName}`}>
                            <IconFactory fontSize={14} icon='company' />
                            {newCompanyName}
                        </Typography>
                        <Typography component='span' className={`${classes.optionDetails} ${classes.userName}`}>
                            <IconFactory fontSize={14} icon='user' />
                            {fullName}
                        </Typography>
                    </Box>
                    <Box className={classes.submitButton}>
                        <Button variant='cta-secondary' size='small' onClick={handleSubmit}>
                            <FormattedMessage id='landing_login_page.confirm' />
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default JoinCompanyModal
