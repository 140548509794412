/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'

import { useStyles } from './JobShowBanner_style'
import { Hidden, Box, Fade } from '@material-ui/core'

import JobShowBannerDesktop from './components/JobShowBannerDesktop/JobShowBannerDesktop'
import JobShowBannerDesktopSticky from './components/JobShowBannerDesktopSticky/JobShowBannerDesktopSticky'
import JobShowBannerMobile from './components/JobShowBannerMobile/JobShowBannerMobile'
import JobShowBannerMobileSticky from './components/JobShowBannerMobileSticky/JobShowBannerMobileSticky'
import BoostCompanyJobModal from 'shared/modals/BoostCompanyJobModal/BoostCompanyJobModal'
import FeedbackSnackbar from 'components/FeedbackSnackbar/FeedbackSnackbar'

const SCROLL_PIXELS_FOR_STICKY_NAV = 80

const JobShowBanner = (props) => {
    const { job, user, unavailable } = props
    const classes = useStyles()
    const [isSticky, setIsSticky] = useState(false)
    const [showBoostCompanyJobModal, setShowBoostCompanyJobModal] = useState(false)
    const [creditCount, setCreditCount] = useState(user?.number_of_credits)
    const [feedback, setFeedback] = useState({ open: false, severity: 'success', message: null })

    useEffect(() => {
        const handleScroll = () => {
            (window.scrollY > SCROLL_PIXELS_FOR_STICKY_NAV) ? setIsSticky(true) : setIsSticky(false)
        }

        window.addEventListener('scroll', handleScroll)

        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    const toggleActiveJobStatus = () => {
        window.location.reload()
    }
    const closeFeedback = () => setFeedback({ ...feedback, open: false })
    const toggleBoost = () => setShowBoostCompanyJobModal(true)

    return (
        <>
            <BoostCompanyJobModal
                showModal={showBoostCompanyJobModal}
                setShowModal={setShowBoostCompanyJobModal}
                setFeedback={setFeedback}
                jobToBoost={job}
                creditCount={creditCount}
                setCreditCount={setCreditCount}
                toggleActiveJobStatus={toggleActiveJobStatus}
                user={user}
                company={job.company}
                filters={{}}
            />
            <FeedbackSnackbar
                open={feedback.open}
                message={feedback.message}
                severity={feedback.severity}
                closeFeedback={closeFeedback}
                autoHideDuration={null}
            />
            <Box className={classes.viewMonitor} />
            <Box className={classes.jobShowBanner}>
                <Hidden smDown>
                    <Fade in={!isSticky} timeout={500}>
                        <Box>{!isSticky ? <JobShowBannerDesktop job={job} user={user} unavailable={unavailable} toggleBoost={toggleBoost} /> : null}</Box>
                    </Fade>
                    <Fade in={isSticky} timeout={500}>
                        <Box>{isSticky ? <JobShowBannerDesktopSticky job={job} user={user} unavailable={unavailable} /> : null}</Box>
                    </Fade>
                </Hidden>
                <Hidden mdUp>
                    <Fade in={!isSticky} timeout={500}>
                        <Box>{!isSticky ? <JobShowBannerMobile job={job} user={user} unavailable={unavailable} toggleBoost={toggleBoost} /> : null}</Box>
                    </Fade>
                    <Fade in={isSticky} timeout={500}>
                        <Box>{isSticky ? <JobShowBannerMobileSticky job={job} user={user} unavailable={unavailable} /> : null}</Box>
                    </Fade>
                </Hidden>
            </Box>
        </>
    )
}

export default JobShowBanner
