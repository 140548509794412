import confetti from 'canvas-confetti'

const ConfettiCannon = () => {
    const galaConfetti = () => {
        const duration = 5 * 1000
        const animationEnd = Date.now() + duration
    
        const interval = setInterval(function() {
            const timeLeft = animationEnd - Date.now()
            confetti({particleCount: 10, angle: 60, spread: 35, origin: { x: 0 }})
            confetti({particleCount: 10, angle: 120, spread: 35, origin: { x: 1 }})
    
            if (timeLeft <= 0) {
                return clearInterval(interval)
            }
        }, 200)
    }
    
    const fireworkConfetti = () => {
        const duration = 5 * 1000
        const animationEnd = Date.now() + duration
        const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 }
    
        const randomInRange = (min, max) => {
            return Math.random() * (max - min) + min
        }
    
        const interval = setInterval(function() {
        const timeLeft = animationEnd - Date.now()
    
        if (timeLeft <= 0) {
            return clearInterval(interval)
        }
    
        const particleCount = 50 * (timeLeft / duration)
            confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } })
            confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } })
        }, 250)
    }
    
    const curtainConfetti = () => {
        confetti({
            particleCount: 300,
            spread: 145,
            ticks: 500,
            origin: { y: -0.1 }
        })
    }
    
    const defaultConfetti = () => {
        confetti({
            particleCount: 300,
            spread: 90,
            origin: { y: 0.6 }
        })
    }
    
    const fire = () => {
        const possibleFireworks = [galaConfetti, fireworkConfetti, curtainConfetti, defaultConfetti]
        const randomIndex = Math.floor(Math.random() * possibleFireworks.length)
        possibleFireworks[randomIndex]()
    }

    fire()
}

export default ConfettiCannon
