import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './CompanyInfoCard_style'
import { Box, Typography, useTheme } from '@material-ui/core'

import IconFactory from 'icons/IconFactory/IconFactory'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import ExpandingText from 'shared/components/ExpandingText/ExpandingText'

const CompanyInfoCard = (props) => {
    const { job } = props
    const classes = useStyles()
    const theme = useTheme()

    return (
        <Box className={classes.companyInfoCardContainer}>
            <Typography variant='h1' component='h2' className={classes.title}>
                <FormattedMessage id='job_show.company_info_card.title_prefix' /> {job?.company_name}
            </Typography>
            <ExpandingText text={job?.company_description} />
            <RouteMapLink
                showItemType='company'
                showItemUrl={job.company_url}
                className={classes.companyPageLinkContainer}
            >
                <Typography variant='h1' component='p' className={classes.companyPageLinkText}>
                    <FormattedMessage id='job_show.company_info_card.company_page_link_title' />
                </Typography>
                <Box className={classes.linkIconContainer}>
                    <IconFactory
                        icon='arrow-right'
                        color={theme.palette.fg.white}
                        width='10px'
                        height='10px'
                    />
                </Box>
            </RouteMapLink>
        </Box>
    )
}

export default CompanyInfoCard
