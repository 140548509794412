import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: 'transparent'
    },
    mobileIcon: {
        fontSize: '20px !important',
        color: 'white'
    },
    loginButton: {
        padding: '8px 17px',
        borderRadius: '8px',
        height: '32px',
        boxShadow: 'none',
        '& p': {
            fontFamily: "'GintoNormal-Medium', sans-serif !important",
            fontSize: 12,
            lineHeight: '18.33px'
        },
        '&:hover': {
            boxShadow: 'none'
        }
    },
    gridItem: {
        display: 'flex',
        alignItems: 'center'
    },
    logoContainer: {
        justifyContent: 'center'
    },
    logoImage: {
        width: '112px'
    },
    userContainer: {
        justifyContent: 'flex-end'
    }
}))
