/* eslint-disable react/prop-types */
/* eslint-disable camelcase */

import React, { useState, useEffect } from 'react'

import { Image } from 'cloudinary-react'

/* eslint-disable no-unused-vars */
import css from './companypicturesmanagerpopup.scss'
import css2 from './companypicturesmanagerpopup_responsive.scss'
/* eslint-enable no-unused-vars */

import CompanyPicturesUploader from './CompanyPicturesUploader/CompanyPicturesUploader'
import { FormattedMessage } from 'react-intl'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

const CompanyPicturesManagerPopup = (props) => {
    const [state, setState] = useState({
        selectedPictures: props.value,
        error: false
    })

    useEffect(
        () => {
            props.getPopupContent({
                popupTitle: <FormattedMessage id="company_profile.company_page_images.popup.title" />,
                popupActionButton: <FormattedMessage id="company_profile.company_page_images.popup.save_button" />,
                actionPopup: () => actionPopup(),
                maxWidth: 'lg'
            })
        }, [])

    const actionPopup = () => {
        props.actionPopup(state.selectedPictures)
    }

    const handleSelectedPictureChange = (document_object) => {
        const selectedPictures = state.selectedPictures
        selectedPictures.push({
            cloudinary_key: document_object.cloudinary_key,
            id: document_object.id,
            is_used: document_object.is_used,
            width: document_object.width,
            height: document_object.height
        })
        setState({
            selectedPictures
        })
    }

    const handleChangeCheckBox = (event) => {
        const { value } = event.target
        var selectedPictures = state.selectedPictures
        selectedPictures[value].is_used = !selectedPictures[value].is_used
        setState({ selectedPictures: selectedPictures }, () => {})
    }

    const displayGallery = () => {
        return (
            <Grid container spacing={2}>
                {state.selectedPictures.map((picture, index) => {
                    return (
                        <Grid item xs={12} md={3} style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}>
                            <Image
                                style={{ width: 200 }}
                                cloudName="studentbe"
                                publicId={picture.cloudinary_key}
                            />
                            <input
                                type="checkbox"
                                name={'checkbox' + index}
                                value={index}
                                checked={picture.is_used}
                                onClick={handleChangeCheckBox}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        )
    }

    return (
        <Box>
            <Typography variant='body2'>
                <FormattedMessage id="company_profile.company_page_images.popup.description" />
            </Typography>
            <Box>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Box my={4} style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 130,
                        marginRight: '1%',
                        marginLeft: '1%',
                        width: '250px',
                        border: '1px dashed black',
                        borderRadius: '5px'
                    }}>
                        <CompanyPicturesUploader
                            selectedPictures={state.selectedPictures}
                            handleSelectedPictureChange={handleSelectedPictureChange}
                            companyId={props.companyId}
                        />
                    </Box>
                </Grid>
                {displayGallery()}
            </Box>
        </Box>
    )
}

export default CompanyPicturesManagerPopup
