import React, { useState, useEffect } from 'react'

import { useStyles } from './QuickFilterArrows_style'
import Box from '@material-ui/core/Box'

import ArrowForward from '@material-ui/icons/ArrowForward'
import IconButton from '@material-ui/core/IconButton'
import ArrowBack from '@material-ui/icons/ArrowBack'

const QuickFilterArrows = (props) => {
    const classes = useStyles()
    const { quickFiltersRef } = props
    const [showLeftArrow, setShowLeftArrow] = useState(false)
    const [showRightArrow, setShowRightArrow] = useState(true)
    const [isScrollStart, setIsScrollStart] = useState(true)
    const [isScrollEnd, setIsScrollEnd] = useState(false)
    const itemWidth = 100
    const itemsToScroll = 3

    const handleNext = () => {
        const quickFiltersContainer = quickFiltersRef.current
        quickFiltersContainer.scrollLeft += itemWidth * itemsToScroll
    }

    const handleBack = () => {
        const quickFiltersContainer = quickFiltersRef.current
        quickFiltersContainer.scrollLeft -= itemWidth * itemsToScroll
    }

    const handleScroll = () => {
        const isAtEnd = quickFiltersRef.current.scrollLeft + 1 >= quickFiltersRef.current.scrollWidth - quickFiltersRef.current.clientWidth
        const isAtStart = quickFiltersRef.current.scrollLeft === 0
        setShowLeftArrow(!isAtStart)
        setShowRightArrow(!isAtEnd)
        setIsScrollStart(isAtStart)
        setIsScrollEnd(isAtEnd)
    }

    const handleScrollEffect = () => {
        // Add fade effect if we are at the end
        if (isScrollEnd) {
            quickFiltersRef.current.classList.add(classes.atScrollEnd)
        } else {
            quickFiltersRef.current.classList.remove(classes.atScrollEnd)
        }

        if (isScrollStart) {
            quickFiltersRef.current.classList.add(classes.atScrollStart)
        } else {
            quickFiltersRef.current.classList.remove(classes.atScrollStart)
        }
    }

    useEffect(() => {
        const quickFiltersContainer = quickFiltersRef.current

        quickFiltersContainer.addEventListener('scroll', handleScroll)

        handleScroll()
        handleScrollEffect()

        return () => {
            quickFiltersContainer.removeEventListener('scroll', handleScroll)
        }
    }, [isScrollEnd, isScrollStart])

    return (
        <>
            <Box className={classes.arrowLeft} style={{ display: showLeftArrow ? 'block' : 'none' }}>
                <IconButton onClick={handleBack} className={classes.arrowBtn}>
                    <ArrowBack fontSize='medium' className={classes.arrows} />
                </IconButton>
            </Box>
            <Box className={classes.arrowRight} style={{ display: showRightArrow ? 'block' : 'none' }}>
                <IconButton onClick={handleNext} className={classes.arrowBtn}>
                    <ArrowForward fontSize='medium' className={classes.arrows} />
                </IconButton>
            </Box>
        </>
    )
}

export default QuickFilterArrows
