import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    paperMenu: {
        borderRadius: 'var(--border-radius)',
        boxShadow: 'var(--box-shadow)',
        border: 'var(--border)',
        marginTop: '10px',
        width: '238px'
    },
    menuList: {
        padding: '8px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px'
    },
    menuItem: {
        height: '36px',
        gap: '12px',
        padding: '12px',
        borderRadius: 'var(--border-radius)',
        '&:hover': {
            backgroundColor: theme.palette.bg.greyPale
        }
    },
    menuItemIcon: {
        width: '25px'
    },
    userProfileIconButton: {
        minWidth: 'unset',
        padding: '0px',
        '&:hover': {
            backgroundColor: 'transparent'
        },
    },
    badge: {
        '& .MuiBadge-anchorOriginTopRightCircle': {
            padding: '2px',
            backgroundColor: theme.palette.bg.black,
            borderRadius: '50%',
            color: theme.palette.fg.white,
            fontFamily: 'GreedStandard-Medium',
            fontSize: '10px',
            lineHeight: '11px'
        }
    },
    userProfileIcon: {
        fontSize: '24px !important'
    }
}))
