import React from 'react'

const VegetablesSVG = (props) => (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
        <path d="M479.36,170.29c-.17-.16-.35-.32-.52-.48-2.83-2.54-5.65-5.03-8.48-7.51l-1.65-1.45c-10.25-8.88-20.93-15.22-32.66-19.37-6.1-2.16-12.19-3.83-18.26-5.01-1.73-9.38-4.6-18.4-8.62-27.02-5.95-12.5-13.81-21.58-20.34-29.11-1.75-2.02-3.61-3.95-5.6-6.01l-1.67-1.72c-8.56-8.98-20.71-13.76-33.01-13.09-12.39.66-23.87,6.7-31.43,16.53l-2.09,2.6c-7.09,8.62-15.92,19.35-22.45,33.42-6.85,14.93-10.38,29.34-10.73,43.84-8.88-.62-17.88-.34-26.94.92-21.77,3-41.27,11.02-57.87,23.76-7.36,5.6-13.92,11.82-20.08,19-3.69,4.32-6.38,9.17-8.07,14.27-4.54,4.04-8.23,9.05-10.72,14.78l-85.41,195.94c-6.43,14.74-3.98,31.84,6.31,44.19.29.34.66.78,1.01,1.17,8.24,9.4,19.97,14.49,31.97,14.48,5.7,0,11.46-1.15,16.93-3.52l106.71-46.36c3.72-1.62,7.14-3.74,10.19-6.26,4.43-.51,8.86-1.7,13.18-3.57,20.03-8.73,40.06-17.38,60.15-26.05,15.63-6.74,29.46-15.85,41.14-27.13,11.05-10.69,20.11-23.47,26.9-37.99,6.89-14.72,10.59-30.17,11-45.82.14-4.99.03-10.11-.35-15.53,4.8-.08,9.94-.48,15.31-1.41,17.23-2.98,32.86-9.58,46.29-19.48,5.72-4.18,11.33-8.44,16.68-12.61,14.81-11.4,17.62-25.84,17.97-33.44.35-7.59-1.1-22.23-14.79-34.96Z" fill="#000" strokeWidth="0px" />
        <g>
            <path d="M447.48,138.41c-.17-.16-.35-.32-.52-.48-2.83-2.54-5.65-5.03-8.48-7.51l-1.65-1.45c-10.25-8.88-20.93-15.22-32.66-19.37-6.1-2.16-12.19-3.83-18.26-5.01-1.73-9.38-4.6-18.4-8.62-27.02-5.95-12.5-13.81-21.58-20.34-29.11-1.75-2.02-3.61-3.95-5.6-6.01l-1.67-1.72c-8.56-8.98-20.71-13.76-33.01-13.09-12.39.66-23.87,6.7-31.43,16.53l-2.09,2.6c-7.09,8.62-15.92,19.35-22.45,33.42-6.85,14.93-10.38,29.34-10.73,43.84-8.88-.62-17.88-.34-26.94.92-21.77,3-41.27,11.02-57.87,23.76-7.36,5.6-13.92,11.82-20.08,19-3.69,4.32-6.38,9.17-8.07,14.27-4.54,4.04-8.23,9.05-10.72,14.78l-85.41,195.94c-6.43,14.74-3.98,31.84,6.31,44.19.29.34.66.78,1.01,1.17,8.24,9.4,19.97,14.49,31.97,14.48,5.7,0,11.46-1.15,16.93-3.52l106.71-46.36c3.72-1.62,7.14-3.74,10.19-6.26,4.43-.51,8.86-1.7,13.18-3.57,20.03-8.73,40.06-17.38,60.15-26.05,15.63-6.74,29.46-15.85,41.14-27.13,11.05-10.69,20.11-23.47,26.9-37.99,6.89-14.72,10.59-30.17,11-45.82.14-4.99.03-10.11-.35-15.53,4.8-.08,9.94-.48,15.31-1.41,17.23-2.98,32.86-9.58,46.29-19.48,5.72-4.18,11.33-8.44,16.68-12.61,14.81-11.4,17.62-25.84,17.97-33.44.35-7.59-1.1-22.23-14.79-34.96Z" fill="#000" strokeWidth="0px" />
            <path d="M79.85,409.69c.11.12.21.23.32.36,35.8-15.57,71.66-31.13,106.72-46.37-12.27-12.91-24.6-25.86-36.65-38.57,4.52-4.57,9.56-9.63,14.61-14.73.64.68,1.33,1.48,2.07,2.23,13.44,13.55,26.88,27.08,40.27,40.66,1.01.99,1.7,1.17,3.03.59,20.08-8.75,40.16-17.42,60.29-26.11,10.57-4.56,20.13-10.65,28.42-18.65,7.6-7.35,13.49-15.93,17.95-25.47,4.3-9.18,6.75-18.9,7.01-29.03.16-5.57-.16-11.21-.74-16.75-.96-8.97-3.67-17.51-7.7-25.62-4.67-9.49-11.37-17.44-19.07-24.64-6.22-5.83-13.17-10.58-20.98-14-14.87-6.52-30.39-8.77-46.53-6.54-14.02,1.93-26.72,6.87-37.93,15.46-4.94,3.77-9.4,8.01-13.55,12.84,14.98,15.11,29.85,30.16,44.67,45.06-4.94,4.89-9.99,9.96-14.87,14.81-13.76-13.63-27.68-27.4-41.91-41.48-28.58,65.58-57,130.76-85.42,195.94ZM318.95,70.08c-.58.77-.85,1.15-1.17,1.51-6.8,8.31-13.86,16.49-18.43,26.33-4.46,9.73-7.28,19.92-6.85,30.78.16,3,.37,6.01.69,9,.96,9.93,4.14,19.04,10.04,27.19,4.52,6.2,10.04,11.46,15.56,16.7,4.14,3.96,8.87,7.24,13.92,9.94,6.11,3.24,12.7,4.67,19.5,5.48,7.28.87,14.61,1.27,21.89.02,10.41-1.8,19.97-5.65,28.47-11.93,5.37-3.92,10.62-7.91,15.83-11.96,1.91-1.47,1.86-2.01.16-3.6-3.19-2.86-6.37-5.65-9.56-8.45-5.68-4.93-11.9-8.91-19.02-11.43-10.94-3.88-22.15-5.66-33.78-4.1-4.78.64-9.62,1.01-13.92,3.57-.37.21-.74.36-1.22.57.96-2.76,2.07-5.27,2.71-7.88,1.81-6.86,1.91-13.89,1.43-20.9-.58-8.72-2.55-17.11-6.27-25.07-3.51-7.37-8.77-13.41-14.02-19.48-1.81-2.08-3.77-4-5.95-6.29h0Z" fill="#fff" strokeWidth="0px" />
        </g>
    </svg>
)

export default VegetablesSVG
