import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import ProgressCircle from 'components/ProgressCircle/ProgressCircle'
import Button from 'shared/components/Button/Button'

import Typography from '@material-ui/core/Typography'
import DialogContent from '@material-ui/core/DialogContent'
import Box from '@material-ui/core/Box'
import DialogActions from '@material-ui/core/DialogActions'

import CollectionHandler from 'assets/CollectionHandler'
import DateHandler from 'assets/DateHandler.js'

const ActivateCompanyJobModalStep1View = (props) => {
    const { closeModal, loading, info, activateOrDeactivateJob, errors } = props
    const { formatMessage } = props.intl
    const jobOfferDuration = CollectionHandler.Get('jobOfferDuration')

    const renderButtonText = () => {
        if (Object.keys(info).length === 0) return null
        if (!info.jobInfoIsComplete) {
            return formatMessage({ id: 'active_popup.complete_offer_button' })
        } else if (info.status === 'deactivate') {
            return `${formatMessage({ id: 'active_popup.deactivate' })} ${info.offersNumber > 1 ? formatMessage({ id: 'active_popup.jobs' }) : formatMessage({ id: 'active_popup.job' })}`
        } else if ((!info.enoughCredits || errors?.activate_credits) && info.needsCreditsForActivation) {
            return formatMessage({ id: 'active_popup.no_credits_button' })
        } else if (info.status === 'activate_until') {
            return `${formatMessage({ id: 'active_popup.active' })} ${info.offersNumber > 1 ? formatMessage({ id: 'active_popup.jobs' }) : formatMessage({ id: 'active_popup.job' })}`
        }
    }

    const renderStatusText = () => {
        if (Object.keys(info).length === 0) return null
        if (!info.jobInfoIsComplete) {
            return formatMessage({ id: 'item_index.incomplete_offer' })
        } else if (info.silverLicence && info.jobType !== 'first_job') {
            return formatMessage({ id: 'item_index.silver_licence' })
        } else if (info.goldLicence) {
            return formatMessage({ id: 'item_index.gold_licence' })
        } else if (!info.needsCreditsForActivation) {
            const date = new Date(info.jobToActivate.active_until)
            return formatMessage({ id: 'item_index.activate_during_active_until' }, { active_until: date.toLocaleDateString() })
        }

        switch (info.status) {
        case 'activate_until':
            const date = new Date()
            const jobDuration = info.jobType === 'first_job' ? jobOfferDuration.first_job : info.jobType === 'student_job' ? jobOfferDuration.student_job : jobOfferDuration.internship
            date.setMonth(date.getMonth() + jobDuration)
            const statusDate = DateHandler.ToBasicFormat(date)
            const typeOfJob = formatMessage({ id: `type.${info.jobType}.singular` })

            return `${formatMessage(
                { id: 'active_popup.active_until.full_text'},
                { type_of_job: typeOfJob, number_of_months: jobDuration, active_until_date: statusDate }
            )}`
        case 'deactivate':
            return formatMessage({ id: 'active_popup.text_job' })
        }
    }
    const showCreditCostForActivation = !(info.status === 'deactivate') &&
        info.needsCreditsForActivation && info.jobInfoIsComplete

    const missingCredits = !(info.status === 'deactivate') && !info.enoughCredits &&
        info.needsCreditsForActivation && info.jobInfoIsComplete

    return (<>
        {loading && <ProgressCircle />}
        {!loading && <>
            <DialogContent>
                <Box m={2}>
                    <Typography
                        variant='body1'
                        color='primary'
                        style={{ textAlign: 'center' }}
                    >
                        {renderStatusText()}
                    </Typography>
                    {showCreditCostForActivation &&
                            <Typography
                                variant='h1'
                                component='h4'
                                color='primary'
                                style={{ textAlign: 'center' }}
                            >
                                {info.creditsCost + ' ' + formatMessage({ id: `active_popup.${info.creditsCost === 1 ? 'credit' : 'credits'}` })}
                            </Typography>
                    }
                    {info.enoughCredits && props.errors?.activate_credits &&
                            <Box>
                                <Typography color='error' style={{ textAlign: 'center', marginTop: 20 }}>
                                    {errors.activate_credits}
                                </Typography>
                            </Box>
                    }
                    {missingCredits &&
                            <Box>
                                <Typography color='error' style={{ textAlign: 'center', marginTop: 20 }}>
                                    {formatMessage({ id: 'landing_payment_page.back_validation.missing_credits' })}
                                </Typography>
                            </Box>
                    }
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant='secondary' size='small' onClick={closeModal}>
                    <FormattedMessage id="delete_offer_popup.option_no" />
                </Button>
                <Button variant='cta-secondary' size='small' onClick={activateOrDeactivateJob}>
                    {renderButtonText()}
                </Button>
            </DialogActions>
        </>}
    </>)
}

export default injectIntl(ActivateCompanyJobModalStep1View)
