/* eslint-disable react/prop-types */
import React from 'react'
import Box from '@material-ui/core/Box'
import AdBanner from 'components/AdBanner/AdBanner'

const BottomAd = (props) => {
    const { ads, type, placementIndex } = props

    const renderBottomAd = () => {
        switch (type) {
        case 'first_job':
            return <AdBanner key={'ad-banner-' + placementIndex} ads={ads} place={'Firstjobs_Show_Mobile_' + placementIndex} device='mobile' width={320} />
        case 'internship':
            return <AdBanner key={'ad-banner-' + placementIndex} ads={ads} place={'Internships_Show_Mobile_' + placementIndex} device='mobile' width={320} />
        case 'student_job':
            return <AdBanner key={'ad-banner-' + placementIndex} ads={ads} place={'Studentjobs_Show_Mobile_' + placementIndex} device='mobile' width={320} />
        default:
            break
        }
    }

    return (
        <Box py={2}>
            {renderBottomAd()}
        </Box>
    )
}

export default BottomAd
