/* eslint-disable react/prop-types */
import React, { useState } from 'react'

import { useStyles } from './SelectFilter_style'
import { FormControl, MenuItem, Select } from '@material-ui/core'

import IconFactory from 'icons/IconFactory/IconFactory'
import { DROPDOWN_STYLE_MENU_PROPS } from 'assets/StyleHelper'

const OpenIcon = (props) => {
    const { open } = props

    if (open) {
        return <IconFactory icon='up-solid' fontSize='12px' color='black' />
    } else {
        return <IconFactory icon='down-solid' fontSize='12px' color='black' />
    }
}

const SelectFilter = (props) => {
    const { value, name, onChange, collection, onClick, className, placeholder, defaultValue } = props
    const [open, setOpen] = useState(false)
    const classes = useStyles()

    return (
        <FormControl className={classes.formControl} required onClick={() => setOpen(!open)}>
            <Select
                className={`${classes.select} ${className}`}
                value={value}
                name={name}
                MenuProps={DROPDOWN_STYLE_MENU_PROPS}
                open={open}
                onClick={onClick}
                disableUnderline
                onChange={onChange}
                placeholder={placeholder}
                IconComponent={() => <OpenIcon open={open}/>}
                displayEmpty
            >
                {defaultValue && <MenuItem value={null}>{defaultValue}</MenuItem>}
                {collection.map((item, itemKey) => {
                    return (
                        <MenuItem className={classes.menuItem} key={itemKey.toString()} value={item.value}>
                            <IconFactory icon={item.value.replace('_', '-')} fontSize='20px' color='' />
                            <span>{item.name}</span>
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

export default SelectFilter
