import React, { useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useMediaQuery, useTheme, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Box, TextField, IconButton } from '@material-ui/core'

import Button from 'shared/components/Button/Button'
import { useStyles } from './DangerZonePopup_style'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import IconFactory from 'icons/IconFactory/IconFactory'
import ProgressCircle from 'components/ProgressCircle/ProgressCircle'
import RouteMap from 'assets/RouteMap'
import SettingsAPI from 'api/SettingsAPI'

const DangerZonePopup = (props) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const classes = useStyles()

    const { formatMessage } = props.intl

    const handleClose = () => {
        props.popupExit()
    }

    const [deleteSuccesful, setDeleteSuccesful] = useState(false)
    const [loading, setLoading] = useState(false)
    const [userState, setUserState] = useState(
        {
            currentPassword: '',
            deleteString: '',
            errorPassword: '',
            errorDeleteString: ''
        }
    )
    const handleChangeData = prop => event => {
        setUserState({ ...userState, [prop]: event.target.value })
    }

    const canSubmit = () => {
        var canSubmit = true
        let errorPassword = ''
        let errorDeleteString = ''

        if (userState.currentPassword === '') {
            errorPassword = <FormattedMessage id="landing_app_setting_page.errors.missing_password" />
            canSubmit = false
        }

        if (userState.deleteString !== 'DELETE') {
            errorDeleteString = <FormattedMessage id="landing_app_setting_page.errors.delete_error" />
            canSubmit = false
        }
        setUserState({
            ...userState,
            errorDeleteString: errorDeleteString,
            errorPassword: errorPassword
        })
        return canSubmit
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        setLoading(true)

        if (canSubmit()) {
            const data = {
                email: props.user.email,
                delete_string: userState.deleteString,
                password: userState.currentPassword
            }

            SettingsAPI.DeleteAccount(data).then((_) => {
                setLoading(false)
                setDeleteSuccesful(true)
                setTimeout(() => {
                    AnalyticsHelper.sendGTMEvent(
                        'Account Deleted',
                        AnalyticsHelper.constructBasicAnalyticsAttributes(props.user)
                    )
                    window.location.href = RouteMap.Page('/')
                }, 2000)
            }).catch(
                (error) => {
                    setUserState({
                        ...userState,
                        errorPassword: error.response.data.errorPassword,
                        errorDeleteString: error.response.data.errorDeleteString
                    })
                }
            )
        }
    }

    const renderDeleteForm = () => {
        return (
            <form onSubmit={handleSubmit}>
                <DialogTitle id="responsive-dialog-delete">
                    <Typography variant='h4' component='h1' color='primary' style={{ margin: 0 }}>
                        <FormattedMessage id="landing_app_setting_page.danger_zone.popuptitle"/>
                    </Typography>
                    <IconButton aria-label="close" onClick={props.handleClose} style={{
                        position: 'absolute',
                        right: theme.spacing(1),
                        top: 5,
                        color: theme.palette.grey[500]
                    }}>
                        <IconFactory icon='close' />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormattedMessage id="landing_app_setting_page.danger_zone.popupwarning"/>
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="password"
                        label={formatMessage({ id: 'landing_app_setting_page.current_password' })}
                        error={userState.errorPassword}
                        value={userState.currentPassword}
                        onChange={handleChangeData('currentPassword')}
                        type="password"
                        autoComplete="current-password"
                        InputProps={{
                            classes: {
                                input: classes.inputBase
                            }
                        }}
                        classes={{
                            root: classes.errorText
                        }}
                        fullWidth
                    />
                </DialogContent>

                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="delete"
                        label={formatMessage({ id: 'landing_app_setting_page.danger_zone.deletelabel' })}
                        type="text"
                        fullWidth
                        error={userState.errorDeleteString}
                        value={userState.deleteString}
                        onChange={handleChangeData('deleteString')}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant='secondary' size='small' type='submit'>
                        <FormattedMessage id="landing_app_setting_page.danger_zone.confirm_yes"/>
                    </Button>
                    <Button variant='cta-secondary' size='small' onClick={handleClose}>
                        <FormattedMessage id="landing_app_setting_page.danger_zone.confirm_no"/>
                    </Button>
                </DialogActions>
            </form>
        )
    }

    const renderSuccess = () => {
        return (
            <Box>
                <DialogTitle id="responsive-dialog-delete">
                    <Typography variant='h4' component='h1' color='primary' style={{ margin: 0 }}>
                        <FormattedMessage id="landing_app_setting_page.danger_zone.successful_delete_title"/>
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormattedMessage id="landing_app_setting_page.danger_zone.successful_delete_text"/>
                    </DialogContentText>
                </DialogContent>
            </Box>
        )
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={props.openShare}
            onClose={props.handleClose}
            disableScrollLock={true}
            aria-labelledby="responsive-dialog-delete"
            fullWidth
            PaperProps={{ className: classes.dialogContainer }}
        >
            {!deleteSuccesful && !loading && renderDeleteForm()}
            {loading && <ProgressCircle/>}
            {deleteSuccesful && renderSuccess()}
        </Dialog>
    )
}

export default injectIntl(DangerZonePopup)
