import React from 'react'
import { IntlProvider } from 'react-intl'
import { Grid, Box, TextField, Switch, InputLabel, Input, FormHelperText, FormControl, FormControlLabel, FormLabel, Select, Button } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import IconFactory from 'shared/icons/IconFactory/IconFactory'
import UploadImage from 'modals/UploadImage/UploadImage'
import FeedbackSnackbar from 'shared/components/FeedbackSnackbar/FeedbackSnackbar'
import URLHandler from 'assets/URLHandler'
import translations from 'assets/i18n/translations.json'
import CollectionHandler from 'assets/CollectionHandler'
import { useStyles } from './AdminEditAdView_style'

const AdminEditAdView = (props) => {
    const {
        state,
        setState,
        feedback,
        closeFeedback,
        formErrors,
        handleChange,
        handleStartDateChange,
        handleEndDateChange,
        handleImageChange,
        handlePlacesChange,
        handleSubmit
    } = props
    const classes = useStyles()

    const languageOptions = CollectionHandler.Get('defaultLanguageCollection')
    const locale = URLHandler.GetLocaleFromUrl()
    const messages = translations[locale]

    return(
        <Box className={classes.container}>
             <FeedbackSnackbar
                open={feedback.open}
                message={feedback.message}
                severity={feedback.severity}
                closeFeedback={closeFeedback}
            />
            <form onSubmit={handleSubmit}>
                <Grid container direction="column" spacing={4} justifyContent="flex-start" alignItems="flex-start">
                    <Grid item xs={12} className={classes.minHalfWidth}>
                        <FormControl>
                            <InputLabel htmlFor="email-input">Client</InputLabel>
                            <Input
                                value={state.client}
                                name='client'
                                id="client-input"
                                aria-describedby="client-helper-text"
                                onChange={handleChange}
                                required
                                error={!!formErrors.client}
                            />
                            <FormHelperText id="email-helper-text">{formErrors.client}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} className={classes.minHalfWidth}>
                        <FormControl>
                            <InputLabel htmlFor="campaign_name-input">Campaign Name</InputLabel>
                            <Input
                                value={state.campaign_name}
                                name='campaign_name'
                                id="campaign_name-input"
                                aria-describedby="campaign_name-helper-text"
                                onChange={handleChange}
                                required
                                error={!!formErrors.campaign_name}
                            />
                            <FormHelperText id="campaign_name-helper-text">{formErrors.campaign_name}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} className={classes.minHalfWidth}>
                        <FormControl>
                            <InputLabel htmlFor="url-input">Url</InputLabel>
                            <Input
                                value={state.url}
                                name='url'
                                id="url-input"
                                aria-describedby="url-helper-text"
                                onChange={handleChange}
                                error={!!formErrors.url}
                                required
                            />
                            <FormHelperText id="url-helper-text">
                                {formErrors.url || '💡 Where the user is redirected when clicking the ad'}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <FormControl>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            name='start_date'
                                            label="Start date"
                                            keyboardIcon={<IconFactory icon='date'/>}
                                            margin="normal"
                                            format="dd/MM/yyyy"
                                            value={state.start_date}
                                            onChange={handleStartDateChange}
                                            KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                            placeholder="dd/mm/yyyy"
                                            required
                                        />
                                    </MuiPickersUtilsProvider>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            name='end_date'
                                            label='End date'
                                            keyboardIcon={<IconFactory icon='date'/>}
                                            margin="normal"
                                            format="dd/MM/yyyy"
                                            value={state.end_date}
                                            onChange={handleEndDateChange}
                                            KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                            placeholder="dd/mm/yyyy"
                                            minDate={state.start_date}
                                            minDateMessage="Date cannot be before start date"
                                            required
                                        />
                                    </MuiPickersUtilsProvider>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel>Active</FormLabel>
                            <FormControlLabel
                                control={
                                    <Switch
                                        name='active'
                                        id="active-input"
                                        aria-describedby="active-helper-text"
                                        color='secondary'
                                        checked={state.active}
                                        value={state.active}
                                        onChange={() => setState({ ...state, active: !state.active })}
                                    />
                                }
                            />
                            <FormHelperText id="active-helper-text">
                                {formErrors.active || '💡 An active ad will appear on Student.be (if end date is in the future)'}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} className={classes.minHalfWidth}>
                        <FormControl>
                            <InputLabel htmlFor="language-native-simple">Language</InputLabel>
                            <Select
                                native
                                value={state.langage}
                                onChange={handleChange}
                                inputProps={{ name: 'langage', id: 'language-native-simple' }}
                                required
                            >
                                <option aria-label="None" value={null} />
                                {languageOptions.map(language => {
                                    return <option value={language.value} key={language.value}>{language.name}</option>
                                })}
                            </Select>
                            <FormHelperText id="language-native-simple">
                                {formErrors.language || '💡 The ad is only visible for users browsing the website in this language'}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} className={classes.minHalfWidth}>
                        <Autocomplete
                            multiple
                            id="ads-outlined"
                            options={state.all_places}
                            getOptionLabel={(option) => option.place_on_page}
                            value={state.all_places.filter(place => state.ids_selected_places.includes(place.id))}
                            onChange={handlePlacesChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Selected ad places"
                                    placeholder="+ add places"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <InputLabel htmlFor="format-input">Format</InputLabel>
                            <Select
                                native
                                value={state.format}
                                onChange={handleChange}
                                inputProps={{ name: 'format', id: 'format-input' }}
                            >
                                <option aria-label="None" value={null} />
                                {state.all_formats.map(format =>
                                    <option value={format.value} key={format.value}>{format.en}</option>
                                )}
                            </Select>
                            <FormHelperText id="format-input">
                                {formErrors.active || '💡 If your ad is placed in a leaderboard place, you can choose between leaderboard format or billboard format'}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} className={classes.fullWidth}>
                        <FormControl fullWidth>
                            <FormLabel>HTML</FormLabel>
                            <textarea
                                className={classes.htmlArea}
                                aria-describedby="html-helper-text"
                                name='html'
                                value={state.html}
                                onChange={handleChange}
                            >
                                {state.html}
                            </textarea>
                            <FormHelperText id="html-helper-text">{formErrors.html}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} className={classes.minHalfWidth}>
                        <FormControl fullWidth>
                            <FormLabel>Image</FormLabel>
                            <IntlProvider locale={locale} key={locale} messages={messages} textComponent={React.Fragment}>
                                <UploadImage
                                    imageArray={[state.document]}
                                    setImageArray={(res) => handleImageChange(res)}
                                    maxPictures={1}
                                />
                            </IntlProvider>
                        </FormControl>
                    </Grid>
                    <Box m={2}>
                        <Grid container justifyContent='flex-end'>
                            <Button type="submit" variant="contained" color="primary">
                                save or edit ✍🏼
                            </Button>
                        </Grid>
                    </Box>
                </Grid>
            </form>
        </Box>
    )
}

export default AdminEditAdView
