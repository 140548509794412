/* eslint-disable react/prop-types */
import React from 'react'
import InfoLineView from './InfoLineView/InfoLineView'
import DateHandler from 'assets/DateHandler.js'
import { FormattedMessage } from 'react-intl'

const StartDateLine = (props) => {
    const { job } = props
    if (!job.start_date) return null

    const getText = () => {
        const startDate = DateHandler.ToBasicFormat(job.start_date)

        return <FormattedMessage
            id='job_show.job_info_card.start_date_line'
            values={{ date: startDate }}
        />
    }

    return (
        <InfoLineView
            icon='date'
            text={getText()}
        />
    )
}

export default StartDateLine
