import React from 'react'
import { Box } from '@material-ui/core'

import SVGFactory from 'icons/SVGFactory/SVGFactory'
import { useStyles } from '../CompanyPageImages_style'

const GalleryImage = (props) => {
    const classes = useStyles()
    const { image, index, openLightbox } = props
    const calculatedWidth = image.width - image.margin * 2
    const calculatedHeight = image.height - image.margin * 2

    return (<Box style={{ position: 'relative' }}>
        <img
            style={{
                margin: image.margin,
                width: calculatedWidth,
                height: calculatedHeight,
                objectFit: 'cover',
                cursor: 'pointer'
            }}
            className={classes.imageRender}
            src={image.src}
            alt={image.alt}
            onClick={() => openLightbox(index)}
            key={image.cloudinary_key}
        />
        {image.type === 'video' &&
            <Box className={classes.videoIcon}>
                <SVGFactory name='play' />
            </Box>
        }
    </Box>)
}

export default GalleryImage
