import React, { useState, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { useStyles } from './CTAAlert_style'
import { Box, Typography } from '@material-ui/core'
import Button from 'shared/components/Button/Button.jsx'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import { SAVE_FILTERS } from 'assets/AuthentificationHelper'
import { LoginRegisterContext } from 'shared/contexts/LoginRegisterContext'
import URLParamsHandler from 'assets/URLParamsHandler.js'
import JobAlertModal from 'shared/modals/JobAlertModal/JobAlertModal.jsx'
import AlertsAPI from 'api/AlertsAPI.js'
import FeedbackSnackbar from 'components/FeedbackSnackbar/FeedbackSnackbar'
import CTAJobAlert from './CTAJobAlert/CTAJobAlert'
import FiltersHelper from 'assets/FiltersHelper'
import AlertStickyButton from './AlertStickyButton/AlertStickyButton'
import { JOB_TYPES_LIST } from 'assets/ItemHelper'

const CTAAlert = (props) => {
    const { type, user, componentType, origin, filters, showMoreAction, loadingMore, loadMoreStatus, className } = props
    // if (user && (user?.type !== 'student')) return null
    // if (type === 'kot' && componentType !== 'showMoreButton') return null
    const classes = useStyles()
    const context = useContext(LoginRegisterContext)
    const [showAlertModal, setShowAlertModal] = useState(false)
    const [alertModalShown, setAlertModalShown] = useState(false)
    const [alertOrigin, setAlertOrigin] = useState(URLParamsHandler.parseURLParams().alertOrigin)
    const [feedback, setFeedback] = useState({
        open: false,
        message: null,
        severity: null,
        anchor: { vertical: 'bottom', horizontal: 'center' }
    })

    const closeFeedback = () => setFeedback({ ...feedback, open: false })

    const onClick = () => {
        if (JOB_TYPES_LIST.includes(type)) {
            if (!user) {
                context.openLoginRegisterModal({
                    origin: SAVE_FILTERS,
                    afterAuthAction: () =>
                        afterAuthAction(origin)
                })
            } else {
                renderAlertModal(origin)
                AnalyticsHelper.sendGTMEvent('alert-creation-click')
            }
        }
    }
    const showMoreOnClick = () => {
        if (!alertModalShown && user) {
            onClick()
        }
        showMoreAction && showMoreAction()
    }

    const renderAlertModal = (origin = null) => {
        setShowAlertModal(!showAlertModal)
        setAlertOrigin(origin)
        setAlertModalShown(true)
    }

    const afterAuthAction = (alertOrigin) => {
        URLParamsHandler.reloadPageAndAddParams({
            process: 'save_filters',
            alertOrigin
        })
    }

    const getCoords = (filterCities) => {
        if (filterCities && filterCities.length) {
            return filterCities.map((option) => {
                return {
                    long: option.long,
                    lat: option.lat,
                    value: option.value
                }
            })
        } else {
            return 'null'
        }
    }

    const tmpCondition = () => {
        if (user && (user?.type !== 'student')) {
            return false
        }
        if (type === 'kot' && componentType !== 'showMoreButton') {
            return false
        }
        return true
        // TODO: Fixing Edouard changes by putting seemore in AlertCTA
    }

    const createAlert = () => {
        AlertsAPI.CreateAlert({
            study_domains: FiltersHelper.getValues(filters?.studyDomain),
            locations: getCoords(filters?.location),
            company_sizes: FiltersHelper.getValues(filters?.companySize),
            sectors: FiltersHelper.getValues(filters?.sector),
            job_type: type,
            schedules: FiltersHelper.getValues(filters?.jobSchedule),
            creation_origin: alertOrigin,
            quick_filter: filters?.quickFilter
        }).then(
            (response) => {
                renderAlertModal()
                setFeedback({
                    ...feedback,
                    open: true,
                    message: <FormattedMessage id='create_alert_popup.alert_creation_success'/>,
                    severity: 'success'
                })
                AnalyticsHelper.sendGTMEvent('alert-creation-success')
                AnalyticsHelper.sendGTMEvent(
                    'Search Saved',
                    AnalyticsHelper.constructSearchFilterAppliedAnalyticsAttributes(
                        user,
                        { job_type: type },
                        alertOrigin
                    )
                )
            },
            (error) => {
                renderAlertModal()
                setFeedback({
                    ...feedback,
                    open: true,
                    message: <FormattedMessage id='create_alert_popup.alert_creation_failure'/> + error,
                    severity: 'error'
                })
            }
        )
    }

    return (<>
        {/* TODO: Fixing Edouard changes by putting seemore in AlertCTA */}
        {tmpCondition() && <>
            <JobAlertModal
                popupExit={renderAlertModal}
                actionPopup={createAlert}
                open={showAlertModal}
                filters={filters || {
                    companySize: [],
                    jobSchedule: [],
                    location: [],
                    quickFilter: '',
                    sector: [],
                    studyDomain: []
                }}
                jobType={type}
            />
            <FeedbackSnackbar
                open={feedback.open}
                message={feedback.message}
                severity={feedback.severity}
                closeFeedback={closeFeedback}
                anchor={feedback.anchor}
            />
            {componentType === 'default' &&
                <Box className={`${classes.alertContainer} ${className}`}>
                    <Box className={classes.alertTextContainer}>
                        <Typography variant='h3' component='p' className={classes.alertText}>
                            <FormattedMessage id={`item_index.alert.${type}.title`} />
                        </Typography>
                    </Box>
                    <Button
                        variant='cta-primary'
                        size='small'
                        className={classes.alertButton}
                        onClick={onClick}
                    >
                        <FormattedMessage id={`item_offers.${type}.save_my_search_v2`} />
                    </Button>
                </Box>
            }
            {componentType === 'stickyButton' &&
                <AlertStickyButton stickyAlertBtn={onClick} type={type}/>
            }
            {componentType === 'banner' &&
                <CTAJobAlert handleReceiveJobs={onClick}/>
            }
        </>}
        {componentType === 'showMoreButton' && loadMoreStatus && !loadingMore &&
            <Box className='show-more-button-container-centered'>
                <Button size='small' className='show-more-button' onClick={showMoreOnClick}>
                    <FormattedMessage id='item_index.show_more_button' />
                </Button>
            </Box>
        }
    </>)
}

export default CTAAlert
