import axios from 'axios'
import ReactOnRails from 'react-on-rails'
import RouteMap from '../assets/RouteMap.js'

import { CANDIDACY_API, CANDIDACY_CHECK_AND_RETRIEVE_STUDENT_DOCUMENTS } from './env'

const CheckAndRetrieveStudentDocuments = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: CANDIDACY_CHECK_AND_RETRIEVE_STUDENT_DOCUMENTS,
        method: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

const updateCandidacy = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: CANDIDACY_API,
        method: 'patch',
        data: data
    })
}

export default {
    CheckAndRetrieveStudentDocuments,
    updateCandidacy
}
