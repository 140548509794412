import React from 'react'

const LightBulbSVG = (props) => (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65.98 86">
        <g id="Calque_2">
            <g id="Calque_1-2">
                <path d="M65.5,30.8c-1-6.02-3.88-11.58-8.22-15.87-2.53-2.62-5.54-4.74-8.86-6.25-4.32-1.96-9.04-2.88-13.78-2.68-1.9.11-3.79.39-5.64.84-6.22,1.48-11.79,4.93-15.89,9.84-2.24,2.57-3.99,5.52-5.17,8.72-1.95,5.19-2.44,10.81-1.4,16.26.69,3.81,2.15,7.44,4.29,10.66.81,1.2,1.66,2.34,2.41,3.35.3.42.61.83.9,1.23l.45.6.09.1c1.47,2.03,2.67,4.25,3.57,6.59-.15.59-.24,1.2-.25,1.81v12c0,4.42,3.58,8,8,8h20c4.42,0,8-3.58,8-8v-12c0-.63-.08-1.26-.23-1.87.63-1.63,1.42-3.19,2.37-4.66.86-1.37,1.86-2.74,3.11-4.42l.15-.2c1.39-1.82,2.6-3.78,3.6-5.85,2.72-5.65,3.6-12.02,2.5-18.2Z"/>
                <path d="M59.5,24.8c-1-6.02-3.88-11.58-8.22-15.87-2.53-2.62-5.54-4.74-8.86-6.25C38.1.72,33.38-.2,28.64,0c-1.9.11-3.79.39-5.64.84-6.23,1.47-11.81,4.93-15.91,9.84-2.23,2.57-3.97,5.53-5.15,8.72-1.94,5.19-2.44,10.81-1.4,16.26.69,3.81,2.15,7.44,4.29,10.66.81,1.2,1.66,2.34,2.41,3.35.3.42.61.83.9,1.23l.45.6.09.1c1.47,2.03,2.67,4.25,3.57,6.59-.15.59-.24,1.2-.25,1.81v12c0,4.42,3.58,8,8,8h20c4.42,0,8-3.58,8-8v-12c0-.63-.08-1.26-.23-1.87.63-1.63,1.42-3.19,2.37-4.66.86-1.37,1.86-2.74,3.11-4.42l.15-.2c1.39-1.82,2.6-3.78,3.6-5.85,2.72-5.66,3.59-12.02,2.5-18.2Z"/>
                <path d="M20,72v-12h20v12h-20Z" fill="#fff"/>
                <path d="M51.63,26.18c-.33-1.94-.92-3.83-1.76-5.61-1.07-2.23-2.51-4.26-4.27-6-1.84-1.91-4.02-3.46-6.43-4.57-3.18-1.46-6.67-2.15-10.17-2-1.42.07-2.83.28-4.21.61-4.57,1.1-8.65,3.66-11.63,7.29-1.61,1.84-2.87,3.96-3.71,6.25-1.45,3.83-1.82,7.98-1.06,12,.49,2.75,1.53,5.36,3.07,7.69.71,1,1.46,2.06,2.21,3.08.32.42.63.85.95,1.28l.27.36.31.41c1.94,2.67,3.5,5.59,4.64,8.68.05.24.29.4.53.36h19.63v-.11l.12-.28c.86-2.28,1.96-4.46,3.28-6.5,1.06-1.61,2.2-3.17,3.33-4.73l.16-.22c1.1-1.41,2.06-2.92,2.87-4.52,2.03-4.18,2.68-8.9,1.87-13.47ZM31,21h-1c-4.9-.07-8.93,3.85-9,8.76v1.24h-6v-1c-.05-3.93,1.48-7.72,4.25-10.51,2.8-2.88,6.64-4.5,10.66-4.49h1.09v6Z" fill="#fff" fillRule="evenodd"/>
            </g>
        </g>
    </svg>
)

export default LightBulbSVG
