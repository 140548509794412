import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './MobileJobTypesCard_style'
import { Box, Typography, useTheme } from '@material-ui/core'

import SVGFactory from 'icons/SVGFactory/SVGFactory'
import CollectionHandler from 'assets/CollectionHandler'

const MobileJobTypesCards = (props) => {
    const classes = useStyles()
    const { setJobTypeSelection } = props
    const theme = useTheme()
    const jobTypes = [
        {
            tag: 'student-jobs',
            iconName: 'lightning',
            title: CollectionHandler.Translate('itemTypeCollection', 'student_job'),
            color: theme.palette.bg['yellow'],
        },
        {
            tag: 'internships',
            iconName: 'graduate',
            title: CollectionHandler.Translate('itemTypeCollection', 'internship'),
            color: theme.palette.bg['blue'],
        },
        {
            tag: 'first-jobs',
            iconName: 'first-job',
            title: CollectionHandler.Translate('itemTypeCollection', 'first_job'),
            color: theme.palette.bg['green'],
        },
    ]

    return (
        <Box className={classes.jobTypesCardContainer}>
            <Typography variant='h1' component='div' className={classes.jobTypesTitle}>
                <FormattedMessage id='page_footer_navbar_data.what_job_looking_for' />
            </Typography>
            <Box className={classes.cardsList}>
                {jobTypes.map((type, index) => <Box className={classes.card} role='button' key={index} sx={{ backgroundColor: type.color }} onClick={() => setJobTypeSelection(type)}>
                    <Box className={classes.iconContainer}>
                        <SVGFactory name={type.iconName} width='26.14px' height='32px' />
                    </Box>
                    <Typography className={classes.cardTitle}>
                        {type.title}
                    </Typography>
                </Box>)}
            </Box>
        </Box>
    )
}

export default MobileJobTypesCards
