/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useRef, useState } from 'react'
import DangerZonePopup from './Popup/DangerZonePopup/DangerZonePopup'

import useTheme from '@material-ui/core/styles/useTheme'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import DialogContent from '@material-ui/core/DialogContent'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'

import IconFactory from 'icons/IconFactory/IconFactory'

const ModalFactory = (props) => {
    const theme = useTheme()

    const handleClose = () => {
        props.popupExit()
    }

    const renderPopup = () => {
        switch (props.type) {
        case 'DangerZonePopup':
            return <DangerZonePopup
                {...props}
                handleClose={handleClose}
            />
        default:
            return <p>Something went wrong</p>
        }
    }

    return (
        renderPopup()
    )
}

export default ModalFactory
