import React from 'react'
import { Dialog, DialogContent, DialogContentText, DialogActions, Box } from '@material-ui/core'

import { useStyles } from './JobGeneratorModal_style'
import Button from 'shared/components/Button/Button'
import { FormattedMessage } from 'react-intl'

const JobGeneratorModal = (props) => {
    const classes = useStyles()
    const { open, handleClose, aiFilledFields } = props

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paper: classes.dialogPaper }}
        >
            <DialogContent className={classes.dialogContent}>
                <DialogContentText id="alert-dialog-description" className={classes.dialogContentText}>
                    <Box className={classes.number}>{aiFilledFields}</Box><Box className={classes.mainText}><FormattedMessage id={'job_creation_funnel.job_offer_generator.modal_text'}></FormattedMessage></Box>
                    <Box className={classes.disclaimer}><FormattedMessage id={'job_creation_funnel.job_offer_generator.modal_disclaimer'}></FormattedMessage></Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button className={classes.button} onClick={handleClose} size='small'>
                    <FormattedMessage id={'personal_dashboard.profile_edit_form.continue'}></FormattedMessage>
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default JobGeneratorModal
