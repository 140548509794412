/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import Hidden from '@material-ui/core/Hidden'
import TextField from '@material-ui/core/TextField'

import Select from '@material-ui/core/Select'
import Avatar from '@material-ui/core/Avatar'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import Checkbox from '@material-ui/core/Checkbox'

import Rating from '@material-ui/lab/Rating'

import ObjectTagList from 'components/ObjectTagList/ObjectTagList'
import Button from 'components/Button/Button'
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox'
import RouteMap from 'assets/RouteMap'

import { List } from 'react-virtualized'

import { useStyles } from './Searchbar_style'
import { FormattedMessage } from 'react-intl'
import CollectionHandler from 'assets/CollectionHandler.js'
import { DROPDOWN_STYLE_MENU_PROPS } from 'assets/StyleHelper.js'
import IconFactory from 'icons/IconFactory/IconFactory'

const SearchbarComponent = (props) => {
    const classes = useStyles()
    const [state, setState] = useState({})
    const [inputField, setInputField] = useState({})
    const [searchCities, setSearchCities] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [initFiltered, setInitFiltered] = useState(false)
    const [timeoutInputsearch, setTimeoutInput] = useState(false)
    const [timerFinished, setTimerFinished] = useState(false)
    const [timerRunning, setTimerRunning] = useState(false)
    const [citiesInput, setCitiesInput] = useState('')
    const [moreFilter, setMorefilter] = useState(false)

    const cityCollection = CollectionHandler.Get('cityCollection')
    const companySizeCollection = CollectionHandler.Get('companySizeCollection')
    const studyDomainCollection = CollectionHandler.Get('studyDomainCollection')
    const studentSituationCollection = CollectionHandler.Get('studentSituationCollection')
    const recruitmentStatusCollection = CollectionHandler.Get('recruitmentStatusCollection')

    let searchText
    if (window.location.search.includes('?search=', 0)) {
        searchText = window.location.search.replace('?search=', '')
    }

    useEffect(
        () => {
            initSetupState()
        }, [isLoaded === false]
    )

    useEffect(
        () => {
            if (isLoaded && initFiltered === false) {
                setInitFiltered(true)
            }
        }, [isLoaded === true]
    )

    useEffect(
        () => {
            if (isLoaded && initFiltered) {
                props.handleFilterChange({ ...state, title: inputField.title, name: inputField.name })
            }
        }, [state]
    )

    useEffect(
        () => {
            if (timeoutInputsearch) {
                if (!timerRunning) {
                    setTimerRunning(true)
                    const timer = setTimeout(() => {
                        setTimerFinished(true)
                        setTimeoutInput(false)
                        setTimerRunning(false)
                    }, 1200)
                    return () => clearTimeout(timer)
                }
            }
        }, [timeoutInputsearch === true]
    )

    useEffect(
        () => {
            if (timerFinished) {
                props.handleFilterChange({ ...state, title: inputField.title, name: inputField.name })
                setTimerFinished(false)
            }
        }, [timerFinished === true]
    )

    const keyPress = (event) => {
        if (event.key === 'Enter') {
            props.handleFilterChange({ ...state, title: inputField.title, name: inputField.name })
        }
    }

    /* ===== INIT FUNCTIONS ===== */

    const initSetupState = () => {
        const fields = initState()

        if (props.type === 'article') {
            let tagList = []

            if (props.selectedArticleTagValue === 'all') {
                tagList = props.tags.map(tag => tag.name)
            } else if (props.selectedArticleTagValue) {
                tagList.push(props.selectedArticleTagValue)
            }

            fields.tagList = tagList
        }

        setState(fields)
        setInputField(fields)
        setSearchCities(cityCollection)

        // TODO: Should not use that method to check screen size, but did not find any other solution to set moreFilter Bool to the right value
        setMorefilter(screen.width > 960)
        setIsLoaded(true)
    }

    const initState = (reset) => {
        var searchField
        let locationArray = []
        let studyDomainArray = []
        if (props.location) {
            if (props.location.type === 'location') {
                locationArray = props.location
                    ? convertLocationArray([props.location])
                    : locationArray
            }
            if (props.location.type === 'studyDomain') {
                studyDomainArray = props.location
                    ? convertStudyDomainArray([props.location])
                    : studyDomainArray
            }
            if (props.location.type === 'freeText') {
                searchText = props.location.name
            }
        }
        if (reset) {
            locationArray = []
            studyDomainArray = []
        }
        if (props.type === 'article') {
            if (props.urlFilters && !reset) {
                searchField = {
                    title: props.urlFilters.title
                }
            } else {
                searchField = {
                    title: '',
                    tagList: [],
                    noMoreFilter: true
                }
            }
        } else if (props.type === 'myItemSelection') {
            searchField = {
                title: '',
                type: '',
                noMoreFilter: true
            }
        } else if (props.type === 'student_headhunt') {
            searchField = {
                fieldOfStudies: [],
                status: '',
                distance: props.filters.distance,
                legalAge: false,
                drivingLicense: false,
                langLevel: {
                    fr: false,
                    en: false,
                    nl: false
                }
            }
        } else if (props.type === 'job_results') {
            searchField = {
                name: '',
                recruitmentStatuses: [],
                fieldOfStudies: [],
                studyLevel: []
            }
            if (props.overage) {
                searchField.legalAge = false
            }
        } else {
            searchField = {
                title: searchText || '',
                studyDomain: studyDomainArray,
                location: locationArray,
                companySize: [],
                noMoreFilter: props.type === 'student_job'
            }
            if (!reset && props.urlFilters) {
                searchField = {
                    ...searchField,
                    ...props.urlFilters
                }
            }
        }
        props.type !== 'myItemSelection' && props.setFilters(searchField)
        return searchField
    }

    const reset = () => {
        const newState = initState(true)
        setState(newState)
        setInputField(newState)
        props.handleFilterChange(newState)
        window.history.pushState('', '', location.pathname)
    }

    const convertLocationArray = (locationArray) => {
        return locationArray.map((location) => ({
            label: location.name + ' (' + location.value + ')',
            value: location.value,
            long: location.long,
            lat: location.lat
        }))
    }

    const convertStudyDomainArray = (studyDomainArray) => {
        return studyDomainArray.map((studyDomain) => ({
            label: studyDomain.name,
            value: studyDomain.value
        }))
    }

    /* ===== HANDLER FUNCTIONS ===== */

    const handleFiltersArray = (name, event) => {
        const stateTags = state[name]
        const eventTags = event.target.value

        const newTags = []
        var added = true
        if (stateTags.length === 0) {
            newTags.push(eventTags[0])
        } else {
            for (var i = 0; i < stateTags.length; i++) {
                if (stateTags[i].value !== eventTags[eventTags.length - 1].value) {
                    newTags.push(stateTags[i])
                } else {
                    added = false
                }
            }
            if (added) {
                newTags.push(
                    eventTags[eventTags.length - 1]
                )
            }
        }

        setState({ ...state, [name]: newTags })
    }

    const handleFiltersSingleSelect = (name, event) => {
        setState({ ...state, [name]: event.target.value })
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target
        setInputField({ ...inputField, [name]: value })
        setTimeoutInput(true)
    }

    const handleCheckBoxChange = (event) => {
        const { name, checked } = event.target
        setState({ ...state, [name]: checked })
    }

    const handleSliderChange = (event, value) => {
        setState({ ...state, distance: value })
    }

    const handleLanguageLevelChange = (event) => {
        const { name, value } = event.target
        var newLangLevel = state.langLevel
        newLangLevel[name] = value

        setState({ ...state, langLevel: newLangLevel })
    }

    const handleSearchCities = (value) => {
        const locations = state.location
        const newLocations = []
        var push = true

        for (var i = 0; i < locations.length; i++) {
            if (locations[i].value === value.value) {
                push = false
            } else {
                newLocations.push(locations[i])
            }
        }
        /* This condition is to prevent the Input inside the SELECT to be added
        on the location array. Because the Input is inside the SElECT, clicking
        on the Input also trigger the onChange of the SELECT */
        if (push) {
            if (!value.dispatchConfig) {
                newLocations.push(value)
            }
        }
        setState({ ...state, location: newLocations })
    }

    const handleInputSearchCity = (event) => {
        const { value } = event.target
        var newCities = []
        if (value.length !== 0) {
            const regex = value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
            newCities = cityCollection.filter(elem =>
                elem.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(regex) === true
            )
        }

        if (newCities.length !== 0) {
            setSearchCities(newCities)
        } else {
            setSearchCities(cityCollection)
        }
        setCitiesInput(value)
    }

    const handleTagsChange = (selectedTags) => {
        setState({ ...state, tagList: selectedTags })
    }

    const handleTagDelete = (tag) => {
        var tagList = state.tagList.filter((chip) => chip !== tag)

        setState({ ...state, tagList: tagList })

        if (tagList.length === 0) {
            window.location.href = RouteMap.Page('student-life')
        }
    }

    const renderFilters = () => {
        switch (props.type) {
        case 'article':
            return renderArticlesFilters()
        case 'myItemSelection':
            return renderMyItemSelection()
        case 'student_headhunt':
            return renderStudentHeadHuntFilters()
        case 'job_results':
            return renderJobResultsFilter()
        default:
            return renderJobFilters()
        }
    }

    const renderTags = () => {
        const tags = state.tagList

        const tagsList = []

        tags.map((tag) => {
            const nameTag = props.tags.find((element) => element.value === tag)

            tagsList.push(
                <Chip
                    onDelete={() => handleTagDelete(tag)}
                    label={nameTag ? nameTag.name : 'undefined'}
                    component="h2"
                />
            )
        })

        return tagsList
    }

    const renderArticlesFilters = () => {
        var formatedType = ''
        switch (props.page) {
        case 'student-life':
            formatedType = 'article'
            break
        case 'first-jobs':
            formatedType = 'first_job'
            break
        case 'internships':
            formatedType = 'internship'
            break
        case 'student-rooms':
            formatedType = 'kot'
            break
        case 'student-jobs':
            formatedType = 'student_job'
            break
        }

        return (
            <Box className={classes.boxContainer}>
                <Hidden mdUp>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography color='primary' variant='h4' component='h1' className={classes.typographyCenter}>
                                <FormattedMessage id={'search_bar.' + (props.selectedArticleTagValue === 'event' ? 'event_title' : `${formatedType}_title`)}>
                                    {articleTitle =>
                                        articleTitle.toUpperCase()
                                    }
                                </FormattedMessage>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={7} className={classes.itemCenter}>
                            <Box className={classes.boxInput}>
                                <Box style={{ marginRight: 10, display: 'flex' }}>
                                    <IconFactory icon='search' color='primary'/>
                                </Box>
                                <FormattedMessage id={'search_bar.search.' + (props.selectedArticleTagValue === 'event' ? 'event' : 'article')}>
                                    {placeholder =>
                                        <TextField
                                            InputProps={{
                                                classes: {
                                                    input: classes.inputBase
                                                }
                                            }}
                                            placeholder={placeholder}
                                            value={inputField.title}
                                            name='title'
                                            onChange={handleInputChange}
                                            className={classes.inputBase}
                                            fullWidth
                                            onKeyPress={keyPress}
                                        />
                                    }
                                </FormattedMessage>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            {props.hideTag || renderTags()}
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden smDown>
                    <Typography color='primary' variant='h4' component='h1' className={classes.articleTitle}>
                        <FormattedMessage id={'search_bar.' + (props.selectedArticleTagValue === 'event' ? 'event_title' : `${formatedType}_title`)}>
                            {articleTitle =>
                                articleTitle.toUpperCase()
                            }
                        </FormattedMessage>
                    </Typography>
                    <Divider orientation='vertical' className={classes.divider}/>
                    <Box className={classes.boxInput}>
                        <Box style={{ marginRight: 10, display: 'flex' }}>
                            <IconFactory icon='search' color='primary'/>
                        </Box>
                        <FormattedMessage id={'search_bar.search.' + (props.selectedArticleTagValue === 'event' ? 'event' : 'article')}>
                            {placeholder =>
                                <TextField
                                    InputProps={{
                                        classes: {
                                            input: classes.inputBase
                                        }
                                    }}
                                    placeholder={placeholder}
                                    value={inputField.title}
                                    name='title'
                                    onChange={handleInputChange}
                                    className={classes.inputBase}
                                    fullWidth
                                    onKeyPress={keyPress}
                                />
                            }
                        </FormattedMessage>
                    </Box>
                    {props.hideTag || renderTags()}
                    {props.renderingTags && false && <div className="searchbarbottom lineargradienttagList">
                        <ObjectTagList
                            enableSelectAll
                            value={state.tagList}
                            collection={props.tags}
                            name="categorytag"
                            selectAllArticleTags={props.selectAllArticleTags}
                            onRef={ref => (state.tagList = ref)}
                            onFilterChange={handleTagsChange}
                        />
                    </div>}
                </Hidden>
            </Box>
        )
    }

    const renderMyItemSelection = () => {
        return (
            <Box className={classes.boxContainer}>
                <Box className={classes.boxInput}>
                    <Box style={{ marginRight: 10, display: 'flex' }}>
                        <IconFactory icon='search' color='primary'/>
                    </Box>
                    <FormattedMessage id="search_bar.search.job">
                        {placeholder =>
                            <TextField
                                InputProps={{
                                    classes: {
                                        input: classes.inputBase
                                    }
                                }}
                                placeholder={placeholder}
                                value={inputField.title}
                                name='title'
                                onChange={handleInputChange}
                                className={classes.inputBase}
                                fullWidth
                                onKeyPress={keyPress}
                            />
                        }
                    </FormattedMessage>
                </Box>
                <FormControl fullWidth className={classes.formControl}>
                    <Select
                        value={state.type}
                        onChange={(event) => handleFiltersSingleSelect('type', event)}
                        MenuProps={DROPDOWN_STYLE_MENU_PROPS}
                        displayEmpty
                        inputProps={{ MenuProps: { disableScrollLock: true } }}
                    >
                        <MenuItem disabled value={''}>
                            <Typography variant='subtitle1' className={`${classes.selectPlaceholder} ${classes.placeholderHeight}`}>
                                <FormattedMessage id="search_bar.job_type" />
                            </Typography>
                        </MenuItem>
                        {
                            props.searchParams.map((type, key) => (
                                <MenuItem value={type[1]} key={key}>
                                    <Typography variant='body2'>
                                        {type[0]}
                                    </Typography>
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Box>
        )
    }

    const renderStudentHeadHuntFilters = () => {
        const elems = [
            { name: <FormattedMessage id="search_bar.headhunt_filter.interested" />, value: 'interested' },
            { name: <FormattedMessage id="search_bar.headhunt_filter.not_interested" />, value: 'not_interested' },
            { name: <FormattedMessage id="search_bar.headhunt_filter.invited" />, value: 'invited' },
            { name: <FormattedMessage id="search_bar.headhunt_filter.not_invited" />, value: 'not_invited' }
        ]

        return (
            <Box className={classes.boxContainer} >
                <FormControl className={`${classes.formControl} ${classes.formControlSome}`}>
                    <Select
                        multiple
                        value={state.fieldOfStudies}
                        displayEmpty
                        onChange={(event) => { handleFiltersArray('fieldOfStudies', event) } }
                        input={<Input />}
                        MenuProps={DROPDOWN_STYLE_MENU_PROPS}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return <em style={{ color: 'var(--grey)', fontSize: 14 }} ><FormattedMessage id="search_bar.study.field_singular" /></em>
                            } else {
                                return (
                                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Box style={{ paddingRight: 0 }}><FormattedMessage id="search_bar.study.field_singular" /></Box>
                                        <Avatar style={{ width: 20, height: 20, fontSize: 13 }} >{selected.length}</Avatar>
                                    </Box>
                                )
                            }
                        }}
                    >
                        {
                            studyDomainCollection.map((fieldOfStudy, key) => (
                                <MenuItem className={classes.menuItem} key={key} value={fieldOfStudy.value}>
                                    <CustomCheckbox
                                        style={{ marginRight: 8 }}
                                        checked={state.fieldOfStudies.some(elem => elem === fieldOfStudy.value)}
                                    />
                                    <ListItemText primary={fieldOfStudy.name} primaryTypographyProps={{ variant: 'body2' }}/>
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                {
                    moreFilter &&
                    <>
                        <Box className={classes.checkboxContainer}>
                            <FormControlLabel
                                style={{ maxHeight: 20 }}
                                control={
                                    <CustomCheckbox
                                        style={{ marginRight: 8 }}
                                        checked={state.legalAge}
                                        onChange={handleCheckBoxChange}
                                        name="legalAge"
                                    />
                                }
                                label={<Box component="div" className={classes.placeholderText}><FormattedMessage id="search_bar.legal_age" /></Box>}
                            />
                        </Box>
                        <Box className={classes.checkboxContainer}>
                            <FormControlLabel
                                style={{ maxHeight: 20 }}
                                control={
                                    <CustomCheckbox
                                        style={{ marginRight: 8 }}
                                        checked={state.drivingLicense}
                                        onChange={handleCheckBoxChange}
                                        name="drivingLicense"
                                    />
                                }
                                label={<Box component="div" className={classes.placeholderText}><FormattedMessage id="search_bar.driver_license" /></Box>}
                            />
                        </Box>
                        <Box className={classes.sliderContainer}>
                            <Slider
                                value={state.distance}
                                name="distance"
                                onChange={handleSliderChange}
                                aria-labelledby="continuous-slider"
                                valueLabelDisplay="auto"
                                min={1}
                                max={20}
                                steps={1}
                            />
                            <Box className={`${classes.placeholderText} ${classes.slider}`}>
                                {state.distance} km
                            </Box>
                        </Box>

                        <Box className={classes.languageContainer}>
                            <Box borderColor="transparent" className={classes.langContainer}>
                                <Typography variant='body2' component='div' style={{ marginRight: 10 }}>FR</Typography>
                                <Rating
                                    name="fr"
                                    precision={1}
                                    emptyIcon={<IconFactory icon='star' fontSize='0.875rem'/>}
                                    icon={<IconFactory icon='star-full' fontSize='0.875rem'/>}
                                    size="small"
                                    onChange={handleLanguageLevelChange}
                                    max={4}
                                    value={state.langLevel.fr}
                                />
                            </Box>
                            <Box borderColor="transparent" className={classes.langContainer}>
                                <Typography variant='body2' component='div' style={{ marginRight: 10 }}>EN</Typography>
                                <Rating
                                    name="en"
                                    precision={1}
                                    emptyIcon={<IconFactory icon='star' fontSize='0.875rem'/>}
                                    icon={<IconFactory icon='star-full' fontSize='0.875rem'/>}
                                    size="small"
                                    onChange={handleLanguageLevelChange}
                                    max={4}
                                    value={state.langLevel.en}
                                />
                            </Box>
                            <Box borderColor="transparent" className={classes.langContainer}>
                                <Typography variant='body2' component='div' style={{ marginRight: 10 }}>NL</Typography>
                                <Rating
                                    name="nl"
                                    precision={1}
                                    emptyIcon={<IconFactory icon='star' fontSize='0.875rem'/>}
                                    icon={<IconFactory icon='star-full' fontSize='0.875rem'/>}
                                    size="small"
                                    onChange={handleLanguageLevelChange}
                                    max={4}
                                    value={state.langLevel.nl}
                                />
                            </Box>
                        </Box>
                        <Box mr={2}>
                            <FormControl fullWidth>
                                <Select
                                    value={state.status}
                                    onChange={(event) => handleFiltersSingleSelect('status', event)}
                                    MenuProps={DROPDOWN_STYLE_MENU_PROPS}
                                    displayEmpty
                                >
                                    <MenuItem value=""><em className={classes.placeholderText}><FormattedMessage id="search_bar.status" /></em></MenuItem>
                                    {
                                        elems.map((elem, key) => (
                                            <MenuItem className={classes.menuItem} value={elem.value} key={key}>
                                                <Typography variant='body2'>
                                                    {elem.name}
                                                </Typography>
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </>
                }
            </Box>
        )
    }

    const renderJobResultsFilter = () => {
        return (
            <Box className={classes.boxContainer} mr={2}>
                <Box className={classes.boxInput}>
                    <Box style={{ marginRight: 10, display: 'flex' }}>
                        <IconFactory icon='search' color='primary'/>
                    </Box>
                    <FormattedMessage id="search_bar.candidate_name">
                        {placeholder =>
                            <TextField
                                placeholder={placeholder}
                                InputProps={{
                                    classes: {
                                        input: classes.inputBase
                                    }
                                }}
                                value={inputField.name}
                                name="name"
                                onChange={handleInputChange}
                                className={classes.inputBase}
                                fullWidth
                            />
                        }
                    </FormattedMessage>
                </Box>
                {
                    moreFilter &&
                    <>
                        <FormControl fullWidth className={classes.formControl}>
                            <Select
                                multiple
                                value={state.recruitmentStatuses}
                                displayEmpty
                                onChange={(event) => { handleFiltersArray('recruitmentStatuses', event) } }
                                input={<Input />}
                                inputProps={{ MenuProps: { disableScrollLock: true } }}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <em className={classes.placeholderText}><FormattedMessage id="search_bar.all_recruitment_statuses" /></em>
                                    } else {
                                        return (
                                            <Box className={classes.tagsNumberContainer}>
                                                <Box className={classes.tagText}><FormattedMessage id="search_bar.all_recruitment_statuses" /></Box>
                                                <Avatar className={classes.tagAvatar} >{selected.length}</Avatar>
                                            </Box>
                                        )
                                    }
                                }}
                                MenuProps={DROPDOWN_STYLE_MENU_PROPS}
                            >
                                <MenuItem value={[]} disabled>
                                    <Typography variant='subtitle1' className={classes.selectPlaceholder}>
                                        <FormattedMessage id="search_bar.recruitment_status" />
                                    </Typography>
                                </MenuItem>
                                {recruitmentStatusCollection.map((status, key) => (
                                    <MenuItem key={key} value={{ label: status.value, value: status.value }}>
                                        <Checkbox
                                            icon={<IconFactory icon='checkbox-false' />}
                                            checkedIcon={<IconFactory icon='checkbox-true' />}
                                            color='primary'
                                            checked={state.recruitmentStatuses.some(elem => elem.value === status.value)}
                                        />
                                        <ListItemText primary={<FormattedMessage id={`candidacy.recruitment_status.${status.value}`} />} primaryTypographyProps={{ variant: 'body2' }}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth className={classes.formControl}>
                            <Select
                                multiple
                                value={state.fieldOfStudies}
                                displayEmpty
                                onChange={(event) => { handleFiltersArray('fieldOfStudies', event) } }
                                input={<Input />}
                                inputProps={{ MenuProps: { disableScrollLock: true } }}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <em className={classes.placeholderText}><FormattedMessage id="search_bar.all_orientations" /></em>
                                    } else {
                                        return (
                                            <Box className={classes.tagsNumberContainer}>
                                                <Box className={classes.tagText}><FormattedMessage id="search_bar.all_orientations" /></Box>
                                                <Avatar className={classes.tagAvatar} >{selected.length}</Avatar>
                                            </Box>
                                        )
                                    }
                                }}
                                MenuProps={DROPDOWN_STYLE_MENU_PROPS}
                            >
                                <MenuItem value={[]} disabled>
                                    <Typography variant='subtitle1' className={classes.selectPlaceholder}>
                                        <FormattedMessage id="search_bar.study.field_plural" />
                                    </Typography>
                                </MenuItem>
                                {
                                    studyDomainCollection.map((fieldOfStudy, key) => (
                                        <MenuItem key={key} value={{ label: fieldOfStudy.name, value: fieldOfStudy.value }}>
                                            <Checkbox
                                                icon={<IconFactory icon='checkbox-false' />}
                                                checkedIcon={<IconFactory icon='checkbox-true' />}
                                                color='primary'
                                                checked={state.fieldOfStudies.some(elem => elem.value === fieldOfStudy.value)}
                                            />
                                            <ListItemText primary={fieldOfStudy.name} primaryTypographyProps={{ variant: 'body2' }}/>
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <Select
                                multiple
                                value={state.studyLevel}
                                displayEmpty
                                onChange={(event) => handleFiltersArray('studyLevel', event)}
                                input={<Input />}
                                inputProps={{ MenuProps: { disableScrollLock: true } }}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <em className={classes.placeholderText}><FormattedMessage id="search_bar.all_lvl" /></em>
                                    } else {
                                        return (
                                            <Box className={classes.tagsNumberContainer}>
                                                <Box className={classes.tagText}><FormattedMessage id="search_bar.study.level" /></Box>
                                                <Avatar className={classes.tagAvatar} >{selected.length}</Avatar>
                                            </Box>
                                        )
                                    }
                                }}
                                MenuProps={DROPDOWN_STYLE_MENU_PROPS}
                            >
                                <MenuItem value={[]} disabled>
                                    <Typography variant='subtitle1' className={classes.selectPlaceholder}>
                                        <FormattedMessage id="search_bar.study.level" />
                                    </Typography>
                                </MenuItem>
                                {
                                    studentSituationCollection.map((studyLevel, key) => (
                                        <MenuItem key={key} value={{ label: studyLevel.name, value: studyLevel.value }}>
                                            <Checkbox
                                                icon={<IconFactory icon='checkbox-false' />}
                                                checkedIcon={<IconFactory icon='checkbox-true' />}
                                                color='primary'
                                                checked={state.studyLevel.some(elem => elem.value === studyLevel.value)}
                                            />
                                            <ListItemText primary={studyLevel.name} primaryTypographyProps={{ variant: 'body2' }}/>
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </>
                }
            </Box>
        )
    }

    const rowRenderer = (row) => {
        const { key, index, style } = row
        const option = searchCities[index]

        return (
            <MenuItem
                key={key}
                style={style}
                value={option.value}
                onClick={() => handleSearchCities(option)}
            >
                <Checkbox
                    icon={<IconFactory icon='checkbox-false' />}
                    checkedIcon={<IconFactory icon='checkbox-true' />}
                    color='primary'
                    checked={state.location.some(elem => elem.value === option.value)}
                />
                <ListItemText primary={`${option.name} (${option.value})`} primaryTypographyProps={{ variant: 'body2' }}/>
            </MenuItem>
        )
    }

    const renderCitiesMultipleSelect = () => {
        return (
            <FormControl fullWidth>
                <Select
                    multiple
                    value={state.location}
                    displayEmpty
                    onChange={handleSearchCities}
                    input={<Input />}
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                    focusable={false}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                            return <em className={classes.placeholderText} ><FormattedMessage id="search_bar.city" /></em>
                        } else {
                            return (
                                <Box className={classes.tagsNumberContainer}>
                                    <Box className={classes.tagText}><FormattedMessage id="search_bar.city" /></Box>
                                    <Avatar className={classes.tagAvatar} >{selected.length}</Avatar>
                                </Box>
                            )
                        }
                    }}
                    MenuProps={DROPDOWN_STYLE_MENU_PROPS}
                    style={{ width: '100%' }}
                >
                    <Box className={`${classes.boxInput} ${classes.boxInputSearchCity}`}>
                        <Box style={{ marginRight: 10, display: 'flex' }}>
                            <IconFactory icon='search' color='primary'/>
                        </Box>
                        <FormattedMessage id="search_bar.search.city">
                            {placeholder =>
                                <InputBase
                                    className={classes.inputBase}
                                    placeholder={placeholder}
                                    name="citySearch"
                                    onChange={handleInputSearchCity}
                                    value={citiesInput}
                                    fullWidth
                                    onKeyDown={e => e.stopPropagation()}
                                />
                            }
                        </FormattedMessage>
                    </Box>
                    <List
                        width={500}
                        height={300}
                        rowCount={searchCities.length}
                        rowHeight={45}
                        rowRenderer={rowRenderer}
                    />
                </Select>
            </FormControl>
        )
    }

    const renderStudyDomainSelect = () => {
        return (
            <FormControl fullWidth className={classes.formControl}>
                <Select
                    multiple
                    value={state.studyDomain}
                    displayEmpty
                    onChange={(event) => { handleFiltersArray('studyDomain', event) } }
                    input={<Input />}
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                            return <em className={classes.placeholderText}><FormattedMessage id="search_bar.study.field_singular" /></em>
                        } else {
                            return (
                                <Box className={classes.tagsNumberContainer}>
                                    <Box className={classes.tagText}><FormattedMessage id="search_bar.study.field_singular" /></Box>
                                    <Avatar className={classes.tagAvatar} >{selected.length}</Avatar>
                                </Box>
                            )
                        }
                    }}
                    MenuProps={DROPDOWN_STYLE_MENU_PROPS}
                >
                    <MenuItem value={[]} disabled>
                        <Typography variant='subtitle1' className={classes.selectPlaceholder}>
                            <FormattedMessage id="search_bar.study.field_singular" />
                        </Typography>
                    </MenuItem>
                    {studyDomainCollection.map((domain, key) => (
                        <MenuItem className={classes.menuItem} key={key} value={{ label: domain.name, value: domain.value }}>
                            <Checkbox
                                icon={<IconFactory icon='checkbox-false' />}
                                checkedIcon={<IconFactory icon='checkbox-true' />}
                                color='primary'
                                checked={state.studyDomain.some(elem => elem.value === domain.value)}
                            />
                            <ListItemText primary={domain.name} primaryTypographyProps={{ variant: 'body2' }}/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    }

    const renderJobFilters = () => {
        return (
            <Box className={classes.boxContainer} >
                <Hidden smDown>
                    <Box className={classes.boxInput}>
                        <Box style={{ marginRight: 10, display: 'flex' }}>
                            <IconFactory icon='search' color='primary'/>
                        </Box>
                        <FormattedMessage id="search_bar.search.job">
                            {placeholder =>
                                <TextField
                                    InputProps={{ classes: { input: classes.inputBase } }}
                                    placeholder={placeholder}
                                    value={inputField.title}
                                    name='title'
                                    onChange={handleInputChange}
                                    className={classes.inputBase}
                                    fullWidth
                                    onKeyPress={keyPress}
                                />
                            }
                        </FormattedMessage>
                    </Box>
                </Hidden>
                <Hidden mdUp>
                    <Box className={classes.boxInput}>
                        <Box style={{ marginRight: 10, display: 'flex' }}>
                            <IconFactory icon='search' color='primary'/>
                        </Box>
                        <FormattedMessage id="search_bar.search.job">
                            {placeholder =>
                                <TextField
                                    InputProps={{
                                        classes: {
                                            input: classes.inputBase
                                        }
                                    }}
                                    placeholder={placeholder}
                                    value={inputField.title}
                                    name='title'
                                    onChange={handleInputChange}
                                    className={classes.inputBase}
                                    fullWidth
                                    onKeyPress={keyPress}
                                />
                            }
                        </FormattedMessage>
                        <IconButton
                            onClick={() => setMorefilter((moreFilter) => !moreFilter)}
                        >
                            <IconFactory icon='sliders' color='primary' fontSize={22}/>
                        </IconButton>
                    </Box>
                </Hidden>
                {
                    moreFilter &&
                    <>
                        {renderStudyDomainSelect()}
                        <Box className={classes.locationsDropdown}>
                            {renderCitiesMultipleSelect()}
                        </Box>
                        <FormControl fullWidth className={classes.formControl}>
                            <Select
                                multiple
                                value={state.companySize}
                                displayEmpty
                                onChange={(event) => { handleFiltersArray('companySize', event) } }
                                input={<Input />}
                                inputProps={{ MenuProps: { disableScrollLock: true } }}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <em className={classes.placeholderText} ><FormattedMessage id="search_bar.company_size" /></em>
                                    } else {
                                        return (
                                            <Box className={classes.tagsNumberContainer}>
                                                <Box className={classes.tagText}><FormattedMessage id="search_bar.company_size" /></Box>
                                                <Avatar className={classes.tagAvatar} >{selected.length}</Avatar>
                                            </Box>
                                        )
                                    }
                                }}
                                MenuProps={DROPDOWN_STYLE_MENU_PROPS}
                            >
                                <MenuItem value={[]} disabled>
                                    <Typography variant='subtitle1' className={classes.selectPlaceholder}>
                                        <FormattedMessage id="search_bar.company_size" />
                                    </Typography>
                                </MenuItem>
                                {
                                    companySizeCollection.map((companySize, key) => (
                                        <MenuItem className={classes.menuItem} key={key} value={{ label: companySize.name, value: companySize.value }}>
                                            <Checkbox
                                                icon={<IconFactory icon='checkbox-false' />}
                                                checkedIcon={<IconFactory icon='checkbox-true' />}
                                                color='primary'
                                                checked={state.companySize.some(elem => elem.value === companySize.value)}
                                            />
                                            <ListItemText primary={companySize.name} primaryTypographyProps={{ variant: 'body2' }}/>
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </>
                }
            </Box>
        )
    }

    return (
        <>
            {isLoaded && <Box>
                <Paper elevation={3} className={props.paper ? classes.paperContainer : classes.noPaperContainer}>
                    <Box className={classes.boxContainerPage}>
                        {renderFilters()}
                        <Hidden only={['xs', 'sm']}>
                            <Box className={classes.desktopResetButton}>
                                <Button
                                    size='small'
                                    variant='secondary'
                                    onClick={reset}
                                    className={classes.resetButton}
                                >
                                    <FormattedMessage id='search_bar.reinit_button_1' />
                                </Button>
                            </Box>
                        </Hidden>
                        <Hidden only={['md', 'lg', 'xl']}>
                            <Button
                                fullWidth
                                size='small'
                                variant='secondary'
                                onClick={reset}
                                className={classes.buttonResetMobile}
                            >
                                <FormattedMessage id='search_bar.reinit_button_1' />
                            </Button>
                        </Hidden>
                    </Box>
                </Paper>
            </Box>}
        </>
    )
}

export default SearchbarComponent
