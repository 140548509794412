import React from 'react'

const Package0SVG = (props) => (
    <g>
        <g>
            <g>
                <g>
                    <g>
                        <path d="M296.968,371h-91.936c-5.269,0-9.634-4.089-9.979-9.347c-1.572-23.999-12.89-53.977-23.834-82.968       c-12.399-32.842-25.22-66.803-25.22-95.14c0-42.59,10.418-82.775,29.336-113.152C195.172,38.542,222.044,21,251,21       s55.827,17.542,75.663,49.394C345.581,100.771,356,140.956,356,183.546c0,28.151-12.814,62.167-25.207,95.063       c-10.952,29.074-22.278,59.139-23.847,83.046C306.602,366.912,302.236,371,296.968,371z M214.035,351h73.933       c3.479-24.677,13.947-52.467,24.109-79.442C323.839,240.337,336,208.055,336,183.546c0-38.902-9.345-75.333-26.313-102.58       C293.637,55.193,272.794,41,251,41c-21.794,0-42.636,14.193-58.687,39.966C175.345,108.213,166,144.644,166,183.546       c0,24.688,12.166,56.912,23.931,88.076C200.091,298.535,210.559,326.264,214.035,351z" />
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M267.936,371h-33.871c-5.429,0-9.866-4.331-9.997-9.759c-0.601-24.914-4.829-55.313-8.918-84.711       c-4.498-32.34-9.149-65.78-9.149-92.984c0-41.477,3.73-80.579,10.504-110.103C224.601,38.154,235.885,21,251,21       s26.398,17.154,34.495,52.443C292.27,102.967,296,142.069,296,183.546c0,27.021-4.649,60.518-9.146,92.911       c-4.091,29.48-8.322,59.965-8.922,84.784C277.802,366.669,273.364,371,267.936,371z M243.673,351h14.655       c1.292-23.794,5.059-50.937,8.717-77.292c4.402-31.721,8.955-64.523,8.955-90.162c0-37.586-3.188-73.446-8.976-100.974       C260.26,50.399,252.911,42.75,251,41.251c-1.911,1.499-9.26,9.147-16.024,41.32C229.188,110.1,226,145.96,226,183.546       c0,25.82,4.554,58.563,8.958,90.229C238.616,300.069,242.382,327.15,243.673,351z" />
                    </g>
                </g>
                <g>
                    <path d="M282.5,491h-63c-18.472,0-33.5-15.028-33.5-33.5V401c0-5.522,4.477-10,10-10h110c5.522,0,10,4.478,10,10v56.5      C316,475.972,300.972,491,282.5,491z M206,411v46.5c0,7.444,6.056,13.5,13.5,13.5h63c7.444,0,13.5-6.056,13.5-13.5V411H206z" />
                </g>
                <g>
                    <path d="M326,411H176c-5.523,0-10-4.478-10-10s4.477-10,10-10h150c5.522,0,10,4.478,10,10S331.522,411,326,411z" />
                </g>
                <g>
                    <path d="M346,371H156c-5.523,0-10-4.478-10-10s4.477-10,10-10h190c5.522,0,10,4.478,10,10S351.522,371,346,371z" />
                </g>
                <g>
                    <path d="M236,491c-5.523,0-10-4.478-10-10v-30c0-5.522,4.477-10,10-10s10,4.478,10,10v30C246,486.522,241.523,491,236,491z" />
                </g>
                <g>
                    <path d="M266,491c-5.522,0-10-4.478-10-10v-30c0-5.522,4.478-10,10-10s10,4.478,10,10v30C276,486.522,271.522,491,266,491z" />
                </g>
                <g>
                    <path d="M411,441h-63.333c-5.522,0-10-4.478-10-10s4.478-10,10-10H411c8.271,0,15-6.729,15-15s-6.729-15-15-15s-15,6.729-15,15      c0,5.522-4.478,10-10,10s-10-4.478-10-10c0-19.299,15.701-35,35-35s35,15.701,35,35S430.299,441,411,441z" />
                </g>
                <g>
                    <path d="M99.333,401H36c-5.523,0-10-4.478-10-10s4.477-10,10-10h63.333c8.271,0,15-6.729,15-15s-6.729-15-15-15s-15,6.729-15,15      c0,5.522-4.477,10-10,10s-10-4.478-10-10c0-19.299,15.701-35,35-35c19.299,0,35,15.701,35,35S118.632,401,99.333,401z" />
                </g>
                <g>
                    <path d="M486,481H386c-5.522,0-10-4.478-10-10s4.478-10,10-10h100c5.522,0,10,4.478,10,10S491.522,481,486,481z" />
                </g>
                <g>
                    <path d="M156,451H76c-5.523,0-10-4.478-10-10s4.477-10,10-10h80c5.523,0,10,4.478,10,10S161.523,451,156,451z" />
                </g>
                <g>
                    <path d="M76,491H46c-5.523,0-10-4.478-10-10s4.477-10,10-10h30c5.523,0,10,4.478,10,10S81.523,491,76,491z" />
                </g>
                <g>
                    <path d="M471,91h-30c-12.073,0-22.175-8.603-24.498-20H411c-13.785,0-25-11.215-25-25s11.215-25,25-25h30      c12.073,0,22.175,8.603,24.498,20H471c13.785,0,25,11.215,25,25S484.785,91,471,91z M411,41c-2.757,0-5,2.243-5,5s2.243,5,5,5      h15.872c3.247,0,6.293,1.577,8.167,4.229c1.874,2.652,2.344,6.049,1.259,9.11C436.101,64.897,436,65.457,436,66      c0,2.757,2.243,5,5,5h30c2.757,0,5-2.243,5-5s-2.243-5-5-5h-15.872c-3.247,0-6.293-1.577-8.167-4.229      c-1.874-2.652-2.344-6.049-1.259-9.11C445.899,47.103,446,46.543,446,46c0-2.757-2.243-5-5-5H411z" />
                </g>
                <g>
                    <path d="M101,151H71c-12.073,0-22.175-8.603-24.498-20H41c-13.785,0-25-11.215-25-25s11.215-25,25-25h30      c12.073,0,22.175,8.603,24.498,20H101c6.98,0,13.698,2.964,18.43,8.133c2.805,3.063,3.424,7.542,1.555,11.252      c-3.78,7.504-6.896,15.373-9.26,23.387c-1.177,3.991-4.702,6.835-8.852,7.144C102.366,150.953,101.737,151,101,151z M41,101      c-2.757,0-5,2.243-5,5s2.243,5,5,5h15.872c3.247,0,6.292,1.577,8.166,4.228c1.874,2.651,2.344,6.047,1.261,9.108      C66.101,124.896,66,125.456,66,126c0,2.757,2.243,5,5,5h23.825c1.169-3.37,2.454-6.708,3.848-10H85.128      c-3.247,0-6.292-1.577-8.166-4.228c-1.874-2.651-2.344-6.047-1.261-9.108C75.899,107.104,76,106.544,76,106c0-2.757-2.243-5-5-5      H41z" />
                </g>
                <g>
                    <path d="M326,371H176c-5.111,0-9.4-3.854-9.943-8.938c-2.483-23.231-20.78-52.938-38.475-81.664      C107.139,247.209,86,212.891,86,183.546c0-15.427,2.201-30.712,6.543-45.432c2.703-9.162,6.262-18.154,10.581-26.727      c13.465-26.731,34.134-49.31,59.772-65.293C189.23,29.677,219.696,21,251,21c90.981,0,165,72.918,165,162.546      c0,29.158-21.128,63.532-41.561,96.774c-17.708,28.811-36.02,58.602-38.496,81.744C335.399,367.146,331.111,371,326,371z       M184.466,351h133.072c5.614-25.43,22.995-53.71,39.863-81.152C376.378,238.974,396,207.049,396,183.546      C396,104.946,330.953,41,251,41c-55.531,0-105.349,30.418-130.014,79.385c-3.781,7.504-6.896,15.373-9.26,23.387      c-3.799,12.881-5.726,26.263-5.726,39.774c0,23.679,19.628,55.546,38.611,86.363C161.476,297.29,178.855,325.506,184.466,351z" />
                </g>
            </g>
        </g>
    </g>
)

export default Package0SVG
