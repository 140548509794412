import axios from 'axios'
import RouteMap from '../assets/RouteMap.js'
import ReactOnRails from 'react-on-rails'

import {
    CONVERSATION_API,
    CANDIDACIES_MESSAGES_API,
    OFFERS_MESSAGES_API,
    ADD_FILE_MESSAGES_API,
    GET_MESSAGES_API,
    GET_CONVERSATION_MESSAGES_API,
    SEND_MESSAGE_TO_SELECTION
} from './env'

const CreateConversation = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: CONVERSATION_API,
        method: 'post',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

const GetCandidacies = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: CANDIDACIES_MESSAGES_API,
        method: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

const GetOffers = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: OFFERS_MESSAGES_API,
        method: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

const GetMessages = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: GET_MESSAGES_API,
        method: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

const GetConversations = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: GET_CONVERSATION_MESSAGES_API,
        method: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

const AddFileToMessage = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: ADD_FILE_MESSAGES_API,
        method: 'get',
        params: {
            locale: RouteMap.GetLocaleFromUrl(),
            ...data
        }
    })
}

const SendMessageToSelection = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: SEND_MESSAGE_TO_SELECTION,
        method: 'post',
        data: data
    })
}

export default {
    CreateConversation,
    GetCandidacies,
    GetOffers,
    GetMessages,
    AddFileToMessage,
    GetConversations,
    SendMessageToSelection
}
