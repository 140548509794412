/* eslint-disable react/prop-types */
import React from 'react'
import InfoLineView from './InfoLineView/InfoLineView'
import { FormattedMessage } from 'react-intl'

const OpenPositionsLine = (props) => {
    const { job } = props
    if (!job.number_of_people_sought) return null

    return (
        <InfoLineView
            icon='candidacies'
            text={<FormattedMessage
                id='job_show.job_info_card.number_of_people_sought'
                values={{ number_of_people_sought: job.number_of_people_sought }}
            />}
        />
    )
}

export default OpenPositionsLine
