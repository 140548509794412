import axios from 'axios'
import ReactOnRails from 'react-on-rails'

import {
    USER_PROFILE,
    USER_EXPERIENCE,
    USER_CANDIDACY,
    GET_USER_INFO_API,
    UPDATE_NAME_PROFILE_API,
    UPDATE_CITY_PROFILE_API,
    UPDATE_EXPERIENCES_PROFILE_API
} from './env'

function UserEditMyProfile (data) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: USER_PROFILE,
        method: 'patch',
        data: data
    })
}
function UserCreateExperience (data) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: USER_EXPERIENCE,
        method: 'post',
        data: data
    })
}
function UpdateExperience (data) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: USER_EXPERIENCE,
        method: 'patch',
        data: data
    })
}

function SendCandidacy (data) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: USER_CANDIDACY,
        method: 'post',
        data: data
    })
}

const GetUserInfo = (params) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: GET_USER_INFO_API,
        method: 'get'
    })
}

const UpdateName = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: UPDATE_NAME_PROFILE_API,
        method: 'patch',
        data: data
    })
}

const UpdateCity = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: UPDATE_CITY_PROFILE_API,
        method: 'patch',
        data: data
    })
}

const UpdateExperiences = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: UPDATE_EXPERIENCES_PROFILE_API,
        method: 'patch',
        data: data
    })
}

export default {
    UpdateExperience,
    UserEditMyProfile,
    UserCreateExperience,
    SendCandidacy,
    GetUserInfo,
    UpdateName,
    UpdateCity,
    UpdateExperiences
}
