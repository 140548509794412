import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Image, Transformation } from 'cloudinary-react'

import { useStyles } from './CTABannerHiring_style'
import { Typography, Container, Box, Hidden } from '@material-ui/core'

import Button from 'shared/components/Button/Button.jsx'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const CTABannerHiring = (props) => {
    const { userType, type } = props
    const classes = useStyles()

    return (<>
        { (!userType || userType === 'employer') &&
        <Container className={classes.bannerContainer} disableGutters>
            <Box className={classes.upContainer}>
                <FormattedMessage id='banner_hiring.title'>
                    {text =>
                        <Typography className={classes.titleContainer} variant='h1' component='p' dangerouslySetInnerHTML={{ __html: text }} />
                    }
                </FormattedMessage>
                <Typography className={classes.description}>  <FormattedMessage id='banner_hiring.description' /></Typography>
                {/* <CTABannerButton userType={userType} type={type} textId='banner_hiring.button.label' /> */}
                <RouteMapLink page='contact-employer'>
                    <Button variant='cta-primary'>
                        <FormattedMessage id="banner_hiring.button.label"/>
                    </Button>
                </RouteMapLink>
            </Box>

            <Box className={classes.downContainer}>
                <Hidden smUp>
                    <Image
                        cloudName='studentbe'
                        publicId='student-be-assets/lol-girl'
                        alt='student.be post 3 jobs for free'
                        className={classes.image}
                    >
                        <Transformation flags='progressive' fetchFormat='auto' quality='auto:best' crop='fill' />
                    </Image>
                </Hidden>
                <Hidden xsDown>
                    <Image
                        cloudName='studentbe'
                        publicId='student-be-assets/lol-girl'
                        alt='student.be post 3 jobs for free'
                        className={classes.image}
                    >
                        <Transformation flags='progressive' fetchFormat='auto' quality='auto:best' crop='fill' />
                    </Image>
                </Hidden>
            </Box>
        </Container >}
    </>
    )
}

export default CTABannerHiring
